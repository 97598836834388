import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../../assets/sass/mob/navBar.sass";
import HomeSvg from "../../../assets/svgComponents/HomeSvg";
import ManagementSvg from "../../../assets/svgComponents/ManagementSvg";
import ProductSvg from "../../../assets/svgComponents/ProductSvg";
import OrdersSvg from "../../../assets/svgComponents/OrdersSvg";
import OptionsSvg from "../../../assets/svgComponents/OptionsSvg";
import { selectedMainMenu as onSelectedMainMenu } from "../../../actions/uiAction";
import { useDispatch } from "react-redux";
import { useNav } from "../../../helpers/customHook";

const NavBar = (props: any) => {
    const dispatch = useDispatch();
    const navigateTo = useNav();
    const [selectedMainMenu, setSelectedMainMenu] = useState(null);
    const [hideBar, setHideBar] = useState(false);
    const { selectedMainMenu: selectedMainMenuUI } = useSelector((state: any) => state.ui);
    const WindowHeight = window.innerHeight;
    useEffect(() => {
        function handleResize() {
            if ((WindowHeight / 2) > (window.innerHeight - 110)) {
                props.onNavBarHidden(true);
                setHideBar(true);
            } else {
                props.onNavBarHidden(false);
                setHideBar(false);
            }
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (selectedMainMenuUI !== selectedMainMenu) {
            setSelectedMainMenu(selectedMainMenuUI)
        }
        //eslint-disable-next-line
    }, [selectedMainMenuUI])
    useEffect(() => {
        if (selectedMainMenu) {
            navigateTo(`/${selectedMainMenu}`);
            dispatch(onSelectedMainMenu(selectedMainMenu));
        }
        //eslint-disable-next-line
    }, [selectedMainMenu]);
    return <div className="nav-bar" style={{ display: hideBar ? 'none' : 'block' }}>
        <div className="icons">
            <div className="icon">
                <div className={selectedMainMenu === "home" ? "box selected" : "box"}></div>
                <HomeSvg stroke={selectedMainMenu === "home" ? "#33E1BE" : "#FFF"} onClick={() => { setSelectedMainMenu("home") }}></HomeSvg>
            </div>
            <div className="icon">
                <div className={selectedMainMenu === "order" ? "box selected" : "box"}></div>
                <OrdersSvg stroke={selectedMainMenu === "order" ? "#33E1BE" : "#FFF"} onClick={() => { setSelectedMainMenu("order") }}></OrdersSvg>
            </div>
            <div className="icon">
                <div className={selectedMainMenu === "products" ? "box selected" : "box"}></div>
                <ProductSvg stroke={selectedMainMenu === "products" ? "#33E1BE" : "#FFF"} onClick={() => { setSelectedMainMenu("products") }}></ProductSvg>
            </div>
            <div className="icon">
                <div className={selectedMainMenu === "category" ? "box selected" : "box"}></div>
                <ManagementSvg stroke={selectedMainMenu === "category" ? "#33E1BE" : "#FFF"} onClick={() => { setSelectedMainMenu("category") }}></ManagementSvg>
            </div>
            <div className="icon">
                <div className={selectedMainMenu === "options" ? "box selected" : "box"}></div>
                <OptionsSvg stroke={selectedMainMenu === "options" ? "#33E1BE" : "#FFF"} onClick={() => { setSelectedMainMenu("options") }}></OptionsSvg>
            </div>
        </div>
    </div>
}
export default NavBar;