import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import '../../../assets/sass/dsk/menu.sass';
import { selectedTabMenu, selectedSettingsMenu } from '../../../actions/uiAction';
import ArrowSvg from "../../../assets/images/arrow_ico.svg";
import { Categories, Menus } from "./Constants";
import { useLocation } from 'react-router-dom';
import { useNav } from '../../../helpers/customHook';
import { getOutlets } from '../../../actions/outletAction';
import { useDispatch } from 'react-redux';

interface IMenu {
    icon: any,
    key: string,
    label: string,
    desc: string
}
const Breadcrumbs = (props: any) => {
    const regexUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
    const location = useLocation();
    const dispatch = useDispatch();
    const { outlets } = useSelector((state: any) => state.outlet);
    const routePath = location.pathname.split("/");
    const navigateTo = useNav();
    const [selectedMenu, setMenu] = useState<IMenu>({
        icon: null,
        key: "",
        label: "",
        desc: ""
    });
    const [selectedCategory, setCategory] = useState<IMenu>({
        icon: null,
        key: "",
        label: "",
        desc: ""
    });
    const loadOutlets = async () => {
        await getOutlets()(dispatch);
    }
    useEffect(() => {
        if (routePath.indexOf("outlet") > -1 && outlets.length === 0) {
            loadOutlets();
        }
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (routePath.length > 3) {
            setMenu(Menus[routePath[3]].filter((item) => routePath[4] === item.key)[0]);
            setCategory(Categories.filter((item) => routePath[3] === item.key)[0]);
        } else if (selectedMenu.key !== "" || selectedCategory.key !== "") {
            setMenu({
                icon: null,
                key: "",
                label: "",
                desc: ""
            });
            setCategory({
                icon: null,
                key: "",
                label: "",
                desc: ""
            });
        }
        //eslint-disable-next-line
    }, [routePath]);
    const gotoSettings = (pos) => {
        if (pos && pos === 2) {
            navigateTo("settings");
        } else {
            navigateTo(-1);
        }
    }
    return (<div className="header-menu-bar" style={{ textTransform: "capitalize" }}>
        <div className="title" >
            <div style={{ cursor: "pointer" }} onClick={() => gotoSettings(2)}>Settings</div>
            <img alt='Arrow' src={ArrowSvg} style={{ paddingLeft: 8, paddingRight: 8, height: 12 }} />
            {routePath && routePath.map((item, index) => {
                let outletName = null;
                if (outlets && outlets.length > 0 && routePath.indexOf("outlet") > -1 && regexUUID.test(item)) {
                    outletName = outlets.filter((outlet: any) => outlet.id === item)[0].name;
                }
                return index > 2 && <div key={index} style={{ color: "#474747", fontWeight: 100, display: 'flex', alignItems: 'center' }}>
                    <div onClick={() => index > 2 && routePath.length - 1 !== index && gotoSettings(index)} style={{ cursor: index > 2 && routePath.length - 1 !== index ? "pointer" : "default" }}>{outletName || item}</div>
                    {index !== routePath.length - 1 && <img alt='Arrow' src={ArrowSvg} style={{ paddingLeft: 8, paddingRight: 8, height: 12 }} />}
                </div>
            })}
        </div>
    </div >
    );
}

export default connect(null, { selectedTabMenu, selectedSettingsMenu })(Breadcrumbs);