import React from 'react';

import { Action, ActionProps } from '../Action';
import DragSvg from '../../../../../../assets/images/drag-icon.svg';

export function Handle(props: ActionProps) {
  return (
    <Action cursor="grab" data-cypress="draggable-handle" {...props}>
      <img src={DragSvg} alt="drag" className="drag"></img>
    </Action>
  );
}
