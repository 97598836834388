import { Input } from 'antd';
import React, { useState, useEffect } from 'react';
// import { getBase64 } from '../../../../../../../helpers/util';
import UploadSvg from '../../../../../../../assets/images/upload.svg';
import ColorPalette from '../../../../../common/ColorPalette';
import apiConfig from '../../../../../../../apiConfig';
// import { CloseCircleFilled } from '@ant-design/icons';
import CustomFileUploader from '../../../../../../common/CustomFileUploader';
import TextArea from 'antd/lib/input/TextArea';
import { useOutletContext } from 'react-router-dom';

const Landing = (props: any) => {
    const [mobTheme, isSave, onSave]: any = useOutletContext();
    const [mainTitle, setMainTitle] = useState({
        label: '',
        color: ''
    });
    const [subTitle, setSubTitle] = useState({
        label: '',
        color: ''
    });
    const [backgroundColor, setBackgroundColor] = useState(null);
    const [deleteUrl, setDeleteUrl] = useState([]);
    // const [file, setFile] = useState(null);
    const [selectedLayout, setSelectedLayout] = useState(null);
    const [location, setLocation] = useState(null);
    const [fileContent, setFileContent] = useState({});
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const onFileRemove = (file) => {
        setDeleteUrl(preState => [...preState, file.url.replace(apiConfig.baseImageUrl, "")]);
    }
    const [images, setImages] = useState([]);
    const [banner, setBanner] = useState({
        background: "#000000",
        items: []
    })

    useEffect(() => {
        if (mobTheme) {
            const { landingPage } = mobTheme;
            if (landingPage) {
                const { content: dContent, banner: dBanner, backgroundColor: DBackgroundColor, layout } = landingPage
                if (dContent) {
                    const { mainTitle: dMainTitle, subTitle: dSubTitle, location: dLocation } = dContent;
                    setMainTitle(dMainTitle);
                    setSubTitle(dSubTitle);
                    setBackgroundColor(DBackgroundColor);
                    setSelectedLayout(layout);
                    setLocation(dLocation);
                }

                if (dBanner) {
                    if (dBanner.background) {
                        setBanner({ ...banner, background: dBanner.background });
                    }
                    if (dBanner.items && dBanner.items.length > 0) {
                        let updateImages = [];
                        let newObj = {};
                        for (let i in dBanner.items) {
                            updateImages.push({
                                uid: i,
                                title: dBanner.items[i].title,
                                description: dBanner.items[i].description,
                                name: dBanner.items[i].title,
                                type: dBanner.items[i].type,
                                status: 'done',
                                url: apiConfig.baseImageUrl + dBanner.items[i].url
                            });
                            newObj[i] = {
                                title: dBanner.items[i].title,
                                desc: dBanner.items[i].description
                            };
                        }
                        setFileContent({
                            ...fileContent,
                            ...newObj
                        });
                        setImages(updateImages);
                    }
                }

            }
        }
        //eslint-disable-next-line
    }, [mobTheme])

    const onChangeFile = (data) => {
        let items = [];
        if (data.fileList && data.fileList.length > 0) {
            for (let i in data.fileList) {
                let title = "";
                let desc = "";
                if (fileContent[data.fileList[i].uid]) {
                    title = fileContent[data.fileList[i].uid].title;
                    desc = fileContent[data.fileList[i].uid].desc;
                }
                const item = {
                    description: desc,
                    name: data.fileList[i].name,
                    status: data.fileList[i].status === "done" ? data.fileList[i].status : "new",
                    title: title,
                    uid: data.fileList[i].uid,
                    url: data.fileList[i].url,
                    fileExt: data.fileList[i].fileExt,
                    type: data.fileList[i].type,
                    fileObj: data.fileList[i].fileObj || data.fileList[i]
                }
                items.push(item);
            }
            setBanner({ ...banner, items });
        }
        if (data.selectedFileId) {
            onSelectedFile(data.selectedFileId)
        }
    }
    useEffect(() => {
        onChangeFile({ fileList: banner.items });
        //eslint-disable-next-line
    }, [fileContent]);

    const onSelectedFile = (uid) => {
        setTitle("");
        setDescription("");
        setSelectedFileId(uid);
        if (!fileContent[uid]) {
            const newObj = {};
            newObj[uid] = {
                title: "",
                desc: ""
            };
            setFileContent({
                ...fileContent,
                ...newObj
            })
        } else {
            setTitle(fileContent[uid].title);
            setDescription(fileContent[uid].desc);
        }
    }

    useEffect(() => {
        if (selectedFileId && fileContent[selectedFileId]) {
            const updateObj = {
                title: title,
                desc: description
            }
            setFileContent({ ...fileContent, [selectedFileId]: updateObj });
        }
        //eslint-disable-next-line
    }, [title, description]);

    useEffect(() => {
        if (isSave) {
            let payload = {
                layout: selectedLayout,
                banner,
                backgroundColor,
                content: {
                    mainTitle,
                    subTitle
                },
                location
            };
            if (deleteUrl && deleteUrl.length > 0) {
                payload['deleteUrl'] = deleteUrl;
            }
            onSave("Landing", payload);
        }
        //eslint-disable-next-line
    }, [isSave])

    return <div className="content" style={{ flexDirection: 'row' }}>
        <div className="container" style={{ flexGrow: 1, padding: 0 }}>
            <div className='home-page'>
                <div className="section" style={{ flexGrow: 1 }}>
                    <div style={{ display: 'flex', flexGrow: 1, alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 30 }}>
                        <div style={{ color: '#121212', fontSize: 15, marginBottom: 4 }}>Upload banner images with content</div>
                        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', marginTop: 12 }}>
                            <CustomFileUploader onRemoveHandle={onFileRemove} onChange={onChangeFile} multiple={true} defaultFiles={images} uploadIcon={UploadSvg} uploadIconStyle={{ height: 40, objectFit: 'cover' }}></CustomFileUploader>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 22 }}>
                            <div style={{ flexGrow: 1, fontSize: 13, color: '#808080', width: '40%' }}>
                                Add banner title and short description to display
                            </div>
                            <div style={{ flexGrow: 1, width: '60%' }}>
                                <div style={{ width: '70%', marginBottom: 17 }}>
                                    <div style={{
                                        fontSize: 15,
                                        color: '#808080'
                                    }}>Title</div>
                                    <Input style={{ width: 400 }} disabled={!selectedFileId} value={title} placeholder={"Enter Title"} onChange={(e) => setTitle(e.target.value)}></Input>

                                </div>
                                <div style={{ width: '70%' }}>
                                    <div style={{
                                        fontSize: 15,
                                        color: '#808080'
                                    }}>Description</div>
                                    <TextArea disabled={!selectedFileId} value={description} onChange={(e) => setDescription(e.target.value)} style={{ width: 400 }} autoSize={{ minRows: 4, maxRows: 6 }} showCount maxLength={300} placeholder="Enter short description"></TextArea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section" style={{ display: 'flex', width: '100%' }}>
                    <div className="desc" style={{ width: '40%' }}>
                        <div className="title">Background Color</div>
                        <div style={{ flexGrow: 1 }} className="subtitle" > Set your background colour</div>
                    </div>
                    <div style={{
                        flexDirection: 'column',
                    }}>
                        <ColorPalette defaultColor={backgroundColor} onColorChange={(color) => {
                            setBackgroundColor(color)
                        }}></ColorPalette>
                    </div>
                </div>
                <div className="section" style={{ display: 'flex', width: '100%' }}>
                    <div className="desc" style={{ width: '40%' }}>
                        <div className="title">Title</div>
                        <div style={{ flexGrow: 1 }} className="subtitle" >Set your landing screen title here</div>
                    </div>
                    <div style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <div style={{ flexGrow: 1, marginBottom: 20 }}>
                            <Input style={{ width: 400 }} value={mainTitle.label} defaultValue={mainTitle.label} onChange={(e) => setMainTitle({ ...mainTitle, label: e.target.value })} className="input" placeholder="Enter title here" />
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <ColorPalette label={"Set title color"} defaultColor={mainTitle.color} onColorChange={(color) => {
                                setMainTitle({ ...mainTitle, color: color })
                            }}></ColorPalette>
                        </div>
                    </div>
                </div>
                <div className="section" style={{ display: 'flex', width: '100%' }}>
                    <div className="desc" style={{ width: '40%' }}>
                        <div className="title">Sub Title</div>
                        <div style={{ flexGrow: 1 }} className="subtitle" >Set your landing screen subtitle here</div>
                    </div>
                    <div style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <div style={{ flexGrow: 1, marginBottom: 20 }}>
                            <Input style={{ width: 400 }} value={subTitle.label} defaultValue={subTitle.label} onChange={(e) => setSubTitle({ ...subTitle, label: e.target.value })} className="input" placeholder="Enter subtitle here" />
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <ColorPalette label={"Set sub title color"} defaultColor={subTitle.color} onColorChange={(color) => {
                                setSubTitle({ ...subTitle, color: color })
                            }}></ColorPalette>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Landing;