import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlackCircleWithTickSvg from "../../../assets/images/blackCircleWithTickSvg.svg";
import { updateSubscription, getSubscriptionPlans, onlinePaymentFailure, onlinePaymentSuccess } from "../../../actions/setupStoreActions";
import { useDispatch, useSelector } from "react-redux";

const Container = styled.div`
    color: #121212;
    overflow-y: auto;
    width: 100%;
    display: flex;
    justify-content: center;
`;
const Section = styled.div`
    display: flex;    
    flex-direction: column;
    height: auto;
`;
const Title = styled.div`
    font-size: 40px;
    font-weight: 600;
    color: #121212;
    margin-bottom: 10px;
`;
const Desc = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: #474747;
    margin-bottom: 40px;
`;
const Subtitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #2D3748;
    margin-bottom: 24px;
`;
const Features = styled.div`
    color:#2D3748;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    .item {
        display: flex;
        gap: 7px;
        margin-bottom: 14px;
    }
`;
const Divider = styled.div`
    background: #C6C6C6C4;
    height: 1px;
    width: 100%;
    margin: 24px 0px;
`;
const Fee = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #2D3748
`;
const More = styled.a`
    font-size: 14px;
    font-weight: 600;
    margin-left: 4px;
    color: #2D3748;
`;
const Plan = styled.div`
    width: 434px;
    height: 110px;
    border-radius: 12px;
    border: 1px solid #A0A7B142;
    box-shadow: 0px 20px 25px -5px #0000001A;
    background: #fff;
    padding: 14px 20px;
`;
const PName = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-left: 12px;
`;
const Price = styled.div`
    color: #171923;
    font-size: 36px;
    font-weight: 600;
    margin-right: 16px;
`;
const PriceMonth = styled.div`
    font-size: 20px;
    color: #171923;
    font-weight: 600;
`;
const Transaction = styled.div`
    background: #FFF1E2;
    font-size: 14px;
    color:#121212;
    font-weight: 500;
    padding: 2px 10px;
    border-radius: 4px;
`;
const UnselectPlanRadio = styled.div`
    border: 3px solid #C4C4C4;
    width: 26px;
    height: 26px;
    border-radius: 50px;
`;
const SelectPlanRadio = styled.div`
    border: 3px solid #121212;
    width: 26px;
    height: 26px;
    padding: 3px;
    border-radius: 50px;
    div {
        background: #121212;
        height: 100%;
        width: 100%;
        border-radius: 50px;
    }
`;

const ChoosePlan = (props: any) => {
    const WindowObj: any = window;
    const dispatch: any = useDispatch();
    const [subscription, setSubscription] = useState(null);
    const { setupStoreDetails } = useSelector((state: any) => state.ui);
    const [selectedPlan, setSelectedPlan] = useState('growth');
    const [plans, setPlans] = useState(null);
    useEffect(() => {
        loadSubscriptionPlans();
        //eslint-disable-next-line
    }, []);
    const loadSubscriptionPlans = async () => {
        const resp = await dispatch(getSubscriptionPlans());
        if (resp?.plans) {
            setPlans(resp.plans);
        }
    }
    const onSelectedPlan = async () => {
        if (selectedPlan) {
            let payload = {
                planId: selectedPlan,
                trial: false,
            }
            const resp = await dispatch(updateSubscription(payload));
            if (resp?.hostedPage) {
                setSubscription(resp);
            }
        }
    }
    useEffect(() => {
        if (setupStoreDetails) {
            const { plan } = setupStoreDetails;
            if (plan) {
                const {
                    name,
                } = plan;
                setSelectedPlan(name);
            }
        }
    }, [setupStoreDetails]);
    useEffect(() => {
        if (subscription?.hostedPage) {
            payNow(subscription.hostedPage)
        }
        //eslint-disable-next-line
    }, [subscription]);
    const payNow = (hostedPage) => {
        var cbInstance = WindowObj.Chargebee.init({
            site: WindowObj.chargebee_site_name
        })
        cbInstance.openCheckout({
            hostedPage: function () {
                return new Promise((resolve, reject) => resolve(hostedPage));
            },
            success: (hostedPageId) => {
                onPaymentSuccess(hostedPageId)
            },
            failure: (hostedPageId) => {
                onPaymentFailure(hostedPageId)
            },
        });
    }

    const onPaymentSuccess = async (hostedPageId) => {
        const data = {
            "paymentGw": "chargebee",
            "cbResponse": {
                "hostedPageId": hostedPageId,
                "subscriptionId": subscription.paymentSubscriptionId
            }
        };
        const apiResp: any = await dispatch(onlinePaymentSuccess(data));
        if (apiResp?.subscriptionStatus && typeof props.successCallback === 'function') {
            props.successCallback();
        }
    }
    const onPaymentFailure = async (hostedPageId) => {
        const data = {
            "paymentGw": "chargebee",
            "cbResponse": {
                "hostedPageId": hostedPageId,
                "subscriptionId": subscription.paymentSubscriptionId
            }
        };
        const apiResp: any = await dispatch(onlinePaymentFailure(data));
        console.log({ apiResp })
    }
    return <div className="setup-store" style={{ background: 'none', zIndex: 1 }}>
        <div className="content" style={{ marginTop: 0 }}>
            <Container style={{ height: '100%' }}>
                {plans && plans.length > 0 && <Section style={{ width: 500, marginRight: 130 }}>
                    <Title>Simple, transparent Pricing</Title>
                    <Desc>Pricing plans for business at every stage of growth.</Desc>
                    <Subtitle>What's included in your subscription</Subtitle>
                    <Section>
                        <Features>
                            <Section>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Mobile App (Android / iOS)</div>
                                </div>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Free SSL Certificate</div>
                                </div>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Dashboard Analytics</div>
                                </div>
                                <div className="item" style={{ marginBottom: 0 }}>
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>SEO Ready</div>
                                </div>
                            </Section>
                            <Section>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Lexi AI</div>
                                </div>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Online Store</div>
                                </div>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Custom Domains</div>
                                </div>
                                <div className="item" style={{ marginBottom: 0 }}>
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Online Payment</div>
                                </div>
                            </Section>
                        </Features>
                    </Section>
                    <Section><Divider /></Section>
                    <Section style={{ flexDirection: 'row' }}>
                        <Fee>Unlimited Access. Regular Updates. No Hidden Fees.</Fee>
                        <More href='https://www.shopletzy.com/pricing/' target='_blank'>See more feature list</More>
                    </Section>
                </Section>}
                {plans && plans.length > 0 && <Section style={{ gap: 20, marginTop: 10 }}>
                    {plans.map((plan: any) => {
                        return <Plan key={plan.id} onClick={() => { setSelectedPlan(plan.id) }} style={{ cursor: selectedPlan === plan.id ? 'default' : 'pointer' }}>
                            <Section style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Section style={{ flexDirection: 'row', }}>
                                    {selectedPlan === plan.id ? <SelectPlanRadio>
                                        <div></div>
                                    </SelectPlanRadio> : <UnselectPlanRadio></UnselectPlanRadio>}
                                    <PName>{plan.name}</PName>
                                </Section>
                                <Section>
                                    <Section style={{ flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 6, alignItems: 'baseline' }}>
                                        <Price>₹ {plan.planFee / 100}</Price>
                                        <PriceMonth>/mo</PriceMonth>
                                    </Section>
                                    <Transaction>{`+ ${plan.transactionFeePct}% transaction fee on every order `}</Transaction>
                                </Section>
                            </Section>
                        </Plan>
                    })}
                    <Section style={{ justifyContent: 'center', alignItems: 'center', marginTop: 18 }}>
                        <button onClick={onSelectedPlan} style={{ background: "#121212", color: '#fff' }} className="sp_btn getStared">
                            Subscribe
                        </button>
                    </Section>
                </Section>}
            </Container>
        </div>
    </div>
}

export default ChoosePlan;