import { ArrowLeftOutlined } from "@ant-design/icons";
import { Drawer, Input, Select } from "antd";
import InputValidation from '../../../common/InputValidation';
import React, { useEffect, useState } from "react";
import "../../../../../assets/sass/dsk/holidays.sass";
import { isNotNullOrUndefinedString } from '../../../../../helpers/util';
import dayjs from 'dayjs';

const emptyError = {
    name: {
        message: "",
        type: ""
    },
    date: {
        message: "",
        type: ""
    }
};

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const monthKeys = {
    "January": "01", "February": "02", "March": "03", "April": "04", "May": "05", "June": "06",
    "July": "07", "August": "08", "September": "09", "October": "10", "November": "11", "December": "12"
}

const dates = Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, "0"));

const { Option } = Select;

const Add = (props: any) => {
    const [holidayName, setHolidayName] = useState(null);
    const [dateList, setDateList] = useState(dates);
    const [date, setDate] = useState(null);
    const [month, setMonth] = useState(null);
    const [error, setError] = useState(JSON.parse(JSON.stringify(emptyError)));

    useEffect(() => {
        const selectedMonth = monthKeys[month]
        if (selectedMonth === "02") {
            const list = Array.from({ length: 28 }, (_, i) => (i + 1).toString().padStart(2, "0"))
            if (new Date(dayjs().year(), 1, 29).getDate() === 29)
                list.push("29")
            setDateList(list)
        } else if ((selectedMonth === "01") || (selectedMonth === "03") || (selectedMonth === "05") || (selectedMonth === "07") || (selectedMonth === "08") ||
            (selectedMonth === "10") || (selectedMonth === "12")) {
            setDateList(dates);
        } else if ((selectedMonth === "04") || (selectedMonth === "06") || (selectedMonth === "09") || (selectedMonth === "11")) {
            const d = [...dates]
            d.pop()
            setDateList(d)
        }
    }, [month])

    useEffect(() => {
        if (date && !dateList.includes(date))
            setDate(dates[0]);
        //eslint-disable-next-line
    }, [dateList])


    const onSubmit = async () => {
        let errorExists = false
        if (!isNotNullOrUndefinedString(holidayName)) {
            error.name.message = "Missing holiday name";
            error.name.type = "error";
            errorExists = true;
        } else {
            error.name.message = "";
            error.name.type = "";
        }
        if (!isNotNullOrUndefinedString(date) || !isNotNullOrUndefinedString(month)) {
            error.date.message = "Missing holiday date";
            error.date.type = "error";
            errorExists = true;
        } else {
            error.date.message = "";
            error.date.type = "";
        }
        setError((prevState) => ({ ...prevState, ...error }));
        if (!errorExists) {
            onClose({ date: monthKeys[month] + "-" + date, name: holidayName, dateString: date + ", " + month, dateKey: dayjs().format('YYYY') + "-" + monthKeys[month] + "-" + date })
        }
    }

    const onClose = (holiday: any) => {
        props.onClose(holiday);
        setError(JSON.parse(JSON.stringify(emptyError)));
        setHolidayName(null);
        setDate(null);
        setMonth(null);
        setDateList(dates);
    }

    return <Drawer
        width={470}
        maskClosable={false}
        closable={false}
        onClose={() => onClose(null)}
        className="add_holiday"
        open={props.isVisible}
        footer={<div className="footer">
            <div className="sp_btn" onClick={() => onClose(null)} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
            <div className="sp_btn" onClick={onSubmit} style={{ width: 120, fontSize: 14 }}>Add</div>
        </div>}>
        <div className="header">
            <ArrowLeftOutlined className="arrow" onClick={() => onClose(null)}></ArrowLeftOutlined>
            <div className="title">Add new holiday</div>
        </div>
        <div className="content">
            <div className="group">
                <div className="label">Holiday Name *</div>
                <InputValidation type={error?.name?.type} message={error?.name?.message}>
                    <Input type="text" name="name" value={holidayName} defaultValue={holidayName} placeholder="Holiday name"
                        onChange={(e) => setHolidayName(e.target.value)}
                    />
                </InputValidation>
            </div>
            <div className="group">
                <div className="label">Holiday Date *</div>
                <InputValidation type={error?.date?.type} message={error?.date?.message}>
                    <div className="date">
                        <Select
                            className="select"
                            style={{ width: "150px" }}
                            size="middle"
                            placeholder="Date"
                            value={date}
                            onChange={(m) => { setDate(m) }}>
                            {dateList?.map((item) => {
                                return <Option key={item} style={{ paddingLeft: '16px' }} value={item}>
                                    {item}
                                </Option>
                            })}
                        </Select>
                        <Select
                            className="select"
                            size="middle"
                            placeholder="Select month"
                            value={month}
                            onChange={(m) => { setMonth(m) }}>
                            {months?.map((item) => {
                                return <Option key={item} style={{ paddingLeft: '16px' }} value={item}>
                                    {item}
                                </Option>
                            })}
                        </Select>
                    </div>
                </InputValidation>
            </div>
        </div>
    </Drawer >
}

export default Add;