import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Drawer, Input, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import { isNotNullOrUndefinedString } from '../../../../../helpers/util';
import InputValidation from '../../../common/InputValidation';
import insert from 'markdown-it-ins'

const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    highlight(str, lang) { },
}).use(insert);

const FAQCategory = {
    createdAt: null,
    isPublished: null,
    mrId: null,
    name: null,
    questions: null,
    updatedAt: null,
    id: null
};

const FAQQuestion = {
    answer: "",
    createdAt: null,
    isPublished: null,
    question: null,
    updatedAt: null,
    id: null
};

const EditorPlugins = [
    'header',
    'font-bold',
    'font-italic',
    'font-underline',
    'font-strikethrough',
    'list-unordered',
    'list-ordered',
    'block-quote',
    'block-wrap',
    'block-code-inline',
    'block-code-block',
    'table',
    'image',
    'link',
    'tab-insert'
]

const Save = (props: any) => {

    const [error, setError] = useState(FAQQuestion);
    const [faqCategory, setFAQCategory] = useState(FAQCategory);
    const [faqQuestion, setFAQQuestion] = useState(FAQQuestion);
    const [isEdit, setEdit] = useState(true);

    useEffect(() => {
        if (props.selectedFAQCategory) {
            setFAQCategory(props.selectedFAQCategory);
        }
    }, [props.selectedFAQCategory]);

    const handleEditorChange = ({ html, text }) => {
        setFAQQuestion({ ...faqQuestion, answer: text })
    }

    useEffect(() => {
        if (props.selectedFAQQuestion && props.selectedFAQQuestion.id) {
            setFAQQuestion(props.selectedFAQQuestion);
            setEdit(false);
        } else {
            setEdit(true);
            setFAQQuestion(FAQQuestion);
        }
    }, [props.selectedFAQQuestion]);

    const onHandleSubmit = async () => {
        let errorInfo = Object.create(FAQQuestion);
        setError({ ...error, ...errorInfo });
        if (!isNotNullOrUndefinedString(faqQuestion.question)) {
            errorInfo.question = "Enter the faq question";
        } else {
            errorInfo.question = null;
        }
        if (!isNotNullOrUndefinedString(faqQuestion.answer)) {
            errorInfo.answer = "Enter the faq answer";
        } else {
            errorInfo.answer = null;
        }
        setError({ ...error, ...errorInfo });
        if (!errorInfo.question && !errorInfo.answer) {
            let payload = {
                question: faqQuestion.question,
                answer: faqQuestion.answer,
                questionId: faqQuestion.id,
                faqId: faqCategory.id,
            }
            props.onSave(payload);
        }
    }

    const onClose = () => {
        props.onClose();
        setFAQCategory(FAQCategory);
        setFAQQuestion(FAQQuestion);
    }

    return (
        <Drawer
            width={940}
            closable={false}
            onClose={onClose}
            footer={
                <div className='footer'>
                    <button onClick={props.onClose} className="sp_btn cancel" style={{ marginRight: 16 }}>Cancel</button>
                    {!isEdit && <button onClick={() => { setEdit(true) }} className="sp_btn edit">Edit</button>}
                    {isEdit && <button onClick={onHandleSubmit} className="sp_btn save">{faqQuestion && faqQuestion.id ? "Update" : "Add"}</button>}
                </div>
            }
            className="faq-save"
            visible={props.isVisible}>
            <Row >
                <Col span={24} className="header">
                    <ArrowLeftOutlined className="back" onClick={onClose}></ArrowLeftOutlined>
                    <div className="title">{faqQuestion && faqQuestion.id ? 'UPDATE QNA' : 'ADD QNA'}</div>
                </Col>
                <Col span={24} className="content" style={{ height: window.innerHeight - 100 }}>
                    <div className="group" style={{ color: '#FFF', marginBottom: 0 }}>
                        <div className="label" style={{ fontWeight: "bold", display: "flex" }}>FAQ: <div style={{ marginLeft: 10 }}>{faqCategory.name}</div></div>
                    </div>
                    <div className="group">
                        <div className="label">Question *</div>
                        <InputValidation type={error.question ? "error" : null} message={error.question}>
                            <Input disabled={!isEdit} type="text" name="question" value={faqQuestion.question} placeholder="Enter the FAQ Question"
                                onChange={(e) => setFAQQuestion({ ...faqQuestion, question: e.target.value })}
                            />
                        </InputValidation>
                    </div>
                    <div className="group">
                        <div className="label">Answer *</div>
                        <InputValidation type={error.answer ? "error" : null} message={error.answer}>
                            <MdEditor
                                readOnly={!isEdit}
                                plugins={EditorPlugins}
                                defaultValue={faqQuestion.answer}
                                value={faqQuestion.answer}
                                placeholder="Enter the FAQ Answer"
                                style={{ height: "500px" }}
                                renderHTML={(text) => mdParser.render(text)}
                                onChange={handleEditorChange}
                            />
                            {/* <TextArea
                                name="answer"
                                disabled={!isEdit}
                                onChange={(e) => setFAQQuestion({ ...faqQuestion, answer: e.target.value })}
                                value={faqQuestion.answer}
                                placeholder="Enter the FAQ Answer"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            /> */}
                        </InputValidation>
                    </div>
                    {/* <div className="group" >
                        <div className="label">Preview</div>
                        <div style={{ color: "#FFF" }}>
                        </div>
                    </div> */}
                </Col>
            </Row>
        </Drawer>
    );
}

export default connect(null, {})(Save);