import React from "react";
import { ResponsiveBar } from '@nivo/bar';

const BarVz = (props: any) => {
    const colorCodes = ['#7828dc', '#003f5c', '#bc5090', '#ffa600', '#58508d', '#ff6361'];
    return (
        props.data && props.data.length > 0 ? <ResponsiveBar
            data={props.data}
            keys={[props.yKey]}
            indexBy={props.xKey}
            margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
            padding={0.3}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            labelTextColor="#FFF"
            borderRadius={1}
            axisBottom={props.axisBottom}
            indexScale={{ type: 'band', round: true }}
            colors={colorCodes[3]}
            barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in country: " + e.indexValue }}
        />
            : <div>No data found</div>
    );
}

export default BarVz;