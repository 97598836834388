import type from "../types/actionTypes";

const initialState = {
};

export default function employeeReducers(state = initialState, action) {
    switch (action.type) {
        case type.GET_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: action.data.employees
            }
        case type.GET_EMPLOYEES_ERROR:
            return {
                ...state,
                message: action.error
            }
        default:
            return state;
    }
}