import React from 'react';
import '../../../assets/sass/mob/header.sass';
import logo from "../../../assets/images/shopletzy_logo.svg";
import { signout } from "../../../actions/commonAction";
import { useDispatch } from 'react-redux';
import { useNav } from '../../../helpers/customHook';

const Header = (props: any) => {
    const { redirectPath } = props;
    const dispatch = useDispatch();
    const navigateTo = useNav();
    const logout = () => {
        localStorage.setItem("userInfo", "");
        dispatch(signout());
        navigateTo("/signin");
    }
    const onCreateNewStore = () => {
        navigateTo("/setupstore/createstore")
    }
    return (
        <div className="sp-header" style={{ zIndex: 9999 }}>
            <div className='group'>
                <div className="logo" onClick={() => navigateTo(redirectPath ? redirectPath : -1)}>
                    <img alt='Logo' src={logo} />
                    <label>Shopletzy</label>
                </div>
                {props.enableLogout && <div className='logout' onClick={logout}>Logout</div>}
            </div>
            {props.enableCreateStore && <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div data-tut="reactour__select-store" onClick={onCreateNewStore} className="create-store">
                    Create New Store
                </div>
            </div>}
        </div>
    );
}

export default Header;