import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import DeviceWrap from "./DeviceWrap";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from "./store";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ConfigProvider } from 'antd';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<>
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'AvenirNext',
            },
          }}
        >
          <DeviceWrap />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </I18nextProvider>
</>);