import { getLocale } from '../actions/uiAction';
import { store } from '../store';

export function getL10N(data, key, specLang) {
    let selectedLang = getLocale();
    if (specLang) {
        selectedLang = specLang;
    };
    let i10n = null;
    let isDefault = false;
    if (selectedLang && selectedLang.key) {
        if (data && data.l10n) {
            if (data.l10n[selectedLang.key] && data.l10n[selectedLang.key][key]) {
                i10n = data.l10n[selectedLang.key][key];
            }
        }
    }
    isDefault = i10n == null && selectedLang.key !== "en" ? true : false;
    if (i10n == null && data[key]) {
        i10n = data[key];
    }
    return { i10n, isDefault };
}

export function getOutletDetail(data, ouId, key) {
    let outletDetail = null;
    if (data && data.outlets) {
        for (let i in data.outlets) {
            if (data.outlets[i] && data.outlets[i].ouId && data.outlets[i].ouId === ouId) {
                if (data.outlets[i][key]) {
                    outletDetail = data.outlets[i][key];
                }
            }
        }
    }
    return outletDetail;
}

export function loadOutletBasedProduct(record, key, selectedOutlets = null, format = null) {
    let value = null;
    if (!selectedOutlets) {
        selectedOutlets = store && store.getState().task.selectedOutlets ? store.getState().task.selectedOutlets : [];
    }
    if (selectedOutlets.length > 0 && record.outlets && record.outlets.length > 0) {
        for (let i in record.outlets) {
            if (record.outlets[i].ouId && selectedOutlets.indexOf(record.outlets[i].ouId) > -1) {
                value = record.outlets[i][key];
            }
        }
    }
    if (value && format) {
        if (format === 'amount') {
            value = value.toLocaleString('en-IN');
        }
    }
    return value;
}

