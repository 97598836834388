import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { Row, Col, Input } from "antd";
import { exportCustomers, getCustomers } from "../../../actions/customerActions";
import { isDark, stringToHslColor } from "../../../helpers/util";
import { clearCustomerList } from "../../../actions/commonAction";
import SortUpSvg from "../../../assets/images/sort_up.svg";
import SortDownSvg from "../../../assets/images/sort_down.svg";
import "../../../assets/sass/dsk/customer.sass";
import SearchIcn from "../../../assets/images/search.svg";
import CartSvg from "../../../assets/images/shopping-cart.svg";
// import editIcn from '../../../assets/images/edit.png';
// import Save from './Save';
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../common/Loading";
import PopupConfirm from "../common/PopupConfirm";

let keyStrokesTimer = null;
const ListedCustomer = (props: any) => {
    const windowHeight = window.innerHeight;
    // const hasMore = true;
    // const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [deliveryAreaSearch, setDeliveryAreaSearch] = useState("");
    const [emailSearch, setEmailSearch] = useState("");
    const [phoneSearch, setPhoneSearch] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [sortSince, setSortSince] = useState("desc");
    const [sortCount, setSortCount] = useState(null);
    const [sortLast, setSortLast] = useState(null);
    const [customerSearch, setCustomerSearch] = useState("");
    const [loadInitialData, setLoadInitialData] = useState(false);
    const { customers, totalCount } = useSelector((state: any) => state.customer);

    useEffect(() => {
        props.clearCustomerList();
        // eslint-disable-next-line
    }, []);

    const getCustomers = async (isFreshList = false) => {
        let params: any = [
            { itemCount: true },
            // { lastPageItemTime: null },
            // { limit: 20 }
        ];
        setLoadInitialData(true);
        if (isFreshList) {
            setHasMore(true);
        }
        setLoading(true);
        const resp = await props.getCustomers(isFreshList, params);
        if (resp) {
            setHasMore(false);
        }
        // if (!resp.customers || resp.customers.length === 0) {
        //     setHasMore(false);
        // }
        setLoading(false);
    };

    const getCustomerSearch = async () => {
        let params: any = [
            { itemCount: true },
            // { lastPageItemTime: null },
            // { limit: 20 }
        ];
        let sort = "";
        if (sortCount) {
            sort = sort.length > 0 ? sort + `/orderCount ${sortCount}` : `orderCount ${sortCount}`;
        }
        if (sortLast) {
            sort = sort.length > 0 ? sort + `/lastOrder ${sortLast}` : `lastOrder ${sortLast}`;
        }
        if (sortSince) {
            sort = sort.length > 0 ? sort + `/customerSince ${sortSince}` : `customerSince ${sortSince}`;
        }
        if (sort.length > 0) {
            params.push({
                sort
            });
        }
        if (customerSearch) {
            params.push({
                filter: `name:${customerSearch}`
            });
        }
        if (emailSearch) {
            params.push({
                filter: `email:${emailSearch}`
            });
        }
        if (deliveryAreaSearch) {
            params.push({
                filter: `area:${deliveryAreaSearch}`
            });
        }
        if (phoneSearch) {
            params.push({
                filter: `mobile:${phoneSearch}`
            });
        }
        if (params.length > 0) {
            keyStrokesTimer = setTimeout(async () => {
                setLoading(true);
                await props.getCustomers(true, params);
                setLoading(false);
            }, 500);
        } else {
            getCustomers(true);
        }
    };

    useEffect(() => {
        if (keyStrokesTimer) {
            clearTimeout(keyStrokesTimer);
        }
        if (loadInitialData) {
            getCustomerSearch();
        }
        //eslint-disable-next-line
    }, [customerSearch, emailSearch, phoneSearch, deliveryAreaSearch, sortSince, sortLast, sortCount]);


    useEffect(() => {
        //     if (customerRefVisible) {
        getCustomers(true);
        //     }
        //eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     if (showOrders && selectedCustomer) {
    //         props.history.push('/customer/orders')
    //     }
    // }, [showOrders, selectedCustomer])

    const fetchNext = () => {
        if (hasMore) {
            getCustomers();
        }
    };

    const getInitial = (record) => {
        let initial = "";
        const splitName = record.fullName?.split(" ");
        if (splitName.length > 1) {
            initial = record.fullName?.split(" ")[0].charAt(0) + record.fullName?.split(" ")[1]?.charAt(0);
        } else if (record.fullName.length > 0) {
            initial = record.fullName.trim().charAt(0).toUpperCase();
        }
        return initial
    }

    const onExport = async () => {
        const resp = await props.exportCustomers();
        if (resp?.submitted) {
            setPopupConfirm({
                title: `Exporting Customers`,
                renderContent: () => { return <div>We're preparing your customer data for download. <span style={{ 'color': '#21c082', 'fontWeight': '600' }}>We'll send you an email with a link to download the file once it's ready.</span><br /><br /> This may take a few moments depending on the size of your customer database. Thank you for your patience!</div > },
                yes: {
                    label: "Okay",
                    callback: async () => {
                        setPopupConfirm(null);
                    }
                }
            });
        }
    }

    return (
        <Row className="customer">
            {isLoading && <Loading background="#00000070"></Loading>}
            <Col className="list" style={{ height: windowHeight - 110, overflow: 'hidden' }}>
                <div className="action-bar">
                    <div className="header-title">You've {totalCount} Customers in total</div>
                    <div className="actions" style={{ marginRight: 16 }}>
                        <button className="sp_btn btn" onClick={onExport} >Export</button>
                    </div>
                </div>
                <div className="content">
                    <Row className="header">
                        <Col span={4}>Name</Col>
                        <Col span={3}>Mobile No</Col>
                        <Col span={4}>Email</Col>
                        <Col span={3}>Delivery Area</Col>
                        <Col span={2}>Orders Count</Col>
                        <Col span={3}>Last Order</Col>
                        <Col span={3}>Customer Since</Col>
                        <Col span={4}></Col>
                    </Row>
                    <Row className="filters">
                        <Col span={4}>
                            <Input
                                className="filter"
                                name="userSearch"
                                onChange={(event) => setCustomerSearch(event.target.value)}
                                placeholder="User Name"
                                suffix={<img alt="Search Icon" src={SearchIcn} />}
                            />
                        </Col>
                        <Col span={3}>
                            <Input
                                className="filter"
                                placeholder="Mobile Number"
                                name="phoneSearch"
                                onChange={(e) => setPhoneSearch(e.target.value)}
                                suffix={<img alt="Search Icon" src={SearchIcn} />}
                            />
                        </Col>
                        <Col span={4}>
                            <Input
                                className="filter"
                                placeholder="Email"
                                name="emailSearch"
                                onChange={(event) => setEmailSearch(event.target.value)}
                                suffix={<img alt="Search Icon" src={SearchIcn} />}
                            />
                        </Col>
                        <Col span={3}>
                            <Input
                                className="filter"
                                placeholder="Delivery Area"
                                name="deliveryAreaSearch"
                                onChange={(event) => setDeliveryAreaSearch(event.target.value)}
                                suffix={<img alt="Search Icon" src={SearchIcn} />}
                            />
                        </Col>
                        <Col span={2}>
                            <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                                <div
                                    style={{
                                        background: sortCount === "asc" ? "#d6e6f2" : "#FFF",
                                        border: sortCount === "asc" ? "1px solid #d6e6f2" : "1px solid #cacaca",
                                        marginRight: 20,
                                        cursor: "pointer",
                                        borderRadius: 5,
                                        height: 28,
                                        width: 28,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        setSortCount((prevSortCount) => {
                                            // Toggle sortCount state
                                            return prevSortCount === null ? 'asc' : null;
                                        });
                                    }}
                                >
                                    <img alt="Sort Down Icon" src={SortDownSvg} width={20} />
                                </div>
                                <div
                                    style={{
                                        background: sortCount === "desc" ? "#d6e6f2" : "#FFF",
                                        border: sortCount === "desc" ? "1px solid #d6e6f2" : "1px solid #cacaca",
                                        marginRight: 20,
                                        cursor: "pointer",
                                        borderRadius: 5,
                                        height: 28,
                                        width: 28,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        setSortCount((prevSortCount) => {
                                            // Toggle sortCount state
                                            return prevSortCount === null ? 'desc' : null;
                                        });
                                    }}
                                >
                                    <img alt="Sort Up Icon" src={SortUpSvg} width={20} />
                                </div>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                                <div
                                    style={{
                                        background: sortLast === "asc" ? "#d6e6f2" : "#FFF",
                                        border: sortLast === "asc" ? "1px solid #d6e6f2" : "1px solid #cacaca",
                                        marginRight: 20,
                                        cursor: "pointer",
                                        borderRadius: 5,
                                        height: 28,
                                        width: 28,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        setSortLast((prevSortCount) => {
                                            // Toggle sortCount state
                                            return prevSortCount === null ? 'asc' : null;
                                        });
                                    }}
                                >
                                    <img alt="Sort Down Icon" src={SortDownSvg} width={20} />
                                </div>
                                <div
                                    style={{
                                        background: sortLast === "desc" ? "#d6e6f2" : "#FFF",
                                        border: sortLast === "desc" ? "1px solid #d6e6f2" : "1px solid #cacaca",
                                        marginRight: 20,
                                        cursor: "pointer",
                                        borderRadius: 5,
                                        height: 28,
                                        width: 28,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        setSortLast((prevSortCount) => {
                                            // Toggle sortCount state
                                            return prevSortCount === null ? 'desc' : null;
                                        });
                                    }}
                                >
                                    <img alt="Sort Up Icon" src={SortUpSvg} width={20} />
                                </div>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                                <div
                                    style={{
                                        background: sortSince === "asc" ? "#d6e6f2" : "#FFF",
                                        border: sortSince === "asc" ? "1px solid #d6e6f2" : "1px solid #cacaca",
                                        marginRight: 20,
                                        cursor: "pointer",
                                        borderRadius: 5,
                                        height: 28,
                                        width: 28,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        setSortSince((prevSortCount) => {
                                            // Toggle sortCount state
                                            return prevSortCount === null ? 'asc' : null;
                                        });
                                    }}
                                >
                                    <img alt="Sort Down Icon" src={SortDownSvg} width={20} />
                                </div>
                                <div
                                    style={{
                                        background: sortSince === "desc" ? "#d6e6f2" : "#FFF",
                                        border: sortSince === "desc" ? "1px solid #d6e6f2" : "1px solid #cacaca",
                                        marginRight: 20,
                                        cursor: "pointer",
                                        borderRadius: 5,
                                        height: 28,
                                        width: 28,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        setSortSince((prevSortCount) => {
                                            // Toggle sortCount state
                                            return prevSortCount === null ? 'desc' : null;
                                        });
                                    }}
                                >
                                    <img alt="Sort Up Icon" src={SortUpSvg} width={20} />
                                </div>
                            </div>
                        </Col>
                        <Col span={4}></Col>
                    </Row>
                    <Row className="contains">
                        <Col span={24}>
                            <div id="scrollableDiv">
                                <InfiniteScroll
                                    dataLength={customers.length}
                                    hasMore={hasMore}
                                    height={windowHeight - 240}
                                    next={fetchNext}
                                    loader={null}
                                    // endMessage={
                                    //     <div
                                    //         style={{
                                    //             display: "flex",
                                    //             alignItems: "center",
                                    //             justifyContent: "center",
                                    //             height: 20,
                                    //         }}
                                    //     >
                                    //         Nothing more
                                    //     </div>
                                    // }
                                    scrollableTarget="scrollableDiv"
                                >
                                    {/* onClick={() => { setSelectedCustomer(record); setIsSaveCustomer(true); }} */}
                                    {customers &&
                                        customers.map((record, index) => {
                                            const initialLetters = getInitial(record);
                                            return (
                                                <Row
                                                    key={`${index}`}
                                                    className="item"
                                                    style={{
                                                        border:
                                                            customers.length - 1 <= index
                                                                ? "border: 1px solid #E7EEF7"
                                                                : "1px solid #E7EEF7",
                                                    }}
                                                >
                                                    <Col span={4}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    width: 30,
                                                                    height: 30,
                                                                    borderRadius: 30,
                                                                    background: stringToHslColor(
                                                                        record.fullName,
                                                                        60,
                                                                        40
                                                                    ),
                                                                    fontSize: 12,
                                                                    marginRight: 10,
                                                                    color: isDark(
                                                                        stringToHslColor(record.fullName, 60, 40)
                                                                    )
                                                                        ? "#FFF"
                                                                        : "#121212",
                                                                }}
                                                            >
                                                                {initialLetters}
                                                            </div>
                                                            <div className="row-ellipsis">{record.fullName}</div>
                                                        </div>
                                                    </Col>
                                                    <Col span={3}>
                                                        <div className="row-ellipsis">{record.mobileNo}</div>
                                                    </Col>
                                                    <Col span={4}>
                                                        <div className="row-ellipsis">{record.email}</div>
                                                    </Col>
                                                    <Col span={3}>
                                                        <div className="row-ellipsis" title={record.deliveryArea?.join(", ")}>
                                                            {record.deliveryArea?.join(", ")}
                                                        </div>
                                                    </Col>
                                                    <Col span={2}>
                                                        <div className="row-ellipsis">{record.ordersReceived}</div>
                                                    </Col>
                                                    <Col span={3}>
                                                        {record.lastOrderedAt && (
                                                            <div className="row-ellipsis">
                                                                {dayjs(record.lastOrderedAt).calendar(null, {
                                                                    sameDay: "[Today] h:mm A",
                                                                    nextDay: "[Tomorrow] h:mm A",
                                                                    nextWeek: "ddd h:mm A",
                                                                    lastDay: "[Yesterday] h:mm A",
                                                                    lastWeek: "[Last] dddd h:mm A",
                                                                    sameElse: "MMM D YYYY [at] h:mm A",
                                                                })}
                                                            </div>
                                                        )}
                                                    </Col>
                                                    <Col span={3}>
                                                        <div className="row-ellipsis">
                                                            {dayjs(record.createdAt).calendar(null, {
                                                                sameDay: "[Today] h:mm A",
                                                                nextDay: "[Tomorrow] h:mm A",
                                                                nextWeek: "ddd h:mm A",
                                                                lastDay: "[Yesterday] h:mm A",
                                                                lastWeek: "[Last] dddd h:mm A",
                                                                sameElse: "MMM D YYYY [at] h:mm A",
                                                            })}
                                                        </div>
                                                    </Col>
                                                    <Col span={1} style={{ display: "flex", justifyContent: "center" }}>
                                                        <Link className="view-details" to={`${record.id}/orders`}>
                                                            <div
                                                                // onClick={(e) => { e.stopPropagation(); setShowOrders(true); selectedCustomer(record) }}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    color: "#21c082",
                                                                    backgroundColor: "#21c08221",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "space-around",
                                                                    padding: "0px 4px",
                                                                    borderRadius: 5,
                                                                    width: 36,
                                                                    height: 36,
                                                                }}
                                                            >
                                                                <img alt="Cart Icon" src={CartSvg} />
                                                                {/* <div>Order Details</div> */}
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                </InfiniteScroll>
                                {/* <div ref={customerRef as React.LegacyRef<HTMLDivElement>} /> */}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 800, width: '100%', fontSize: 14, paddingTop: 10
            }}>Showing first 200 customers</Col>
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
            {/* <Save onClose={() => { setIsSaveCustomer(false); }} selectedCustomer={selectedCustomer} isVisible={isSaveCustomer} /> */}
        </Row>
    );
    // }
};

function mapStateToProps(state: any) {
    // console.log("User mapStateToProps: ", state);
    return {
        auth: state.auth,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getCustomers,
            exportCustomers,
            clearCustomerList
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListedCustomer);
