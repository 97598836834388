import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import '../../../assets/sass/dsk/menu.sass';
import { selectedTabMenu } from '../../../actions/uiAction';
import { useNav } from '../../../helpers/customHook';

const SubMenu = (props: any) => {
    const { title, menus, root, tab } = props;
    const navigateTo = useNav();
    const [selectedTabMenu, setSelectedTabMenu] = useState(null);
    useEffect(() => {
        setSelectedTabMenu(tab);
        //eslint-disable-next-line
    }, [tab]);
    const onSelectedTabMenu = (tab: string) => {
        setSelectedTabMenu(tab);
        navigateTo(`${root}/${tab}`);
        props.selectedTabMenu(tab);
    }
    return (
        <div className="header-menu-bar">
            {title && <div className="title">{title}</div>}
            <div className="items">
                {menus && menus.map((menu) =>
                    <div key={menu.value} className={selectedTabMenu === menu.value ? "label selected" : "label"} onClick={() => onSelectedTabMenu(menu.value)}>
                        {menu.label}
                        {selectedTabMenu === menu.value && <div className="underline"></div>}
                    </div>)}
            </div>
        </div>
    );
}

export default connect(null, { selectedTabMenu })(SubMenu);