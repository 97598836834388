import axios from 'axios';
import apiUrls from '../apiConfig';
import { store } from '../store';
import types from '../types/actionTypes';

const axiosInstance = axios.create({
  baseURL: apiUrls.baseUrl, // Replace with your API base URL
  timeout: 10000, // Request timeout in milliseconds
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    // Add other default headers here if needed
  },
});

// Request interceptor to add authorization token if available
axiosInstance.interceptors.request.use((config) => {
  if (store.getState()?.auth?.info?.token) {
    config.headers['Authorization'] = `Bearer ${store.getState().auth?.info?.token}`;
  }
  return config;
},
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle responses globally
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors globally
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error('Error response:', error.response.data.message);
      if (error?.response?.data) {
        return Promise.reject(error);
      }
    } else if (error.request) {
      // Request was made but no response received
      console.error('Error request:', error.request);
    } else {

      // Something happened in setting up the request
      console.error('Error message:', error.message);
    }
    return Promise.reject(error);
  }
);

const axiosWrapper = async (url: string, config: any = {}, option: any) => {
  try {
    const { dispatch, skipOption } = option;
    const selectedStore = store.getState().ui?.selectedStore;
    const { skipAuth = false, skipSubscriptionStatus = false } = skipOption || {};
    if (!skipAuth) {
      const { token } = store.getState().auth?.info;
      if (!skipAuth && !token) {
        dispatch({
          type: types.ERROR,
          data: {
            title: 'Session Expired',
            message: 'Your session has expired. Please login again.',
            code: 'SC10020'
          }
        })
        return;
      }
    }
    if (skipSubscriptionStatus || selectedStore?.subscriptionStatus === 'active' || selectedStore?.subscriptionStatus === 'trial' || selectedStore?.subscriptionStatus === 'pendingCancellation') {
      if (config?.method === 'POST') {
        const resp = await post(url, config.data, config);
        return resp;
      } else if (config?.method === 'DELETE') {
        const resp = await del(url, config);
        return resp;
      } else if (config?.method === 'PUT') {
        const resp = await put(url, config.data, config);
        return resp;
      } else {
        const resp = await get(url, config);
        return resp;
      }
    }
  } catch (error: any) {
    throw error;
  }
};


//  GET request
const get = async (url: string, config = {}) => {
  try {
    const response = await axiosInstance.get(url, config);
    return response;
  } catch (error: any) {
    throw error;
  }
};

//  POST request
const post = async (url: string, data: any, config = {}) => {
  try {
    const response = await axiosInstance.post(url, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

// DELETE request
const del = async (url: string, config = {}) => {
  try {
    const response = await axiosInstance.delete(url, config);
    return response;
  } catch (error) {
    throw error;
  }
};

// PUT request
const put = async (url: string, data: any, config = {}) => {
  try {
    const response = await axiosInstance.put(url, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export default axiosWrapper;
