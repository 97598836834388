import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ArrowLeftIcn from "../../../assets/images/arrow-left.svg";
import ToggleIcn from "../../../assets/images/settings/toggle.svg";
import RightArrowSvg from "../../../assets/images/arrow_ico.svg";
import FileUploadSvg from "../../../assets/images/img-upload.svg";
import PlaceholderSvg from "../../../assets/images/category_upload.svg";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Editor from "@monaco-editor/react";
import { Col, Input, Row, Select, Switch } from "antd";
import InputValidation from "../common/InputValidation";
import LexicalEditorComponent from "../common/editor";
import Loading from "../common/Loading";
import TextArea from "antd/lib/input/TextArea";
import PopupConfirm from "../common/PopupConfirm";
import { createPost, getBlogs, getPostById, updatePost } from "../../../actions/blogActions";
import { getUsers } from "../../../actions/userActions";
import TagComp from "../common/Tag";
import apiConfig from "../../../apiConfig";
import { onDeleteMedia, onUploadMedia } from "../../../actions/commonAction";
import { useNav } from "../../../helpers/customHook";
import { isNotNullOrUndefinedString } from "../../../helpers/util";
import { MediaAssets } from "../common/mediaAssets";

const { Option } = Select;
const errorInfo = {
    title: {
        message: "",
        type: "",
    },
    slug: {
        message: "",
        type: "",
    }
}
const CreatePost = () => {
    const WindowHeight = window.innerHeight;
    const { id } = useParams();
    const navigateTo = useNav();
    const dispatch = useDispatch();
    const [error, setError] = useState(errorInfo);
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [editDetail, setEditDetail] = useState(null);
    const [title, setTitle] = useState("");
    const [status, setStatus] = useState("draft");
    const [visibleType, setVisibleType] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [slug, setSlug] = useState("");
    const [lexical, setLexical] = useState(null);
    const [featured, setFeatured] = useState(false);
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [metaKeywords, setMetaKeywords] = useState([]);
    const [tags, setTags] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [users, setUsers] = useState([]);
    const [excerpt, setExcerpt] = useState("");
    const [codeInjectionHead, setCodeInjectionHead] = useState("");
    const [codeInjectionFoot, setCodeInjectionFoot] = useState("");
    const [customTemplate] = useState("");
    const [blogId, setBlogId] = useState("");
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [featuredImage, setFeaturedImage]: any = useState(null);
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        if (id) {
            getPostByIdDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPostByIdDetail = async () => {
        const resp = await getPostById(id)(dispatch);
        if (resp) {
            setEditDetail(resp);
        }
    }

    useEffect(() => {
        if (editDetail) {
            setTitle(editDetail?.title || "");
            setSlug(editDetail?.slug || "");
            setMetaTitle(editDetail?.metaTitle || "");
            setMetaDescription(editDetail?.metaDescription || "");
            if (editDetail?.metaKeywords) {
                setMetaKeywords(editDetail.metaKeywords.split(","));
            }
            setTags(editDetail?.tags || []);
            setLexical(JSON.parse(editDetail?.lexical));
            setFeatured(editDetail?.featured || false);
            setStatus(editDetail?.status || "draft");
            setExcerpt(editDetail.excerpt || "");
            setAuthors(editDetail.authors || []);
            setFeaturedImage({ status: "done", url: editDetail?.featureImage || "" });
            setBlogId(editDetail?.blogId);
            setCodeInjectionFoot(editDetail?.codeInjectionFoot || "");
            setCodeInjectionHead(editDetail?.codeInjectionHead || "");
        }
    }, [editDetail]);

    useEffect(() => {
        loadInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleType]);

    const onLexicalChange = (value: any) => {
        setLexical(value);
    }
    const loadInitialData = async () => {
        const respUsers: any = await getUsers()(dispatch);
        setUsers(respUsers);
        const respBlogs: any = await getBlogs()(dispatch);
        if (respBlogs?.blogs) {
            setBlogs(respBlogs.blogs);
        }
    }

    function base64EncodeUnicode(str: string) {
        // Create a TextEncoder instance
        const encoder = new TextEncoder();

        // Encode the string as raw binary data (Uint8Array)
        const data = encoder.encode(str);

        // Convert the binary data to base64
        return btoa(String.fromCharCode(...data));
    }

    const onSave = (statusCode) => {
        let isError = false;
        if (!isNotNullOrUndefinedString(title)) {
            errorInfo.title.type = "error";
            errorInfo.title.message = "Fill the page title";
            isError = true;
        } else {
            errorInfo.title.type = "";
            errorInfo.title.message = "";
        }
        if (slug.length < 4) {
            errorInfo.slug.type = "error";
            errorInfo.slug.message = "Slug minimum 4 characters";
            isError = true;
            setVisibleType('Post Settings');
        } else {
            errorInfo.slug.type = "";
            errorInfo.slug.message = "";
        }
        if (!isError) {
            setPopupConfirm({
                title: `${title} POST`,
                renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to {<span style={{ fontWeight: "bold" }}>{status}</span>} the <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>{title}</span>.</span></div > },
                yes: {
                    label: statusCode === "draft" && status === "published" ? "Unpublished" : statusCode === "published" && status === "draft" ? "Publish" : statusCode === "published" && status === "published" ? "update" : "Save",
                    callback: async () => {
                        setIsLoading(true);
                        setPopupConfirm(null);
                        let featureImage = "";
                        if (editDetail?.featureImage && featuredImage?.originFileObj) {
                            await onDeleteMedia({ filenames: [editDetail.featureImage] })(dispatch);
                        }
                        if (featuredImage && featuredImage?.originFileObj) {
                            let bodyFormData = new FormData();
                            bodyFormData.append('images', featuredImage.originFileObj);
                            bodyFormData.append('forUse', 'postFeaturedImage');
                            const uploadResp: any = await onUploadMedia(bodyFormData)(dispatch);
                            if (uploadResp && uploadResp.isUploaded) {
                                featureImage = uploadResp.images[0];
                            } else {
                                console.error("failed");
                            }
                        } else if (featuredImage && featuredImage?.url) {
                            featureImage = featuredImage.url;
                        }
                        let payload: any = {
                            "title": title,
                            "slug": slug,
                            "excerpt": excerpt,
                            "lexical": base64EncodeUnicode(JSON.stringify(lexical)),
                            "status": statusCode,
                            "featureImage": featureImage,
                            "featured": featured,
                            "metaTitle": metaTitle,
                            "metaDescription": metaDescription,
                            "metaKeywords": metaKeywords.join(",").trim(),
                            "type": "post",
                            "tags": tags,
                            "authors": authors,
                            "codeInjectionHead": codeInjectionHead,
                            "codeInjectionFoot": codeInjectionFoot,
                            "customTemplate": customTemplate
                        }
                        if (blogId) {
                            payload["blogId"] = blogId;
                        }
                        let resp = null;
                        if (editDetail) {
                            resp = await updatePost(editDetail.id, payload)(dispatch);
                        } else {
                            resp = await createPost(payload)(dispatch);
                        }
                        if (resp) {
                            navigateTo(`/${selectedStore.name.toLowerCase()}/blogs/post`);
                        }
                        setIsLoading(false);
                    }
                },
                no: {
                    label: "May be next time",
                    callback: () => {
                        setIsLoading(false);
                        setPopupConfirm(null);
                    }
                }
            });
        }
        setError((preState) => ({ ...preState, ...errorInfo }));
    }

    const onUploadFiles = (fileList, type, disableImageTransform) => {
        const uploadPromises = fileList.map(async (item) => {
            const formData = new FormData();
            formData.append('files', item.originFileObj);
            formData.append('forUse', 'mediaFiles');
            if (!disableImageTransform) {
                formData.append('imageTransform', 'w:500');
            }
            const resp = await onUploadMedia(formData)(dispatch);
            return resp;
        });
        Promise.all(uploadPromises)
            .then((resp: any) => {
                let urls = [];
                if (resp && resp.length > 0) {
                    urls = resp.map((r) => {
                        return r.files && r.files.length > 0 && r.files[0]
                    });
                    if (type === 'featured' && urls.length > 0) {
                        setFeaturedImage({
                            url: urls[0]
                        });
                    }
                }
            })
            .catch(error => {
                // Handle errors if any
                console.error("Error uploading files:", error);
                return { error: true }
            });
    }

    const loadFeatureImage = () => {
        const url = PlaceholderSvg;
        if (featuredImage?.originFileObj) {
            return featuredImage.url;
        } else if (featuredImage.url) {
            return apiConfig.baseImageUrl + featuredImage.url;
        }
        return url;
    }

    return <div className="create-post">
        <div className="container">
            <div className="sec-l">
                <div className="header">
                    <div className="title-section">
                        <img src={ArrowLeftIcn} alt="arrow-left" onClick={() => navigateTo(`/${selectedStore.name.toLowerCase()}/blogs/post`)} />
                        <div className="label">{id ? "Update " : "New "} Post</div>
                        <div className={status}>{status}</div>
                    </div>
                    <div className="action-section">
                        <div className={status === "published" ? "unpublish" : "publish"} onClick={() => onSave(status === "published" ? "draft" : "published")}>{status === "published" ? "Unpublished" : "Publish"}</div>
                        <div style={{ marginLeft: 14 }} className="publish" onClick={() => onSave(status)}>{status === "published" ? "Update" : "Save"}</div>
                        {!visibleType && <img src={ToggleIcn} alt="toggle" onClick={() => setVisibleType('Post Settings')} />}
                    </div>
                </div>
                <div className="rich-editor">
                    <div className="content">
                        <div style={{ paddingTop: 45, paddingBottom: 33 }}>
                            <InputValidation type={error.title.type} message={error.title.message}>
                                <Input type="text" style={{ margin: 0 }} placeholder="Title" value={title} onChange={(e: any) => setTitle(e.target.value)} />
                            </InputValidation>
                        </div>
                        {featuredImage?.url && <img style={{ height: 200, objectFit: 'cover' }} src={featuredImage?.originFileObj ? featuredImage.url : apiConfig.baseImageUrl + featuredImage?.url} alt="Featured Img" />}
                        {((id && lexical) || !id) && <LexicalEditorComponent defaultValue={lexical} onChange={onLexicalChange} height={featuredImage?.url ? WindowHeight - 480 : WindowHeight - 280}></LexicalEditorComponent>}
                    </div>
                </div>
            </div>
            <div className="sec-r" style={{ width: visibleType ? '30%' : '0%', opacity: visibleType ? 1 : 0 }}>
                <div className="action-section">
                    <div className="label">{visibleType}</div>
                    <img src={ToggleIcn} alt="toggle" onClick={() => setVisibleType(visibleType === "Post Settings" ? null : "Post Settings")} />
                </div>
                <div className="details" style={{ height: WindowHeight - 80, overflow: 'auto' }}>
                    {visibleType === "Post Settings" && <Row>
                        <Col span={24}>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Slug</div>
                                </Col>
                                <Col span={24}>
                                    <InputValidation type={error.slug.type} message={error.slug.message}>
                                        <Input className="value" value={slug} onChange={(e) => setSlug(e.target.value)} />
                                    </InputValidation>
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Featured Image</div>
                                </Col>
                                <Col span={24}>
                                    <div className="file-upload-field">
                                        <div>
                                            {featuredImage && <div key={featuredImage.updateAt} style={{ display: 'flex', alignItems: 'center' }}>
                                                <img style={{ height: 30, marginRight: 4 }} src={loadFeatureImage()} alt="Preview Img" />
                                                <div key={featuredImage.updateAt}>{featuredImage.name}</div>
                                            </div>}
                                        </div>
                                        <MediaAssets isEdit={true} multiple={false}
                                            key="featured-images"
                                            defaultValue={null}
                                            uploadIcon={FileUploadSvg}
                                            onSubmit={async ({ fileList, selectedPreIcon, disableImageTransform }) => {
                                                let urls = [];
                                                if (selectedPreIcon && selectedPreIcon.length > 0) {
                                                    urls = selectedPreIcon.map((item) => {
                                                        return item.url
                                                    });
                                                }
                                                if (fileList && fileList.length > 0) {
                                                    onUploadFiles(fileList, 'featured', disableImageTransform)
                                                } else if (urls && urls.length === 1) {
                                                    setFeaturedImage({ url: urls[0] });
                                                }
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {/* <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Publish Date</div>
                                </Col>
                                <Col span={12}>
                                    <DatePicker picker="time" mode={undefined} />
                                </Col>
                                <Col span={12}>
                                    <TimePicker
                                    style={{ width: 70, borderBottom: '2px solid #188FFC' }}
                                    defaultValue={dayjs(sameTimeOpen, "h:mm A")}
                                    format="h:mm A"
                                    onChange={(time, timeString) => setSameTimeOpen(timeString)}
                                    placeholder={"Open"} />

                                </Col>
                            </Row> */}
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Excerpt</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                    <TextArea className="value" value={excerpt} onChange={(e) => setExcerpt(e.target.value)} />
                                    {/* </InputValidation> */}
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Authors</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        filterOption={(inputValue, option) =>
                                            option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                                        }
                                        defaultValue={authors}
                                        onChange={(value) => setAuthors(value)}  >
                                        {users && users.map((u) => {
                                            return <Option key={u.id} value={u.id} style={{ textTransform: 'capitalize' }}>{u.fullName}</Option>
                                        })}
                                    </Select>
                                    {/* </InputValidation> */}
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Blog</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                    <Select
                                        showSearch
                                        defaultValue={blogId}
                                        filterOption={(inputValue, option) =>
                                            option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                                        }
                                        onChange={(value) => setBlogId(value)}  >
                                        {blogs && blogs.map((b) => {
                                            return <Option key={b.id} value={b.id} style={{ textTransform: 'capitalize' }}>{b.title}</Option>
                                        })}
                                    </Select>
                                    {/* </InputValidation> */}
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Tags</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                    <TagComp defaultValue={tags} onChange={(values) => setTags(values)} />
                                    {/* </InputValidation> */}
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Custom Template</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        filterOption={(inputValue, option) =>
                                            option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                                        }
                                        onChange={(value) => setAuthors(value)}  >
                                        {blogs && blogs.map((b) => {
                                            return <Option key={b.id} value={b.id} style={{ textTransform: 'capitalize' }}>{b.title}</Option>
                                        })}
                                    </Select>
                                    {/* </InputValidation> */}
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Feature this post</div>
                                    <div><Switch className="toggle-opt" checked={featured} onChange={(val) => setFeatured(val)} /></div>
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24} style={{ justifyContent: 'space-between', display: 'flex', cursor: 'pointer' }} onClick={() => setVisibleType('Meta')} >
                                    <div className="label" >Meta</div>
                                    <img src={RightArrowSvg} alt="arrow" />
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24} style={{ justifyContent: 'space-between', display: 'flex', cursor: 'pointer' }} onClick={() => setVisibleType('Code Injection')}>
                                    <div className="label" >Code Injection</div>
                                    <img src={RightArrowSvg} alt="arrow" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>}
                    {visibleType === "Meta" && <Row>
                        <Col span={24}>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Meta Title</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                    <Input className="value" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
                                    {/* </InputValidation> */}
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Meta Description</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                    <TextArea className="value" value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} />
                                    {/* </InputValidation> */}
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Meta Keywords</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                    <TagComp defaultValue={metaKeywords} onChange={(values) => setMetaKeywords(values)} />
                                    {/* </InputValidation> */}
                                </Col>
                            </Row>

                        </Col>
                    </Row>}
                    {visibleType === "Code Injection" && <Row>
                        <Col span={24}>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Post Header</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                    <Editor
                                        language="html"
                                        theme="vs-light"
                                        className={"monaco-editor"}
                                        onChange={(code: string, val: any) => { setCodeInjectionHead(code) }}
                                        height={180}
                                        options={{
                                            minimap: {
                                                enabled: false
                                            },
                                            scrollbar: {
                                                vertical: 'hidden'
                                            },
                                            overviewRulerBorder: false,
                                            fontSize: 12,
                                            lineNumbers: 'on',
                                            renderLineHighlight: 'none',
                                            scrollBeyondLastLine: false
                                        }}
                                        value={codeInjectionHead} />
                                    {/* </InputValidation> */}
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Post Footer</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.title.type} message={error.title.message}> */}
                                    <Editor
                                        language="html"
                                        theme="vs-light"
                                        className={"monaco-editor"}
                                        onChange={(code: string, val: any) => { setCodeInjectionFoot(code) }}
                                        height={180}
                                        options={{
                                            minimap: {
                                                enabled: false
                                            },
                                            fontSize: 12,
                                            overviewRulerBorder: false,
                                            lineNumbers: 'on',
                                            renderLineHighlight: 'none',
                                            scrollBeyondLastLine: false
                                        }}
                                        value={codeInjectionFoot} />
                                    {/* </InputValidation> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>}
                </div>
            </div>
        </div>
        {isLoading && <Loading background="#00000070"></Loading>}
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
    </div>
}

export default CreatePost;