import React, { useState, useEffect, useContext } from 'react';
import { connect, useDispatch } from "react-redux";
import Editor from "@monaco-editor/react";
import { getStoreDetails, onUpdateSiteSettings } from '../../../../../../actions/brandAction';
import { loadSiteSettings, onCreatePayload } from '../../../themeService';
import Loading from '../../../../common/Loading';
import { ThemeContext } from '../../../theme';

const CodeInjection = (props: any) => {
    const dispatch = useDispatch();
    const context = useContext(ThemeContext);
    const [data, setdata] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [codeInjection, setCodeInjection]: any = useState({
        footer: null,
        header: null
    });

    useEffect(() => {
        loadData();
        //eslint-disable-next-line
    }, []);

    const loadData = async () => {
        const resp: any = await getStoreDetails()(dispatch);
        const { data, codeInjection: defaultCodeInjection }: any = loadSiteSettings(resp, "codeInjection", context);
        setdata(data);
        setCodeInjection(defaultCodeInjection);
    }

    const headerCode = (code: string, item: any) => {
        setCodeInjection({ ...codeInjection, header: code })
    };

    const footerCode = (code: string, item: any) => {
        setCodeInjection({ ...codeInjection, footer: code })
    };
    const onSave = async () => {
        const siteSetting = { ...data };
        setLoading(true)
        siteSetting.codeInjection = codeInjection;
        const payload = onCreatePayload(siteSetting, 'all');
        const themeUpdateResp: any = await onUpdateSiteSettings(payload)(dispatch);
        setLoading(false)
        if (themeUpdateResp && themeUpdateResp.isUpdated) {
            loadData();
        }
    }
    return (
        <div className="code-injection">
            {isLoading && <Loading background="#00000070"></Loading>}
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 14 }}>
                <button style={{ width: 160, fontSize: 14 }} className="sp_btn" onClick={onSave}>Save</button>
            </div>
            <div className='division'>
                <div className="section">
                    <div className="title">
                        Header
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ flexGrow: 1 }} className="subtitle">HTML Code here will be injected into the head tag of every page of your site</div>
                    </div>
                    <div style={{ marginTop: 25 }}>
                        <Editor
                            language="html"
                            theme="vs-light"
                            className={"monaco-editor"}
                            onChange={(code: string, val: any) => { headerCode(code, val) }}
                            height={180}
                            options={{
                                minimap: {
                                    enabled: false
                                },
                                fontSize: 12,
                                lineNumbers: 'on',
                                scrollBeyondLastLine: false
                            }}
                            value={codeInjection.header} />
                    </div>
                </div>
            </div>
            <div className='division'>
                <div className="section">
                    <div className="title">
                        Footer
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ flexGrow: 1 }} className="subtitle">Javascript Code here will be injected into the body tag of every page of your site</div>
                    </div>
                    <div style={{ marginTop: 25 }}>
                        <Editor
                            language="javascript"
                            className={"monaco-editor"}
                            onChange={(code: string, val: any) => { footerCode(code, val) }}
                            theme="vs-light"
                            height={180}
                            options={{
                                minimap: {
                                    enabled: false
                                },
                                fontSize: 12,
                                lineNumbers: 'on',
                                scrollBeyondLastLine: false
                            }}
                            value={codeInjection.footer} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, {})(CodeInjection);