import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { selectedMainMenu, selectedTabMenu } from "../../../../actions/uiAction";
import { Input } from 'antd';
import "../../../../assets/sass/dsk/settings.sass";
import { getStoreDetails, onUpdateThemeConfig } from '../../../../actions/brandAction';
import InputValidation from '../../common/InputValidation';
import Loading from '../../common/Loading';

const ThemeConfig = (props: any) => {
    let errorInfo = {
        themeConfigId: {
            message: "",
            type: ""
        }
    }
    const dispatch = useDispatch();
    const [isEdit, setEdit] = useState(false);
    const [error, setError] = useState(errorInfo);
    const [themeId, setThemeId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { selectedStore } = useSelector((state: any) => state.ui);
    useEffect(() => {
        setIsLoading(true);
        (async () => {
            const storeDetail = await getStoreDetails(selectedStore.id)(dispatch);
            if (storeDetail?.theme) {
                setThemeId(storeDetail.theme)
            }
            setIsLoading(false);
        })();
        //eslint-disable-next-line
    }, [selectedStore?.id]);
    const onSubmit = async () => {
        let isError = false;
        setIsLoading(true);
        if (!themeId) {
            errorInfo.themeConfigId.message = "Enter the brand theme id";
            errorInfo.themeConfigId.type = "error";
            isError = true;
        } else {
            errorInfo.themeConfigId.message = "";
            errorInfo.themeConfigId.type = "";
        }
        setError({ ...errorInfo })
        if (!isError) {
            await onUpdateThemeConfig({
                "theme": themeId
            })(dispatch);
        }
        setIsLoading(false);
    }
    useEffect(() => {
        let isChanged = false;
        if (themeId === null || themeId) {
            isChanged = true;
        }
        setEdit(isChanged)
    }, [themeId])
    return (
        <div className="settings" style={{ flexDirection: 'column' }}>
            <div className="content">
                <div className="container">
                    <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            Set your theme configuration
                        </div>
                        {isEdit && <button onClick={onSubmit} className="sp_btn save">Save</button>}
                    </div>
                    <div className="division">
                        <div className="section" style={{ display: 'flex' }}>
                            <div style={{ width: '40%' }}>
                                <div className="title" style={{ marginBottom: 10 }}> Theme Id</div>
                                <div style={{ flexGrow: 1, fontSize: 13 }} className="key" >Set your brand theme id</div>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                position: 'relative'
                            }}>
                                <div style={{ flexGrow: 1 }}>
                                    <InputValidation type={error.themeConfigId.type} message={error.themeConfigId.message}>
                                        <Input style={{ width: 400 }} value={themeId} defaultValue={themeId} onChange={(e) => setThemeId(e.target.value)} className="input" placeholder="Enter brand theme id" />
                                    </InputValidation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <Loading background="#00000070"></Loading>}
        </div>
    );
}

export default connect(null, { selectedMainMenu, selectedTabMenu })(ThemeConfig);