import type from "../types/actionTypes";

const initialState = {
};

export default function prodctReducers(state = initialState, action) {
    switch (action.type) {
        case type.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.data.products
            }
        case type.GET_PRODUCTS_ERROR:
            return {
                ...state,
                message: action.error
            }
        case type.SAVE_PRODUCTS_SUCCESS:
            return {
                ...state,
                saveProduct: action.data
            }
        default:
            return state;
    }
}