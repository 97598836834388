import React, { useState, useEffect } from 'react';
import { useSelector, connect } from "react-redux";
import "../../../../../assets/sass/dsk/faq.sass";
import deleteIcn from '../../../../../assets/images/editv1_ico.svg';
import editIcn from '../../../../../assets/images/edit_ico.svg';
import searchIcn from '../../../../../assets/images/search.svg';
import tickIcn from '../../../../../assets/images/card-select.svg';
import closeIcn from '../../../../../assets/images/remove-filter.svg';
import moreOptIcn from '../../../../../assets/images/moreOpt_ico.svg';
import Save from './Save';
import {
    getFaqs, createFaq, unpublishFaq, publishFaq, createFaqQuestions,
    updateFaqQuestions, updateFaq, deleteFaq, publishFaqQuestions,
    unpublishFaqQuestions, deleteFaqQuestions
} from "../../../../../actions/faqActions";
import { Popover, Switch } from 'antd';
import Loading from '../../../common/Loading';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import insert from 'markdown-it-ins'

const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    highlight(str, lang) { },
}).use(insert);

const Categories = (props: any) => {
    const WindowHeight = window.innerHeight;
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [faqs, setFaqs] = useState([]);
    const [faqList, setFaqList] = useState([]);
    const [newFAQ, setNewFAQ] = useState("");
    const [searchFAQCategory, setSearchFAQCategory] = useState(null);
    const [selectedFAQCategory, setSelectFAQCategory] = useState(null);
    const [selectedFAQQuestion, setSelectFAQQuestion] = useState(null);
    const [editFAQQuestion, setEditFAQQuestion] = useState(null);
    const [editFAQTitle, setEditFAQTitle] = useState(null);
    const [moreOption, setMoreOption] = useState(null);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        if (selectedStore) {
            getFaqs();
        }
        //eslint-disable-next-line
    }, [selectedStore]);
    useEffect(() => {
        let filterFAQs = [];
        if (searchFAQCategory && searchFAQCategory.length > 0) {
            for (let faq of faqList) {
                if (faq.name.toLocaleLowerCase().indexOf(searchFAQCategory.toLocaleLowerCase()) > -1) {
                    filterFAQs.push(faq)
                }
            }
            setFaqs(filterFAQs);
        } else {
            setFaqs(faqList);
        }
        //eslint-disable-next-line
    }, [searchFAQCategory]);
    useEffect(() => {
        getFaqs();
        //eslint-disable-next-line
    }, []);
    const getFaqs = async () => {
        let resp = await props.getFaqs();
        if (resp && resp.faqs) {
            setFaqs(resp.faqs);
            setFaqList(resp.faqs);
            if (selectedFAQCategory && selectedFAQCategory.id) {
                for (let item of resp.faqs) {
                    if (item.id === selectedFAQCategory.id) {
                        setSelectFAQCategory(item);
                    }
                }
            } else if (resp.faqs.length > 0) {
                setSelectFAQCategory(resp.faqs[0])
            }
        }
    }
    const onSave = async (payload) => {
        if (payload.questionId) {
            await onFAQAction("UPDATE_QUESTION", payload);
        } else {
            await onFAQAction("CREATE_QUESTION", payload);
        }
        setEditFAQQuestion(null);
        setSelectFAQQuestion(null);
        setEditFAQQuestion(null);
    }

    const onFAQAction = async (action, data) => {
        let resp = null;
        setLoading(true);
        if (action === "PUBLISH_FAQ") {
            resp = await props.publishFaq(data.id, data.name);
        } else if (action === "UNPUBLISH_FAQ") {
            resp = await props.unpublishFaq(data.id, data.name);
        } else if (action === "DELETE_FAQ") {
            resp = await props.deleteFaq(data.id, data.name);
        } else if (action === "PUBLISH_QNA") {
            resp = await props.publishFaqQuestions(selectedFAQCategory.id, data.id, { question: data.question, answer: data.answer });
        } else if (action === "UNPUBLISH_QNA") {
            resp = await props.unpublishFaqQuestions(selectedFAQCategory.id, data.id, { question: data.question, answer: data.answer });
        } else if (action === "DELETE_QNA") {
            resp = await props.deleteFaqQuestions(selectedFAQCategory.id, data.id, { question: data.question, answer: data.answer });
        } else if (action === "ADD_FAQ") {
            resp = await props.createFaq(data);
        } else if (action === "UPDATE_FAQ") {
            resp = await props.updateFaq(data.id, data.name);
        } else if (action === "CREATE_QUESTION") {
            resp = await props.createFaqQuestions(data.faqId, data.question, data.answer);
        } else if (action === "UPDATE_QUESTION") {
            resp = await props.updateFaqQuestions(data.faqId, data.questionId, data.question, data.answer);
        }
        console.log({ resp })
        await getFaqs();
        setLoading(false);
    }
    return (
        <div className='faq'>
            {faqList && faqList.length > 0 ? <>
                <div className="action-bar">
                    <div className="title">
                        You've {faqs && faqs.length} {faqs && faqs.length > 1 ? 'FAQs' : 'FAQ'} listed
                    </div>
                    <div className="actions">
                        <input type="text" value={newFAQ} onChange={(e) => { setNewFAQ(e.target.value) }} placeholder="Create FAQ" />
                        <button className="sp_btn" onClick={() => { newFAQ && newFAQ.length > 2 && onFAQAction('ADD_FAQ', newFAQ); setNewFAQ(""); }} >+ Add FAQ</button>
                    </div>
                </div>
                <div className="content" onClick={(e) => { e.stopPropagation(); setMoreOption(null); }}>
                    <div className="categories">
                        <div className="search">
                            <input type="text" onChange={(e) => setSearchFAQCategory(e.target.value)} placeholder="Search FAQ Category" />
                            <img alt='Search Icon' src={searchIcn} />
                        </div>
                        <div className="items" style={{ height: WindowHeight - 136 }}>
                            {faqs && faqs.map((item) => {
                                return <div style={{ borderBottomColor: selectedFAQCategory && selectedFAQCategory.id === item.id ? '#7bdfbb' : '#EBEBEB' }} onClick={() => setSelectFAQCategory(item)} key={item.id} className="item">
                                    <div className={item.isPublished ? "active" : "inactive"}> {item.name}</div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className="switch"><Switch className="toggle-opt" defaultChecked={item.isPublished} onChange={(checked) => { onFAQAction(checked ? "PUBLISH_FAQ" : "UNPUBLISH_FAQ", item) }}></Switch> </div>
                                        {/* <img style={{ height: 18, marginTop: 2, marginLeft: 10 }} src={deleteIcn} onClick={(e) => { e.stopPropagation(); onFAQAction("DELETE_FAQ", item) }} /> */}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    {selectedFAQCategory && <div className="questions">
                        <div className="title">
                            {selectedFAQCategory && !editFAQTitle && <div>
                                {selectedFAQCategory && selectedFAQCategory.name}
                                <img alt='Edit Icon' src={editIcn} onClick={() => setEditFAQTitle(selectedFAQCategory.name)} />
                                <img alt='Delete Icon' style={{ height: 16, marginTop: 2, marginLeft: 10 }} src={deleteIcn} onClick={(e) => { e.stopPropagation(); onFAQAction("DELETE_FAQ", selectedFAQCategory) }} />
                            </div>}
                            {editFAQTitle && <div className="edit-text">
                                <input type="text" onChange={(e) => { setEditFAQTitle(e.target.value) }} value={editFAQTitle} />
                                <img alt='Update' title="Update" src={tickIcn} onClick={() => { onFAQAction('UPDATE_FAQ', { id: selectedFAQCategory.id, name: editFAQTitle }); setEditFAQTitle(null) }} />
                                <img alt='Cancel' title="Cancel" style={{ marginRight: 8 }} src={closeIcn} onClick={() => setEditFAQTitle(null)} />
                            </div>
                            }
                        </div>
                        <div className="items" style={{ maxHeight: WindowHeight - 260 }}>
                            {selectedFAQCategory && selectedFAQCategory.questions &&
                                selectedFAQCategory.questions.map((item) => {
                                    return <div className="item" key={item.id} onClick={(e) => { e.stopPropagation(); setMoreOption(null); setSelectFAQQuestion(item); }} >
                                        <div className="ques">
                                            <label>
                                                {item.question}
                                            </label>
                                            <div className="action">
                                                <div className="switch"><Switch className="toggle-opt" defaultChecked={item.isPublished} onChange={(checked) => { onFAQAction(checked ? "PUBLISH_QNA" : "UNPUBLISH_QNA", item) }}></Switch> </div>
                                                <Popover
                                                    placement="bottomRight"
                                                    content={
                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                            <div style={{ height: 38, display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); setSelectFAQQuestion(item); setEditFAQQuestion(item); setMoreOption(null); }}>
                                                                <img alt='Edit Icon' src={editIcn} />
                                                                <label style={{ marginLeft: 6 }}>Edit</label>
                                                            </div>
                                                            <div style={{ height: 38, display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); setSelectFAQQuestion(item); onFAQAction("DELETE_QNA", item); setMoreOption(null); }}>
                                                                <img alt='Delete Icon' src={deleteIcn} />
                                                                <label style={{ marginLeft: 6 }}>Delete</label>
                                                            </div>
                                                        </div>
                                                    }
                                                    title="Actions"
                                                    trigger="click"
                                                    visible={moreOption && moreOption.id === item.id ? true : false}
                                                >
                                                    <img alt='More Icon' style={{ transform: 'rotate(90deg)' }} src={moreOptIcn} onClick={(e) => {
                                                        e.stopPropagation();
                                                        (moreOption && moreOption.id === item.id) ? setMoreOption(null) : setMoreOption(item)
                                                    }} />
                                                </Popover>
                                            </div>
                                        </div>
                                        <div className="answ" style={{ marginBottom: selectedFAQQuestion && selectedFAQQuestion.id === item.id ? 10 : 0, maxHeight: selectedFAQQuestion && selectedFAQQuestion.id === item.id ? 200 : 0 }} >
                                            <MdEditor
                                                plugins={[]}
                                                config={{
                                                    view: {
                                                        menu: false,
                                                        md: false,
                                                        html: true,
                                                        fullScreen: false,
                                                        hideMenu: false,
                                                    }
                                                }}
                                                renderHTML={(text) => mdParser.render(item.answer)}
                                            />
                                            {/* {mdParser.render()} */}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="add-qna" onClick={(e) => { e.stopPropagation(); setSelectFAQQuestion(null); setEditFAQQuestion({}) }}>Add question</div>
                    </div>}
                </div>
                {isLoading && <Loading background="#00000070"></Loading>}
                <Save onSave={onSave} selectedFAQCategory={selectedFAQCategory} selectedFAQQuestion={selectedFAQQuestion} onClose={() => { setEditFAQQuestion(null); setSelectFAQQuestion(null); }} isVisible={editFAQQuestion ? true : false}></Save>
            </> : <>
                {isLoading && <Loading background="#00000070"></Loading>}
                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: WindowHeight - 200, flexDirection: 'column' }}>
                    <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy FAQs</div>
                    <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first FAQ.</div>
                    <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no FAQs yet...</div>
                    <div style={{ display: 'flex' }}>
                        <input style={{
                            width: 254,
                            padding: '0px 10px',
                            outline: 'none',
                            border: '1px solid #dfdfdf',
                            marginRight: 10
                        }} type="text" value={newFAQ} onChange={(e) => { setNewFAQ(e.target.value) }} placeholder="Create FAQ" />
                        <button onClick={() => { newFAQ && newFAQ.length > 2 && onFAQAction('ADD_FAQ', newFAQ); setNewFAQ(""); }} className="sp_btn" style={{ width: 114 }} >+ Add FAQ</button>
                    </div>
                </div>
            </>}
        </div>
    );
}

export default connect(null, { getFaqs, createFaq, unpublishFaq, createFaqQuestions, updateFaqQuestions, publishFaq, updateFaq, deleteFaq, publishFaqQuestions, unpublishFaqQuestions, deleteFaqQuestions })(Categories);