import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { uuidv4 } from "../../../helpers/util";
import filterIcn from "../../../assets/images/filter-icn.svg"
import removeFilterIcn from "../../../assets/images/remove-filter.svg";
import "../../../assets/sass/dsk/filter.sass";

type Queries = {
    qId: string,
    fValue: any,
    fName: string,
    fId: string
}
type Property = {
    key: string,
    value: any,
}
type Properties = Property[];
interface IProps {
    defaultValue?: Properties,
    properties: Properties,
    queries: Queries,
    onSelectedFilters: (val: Queries[]) => void;
};

const QueryFilter = (props: IProps) => {
    const ref = useRef(null);
    // const [config, setConfig] = useState(null);
    const [showPropsPanel, setPropsPanel] = useState(false);
    const [selectedProps, setSelectedProps] = useState(null);
    const [query, setQuery] = useState(null);
    const [queries, setQueries] = useState(null);
    // const onCloseProperties = () => {
    //     setPropsPanel(false);
    // }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setPropsPanel(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
        //eslint-disable-next-line
    }, []);
    const onSetQuery = (e, isFocusOut) => {
        if ((e.target.value.trim().length > 0 && isFocusOut) || (e.key === "Enter" || e.charCode === 13)) {
            e.preventDefault();
            e.stopPropagation();
            let qId = uuidv4();
            if (queries !== null) {
                setQueries([...queries, { qId, fValue: query, fName: selectedProps.value, fId: selectedProps.key }]);
            } else {
                setQueries([{ qId, fValue: query, fName: selectedProps.value, fId: selectedProps.key }]);
            }
            setSelectedProps(null);
        }
    }
    const onRemoveFilter = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        setQueries(queries.filter(q => q.qId !== item.qId));
    }
    useEffect(() => {
        // debugger
        // if (JSON.stringify(queries) !== JSON.stringify(props.defaultValue)) {
        // }
        // console.log({ queries });
        if (queries !== null) {
            props.onSelectedFilters(queries);
        }
        //eslint-disable-next-line
    }, [queries]);
    useEffect(() => {
        if (props.defaultValue !== null) {
            setQueries(props.defaultValue)
        }
        //eslint-disable-next-line
    }, [props.defaultValue]);
    return <div className="filter">
        <img alt="Filter Icon" src={filterIcn} style={{ cursor: selectedProps === null ? 'pointer' : 'default' }} onClick={() => { selectedProps === null && setPropsPanel(true) }} />
        <div className="query">
            {selectedProps !== null && <div className={"not-filled"}>
                <div className="name">{selectedProps.value}</div>
                <Input type="text" tabIndex={1} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} onChange={(e) => setQuery(e.target.value)} onKeyPress={(e) => { onSetQuery(e, false) }} onBlur={(e) => { onSetQuery(e, true) }} />
            </div>}
            {queries?.map((q) => {
                return <div className="filled" key={q.qId}>
                    <div className="name">{q.fName}: </div><div className="value">{q.fValue}</div>
                    <div className="remove" onClick={(e) => onRemoveFilter(e, q)}><img alt="Remove Filter Icon" src={removeFilterIcn} /></div>
                </div>
            })}
        </div>
        {showPropsPanel && <div className="conditions" ref={ref} >
            <div className="header">Properties</div>
            <div className="condition">
                {props.properties.map((item) => {
                    return <div onClick={() => {
                        setSelectedProps(item); setPropsPanel(false);
                    }} className="item" key={item.key}>{item.value}</div>
                })}
            </div>

        </div>}
    </div>
}

export default QueryFilter;