import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { getOutletById, getOutletSlot } from "../../../../../actions/outletAction";
import "../../../../../assets/sass/dsk/outlet.sass";
// import MapWidget from "../../../common/MapWidget";
import Update from "./Update";

const Detail = (props: any) => {
    const dispatch = useDispatch();
    // const [outletId, setOutletId] = useState(null);
    const [outletSlots, setOutletSlots] = useState(null);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [isSaveOutlet, setSaveOutlet] = useState(false);
    const [isDeliveryAction, setDeliveryAction] = useState(false);
    const [outlet, setOutlet] = useState(null);
    // const [placeDetails, setPlaceDetails] = useState(null);
    const { id: outletId } = useParams();
    useEffect(() => {
        if (outletId) {
            loadOutletDetail(outletId);
        }
        //eslint-disable-next-line
    }, [outletId]);
    const loadOutletDetail = async (ouId) => {
        const respSlot: any = await getOutletSlot(ouId)(dispatch);
        if (respSlot) {
            setOutletSlots(respSlot);
        }
        const resp: any = await getOutletById(ouId)(dispatch);
        if (resp && resp.deliverySlotConfig && resp.deliverySlotConfig.slotIds) {
            setSelectedSlots(resp.deliverySlotConfig.slotIds)
        }
        // if (resp && resp.placeDetails) {
        //     setPlaceDetails(resp.placeDetails);
        // }
        setOutlet(resp);
    }
    const getSlotName = (id: string) => {
        let slotName = "";
        const slot = outletSlots.filter((ouSlot) => ouSlot.id === id);
        if (slot && slot.length > 0) {
            slotName = slot[0].name;
        }

        return slotName;
    }
    return <div className="outlet-detail">
        <div className="action-bar">
            <div className="title">
                View & configure the store details
            </div>
            <div className="actions">
            </div>
        </div>
        {outlet && <div className="contents">
            <Row style={{ width: '100%' }}>
                <Col span={12} style={{
                    paddingRight: 10
                }}>
                    <div className="card">
                        <div className="title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 22 }}>
                            <div>Outlet Details</div>
                            <button className="sp_btn btn" style={{ width: 100 }} onClick={() => { setSaveOutlet(true); setDeliveryAction(false) }}>Edit</button>
                        </div>
                        <Row style={{ marginTop: 16 }}>
                            <Col span={10} className="label">
                                Outlet Name:
                            </Col>
                            <Col span={14} className="value" style={{ color: "#188FFC" }}>
                                {outlet.name}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 16 }}>
                            <Col span={10} className="label">
                                Contact Number:
                            </Col>
                            <Col span={14} className="value">
                                {outlet?.contactNumber}
                            </Col>
                        </Row>
                        <div className="title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 45, marginBottom: 22 }}>
                            <div>Outlet Address</div>
                        </div>
                        <Row style={{ marginTop: 16 }}>
                            <Col span={10} className="label">
                                Address Details:
                            </Col>
                            <Col span={14} className="value">
                                <div>
                                    {outlet.address.addressLine1}
                                    {outlet.address.addressLine2 ? `, ${outlet.address.addressLine2}` : ''}
                                </div>
                                <div>
                                    {outlet.address.area}
                                    {outlet.address.city ? `, ${outlet.address.city}` : ''}
                                </div>
                                <div>
                                    {outlet.address.state}
                                    {outlet.address.zipcode ? `, ${outlet.address.zipcode}` : ''}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={1}></Col>
                <Col span={11} >
                    {((selectedSlots && selectedSlots.length > 0) || (outlet && outlet.deliveryArea)) && <div className="card">
                        {outlet.deliveryArea && <>
                            <div className="title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 22 }}>
                                <div>Delivery Range Type</div>
                                <button className="sp_btn btn" onClick={() => { setSaveOutlet(true); setDeliveryAction(true) }} style={{ width: 100 }}>Edit</button>
                            </div>
                            <Row style={{ marginTop: 16 }}>
                                <Col span={10} className="label" style={{
                                    display: "flex",
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start'
                                }}>
                                    {outlet.deliveryArea.type === "city" && "Cities"}
                                    {outlet.deliveryArea.type === "maxDistance" && "Max Distance"}
                                    {outlet.deliveryArea.type === "postalCode" && "Pincodes"}
                                    {outlet.deliveryArea.type === "state" && "States"}
                                    {outlet.deliveryArea.type === "country" && "Countries"}
                                </Col>
                                <Col span={14} className="value" style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    flexWrap: 'wrap',

                                }} >
                                    {outlet.deliveryArea.maxDistance &&
                                        <div style={{
                                            height: 35,
                                            background: '#DBE2EA6C',
                                            borderRadius: 5,
                                            color: "#121212",
                                            display: "flex",
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '0px 10px',
                                        }}>
                                            {outlet.deliveryArea.maxDistance}
                                        </div>}
                                    {outlet.deliveryArea.cities && outlet.deliveryArea.cities.map((c) => {
                                        return <div style={{ background: 'transparent', padding: 5 }}><div
                                            key={c}
                                            style={{
                                                height: 35,
                                                background: '#DBE2EA6C',
                                                borderRadius: 5,
                                                color: "#121212",
                                                display: "flex",
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '0px 5px',
                                                marginRight: 5
                                            }}>{c}</div></div>
                                    })}
                                    {outlet.deliveryArea.states && outlet.deliveryArea.states.map((c) => {
                                        return <div style={{ background: 'transparent', padding: 5 }}><div
                                            key={c}
                                            style={{
                                                height: 35,
                                                background: '#DBE2EA6C',
                                                borderRadius: 5,
                                                color: "#121212",
                                                display: "flex",
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '0px 5px',
                                                marginRight: 5
                                            }}>{c}</div></div>
                                    })}
                                    {outlet.deliveryArea.postalCodes && outlet.deliveryArea.postalCodes.map((c) => {
                                        return <div style={{ background: 'transparent', padding: 5 }}><div
                                            key={c}
                                            style={{
                                                height: 35,
                                                background: '#DBE2EA6C',
                                                borderRadius: 5,
                                                color: "#121212",
                                                display: "flex",
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '0px 5px',
                                                marginRight: 5
                                            }}>{c}</div></div>
                                    })}
                                    {outlet.deliveryArea.countries && outlet.deliveryArea.countries.map((c) => {
                                        return <div style={{ background: 'transparent', padding: 5 }}><div
                                            key={c}
                                            style={{
                                                height: 35,
                                                background: '#DBE2EA6C',
                                                borderRadius: 5,
                                                color: "#121212",
                                                display: "flex",
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '0px 10px',
                                                marginRight: 5
                                            }}>{c}</div></div>
                                    })}
                                </Col>
                            </Row></>}
                        {selectedSlots && selectedSlots.length > 0 && <Row style={{ marginTop: 16 }}>
                            <Col span={24} className="title" style={{ marginBottom: 18 }}>
                                Delivery Slots
                            </Col>
                            <Col span={24} className="value" style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap',
                            }}>
                                {selectedSlots.map((id) => {
                                    return <div
                                        key={id}
                                        style={{
                                            height: 35,
                                            marginBottom: 10,
                                            background: '#DBE2EA6C',
                                            borderRadius: 5,
                                            color: "#121212",
                                            display: "flex",
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '0px 10px',
                                            marginRight: 10
                                        }}>{getSlotName(id)}</div>
                                })}
                            </Col>
                        </Row>}
                    </div>}
                    {/* <div className="map" style={{ height: WindowHeight - 200 }}>
                        {placeDetails && placeDetails.geometry && placeDetails.geometry.coordinates && <MapWidget height={WindowHeight - 220} lat={placeDetails.geometry.coordinates[1]} lng={placeDetails.geometry.coordinates[0]}></MapWidget>}
                    </div> */}
                </Col>
            </Row>
        </div>
        }
        <Update isDeliveryAction={isDeliveryAction} outletSlots={outletSlots} onClose={() => { setDeliveryAction(false); setSaveOutlet(false); loadOutletDetail(outletId); }} outlet={outlet} isVisible={isSaveOutlet} />
    </div>
}

export default Detail;