import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
// import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { getStores } from "../../../actions/setupStoreActions";
import { selectedStore, selectedMainMenu, selectedTabMenu, resetOrders, setSetupStoreDetails } from "../../../actions/uiAction";
import Header from "./Header";
import Footer from "./Footer";
import "../../../assets/sass/mob/selectStore.sass";
import StoreNotAvailableJson from "../../../assets/lotties/store-at-hitit.json";
import LottiePlayer from "lottie-web";
import config from '../../../apiConfig';
import PopupConfirm from "../common/PopupConfirm";
import { useNav } from "../../../helpers/customHook";
import Loading from "../common/Loading";

const SelectStore = (props: any) => {
    const navigateTo = useNav();
    const [popupConfirmDetail, setPopupConfirmDetail] = useState(null);
    const [isLoading, setLoading] = useState(false);
    // const windowHeight = window.innerHeight;
    // const responsive = {
    //     0: {
    //         items: 2,
    //     },
    //     1024: {
    //         items: 10,
    //     },
    // };
    const [stores, setStores] = useState(null);
    // const [storeCards, setStoreCards] = useState([]);
    const handleDragStart = (e) => e.preventDefault();
    const storeNotAvailableRef = useRef(null);
    const onSelectedStore = async (store) => {
        await props.selectedStore(store);
        const SubscriptionAllowed = ["active", "trial", "pendingCancellation"];
        // const SubscriptionNotAllowed = ["cancelled", "none", "suspended", "ended"];
        if (store && SubscriptionAllowed.includes(store?.subscriptionStatus)) {
            // await props.resetOrders();
            // props.selectedMainMenu("orders");
            // props.selectedTabMenu("current_order");
            // navigateTo(`/${store.name.toLowerCase()}/orders/current`);
            navigateTo(`/redirectToDsk`);
        } else if (store.userType === "owner") {
            if (store.subscriptionStatus === "cancelled" || store.subscriptionStatus === "suspended") {
                // navigateTo(`/${store.name.toLowerCase()}/billing/plans`);
                navigateTo(`/redirectToDsk`);
                // navigateTo(`/${store.name.toLowerCase()}/${store.subscriptionStatus}`);
            } else if (store.subscriptionStatus === "none") {
                // navigateTo(`/${store.name.toLowerCase()}/chooseplan`);
                navigateTo(`/redirectToDsk`);
            }
        } else {
            let popupConfirmDetail = {
                title: "Store Inactive",
                renderContent: () => { return <div style={{ color: "#000", lineHeight: 2 }} >Please contact your <span style={{ fontWeight: "bold" }}>{store.name}</span> store administrator. </div> },
                okay: {
                    label: "Okay",
                    callback: () => {
                        setPopupConfirmDetail(null);
                    }
                }
            }
            setPopupConfirmDetail(popupConfirmDetail)
        }
    };
    useEffect(() => {
        props.setSetupStoreDetails(null);
        getStoreDetails();
        //eslint-disable-next-line
    }, []);
    // useEffect(() => {
    //     let items = [];
    //     if (stores) {
    //         for (const store of stores) {
    //             items.push(
    //                 <div
    //                     className="store"
    //                     key={store.brandName}
    //                     onClick={() => {
    //                         onSelectedStore(store);
    //                     }}
    //                     onDragStart={handleDragStart}
    //                 >
    //                     <div className="item" title={store.brandName}>
    //                         <div className="letter">{store.brandName.charAt(0).toUpperCase()}</div>
    //                     </div>
    //                     <div className="name">{store.brandName}</div>
    //                     <div className={`type_${store.userType.toLowerCase()}`}>{store.userType}</div>
    //                 </div>
    //             );
    //         }
    //     }
    //     setStoreCards(items);
    // }, [stores]);
    const getStoreDetails = async () => {
        setLoading(true);
        const resp = await props.getStores();
        if (!resp || resp.length === 0) {
            LottiePlayer.loadAnimation({
                container: storeNotAvailableRef.current,
                renderer: "svg",
                loop: false,
                autoplay: true,
                animationData: StoreNotAvailableJson,
            });
            setStores([]);
        } else {
            setStores(resp);
        }
        setLoading(false);
    };
    // const onCreateStore = () => {
    //     navigateTo("/setupstore");
    // };

    const getStoreLogo = (store: any) => {
        if (store && store.logo) {
            return config.baseImageUrl + store.logo;
        }
    };

    return (
        <div className="select-store-mob" style={{ height: stores && stores.length === 0 ? '100vh' : 'auto' }}>
            <Header enableCreateStore={true} enableLogout={true} />
            <div className="content" style={{ margin: stores && stores.length > 0 ? '50px 0px' : '0px' }}>
                {stores && stores.length > 0 && <div className="title">Your Stores</div>}
                {stores && stores.length > 0 && <div className="stores-list">
                    {/* {stores && stores.length > 0 && <AliceCarousel keyboardNavigation={true} mouseTracking={true} autoWidth={true} responsive={responsive} disableButtonsControls={true} disableDotsControls={true} items={storeCards} />} */}
                    {stores &&
                        stores.map((store) => {
                            const imageUrl = getStoreLogo(store)
                            return (
                                <div
                                    className="store-card"
                                    key={store.brandName}
                                    onClick={() => {
                                        onSelectedStore(store);
                                    }}
                                    onDragStart={handleDragStart}
                                >
                                    <div className="dp" title={store.brandName}>
                                        {!imageUrl && <div className="letter">{store.brandName.trim().charAt(0).toUpperCase()}</div>}
                                        {imageUrl && <img alt="Logo" className="logo" src={imageUrl} />}
                                        {(store.subscriptionStatus === "cancelled" || store.subscriptionStatus === "suspended") && <div className="not_active">-</div>}
                                    </div>
                                    <div>
                                        <div className="name">{store.brandName}</div>
                                        <div className={`type_${store.userType.toLowerCase()}`}>{store.userType}</div>
                                    </div>
                                </div>
                            );
                        })}
                </div>}
                {stores && stores.length === 0 && (
                    <div className="no_store">
                        <div ref={storeNotAvailableRef}></div>
                        <div
                            className="subtitle"
                            style={{
                                width: "100%",
                                textAlign: 'center',
                                marginTop: "186px",
                                position: "absolute",
                            }}
                        >
                            You are not part of any store yet. Tell your store admin to grant you access
                        </div>
                    </div>
                )}
            </div>
            {popupConfirmDetail && <PopupConfirm config={popupConfirmDetail}></PopupConfirm>}
            <Footer></Footer>
            {isLoading && <Loading background="#00000070"></Loading>}
        </div>
    );
};

export default connect(null, {
    getStores,
    selectedStore,
    setSetupStoreDetails,
    selectedMainMenu,
    resetOrders,
    selectedTabMenu,
})(withTranslation()(SelectStore));
