import React, { useEffect, useState } from "react";
import DragDropUpload from "./DragDropUpload";
import { onDeleteMedia, onUploadMedia, getMediaFiles } from "../../../actions/commonAction";
import { useDispatch } from "react-redux";
import Loading from "../common/Loading";

const Media = (props: any) => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [files, setFiles]: any = React.useState([]);
    const [consumption, setConsumption] = useState(null);
    useEffect(() => {
        getFiles();
        //eslint-disable-next-line
    }, []);
    const onDeleteFile = async (file: any) => {
        const payload = file?.objectUrl ? [file?.objectUrl] : file;
        const resp = await onDeleteMedia({ filenames: payload })(dispatch);
        if (!resp?.error) {
            getFiles();
            return { resp: { error: false }, file };
        }
        return { resp: { error: true }, file };
    }
    const getFiles = async () => {
        setLoading(true);
        const resp: any = await getMediaFiles()(dispatch);
        setLoading(false);
        if (resp?.media) {
            setFiles(resp.media);
            setConsumption(resp?.consumption)
        }
    }
    const onUploadFile = async (file, disableImageTransform = false) => {
        const formData = new FormData();
        formData.append('files', file.originFileObj);
        formData.append('forUse', 'mediaFiles');
        if (!disableImageTransform) {
            formData.append('imageTransform', 'w:500');
        }
        const resp = await onUploadMedia(formData)(dispatch);
        if (resp) {
            return { resp: { error: false }, file };
        }
        return { resp: { error: true }, file };
    }
    return <div>
        {isLoading && <Loading background="#00000070"></Loading>}
        <DragDropUpload onLoadFiles={getFiles} files={files} onUpload={onUploadFile} consumption={consumption} onDelete={onDeleteFile}></DragDropUpload>
    </div>
}
export default Media;