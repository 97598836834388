import * as React from "react"

function AwaitSvg(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="49" viewBox="0 0 53 49">
            <g id="awaitingFull" transform="translate(-0.32 -0.7)">
                <g id="Group_10728" data-name="Group 10728" transform="translate(0.32 0.7)">
                    <rect id="Rectangle_17259" data-name="Rectangle 17259" width="53" height="49" rx="5" transform="translate(0 0)" fill="rgba(15,187,89,0.15)" />
                    <g id="receipt-edit" transform="translate(13.629 11.582)">
                        <path id="Vector" d="M18.975,10.451V5.664C18.975,1.135,17.926,0,13.707,0H5.268C1.049,0,0,1.135,0,5.664V18.318c0,2.989,1.63,3.7,3.605,1.562l.011-.011a1.955,1.955,0,0,1,3.1.169l1.127,1.517" transform="translate(4.141 2.366)" fill="none" stroke="#1cbb59" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                        <path id="Vector-2" data-name="Vector" d="M0,0H9.466" transform="translate(8.896 7.95)" fill="none" stroke="#1cbb59" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                        <path id="Vector-3" data-name="Vector" d="M0,0H7.1" transform="translate(10.079 12.493)" fill="none" stroke="#1cbb59" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                        <g id="Group" transform="translate(15.629 15.659)">
                            <path id="Vector-4" data-name="Vector" d="M4.779.678.591,4.866a1.458,1.458,0,0,0-.355.7l-.225,1.6a.751.751,0,0,0,.9.9l1.6-.225a1.407,1.407,0,0,0,.7-.355L7.394,3.293a1.615,1.615,0,0,0,0-2.627A1.61,1.61,0,0,0,4.779.678Z" transform="translate(0 0)" fill="none" stroke="#1cbb59" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                            <path id="Vector-5" data-name="Vector" d="M0,0A3.769,3.769,0,0,0,2.627,2.627" transform="translate(4.174 1.281)" fill="none" stroke="#1cbb59" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                        </g>
                        <path id="Vector-6" data-name="Vector" d="M0,0H27.257V27.257H0Z" transform="translate(0)" fill="none" opacity="0" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default AwaitSvg
