import React from 'react';

import { Action, ActionProps } from '../Action';
import DeleteSvg from '../../../../../../assets/images/settings/trash.svg';

export function Remove(props: ActionProps) {
  return (
    <Action
      {...props}
      active={{
        fill: 'rgba(255, 70, 70, 0.95)',
        background: 'rgba(255, 70, 70, 0.1)',
      }}
    >
      <img className="delete" alt="delete" src={DeleteSvg} style={{ height: 16 }} />
    </Action>
  );
}
