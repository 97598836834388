import React from 'react';
import { connect } from 'react-redux';
import '../../../assets/sass/dsk/menu.sass';
import { selectedTabMenu } from '../../../actions/uiAction';
import SubMenu from '../common/SubMenu';
import DetailMenu from './DetailMenu';

const Menu = (props: any) => {
    const { currentSubMenu } = props;
    return (
        currentSubMenu ? <DetailMenu /> : <SubMenu {...{
            title: "Customers",
            root: "customers",
        }} />
    );
}

export default connect(null, { selectedTabMenu })(Menu);