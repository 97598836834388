import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Input, Switch } from "antd";
import InputValidation from "../../../common/InputValidation";
import Loading from "../../../common/Loading";
import { createPageRedirect } from "../../../../../actions/blogActions";
import "../../../../../assets/sass/dsk/settings.sass";
import { isNotNullOrUndefinedString } from "../../../../../helpers/util";
import { useNav } from "../../../../../helpers/customHook";

const errorInfo = {
    source: {
        message: "",
        type: "",
    },
    destination: {
        message: "",
        type: "",
    }
}
const Save = () => {
    const navigateTo = useNav();
    const dispatch = useDispatch();
    const [error, setError] = useState(errorInfo);
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [isLoading, setIsLoading] = useState(false);
    const [source, setSource] = useState("");
    const [destination, setDestination] = useState("");
    const [permanent, setPermanent] = useState(false);
    const [useRegex, setUseRegex] = useState(false);

    const onSave = async () => {
        setIsLoading(true);
        setIsLoading(true);
        let errorInfo = {
            source: {
                message: "",
                type: "",
            },
            destination: {
                message: "",
                type: "",
            }
        }, isError = false;
        // this.setState({ error });
        setError(errorInfo);
        if (!isNotNullOrUndefinedString(source)) {
            errorInfo.source.type = "error";
            errorInfo.source.message = "Fill the source";
            isError = true;
        }
        if (!isNotNullOrUndefinedString(destination)) {
            errorInfo.destination.type = "error";
            errorInfo.destination.message = "Fill the destination";
            isError = true;
        }
        if (!isError) {
            const payload = {
                source,
                destination,
                permanent,
                useRegex
            };
            if (payload) {
                const resp = await createPageRedirect(payload)(dispatch);
                if (resp) {
                    navigateTo(`/${selectedStore.name.toLowerCase()}/settings/store/pageredirects`);
                }
            }
        } else {
            setError(errorInfo);
        }
        setIsLoading(false);
    }

    return <div className="settings">
        <div className="content" style={{ flexDirection: 'row' }}>
            <div className="container" style={{ flexGrow: 1, margin: '0px 24px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 800, fontSize: 18 }}>
                    <div>Page Redirects</div>
                    <button className="sp_btn" style={{ width: 'auto' }} onClick={onSave}>Save</button>
                </div>
                <div style={{ height: window.innerHeight - 80 }}>
                    <div className="division">
                        <div className="section">
                            <div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div className="title" style={{ width: '36vw', display: 'flex', flexDirection: 'column' }}>
                                        <label>Source</label>
                                        <label style={{ fontWeight: 500, fontSize: 13, color: "#000" }}>Lorem ipsum dolor sit amet, consectetur</label>
                                    </div>
                                    <InputValidation style={{ width: "100%" }} type={error.source.type} message={error.source.message}>
                                        <Input
                                            style={{ width: "100%" }}
                                            value={source}
                                            name="Source"
                                            placeholder="Enter the Source"
                                            onChange={(e) => {
                                                setSource(e.target.value);
                                            }}
                                        />
                                    </InputValidation>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: "25px 0px"
                                }}>
                                    <div className="title" style={{ width: '36vw', display: 'flex', flexDirection: 'column' }}>
                                        <label>Destination</label>
                                        <label style={{ fontWeight: 500, fontSize: 13, color: "#000" }}>Lorem ipsum dolor sit amet, consectetur</label>
                                    </div>
                                    <InputValidation style={{ width: "100%" }} type={error.destination.type} message={error.destination.message}>
                                        <Input
                                            style={{ width: "100%" }}
                                            value={destination}
                                            name="Destination"
                                            placeholder="Enter the Destination"
                                            onChange={(e) => {
                                                const { value }: any = e.target;
                                                setDestination(value);
                                            }}
                                        />
                                    </InputValidation>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div className="title" style={{ width: '36vw', display: 'flex', flexDirection: 'column' }}>
                                        <label>Permanent Redirect</label>
                                        <label style={{ fontWeight: 500, fontSize: 13, color: "#000" }}>Lorem ipsum dolor sit amet, consectetur</label>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <Switch className="toggle-opt" checked={permanent} onChange={(val) => setPermanent(val)}></Switch>
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: "25px 0px"
                                }}>
                                    <div className="title" style={{ width: '36vw', display: 'flex', flexDirection: 'column' }}>
                                        <label>Use Regex</label>
                                        <label style={{ fontWeight: 500, fontSize: 13, color: "#000" }}>Lorem ipsum dolor sit amet, consectetur</label>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <Switch className="toggle-opt" checked={useRegex} onChange={(val) => setUseRegex(val)}></Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {isLoading && <Loading background="#00000070"></Loading>}
    </div>
}

export default Save;