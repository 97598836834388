export const CONSTANTS = {
    Currency: '₹',
    Urls: {
        tc: "https://www.shopletzy.com/terms-of-use",
        pp: "https://www.shopletzy.com/privacy-policy"
    },
    OrderStatus: {
        colors: {
            awaitingConfirmation: "#A239EA",
            awaitingFulfilment: "#1A8FE3",
            completed: "#29BB89",
            cancelled: "#e63946",
            shipped: "#FC5404",
            declined: "#CE1212",
            pending: "#f29339",
            awaitingPickup: "#FF4800"
        },
        labels: {
            awaitingConfirmation: "Awaiting Confirmation",
            awaitingFulfilment: "Awaiting Fulfilment",
            declined: "Declined",
            completed: "Completed",
            shipped: "Shipped",
            cancelled: "Cancelled",
            pending: "Pending",
            awaitingPickup: "Awaiting Pickup",
            awaitingPayment: "Awaiting Payment"
        },
    }
}