import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ArrowIcn from '../../../../assets/images/arrow_ico.svg';
import { Input, Tooltip } from 'antd';
import styled from "styled-components";
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import Loading from '../../common/Loading';
// import config from '../../../../apiConfig';

const ColorCodes = ["#0076FF", "#FD1054", "#6DB821", "#6C00FF", "#FF8100"];

const Menu = styled.button`
 border-bottom-left-radius: ${(props) => props.theme.enable ? "0px" : "5px"}; 
 border-bottom-right-radius: ${(props) => props.theme.enable ? "0px" : "5px"};
 color: ${(props) => props.theme.enable ? "#07c180" : "#FFF"};
`;

const Color = styled.button`
    width: 26px;
    height: 30px;
    background: ${(props) => props.theme.color}; 
    box-shadow: 0px 1px 2px #ADB7C3;
    border: 2px solid #FFFFFF;
    border-radius: 3px;
`;

const WebView = (props: any) => {
    // const [loaded, setLoaded] = useState(false);
    // const [lastUpdated, setLastUpdated] = useState(props.dskThemeProps.lastUpdated);
    const [primaryColor, setPrimaryColor] = useState(props.dskThemeProps.general.primaryColor);
    const [secondaryColor, setSecondaryColor] = useState(props.dskThemeProps.general.secondaryColor);
    const [brandName, setBrandName] = useState(props.dskThemeProps.general.brand.label);
    const [homeBGColor, setHomeBGColor] = useState(props.dskThemeProps.landing.backgroundColor);
    const [homePrimaryText, setHomePrimaryText] = useState(props.dskThemeProps.landing.content.mainTitle.label);
    const [homeSecondaryText, setHomeSecondaryText] = useState(props.dskThemeProps.landing.content.subTitle.label);
    const [homePrimaryColor, setHomePrimaryColor] = useState(props.dskThemeProps.landing.content.mainTitle.color);
    const [homeSecondaryColor, setHomeSecondaryColor] = useState(props.dskThemeProps.landing.content.subTitle.color);
    const [toggleSetting, setToggleSetting] = useState(true);
    const [selectedMenu, setSelectedMenu] = useState("general");
    const setTheme = (key, value) => {
        let hexRegx = /^([0-9a-f]{3}|[0-9a-f]{6})$/i;
        if (key === "primaryColor" && hexRegx.test(value)) {
            props.setDskThemeProps(_.merge({}, props.dskThemeProps, {
                general: {
                    primaryColor: `#${value}`
                }
            }));
        } else if (key === "secondaryColor" && hexRegx.test(value)) {
            props.setDskThemeProps(_.merge({}, props.dskThemeProps, {
                general: {
                    secondaryColor: `#${value}`
                }
            }));
        } else if (key === "brand") {
            props.setDskThemeProps(_.merge({}, props.dskThemeProps, {
                general: {
                    brand: {
                        label: value
                    }
                }
            }));
        } else if (key === "homeBGColor") {
            props.setDskThemeProps(_.merge({}, props.dskThemeProps, {
                home: {
                    backgroundColor: `#${value}`
                }
            }));
        } else if (key === "homePrimaryText") {
            props.setDskThemeProps(_.merge({}, props.dskThemeProps, {
                home: {
                    content: {
                        mainTitle: {
                            label: value
                        }
                    }
                }
            }));
        } else if (key === "homePrimaryColor") {
            props.setDskThemeProps(_.merge({}, props.dskThemeProps, {
                home: {
                    content: {
                        mainTitle: {
                            color: `#${value}`
                        }
                    }
                }
            }));
        } else if (key === "homeSecondaryText") {
            props.setDskThemeProps(_.merge({}, props.dskThemeProps, {
                home: {
                    content: {
                        subTitle: {
                            label: value
                        }
                    }
                }
            }));
        } else if (key === "homeSecondaryColor") {
            props.setDskThemeProps(_.merge({}, props.dskThemeProps, {
                home: {
                    content: {
                        subTitle: {
                            color: `#${value}`
                        }
                    }
                }
            }));
        }
    }

    // const onLoad = () => {
    //     setLastUpdated(new Date().getTime());
    //     setTimeout(() => {
    //         setLoaded(true);
    //     }, 1000)
    //     props.setDskThemeProps(_.merge({}, props.dskThemeProps, {
    //         lastUpdated
    //     }));
    // }

    return (
        <div className="web_view">
            <div className="setting" style={{ width: toggleSetting ? "30%" : "2%" }}>
                <div className="header">
                    <div className="title" style={{ display: toggleSetting ? "block" : "none" }}>SETTINGS</div>
                    <img alt='Arrow' src={ArrowIcn} onClick={() => setToggleSetting(!toggleSetting)} style={{ transform: toggleSetting ? "rotate(180deg)" : "rotate(360deg)" }} />
                </div>
                <div className="contents" style={{ display: toggleSetting ? "block" : "none" }}>
                    <div className="group">
                        <Menu className="sp_btn" theme={{ enable: selectedMenu === "general" }} onClick={() => setSelectedMenu("general")}>
                            general
                            <Tooltip placement="bottomRight" title={"These are the main colours for your app. Set them here and reuse them throughout your theme. This will make your theme look nice & consistent!"}>
                                <ExclamationCircleOutlined style={{ fontSize: "16px", color: "#FFF" }} />
                            </Tooltip>
                        </Menu>
                        <div className="panel" style={{ display: selectedMenu === "general" ? "block" : "none" }}>
                            <div className="group">
                                <label>Primary Color</label>
                                <div className="color_palettes">
                                    {ColorCodes.map((color) => {
                                        return <Color key={color} theme={{ color }} onClick={() => { setPrimaryColor(color.replace("#", "")); setTheme("primaryColor", color.replace("#", "")) }} />
                                    })}
                                    <Input maxLength={6} prefix={"#"} onChange={(e) => { setPrimaryColor(e.target.value); setTheme("primaryColor", e.target.value); }} type="text" value={primaryColor.replace("#", "")} />
                                </div>
                            </div>
                            <div className="group">
                                <label>Secondary Color</label>
                                <div className="color_palettes">
                                    {ColorCodes.map((color) => {
                                        return <Color theme={{ color }} onClick={() => { setSecondaryColor(color.replace("#", "")); setTheme("secondaryColor", color.replace("#", "")) }} />
                                    })}
                                    <Input maxLength={6} prefix={"#"} onChange={(e) => { setSecondaryColor(e.target.value); setTheme("secondaryColor", e.target.value,) }} type="text" value={secondaryColor.replace("#", "")} />
                                </div>
                            </div>
                            <div className="group">
                                <label>Brand Name</label>
                                <div>
                                    <Input placeholder="Enter the brand name" maxLength={20} onChange={(e) => { setBrandName(e.target.value); setTheme("brand", e.target.value,) }} type="text" value={brandName} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="group">
                        <Menu className="sp_btn" theme={{ enable: selectedMenu === "home" }} onClick={() => setSelectedMenu("home")}>
                            home
                            <Tooltip placement="bottomRight" title={"After you have uploaded your logo, we will generate a color palette you can use for your brand colours."}>
                                <ExclamationCircleOutlined style={{ fontSize: "16px", color: "#FFF" }} />
                            </Tooltip>
                        </Menu>
                        <div className="panel" style={{ display: selectedMenu === "home" ? "block" : "none" }}>
                            <div className="group">
                                <label>Background</label>
                                <div className="color_palettes">
                                    {ColorCodes.map((color) => {
                                        return <Color key={color} theme={{ color }} onClick={() => { setHomeBGColor(color.replace("#", "")); setTheme("homeBGColor", color.replace("#", "")) }} />
                                    })}
                                    <Input maxLength={6} prefix={"#"} onChange={(e) => { setHomeBGColor(e.target.value); setTheme("homeBGColor", e.target.value,) }} type="text" value={homeBGColor.replace("#", "")} />
                                </div>
                            </div>
                            <div className="group">
                                <label>Brand Name</label>
                                <div>
                                    <Input placeholder="Enter the brand name" maxLength={20} onChange={(e) => { setBrandName(e.target.value); setTheme("brand", e.target.value,) }} type="text" value={brandName} />
                                </div>
                            </div>
                            <div className="group">
                                <label>Primary Text</label>
                                <div>
                                    <Input placeholder="Enter the primary text" maxLength={30} onChange={(e) => { setHomePrimaryText(e.target.value); setTheme("homePrimaryText", e.target.value,) }} type="text" value={homePrimaryText} />
                                </div>
                                <label>Primary Color</label>
                                <div className="color_palettes">
                                    {ColorCodes.map((color) => {
                                        return <Color key={color} theme={{ color }} onClick={() => { setHomePrimaryColor(color.replace("#", "")); setTheme("homePrimaryColor", color.replace("#", "")) }} />
                                    })}
                                    <Input maxLength={6} prefix={"#"} onChange={(e) => { setHomePrimaryColor(e.target.value); setTheme("homePrimaryColor", e.target.value,) }} type="text" value={homePrimaryColor.replace("#", "")} />
                                </div>
                            </div>
                            <div className="group">
                                <label>Secondary Text</label>
                                <div>
                                    <Input placeholder="Enter the secondary text" maxLength={40} onChange={(e) => { setHomeSecondaryText(e.target.value); setTheme("homeSecondaryText", e.target.value,) }} type="text" value={homeSecondaryText} />
                                </div>
                                <label>Primary Color</label>
                                <div className="color_palettes">
                                    {ColorCodes.map((color) => {
                                        return <Color key={color} theme={{ color }} onClick={() => { setHomeSecondaryColor(color.replace("#", "")); setTheme("homeSecondaryColor", color.replace("#", "")) }} />
                                    })}
                                    <Input maxLength={6} prefix={"#"} onChange={(e) => { setHomeSecondaryColor(e.target.value); setTheme("homeSecondaryColor", e.target.value,) }} type="text" value={homeSecondaryColor.replace("#", "")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="preview">
                {/* {!loaded && <Loading backgournd={"#00000070"} width={toggleSetting ? "70%" : "100%"} />} */}
                {/* <iframe id="slz_shop_web_site" src={config.shopWebURL} onLoad={() => onLoad()} style={{ display: loaded ? "block" : "none" }} /> */}
            </div>
        </div>
    );
}

export default connect(null, {})(WebView);