import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Row, Col } from 'antd';
import '../../../assets/sass/dsk/order.sass';
import { updateOrderStatus, getDeliveryExecs, assignDelivery, getOrderById, downloadOrderInvoice } from "../../../actions/orderActions";
import { onUpdateOrderStatus } from "../../../actions/uiAction";
import dayjs from 'dayjs';
import Loading from '../common/Loading';
import { CONSTANTS } from '../../../constants';
import apiConfig from '../../../apiConfig';
import NoImageAvailableIcn from "../../../assets/images/No-image-available.png";
import DeliveryService from './DeliveryService';
import PopupConfirm from '../common/PopupConfirm';
import { Link, useLocation } from 'react-router-dom';

const Details = (props: any) => {
    const location = useLocation();
    const [, storeId, currentMenu, currentSubMenu] = location.pathname.split("/");
    const [showDeliveryExecs, setShowDeliveryExecs] = useState(false);
    const [order, setOrder] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [disableBack, setDisableBack] = useState(false);
    const [popupConfirmConfig, setPopupConfirmConfig] = useState(null);
    const windowHeight = window.innerHeight;
    useEffect(() => {
        if (props.orderId) {
            getOrderDetails(props.orderId);
        }
        //eslint-disable-next-line
    }, [props.orderId]);

    useEffect(() => {
        if (props.disableBack) {
            setDisableBack(props.disableBack);
        }
    }, [props.disableBack])

    const getOrderDetails = async (orderId) => {
        let resp = await props.getOrderById(orderId);
        if (resp) {
            setOrder(resp);
        }
    }

    const updateOrderStatus = async (status, queryParams = null) => {
        if (status && order.id) {
            if (status === "ship") {
                setShowDeliveryExecs(true);
            } else {
                setIsLoading(true)
                const resp = await props.updateOrderStatus(order.id, status, queryParams);
                if (resp && resp.order) {
                    props.onUpdateOrderStatus(true);
                    setOrder(resp.order)
                }
                setIsLoading(false);

            }
        }
    }

    const onClose = (resp) => {
        if (resp && resp.id) {
            setOrder(resp);
        }
        setShowDeliveryExecs(false);
    }

    const goBack = () => {
        if (props.goback) {
            props.goback();
        }
    }

    const downloadInvoice = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const blob = await props.downloadOrderInvoice(props.orderId);
        if (blob) {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${props.orderId}_invoice.pdf`;
            document.body.append(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
    }

    const onChangeStatus = (status) => {
        let popupConfirmDetail = null;
        popupConfirmDetail = {
            title: `${status} Order`,
            renderContent: () => {
                return <div>
                    <div>Are you sure you want to change the status to {status}?</div>
                </div>
            },
            yes: {
                label: (status === "cancel" && order?.payment?.mode === "online") ? "Refund & Cancel" : (status === "cancel" && order?.payment?.mode !== "online") ? "Cancel" : "Yes",
                callback: async () => {
                    setPopupConfirmConfig(null);
                    updateOrderStatus(status);
                },
                style: status === "cancel" ? {
                    background: "#C70039",
                    border: "1px solid #C70039"
                } : {}
            },
            no: {
                label: "No",
                callback: () => {
                    setPopupConfirmConfig(null);
                }
            }
        }
        if (status === "cancel" && order?.payment?.mode === "online") {
            popupConfirmDetail['okay'] = {
                label: "Cancel without refund",
                callback: async () => {
                    setPopupConfirmConfig(null);
                    updateOrderStatus("cancel", { skipRefund: true });
                },
                style: {
                    fontWeight: "bold",
                    fontSize: 14,
                    background: "#FFF",
                    color: "#C70039",
                    border: "1px solid #C70039"
                }
            }
        }
        setPopupConfirmConfig(popupConfirmDetail);
    }

    return (
        order && <Row className="order-view">
            {/* <Col className="header" span={24}> */}
            {/* <span className="link" onClick={gotoOrders}>Orders</span> <RightOutlined className="nav" /> <span className="head">Details</span> */}
            {/* </Col> */}
            <Col span={24} className="title">
                <Row className="group" style={{ width: '100%' }}>
                    <Col span="24">
                        <div className="action-bar" style={{ justifyContent: "space-between" }}>
                            {!disableBack && <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', cursor: 'pointer' }} onClick={goBack} ><ArrowLeftOutlined /><label style={{ marginLeft: 10 }}>Back to orders</label></div>}
                            <div style={{ display: 'flex' }}>
                                {order.status === "awaitingConfirmation" &&
                                    <div style={{ display: 'flex' }}>
                                        <div className="reject" onClick={() => { onChangeStatus("decline") }}>Reject</div>
                                        <div className="accept" onClick={() => { onChangeStatus("confirm") }}>Accept</div>
                                    </div>}
                                {order.status === "awaitingFulfilment" && !order.pickup &&
                                    <div style={{ display: 'flex' }}>
                                        <div className="reject" onClick={() => { onChangeStatus("cancel") }}>Cancel</div>
                                        <div className="accept" onClick={() => { onChangeStatus("ship") }}>Ship</div>
                                    </div>}
                                {order.status === "awaitingFulfilment" && order.pickup &&
                                    <div style={{ display: 'flex' }}>
                                        <div className="reject" onClick={() => { onChangeStatus("cancel") }}>Cancel</div>
                                        <div className="accept" onClick={() => { onChangeStatus("readyForPickup") }}>Ready for pickup</div>
                                    </div>}
                                {(order.status === "shipped" || order.status === "awaitingPickup") &&
                                    <div style={{ display: 'flex' }}>
                                        <div className="reject" onClick={() => { onChangeStatus("cancel") }}>Cancel</div>
                                        <div className="accept" onClick={() => { onChangeStatus("complete") }}>Complete</div>
                                    </div>
                                }
                                {currentSubMenu !== "paymentfailures" && <div style={{ display: 'flex' }}>
                                    <div className="accept" onClick={downloadInvoice}>Download Invoice</div>
                                </div>}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col span={24} className="contains">
                <Row>
                    <Col span={7} style={{ height: windowHeight - 170, overflowY: "auto" }}>
                        <div className="info">
                            <div className="title">Order Info</div>
                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">OrderNo:</div>
                                </Col>
                                <Col span={12}>
                                    <div className="value">#{order.orderNo}</div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Date & Time:</div>
                                </Col>
                                <Col span={12}>
                                    {dayjs(order.createdAt).format('ddd')}, {dayjs(order.createdAt).format('MMM')} {dayjs(order.createdAt).format('DD')},{dayjs(order.createdAt).format('YYYY')}, {dayjs(order.createdAt).format('hh:mm A')}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Payment Method:</div>
                                </Col>
                                <Col span={12}>
                                    <div className="value">{order.payment && order.payment.mode.toUpperCase()}</div>
                                </Col>
                            </Row>
                            {/* <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Invoice:</div>
                                </Col>
                                <Col span={12}>
                                    <div className="value">-</div>
                                </Col>
                            </Row> */}
                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Status:</div>
                                </Col>
                                <Col span={12} style={{ display: 'flex' }}>
                                    <div className="value" style={{
                                        background: CONSTANTS.OrderStatus.colors[order.status] + 22,
                                        padding: "0px 8px",
                                        borderRadius: 4,
                                        color: CONSTANTS.OrderStatus.colors[order.status]
                                    }}>{CONSTANTS.OrderStatus.labels[order.status] || order.status}</div>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <div className="title" style={{ justifyContent: "space-between" }}>
                                <div>Delivery Slot</div>
                            </div>
                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Slot name:</div>
                                </Col>
                                <Col span={12}>
                                    <div className="value">{order.deliverySlot?.name}</div>
                                </Col>
                            </Row>
                            {order.deliverySlot?.date && <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Date:</div>
                                </Col>
                                <Col span={12}>
                                    <div className="value">{dayjs(order.deliverySlot?.date).format('ddd')}, {dayjs(order.deliverySlot?.date).format('MMM')} {dayjs(order.deliverySlot?.date).format('DD')}, {dayjs(order.deliverySlot?.date).format('YYYY')}</div>
                                </Col>
                            </Row>}
                            {order.shippingAddress && <>
                                <div className="divider"></div>
                                <div className="title" >Shipping Address</div>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">Address:</div>
                                    </Col>
                                    <Col span={12}>
                                        {order.shippingAddress && <div className="value" style={{
                                            display: "flex",
                                            justifyContent: 'flex-start',
                                            flexWrap: 'wrap'
                                        }}>
                                            <div style={{ marginRight: 4 }}>
                                                {order.shippingAddress?.houseName},
                                            </div>
                                            <div style={{ marginRight: 4 }}>
                                                {order.shippingAddress?.addressLine},
                                            </div>
                                            <div style={{ marginRight: 4 }}>
                                                {order.pickup ? <div style={{ color: "#ff4800" }}>Outlet Pickup</div> : <div>
                                                    {order.shippingAddress?.area}
                                                </div>}
                                            </div>
                                        </div>}
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">City:</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="value">{order.shippingAddress?.city}</div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">Pin Code:</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="value">{order.shippingAddress?.zipcode}</div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">State:</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="value">{order.shippingAddress?.state}</div>
                                    </Col>
                                </Row>
                            </>}
                            {order.pickupAddress && <>
                                <div className="divider"></div>
                                <div className="title" >Pickup Address</div>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">Outlet:</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="value">{order.pickupAddress?.outletName}</div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">Address:</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="value" style={{
                                            display: "flex",
                                            justifyContent: 'flex-start',
                                            flexWrap: 'wrap'
                                        }}>
                                            <div style={{ marginRight: 4 }}>
                                                {order.pickupAddress?.addressLine1},
                                            </div>
                                            <div style={{ marginRight: 4 }}>
                                                {order.pickupAddress?.area}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">City:</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="value">{order.pickupAddress?.city}</div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">Pin Code:</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="value">{order.pickupAddress?.zipcode}</div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">State:</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="value">{order.pickupAddress?.state}</div>
                                    </Col>
                                </Row>
                            </>}
                        </div>
                    </Col>
                    <Col span={10} style={{ maxHeight: windowHeight - 170 }}>
                        <div className="o_items">
                            <div className="title">Order Items</div>
                            <div className="divider"></div>
                            <Row>
                                <Col span={16} className="label" style={{ textAlign: "left", left: "16px" }}>Products</Col>
                                <Col span={5} className="label">Quantity</Col>
                                <Col span={3} className="label" style={{ textAlign: "right", right: order.items.length > 2 ? "18px" : "16px" }}>Price</Col>
                            </Row>
                            <div className="divider"></div>
                            <div style={{ maxHeight: windowHeight - 300, overflowY: "auto" }}>
                                {order.items.map((item) => {
                                    const ImgSrc = (!item.images || item.images === null || item.images?.length === 0) ? NoImageAvailableIcn : apiConfig.baseImageUrl + item.images[0];
                                    return <div key={item.id}>
                                        <Row>
                                            <Col span={16} className="value" style={{ display: "flex", textAlign: "left", left: "16px" }}>
                                                <div className="product">
                                                    <img alt='Icon' src={ImgSrc} />
                                                </div>
                                                <div>
                                                    <div style={{ fontWeight: 600 }}>{item.title}</div>
                                                    <div style={{
                                                        color: "#404040",
                                                        fontSize: 13
                                                    }}>{item.subtitle}</div>
                                                    <div style={{ display: 'flex', color: "#404040" }}>{item.addons.map((a, index) => {
                                                        return <div style={{
                                                            fontSize: 13,
                                                            marginRight: 4
                                                        }} title={`Price ${a.price} Tax ${a.tax}`}>{a.title}{item.addons.length - 1 === index ? "" : ","}</div>
                                                    })}</div>
                                                </div>
                                            </Col>
                                            <Col span={5} style={{ paddingTop: "20px" }} className="value">{item.quantity} x {(item.salePrice?.toLocaleString('en-IN') || item.price?.toLocaleString('en-IN'))}</Col>
                                            <Col span={3} className="value" style={{ paddingTop: "20px", textAlign: "right", right: "16px" }}>₹{(item.totalBeforeTax).toLocaleString('en-IN')}</Col>
                                        </Row>
                                        <div className="divider"></div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </Col>
                    <Col span={7} style={{ maxHeight: windowHeight - 170, overflowY: "auto" }}>
                        <div className="info">
                            <div className="title" style={{ justifyContent: "space-between" }}>
                                <div>Customer Details</div>
                            </div>
                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Name:</div>
                                </Col>
                                <Col span={12}>
                                    {currentMenu === "orders" ? <Link className="view-details" to={`/${storeId}/customers/${order.customer.id}/orders`} >
                                        <div className="value" style={{
                                            textDecoration: "underline",
                                            fontSize: 14,
                                            color: "#1890ff",
                                            cursor: "pointer"
                                        }}>{order.customer.fullName}</div>
                                    </Link> : <div>{order.customer.fullName}</div>}
                                </Col>
                            </Row>

                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Email ID:</div>
                                </Col>
                                <Col span={12}>
                                    <div className="value">{order.customer.email}</div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Phone Number:</div>
                                </Col>
                                <Col span={12}>
                                    <div className="value">{order.customer.mobileNo}</div>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <div className="title">Customer Billing</div>
                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Item Subtotal</div>
                                </Col>
                                <Col span={12}>
                                    <div className="price">₹{order?.billing?.itemSubtotal?.toLocaleString('en-IN')}</div>
                                </Col>
                            </Row>
                            {order?.billing?.discount && <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Discount</div>
                                </Col>
                                <Col span={12}>
                                    <div style={{
                                        color: "rgb(0, 153, 137)",
                                        fontWeight: 400
                                    }} className="price">-₹{order?.billing?.discount}</div>
                                </Col>
                            </Row>}
                            {order?.billing?.additionalCharges?.map((a, i) => {
                                return <Row key={`additional_charges_${i}`} style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">{a?.label}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="price">₹{a?.fee?.toLocaleString('en-IN')}</div>
                                    </Col>
                                </Row>
                            })}
                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Tax</div>
                                </Col>
                                <Col span={12}>
                                    <div className="price">₹{order?.billing?.tax?.toLocaleString('en-IN')}</div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Total</div>
                                </Col>
                                <Col span={12}>
                                    <div className="price">₹{order?.billing?.toPay?.toLocaleString('en-IN')}</div>
                                </Col>
                            </Row>
                            <div className="divider"></div>
                            <div className="title">Payment</div>
                            <Row style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <div className="label">Mode</div>
                                </Col>
                                <Col span={12}>
                                    <div className="price">{order?.payment?.mode.toUpperCase()}</div>
                                </Col>
                            </Row>
                            {order?.payment?.gateway && <>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">Gateway</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="price">{order?.payment?.gateway}</div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">Transaction ID</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="price">{order?.payment?.gatewayTxnId}</div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col span={12}>
                                        <div className="label">Payment ID</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="price">{order?.payment?.gatewayResp?.paymentId}</div>
                                    </Col>
                                </Row>
                                {currentSubMenu === "paymentfailures" && <Row style={{ marginTop: 10 }}>
                                    <Col span={24}>
                                        <div className="label" style={{ color: "#C70039" }}>Error</div>
                                        <div className="price" style={{ textAlign: "left" }}>{order?.payment?.gatewayResp?.error?.message || order?.payment?.gatewayResp?.error?.description}</div>
                                    </Col>
                                </Row>}
                            </>}
                        </div>
                    </Col>
                </Row>
            </Col>
            <DeliveryService onClose={(resp) => { onClose(resp) }} selectedOrder={order} selectedOuId={order.ouId} isVisible={showDeliveryExecs} />
            {isLoading && <Loading background="#00000070"></Loading>}
            {popupConfirmConfig && <PopupConfirm config={popupConfirmConfig}></PopupConfirm>}

        </Row >
    )
    // }
}



function mapStateToProps(state: any) {
    return {
        product: state.product
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onUpdateOrderStatus,
        updateOrderStatus,
        getDeliveryExecs,
        assignDelivery,
        getOrderById,
        downloadOrderInvoice
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);