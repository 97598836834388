import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router";
import ComingSoonSvg from "../../../assets/images/settings/coming_soon.svg";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Menus } from "./Constants";
// import { Menus } from "./Contants";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; 
    height: 80vh;
`;
const Title = styled.div`
    font-size:  16px;
    font-weight: bold;
    letter-spacing: 1.44px;
    color: #188FFC;
    text-transform: uppercase;
`;
const ComingSoonTxt = styled.div`
    font-size:36px;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 2px;
`;

const ComingSoon = (props: any) => {
    const { style } = props;
    const location = useLocation();
    const [, , currentMainMenu, currentSubMenu] = location.pathname.split("/");
    const [cName, setCName] = useState(null);
    useEffect(() => {
        if (currentSubMenu) {
            if (Menus[currentMainMenu] && currentSubMenu) {
                const menu = Menus[currentMainMenu].filter((i) => i.key === currentSubMenu);
                if (menu && menu.length > 0) {
                    setCName(menu[0].label)
                }
            }
        }
    }, [currentMainMenu, currentSubMenu])
    return <Container style={{ ...style }}>
        <img alt="ComingSoon" src={ComingSoonSvg} />
        {cName && <Title>{cName}</Title>}
        <ComingSoonTxt>Coming Soon</ComingSoonTxt>
    </Container>
}

export default ComingSoon;