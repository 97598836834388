import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from '../store';
import axiosWrapper from "./axiosWrapper";

export const getCollections = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.collectionsUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Collections Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};


export const getCollectionById = (cId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.collectionByIdUrl.replace('{sId}', sId).replace('{collectionId}', cId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Collection By Id Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const addCollection = (data) => {
    const options = {
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.collectionsUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Add New Collection Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}

export const updateCollection = (data, cId) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.collectionByIdUrl.replace('{sId}', sId).replace('{collectionId}', cId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Collection Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}

export const deleteCollection = (cId) => {
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.collectionByIdUrl.replace('{sId}', sId).replace('{collectionId}', cId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Delete Collection Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
                return { error: error.response.data }
            }
        }
    };
}
