import { useState } from "react";
import NoImageFoundSvg from "../../../assets/images/theme-placeholder.svg";
import ConfigSvg from "../../../assets/images/setting_black.svg";
import { useNav } from "../../../helpers/customHook";
import { useSelector } from "react-redux";

const Card = (props: any) => {
    const { selectedStore } = useSelector((state: any) => state.ui);
    const navigateTo = useNav();
    const { theme, openConfig, installTheme, type, onActive } = props
    const [selectedPresets, setSelectedPresets] = useState({});
    const onSelectPreset = (theme: any, preset: any) => {
        let imgUrl = null;
        if (preset?.images.length > 0) {
            imgUrl = preset.images[0];
        }
        setSelectedPresets({
            ...selectedPresets,
            [theme.id]: {
                url: imgUrl,
                name: preset.name
            }
        });
    }
    const renderThemeImage = (theme: any) => {
        if (theme.presets && theme.presets.length > 0) {
            return selectedPresets[theme.id] ? `${theme.baseUrl}${selectedPresets[theme.id].url}` :
                theme?.presets[0]?.images.length > 0 ? `${theme.baseUrl}${theme?.presets[0]?.images[0]}` : NoImageFoundSvg;
        }
        return NoImageFoundSvg;
    }
    return <div style={{ cursor: theme.installed ? 'pointer' : 'default' }} className="card" onClick={() => theme.installed && openConfig()} key={theme.id}>
        <div className="preview">
            <img src={renderThemeImage(theme)} alt="preview" />
        </div>
        <div className="details">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="theme-title">{theme.title}</div>
                <div className="presets">
                    {theme.presets && theme.presets.map((preset: any, index: number) => {
                        return <div key={index} className="preset" title={preset.displayName}
                            onClick={() => onSelectPreset(theme, preset)}
                            style={{
                                cursor: selectedPresets[theme.id]?.name === preset.name ? 'default' : 'pointer',
                                border: (!selectedPresets[theme.id] && index === 0) || selectedPresets[theme.id]?.name === preset.name ? '1px solid #949494' : '1px solid #d9d9d9',
                            }} >
                            <div className="p-color" style={{ background: preset.color }}></div>
                        </div>
                    })}
                </div>
            </div>
            {type === "discover" && <div className="action">
                <div>
                    {!theme.installed && <button className="sp_btn try-this" onClick={() => installTheme(theme)}>Try Theme</button>}
                    {theme.installed && <div className="installed">Installed</div>}
                </div>
                <div className="price">
                    Free
                </div>
            </div>}
            {type === "installed" && <div className="action">
                <div>
                    {!theme.active && <button style={{ width: 136 }} className="sp_btn try-this" onClick={() => onActive(theme?.id)}>Use this theme</button>}
                    {theme.active && <div className="active">Active</div>}
                </div>
                <button className="sp_btn config-settings" style={{ width: 100 }} onClick={() => navigateTo(`/${selectedStore.name.toLowerCase()}/themeconfigurator/${theme.name}`, { state: { themeId: theme.id } })}>
                    <img src={ConfigSvg} alt="config" style={{ marginRight: 6 }} />
                    Settings
                </button>
            </div>}
        </div>
    </div>
}
export default Card;