import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import '../../../../../assets/sass/dsk/outlet.sass';
import { AimOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { searchAddress, getAddress } from "../../../../../actions/setupStoreActions";
import { createOutlet, updateOutlet, updateOutletSlot } from "../../../../../actions/outletAction";
import { getDeliverySlots } from "../../../../../actions/deliverySlotAction";
import { Row, Col, Select, Input, Drawer } from 'antd';
import InputValidation from '../../../common/InputValidation';
import { isNotNullOrUndefinedString } from '../../../../../helpers/util';
import Loading from '../../../common/Loading';
import { Countries } from '../../../../../countries';

const { Option } = Select;
const windowHeight = window.innerHeight;
const Update = (props) => {
    let ErrorVariables = {
        name: {
            message: "",
            type: ""
        },
        address1: {
            message: "",
            type: ""
        },
        area: {
            message: "",
            type: ""
        },
        city: {
            message: "",
            type: ""
        },
        contactNumber: {
            message: "",
            type: ""
        },
        state: {
            message: "",
            type: ""
        },
        zipcode: {
            message: "",
            type: ""
        },
        country: {
            message: "",
            type: ""
        },
        range: {
            message: "",
            type: ""
        },
        deliverySlots: {
            message: "",
            type: ""
        },
    }
    const dispatch = useDispatch();
    const [deliverySlots, setDeliverySlots] = useState([]);
    const [selectedDeliverySlot, setSelectedDeliverySlot] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [name, setName] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [outlet, setOutlet] = useState(null);
    const [sessionToken, setSessionToken] = useState(null);
    const [addressSearch, setAddressSearch] = useState("");
    const [address, setAddress] = useState(null);
    const [contactNumber, setContactNumber] = useState(null);
    const [deliveryType, setDeliveryType] = useState("maxDistance");
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [maxDistance, setMaxDistance] = useState(1);
    const [postalCodes, setPostalCodes] = useState([]);
    const [error, setError] = useState({ ...ErrorVariables });

    const init = async () => {
        const resp: any = await getDeliverySlots()(dispatch);
        if (resp && resp.deliverySlots) {
            setDeliverySlots(resp.deliverySlots);
        }
        let { outlet: outletDetail } = props;
        let addressLine1 = (outletDetail && outletDetail.address && outletDetail.address.addressLine1) || "",
            addressLine2 = (outletDetail && outletDetail.address && outletDetail.address.addressLine2) || "",
            area = (outletDetail && outletDetail.address && outletDetail.address.area) || "",
            city = (outletDetail && outletDetail.address && outletDetail.address.city) || "",
            state = (outletDetail && outletDetail.address && outletDetail.address.state) || "",
            zipcode = (outletDetail && outletDetail.address && outletDetail.address.zipcode) || "",
            country = (outletDetail && outletDetail.address && outletDetail.address.country) || "",
            plusCode = (outletDetail && outletDetail.placeDetails && outletDetail.placeDetails.plusCode) || "";
        let geoCoords = outletDetail?.placeDetails?.geometry?.coordinates?.length === 2 ? {
            lat: outletDetail.placeDetails.geometry.coordinates[1],
            lng: outletDetail.placeDetails.geometry.coordinates[0]

        } : {
            lat: 12.94356,
            lng: 80.2386649
        };
        let formattedAddress = (outletDetail && outletDetail.placeDetails && outletDetail.placeDetails.formattedAddress) || "",
            name = (outletDetail && outletDetail.placeDetails && outletDetail.placeDetails.name) || "",
            placeId = (outletDetail && outletDetail.placeDetails && outletDetail.placeDetails.placeId) || "";
        let addressDetail = {
            formattedAddress,
            name,
            placeId,
            addressLine1,
            addressLine2,
            area,
            city,
            state,
            zipcode,
            country,
            plusCode,
            geoCoords,
        };
        // let contactDetail = outletDetail && outletDetail.contactNumber && outletDetail.contactNumber.length > 0 ? outletDetail.contactNumber : [{ "phone": "", "type": "mobile", }];
        setAddress("");
        setEdit(outletDetail && outletDetail.id ? false : true);
        setOutlet(outletDetail);
        setName(outletDetail && outletDetail.name);
        setAddress(addressDetail);
        setContactNumber(outletDetail.contactNumber);
        if (outletDetail && outletDetail.deliveryArea) {
            setDeliveryType(outletDetail.deliveryArea.type);
            if (outletDetail.deliveryArea.maxDistance) {
                setMaxDistance(outletDetail.deliveryArea.maxDistance);
            } else if (outletDetail.deliveryArea.countries) {
                setCountries(outletDetail.deliveryArea.countries);
            } else if (outletDetail.deliveryArea.cities) {
                setCities(outletDetail.deliveryArea.cities);
            } else if (outletDetail.deliveryArea.states) {
                setStates(outletDetail.deliveryArea.states);
            } else if (outletDetail.deliveryArea.postalCodes) {
                setPostalCodes(outletDetail.deliveryArea.postalCodes);
            }
        } else {
            setDeliveryType("maxDistance");
            setMaxDistance(1);
        }
        if (outletDetail.deliverySlotConfig && outletDetail.deliverySlotConfig.slotIds) {
            setSelectedDeliverySlot(outletDetail.deliverySlotConfig.slotIds)
        }
    }

    useEffect(() => {
        if (props.isVisible) {
            init();
        }
        //eslint-disable-next-line
    }, [props.isVisible])

    const onHandleCancel = () => {
        setDeliverySlots([]);
        setSelectedDeliverySlot([]);
        setLoading(false);
        setEdit(false);
        setName("");
        setSuggestions([]);
        setOutlet(null);
        setSessionToken(null);
        setAddressSearch("");
        setAddress(null);
        setContactNumber(null);
        setDeliveryType("maxDistance");
        setCities([]);
        setStates([]);
        setCountries([]);
        setMaxDistance(1);
        setError({ ...ErrorVariables });
        props.onClose();
    }
    const onSearchAddress = async (value) => {
        if (value && value.length > 2) {
            let resp: any = await searchAddress(value)(dispatch);
            if (resp && resp.suggestions) {
                setSuggestions(resp.suggestions);
                setSessionToken(resp.sessionToken);
            }
        }
    }
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        }
    }


    const showPosition = (position) => {
        if (position && position.coords) {
            let { geoCoords } = address;
            if (position.coords.latitude) {
                geoCoords.lat = position.coords.latitude;
            }
            if (position.coords.longitude) {
                geoCoords.lng = position.coords.longitude;
            }
            setAddress({ ...address, geoCoords });
        }
        console.log("Latitude: " + position.coords.latitude + "<br>Longitude: " + position.coords.longitude);
    }

    const updateAddress = (e) => {
        if (e.target.name) {
            setAddress({ ...address, [e.target.name]: e.target.value });
        }
    }

    const onHandleSubmit = async () => {
        setLoading(true);
        let isError = false,
            resp = null;
        console.log({ ErrorVariables })
        setError({ ...ErrorVariables });
        if (!props.isDeliveryAction) {
            if (!isNotNullOrUndefinedString(name)) {
                error.name.type = "error";
                error.name.message = "Fill the outlet name";
                isError = true;
            } else if (name === "Duplicate check") {
                error.name.type = "error";
                error.name.message = "Outlet name already exists";
                isError = true;
            }
            if (!isNotNullOrUndefinedString(contactNumber) || !/^\+?[0-9-\s]{8,14}\d$/.test(contactNumber)) {
                error.contactNumber.message = "Enter a valid mobile number";
                error.contactNumber.type = "error";
                isError = true;
            }
            if (!isNotNullOrUndefinedString(address.area)) {
                error.area.message = "Fill the outlet area";
                error.area.type = "error";
                isError = true;
            }
            if (!isNotNullOrUndefinedString(address.addressLine1)) {
                error.address1.type = "error";
                error.address1.message = "Fill the outlet address line1";
                isError = true;
            }
            if (!isNotNullOrUndefinedString(address.state)) {
                error.state.type = "error";
                error.state.message = "Fill the outlet state";
                isError = true;
            }
            let zcde = address.zipcode
            if (!isNotNullOrUndefinedString(zcde) || !/^\+?[0-9-\s]{4,14}\d$/.test(zcde)) {
                error.zipcode.type = "error";
                error.zipcode.message = "Enter a valid zipcode";
                isError = true;
            }
            if (!isNotNullOrUndefinedString(address.city)) {
                error.city.type = "error";
                error.city.message = "Fill the outlet city";
                isError = true;
            }
        } else {
            if (deliveryType === "maxDistance" && maxDistance < 1) {
                error.range.type = "error";
                error.range.message = "Max distance greater then zero";
                isError = true;
            } else if (deliveryType === "state" && states.length === 0) {
                error.range.type = "error";
                error.range.message = "choose a minimum of one state";
                isError = true;
            } else if (deliveryType === "city" && cities.length === 0) {
                error.range.type = "error";
                error.range.message = "choose a minimum of one city";
                isError = true;
            } else if (deliveryType === "country" && countries.length === 0) {
                error.range.type = "error";
                error.range.message = "choose a minimum of one country";
                isError = true;
            } else if (deliveryType === "postalCode" && postalCodes.length === 0) {
                error.range.type = "error";
                error.range.message = "choose a minimum of one postal code";
                isError = true;
            }
            if (selectedDeliverySlot.length === 0) {
                error.deliverySlots.type = "error";
                error.deliverySlots.message = "choose a minimum of one delivery slot";
                isError = true;
            }
        }
        if (!isError) {
            let outletPayload = {
                name,
                contactNumber,
                address: {
                    addressLine1: address.addressLine1,
                    addressLine2: address.addressLine2,
                    area: address.area,
                    city: address.city,
                    state: address.state,
                    zipcode: address.zipcode,
                    country: "IN"
                },
                placeDetails: {
                    placeId: address.placeId,
                    name: address.name,
                    plusCode: address.plusCode,
                    formattedAddress: address.formattedAddress,
                    geometry: {
                        type: "Point",
                        coordinates: [address.geoCoords.lng, address.geoCoords.lat]
                    }
                },
                deliveryArea: {
                    type: deliveryType
                }

            }
            if (outletPayload.deliveryArea.type === "maxDistance") {
                outletPayload.deliveryArea['maxDistance'] = maxDistance;
            } else if (outletPayload.deliveryArea.type === "state") {
                outletPayload.deliveryArea['states'] = states;
            } else if (outletPayload.deliveryArea.type === "city") {
                outletPayload.deliveryArea['cities'] = cities;
            } else if (outletPayload.deliveryArea.type === "country") {
                outletPayload.deliveryArea['countries'] = countries;
            } else if (outletPayload.deliveryArea.type === "postalCode") {
                outletPayload.deliveryArea['postalCodes'] = postalCodes;
            }
            // console.log(!this.props.isDeliveryAction && this.state.outlet && this.state.outlet.id)
            if (!outlet || !outlet.id) {
                resp = await createOutlet(outletPayload)(dispatch);
            } else if (outlet && outlet.id) {
                resp = await updateOutlet(outlet.id, outletPayload)(dispatch)
            }
            if (outlet && outlet.id) {
                resp = await updateOutletSlot(outlet.id, { slotIds: selectedDeliverySlot })(dispatch);
            }
            setLoading(false);
            if (resp && (resp.isUpdated || resp.ouId)) {
                onHandleCancel();
            }
        } else {
            setLoading(false);
            setError({ ...error })
        }
    }

    const selectedAddress = async (item) => {
        setSuggestions([]);
        setAddress({ ...address, "placeId": item.placeId });
        let resp: any = await getAddress(item.placeId, sessionToken)(dispatch);
        if (resp && resp.addressComponents) {
            let { addressLine, area, city, country, state, zipcode } = resp.addressComponents;
            let { geometry, formattedAddress, name, plusCode } = resp;
            setAddressSearch(name || "");
            let addressDetail = {
                name: name || "",
                addressLine1: addressLine || "",
                addressLine2: "",
                formattedAddress: formattedAddress || "",
                placeId: item.placeId,
                zipcode: zipcode || "",
                area: area || "",
                city: city || "",
                state: state || "",
                country: country || "",
                plusCode: plusCode,
                geoCoords: {}
            }
            if (!plusCode) {
                alert("PlusCode is missing");
            }
            if (geometry && geometry.coordinates && geometry.coordinates.length === 2) {
                addressDetail.geoCoords = {
                    lat: geometry.coordinates[1],
                    lng: geometry.coordinates[0]
                };
            }
            setAddress({ ...address, ...addressDetail });
        }
    }

    return (
        <Drawer
            width={470}
            closable={false}
            onClose={onHandleCancel}
            className="outlet-update"
            visible={props.isVisible}
            footer={
                <div className="footer">
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div className="sp_btn" onClick={onHandleCancel} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 16, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                        {props.outlet && !isEdit && <div className="sp_btn" onClick={() => { setEdit(true) }} style={{ width: 120, fontSize: 14 }}>Edit</div>}
                        {isEdit && <div className="sp_btn" onClick={onHandleSubmit} style={{ width: 155, fontSize: 14 }}>{props.outlet && props.outlet.id ? "Update Outlet" : "Create Outlet"}</div>}
                    </div>
                </div>
            }
        >
            <Row>
                {/* {!this.props.isDeliveryAction && <Col span={12}>
                        {address && <MapWidget lat={address.geoCoords.lat} lng={address.geoCoords.lng}></MapWidget>}
                    </Col>} */}
                <Col span={24} className="content" style={{ marginTop: 0 }}>
                    <div className="header">
                        <ArrowLeftOutlined className="arrow" onClick={onHandleCancel}></ArrowLeftOutlined>
                        <div className="title">{props.isDeliveryAction ? "Delivery " : "Outlet "} Details</div>
                    </div>
                    {/* <div className="group title">
                            <ArrowLeftOutlined className="back" onClick={this.onHandleCancel} style={{
                                position: "absolute",
                                cursor: "pointer"
                            }} ></ArrowLeftOutlined>
                            Outlet Details
                        </div> */}
                    <div style={{ height: windowHeight - 166, overflowY: "auto", marginTop: 30 }}>
                        {!props.isDeliveryAction && address && <>
                            <div className="group">
                                <div className="label">Outlet Name *</div>
                                <InputValidation type={error.name.type} message={error.name.message}>
                                    <Input disabled={!isEdit} type="text" name="name" value={name} defaultValue={name} placeholder="Outlet name"
                                        onChange={(e) => setName(e.target.value)}
                                    //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                    />
                                </InputValidation>
                            </div>
                            {isEdit && <div className="group">
                                <div className="label">Search Address *</div>
                                <Input disabled={!isEdit} type="text" name="search" placeholder="Search here"
                                    value={addressSearch}
                                    onChange={(e) => { onSearchAddress(e.target.value); setAddressSearch(e.target.value) }}
                                    suffix={<AimOutlined onClick={getLocation} />}
                                //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                />
                                {suggestions.length > 0 && <div className="suggestions">
                                    {suggestions.map((item, index) => {
                                        return (
                                            <div key={item.placeId} style={{ borderBottomWidth: suggestions.length - 1 > index ? "1px" : "0px" }} className="item"
                                                onClick={() => selectedAddress(item)}>
                                                <div className="main">{item.mainText}</div>
                                                <div className="secondary">{item.secondaryText}</div>
                                            </div>
                                        )
                                    })}
                                </div>}
                            </div>}
                            <div className="group">
                                <div className="label">Address Line1 *</div>
                                <InputValidation type={error.address1.type} message={error.address1.message}>
                                    <Input disabled={!isEdit} type="text" name="addressLine1" value={address.addressLine1} defaultValue={address.addressLine1} placeholder="Address Line 1"
                                        onChange={updateAddress}
                                    //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                    />
                                </InputValidation>
                            </div>
                            <div className="group">
                                <div className="label">Address Line2</div>
                                {/* <InputValidation type={error.address2.type} message={error.address2.message}> */}
                                <Input disabled={!isEdit} type="text" name="addressLine2" defaultValue={address.addressLine2} placeholder="Address Line 2"
                                    onChange={updateAddress}
                                //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                />
                                {/* </InputValidation> */}
                            </div>
                            {/* <div className="group">
                                <div className="label">Landmark</div>
                                <InputValidation type={error.landmark.type} message={error.landmark.message}>
                                <Input type="text" name="landmark" defaultValue={address.landmark} placeholder="Landmark"
                                    onChange={this.updateAddress}
                                />
                                </InputValidation>
                            </div> */}
                            <div className="group">
                                <Row>
                                    <Col span={12} style={{ paddingRight: "15px" }}>
                                        <div className="label">Area *</div>
                                        <InputValidation type={error.area.type} message={error.area.message}>
                                            <Input disabled={!isEdit} type="text" name="area" value={address.area} defaultValue={address.area} placeholder="Area"
                                                onChange={updateAddress}
                                            //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                            />
                                        </InputValidation>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: "15px" }}>
                                        <div className="label">Zipcode *</div>
                                        <InputValidation type={error.zipcode.type} message={error.zipcode.message}>
                                            <Input disabled={!isEdit} type="text" maxLength={6} name="zipcode" value={address.zipcode} defaultValue={address.zipcode} placeholder="Zipcode"
                                                onChange={updateAddress}
                                            //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                            />
                                        </InputValidation>
                                    </Col>
                                </Row>
                            </div>
                            <div className="group">
                                <Row>
                                    <Col span={12} style={{ paddingRight: "15px" }}>
                                        <div className="label">City *</div>
                                        <InputValidation type={error.city.type} message={error.city.message}>
                                            <Input disabled={!isEdit} type="text" name="city" value={address.city} defaultValue={address.city} placeholder="City"
                                                onChange={updateAddress}
                                            //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                            />
                                        </InputValidation>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: "15px" }}>
                                        <div className="label">State *</div>
                                        <InputValidation type={error.state.type} message={error.state.message}>
                                            <Input disabled={!isEdit} type="text" name="state" value={address.state} defaultValue={address.state} placeholder="State"
                                                onChange={updateAddress}
                                            //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                            />
                                        </InputValidation>
                                    </Col>
                                </Row>
                            </div>
                            <div className="group">
                                <div className="label">Telephone *</div>
                                <InputValidation type={error.contactNumber.type} message={error.contactNumber.message}>
                                    <Input disabled={!isEdit} min="1" type="tel" pattern="[]" name="mobile" value={contactNumber} placeholder="10-digit mobile number or Landline"
                                        onChange={(e) => setContactNumber(e.target.value)}
                                    //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                    />
                                </InputValidation>
                            </div>
                        </>}
                        {props.isDeliveryAction && <>
                            <div className="group">
                                <div className="label">Delivery Range Type</div>
                                <Select disabled={!isEdit} value={deliveryType} onChange={(val) => { setError({ ...ErrorVariables }); setDeliveryType(val) }}>
                                    <Option value={"maxDistance"}>Max distance from outlet</Option>
                                    <Option value={"state"}>States</Option>
                                    <Option value={"city"}>Cities</Option>
                                    <Option value={"country"}>Country</Option>
                                    <Option value={"postalCode"}>Pincodes</Option>
                                </Select>
                            </div>

                            {deliveryType === "maxDistance" && <div className="group">
                                <div className="label">Max Distance (km)</div>
                                <InputValidation type={error.range.type} message={error.range.message}>
                                    <Input disabled={!isEdit} type="number" min="1" name="maxDistance" value={maxDistance} placeholder="Max Distance"
                                        onChange={(e) => setMaxDistance(parseInt(e.target.value))}
                                    />
                                </InputValidation>
                            </div>}
                            {deliveryType === "postalCode" && <div className="group">
                                <div className="label">Pincodes</div>
                                <InputValidation type={error.range.type} message={error.range.message}>
                                    <Select mode="tags" disabled={!isEdit} value={postalCodes} placeholder="Pincode"
                                        onChange={(value: any) => {
                                            let validatePincodes = [];
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            for (let i of value) {
                                                if ((i.length === 6 && !isNaN(i) && reg.test(i)) || i === '' || i === '-') {
                                                    validatePincodes.push(i)
                                                }
                                            }
                                            setPostalCodes(validatePincodes)
                                        }}
                                    />
                                </InputValidation>
                            </div>}
                            {deliveryType === "state" && <div className="group">
                                <div className="label">States</div>
                                <InputValidation type={error.range.type} message={error.range.message}>
                                    <Select disabled={!isEdit} mode='multiple' maxTagCount='responsive' value={states} onChange={(val) => setStates(val)}>
                                        {Countries && Countries.IND.map((item) => { return item.type.toLocaleLowerCase() === "state" && <Option key={item.name} value={item.name}>{item.name}</Option> })}
                                    </Select>
                                </InputValidation>
                            </div>}
                            {deliveryType === "city" && <div className="group">
                                <div className="label">Cities</div>
                                <InputValidation type={error.range.type} message={error.range.message}>
                                    <Select disabled={!isEdit} mode='multiple' maxTagCount='responsive' value={cities} onChange={(val) => setCities(val)}>
                                        {Countries && Countries.IND.map((item) => {
                                            return item.districts.map((citi) => {
                                                return <Option key={citi.name} value={citi.name}>{citi.name}</Option>
                                            })
                                        })}
                                    </Select>
                                </InputValidation>
                            </div>}
                            {deliveryType === "country" && <div className="group">
                                <div className="label">Countries</div>
                                <Select disabled={!isEdit} mode='multiple' maxTagCount='responsive' value={countries} onChange={(val) => setCountries(val)}>
                                    <Option value={'India'}>{'India'}</Option>
                                </Select>
                            </div>}
                            <div className="group">
                                <div className="label">Delivery Slot</div>
                                <InputValidation type={error.deliverySlots.type} message={error.deliverySlots.message}>
                                    <Select mode='multiple' maxTagCount='responsive' disabled={!isEdit} value={selectedDeliverySlot} onChange={(val) => setSelectedDeliverySlot(val)}>
                                        {deliverySlots.map((s) => {
                                            return <Option key={s.id} value={s.id}>{s.name}</Option>
                                        })}
                                    </Select>
                                </InputValidation>
                            </div>
                        </>}
                    </div>
                </Col>
                {isLoading && <Loading background="#00000070"></Loading>}
            </Row>
        </Drawer>
    )
}

export default Update;