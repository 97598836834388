import NoImageFoundSvg from "../../../../assets/images/theme-placeholder.svg";

const Card = (props: any) => {
    const { theme, preset, onApply } = props;
    const onPresetImage = (preset: any) => {
        return theme.baseUrl && preset?.images.length > 0 ? `${theme.baseUrl}${preset?.images[0]}` : NoImageFoundSvg;
    }
    return <div className="theme-apply-preset">
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
            <div className="popover-arrow"></div>
            <div className="container">
                <img className="theme-img" src={onPresetImage(preset)} alt="theme" />
                <div className="action">
                    <div className="title">
                        {preset.displayName}
                    </div>
                    <button className="apply" onClick={() => onApply(preset.name)}>Apply Preset</button>
                </div>
            </div>
        </div>
    </div>;
}
export default Card;