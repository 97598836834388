import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { ReactSortable } from "react-sortablejs";
// import DeleteSvg from "../../../../../../../assets/images/delete.svg";
import DragHandlSvg from "../../../../../../../assets/images/drag-handle.svg";

const Layouts = [
    { id: 'best_sellers', label: 'Best Sellers' },
    { id: 'top_picks', label: 'Top Picks' },
    { id: 'whats_new', label: 'Whats New' }
]
const Layout = (props: any) => {
    const { layoutPostion: dLayoutPostion } = props;
    const sortableOptions = {
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        ghostClass: "ghost",
        group: "shared",
        disabled: false
    };
    // const [reactSortableKey, setReactSortableKey]: any = useState(new Date().getTime());
    // const [sortableOptions, setSortableOptions] = useState({
    //     animation: 150,
    //     fallbackOnBody: true,
    //     swapThreshold: 0.65,
    //     ghostClass: "ghost",
    //     group: "shared",
    //     disabled: false
    // });
    const [sections, setSections] = useState(Layouts);
    // const deleteSection = (section) => {
    //     let newSections = [];
    //     sections.filter((s) => {
    //         if (section.id !== s.id) {
    //             newSections.push(s);
    //         }
    //     });
    //     setSections(newSections);
    // }
    useEffect(() => {
        if (dLayoutPostion && dLayoutPostion.length > 0) {
            let newLayoutPostion = [];
            for (let lp in dLayoutPostion) {
                for (let l of Layouts) {
                    if (l.id === dLayoutPostion[lp]) {
                        newLayoutPostion.push(l);
                    }
                }
            }
            setSections(newLayoutPostion);
        }
    }, [dLayoutPostion]);

    useEffect(() => {
        let newLayoutPostion = [];
        for (let l of sections) {
            newLayoutPostion.push(l.id);
        }
        props.setLayoutPostion(newLayoutPostion);
        //eslint-disable-next-line
    }, [sections]);
    return (
        <div style={{ marginBottom: 30 }}>
            <div className="header">Page Layout</div>
            <div className="section">
                <div className="item">
                    <div className="title">Header</div>
                </div>
                {/* key={reactSortableKey}  */}
                <ReactSortable list={sections} setList={setSections} {...sortableOptions} >
                    {sections.map((s) => {
                        return <div key={s.id} className="item">
                            <div className="drag_item">
                                <div className="left">
                                    <img alt='Drag Icon' src={DragHandlSvg} />
                                    <div className="title">{s.label}</div>
                                </div>
                                {/* <div className="right">
                                    <img src={DeleteSvg} onClick={() => deleteSection(s)} />
                                </div> */}
                            </div>
                        </div>
                    })}
                </ReactSortable>
                <div className="item">
                    <div className="title">Footer</div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, {})(Layout);