import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const AppDev = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const appData = params.get("appData");
    const [appState, setAppState] = useState(null);
    useEffect(() => {
        if (appData) {
            setAppState(JSON.parse(window.atob(appData)));
        }
    }, [appData]);
    return <>{appState?.host && <iframe style={{ border: "none", width: "100%", height: "100%" }} title="App" src={appState?.host} />}</>
}
export default AppDev;