import React, { useEffect, useState } from "react";
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import insert from 'markdown-it-ins'
import { Input, Popover } from "antd";
import { useDispatch } from "react-redux";
import { onUpdateSiteSettings } from "../../../../../../../actions/brandAction";
import { onCreatePayload } from "../../../../themeService";

const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    highlight(str, lang) { },
}).use(insert);
const { TextArea } = Input;
const MarkdownContent = [
    <div key='markdown_content'>
        <div key='bold' style={{ display: 'flex', marginBottom: 10, alignItems: 'center', color: "#1C3A66" }}>To <div style={{ color: '#1FC2CB', fontWeight: 'bold', margin: '0px 4px' }}> bold</div> text use <div style={{ color: '#1FC2CB', margin: '0px 4px' }}>**text**</div></div>
        <div key='emphasize' style={{ display: 'flex', marginBottom: 10, alignItems: 'center', color: "#1C3A66" }}>To <div style={{ color: '#1FC2CB', fontWeight: 'bold', margin: '0px 4px', fontStyle: 'italic' }}> emphasize</div> text use <div style={{ color: '#1FC2CB', margin: '0px 4px' }}>*text*</div></div>
        <div key='strike' style={{ display: 'flex', marginBottom: 10, alignItems: 'center', color: "#1C3A66" }}>To <div style={{ color: '#1FC2CB', fontWeight: 'bold', margin: '0px 4px', textDecoration: 'line-through' }}> Strike through</div> text use <div style={{ color: '#1FC2CB', margin: '0px 4px' }}>~~To Bold~~</div></div>
        <div key='link' style={{ display: 'flex', marginBottom: 10, alignItems: 'center', color: "#1C3A66" }}>To <div style={{ color: '#1FC2CB', fontWeight: 'bold', margin: '0px 4px' }}> Link</div> text use <div style={{ color: '#1FC2CB', margin: '0px 4px' }}>[title](https://google.com)</div></div>
        <div key='list' style={{ display: 'flex', marginBottom: 10, alignItems: 'center', color: "#1C3A66" }}>To <div style={{ color: '#1FC2CB', fontWeight: 'bold', margin: '0px 4px' }}> List</div> text use <div style={{ color: '#1FC2CB', margin: '0px 4px' }}>*text</div></div>
        {/* <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center', color: "#1C3A66" }}>To <div style={{ color: '#1FC2CB', fontWeight: 'bold', margin: '0px 4px' }}> Highlight</div> text use <div style={{ color: '#1FC2CB', margin: '0px 4px' }}>==text==</div></div> */}
    </div>
]

const DeliveryArea = (props: any) => {
    const dispatch = useDispatch();
    // const [webTheme, setWebTheme] = useState(null);
    const [deliveryAreaHint, setDeliveryAreaHint] = useState(null);
    const [data, setdata] = useState(null);
    const [isEdit, setEdit] = useState(false);
    useEffect(() => {
        setdata(props.data);
    }, [props.data])
    useEffect(() => {
        setEdit(false);
        if (props.webTheme && props.webTheme.brand) {
            const {
                deliveryAreaHint: defaultDeliveryAreaHint,
            } = props.webTheme.brand;
            setDeliveryAreaHint(defaultDeliveryAreaHint);
        }
    }, [props.webTheme]);
    const onCancel = () => {
        setEdit(false);
        if (props.webTheme && props.webTheme.brand) {
            const {
                deliveryAreaHint: defaultDeliveryAreaHint,
            } = props.webTheme.brand;
            setDeliveryAreaHint(defaultDeliveryAreaHint);
        }
    }
    const onSave = async () => {
        const { theme, aboutUs, social, codeInjection, paymentModes } = data;
        theme.web.brand.deliveryAreaHint = deliveryAreaHint;
        theme.mobile.brand.deliveryAreaHint = deliveryAreaHint;
        const payload = onCreatePayload({
            theme: {
                web: theme.web,
                mobile: theme.mobile
            },
            codeInjection: codeInjection,
            aboutUs: aboutUs,
            social: social,
            pricingInclusiveOfTax: data.pricingInclusiveOfTax || false,
            paymentModes: paymentModes || [],
            autoConfirmOrders: data.autoConfirmOrders || {
                codOrders: false,
                onlineOrders: false
            }
        }, "all")
        const themeUpdateResp: any = await onUpdateSiteSettings(payload)(dispatch);
        if (themeUpdateResp && themeUpdateResp.isUpdated) {
            setEdit(false);
            props.getTheme();
        }
    }
    return <div className="division" style={{ display: "none" }}>
        <div className="header" style={{ marginTop: 40 }}>Delivery Area Hint</div>
        <div className="section" style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                {!isEdit && <button onClick={() => setEdit(true)} className="sp_btn save">Edit</button>}
                {isEdit && <button onClick={onCancel} className="sp_btn save">Cancel</button>}
                {isEdit && <button onClick={onSave} style={{ marginLeft: 10 }} className="sp_btn save">Save</button>}
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '40%' }}>
                    <div style={{ flexGrow: 1 }} className="subtitle" > Short description about your delivery area locations</div>
                </div>
                <div className="val" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ position: 'relative' }}>
                        <TextArea style={{ width: 600 }} disabled={!isEdit} value={deliveryAreaHint} onChange={(e) => setDeliveryAreaHint(e.target.value)} autoSize={{ minRows: 4, maxRows: 6 }} showCount maxLength={300} placeholder="Enter short description about your  delivery area locations"></TextArea>
                        <div style={{ top: 0, right: '-34px', position: 'absolute' }}>
                            <Popover style={{
                                background: '#FFFFFF',
                                boxShadow: '0px 8px 24px #00000029',
                                border: '1px solid #CBCBCB',
                                height: "auto",
                                width: "auto",
                            }} content={MarkdownContent} trigger="click">
                                <div style={{ marginTop: 3, borderRadius: 4, cursor: 'pointer', marginLeft: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 19, height: 19, background: '#000000', color: '#FFF' }}>
                                    i
                                </div>
                            </Popover>
                        </div>
                    </div>
                    <div className='markdown'>
                        <div className='preview'>
                            <div style={{
                                fontSize: 14,
                                height: 31,
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: 10,
                                background: '#DBE2EA6C',
                                borderRadius: '4px 4px 0px 0px'
                            }}>Preview</div>
                            <MdEditor
                                readOnly={true}
                                config={{
                                    view: {
                                        menu: false,
                                        md: false,
                                        html: true,
                                        fullScreen: false,
                                        hideMenu: false,
                                    }
                                }}
                                className="md-editor"
                                value={deliveryAreaHint}
                                style={{ height: 200, width: 600 }}
                                renderHTML={(text) => mdParser.render(text)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default DeliveryArea