import React, { useEffect, useState } from 'react';
import Header from '../setupstore/Header';
import Footer from '../setupstore/Footer';
import { useDispatch } from 'react-redux';
import '../../../assets/sass/dsk/signin.sass';
import Loading from '../common/Loading';
import { emailSignup } from "../../../actions/authActions";
import InputValidation from '../common/InputValidation';
import { isMail, isNotNullOrUndefinedString } from '../../../helpers/util';
import { useNav } from '../../../helpers/customHook';

const EmailSignup = () => {
    const dispatch: any = useDispatch();
    const navigateTo = useNav();
    const [email, setEmail] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [errorAPI, setErrorAPI] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    let errorInfo = {
        fullName: {
            message: "",
            type: "",
        },
        email: {
            message: "",
            type: "",
        }
    }
    const [error, setError] = useState(errorInfo);
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    const signup = async () => {
        let isError = false;
        if (!isNotNullOrUndefinedString(fullName)) {
            error.fullName.message = "Fill the full name";
            error.fullName.type = "error";
            isError = true;
        } else {
            error.fullName.message = "";
            error.fullName.type = "";
        }
        if (!isNotNullOrUndefinedString(email)) {
            error.email.message = "Enter the email address";
            error.email.type = "error";
            isError = true;
        } else if (!isMail(email)) {
            error.email.message = "Enter valid email address";
            error.email.type = "error";
            isError = true;
        } else {
            error.email.message = "";
            error.email.type = "";
        }
        if (!isError) {
            setLoading(true);
            const resp = await emailSignup({ fullName, email })(dispatch);
            if (resp.token) {
                navigateTo(`/verifyotp/${email}`);
            } else if (resp.error) {
                setErrorAPI(resp.error);
            }
            setLoading(false);
        }
        setError({ ...error });
    }
    return <div className="email" style={{ height: dimensions.height }}>
        <Header disableLogin={true} />
        <div className="content">
            <div className="signin">
                <div className='header'>
                    <div className="title">
                        Sign Up
                    </div>
                </div>
                <div className="title" style={{ marginBottom: 29 }}>
                    <div>Build a stunning and professional online store</div>
                </div>
                <div style={{ marginBottom: 29 }}>
                    <InputValidation type={error.fullName.type} message={error.fullName.message}>
                        <input className="email" style={{ marginTop: 0 }} value={fullName} type="text" placeholder="Full Name" onChange={(e) => setFullName(e.target.value)} />
                    </InputValidation>
                </div>
                <InputValidation type={error.email.type} message={error.email.message}>
                    <input className="email" style={{ marginTop: 0 }} value={email} type="text" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
                </InputValidation>
                {errorAPI && <div className='error'>{errorAPI}</div>}
                <div className="send-otp" onClick={signup}>Sign Up</div>
                <div className='new-reg'>
                    <div className="new">
                        Joined us before?
                    </div>
                    <div className='reg' onClick={() => navigateTo("/loginotp")}>
                        Login Now
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        {isLoading && <Loading background="#00000070"></Loading>}
    </div>
}
export default EmailSignup;