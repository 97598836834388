import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import '../../../../../assets/sass/dsk/employee.sass';
import { ArrowLeftOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Row, Col, Select, Input, Drawer } from 'antd';
import { generateUniqueId, isMail } from '../../../../../helpers/util';
import DeleteSvg from "../../../../../assets/images/red-delete.svg";
import PopupConfirm from '../../../common/PopupConfirm';
import { grantAccess, searchUsers } from '../../../../../actions/userActions';
import Loading from '../../../common/Loading';
import OwnerSvg from '../../../../../assets/images/account.svg';
import { trim } from 'lodash';

const { Option } = Select;
const windowHeight = window.innerHeight;
const Save = (props: any) => {
    const { outlets } = useSelector((state: any) => state.outlet);
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [isLoading, setLoading] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [errorMember, setErrorMember] = useState(null);
    const [isRoleUpdated, setRoleUpdated] = useState(false);
    const [mailNotAvailable, setMailNotAvailable] = useState(false);
    const [validMail, setValidMail] = useState(false);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [isOwner, setOwner] = useState(false);
    const [searchUser, setSearchUser] = useState(null);
    const [selectedUser, setSelectedUser] = useState([]);
    // const [users, setUsers] = useState([]);
    const [usedRoles, setUsedRoles] = useState([]);
    const [roles, setRoles] = useState({});
    // useEffect(() => {
    //     onSearchUsers();
    // }, [searchUser]);
    const onSearchUsers = async (mailId) => {
        const resp = await props.searchUsers(mailId);
        return resp;
        // if (resp) {
        //     setUsers(resp);
        // }
    }
    useEffect(() => {
        setRoles({});
        setLoading(false);
        setError(null);
        setSelectedUser([]);
        setSearchUser(null);
        setOwner(false);
        setEdit(false);
        if (props.user) {
            let preRole = {}
            if (props?.user?.roles) {
                if (props?.user?.userType === "owner") {
                    setOwner(true);
                }
                for (let r in props?.user?.roles) {
                    preRole[generateUniqueId('role')] = {
                        role: props?.user?.roles[r].role,
                        scope: props?.user?.roles[r].scope,
                        outlets: props?.user?.roles[r].scope === "outlet" ? props?.user?.roles[r].outlets : []
                    };
                }
                setRoles({ ...preRole, ...roles });
            }
            setSelectedUser([props.user.email]);
        } else {
            setEdit(true);
        }
        //eslint-disable-next-line
    }, [props.user])

    const onSubmit = () => {
        setPopupConfirm(null);
        let payloadList = [];
        let isError = false;
        if (selectedUser.length === 0) {
            isError = true;
            setLoading(false);
            setErrorMember("Add the members email id");
        } else if (Object.keys(roles).filter((key, index) => { return roles[key].role === null }).length > 0) {
            isError = true;
            setError('Select the user role');
        } else if (selectedUser.length > 0) {
            for (let i in selectedUser) {
                if (roles && Object.keys(roles).length > 0) {
                    payloadList.push({
                        email: selectedUser[i],
                        roles: Object.keys(roles).map((key, index) => { return roles[key] })
                    })
                }
                //  else {
                //     isError = true;
                //     setError("Please the add roles");
                //     break;
                // }
            }
        }
        if (!isError) {
            let popupConfirmDetail = null;
            popupConfirmDetail = {
                title: "User Permissions",
                renderContent: () => { return <div>Do you like to grant access</div> },
                yes: {
                    label: "Grant Access",
                    callback: () => { setLoading(true); setPopupConfirm(null); onUpdateRoles(payloadList); }
                },
                no: {
                    label: "May be next time",
                    callback: () => { setLoading(false); setPopupConfirm(null); }
                }
            }
            setPopupConfirm(popupConfirmDetail);
        }
    }

    const onUpdateRoles = async (payloadList) => {
        let apiResps = await Promise.all(payloadList.map(async (payload) => {
            try {
                if (props.user && isRoleUpdated) {
                    payload.update = true;
                }
                const resp = await props.grantAccess(payload);
                return resp;
            } catch (error) {
                return null;
            }
        }));
        const checkResp = apiResps.filter(x => x === null);
        setLoading(false);
        if (checkResp.length === 0) {
            onClose();
        } else {
            setError("Something went wrong please try again")
        }
    }

    const removeSelectedUser = (email, index) => {
        setSelectedUser(selectedUser.filter(item => item !== email));
    }

    const addRole = () => {
        setRoleUpdated(true);
        setRoles((prevState) => ({
            ...prevState,
            [generateUniqueId('role')]: {
                role: null,
                scope: null,
                outlets: []
            }
        }));
    }

    const udpateRole = (roleId, key, value) => {
        setRoleUpdated(true);
        setRoles((prevState) => ({
            ...prevState,
            [roleId]:
                { ...roles[roleId], [key]: value }
        }));
    }

    const removeRole = (roleId) => {
        setRoleUpdated(true);
        let updateRoleObj = {};
        for (let key of Object.keys(roles)) {
            if (key !== roleId) {
                updateRoleObj[key] = roles[key]
            }
        }
        setRoles(updateRoleObj);
    }

    useEffect(() => {
        let preRoles = [];
        for (let key of Object.keys(roles)) {
            preRoles.push(roles[key].role)
        }
        setUsedRoles(preRoles);
        console.log({ roles });
    }, [roles]);

    useEffect(() => {
        if (selectedUser && selectedUser.length === 0) {
            setRoles({});
        } else if (selectedUser.length === 1 && !props.user) {
            setErrorMember(null);
            addRole();
        }
        //eslint-disable-next-line
    }, [selectedUser]);

    const onClose = () => {
        setErrorMember(null);
        setError(null);
        setSelectedUser([]);
        setSearchUser(null);
        props.onClose();
    }

    const addMailAddress = async () => {
        let mailId = trim(searchUser);
        if (isMail(mailId) && selectedUser.indexOf(mailId) === -1) {
            let res = await onSearchUsers(mailId);
            if (res && res.length > 0 && res[0].email === mailId) {
                setSelectedUser([...selectedUser, mailId]);
                setSearchUser("");
            } else {
                setMailNotAvailable(true)
            }
        }
    }

    useEffect(() => {
        setMailNotAvailable(false);
        let mailId = trim(searchUser);
        if (mailId && isMail(mailId)) {
            setValidMail(true);
        } else {
            setValidMail(false);
        }
    }, [searchUser])

    return (
        <Drawer
            width={470}
            closable={false}
            onClose={onClose}
            className="employee-view"
            visible={props.isVisible}
            footer={
                <div className='footer'>
                    <div className="sp_btn" onClick={onClose} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                    {props.user && !isEdit && <div className="sp_btn" onClick={() => { setEdit(true) }} style={{ width: 120, fontSize: 14 }}>Edit</div>}
                    {isEdit && <div className="sp_btn" onClick={onSubmit} style={{ width: 168, fontSize: 14 }}>{props.user ? "Update User Access" : "Grant User Access"}</div>}
                </div>
                // <Row>
                //     <Col span={24} className="action-bar">
                //         {error && <div className="error">{error}</div>}
                //         <div style={{
                //             display: 'flex',
                //             justifyContent: 'flex-end'
                //         }}>
                //             <div style={{ paddingRight: "15px" }}>
                //                 <button onClick={onClose} className="sp_btn cancel">Cancel</button>
                //             </div>
                //             <div style={{ paddingLeft: "15px" }}>
                //                 {isEdit && <button onClick={onSubmit} className="sp_btn save">{props.user ? "Update User Access" : "Grant User Access"}</button>}
                //                 {props.user && !isEdit && <button onClick={() => setEdit(true)} className="sp_btn edit">Edit</button>}
                //             </div>
                //         </div>
                //     </Col>
                // </Row>
            }
        >
            <Row className="content">
                <div className="header">
                    <ArrowLeftOutlined className="arrow" onClick={onClose}></ArrowLeftOutlined>
                    <div className="title"> {props.user ? 'Update ' : 'Add '} Roles to {selectedStore.brandName}</div>
                </div>
                <Col span={24} className="details" style={{ padding: '0px' }}>
                    {/* <Row className="basic-info">
                        <Col span={24} style={{ textTransform: "uppercase" }}>
                            <ArrowLeftOutlined style={{
                                position: "absolute",
                                cursor: "pointer",
                                marginLeft: "-44px"
                            }} onClick={onClose}></ArrowLeftOutlined>
                            Add Roles to {selectedStore.brandName}
                        </Col>
                    </Row> */}

                    <Row className="group">
                        <Col span={24} style={{ padding: '6px 40px' }}>
                            <span className="label" >Set Permission for members</span>
                        </Col>
                        <Col span={24} >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                background: '#FFF',
                                border: '1px solid #808080',
                                borderRadius: 8,
                                margin: '6px 40px',
                                padding: 10
                            }}>
                                {isEdit && !isOwner && <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: selectedUser.length > 0 ? 10 : 0
                                }}>
                                    <Input autoComplete="off" value={searchUser} style={{ color: mailNotAvailable ? '#FF0000' : '#121212', border: 'none' }} placeholder="Enter email" disabled={!isEdit} className="value" onChange={(e) => setSearchUser(e.target.value)} />
                                    {validMail && <div style={{ cursor: 'pointer', marginRight: 6, marginLeft: 6 }} onClick={addMailAddress}>ADD</div>}
                                </div>}
                                {selectedUser.length > 0 && <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {isOwner && <img alt='Owner' src={OwnerSvg} style={{ marginRight: 6 }} />}
                                    {selectedUser.map((u, i) => {
                                        return <div
                                            key={`${u}`}
                                            style={{
                                                marginRight: 10,
                                                padding: 10,
                                                height: 26,
                                                background: '#EFEFEF',
                                                borderRadius: 3,
                                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                                            }}>
                                            <div style={{
                                                fontSize: 15,
                                                color: '#1A1A1A',
                                                marginRight: 8
                                            }}>{u}</div>
                                            {isEdit && !isOwner && <CloseCircleFilled onClick={() => removeSelectedUser(u, i)} />}
                                        </div>
                                    })}</div>}
                            </div>
                            {errorMember && <div style={{ color: "#FF0000", margin: '10px 40px', fontWeight: 500 }}>{errorMember}</div>}
                            {mailNotAvailable && <div style={{ color: "#FF0000", margin: '-5px 40px', fontWeight: 500, padding: '4px 8px', borderRadius: 4 }}>Account not available in Shopletzy</div>}
                            {/* <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                background: '#FFF',
                                border: '1px solid #808080',
                                borderRadius: 8,
                                margin: '6px 40px',
                                padding: 10
                            }}>
                                {isEdit && !isOwner && <Input style={{ border: 'none', marginBottom: selectedUser.length > 0 ? 10 : 0 }} value={searchUser} placeholder="Enter email" disabled={!isEdit} className="value" onChange={(e) => setSearchUser(e.target.value)} />}
                                {selectedUser.length > 0 && <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {isOwner && <img src={OwnerSvg} style={{ marginRight: 6 }} />}
                                    {selectedUser.map((u, i) => {
                                        return <div
                                            key={`${u}`}
                                            style={{
                                                marginRight: 10,
                                                padding: 10,
                                                height: 26,
                                                background: '#EFEFEF',
                                                borderRadius: 3,
                                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                                            }}>
                                            <div style={{
                                                fontSize: 15,
                                                color: '#1A1A1A',
                                                marginRight: 8
                                            }}>{u}</div>
                                            {isEdit && !isOwner && <CloseCircleFilled onClick={() => removeSelectedUser(u, i)} />}
                                        </div>
                                    })}</div>}
                                {searchUser && searchUser.length > 0 && <div style={{
                                    marginTop: 16,
                                    borderTop: '1px solid #ededed'
                                }}>
                                    {users && users.map((u) => {
                                        return u.email.indexOf(searchUser) > -1 && selectedUser.indexOf(u.email) === -1 && <div
                                            onClick={() => { setSearchUser(""); setSelectedUser([...selectedUser, u.email]) }}
                                            style={{
                                                padding: 10,
                                                cursor: 'pointer'
                                            }} key={u.email}>{u.email}</div>
                                    })}
                                </div>}
                            </div> */}
                            <div style={{ height: windowHeight - 260, overflow: 'auto', marginTop: 20 }}>
                                {selectedUser && selectedUser.length > 0 && roles && Object.keys(roles).map((key, index) => {
                                    return <div key={key} style={{
                                        minHeight: 115,
                                        background: '#CBCBCB26',
                                        borderRadius: 10,
                                        margin: '30px 35px',
                                        position: 'relative',
                                        padding: 20
                                    }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ width: '48%' }}>
                                                <div style={{
                                                    fontSize: 14,
                                                    color: '#808080'
                                                }}>Roles</div>
                                                <Select disabled={!isEdit} defaultValue={roles[key] && roles[key].role} onChange={(val) => { udpateRole(key, "role", val) }}>
                                                    {props.roles.map((rl) => { return rl !== "owner" && usedRoles.indexOf(rl) === -1 && <Option key={rl} value={rl}>{rl}</Option> })}
                                                </Select>
                                            </div>
                                            <div style={{ width: '48%' }}>
                                                <div style={{
                                                    fontSize: 14,
                                                    color: '#808080'
                                                }}>Scope</div>
                                                <Select disabled={!isEdit} defaultValue={roles[key] && roles[key].scope} onChange={(val) => { udpateRole(key, "scope", val) }}>
                                                    <Option value={"store"}>Store</Option>
                                                    <Option value={"outlet"}>Outlet</Option>
                                                </Select>
                                            </div>
                                        </div>
                                        {roles[key] && roles[key].scope === "outlet" && <div>
                                            <div style={{
                                                fontSize: 14,
                                                color: '#808080'
                                            }}>Outlets</div>
                                            <Select mode={'multiple'}
                                                disabled={!isEdit}
                                                maxTagCount={'responsive'}
                                                defaultValue={roles[key].outlets}
                                                onChange={(val) => { udpateRole(key, "outlets", val) }}>
                                                {outlets && outlets.map((out) => {
                                                    return <Option key={out.id} value={out.id}>{out.name}</Option>
                                                })}
                                            </Select>
                                        </div>}
                                        {isEdit && <div onClick={() => removeRole(key)} style={{
                                            position: 'absolute',
                                            right: 4,
                                            marginTop: 32,
                                            color: '#FFF',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: 70,
                                            height: 24,
                                        }}>
                                            <img alt='Delete' src={DeleteSvg} />
                                            <label style={{ color: '#121212' }}>Remove</label>
                                        </div>}
                                    </div>
                                })}
                                {isEdit && selectedUser && selectedUser.length > 0 && <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 35 }}>
                                    <div className="sp_btn" style={{
                                        background: '#000',
                                        color: '#fff',
                                        width: 158,
                                        fontSize: 14
                                    }} onClick={addRole}>Add Another Role</div>
                                </div>}
                                {error && <div style={{ color: "#FF0000", margin: '10px 40px', fontWeight: 500 }}>{error}</div>}
                            </div>
                        </Col>
                    </Row>
                    {/* </Col> */}

                    {/* <Col span={24} className="line"></Col> */}
                    {/* <Col span={24} className="action-bar">
                                <div className="error">{this.state.error}</div>
                            </Col> */}
                    {/* </Row>} */}
                </Col>
                {/* <Col span={24} className="action-bar">
                    {error && <div className="error">{error}</div>}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <div style={{ paddingRight: "15px" }}>
                            <button onClick={onClose} className="sp_btn cancel">Cancel</button>
                        </div>
                        <div style={{ paddingLeft: "15px" }}>
                            {isEdit && <button onClick={onSubmit} className="sp_btn save">{props.user ? "Update User Access" : "Grant User Access"}</button>}
                            {props.user && !isEdit && <button onClick={() => setEdit(true)} className="sp_btn edit">Edit</button>}
                        </div>
                    </div>
                </Col> */}
            </Row>
            {isLoading && <Loading background="#00000070"></Loading>}
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        </Drawer>
    )
}



function mapStateToProps(state: any) {
    // console.log("Product View mapStateToProps: ", state);
    return {
        common: state.common,
        ui: state.ui,
        setupStore: state.setupStore
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        grantAccess,
        searchUsers
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Save);