import React, { useState, useEffect } from 'react';
import { useSelector, connect } from "react-redux";
import { getPayoutAccounts, setPrimaryPayoutAccounts, createPayoutAccounts } from "../../../../../actions/accountActions";
import Card from "./Card";
import Save from "./Save";

const Accounts = (props: any) => {
    const WindowHeight = window.innerHeight;
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [payoutAccounts, setPayoutAccounts] = useState([]);
    const [primaryPayoutAccountId, setPrimaryPayoutAccountId] = useState(null);
    const [selectedAccount, setSelectAccount] = useState(null);
    useEffect(() => {
        getPayoutAccounts();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (selectedStore) {
            getPayoutAccounts();
        }
        //eslint-disable-next-line
    }, [selectedStore]);
    const getPayoutAccounts = async () => {
        let resp = await props.getPayoutAccounts();
        if (resp && resp.payoutAccounts) {
            setPayoutAccounts(resp.payoutAccounts)
            setPrimaryPayoutAccountId(resp.primaryPayoutAccountId)
        }
    }
    const onSetPrimaryAC = async (acId) => {
        let resp = await props.setPrimaryPayoutAccounts(acId);
        if (resp) {
            getPayoutAccounts();
        }
    }
    const onSave = async (payload) => {
        let resp = await props.createPayoutAccounts(payload);
        if (resp) {
            getPayoutAccounts();
        }
        setSelectAccount(null);
    }
    return (
        <>
            {payoutAccounts && payoutAccounts.length > 0 && <div className="action-bar">
                <div className="title">
                    You've {payoutAccounts && payoutAccounts.length} {payoutAccounts && payoutAccounts.length > 1 ? 'accounts' : 'account'} listed
                </div>
                <div className="actions">
                    <button className="sp_btn btn" onClick={() => setSelectAccount({
                        accountHolderName: null,
                        accountNo: null,
                        ifscCode: null,
                        branch: null,
                        accountType: null,
                        id: null
                    })} >+ Add Account</button>
                </div>
            </div>}
            {payoutAccounts && payoutAccounts.length > 0 ? <div className="payouts">
                <div className="title">BANK Details</div>
                <div className="content">
                    {payoutAccounts.map((pa) => {
                        return <Card onSetPrimaryAC={() => { onSetPrimaryAC(pa.id); }} onSelect={() => setSelectAccount(pa)} key={pa.id} account={pa} primaryPayoutAccountId={primaryPayoutAccountId || -1} />
                    })}
                </div>
            </div> : <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: WindowHeight - 200, flexDirection: 'column' }}>
                <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy</div>
                <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first Account.</div>
                <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no Account yet...</div>
                <div style={{ display: 'flex' }}>
                    <button onClick={() => setSelectAccount({
                        accountHolderName: null,
                        accountNo: null,
                        ifscCode: null,
                        branch: null,
                        accountType: null,
                        id: null
                    })} className="sp_btn" style={{ width: 'auto' }} >+ Add Account</button>
                </div>
            </div>}
            {selectedAccount && <Save onSave={onSave} account={selectedAccount} onClose={() => { setSelectAccount(null); getPayoutAccounts(); }} isVisible={selectedAccount ? true : false}></Save>}
        </>
    );
}

export default connect(null, { getPayoutAccounts, setPrimaryPayoutAccounts, createPayoutAccounts })(Accounts);