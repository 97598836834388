import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAdditionalCharges, onDeleteAdditionalCharge } from "../../../../../actions/commonAction";
import Save from "./save";
import Loading from "../../../common/Loading";
import PopupConfirm from "../../../common/PopupConfirm";

const AdditionalCharges = () => {
    const dispatch = useDispatch();
    const [charges, setCharges] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [popupConfirmConfig, setPopupConfirmConfig] = useState(null);
    const [selectedCharge, setSelectedCharge] = useState(null);
    useEffect(() => {
        getCharges();
        //eslint-disable-next-line
    }, [])
    const getCharges = async () => {
        const resp = await getAdditionalCharges()(dispatch);
        if (resp?.additionalCharges) {
            setCharges(resp.additionalCharges);
        }
    }
    const onClose = () => {
        setVisible(false);
        setSelectedCharge(null);
        getCharges();
    }
    const onDelete = (charge) => {
        const popupConfirmDetail = {
            title: "Delete Additional Charge",
            renderContent: () => { return <div>Do you like to delete <span style={{ fontWeight: 900, font: "14px AvenirMedium", letterSpacing: "0px", color: "#34E5C1" }}>{charge.label}</span></div> },
            yes: {
                label: "Delete",
                callback: async () => {
                    setIsLoading(true);
                    const resp = await onDeleteAdditionalCharge(charge.id)(dispatch);
                    setIsLoading(false);
                    if (resp?.isDeleted) {
                        setPopupConfirmConfig(null);
                        getCharges();
                    }
                }
            },
            no: {
                label: "Cancel",
                callback: () => { setPopupConfirmConfig(null) }
            }
        }
        setPopupConfirmConfig(popupConfirmDetail)
    }
    return <div className="settings">
        <div className="content" style={{ flexDirection: 'row' }}>
            <div style={{
                flexGrow: 1,
                height: window.innerHeight - 80
            }}>
                {charges.length > 0 ? <>
                    <div style={{
                        display: 'flex', justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 24, background: '#f5f5f5',
                        height: 60,
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: '#E7EEF7'
                    }}>
                        <div>View & configure the additional charges</div>
                        <button className="sp_btn" style={{ width: 'auto' }} onClick={() => setVisible(true)}>+ Add Charge</button>
                    </div>
                    <div style={{ height: window.innerHeight - 142, background: "#FFF" }}>
                        {charges.map((c) => {
                            return <div key={c.id} style={{
                                border: '1px solid #D8DEE6',
                                borderRadius: 6,
                                margin: 10,
                                padding: 10
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            color: "#242424",
                                            marginRight: 8
                                        }}>{c.label}</div>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontSize: 13,
                                            color: c.isActive ? "#00A73C" : "#c1c1c1",
                                            background: c.isActive ? "#E5FFEE" : "#c1c1c132",
                                            width: 99, height: 28, borderRadius: 30
                                        }}>
                                            {c.isActive ? "Active" : "Inactive"}
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%'
                                    }}>
                                        <button className="sp_btn" style={{ width: 'auto', fontSize: 14 }} onClick={() => { setSelectedCharge(c); setVisible(true); }}>Edit</button>
                                        <button className="sp_btn" style={{ background: '#D8DEE66F', color: "#242424", width: 'auto', marginLeft: 10, fontSize: 14 }} onClick={() => onDelete(c)}>Delete</button>
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: 40
                                }}>
                                    <div>
                                        <div style={{
                                            color: '#506584',
                                            fontSize: 12
                                        }}>Charge Fee:</div>
                                        <div>{c.fee.type}</div>
                                    </div>
                                    <div>
                                        <div style={{
                                            color: '#506584',
                                            fontSize: 12
                                        }}>Charge Type:</div>
                                        <div>{c.chargeType}</div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </> : <>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#f5f5f5',
                        height: '100%',
                        flexDirection: 'column'
                    }}>
                        <div>View & configure the additional charges</div>
                        <button className="sp_btn" style={{ width: 'auto', marginTop: 20 }} onClick={() => setVisible(true)}>+ Add Charge</button>
                    </div>
                </>}
            </div>
            <div
                className="container"
                style={{
                    padding: '12px 18px',
                    height: '100%',
                    width: '26%',
                    background: '#FFFFFF',
                    boxShadow: '0px 3px 16px #DBE2EA6C',
                    flexShrink: 0
                }}>
                <div className="header" style={{ marginBottom: 12 }}>Additional Charges</div>
                <div className="title" style={{ marginBottom: 12 }}>Lorem ipsum</div>
                <div className="value" style={{ marginBottom: 12, textAlign: 'justify' }}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    <br />
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                </div>
                <div className="title" style={{ marginBottom: 12 }}>Lorem ipsum</div>
                <div className="value" style={{ marginBottom: 12, textAlign: 'justify' }}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                </div>
                <div className="title" style={{ marginBottom: 12 }}>Lorem ipsum</div>
                <div className="value" style={{ marginBottom: 12, textAlign: 'justify' }}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                </div>
            </div>
        </div>
        {isLoading && <Loading background="#00000070"></Loading>}
        <Save isVisible={isVisible} onClose={onClose} selectedCharge={selectedCharge}></Save>
        {popupConfirmConfig && <PopupConfirm config={popupConfirmConfig}></PopupConfirm>}
    </div>
}

export default AdditionalCharges;