import React from 'react';
import { Outlet } from 'react-router-dom';

const Notification = (props: any) => {
    return (
        <div className="menu">
            <Outlet></Outlet>
        </div>
    );
}

export default Notification;