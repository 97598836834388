import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface IProps {
    style?: any;
    menus: any;
    defaultMenu?: any;
    onSelectedTabMenu: (selectedMenu: any) => void;

}
const TabMenu = (props: IProps) => {
    // const rMatch = useMatch({ caseSensitive: true, path: "/settings/account/:options" });
    const { onSelectedTabMenu, menus, style = {}, defaultMenu } = props;
    const [selectedTabMenu, setSelectedTabMenu]: any = useState(null);
    useEffect(() => {
        if (defaultMenu) {
            if (!selectedTabMenu || selectedTabMenu.key !== defaultMenu.key) {
                setSelectedTabMenu(defaultMenu)
            }
        }
        //eslint-disable-next-line
    }, [defaultMenu])
    useEffect(() => {
        onSelectedTabMenu(selectedTabMenu);
        //eslint-disable-next-line
    }, [selectedTabMenu]);
    useEffect(() => {
        if (menus.length > 0) {
            setSelectedTabMenu(menus[0])
        }
    }, [menus])
    return <div style={{
        ...{
            display: "flex",
            background: '#FFFFFF',
            height: 60,
            alignItems: 'center',
            width: '100%',
        },
        ...style
    }}>
        {menus && menus.map((m) => {
            return <Link to={`${m.key}`}
                key={m.key}
                style={{
                    cursor: selectedTabMenu && selectedTabMenu.key === m.key ? 'default' : 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                    height: '100%',
                    margin: '0px 10px',
                    padding: '0px 5px',
                    fontSize: 15,
                    color: selectedTabMenu && selectedTabMenu.key === m.key ? "#34E5C1" : "#121212",
                    fontWeight: selectedTabMenu && selectedTabMenu.key === m.key ? "bold" : "normal"
                }} onClick={() => setSelectedTabMenu(m)}>
                <div style={{ marginBottom: 14 }}>{m.value}</div>
                <div style={{ background: selectedTabMenu && selectedTabMenu.key === m.key ? "#34E5C1" : "#fff", height: 3, borderRadius: 10, margin: "0px -14px" }}></div>
            </Link>
        })}
    </div>
}

export default TabMenu;