import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getInstalledThemes, onActiveTheme } from "../../../../actions/themeActions";
import Card from "../Card";

const Installed = (props: any) => {
    const [themes, setThemes] = useState([]) as any[];
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    useEffect(() => {
        loadThemes();
        // eslint-disable-next-line
    }, []);
    const loadThemes = async () => {
        const response = await getInstalledThemes()(dispatch);
        if (response && response.themes) {
            setThemes(response.themes);
        }
    }
    const onActive = async (themeId) => {
        const resp = await onActiveTheme(themeId)(dispatch);
        if (resp?.activated) {
            loadThemes();
        }
    }
    return <div className="installed">
        <div className="header">
            <div className="title">
                Installed Themes
            </div>
            <input type="text" placeholder="Search" className="search" onChange={(e) => setSearch(e.target.value.trim())} />
        </div>
        <div className="list">
            {themes.map((theme: any, index: number) => {
                return (search.length === 0 || theme.title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) && <Card theme={theme} onActive={onActive} type="installed" />
            })}
        </div>
    </div>
}
export default Installed;