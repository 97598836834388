import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, onDeleteNotification } from '../../../actions/notificationActions';
import DeleteIcn from '../../../assets/images/delete.svg';
import BellSvg from '../../../assets/images/settings/bell_cricle.svg';
import ScheduledSvg from '../../../assets/images/settings/scheduled.svg';
import SuccessSvg from '../../../assets/images/checked-circle.svg';
import FailSvg from '../../../assets/images/settings/upload_failed.svg';
import EditIcon from '../../../assets/images/edit.svg';
import '../../../assets/sass/dsk/notification.sass';
import Save from "./Save";
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import PopupConfirm from '../common/PopupConfirm';
import Loading from '../common/Loading';

// const { RangePicker } = DatePicker;
const View = (props: any) => {
    const dispatch = useDispatch();
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [notifications, setNotifications] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [selectedNotify, setSelectedNotify] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const windowHeight = window.innerHeight;
    useEffect(() => {
        loadNotification();
        //eslint-disable-next-line
    }, []);

    const loadNotification = async () => {
        setIsLoading(true);
        const resp: any = await getNotifications()(dispatch);
        if (resp && resp.notifications) {
            setNotifications(resp.notifications);
        }
        setIsLoading(false);
    }

    const deleteNotification = async (item) => {
        const title = `Notification remove from store`;
        setPopupConfirm({
            title,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to remove <span style={{ fontWeight: "bold" }}>{item.title}</span> notification from <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>{selectedStore.name}</span> store.</span></div > },
            yes: {
                label: "Remove Product",
                callback: async () => {
                    setIsLoading(true);
                    setPopupConfirm(null);
                    const resp: any = await onDeleteNotification(item.id)(dispatch);
                    if (resp && resp.success) {
                        loadNotification();
                    }
                    setIsLoading(false);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }
    return (
        <Row className="notification">
            <Col className="list" style={{ height: windowHeight - 76 }} span={24}>
                {notifications.length > 0 ? <>
                    <div className="action-bar">
                        <div className="title">
                            You've got {notifications.length} notification listed
                        </div>
                        <div className="actions">
                            <button style={{ width: 160 }} className="sp_btn btn" onClick={() => { setSelectedNotify(null); setIsVisible(true); }} >+ Create Notification</button>
                        </div>
                    </div>
                    <div className="content">
                        <Row className="header">
                            <Col className='title' span={4}>
                                Title
                            </Col>
                            <Col className='title' span={12}>
                                Summary
                            </Col>
                            <Col className='title' span={2}>
                                Status
                            </Col>
                            <Col className='title' span={4}>
                                Created Date
                            </Col>
                            <Col className='title' span={2}>
                                {/* Action */}
                            </Col>
                        </Row>
                        <Row className="contains" style={{ height: windowHeight - 234 }}>
                            <Col span="24">
                                {notifications.map((item, index) => {
                                    return <Row className='item' style={{ borderBottomWidth: notifications.length - 1 === index ? 0 : 1 }} key={item.id} onClick={() => { setSelectedNotify(item); setIsVisible(true); }}>
                                        <Col span={4} style={{ paddingLeft: 2 }}>
                                            {item.title}
                                        </Col>
                                        <Col span={12} style={{ paddingLeft: 2, height: 40, overflow: "hidden" }}>
                                            {item.body}
                                        </Col>
                                        <Col span={2} style={{ paddingLeft: 2, display: "flex" }}>
                                            {item.status === "scheduled" && <img alt="Scheduled Icon" style={{ height: 18 }} src={ScheduledSvg} />}
                                            {item.status === "delivered" && <img alt="Delivered Icon" style={{ height: 18 }} src={SuccessSvg} />}
                                            {item.status === "failed" && <img alt="Fail Icon" style={{ height: 18 }} src={FailSvg} />}
                                            <div style={{ marginLeft: 8, textTransform: "capitalize", fontWeight: 600, color: "#292D32" }}>{item.status}</div>
                                        </Col>
                                        <Col span={4} style={{ paddingLeft: 2 }}>
                                            {dayjs(item.createdAt).format('DD/MM/YYYY, hh:mm A')}
                                        </Col>
                                        <Col span={2} style={{ paddingLeft: 2, textAlign: 'center' }}>
                                            <img alt="Edit Icon" style={{ marginRight: 12 }} src={EditIcon} onClick={(event) => { event.stopPropagation(); setSelectedNotify(item); setIsVisible(true); }} />
                                            <img alt="Delete Icon" src={DeleteIcn} onClick={(event) => { event.stopPropagation(); deleteNotification(item); }} />
                                        </Col>
                                    </Row>
                                })}
                            </Col>
                        </Row>
                    </div>
                </> : <div className="empty">
                    <div className="icon">
                        <img alt="Bell Icon" src={BellSvg} />
                    </div>
                    <div className="main-title">
                        No Notifications Yet
                    </div>
                    <div className="sub-title">
                        Looks like you haven't set up any notifications to deliver or schedule.
                    </div>
                    <div className="create-notify">
                        <button className="sp_btn btn" onClick={() => { setSelectedNotify(null); setIsVisible(true); }} >+ Create Notification</button>
                    </div>
                </div>}
            </Col>
            <Save onClose={() => { setIsVisible(false); setSelectedNotify(null); loadNotification(); }} selectedNotification={selectedNotify} isVisible={isVisible} />
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
            {isLoading && <Loading background="#00000070"></Loading>}
        </Row >
    )
}

export default View;