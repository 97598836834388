import React, { useEffect, useRef, useState } from "react";
import "../../../assets/sass/dsk/dragdrop.sass";
import CloseWhiteSvg from "../../../assets/images/close_white.svg";
import PlaceholderSvg from "../../../assets/images/settings/media_placeholder.svg";
import SearchSvg from "../../../assets/images/settings/search.svg";
import DeleteSvg from "../../../assets/images/settings/cricle_delete.svg";
import UploadCompletedSvg from "../../../assets/images/settings/file_uploaded.svg";
import UploadFailedSvg from "../../../assets/images/settings/upload_failed.svg";
import { generateUniqueId, humanFileSize } from "../../../helpers/util";
import apiConfig from "../../../apiConfig";
import Loading from "../common/Loading";
import PopupConfirm from "../common/PopupConfirm";
import CloseSvg from "../../../assets/images/close_black.svg";
import VideoSvg from "../../../assets/images/media-icons/video.svg";
import AudioSvg from "../../../assets/images/media-icons/audio.svg";
import Unknown from "../../../assets/images/media-icons/unknown.svg";


interface DragDropUploadProps {
    files: [];
    consumption: number;
    onUpload: (file: any, disableImageTransform: boolean) => Promise<{ resp: { error: boolean; }; file: any; }>;
    onDelete: (file: any) => Promise<{ resp: { error: boolean; }; file: any; }>;
    onLoadFiles: () => void;
}

const DragDropUpload: React.FC<DragDropUploadProps> = (props) => {
    const { files, onLoadFiles, onUpload, onDelete, consumption } = props;
    const [uploading, setUploading] = useState(null);
    const [apiStatus, setApiStatus] = useState(null);
    // const [startedUploading, setStartedUploading] = useState([]);
    const [resetFileUpload, setResetFileUpload] = useState(new Date().getTime());
    const [disableImageTransform, setDisableImageTransform] = useState(false);
    const ddBtnRef = useRef<HTMLDivElement>(null);
    const fileRef = useRef<any>(null);
    const [consumptionSize, setConsumptionSize] = useState(null);
    const [fileList, setFileList] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [search, setSearch] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewPanel, setPreviewPanel] = useState(null);
    // const [infoPanel, setInfoPanel] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const dragdropUpload = useRef<HTMLDivElement>(null);
    const uploadArea = useRef<HTMLLabelElement>(null);
    const preventDefaults = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const highlight = () => {
        const ele = uploadArea.current;
        const ddEle = ddBtnRef.current;
        if (ele) {
            (ele as HTMLElement).className = "upload-label upload-active";
        }
        if (ddEle) {
            (ddEle as HTMLElement).style.display = "flex";
        }
    };

    useEffect(() => {
        if (consumption) {
            setConsumptionSize(consumption);
        } else {
            setConsumptionSize(null);
        }
    }, [consumption]);

    const unHighlight = () => {
        const ele = uploadArea.current;
        const ddEle = ddBtnRef.current;
        if (ele) {
            (ele as HTMLElement).className = "upload-label";
        }
        if (ddEle) {
            (ddEle as HTMLElement).style.display = "none";
        }
    };

    const handleDrop = (e: any) => {
        const dt = e.dataTransfer;
        const { files: fileItems } = dt;
        setFileList(fileItems);
    };

    const handleFiles = async () => {
        setLoading(true);
        let uploadFiles = [];
        for (let file of fileList) {
            uploadFiles.push({
                uid: generateUniqueId("media_file"),
                name: file.name,
                status: "started",
                url: URL.createObjectURL(file),
                fileExt: file.type,
                size: humanFileSize(file.size),
                originFileObj: file
            })
        }
        setApiStatus(uploadFiles);
        setUploading(uploadFiles)
        setLoading(false);
    };

    useEffect(() => {
        const updateFileStatus = (resp, newStatus) => {
            setApiStatus((prevFiles) => {
                const updatedFiles = [...prevFiles];
                updatedFiles.forEach((file) => {
                    if (file.uid === resp.file.uid) {
                        file.status = newStatus;
                    }
                });
                return updatedFiles;
            });
        };
        const processFile = async (index) => {
            const file = uploading[index];
            try {
                const resp: any = await onUpload(file, disableImageTransform);
                const newStatus = resp.error ? "failed" : "done";
                updateFileStatus(resp, newStatus);
                setFileList(null);
                setResetFileUpload(new Date().getTime());
                setDisableImageTransform(false);
            } catch (error) {
                console.error('Error processing file:', error);
            }
        };
        uploading?.forEach((file, index) => {
            if (file.status === 'started') {
                return processFile(index);
            }
        });
        //eslint-disable-next-line
    }, [uploading]);

    useEffect(() => {
        if (apiStatus) {
            const apiCompleted = apiStatus.filter((file: any) => file.status === "done" || file.status === "failed");
            if (apiCompleted.length > 0 && uploading?.length === apiCompleted?.length) {
                onLoadFiles();
                setTimeout(() => {
                    setUploading(null);
                    setApiStatus(apiStatus.filter((file: any) => file.status === "failed"));
                }, 5000);
            }
        }
        //eslint-disable-next-line
    }, [apiStatus])

    const onDeleteMultiple = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        let popupConfirmDetail = null;
        // setInfoPanel(null);
        popupConfirmDetail = {
            title: "Delete Multiple Media Files",
            renderContent: () => {
                return (
                    <div>
                        <div style={{ marginBottom: 10 }}>Do you like to remove these files</div>
                        <div style={{ display: "flex", flexWrap: "wrap", maxHeight: 200, overflowY: "auto" }}>
                            {selectedFiles.map((f, i) => {
                                return (
                                    <div key={`${i}_file`}>
                                        {getMediaType(apiConfig.baseImageUrl + f) === 'image' && <img
                                            style={{
                                                height: 40,
                                                borderRadius: 4,
                                                border: "2px solid #c7c7c7",
                                                marginRight: 10,
                                                marginBottom: 10
                                            }}
                                            src={apiConfig.baseImageUrl + f}
                                            alt={f}
                                        />}
                                        {getMediaType(apiConfig.baseImageUrl + f) === 'video' && <img className="media-icon" style={{
                                            height: 40,
                                            borderRadius: 4,
                                            border: "2px solid #c7c7c7",
                                            marginRight: 10,
                                            marginBottom: 10
                                        }} src={VideoSvg} alt={f} />}
                                        {getMediaType(apiConfig.baseImageUrl + f) === 'audio' && <img className="media-icon" style={{
                                            height: 40,
                                            borderRadius: 4,
                                            border: "2px solid #c7c7c7",
                                            marginRight: 10,
                                            marginBottom: 10
                                        }} src={AudioSvg} alt={f} />}
                                        {getMediaType(apiConfig.baseImageUrl + f) === 'unknown' && <img className="media-icon" style={{
                                            height: 40,
                                            borderRadius: 4,
                                            border: "2px solid #c7c7c7",
                                            marginRight: 10,
                                            marginBottom: 10
                                        }} src={Unknown} alt={f} />}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            },
            yes: {
                label: "Yes",
                callback: async () => {
                    setPopupConfirm(null);
                    setLoading(true);
                    await onDelete(selectedFiles);
                    setSelectedFiles([]);
                    setLoading(false);
                }
            },
            no: {
                label: "No",
                callback: () => { setPopupConfirm(null) }
            }
        }
        setPopupConfirm(popupConfirmDetail);
    }

    const onRemove = async (e, file) => {
        e.stopPropagation();
        e.preventDefault();
        setPreviewPanel(null);
        let popupConfirmDetail = null;
        popupConfirmDetail = {
            title: "Delete Media File",
            renderContent: () => { return <div>Do you like to remove the file <span style={{ fontWeight: 900, fontSize: "14px", letterSpacing: "0px", color: "#34E5C1" }}>{file?.fileName}</span></div> },
            yes: {
                label: "Yes",
                callback: async () => {
                    setPopupConfirm(null);
                    setLoading(true);
                    await onDelete(file);
                    setLoading(false);
                }
            },
            no: {
                label: "No",
                callback: () => { setPopupConfirm(null) }
            }
        }
        setPopupConfirm(popupConfirmDetail);
    }

    const onOpen = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        console.log("onOpen");
    }

    // const onInfo = (e: any, file: any) => {
    //     e.stopPropagation();
    //     e.preventDefault();
    //     setInfoPanel(file)
    // }

    const onPreview = (e: any, file: any) => {
        e.stopPropagation();
        e.preventDefault();
        setPreviewPanel(file);
    }

    // image preview close
    const onClosePreview = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setPreviewPanel(null);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            onClosePreview(event);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        //eslint-disable-next-line
    }, []);

    const onCopyUrl = (e: any, file: any) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(apiConfig.baseImageUrl + file);
    }

    const onMultiSelect = (e: any, file: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (selectedFiles.indexOf(file.objectUrl) === -1) {
            setSelectedFiles([...selectedFiles, file.objectUrl]);
        } else {
            setSelectedFiles(selectedFiles.filter((url: any) => url !== file.objectUrl));
        }
    }

    const onRemoveNotification = (e: any, file: any) => {
        e.stopPropagation();
        e.preventDefault();
        setApiStatus(apiStatus.filter((f: any) => f.uid !== file.uid));
    }

    useEffect(() => {
        const dropArea = dragdropUpload?.current;
        ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
            dropArea?.addEventListener(eventName, preventDefaults as EventListener, false);
        });

        ["dragenter", "dragover"].forEach((eventName) => {
            dropArea?.addEventListener(eventName, highlight as EventListener, false);
        });

        ["dragleave", "drop"].forEach((eventName) => {
            dropArea?.addEventListener(eventName, unHighlight as EventListener, false);
        });
        dropArea?.addEventListener("drop", handleDrop, false);

        return () => {
            ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
                dropArea?.removeEventListener(eventName, preventDefaults as EventListener, false);
            });

            ["dragenter", "dragover"].forEach((eventName) => {
                dropArea?.removeEventListener(eventName, highlight as EventListener, false);
            });

            ["dragleave", "drop"].forEach((eventName) => {
                dropArea?.removeEventListener(eventName, unHighlight as EventListener, false);
            });
            dropArea?.removeEventListener("drop", handleDrop, false);
        };
        //eslint-disable-next-line
    }, []);


    function getMediaType(url) {
        const extension = url.split('.').pop().toLowerCase();

        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'webp':
            case 'svg':
            case 'ico':
            case 'gif':
                return 'image';
            case 'mp3':
            case 'wav':
            case 'mpeg':
            case 'ogg':
            case 'midi':
                return 'audio';
            case 'mp4':
            case 'mov':
            case 'webm':
                return 'video';
            default:
                return 'unknown';
        }
    }

    return (
        <div className="dragdrop-upload" ref={dragdropUpload}>
            {isLoading && <Loading background="#00000070"></Loading>}
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
            {fileList && fileList.length > 0 && <div className="popup" >
                <div className="container">
                    <div className="box" style={{ background: "#fff" }}>
                        <div className="title">Do you want compress images?</div>
                        <div className="content">
                            <div>
                                <div style={{ color: "#000", marginBottom: 20 }}>Select whether you want to compress the images or upload the original image.</div>
                                <div style={{ display: 'flex', color: '#777777', fontWeight: 400, fontSize: 14, gap: 10, alignItems: "center" }} onClick={() => setDisableImageTransform(true)} >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: 'pointer', width: 20, height: 20, border: '1px solid #777777', borderRadius: 50 }}>
                                        <div style={{ width: 12, height: 12, borderRadius: 50, background: `${disableImageTransform ? '#121212' : '#fff'}` }} />
                                    </div>
                                    <label htmlFor="compress">Upload Raw Images</label>
                                </div>
                                <div style={{ display: 'flex', color: '#777777', fontWeight: 400, fontSize: 14, gap: 10, alignItems: "center", margin: '10px 0px' }} onClick={() => setDisableImageTransform(false)} >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: 'pointer', width: 20, height: 20, borderRadius: 50, border: '1px solid #777777' }}  >
                                        <div style={{ width: 12, height: 12, borderRadius: 50, background: `${!disableImageTransform ? '#121212' : '#fff'}` }} />
                                    </div>
                                    <label htmlFor="raw">Upload Compressed Images</label>
                                </div>
                            </div>
                        </div>
                        <div className="action">
                            <button style={{ fontWeight: 500, fontSize: 13, borderColor: "#D9D9D9" }} onClick={() => { setFileList(null); setResetFileUpload(new Date().getTime()); setDisableImageTransform(false); }} className="sp_btn yes">Cancel</button>
                            <button style={{ fontWeight: 500, fontSize: 13 }} className="sp_btn no" onClick={handleFiles}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>}
            <div className="contain">
                {files.length > 0 && <div className="actions">
                    <div className="search" style={{ opacity: 0 }}>
                        <img src={SearchSvg} alt="search" />
                        <input value={search} placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                    </div>
                    <div style={{ display: "flex" }}>
                        {selectedFiles?.length > 0 && <img src={DeleteSvg} style={{ cursor: "pointer", marginRight: 10, height: 44 }} alt="Multiple Delete" onClick={onDeleteMultiple} />}
                        {consumptionSize && <div className="storage-used">
                            <div className="used">{humanFileSize(consumptionSize, true)}</div>
                            <div className="label">Storage Used</div>
                        </div>}
                        <div className="upload-button" onClick={() => fileRef.current.click()}>Upload</div>
                    </div>
                </div>}
                <div className="file-panel">
                    <label className="upload-label" ref={uploadArea} htmlFor="file-comp">
                        <div className="files">
                            {files?.map((file: any, index) => (
                                <div key={index} className="file" onClick={onOpen}>
                                    <div className="image" onClick={(e) => onPreview(e, file)}>
                                        <div className="layer"></div>
                                        <img alt={file.name} src={apiConfig.baseImageUrl + file?.objectUrl} />
                                        {getMediaType(file?.objectUrl) === 'video' && <img className="media-icon" src={VideoSvg} alt="video" />}
                                        {getMediaType(file?.objectUrl) === 'audio' && <img className="media-icon" src={AudioSvg} alt="audio" />}
                                        {getMediaType(file?.objectUrl) === 'unknown' && <img className="media-icon" src={Unknown} alt="unknown" />}
                                        {(selectedFiles?.length < 5 || selectedFiles.indexOf(file.objectUrl) > -1) && <div className="checkbox" style={{ display: selectedFiles.indexOf(file.objectUrl) > -1 && "block" }} onClick={(e) => onMultiSelect(e, file)} >
                                            <div className={`${selectedFiles.indexOf(file.objectUrl) > -1 ? "checked" : "unchecked"}`}></div>
                                        </div>}

                                    </div>
                                </div>
                            )
                            )}
                            {files.length === 0 && <div className="placeholder">
                                <img src={PlaceholderSvg} alt="" />
                                <div>Drag & Drop or Click to Upload an image to use in Store or Outlets</div>
                            </div>}
                        </div>
                        <input
                            key={resetFileUpload}
                            type="file"
                            id="file-comp"
                            multiple
                            ref={fileRef}
                            accept="image/*"
                            onChange={(e) => {
                                setFileList(e.target.files);
                            }}
                        />
                        {/* <div className="image" /> */}
                        <div className="dd-notify" ref={ddBtnRef}>Drop files to upload</div>
                        {apiStatus && <div className="uploading">
                            {apiStatus.map((file: any, index) => {
                                console.log("file", file);
                                return <div className="item" key={file.uid}>
                                    <div style={{ margin: 7 }}>
                                        <div className="name">{file.name}</div>
                                        <div className="size">{file.size}</div>
                                    </div>
                                    {file.status === "started" && <div className="started" />}
                                    {file.status === "done" && <img style={{ margin: "0px 8px" }} src={UploadCompletedSvg} alt="uploaded" />}
                                    {file.status === "failed" && <img className="closed" src={CloseSvg} alt="close" onClick={(e) => onRemoveNotification(e, file)} />}
                                    {file.status === "failed" && <img style={{ margin: "0px 8px" }} src={UploadFailedSvg} alt="uploaded" />}
                                </div>
                            })}
                        </div>}
                    </label>
                </div>
            </div>

            {previewPanel && <div className="preview-panel" onClick={(e) => { onClosePreview(e) }}>
                <div className="back">


                    <img src={CloseWhiteSvg} onClick={(e) => { onClosePreview(e) }} alt="close" />
                </div>
                <div className="container" onClick={(e) => e.stopPropagation()}>
                    <div className="row-left">
                        {previewPanel?.mediaType?.indexOf("image") > -1 && <img src={apiConfig.baseImageUrl + previewPanel?.objectUrl} alt={previewPanel?.fileName} />}
                        {previewPanel?.mediaType?.indexOf("video") > -1 && <video src={apiConfig.baseImageUrl + previewPanel?.objectUrl} controls autoPlay />}
                        {previewPanel?.mediaType?.indexOf("audio") > -1 && <audio src={apiConfig.baseImageUrl + previewPanel?.objectUrl} controls autoPlay />}
                        {previewPanel?.mediaType?.indexOf("unknown") > -1 && <img src={Unknown} alt={previewPanel?.fileName} />}
                    </div>
                    <div className="row-right">
                        {previewPanel?.fileName && <div className="fileName">{previewPanel?.fileName}</div>}
                        {previewPanel?.fileSize && <div className="fileSize">{humanFileSize(previewPanel?.fileSize, true)}</div>}
                        {previewPanel?.mediaType && <div className="fileType">{previewPanel?.mediaType.split("/")[0]}</div>}
                        {previewPanel?.objectUrl && <div className="copy-url" data-id="copy_url" onClick={(e) => onCopyUrl(e, previewPanel?.objectUrl)}>Copy Url</div>}
                        {previewPanel?.objectUrl && <div className="download" >
                            <a
                                className="download-link"
                                href={apiConfig.baseImageUrl + previewPanel?.objectUrl}
                                download
                                data-id="download_file"
                                onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                            >
                                Download File
                            </a>
                        </div>}
                        {previewPanel && <div className="delete" data-id="delete" onClick={(e) => onRemove(e, previewPanel)}>Delete</div>}
                    </div>

                </div>
            </div>}
        </div >
    );
}

export default DragDropUpload;
