import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { sendOTP, verifyOTP } from "../../../actions/setupStoreActions";
// import { getSetupStoreDetails, setSetupStoreDetails } from "../../../actions/uiAction";
// import Locale from "../common/Locale";
import '../../../assets/sass/dsk/setupstore.sass';
import { withTranslation } from 'react-i18next';
import { isMobile } from '../../../helpers/util';
// import Header from './Header';
// import Footer from './Footer';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNav } from '../../../helpers/customHook';

let otpTimeCounter = null;
const VerifyOTP = (props: any) => {
    const dispatch: any = useDispatch();
    const params = useParams();
    const navigateTo = useNav();
    // const windowHeight = window.innerHeight;
    const [mobileNumber, setMobileNumber] = useState("");
    const [otp, setOTP] = useState("");
    const [otpTimestamp, setOTPTimestamp] = useState(0);
    const [otpRunningTimer, setOTPRunningTimer] = useState(null);
    const [error, setError] = useState(null);
    const { otpTimestamp: otpTimestampProp } = useSelector((state: any) => state.setupStore);

    useEffect(() => {
        if (params.mobileNo) {
            setMobileNumber(params.mobileNo);
            setOTPTimestamp(new Date().getTime());
            otpTimer();
        }
        //eslint-disable-next-line
    }, [params.mobileNo]);

    useEffect(() => {
        setOTPTimestamp(otpTimestampProp);
        //eslint-disable-next-line
    }, [otpTimestampProp])

    useEffect(() => {
        otpTimer();
        //eslint-disable-next-line
    }, [otpTimestamp]);

    const otpTimer = () => {
        // Set the date we're counting down to
        let countDownDate = otpTimestamp + (300 * 1000);
        if (otpTimeCounter) {
            clearInterval(otpTimeCounter);
        }
        // Update the count down every 1 second
        otpTimeCounter = setInterval(function () {
            // Get today's date and time
            let now = new Date().getTime();
            // Find the distance between now and the count down date
            let distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            // Output the result in an element with id="demo"
            setOTPRunningTimer(minutes + ":" + (seconds.toString().length === 1 ? "0" + seconds.toString() : seconds));
            if (distance < 0) {
                clearInterval(otpTimeCounter);
                setOTPRunningTimer(null);
            }
            //eslint-disable-next-line
        }.bind(this), 1000);
    }

    const onVerifyOTP = async () => {
        if (otp.length === 6 && mobileNumber.length === 10) {
            const resp = await dispatch(verifyOTP("91", mobileNumber, otp));
            if (resp.error) {
                setError(resp.error);
            } else {
                navigateTo("/setupstore/userType");
            }
        }
    }

    const resendOTP = (e) => {
        e.preventDefault();
        let code = "91";
        if (otpRunningTimer === null && isMobile(mobileNumber)) {
            dispatch(sendOTP(code, mobileNumber))
        }
    }

    // const { t } = props
    return <div style={{ marginTop: 120 }}>
        <div>
            <div className="title">
                OTP Verification
            </div>
            <div className="subtitle">
                Enter the <div className="highlight">OTP</div> sent to +91 <div className="highlight" style={{ fontWeight: 'normal' }}>{mobileNumber}</div>
            </div>
            <div className="otp">
                <div className="label">OTP</div>
                <div className="number" style={{ marginBottom: 0 }} >
                    <input style={{ textAlign: "center" }} maxLength={6} type="text" placeholder="Enter Verification Code" onChange={(e) => setOTP(e.target.value)} />
                </div>
                <div className="error">{error}</div>
                <div className="resend">
                    <div className="timer">{otpRunningTimer}</div><div style={{ color: otpRunningTimer ? '#a7a7a7' : '#4A9CFD' }} className="action" onClick={resendOTP}>Resend OTP</div>
                </div>
                <button style={{ cursor: otp.length === 6 ? 'pointer' : 'default' }} onClick={onVerifyOTP}>Verify OTP</button>
            </div>
        </div>
    </div>
}

export default connect()(withTranslation()(VerifyOTP));
