import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import "../../../../../assets/sass/dsk/settings.sass";
import { useNav } from "../../../../../helpers/customHook";

const Menus = [{
    key: 'about',
    label: 'About'
}, {
    key: 'brand',
    label: 'Brand'
}, {
    key: 'sociallinks',
    label: 'Social Links'
}, {
    key: 'sitestatus',
    label: 'Site Status'
}, {
    key: 'pages',
    label: 'Pages'
}];

const General = (props: any) => {
    const navigateTo = useNav();
    const location = useLocation();
    const [selectedMenu, setMenu] = useState(null);
    useEffect(() => {
        const pathNames = location.pathname.split("/");
        const menuMatched = Menus.filter((m) => m.key === pathNames[pathNames.length - 1]);
        if (menuMatched.length > 0) {
            setMenu(menuMatched[0].key)
        } else {
            setMenu(Menus[0].key);
            navigateTo(`${Menus[0].key}`)
        }
        //eslint-disable-next-line
    }, [])
    return <div className="settings">
        <div className="menus">
            {Menus.map((m) => {
                return <Link to={`${m.key}`} onClick={() => setMenu(m.key)} className={selectedMenu === m.key ? "item selected" : 'item'} key={m.key} >{m.label}</Link>
            })}
        </div>
        <div className="content" style={{ height: window.innerHeight - 80 }}>
            <Outlet />
        </div>
    </div>
};

export default General;