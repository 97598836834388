import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "../../../assets/sass/dsk/leftNav.sass";
// import logout from "../../../assets/images/left-panel-icons/exit.svg";
import logo from "../../../assets/images/left-panel-icons/logo.svg";
import homeIcn from "../../../assets/images/left-panel-icons/home.svg"
import productsIcn from "../../../assets/images/left-panel-icons/products.svg";
import dashboardIcn from "../../../assets/images/left-panel-icons/dashboard.svg";
import helpCenterIcn from "../../../assets/images/left-panel-icons/store_support.svg";
// import menuSelectionIcn from "../../../assets/images/left-panel-icons/menu-selection.svg";
import searchIcn from "../../../assets/images/search.svg"
import ordersIcn from "../../../assets/images/left-panel-icons/order.svg";
import settingIcn from "../../../assets/images/left-panel-icons/setting_1.svg";
import promotionIcn from "../../../assets/images/left-panel-icons/promotion.svg";
import quickMenuIcn from "../../../assets/images/left-panel-icons/quick_menu.svg";
import appDevIcn from "../../../assets/images/left-panel-icons/app_dev.svg";
import customerIcn from "../../../assets/images/left-panel-icons/customer.svg";
import collectionIcn from "../../../assets/images/left-panel-icons/collection.svg";
import CategorySvg from "../../../assets/images/left-panel-icons/category.svg";
import navigationIcn from "../../../assets/images/left-panel-icons/navigation.svg";
import mediaIcn from "../../../assets/images/left-panel-icons/media.svg";
import botIcn from "../../../assets/images/left-panel-icons/bot.svg";
import blogsIcn from "../../../assets/images/left-panel-icons/blogs.svg";
import pagesIcn from "../../../assets/images/left-panel-icons/pages.svg";
import notificationsIcn from "../../../assets/images/left-panel-icons/notifications.svg";
import marketplaceIcn from "../../../assets/images/left-panel-icons/marketplace.svg";
import themesIcn from "../../../assets/images/left-panel-icons/themes.svg";
import { getPermission } from "../../../helpers/util";
import { useNav } from "../../../helpers/customHook";
import useClickOutside from "./useClickOutside";
import { Input } from "antd";

const Menus = [{
  value: 'home',
  name: 'home',
  icnEnb: homeIcn,
  icnDis: homeIcn,
  position: 'main-menu',
  permissions: [
    "all"
  ]
},
{
  value: 'dashboard',
  name: 'Dashboard',
  icnEnb: dashboardIcn,
  icnDis: dashboardIcn,
  position: 'main-menu',
  permissions: [
    "all"
  ]
},
{
  value: 'orders/current',
  name: 'Orders',
  position: 'main-menu',
  icnEnb: ordersIcn,
  icnDis: ordersIcn,
  permissions: [
    "order.list"
  ]
},
{
  value: 'collections/listed',
  position: 'ext-menu',
  name: 'Collections',
  icnEnb: collectionIcn,
  icnDis: collectionIcn,
  permissions: [
    "category.list",
    "collection.list"
  ]
},
{
  value: 'categories/listed',
  position: 'ext-menu',
  name: 'Categories',
  icnEnb: CategorySvg,
  icnDis: CategorySvg,
  permissions: [
    "category.list"
  ]
},
{
  value: 'products/listed',
  position: 'main-menu',
  name: 'Products',
  icnEnb: productsIcn,
  icnDis: productsIcn,
  permissions: [
    "product.list"
  ]
},
// {
//   value: 'promotions',
//   position: 'main-menu',
//   name: 'Promotions',
//   icnEnb: promotionIcn,
//   icnDis: promotionIcn,
//   permissions: [
//     "promotion.list"
//   ]
// },
{
  value: 'customers',
  position: 'main-menu',
  name: 'Customers',
  icnEnb: customerIcn,
  icnDis: customerIcn,
  permissions: [
    "customer.list"
  ]
}, {
  value: 'quickMenu',
  name: 'Quick Menu',
  position: 'main-menu',
  icnEnb: quickMenuIcn,
  icnDis: quickMenuIcn,
  permissions: [
    "storeSettings.update"
  ]
}, {
  value: 'appdev',
  name: 'App Dev',
  icnEnb: appDevIcn,
  icnDis: appDevIcn,
  position: 'bottom-menu',
  permissions: [
    "all"
  ]
},
{
  value: 'settings',
  name: 'Settings',
  icnEnb: settingIcn,
  icnDis: settingIcn,
  position: 'bottom-menu',
  permissions: [
    "storeSettings.update"
  ]
}, {
  value: 'navigations/listed',
  name: 'Navigation',
  icnEnb: navigationIcn,
  icnDis: navigationIcn,
  position: 'ext-menu',
  permissions: [
    "all"
  ]
}, {
  value: 'promotions/listed',
  name: 'Promotions',
  icnEnb: promotionIcn,
  icnDis: promotionIcn,
  position: 'ext-menu',
  permissions: [
    "promotion.list"
  ]
}, {
  value: 'media',
  name: 'Media',
  icnEnb: mediaIcn,
  icnDis: mediaIcn,
  position: 'ext-menu',
  permissions: [
    "all"
  ]
}, {
  value: 'bot',
  name: 'Bot',
  icnEnb: botIcn,
  icnDis: botIcn,
  position: 'ext-menu',
  isNewLunch: true,
  permissions: [
    "all"
  ]
}, {
  value: 'blogs/post',
  name: 'Blogs',
  title: 'Content Management',
  icnEnb: blogsIcn,
  icnDis: blogsIcn,
  position: 'ext-menu',
  permissions: [
    "all"
  ]
}, {
  value: 'pages',
  name: 'Pages',
  icnEnb: pagesIcn,
  icnDis: pagesIcn,
  position: 'ext-menu',
  permissions: [
    "all"
  ]
}, {
  value: 'notifications',
  name: 'Notifications',
  title: 'Marketing',
  icnEnb: notificationsIcn,
  icnDis: notificationsIcn,
  position: 'ext-menu',
  permissions: [
    "all"
  ]
}, {
  value: 'marketplace',
  name: 'Marketplace',
  title: 'Discover',
  icnEnb: marketplaceIcn,
  icnDis: marketplaceIcn,
  commingSoon: true,
  position: 'ext-menu',
  permissions: [
    "all"
  ]
}, {
  value: 'themes/discover',
  name: 'Themes',
  icnEnb: themesIcn,
  icnDis: themesIcn,
  position: 'ext-menu',
  permissions: [
    "all"
  ]
}, {
  value: 'billing/plans',
  name: 'Billing',
  title: 'Account',
  icnEnb: blogsIcn,
  icnDis: blogsIcn,
  position: 'ext-menu',
  permissions: [
    "all"
  ]
}, {
  value: 'helpCenter',
  name: 'Help Center',
  icnEnb: helpCenterIcn,
  icnDis: helpCenterIcn,
  position: 'ext-menu',
  permissions: [
    "all"
  ]
}];



const LeftNav = (props) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const appData = params.get("appData");
  const { selectedStoreDetails } = useSelector((state: any) => state.common);
  const { store, currentMainMenu } = props;
  const [permissions, setPermissions] = useState(null);
  const [quickMenuSearch, setQuickMenuSearch] = useState("");
  const [isOwner, setIsOwner] = useState(false);
  const [isQuickMenu, setQuickMenu] = useState(false);
  const [isQuickMenuHide, setQuickMenuHide] = useState(true);
  const navigateTo = useNav();
  const quickMenuRef = useRef();
  const [iconCondition, setIconCondition] = useState({
    appdev: null,
    quickMenuSelected: false
  });
  const closeColor = useCallback(() => setQuickMenu(false), []);
  useClickOutside(quickMenuRef, closeColor);

  const onSelectedView = (event: any, mainMenu: any) => {
    event.stopPropagation();
    // const [, store, currentMainMenu, currentSubMenu] = location.pathname.split("/");
    if (currentMainMenu !== mainMenu.value) {
      if (mainMenu.position === 'ext-menu') {
        setIconCondition(preState => ({
          ...preState, quickMenuSelected: true
        }));
      } else {
        setIconCondition(preState => ({
          ...preState, quickMenuSelected: false
        }));
      }
      if (mainMenu.value === "quickMenu") {
        isQuickMenu ? setQuickMenu(false) : setQuickMenuHide(false)
      } else {
        setQuickMenu(false);
        let routePath = `/${store}/${mainMenu.value}`;
        navigateTo(routePath);
        // setSelectedMainMenu(mainMenu);
        // setCurrentMainMenu(mainMenu);
        // dispatch(onSelectedMainMenu(mainMenu));
      }
    }
  }

  useEffect(() => {
    setIconCondition(preState => ({
      ...preState, appdev: appData
    }));
  }, [appData]);

  // useEffect(() => {
  //   if (mainMenu) {
  //     // setCurrentMainMenu(mainMenu);
  //     setSelectedMainMenu(mainMenu);
  //   }
  // }, [mainMenu])
  useEffect(() => {
    if (!isQuickMenuHide) {
      setQuickMenu(true);
    }
  }, [isQuickMenuHide])
  useEffect(() => {
    if (!isQuickMenu) {
      setTimeout(() => {
        setQuickMenuHide(true);
      }, 500);
    }
  }, [isQuickMenu])

  useEffect(() => {
    if (selectedStoreDetails?.userType === "owner") {
      setIsOwner(true)
    } else if (selectedStoreDetails?.roles) {
      const perm = getPermission(selectedStoreDetails?.roles)
      setPermissions(perm);
    }
  }, [selectedStoreDetails?.roles, selectedStoreDetails?.userType]);

  const onPermissionCheck = (perm: any) => {
    if (isOwner) {
      return true
    } else if (permissions) {
      return permissions.some((p: any) => perm.includes(p) || perm.includes("all"));
    }
    return false;
  }

  const onRenderMenus = (position) => {
    return (<ul>
      {Menus.map((m, i) => {
        const selectedMenu = m.value.split("/")[0];
        return (iconCondition[selectedMenu] === undefined || iconCondition[selectedMenu] !== null) && m.position === position && (!m.permissions || onPermissionCheck(m.permissions)) && <li
          data-tut={`reactour__${selectedMenu}`}
          key={selectedMenu}
          onClick={(event) => onSelectedView(event, m)}
        >
          <div className="tooltip">
            <div className="selected" style={{
              width: (currentMainMenu === selectedMenu || (iconCondition.quickMenuSelected && selectedMenu === "quickMenu")) ? 40 : 0,
              height: (currentMainMenu === selectedMenu || (iconCondition.quickMenuSelected && selectedMenu === "quickMenu")) ? 40 : 0
            }}></div>
            <img alt="Menu Icon" style={{ zIndex: 2, cursor: currentMainMenu === selectedMenu ? "default" : "pointer" }} src={currentMainMenu === selectedMenu ? m.icnEnb : m.icnDis} />
            {!isQuickMenu && <span className="tooltiptext">{m.name}</span>}
          </div>
        </li>
      })}
    </ul>)
  }

  return (
    <div className="left-nav">
      <div className="main-menu">
        <div className="section">
          <div className="logo" onClick={(event) => { navigateTo('/selectstore') }}>
            <img alt="Logo" src={logo} style={{ marginTop: 4, height: 40, marginRight: 0 }} />
          </div>
          <div className="menus">
            {onRenderMenus("main-menu")}

          </div>
        </div>
        <div className="section">
          <div className="menus">
            {onRenderMenus("bottom-menu")}
          </div>
        </div>
      </div>
      {/* <div className="ext-menu" style={{ zIndex: isQuickMenuHide ? -1 : 2 }}> */}
      <div ref={quickMenuRef} className={isQuickMenu ? "ext-menu" : "ext-menu inactive"} >
        <div className="title">Explore</div>
        <Input className="search" prefix={<img src={searchIcn} alt="search icon" />} placeholder="Search" onChange={(e) => setQuickMenuSearch(e.target.value)} />
        <div style={{
          marginTop: "26px",
          height: "calc(100vh - 150px)",
          overflowY: "auto"
        }}>
          <ul>
            {Menus.map((m, i) => {
              return (quickMenuSearch.length === 0 || m.name.toLowerCase().indexOf(quickMenuSearch.toLowerCase()) > -1) && m.position === "ext-menu" && (!m.permissions || onPermissionCheck(m.permissions)) && <div key={`${m.value}_title_ext_menu`}>
                {m.title && <div className="title">{m.title}</div>}
                <li
                  data-tut={`reactour__${m.value}`}
                  // key={`${m.value}_ext_menu`}
                  style={{ textAlign: "left", cursor: (m?.commingSoon || currentMainMenu === m.value) ? "default" : "pointer" }}
                  onClick={(event) => !m?.commingSoon && onSelectedView(event, m)}
                >
                  <div style={{ display: "flex", alignItems: "center" }} >
                    <div className="selected" style={{ width: currentMainMenu === m.value ? 268 : 0, height: currentMainMenu === m.value ? 30 : 0, left: 15 }}></div>
                    <img alt="Menu Icon" style={{ zIndex: 2, cursor: (m?.commingSoon || currentMainMenu === m.value) ? "default" : "pointer", marginRight: "10px" }} src={currentMainMenu === m.value ? m.icnEnb : m.icnDis} />
                    <div className="tooltiptext" style={{ zIndex: 0 }}>{m.name}</div>
                    {m?.commingSoon && <div className='label-coming-soon'>Coming Soon</div>}
                    {m?.isNewLunch && <div className='new-lunch'>New</div>}
                  </div>
                </li>
              </div>
            })}
          </ul>
        </div>
      </div>
      {/* </div> */}
    </div >
  );

}

export default LeftNav;