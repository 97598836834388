import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPromotionById, getPromotions, onDeletePromotion } from '../../../actions/promotionAction';
import SearchIcn from "../../../assets/images/search.svg";
import DeleteIcn from '../../../assets/images/delete.svg';
import '../../../assets/sass/dsk/promotion.sass';
import Save from "./Save";
import { Col, DatePicker, Input, Row } from 'antd';
import dayjs from 'dayjs';
import PopupConfirm from '../common/PopupConfirm';
import Loading from '../common/Loading';

const { RangePicker } = DatePicker;
const View = (props: any) => {
    const sDate = dayjs();
    const eDate = dayjs();
    const dispatch = useDispatch();
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [promotions, setPromotions] = useState([]);
    // const [promotion, setPromotion] = useState(null);
    const [promoSearch, setPromoSearch] = useState(null);
    const [summarySearch, setSummarySearch] = useState(null);
    const [codeSearch, setCodeSearch] = useState(null);
    const [availableSearch, setAvailableSearch] = useState(null);
    const [filterPromotions, setFilterPromotions] = useState([]);
    // const [discountSearch, setDiscountSearch] = useState(null);
    const [selectedDate, setSelectedDate]: any = useState([sDate, eDate]);
    // const [claimedSearch, setClaimedSearch] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [selectedPromotion, setSelectedPromotion] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const windowHeight = window.innerHeight;
    useEffect(() => {
        onFilterPromotions();
        //eslint-disable-next-line
    }, [codeSearch, promoSearch, summarySearch, availableSearch])
    useEffect(() => {
        loadPromotions();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        onFilterPromotions();
        //eslint-disable-next-line
    }, [promotions]);
    const onFilterPromotions = () => {
        let filterData = [];
        let isCode = false;
        let isPromo = false;
        let isSummary = false;
        let isAvailable = false;
        for (let p of promotions) {
            isCode = false;
            isPromo = false;
            isSummary = false;
            isAvailable = false;
            if (promoSearch) {
                if (p.title && p.title.toLowerCase().indexOf(promoSearch.toLowerCase()) > -1) {
                    isPromo = true
                }
            } else {
                isPromo = true;
            }
            if (summarySearch) {
                if (p.description && p.description.toLowerCase().indexOf(summarySearch.toLowerCase()) > -1) {
                    isSummary = true
                }
            } else {
                isSummary = true;
            }
            if (codeSearch) {
                if (p.code && p.code.toLowerCase().indexOf(codeSearch.toLowerCase()) > -1) {
                    isCode = true
                }
            } else {
                isCode = true;
            }
            if (availableSearch) {
                if (p.code && p.available.toString().indexOf(availableSearch.toString()) > -1) {
                    isAvailable = true
                }
            } else {
                isAvailable = true;
            }
            if (isPromo && isCode && isSummary && isAvailable) {
                filterData.push(p);
            }
        }
        setFilterPromotions(filterData)
    }
    const loadPromotions = async () => {
        const resp: any = await getPromotions()(dispatch);
        if (resp && resp.promotions) {
            setPromotions(resp.promotions);
        }
    }
    const onChangeDate = (dayjsDate, dateString) => {
        // console.log(dayjsDate[0], dayjsDate[1])
        console.log(dateString)
        if (dateString.length > 1) {
            setSelectedDate([dayjs(dateString[0]), dayjs(dateString[1])])
        }
    }
    const deletePromotion = async (item) => {
        const title = `Promotion remove from store`;
        setPopupConfirm({
            title,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to remove <span style={{ fontWeight: "bold" }}>{item.title}</span> promotion from <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>{selectedStore.name}</span> store.</span></div > },
            yes: {
                label: "Remove Promotion",
                callback: async () => {
                    setIsLoading(true);
                    setPopupConfirm(null);
                    const resp: any = await onDeletePromotion(item.id)(dispatch);
                    if (resp && resp.isDeleted) {
                        loadPromotions();
                    }
                    setIsLoading(false);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }
    const loadPromotion = async (id) => {
        const resp: any = await getPromotionById(id)(dispatch);
        if (resp) {
            setSelectedPromotion(resp);
            setIsVisible(true);
        }
    }
    return (
        <Row className="promotion">
            {/* <Col span={24}>
                    <div className="head">Products</div>
                </Col> */}
            <Col className="list" style={{ height: windowHeight - 76 }}>
                <div className="action-bar">
                    <div className="title">
                        You've got {filterPromotions && filterPromotions.length} promotion listed
                    </div>
                    <div className="actions">
                        <button style={{ width: 160 }} className="sp_btn btn" onClick={() => { setSelectedPromotion(null); setIsVisible(true); }} >+ Add Promotion</button>
                    </div>
                </div>
                <div className="content">
                    <Row className="header">
                        <Col span={5}>
                            Promo Name
                        </Col>
                        <Col span={8}>
                            Summary
                        </Col>
                        <Col span={4}>
                            Code
                        </Col>
                        <Col span={2}>
                            Available
                        </Col>
                        <Col span={4}>
                            Valid Date
                        </Col>
                        {/* <Col span={3}>
                            Discount
                        </Col>
                        <Col span={3}>
                            Claimed
                        </Col> */}
                        <Col span={1}>
                            {/* Action */}
                        </Col>
                    </Row>
                    <Row className="filters">
                        <Col span={5}>
                            <Input className="filter" name="promoSearch" onChange={(event) => { setPromoSearch(event.target.value) }} placeholder="Promo Name" suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={8}>
                            <Input className="filter" placeholder="Summary" name="summarySearch" onChange={(event) => setSummarySearch(event.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={4}>
                            <Input className="filter" placeholder="Code" name="codeSearch" onChange={(event) => setCodeSearch(event.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={2}>
                            <Input className="filter" placeholder="Available" name="availableSearch" onChange={(event) => setAvailableSearch(event.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={4}>
                            <div className="date-range">
                                <RangePicker defaultValue={selectedDate} onChange={(dates, dateStrings) => onChangeDate(dates, dateStrings)} />
                            </div>
                        </Col>
                        {/* <Col span={3}>
                            <Input className="filter" placeholder="Discount" name="discountSearch" onChange={(event) => setDiscountSearch(event.target.value)} suffix={<img src={SearchIcn} />} />
                        </Col>
                        <Col span={3}>
                            <Input className="filter" placeholder="Claimed" name="claimedSearch" onChange={(event) => setClaimedSearch(event.target.value)} suffix={<img src={SearchIcn} />} />
                        </Col> */}
                        <Col span={1}>
                        </Col>
                    </Row>
                    <Row className="contains" style={{ height: windowHeight - 234 }}>
                        <Col span="24">
                            {filterPromotions.map((item) => {
                                return <Row className='item' key={item.id} style={{ cursor: 'pointer' }} onClick={() => { loadPromotion(item.id) }}>
                                    <Col span={5} style={{ paddingLeft: 2 }}>
                                        {item.title}
                                    </Col>
                                    <Col span={8} style={{ paddingLeft: 2 }}>
                                        {item.description}
                                    </Col>
                                    <Col span={4} style={{ paddingLeft: 2 }}>
                                        {item.code}
                                    </Col>
                                    <Col span={2} style={{ paddingLeft: 2 }}>
                                        {item.available}
                                    </Col>
                                    <Col span={4} style={{ paddingLeft: 2, textAlign: 'center' }}>
                                        {dayjs(item.validFrom).format('DD/MM/YYYY')} -   {dayjs(item.validUpto).format('DD/MM/YYYY')}
                                    </Col>
                                    {/* <Col span={3}>
                                        {item.code}
                                    </Col>
                                    <Col span={3}>
                                        {item.title}
                                    </Col> */}
                                    <Col span={1} style={{ paddingLeft: 2, textAlign: 'center' }}>
                                        <img alt="Delete Icon" src={DeleteIcn} onClick={(event) => { event.stopPropagation(); deletePromotion(item); }} />
                                    </Col>
                                </Row>
                            })}
                        </Col>
                    </Row>
                </div>
            </Col>
            <Save onClose={() => { setIsVisible(false); setSelectedPromotion(null); loadPromotions(); }} selectedPromotion={selectedPromotion} isVisible={isVisible} />
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
            {isLoading && <Loading background="#00000070"></Loading>}
        </Row >
    )
}

export default View;