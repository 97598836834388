import React from 'react';
import { connect } from "react-redux";
import { selectedMainMenu, selectedTabMenu } from "../../../actions/uiAction";
import { Outlet } from 'react-router-dom';

const Category = (props: any) => {
    return (
        <div className="menu">
            <Outlet />
        </div>
    );
}

export default connect(null, { selectedMainMenu, selectedTabMenu })(Category);