import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedMainMenu as onSelectedMainMenu, selectedTabMenu as onSelectedTabMenu } from "../../../actions/uiAction";
import "../../../assets/sass/dsk/home.sass";
// import Calendar from "../../../assets/svgComponents/overview/calendar";
// import Analytics from "../../../assets/svgComponents/overview/analytics";
// import TotalCustomerSvg from "../../../assets/svgComponents/overview/totalCustomer";
import CheckSvg from "../../../assets/svgComponents/overview/check";
import UnCheckSvg from "../../../assets/svgComponents/overview/uncheck";
import BankCardSvg from "../../../assets/svgComponents/overview/bankCard";
import CMobSvg from "../../../assets/svgComponents/overview/cMob";
import CWebSvg from "../../../assets/svgComponents/overview/cWeb";
import OutletSvg from "../../../assets/svgComponents/overview/outletSv";
// import NewFeatureSVG from "../../../assets/svgComponents/overview/newFeature";
import StoreConfigSvg from "../../../assets/svgComponents/overview/storeConfig";
import "ka-table/style.css";
// import { ITableProps, kaReducer, Table } from 'ka-table';
// import { DataType, EditingMode, SortingMode } from 'ka-table/enums';
// import { DispatchFunc } from 'ka-table/types';
import Confetti from 'react-confetti'
import { getStoreDetails } from "../../../actions/brandAction";
import ComingSoon from "../settings/ComingSoon";
import { useNav } from "../../../helpers/customHook";

const storeSetup = [{
    title: "Store Configuration",
    key: "storeConfiguration",
    steps: [
        { key: "storeBranding", title: "Setup your branding", status: false, link: '/{sName}/settings/store/general/brand' },
        { key: "businessHours", title: "Setup your business hours", status: false, link: '/{sName}/settings/store/businesshours' },
        { key: "deliverySlot", title: "Set your first delivery slot", status: false, link: '/{sName}/settings/store/deliveryslots' }
    ],
    status: false
}, {
    title: "Outlet",
    key: "outlet",
    steps: [
        { key: "createOutlet", title: "Create Outlet", status: false, link: '/{sName}/settings/store/outlet' },
        { key: "deliverySlotForOutlet", title: "Add delivery slot to outlet", status: false, link: 'none' },
        { key: "category", title: "Create new category", status: false, link: '/{sName}/categories' },
        { key: "productCatalog", title: "Create new product catalog", status: false, link: '/{sName}/products/catalog' },
        { key: "productForOutlet", title: "Add product to the outlet", status: false, link: '/{sName}/products/listed' }
    ],
    status: false
}, {
    title: "Bank Details",
    key: "bankDetails",
    steps: [
        { key: "bankDetails", title: "Add your bank details", status: false, link: '/{sName}/settings/account/bankdetails' }
    ],
    status: false

}, {
    title: "Channel-web",
    key: "channelWeb",
    steps: [
        { key: "customDomain", title: "Setup your custom domain", status: false, link: '/{sName}/settings/channels/web/customdomain' },
        // { key: "codeInjection", title: "Setup your code injection", status: false, link: '/{sName}/settings/channels/web/codeinjection' }
    ],
    status: false

    // }, {
    //     title: "Channel-mobile",
    //     key: "channelMob",
    //     steps: [
    //         { key: "mobileBranding", title: "Setup your branding", status: false, link: '/{sName}/settings/store/themeconfiguration' }
    //     ],
    //     status: false
}];


// const dataArray: any[] = [
//     { id: 1, customer: "Mike Wazowski", status: "Awaiting Confirmation", amount: "₹750", date: "13 May 22, 01:30 PM", payment: "Online" },
//     { id: 2, customer: "Billi Bob", status: "Completed", amount: "₹750", date: "13 May 22, 01:30 PM", payment: "Online" },
//     { id: 3, customer: "Tom Williams", status: "Awaiting Confirmation", amount: "₹750", date: "13 May 22, 01:30 PM", payment: "COD" },
//     { id: 4, customer: "Kurt Cobain", status: "Awaiting Confirmation", amount: "₹1250", date: "13 May 22, 01:30 PM", payment: "Online" },
//     { id: 5, customer: "Marshall Bruce", status: "Awaiting Fulfilment", amount: "₹1250", date: "13 May 22, 01:30 PM", payment: "COD" },
//     { id: 6, customer: "Sunny Fox", status: "Completed", amount: "₹750", date: "13 May 22, 01:30 PM", payment: "COD" }
// ];

// const tablePropsInit: ITableProps = {
//     columns: [
//         { key: 'amount', title: 'Amount', dataType: DataType.String },
//         { key: 'status', title: 'Status', dataType: DataType.String },
//         { key: 'customer', title: 'Customer', dataType: DataType.String },
//         { key: 'date', title: 'Date', dataType: DataType.String },
//         { key: 'payment', title: 'Payment Method', dataType: DataType.String },
//     ],
//     data: dataArray,
//     editingMode: EditingMode.Cell,
//     rowKeyField: 'id',
//     sortingMode: SortingMode.Single,
// };

const Home = (props) => {
    const dispatch = useDispatch();
    const [storeSetupInfo, setStoreSetupInfo] = useState(JSON.parse(JSON.stringify(storeSetup)));
    // const [tableProps, changeTableProps] = useState(tablePropsInit);
    // const [paymentStatus, setPaymentStatus] = useState(null)
    const navigateTo = useNav();
    const [isCompletedStore, setCompletedStore] = useState(null);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    const { selectedStore } = useSelector((state: any) => state.ui);

    useEffect(() => {
        loadStoreDetails();
        setCompletedStore(false);
        // setPaymentStatus(true);
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setStoreSetupInfo(JSON.parse(JSON.stringify(storeSetup)));
        loadStoreDetails();
        setCompletedStore(false);
        // setPaymentStatus(true);
        //eslint-disable-next-line
    }, [selectedStore])

    const loadStoreDetails = async () => {
        const resp: any = await getStoreDetails()(dispatch);
        if (resp?.setupChecklist) {
            for (let chk of storeSetupInfo) {
                let pendingStatus = [];
                // console.log(chk.status, chk.key)
                for (let step of chk.steps) {
                    // console.log(step, step.key, resp?.setupChecklist[step.key])
                    if (resp?.setupChecklist[step.key]) {
                        step.status = true;
                    } else {
                        pendingStatus.push(step.key)
                    }
                }
                chk.status = pendingStatus.length === 0 ? true : false;
            }
            setStoreSetupInfo(storeSetupInfo)
        }
    }

    // const dispatchFunc: DispatchFunc = (action) => {
    //     changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    // };

    useEffect(() => {
        dispatch(onSelectedMainMenu('home'))
        dispatch(onSelectedTabMenu(null))
        //eslint-disable-next-line
    }, []);

    const StoreStatusItem = ({ item }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 10 }}>
                {item.status === true ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                    <CheckSvg />
                </div> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                    <UnCheckSvg />
                </div>}
                <p style={{ paddingLeft: 4, color: item.status === true ? "#121212" : "#5E6774", cursor: 'pointer' }} onClick={() => navigateTo(item.link.replace('{sName}', selectedStore?.name))}>
                    {item.title}
                </p>
            </div>
        )
    }

    const loadTimelineItem = (data, key) => {
        return <div className="timeline-item" key={key}>
            <div className="timeline-item-content" style={{ background: 'transparent' }}>
                <div>
                    <p className="tag" style={{ background: "#fff", color: '#000', paddingBottom: 10, paddingTop: 6 }}>
                        {data.title}
                    </p>
                </div>
                <div style={{ paddingTop: 48, background: 'transparent' }}>
                    <div className="v-line" style={{ background: data.status === true ? '#242424' : '#24242430' }}></div>
                    {data.steps.map((item, i) => (
                        <StoreStatusItem item={item} key={`${i}_steps`} />
                    ))}
                    <span className="circle" style={{ background: data.status === true ? "#121212" : "#F1F3F6", zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {data.key === "storeConfiguration" && <div style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><StoreConfigSvg stroke={data.status === true ? "#fff" : "#000"} /></div>}
                        {data.key === "outlet" && <div style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><OutletSvg stroke={data.status === true ? "#fff" : "#000"} /></div>}
                        {data.key === "bankDetails" && <div style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><BankCardSvg stroke={data.status === true ? "#fff" : "#000"} /></div>}
                        {data.key === "channelWeb" && <div style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CWebSvg stroke={data.status === true ? "#fff" : "#000"} /></div>}
                        {data.key === "channelMob" && <div style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CMobSvg stroke={data.status === true ? "#fff" : "#000"} /></div>}
                    </span>
                </div>
            </div>
        </div>
    };

    const Timeline = () => (
        <div style={{ display: 'flex', flexDirection: 'row', background: 'transparent', }}>
            {/* <div style={{ display:'flex', flexDirection:'column', justifyContent:'space-evenly', background:'transparent'}}> */}
            {/* <div className="line">            
       {result.length > 0 && result.map((ico) => (<span className="circle" style={{background: "#121212", zIndex:999, display:'flex', justifyContent:'center', alignItems:'center'}}>
       <div style={{width:2, background:'yellow', }}></div>
             <div style={{color:'#fff', background:"black",display:'flex', justifyContent:'center', alignItems:'center'}}>{ico.name}</div>
          </span>))}
          </div> */}
            {storeSetupInfo.length > 0 && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="timeline-container">
                        {storeSetupInfo.map((data, idx) => (
                            loadTimelineItem(data, `${idx}_timeline`)
                        ))}
                    </div>
                </div>)}
        </div>
    );

    return <div className="home" style={{ height: dimensions.height - 80 }}>
        <div style={{ height: dimensions.height - 1, background: '#F8F8F8' }}>
            <div style={{ width: '100%', height: '100%', background: dimensions.height, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', background: 'transparent', width: '100%', }}>
                    <ComingSoon />
                    {/* <div style={{ display: 'flex', flexDirection: 'row', background: 'transparent', width: '100%', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', padding: 10, marginTop: 15, marginLeft: 15, height: 225, backgroundImage: paymentStatus === true ? "linear-gradient(227deg, #FFF, #FFF)" : "linear-gradient(227deg, #FFF, #FFF)", boxShadow: "0px 1px 8px #D8DEE68B", border: "1px solid #D3D3D39A", borderRadius: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', }}>
                                <div style={{ width: 160, padding: 10, color: paymentStatus === true ? '#02BA65' : "#fff", background: paymentStatus === true ? '#E6FFF6' : "#E6FFF6", borderRadius: 5 }}>
                                    Growth Plan 1750
                                </div>
                                <div style={{ width: 160, padding: 10, color: '#02BA6500', background: '#E6FFF600', borderRadius: 5 }}>

                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ color: paymentStatus === true ? '#121212' : '#FFF', background: '#E6FFF600', fontSize: 17, fontWeight: 800, borderRadius: 5 }}>
                                        ₹1,250
                                    </div>
                                    <div style={{ padding: 0, color: paymentStatus === true ? '#5E6774' : '#FFF', background: '#E6FFF600', fontSize: 13, fontWeight: 500, borderRadius: 5 }}>
                                        Next Payment
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ color: paymentStatus === true ? '#121212' : '#FFF', background: '#E6FFF600', fontSize: 17, fontWeight: 800, borderRadius: 5, textAlign: 'right' }}>
                                        ₹250
                                    </div>
                                    <div style={{ padding: 0, color: paymentStatus === true ? '#5E6774' : '#FFF', background: '#E6FFF600', fontSize: 13, fontWeight: 500, borderRadius: 5, textAlign: 'right' }}>
                                        Current Spent
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 1, background: paymentStatus === true ? '#D8DEE6C3' : '#FFF', marginTop: 10 }} />
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {paymentStatus === true ? <Calendar stroke="#5e6774" /> : <Calendar stroke="#FFF" />}
                                    <div style={{ paddingLeft: 5, color: paymentStatus === true ? '#5E6774' : '#FFF', background: '#E6FFF600', fontSize: 13, fontWeight: 500, borderRadius: 5, textAlign: 'right' }}>
                                        Bill Cycle: 01 May - 30 May
                                    </div>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ color: paymentStatus === true ? '#121212' : '#FFF', background: '#E6FFF600', fontSize: 17, fontWeight: 800, borderRadius: 5, textAlign: 'right' }}>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: paymentStatus === true ? 40 : 0, alignItems: 'flex-end' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                    {paymentStatus === true ? <div style={{ paddingLeft: 5, color: '#CB0000', background: '#E6FFF600', fontSize: 13, fontWeight: 700, borderRadius: 5, textAlign: 'right' }}>
                                        No Over Dues
                                    </div> :
                                        <div style={{ paddingLeft: 0, background: '#E6FFF600', borderRadius: 5, paddingTop: 0 }}>

                                            <div style={{ color: '#FFF', fontSize: 13, padding: 5, fontWeight: 700, textAlign: 'left' }}>
                                                Over Due
                                            </div>
                                            <div style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 25, paddingRight: 25, borderRadius: 30, background: '#fff', color: '#CB0000' }}>
                                                Pay Bill
                                            </div>
                                        </div>}

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ paddingLeft: 5, color: paymentStatus === true ? '#188FFC' : '#FFF', background: '#E6FFF600', fontSize: 13, fontWeight: 700, borderRadius: 5, textAlign: 'right' }}>
                                        Download Bill
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 15, marginLeft: 15, height: 225, background: '#FFFFFF00', }}>
                            <div style={{ padding: 10, width: 300, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', background: '#FFF', boxShadow: "0px 1px 8px #D8DEE68B", border: "1px solid #D3D3D39A", borderRadius: 10 }}>
                                <Analytics style={{ marginTop: 14 }} />
                                <div style={{ display: 'flex', flexDirection: 'column', padding: 5 }}>
                                    <div style={{ width: 160, padding: 10, color: '#5E6774', }}>
                                        Sales Revenue
                                    </div>
                                    <div style={{ width: 140, paddingLeft: 10, color: '#121212', background: '#E6FFF600', fontSize: 22, fontWeight: 800, borderRadius: 5 }}>
                                        ₹22,550
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: 10, width: 300, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', background: '#FFF', boxShadow: "0px 1px 8px #D8DEE68B", border: "1px solid #D3D3D39A", borderRadius: 10 }}>
                                <TotalCustomerSvg style={{ marginTop: 14 }} />
                                <div style={{ display: 'flex', flexDirection: 'column', padding: 5 }}>
                                    <div style={{ width: 160, padding: 10, color: '#5E6774', }}>
                                        Total Customers
                                    </div>
                                    <div style={{ width: 140, paddingLeft: 10, color: '#121212', background: '#E6FFF600', fontSize: 22, fontWeight: 800, borderRadius: 5 }}>
                                        12,550
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: 330, display: 'flex', flexDirection: 'column', padding: 10, marginTop: 15, marginLeft: 15, marginRight: 15, height: 225, background: '#FFF', boxShadow: "0px 1px 8px #D8DEE68B", border: "1px solid #D3D3D39A", borderRadius: 10 }}>
                        </div>
                        {isCompletedStore === true && <div style={{ width: 330, display: 'flex', flexDirection: 'column', padding: 10, marginTop: 15, marginLeft: 15, marginRight: 15, height: 225, background: '#FFF', boxShadow: "0px 1px 8px #D8DEE68B", border: "1px solid #D3D3D39A", borderRadius: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', padding: 10, justifyContent: 'flex-start' }}>
                                <div style={{ width: 50, height: 50, paddingTop: 15 }}>
                                    <NewFeatureSVG width="50" height="50" />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 700, padding: 15 }}>
                                    <div style={{ color: '#1C3A66', fontSize: 16, padding: 15 }}>
                                        New feature
                                    </div>
                                    <div style={{ color: '#1C3A66', fontSize: 13, fontWeight: 300, paddingTop: 5, paddingLeft: 15, textAlign: 'justify' }}>
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                                    </div>
                                </div>

                            </div>
                        </div>}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                        <div style={{ padding: 10, width: '100% - 396', height: 380, marginTop: 15, marginLeft: 15, marginRight: 15, background: '#FFF', boxShadow: "0px 1px 8px #D8DEE68B", border: "1px solid #D3D3D39A", borderRadius: 10 }}>
                            <h3 style={{ fontWeight: 800 }}>Recent Transcations</h3>
                            <div>
                                <Table
                                    {...tableProps}
                                    dispatch={dispatchFunc}

                                />
                            </div>
                        </div>
                        {isCompletedStore === true && <div style={{ width: 330, marginTop: 15, marginLeft: 15, marginRight: 15, background: '#FFF', boxShadow: "0px 1px 8px #D8DEE68B", border: "1px solid #D3D3D39A", borderRadius: 10 }}>
                            <div style={{ width: 330, padding: 15, display: 'flex', flexDirection: 'column', justifyContent: 'flex=start' }}>
                                <div style={{ color: '#121212', fontSize: 14 }}>Lorem ipsum.</div>
                                <div style={{ color: '#506584', fontSize: 12 }}>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
                                </div>
                            </div>
                            <div style={{ width: 396, padding: 15, display: 'flex', flexDirection: 'column', justifyContent: 'flex=start' }}>
                                <div style={{ color: '#121212', fontSize: 14 }}>Lorem ipsum.</div>
                                <div style={{ color: '#506584', fontSize: 12 }}>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
                                </div>
                            </div>
                            <div style={{ width: 396, padding: 15, display: 'flex', flexDirection: 'column', justifyContent: 'flex=start' }}>
                                <div style={{ color: '#121212', fontSize: 14 }}>Lorem ipsum.</div>
                                <div style={{ color: '#506584', fontSize: 12 }}>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
                                </div>
                            </div>
                            <div style={{ width: 396, padding: 15, display: 'flex', flexDirection: 'column', justifyContent: 'flex=start' }}>
                                <div style={{ color: '#121212', fontSize: 14 }}>Lorem ipsum.</div>
                                <div style={{ color: '#506584', fontSize: 12 }}>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
                                </div>
                            </div>
                            <div style={{ width: 396, padding: 15, display: 'flex', flexDirection: 'column', justifyContent: 'flex=start' }}>
                                <div style={{ color: '#121212', fontSize: 14 }}>Lorem ipsum.</div>
                                <div style={{ color: '#506584', fontSize: 12 }}>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
                                </div>
                                <div style={{ height: 25, }}></div>
                            </div>

                        </div>}
                    </div> */}
                </div>

                {isCompletedStore === false && <div style={{ height: '100vh', width: 396, background: '#FFF', boxShadow: "0px 1px 8px #D8DEE68B", border: "1px solid #D3D3D39A", flexGrow: 1, overflowY: 'scroll', }}>
                    <div style={{ paddingTop: 15, paddingLeft: 15, fontSize: 15, color: '#121212', fontWeight: 800 }}>
                        Complete your store setup
                    </div>

                    <Timeline />
                </div>}

            </div>
        </div>
        {isCompletedStore === true && <Confetti
            width={dimensions.width}
            height={dimensions.height}
            onConfettiComplete={confetti => {
                confetti.reset()
            }}
        />}
    </div>
}

export default Home;