import type from "../types/actionTypes";

const initialState = {
    outlets: []
};

export default function prodctReducers(state = initialState, action) {
    switch (action.type) {
        case type.GET_OUTLETS_SUCCESS:
            return {
                ...state,
                outlets: action.data.outlets
            }
        case type.GET_OUTLETS_ERROR:
            return {
                ...state,
                message: action.error
            }
        case type.SAVE_OUTLET_SUCCESS:
            return {
                ...state,
                createOutlet: action.data
            }
        case type.SAVE_OUTLET_ERROR:
            return {
                ...state,
                message: action.error
            }
        default:
            return state;
    }
}