import { useRef, useState } from 'react'

export const useDebouncedAction = (initialValue: any) => {
    const [state, setState] = useState<any>(initialValue)
    const timerRef: any = useRef(undefined)

    const performDebouncedAction = (value: any, action?: any) => {
        if (value) {
            setState(value)
        }
        if (action) {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
            timerRef.current = setTimeout(() => {
                action()
            }, 300)
        }
    }

    return [state, performDebouncedAction]
}