import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Drawer, Row, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { updateSiteStatus, getStoreDetails } from "../../../../../../actions/brandAction";
// import EditSvg from "../../../../../../assets/images/edit_blue.svg"
// import InputValidation from '../../../../common/InputValidation';

const { Option } = Select;
const windowHeight = window.innerHeight;
const ErrorVariables = {
    title: {
        message: "",
        type: ""
    },
    message: {
        message: "",
        type: ""
    },
}
const SiteConfiguration = (props: any) => {
    const dispatch = useDispatch();
    const [siteConfig, setSiteConfig] = useState({
        status: "live",
        msgTitle: "",
        msgSubtitle: ""
    })
    const [isEdit, setEdit] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [error, setError] = useState(ErrorVariables);
    const [status, setStatus] = useState("live");
    // const [title, setTitle] = useState(null);
    // const [message, setMessage] = useState(null);
    const onHandleCancel = () => {
        setError({ ...error, ...ErrorVariables })
        setVisible(false);
        setStatus("live");
        // setTitle(null);
        // setMessage(null);
    }
    useEffect(() => {
        loadSiteDetail();
        //eslint-disable-next-line
    }, []);
    const loadSiteDetail = async () => {
        const resp = await getStoreDetails()(dispatch);
        const { siteStatus }: any = resp;
        if (siteStatus) {
            setSiteConfig({ ...siteConfig, "status": siteStatus });
        }
    }
    useEffect(() => {
        if (siteConfig) {
            if (siteConfig.status) {
                setStatus(siteConfig.status)
            }
            // if (siteConfig.msgTitle) {
            //     setTitle(siteConfig.msgTitle)
            // } else {
            //     setTitle(null);
            // }
            // if (siteConfig.msgSubtitle) {
            //     setMessage(siteConfig.msgSubtitle)
            // } else {
            //     setMessage(null);
            // }
        }
    }, [siteConfig]);
    const onHandleSubmit = async () => {
        // let isError = false;
        let payload = {
            status: status
        }
        // let titleError = {
        //     message: "",
        //     type: ""
        // };
        // let messageError = {
        //     message: "",
        //     type: ""
        // };
        // if (status === "maintenance") {
        //     if (!title || title.length === 0) {
        //         titleError.message = "Enter the maintenance title";
        //         titleError.type = "error";
        //         isError = true;
        //     }
        //     if (!message || message.length === 0) {
        //         messageError.message = "Enter the maintenance message";
        //         messageError.type = "error";
        //         isError = true;
        //     }
        //     if (!isError) {
        //         payload['msgTitle'] = title;
        //         payload['msgSubtitle'] = message;
        //     }
        // }
        // setError({
        //     ...error, ...{ title: titleError, message: messageError }
        // });
        // if (!isError) {
        const resp: any = await updateSiteStatus(payload)(dispatch);
        if (resp && resp.isUpdated) {
            onHandleCancel();
            loadSiteDetail();
        }
        // }
    }
    return (
        <div className="container">
            <div className="header">
                Set your store site configuration
            </div>
            <div className="section">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                    <div className="title">
                        Confirm Site Status
                    </div>
                    {/* <div style={{ margin: "0px 14px" }}> | </div> */}
                    {/* <img src={EditSvg} style={{ cursor: 'pointer', height: 18 }} onClick={() => { setVisible(true); setEdit(true); }} /> */}
                    <div className='sp_btn save' onClick={() => { setVisible(true); setEdit(true); }}>Edit</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ flexGrow: 1 }} className="subtitle">Config the site status settings</div>
                </div>
                <div style={{ marginTop: 37 }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 26
                    }}>
                        <div className="toggle_title" style={{ width: 100 }}>
                            <label>Site Status</label>
                        </div>
                        <div style={{ color: siteConfig.status === "live" ? "#55C67E" : "#188FFC", textTransform: 'uppercase', fontWeight: 'bold' }}>{siteConfig.status}</div>
                    </div>
                    {/* {siteConfig.status === "maintenance" && <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 26
                    }}>
                        <div className="toggle_title" style={{ width: 100 }}>
                            <label>Title</label>
                        </div>
                        <div>{siteConfig.msgTitle}</div>
                    </div>}
                    {siteConfig.status === "maintenance" && <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 26
                    }}>
                        <div className="toggle_title" style={{ width: 100 }}>
                            <label>Message</label>
                        </div>
                        <div>{siteConfig.msgSubtitle}</div>
                    </div>} */}
                </div>
            </div>
            <Drawer
                width={470}
                closable={false}
                onClose={onHandleCancel}
                className="outlet-update"
                visible={isVisible}
                footer={
                    <div className="footer">
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div className="sp_btn" onClick={onHandleCancel} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 16, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                            {isEdit && <div className="sp_btn" onClick={onHandleSubmit} style={{ width: 155, fontSize: 14 }}>Save</div>}
                        </div>
                    </div>
                }
            >
                <Row>
                    <Col span={24} className="content" style={{ marginTop: 0 }}>
                        <div className="header">
                            <ArrowLeftOutlined className="arrow" onClick={onHandleCancel}></ArrowLeftOutlined>
                            <div className="title">Site Configuration</div>
                        </div>
                        <div style={{ height: windowHeight - 166, overflowY: "auto", marginTop: 30 }}>
                            <div className="group">
                                <div className="label">Site Status</div>
                                <Select onChange={(val) => setStatus(val)} value={status}>
                                    <Option value={"live"}>Live</Option>
                                    <Option value={"maintenance"}>Maintenance</Option>
                                </Select>
                            </div>
                            {/* {status === "maintenance" && <div className="group">
                                <div className="label">Title</div>
                                <InputValidation type={error.title.type} message={error.title.message}>
                                    <Input disabled={!isEdit} type="text" name="name" value={title} defaultValue={title} placeholder="Enter the title"
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </InputValidation>
                            </div>}
                            {status === "maintenance" && <div className="group">
                                <div className="label">Message</div>
                                <InputValidation type={error.message.type} message={error.message.message}>
                                    <Input disabled={!isEdit} type="text" name="name" value={message} defaultValue={message} placeholder="Enter the message"
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                </InputValidation>
                            </div>} */}
                        </div>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
}

export default SiteConfiguration;