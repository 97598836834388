import * as React from "react";

const BankCardSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={14}
        viewBox="0 0 17 14"
        {...props}
    >
        <g
            id="vuesax_linear_card"
            data-name="vuesax/linear/card"
            transform="translate(-493.5 -511.005)"
        >
            <g id="card" transform="translate(494 511.505)">
                <path
                    id="Vector"
                    d="M0,0H16"
                    transform="translate(0 3.826)"
                    fill="none"
                    stroke={props.stroke || "#292d32"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M0,0H1.6"
                    transform="translate(3.2 9.947)"
                    fill="none"
                    stroke={props.stroke || "#292d32"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M0,0H3.2"
                    transform="translate(6.8 9.947)"
                    fill="none"
                    stroke={props.stroke || "#292d32"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-4"
                    data-name="Vector"
                    d="M3.552,0H12.44C15.288,0,16,.673,16,3.359V9.641C16,12.327,15.288,13,12.448,13h-8.9C.712,13.008,0,12.334,0,9.649V3.359C0,.673.712,0,3.552,0Z"
                    fill="none"
                    stroke={props.stroke || "#292d32"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
            </g>
        </g>
    </svg>
);

export default BankCardSVG;
