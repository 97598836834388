import React from 'react'
import { Outlet } from 'react-router-dom';
import "../../../../../assets/sass/dsk/blog.sass";

const Pages = () => {
    return <div className="blogs">
        {<Outlet />}
    </div>
}

export default Pages;