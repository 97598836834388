import { combineReducers } from "redux";
import ui from "./uiReducers";
import common from "./commonReducers";
import auth from "./authReducers";
import order from "./orderReducers";
import category from "./categoryReducers";
import product from "./productReducers";
import employee from "./employeeReducers";
import customer from "./customerReducers";
import deliveryExecs from "./deliveryExecsReducers";
import outlet from "./outletReducers";
import setupStore from "./setupStoreReducers";
import { createMigrate, persistReducer } from "redux-persist";
import sessionStorage from 'redux-persist/lib/storage/session';
import { initialState as uiInitialState } from "../reducers/uiReducers";

const migrations = {
    3: (state) => {
        return {
            ...state,
            ui: {
                ...uiInitialState,
                ...state.ui,
            },
        };
    },
};

const sessionConfig = {
    key: 'secondary',
    version: 3,
    migrate: createMigrate(migrations),
    storage: sessionStorage
};

export default combineReducers({
    ui: persistReducer(sessionConfig, ui),
    common,
    auth,
    order,
    outlet,
    product,
    category,
    employee,
    customer,
    setupStore,
    deliveryExecs,
});
