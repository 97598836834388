import React, { useEffect, useState } from "react";
import Add from "./add";
import { Row, Col } from 'antd';
import "../../../../../assets/sass/dsk/holidays.sass";
import { getStoreDetails, onUpdateSiteSettings } from "../../../../../actions/brandAction";
import DeleteSvg from "../../../../../assets/images/settings/trash.svg";
import { useDispatch } from "react-redux";
import Loading from '../../../common/Loading';
import dayjs from 'dayjs';

interface Holiday {
    name: string
    date: string
    dateString: string
    dateKey: string
}

const Holidays = () => {
    const windowHeight = window.innerHeight;
    const dispatch = useDispatch();
    const [openAddDrawer, setOpenAddDrawer] = useState(false);
    const [holidays, setHolidays] = useState({});
    const [holidayKeys, setHolidayKeys] = useState([])
    const [updateHolidays, setUpdateHolidays] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const loadStoreDetails = async () => {
        setUpdateHolidays(false);
        const resp: any = await getStoreDetails()(dispatch);
        if (resp?.settings?.holidays) {
            let h = {}
            let keys = []
            for (let k in resp.settings.holidays) {
                const dateString = dayjs(dayjs().format('YYYY') + "-" + k, 'YYYY-DD-MM', true);
                if (dateString.isValid()) {
                    let key = dateString.format('MM-DD')
                    h[key] = { dateString: dateString.format('DD, MMMM'), name: resp.settings.holidays[k], dateKey: dateString.format('YYYY-MM-DD') }
                    keys.push(key)
                }
            }
            setHolidays(h);
            setHolidayKeys(keys.sort())
        }
        setIsLoading(false)
    }
    useEffect(() => {
        setIsLoading(true)
        loadStoreDetails();
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (updateHolidays)
            saveHolidays()
        //eslint-disable-next-line
    }, [updateHolidays])

    const saveHolidays = async () => {
        setIsLoading(true)
        let holidaySettings = {}
        for (let h in holidays) {
            let val = holidays[h] as Holiday
            holidaySettings[val.dateKey] = val.name
        }
        const updateResponse: any = await onUpdateSiteSettings({ holidays: holidaySettings })(dispatch);
        if (updateResponse)
            loadStoreDetails();
    }

    const onAddHolidayClose = (holiday: Holiday) => {
        setOpenAddDrawer(false);
        if (!holiday)
            return
        let h = { ...holidays }
        h[holiday.date] = { dateString: holiday.dateString, name: holiday.name, dateKey: holiday.dateKey }
        setHolidays(h)
        setHolidayKeys(Object.keys(h)?.sort())
        setUpdateHolidays(true)
    }

    const onRemoveHoliday = (date: string) => {
        let h = { ...holidays }
        delete h[date]
        setHolidays(h)
        setHolidayKeys(Object.keys(h)?.sort())
        setUpdateHolidays(true)
    }


    return <div className="holidays" style={{ flexDirection: 'row' }}>
        <Row style={{ flexGrow: 1, margin: '0px 24px' }}>
            <Col className="list">
                <div className="action-bar">
                    <div className="title">
                        Manage your store's holiday dates
                    </div>
                    <div className="actions" style={{ flexGrow: 1, justifyContent: "flex-end" }}>
                        <button className="sp_btn" style={{ width: "auto" }} onClick={() => { setOpenAddDrawer(true) }} >+ Add Holiday</button>
                    </div>
                </div>
                <div className="content">
                    <Row className="header">
                        <Col span={20}>
                            <Row>
                                <Col span={10}>
                                    Date
                                </Col>
                                <Col span={10}>
                                    Holiday Name
                                </Col>
                                <Col span={2} />
                            </Row>
                        </Col>
                    </Row>
                    <Row className="contains" style={{ height: windowHeight - 234 }}>
                        <Col span={20}>
                            {holidayKeys?.map(key => {
                                return <Row className="item" key={key}>
                                    <Col span={10}>
                                        {holidays[key]?.dateString}
                                    </Col>
                                    <Col span={10}>
                                        {holidays[key]?.name}
                                    </Col>
                                    <Col span={2} >
                                        <div><img alt='Delete Icon' onClick={(e) => { e.stopPropagation(); onRemoveHoliday(key) }} style={{ cursor: 'pointer' }} src={DeleteSvg} /></div>
                                    </Col>
                                </Row>
                            }
                            )}
                        </Col>
                    </Row>
                </div>
            </Col>
            <Add isVisible={openAddDrawer} onClose={onAddHolidayClose}></Add>
        </Row >
        <div
            className="container"
            style={{
                padding: '12px 18px',
                height: '100%',
                width: '30%',
                background: '#FFFFFF',
                boxShadow: '0px 3px 16px #DBE2EA6C',
                flexShrink: 0
            }}>
            <div className="header" style={{ marginBottom: 12 }}>Holidays</div>
            <div className="value" style={{ marginBottom: 12, textAlign: 'justify' }}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                <br />
                <br />
                <br />
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                <br />
                <br />
                <br />
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                <br />
                <br />
                <br />
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                <br />
                <br />
                <br />
            </div>
        </div>
        {isLoading && <Loading background="#00000070"></Loading>}
    </div>
}

export default Holidays;