import React from "react";
import { useSelector } from "react-redux";
import Styles from "styled-components";
import "../../../assets/sass/dsk/setupstore.sass";
import SLZIcon from "../../../assets/images/ShopLetzy_logo_wh_s3.svg";
import Footer from "./Footer";
import { useNav } from "../../../helpers/customHook";

const Container = Styles.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    img {
        width: 65px;
        height: 65px;
        margin-top: 62px;
        margin-bottom: 29px;
    }
    .content {
        flex-grow: 0;
        margin-top: 0px;
        width: 819px;
        height: 429px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 13px 36px #00000029;
        border: 1px solid #BAC3D0;
        border-radius: 11px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .header{
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #1A1A1A;
        padding: 0px;
        margin-bottom: 18px;
        height: auto;
    }
    .info {
        text-align: center;
        font-size: 14px;
        letter-spacing: 0px;
        color: #1A1A1A;
        margin: 0px 30px;
        display: flex;
        flex-direction: column;
        div {
            justify-content: center;
        }     
    }
    .url {
        font-weight: bold;
        color: #188FFC;
        font-size: 14px;
        margin: 0px 4px;
        cursor: pointer;
    }
    .privacyPolicy {
        margin-left: 4px;
        font-size:  14px;
        font-weight: bold;
        color: #1A1A1A;
        text-decoration: underline;
        cursor: pointer;
    }
    .subscribeNow {
        cursor: pointer;
        width: 163px;
        height: 40px;
        background: #1A1A1A;
        border: 1px solid #1A1A1A;
        border-radius: 20px;
        color:#FFF;
        font-size: 13px;
        font-weight: bold;
        display: flex;     
        justify-content: center;  
        align-items: center;
        margin-top: 36px; 
    }
`

const StoreSuspended = () => {
    const windowHeight = window.innerHeight;
    const { selectedStore } = useSelector((state: any) => state.ui);
    console.log(selectedStore, 'selectedStore')
    const navigateTo = useNav();
    const generateWebSiteUrl = (name) => {
        return window.location.origin.replace('shopletzy', 'myshopletzy').replace('app', name) + '/landing';
    }
    return <div className="setup-store" style={{ height: windowHeight + "px", flexDirection: 'column' }}>
        {/* <Header enableCreateStore={true} /> */}
        <Container>
            <div className="content">
                <img alt='slz' src={SLZIcon} />
                <div className="header">{
                    selectedStore.subscriptionStatus === "cancelled" ? "Your subscription has been cancelled" :
                        "You can't access this store because your suspended"}
                </div>
                <div className="info">
                    <div style={{ display: 'flex', marginBottom: 6 }}>
                        <div style={{ whiteSpace: 'nowrap' }}>Your Shopletzy subscription for</div>
                        {selectedStore?.name && <div className="url" style={{ whiteSpace: 'nowrap' }}>
                            <a rel="noopener noreferrer" target={"_blank"} href={`${generateWebSiteUrl(selectedStore.name)}?previewMode=true`}>{selectedStore.name}</a>
                        </div>}
                        <div style={{ whiteSpace: 'nowrap' }}>has been {selectedStore.subscriptionStatus}. To continue using Shopletzy, you need to subscribe.</div>
                    </div>
                    <div style={{ marginBottom: 6 }}>Your store information will be stored for 60 days. To learn more about what information is available, see the</div>
                    <div style={{ display: 'flex', marginRight: 4 }}>
                        <div>Shopletzy</div>
                        <div className="privacyPolicy">Privacy Policy</div>.
                    </div>
                </div>
                <div className="subscribeNow" onClick={() => navigateTo(`/${selectedStore.name.toLowerCase()}/billing/plans`)}>
                    Subscribe Now
                </div>
            </div>
        </Container>
        <Footer></Footer>
    </div>
}

export default StoreSuspended;