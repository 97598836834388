import React, { useEffect, useState } from "react";
import LexiBackSvg from "../../../assets/images/lexi-icon.svg";
import LexiWhiteSvg from "../../../assets/images/lexi-icon-white.svg";
// import LexiSvg from "../../../assets/svgComponents/LexiSvg";
import CloseSvg from "../../../assets/images/close_black.svg";
import "../../../assets/sass/dsk/lexi.sass";
import { useDispatch } from "react-redux";
import { onLexiGenerateText } from "../../../actions/commonAction";
import MdEditor from 'react-markdown-editor-lite';
import insert from 'markdown-it-ins';
import MarkdownIt from 'markdown-it';
import { AnimatePresence, motion } from "framer-motion";

interface IProps {
    uesFor: string;
    callback: (value: string) => void;
    title?: string;
}
const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    highlight(str, lang) { },
}).use(insert);
const EditorPlugins = [
    'header',
    'font-bold',
    'font-italic',
    'font-underline',
    'font-strikethrough',
    'list-unordered',
    'list-ordered',
    'block-quote',
    'block-wrap',
    'block-code-inline',
    'block-code-block',
    'table',
    'image',
    'link',
    'tab-insert'
];
const backdropVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
};

const Lexi = (props: IProps) => {
    const dispatch = useDispatch();
    const { uesFor, callback, title } = props;
    const [isVisible, setVisible] = useState(false);
    const [content, setContent] = useState(null);
    const [earlierContent, setEarlierContent] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [keywords, setKeywords] = useState('');
    const [instructions, setInstructions] = useState('');
    const onUseContent = () => {
        callback(content);
        onClose();
    }
    const onClose = () => {
        setVisible(false);
        setContent(null);
        setKeywords('');
        setEarlierContent([]);
        setInstructions('');
    }
    useEffect(() => {
        if (content) {
            setEarlierContent((prev) => { prev.push(content); return prev; });
        }
    }, [content]);
    const generateText = async () => {
        if (keywords && uesFor) {
            setLoading(true);
            const payload = {
                keywords
            };
            if (instructions) {
                payload['instructions'] = instructions;
            }
            if (earlierContent.length > 0) {
                payload['earlierContent'] = earlierContent;
            }
            const resp = await onLexiGenerateText(payload, uesFor)(dispatch);
            if (resp && resp.completion) {
                setContent(resp.completion);
            }
            setLoading(false);
        }
    }
    return <div className="lexi">
        <div className="open" onClick={() => setVisible(true)}>
            <img src={LexiBackSvg} alt="lexi" />
            <div>Write with Lexi</div>
        </div>
        <AnimatePresence>
            {isVisible && <motion.div
                className="backdrop"
                variants={backdropVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                onClick={() => setVisible(false)}
            >
                <motion.div
                    className="popup"
                    initial={{
                        opacity: 0,
                        scale: 0.75,
                    }}
                    animate={{
                        opacity: 1,
                        scale: 1,
                        transition: {
                            ease: "easeOut",
                            duration: 0.15,
                        },
                    }}
                    exit={{
                        opacity: 0,
                        scale: 0.75,
                        transition: {
                            ease: "easeIn",
                            duration: 0.15,
                        },
                    }}
                    onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside it
                >
                    <div className="container">
                        <div className="box">
                            <div className="header">
                                {title && <div className="title">{title}</div>}
                                <img src={CloseSvg} alt="close" className="close" onClick={onClose} />
                            </div>
                            {content && <div className="content">
                                <MdEditor
                                    readOnly={true}
                                    plugins={EditorPlugins}
                                    config={{
                                        view: {
                                            menu: false,
                                            md: false,
                                            html: true,
                                            fullScreen: false,
                                            hideMenu: false,
                                        }
                                    }}
                                    value={content}
                                    style={{ border: "none" }}
                                    renderHTML={(text) => mdParser.render(text)}
                                />
                            </div>}
                            <div className="user-input">
                                <div>
                                    <label>Features and Keywords</label>
                                    <input type="text" value={keywords} placeholder="Enter the features and keywords" onChange={(e) => setKeywords(e.target.value)} />
                                </div>
                                <div style={{ marginTop: 24 }}>
                                    <label>Special instructions</label>
                                    <input type="text" placeholder="Enter the instructions" onChange={(e) => setInstructions(e.target.value)} />
                                </div>
                            </div>
                            <div className="action">
                                {content && <button className="sp_btn use-it" onClick={onUseContent}>Use the Content</button>}
                                <button className="sp_btn generate metal-button" onClick={generateText} >
                                    {/* <div className={isLoading ? 'text-generating' : ''}>
                                        {false ? <LexiSvg style={{ marginTop: 10 }} gradientColors={gradientColors} /> : <img src={LexiWhiteSvg} alt="lexi" />}
                                    </div> */}
                                    <div className={isLoading ? 'text-generating' : ''}><img src={LexiWhiteSvg} alt="lexi" /></div>
                                    {!isLoading && (content ? 'Regenerate' : 'Generate Text')}
                                    {isLoading && <div className="text-shine">Generating...</div>}
                                </button>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </motion.div>}
        </AnimatePresence>
    </div>;
}
export default Lexi;