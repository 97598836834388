import type from "../types/actionTypes"
const initialState = {
};

export default function deliveryExecsReducers(state = initialState, action) {
    // console.log(action)
    switch (action.type) {
        case type.GET_DELIVERY_EXECS_SUCCESS:
            return {
                ...state,
                deliveryExecs: action.data.deliveryExecutives
            }
        case type.GET_DELIVERY_EXECS_ERROR:
            return {
                ...state,
                message: action.error
            }
        default:
            return state;
    }
}