import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Drawer, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PrintSvg from '../../../assets/images/settings/print.svg';
import DownloadSvg from '../../../assets/images/settings/file_download.svg';
import { getProcurementDetail, downloadProcurementDetail } from "../../../actions/commonAction";
import ReactToPrint from "react-to-print";
import { useDispatch } from "react-redux";

const Table = styled.div`
    margin: 14px;
    background: #FFFFFF;
    border: 1px solid #D8DEE6;
    border-radius: 4px;
`;

const PrintPreview = styled.a`
    cursor: pointer;
`;

const Header = styled.div`
    height: 40px;
    background: #D8DEE63C;
    border-bottom: 1px solid #D8DEE6;
    display: flex;
    padding: 0px 10px;
    .title {
        font-weight: bold;
        font-size:  12px;
        color: #1C3A66;
    }
`;

const Contain = styled.div`
    .item {
        padding: 6px 10px 0px 10px;
        width: 100%;
        margin-bottom: 10px;
        display: flex; 
        .value {            
            font-size: 14px;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

// const Footer = styled.div`
//     height: 40px;
//     background: #D8DEE63C 0% 0% no-repeat padding-box;
//     border: 1px solid #D8DEE6;
//     border-radius: 4px;
//     margin: 8px;
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//     .total {
//         font-size: 13px;
//         font-weight: bold;
//         color: #1C3A66;
//         margin-right: 27px;
//     }
//     .val {
//         margin-right: 23px;
//         font-size: 15px;
//         font-weight: bold;
//         color: #1C3A66;
//     }
// `;


const Procurement = (props: any) => {
    const dispatch = useDispatch();
    const printRef: any = useRef(null);
    let total = 0;
    const { isVisible, onClose, ouId, date, slotId } = props;
    const [items, setItems] = useState(null);
    useEffect(() => {
        if (isVisible && ouId && date && slotId) {
            loadProcurementDetail(ouId, date, slotId);
        }
        //eslint-disable-next-line
    }, [isVisible, ouId, date, slotId]);

    const loadProcurementDetail = async (ouId, date, slotId) => {
        let queryParams = {
            reportType: 'procurement',
        };
        if (ouId !== 'all') {
            queryParams['ouId'] = ouId;
        }
        if (slotId !== 'all') {
            queryParams['deliverySlotId'] = slotId;
        }
        if (date !== 'all') {
            queryParams['date'] = date;
        }
        const resp: any = await getProcurementDetail(queryParams)(dispatch);
        if (resp && resp.items) {
            setItems(resp.items);
        }
    }

    const downloadProcurement = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const blob = await downloadProcurementDetail()(dispatch);
        if (blob) {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${new Date().toLocaleDateString()}_procurement_details.xlsx`;
            document.body.append(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
    }


    return <Drawer
        maskClosable={false}
        width={'50%'}
        closable={false}
        onClose={() => { onClose(); }}
        className="drawer-view"
        visible={isVisible}
    >
        <div className="header" style={{ justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
                <ArrowLeftOutlined className="arrow" onClick={() => { onClose(); }}></ArrowLeftOutlined>
                <div className="title">Procurement</div>
            </div>
            <div style={{ display: 'flex' }}>
                <ReactToPrint
                    trigger={() => <PrintPreview href="#"><img alt="Print" src={PrintSvg} style={{ marginRight: 12 }} /></PrintPreview>}
                    content={() => printRef.current}
                />
                <img alt="Download" style={{ cursor: "pointer" }} src={DownloadSvg} onClick={downloadProcurement} />
            </div>
        </div>
        <Row style={{ height: window.innerHeight - 146, overflow: 'auto' }}>
            <Col span={24}>
                <Row ref={printRef}>
                    <Col span={24}>
                        <Table>
                            <Header>
                                <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Col span={2}>
                                        <div className="title">S.no</div>
                                    </Col>
                                    <Col span={20}>
                                        <div className="title">Product Name</div>
                                    </Col>
                                    <Col span={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div className="title">Quantity</div>
                                    </Col>
                                </Row>
                            </Header>
                            <Contain>
                                {items && items.map((i, count) => {
                                    total = i.quantity + total
                                    return <Row className="item">
                                        <Col span={2}>
                                            <div className="value">{count + 1}</div>
                                        </Col>
                                        <Col span={20}>
                                            <div className="value" >{i.title}</div>
                                            <div className="value" style={{ color: '#00000085', lineHeight: 1, fontSize: 12, marginBottom: 2 }}>{i.subtitle}</div>
                                            <div className="value" style={{ color: '#00000085', display: 'flex', fontSize: 12 }}>SKU: <div style={{ color: '#00000085', marginLeft: 6 }}>{i.sku}</div></div>
                                        </Col>
                                        <Col span={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div className="value" >{i.quantity}</div>
                                        </Col>
                                    </Row>
                                })}
                            </Contain>
                            {/* <Footer>
                        <div className="total">Total</div>
                        <div className="val">{total}</div>
                    </Footer> */}
                        </Table>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Drawer >
}

export default Procurement;