import type from "../types/actionTypes";
import axiosWrapper from "./axiosWrapper";
import config from "../apiConfig";
import qs from 'qs';
import { store } from '../store';
import { encodeQueryData } from "../helpers/util";

export const getIncompleteOrders = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let queryString = encodeQueryData(queryParams);
            let url = `${config.baseUrl}${config.incompleteOrdersUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.GET_INCOMPLETE_ORDERS_SUCCESS,
                data: resp.data
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Incomplete Orders Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const downloadAllOrderInvoices = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        responseType: "blob"
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let queryString = encodeQueryData(queryParams);
            let url = `${config.baseUrl}${config.allOrdersUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Download Orders Invoices Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const downloadCurrentOrderInvoices = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        responseType: "blob"
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let queryString = encodeQueryData(queryParams);
            let url = `${config.baseUrl}${config.incompleteOrdersUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Download Orders Invoices Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const downloadIncompleteOrder = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        responseType: "blob"
    };
    queryParams = queryParams || {};
    queryParams['excel'] = true
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let queryString = encodeQueryData(queryParams);
            let url = `${config.baseUrl}${config.incompleteOrdersUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Download Incomplete Orders Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const downloadOrderInvoice = (orderId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        responseType: "blob"
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getOrderInvoiceUrl.replace('{sId}', sId).replace('{orderId}', orderId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Download Order Invoice Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getIncompleteOrderDates = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let queryString = encodeQueryData(queryParams);
            let url = `${config.baseUrl}${config.getIncompleteOrderDatesUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.GET_INCOMPLETE_ORDERS_SUCCESS,
                data: resp.data
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get IncompleteOrder Dates Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getAllOrders = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let queryString = encodeQueryData(queryParams);
            let url = `${config.baseUrl}${config.allOrdersUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.GET_ALL_ORDERS_SUCCESS,
                data: resp.data
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get All Orders Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getPendingOrders = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let queryString = encodeQueryData(queryParams);
            let url = `${config.baseUrl}${config.pendingOrdersUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Pending Orders Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getPaymentFailure = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let queryString = encodeQueryData(queryParams);
            let url = `${config.baseUrl}${config.paymentFailureOrdersUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.GET_PAYMENT_FAILURE_ORDERS_SUCCESS,
                data: resp.data
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Payment Failure Orders Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const downloadPendingOrders = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        responseType: "blob"
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let queryString = encodeQueryData(queryParams);
            let url = `${config.baseUrl}${config.pendingOrdersUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Download Pending Orders Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const downloadPaymentFailure = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        responseType: "blob"
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let queryString = encodeQueryData(queryParams);
            let url = `${config.baseUrl}${config.paymentFailureOrdersUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Download Payment Failure Orders Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const downloadAllOrder = (queryParams) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        responseType: "blob"
    };
    queryParams = queryParams || {};
    queryParams['excel'] = true
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let queryString = encodeQueryData(queryParams);
            let url = `${config.baseUrl}${config.allOrdersUrl.replace('{sId}', sId)}${queryString}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Download Incomplete Orders Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getOrders = (startDate, endDate, includeAll) => {

    let response;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        let url = `${config.baseUrl}${config.ordersUrl.replace('{sId}', sId)}?${qs.stringify({
            'startDate': startDate,
            'endDate': endDate
        })}`
        //,'includeAll': includeAll
        // let url = `${config.baseUrl}${config.ordersUrl}`
        // let url = `${config.baseUrl}${config.orderDetailUrl.replace("{orderID}", "5f47d55a4199afdee74001d5")}`
        axiosWrapper(url, options)
            .then(function (resp) {
                response = resp;
                dispatch({
                    type: type.GET_ORDERS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    dispatch({
                        type: type.ERROR,
                        data: {
                            title: "Get Orders Failed",
                            message: error.response.data.message,
                            code: error.response.data.code
                        }
                    });
                } else {
                    console.error('error', 'Authentication', error);
                }
            });
    }
};

export const getOrderById = (orderId) => {
    const { token } = store.getState().auth.info;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.orderDetailUrl.replace('{sId}', sId).replace('{orderId}', orderId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Delivery Executives Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const getDeliveryExecs = (ouId) => {
    const { token } = store.getState().auth.info;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getDeliveryexecutivesUrl.replace('{sId}', sId).replace('{ouId}', ouId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.GET_DELIVERY_EXECS_SUCCESS,
                data: resp.data
            });
            return resp && resp.data ? resp.data.deliveryExecs : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Delivery Executives Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const updateOrderStatus = (orderId, status, queryParams = null) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.orderStatusUrl.replace('{orderId}', orderId).replace('{sId}', sId).replace('{status}', status)}`;
            if (queryParams) {
                let queryString = encodeQueryData(queryParams);
                url = `${url}${queryString}`;
            }
            let resp = await axiosWrapper(url, options, { dispatch });
            // dispatch({
            //     type: type.INCOMPLETE_ORDER_SUCCESS,
            //     data: resp.data
            // });
            // dispatch({
            //     type: type.COMPLETED_ORDER_SUCCESS,
            //     data: resp.data
            // });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Order Status Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    }
}

export const assignDelivery = (orderId, empId) => {
    const options = {
        method: 'PUT',
        data: {
            empId
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.assignDeliveryUrl.replace('{orderId}', orderId).replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data.employees : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Assign Deliver Executive Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
            return null;
        }
    };
}


export const shipOrder = (orderId, data) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.orderShipUrl.replace('{orderId}', orderId).replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.INCOMPLETE_ORDER_SUCCESS,
                data: resp.data
            });
            dispatch({
                type: type.COMPLETED_ORDER_SUCCESS,
                data: resp.data
            });
            return resp ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Assign Deliver Executive Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
            return null;
        }
    };
}


export const updateAllOrderStatus = (orderIds, newStatus) => {
    let response;
    const options = {
        method: 'PUT',
        data: JSON.stringify({
            status: newStatus,
            orders: orderIds
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        let url = `${config.baseUrl}${config.updateAllOrdersStatusUrl.replace('{sId}', sId)}`
        axiosWrapper(url, options)
            .then(function (resp) {
                response = resp;
                dispatch({
                    type: type.CHANGE_MULTI_ORDER_STATUS_SUCCESS,
                    data: Object.assign(response.data, {
                        orderIds: orderIds,
                        newStatus: newStatus
                    })
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    dispatch({
                        type: type.ERROR,
                        data: {
                            title: "Update All Order Status Failed",
                            message: error.response.data.message,
                            code: error.response.data.code
                        }
                    });
                } else {
                    console.error('error', 'Authentication', error);
                }
            });
    }
}