import * as React from "react"

function CompleteOrder(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="50" viewBox="0 0 53 50">
            <g id="completeOrders" transform="translate(-0.871 -0.7)">
                <g id="Group_10726" data-name="Group 10726" transform="translate(0.871 0.701)">
                    <rect id="Rectangle_17260" data-name="Rectangle 17260" width="53" height="50" rx="5" transform="translate(0 0)" fill="rgba(1,99,222,0.15)" />
                    <g id="vuesax_linear_clipboard-tick" data-name="vuesax/linear/clipboard-tick" transform="translate(13.629 12.115)">
                        <g id="clipboard-tick">
                            <path id="Vector" d="M0,3.023,1.814,4.837,6.651,0" transform="translate(10.37 13.676)" fill="none" stroke="#0f63de" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                            <path id="Vector-2" data-name="Vector" d="M2.419,4.837H7.256c2.419,0,2.419-1.209,2.419-2.419C9.674,0,8.465,0,7.256,0H2.419C1.209,0,0,0,0,2.419S1.209,4.837,2.419,4.837Z" transform="translate(8.791 2.419)" fill="none" stroke="#0f63de" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                            <path id="Vector-3" data-name="Vector" d="M14.445,0C18.146.218,20,1.705,20,7.232v7.256c0,4.837-1.111,7.256-6.667,7.256H6.667C1.111,21.743,0,19.324,0,14.487V7.232C0,1.717,1.856.218,5.556,0" transform="translate(3.628 3.096)" fill="none" stroke="#0f63de" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                            <path id="Vector-4" data-name="Vector" d="M0,0H27.257V27.257H0Z" fill="none" opacity="0" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CompleteOrder
