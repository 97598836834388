import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { handleLeftDrawerVisibility } from "../../../actions/commonAction";
import { getOutlets } from '../../../actions/outletAction';
import NavBar from "./NavBar";

const Main = (props: any) => {
    const location = useLocation();
    const [, store, currentMainMenu, currentSubMenu] = location.pathname.split("/");
    // const [collapsed, setCollapsed] = React.useState(false);
    // const [outlets, setOutlets] = React.useState([]);
    // const { outlets: outletList } = useSelector((state: any) => state.outlet);
    const { selectedStore } = useSelector((state: any) => state.ui);
    // const { info } = useSelector((state: any) => state.auth);
    // const [dimensions, setDimensions] = React.useState({
    //     height: window.innerHeight,
    //     width: window.innerWidth
    // });
    // useEffect(() => {
    //     function handleResize() {
    //         setDimensions({
    //             height: window.innerHeight,
    //             width: window.innerWidth
    //         });
    //     }
    //     window.addEventListener('resize', handleResize);
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     }
    // }, []);
    // useEffect(() => {
    //     setCollapsed(leftMenuToggle);
    // }, [leftMenuToggle]);

    // useEffect(() => {
    //     setOutlets(outletList)
    // }, [outletList]);

    useEffect(() => {
        if (selectedStore && selectedStore.id) {
            props.getOutlets();
        }
        //eslint-disable-next-line
    }, [selectedStore])
    return (
        <div style={{ height: '100%', display: "flex", flexDirection: 'column' }}>
            {/* height: dimensions.height - 60, */}
            <div style={{ background: "#FFF", display: "flex", flexDirection: "column", flexGrow: 1, overflow: "auto" }}>
                <Outlet context={[store, currentMainMenu, currentSubMenu]} />
            </div>
            <NavBar></NavBar>
        </div>
    );
}
const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        ui: state.ui,
        common: state.common,
        outlet: state.outlet
    };
};
export default connect(
    mapStateToProps,
    {
        handleLeftDrawerVisibility,
        getOutlets
    }
)(Main);
