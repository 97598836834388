import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getIndustryTypes, sendOTP, verifyOTP, createMerchant } from "../../../actions/setupStoreActions";
import { setSetupStoreDetails } from "../../../actions/uiAction";
// import Locale from "../common/Locale";
import '../../../assets/sass/dsk/setupstore.sass';
import { withTranslation } from 'react-i18next';
import { useNav } from '../../../helpers/customHook';
// import Header from './Header';
// import Footer from './Footer';

const SendOTP = (props: any) => {
    const navigateTo = useNav();
    // const windowHeight = window.innerHeight;
    const [mobileNumber, setMobileNumber] = useState("");
    const onChangeMobileNo = (value) => {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            setMobileNumber(value)
        }
    }
    const onSendOTP = async () => {
        if (mobileNumber.length === 10) {
            const code = "91";
            const resp = await props.sendOTP(code, mobileNumber);
            if (resp) {
                navigateTo(`/setupstore/verifyOTP/${mobileNumber}`);
            }
        }
    }
    // const { t } = props
    return <div>
        <div className="title">
            Lets get your mobile number verified
        </div>
        <div className="subtitle">
            We will send you an <div className="highlight">One Time Password</div> on this mobile number
        </div>
        <div className="otp">
            <div className="label">Mobile Number</div>
            <div className="number">
                <div className="country_code">+91</div>
                <div className="divider"></div>
                <input type="text" placeholder="Enter Mobile Number" value={mobileNumber} pattern="[0-9]*" maxLength={10} onChange={(e) => onChangeMobileNo(e.target.value)} />
            </div>
            <button style={{ cursor: mobileNumber.length === 10 ? 'pointer' : 'default' }} onClick={onSendOTP}>Send OTP</button>
        </div>
    </div>
}

function mapStateToProps(state: any) {
    // console.log("SignIn mapStateToProps: ", state);
    return {
        setupStore: state.setupStore
    }
}

export default connect(mapStateToProps, { getIndustryTypes, sendOTP, verifyOTP, setSetupStoreDetails, createMerchant })(withTranslation()(SendOTP));
