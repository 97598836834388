import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";

const PieVz = (props: any) => {
    const [colors, setColors] = useState([]);
    useEffect(() => {
        if (props.data) {
            let cols = [];
            for (let c of props.data) {
                if (c.color) {
                    cols.push(c.color)
                }
            }
            setColors(cols);
        }
    }, [props.data])
    return (
        props.data && props.data.length > 0 ? <ResponsivePie
            data={props.data}
            margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
            innerRadius={0.5}
            padAngle={0.4}
            cornerRadius={3}
            colors={colors}
            arcLabelsTextColor="#FFF"
            arcLinkLabelsTextColor="#ff0000"
            activeOuterRadiusOffset={8}
            borderWidth={4}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.7
                    ]
                ]
            }}
        /> : <div>No Data Found</div>
    );
}

export default PieVz;