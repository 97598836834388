import { CloseCircleFilled, DeleteOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { generateUniqueId, getBase64, humanFileSize } from "../../helpers/util";

const FileUploadPanel = styled.div`
    display: flex;
`;

const FilePreview = styled.div`   
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    z-index: 9999;
    background: #00000032;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
        padding: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFF;
        border-radius: 10px;
        overflow: hidden;
        height: 60%;
        width: 58%;
        box-shadow: 0px 0px 10px #0000003d;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
        }  
    }  
`;

const UploadIcon = styled.div`
    width: 100px;
    height: 100px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #808080;
    border-radius: 10px;
    display: flex;
    align-items: center;
    /* margin-right: 20px; */
    justify-content: center;
    margin: 6px;
`;

const FileProgressing = styled.div`
  text-align: center;
  margin-top: 62px;
  color: #505f7b;
`;

const FileContainer = styled.div`  
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 6px;
`;

const File = styled.div`
  border-radius: 10px;
  position: relative;
  cursor: pointer;
`;

const CustomFileUploader = (props: any) => {
    const [previewFile, setPreviewFile] = useState(null);
    const [previewFileId, setPreviewFileId] = useState(null);
    const [isFileContainer, setIsFileContainer] = useState(true);
    const [files, setFiles] = useState<any>([]);
    // const [stopRepeatEnter, setStopRepeatEnter] = React.useState(false);
    const [uploadFile, setUploadFile] = React.useState({
        uploadIcon: null,
        defaultFiles: [],
        fileList: [],
        selectedFileId: null
    });
    const validFileContentType = {
        image: ["image/jpg", "image/png", "image/jpeg"],
    };

    const loadThumbnail = async (file: any) => {
        return await getBase64(file);
    };

    const onBeforeUpload = async (event: any) => {
        let { files } = event.target;
        setFiles(files);
        // if (currentFileId !== files.uid) {
        //     currentFileId = files.uid;
        //     console.log("files", files);
        //     let validFileList = [];
        //     let fileList = uploadFile.fileList;
        //     console.log("files", files);
        //     for (let i in files) {
        //         console.log("::::::::::::::::::::::::: 1");
        //         if (files[i].type) {
        //             console.log("::::::::::::::::::::::::: 2");
        //             let file = files[i];
        //             let isValidType = false;
        //             const isLt2M = file.size / 1024 / 1024 < 2;
        //             if (isLt2M) {
        //                 console.log("::::::::::::::::::::::::: 3");
        //                 for (let j in validFileContentType) {
        //                     if (validFileContentType[j].indexOf(file.type) > -1) {
        //                         console.log("::::::::::::::::::::::::: 4");
        //                         let id = generateUniqueId("File");
        //                         isValidType = true;
        //                         file.uid = id;
        //                         file.status = "new";
        //                     }
        //                 }
        //                 if (!isValidType) {
        //                     console.error(
        //                         `The ${file.name} file extension is not able to attached`
        //                     );
        //                 }
        //             } else {
        //                 console.error(
        //                     `File size limit to be attached in email is less than 2MB. ${file.name
        //                     } size is ${humanFileSize(file.size, true)}`
        //                 );
        //             }

        //             if (isValidType && isLt2M) {
        //                 let fileDetails = file.name.split(/[\s.]+/);
        //                 let fileExt = fileDetails[fileDetails.length - 1];
        //                 file.fileExt = fileExt;
        //                 validFileList.push(file);
        //             }
        //         }
        //     }
        //     console.log("::::::::::::::::::::::::: 5");
        //     if (props.multiple) {
        //         fileList = fileList.concat(validFileList);
        //     } else {
        //         fileList = [...validFileList];
        //     }
        //     console.log("::::::::::::::::::::::::: 6");
        //     setUploadFile({
        //         ...uploadFile,
        //         fileList,
        //         selectedFileId:
        //             fileList.length === 1 ? fileList[0].uid : uploadFile.selectedFileId,
        //     });
        //     console.log("::::::::::::::::::::::::: 7");
        // }
    };

    useEffect(() => {
        if (files.length > 0) {
            let { fileList } = uploadFile;
            for (let i in files) {
                if (files[i].type && validFileContentType.image.includes(files[i].type)) {
                    fileList.push({
                        uid: generateUniqueId("custom_file"),
                        name: files[i].name,
                        status: "new",
                        url: URL.createObjectURL(files[i]),
                        fileExt: files[i].type,
                        size: humanFileSize(files[i].size),
                        originFileObj: files[i]
                    });
                }
            }
            setUploadFile({
                ...uploadFile,
                fileList: fileList,
                selectedFileId: fileList.length === 1 ? fileList[0].uid : uploadFile.selectedFileId,
            });
        }
        //eslint-disable-next-line
    }, [files]);

    const onSelectedFile = (uid) => {
        if (uploadFile.fileList.length > 0) {
            setUploadFile({
                ...uploadFile,
                selectedFileId: uid
            });
        }
    };

    useEffect(() => {
        if (props.onSelectedFile) {
            props.onSelectedFile(uploadFile.selectedFileId)
        }
        //eslint-disable-next-line
    }, [uploadFile.selectedFileId])

    useEffect(() => {
        if (props.isFileContainer === false) {
            setIsFileContainer(false);
        }
    }, [props.isFileContainer]);

    const loadFiles = async () => {
        let files: any = uploadFile.fileList;
        for (let i in files) {
            if (!files[i].uploadUrl) {
                //     let resp: any = await getFileUploadUrl(files[i].fileExt);
                if (files[i].status !== "done") {
                    //     files[i].url = files[i].url;
                    // } else {
                    files[i].url = await loadThumbnail(files[i]);
                }
                // if (resp && resp.data && resp.data.url) {
                //   files[i].uploadUrl = resp.data.url;
                // }
                setUploadFile({
                    ...uploadFile,
                    fileList: files,
                });
            }
        }
    };

    const onRemoveFile = (removeIndex: number, id: string) => {
        let { fileList } = uploadFile;
        if (props.onRemoveHandle) {
            if (fileList[removeIndex].status === "done") {
                props.onRemoveHandle(fileList[removeIndex]);
            }
        }
        fileList.splice(removeIndex, 1);
        setUploadFile({
            ...uploadFile,
            fileList: fileList,
        });
    };

    useEffect(() => {
        if (uploadFile.fileList.length > 0) {
            loadFiles();
        }
        //eslint-disable-next-line
    }, [uploadFile.fileList]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(uploadFile);
        }
        //eslint-disable-next-line
    }, [uploadFile]);

    useEffect(() => {
        let propsObj = {};
        if (props.uploadIcon) {
            propsObj['uploadIcon'] = props.uploadIcon;
        }
        if (props.defaultFiles) {
            propsObj['fileList'] = props.defaultFiles;
        }
        setUploadFile({
            ...uploadFile,
            ...propsObj,
        });
        //eslint-disable-next-line
    }, [props.defaultFiles, props.uploadIcon]);

    return <FileUploadPanel>
        <UploadIcon style={{ ...props.uploadBoxStyle }}>
            <label htmlFor="file_attachment">
                {uploadFile && uploadFile.uploadIcon ? <img alt="Plus" style={{ ...props.uploadIconStyle }} src={uploadFile.uploadIcon} /> :
                    <PlusOutlined style={{ fontSize: 50, color: uploadFile.fileList.length > 0 ? '#000' : "#a8acbf" }} />}
            </label>
        </UploadIcon>
        <div>
            {/*   */}
            {/* {stopRepeatEnter == true && (
                <FileUploadProgressing style={{ color: header.background.hex }}>
                  <FontAwesomeIcon size="2x" icon={solidIcons.faCircleNotch} color="#808080" className="loader" />
                  <div style={{ marginTop: "12px" }}>File Uploading...</div>
                </FileUploadProgressing>
              )} */}
            {previewFile && <FilePreview>
                <div className="container">
                    <CloseCircleFilled style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: 6,
                        top: 8,
                        fontSize: 20
                    }} onClick={() => setPreviewFile(null)} />
                    <img alt="Preview" src={previewFile.url}></img>
                </div>
            </FilePreview>}
            {isFileContainer && <FileContainer>
                {uploadFile.fileList.map((item, index) => {
                    return (
                        <File key={index} onClick={() => onSelectedFile(item.uid)} onMouseEnter={() => setPreviewFileId(item.uid)} onMouseLeave={() => setPreviewFileId(null)}>
                            <div
                                style={{
                                    width: 100,
                                    height: 100,
                                    boxShadow: '0px 0px 10px #0000003D',
                                    borderRadius: 10,
                                    border: `${uploadFile.selectedFileId === item.uid
                                        ? "solid 2px #1A1A1A"
                                        : "none"
                                        } `,
                                    overflow: "hidden",
                                    marginRight: 20
                                }}
                            >
                                {!item.url && (
                                    <FileProgressing>Loading...</FileProgressing>
                                )}
                                {item.url && (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {previewFileId === item.uid && <div style={{
                                            position: 'absolute',
                                            background: 'rgb(0 0 0 / 42%)',
                                            width: 100,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: 100,
                                            borderRadius: 10
                                        }}>
                                            <EyeOutlined style={{
                                                fontSize: 16,
                                                color: '#FFF',
                                                marginLeft: 6,
                                                cursor: 'pointer'
                                            }} onClick={(e) => { e.stopPropagation(); setPreviewFile(item); }} />
                                            <DeleteOutlined style={{
                                                fontSize: 16,
                                                color: '#FFF',
                                                marginLeft: 6,
                                                cursor: 'pointer'
                                            }} onClick={(e) => { e.stopPropagation(); onRemoveFile(index, item.uid); }} />
                                        </div>}
                                        <img
                                            className="preview-image"
                                            alt="Preview"
                                            src={item.url}
                                            style={{ width: "100px", height: "100px", objectFit: 'cover' }}
                                        />
                                    </div>
                                )}
                            </div>
                        </File>
                    );
                })}
            </FileContainer>}
        </div>
        <input
            type="file"
            id="file_attachment"
            disabled={false}
            multiple={props.multiple || false}
            accept="image/png, image/jpeg, image/jpg"
            onChange={onBeforeUpload}
            style={{ width: "22px", opacity: 0, position: "absolute", display: "none" }}
        />
    </FileUploadPanel>
}

export default CustomFileUploader;