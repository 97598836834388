import React, { useEffect, useState } from 'react'
import { Row, Col, Input } from 'antd';
import { useDispatch } from 'react-redux';
import SearchIcn from "../../../assets/images/search.svg";
import EditSvg from "../../../assets/images/settings/edit.svg";
import DeleteSvg from "../../../assets/images/settings/trash.svg";
import { getPosts, deletePost } from "../../../actions/blogActions";
import { getUsers } from '../../../actions/userActions';
import dayjs from 'dayjs';
import Loading from '../common/Loading';
import PopupConfirm from '../common/PopupConfirm';
import { useNav } from '../../../helpers/customHook';

const Post = () => {
    const windowHeight = window.innerHeight;
    const navigateTo = useNav();
    const dispatch: any = useDispatch();
    const [search, setSearch] = useState("");
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [postList, setPostList] = useState(null);
    const [posts, setPosts] = useState(null);
    const [users, setUsers] = useState([]);
    const onCreatePost = () => {
        navigateTo('create');
    }
    useEffect(() => {
        loadInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const loadInitialData = async () => {
        setIsLoading(true);
        const respUsers: any = await getUsers()(dispatch);
        setUsers(respUsers);
        await getPostList();
        setIsLoading(false);
    }
    const getPostList = async () => {
        const resp = await getPosts()(dispatch);
        if (resp?.posts) {
            setPosts(resp.posts);
        } else {
            setPosts([]);
        }
    }
    useEffect(() => {
        if (posts) {
            setPostList(posts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [posts]);
    const getUserName = (id: string) => {
        const user: any = users?.filter((u) => { return u.id === id })[0];
        return user?.fullName || "-";
    }
    const onEdit = async (id: string) => {
        navigateTo(`edit/${id}`);
    }
    const onDelete = async (item: any) => {
        setPopupConfirm({
            title: `${item.title} POST`,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to {<span style={{ fontWeight: "bold" }}> delete </span>} the <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>{item.title}</span>.</span></div > },
            yes: {
                label: "Delete",
                callback: async () => {
                    setIsLoading(true);
                    const resp = await deletePost(item.id)(dispatch);
                    if (resp) {
                        getPostList();
                    }
                    setIsLoading(false);
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }
    useEffect(() => {
        if (search) {
            const filteredList = posts?.filter((item) => {
                return item.title.toLowerCase().indexOf(search.toLowerCase()) > -1;
            });
            setPostList(filteredList);
        } else {
            setPostList(posts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    return <Row className="post">
        <Col span={24} style={{ height: windowHeight - 80 }}>
            {posts?.length > 0 && <div className="action-bar">
                <div className="title">
                    You can add or edit new post
                </div>
                <div className="actions">
                    <Input className="search" value={search} placeholder="Search" onChange={(e) => setSearch(e.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                    <button className="sp_btn btn" onClick={onCreatePost} >+ Add New Post</button>
                </div>
            </div>}
            <div className="content">
                {posts?.length === 0 && <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: windowHeight - 200, flexDirection: 'column', width: '100%' }}>
                    <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Post</div>
                    <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first POST.</div>
                    <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no Posts yet...</div>
                    <div style={{ display: 'flex' }}>
                        <button onClick={onCreatePost} className="sp_btn" style={{ width: 130 }} >+ Add New Post</button>
                    </div>
                </div>}
                {postList?.length > 0 && <div className="list">
                    {postList.map((item: any, index: number) => {
                        return <div className="item" key={index}>
                            <Row>
                                <Col span={12}>
                                    <div className="title">{item.title}</div>
                                    <div className="details">
                                        <div className="by">By</div>
                                        <div className="author">{getUserName(item?.createdBy)}</div>
                                        <div className="author">.</div>
                                        {item?.createdAt && <div className="date">{dayjs(item?.createdAt).calendar(null, {
                                            sameDay: "[Today] h:mm A",
                                            nextDay: "[Tomorrow] h:mm A",
                                            nextWeek: "ddd h:mm A",
                                            lastDay: "[Yesterday] h:mm A",
                                            lastWeek: "[Last] dddd h:mm A",
                                            sameElse: "MMM D YYYY [at] h:mm A",
                                        })}</div>}
                                    </div>
                                </Col>
                                <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    {item.blogTitle && <div className='blog'>{item.blogTitle}</div>}
                                </Col>
                                <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className={item.status === "draft" ? 'draft' : 'published'}>{item.status}</div>
                                </Col>
                                <Col span={2}>
                                    <div className='actions'>
                                        <img src={EditSvg} alt='Edit Icon' style={{ marginRight: 17 }} onClick={() => onEdit(item.id)} />
                                        <img src={DeleteSvg} alt='Delete Icon' onClick={() => onDelete(item)} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    })}
                </div>}
                {posts?.length > 0 && postList?.length === 0 && <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: windowHeight - 200, flexDirection: 'column', width: '100%' }}>
                    <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Search not found any records</div>
                </div>}
            </div>
        </Col>
        {isLoading && <Loading background="#00000070"></Loading>}
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
    </Row>
}

export default Post;