import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import BlackCircleWithTickSvg from "../../../assets/images/blackCircleWithTickSvg.svg";
import { setSetupStoreDetails } from "../../../actions/uiAction";
import { getSubscriptionPlans } from "../../../actions/setupStoreActions";
import { useDispatch, useSelector } from "react-redux";
import Header from "../setupstore/Header";
import { useNav } from "../../../helpers/customHook";
import Footer from "../setupstore/Footer";

const Container = styled.div`
    color: #121212;
    width: 90%;
    max-width: 357px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
`;
const Section = styled.div`
    display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
`;
const Desc = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 34px;
    text-align: center;
`;
// const Subtitle = styled.div`
//     font-size: 18px;
//     font-weight: 600;
//     color: #2D3748;
//     margin-bottom: 24px;
// `;
// const Features = styled.div`
//     color:#2D3748;
//     font-size: 14px;
//     font-weight: 500;
//     display: flex;
//     justify-content: space-between;
//     .item {
//         display: flex;
//         gap: 7px;
//         margin-bottom: 14px;
//     }
// `;
// const Divider = styled.div`
//     background: #C6C6C6C4;
//     height: 1px;
//     width: 100%;
//     margin: 24px 0px;
// `;
// const Fee = styled.div`
//     font-size: 14px;
//     font-weight: 500;
//     color: #2D3748
// `;
// const More = styled.a`
//     font-size: 14px;
//     font-weight: 600;
//     margin-left: 4px;
//     color: #2D3748;
// `;
const Plan = styled.div`
    border-radius: 12px;
    border: 1px solid #A0A7B142;
    box-shadow: 0px 20px 25px -5px #0000001A;
    background: #fff;
    padding: 21px;
    width: 100%;
`;
const PName = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-left: 12px;
`;
const Price = styled.div`
    color: #171923;
    font-size: 30px;
    font-weight: 600;
    margin-right: 10px;
`;
const PriceMonth = styled.div`
    font-size: 16px;
    color: #171923;
    font-weight: 600;
`;
const Transaction = styled.div`   
    display: flex;
    align-items: center;
    justify-content: flex-end;
    div {
        background: #FFF1E2;
        font-size: 14px;
        color:#121212;
        font-weight: 500;
        padding: 2px 10px;
        border-radius: 4px;
    }
`;
const UnselectPlanRadio = styled.div`
    border: 3px solid #C4C4C4;
    width: 26px;
    height: 26px;
    border-radius: 50px;
`;
const SelectPlanRadio = styled.div`
    border: 3px solid #121212;
    width: 26px;
    height: 26px;
    padding: 3px;
    border-radius: 50px;
    div {
        background: #121212;
        height: 100%;
        width: 100%;
        border-radius: 50px;
    }
`;

const ChoosePlan = () => {
    const dispatch: any = useDispatch();
    const navigateTo = useNav();
    const { setupStoreDetails } = useSelector((state: any) => state.ui);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [plans, setPlans] = useState(null);
    useEffect(() => {
        loadSubscriptionPlans();
        //eslint-disable-next-line
    }, []);
    const loadSubscriptionPlans = async () => {
        const resp = await dispatch(getSubscriptionPlans());
        if (resp?.plans && !setupStoreDetails?.plan) {
            setSelectedPlan(resp.plans[resp.plans.length - 1]);
        }
        setPlans(resp.plans);
    }
    const onSelectedPlan = () => {
        if (selectedPlan) {
            dispatch(setSetupStoreDetails({ ...setupStoreDetails, plan: selectedPlan }));
            navigateTo(`/${setupStoreDetails.storeName}/plan/${selectedPlan.name}`, { state: { plan: selectedPlan } })
        }
    }
    useEffect(() => {
        if (setupStoreDetails) {
            const { plan } = setupStoreDetails;
            if (plan) {
                setSelectedPlan({ ...plan });
            }
        }
    }, [setupStoreDetails])
    return <div className="setup-store-mob">
        <Header enableLogout={true} />
        <div className="content" >
            <Container>
                {plans && plans.length > 0 && <Section>
                    <Title>Simple, transparent Pricing</Title>
                    <Desc>Pricing plans for business at every stage of growth.</Desc>
                    {/* <Subtitle>What's included in your subscription</Subtitle> */}
                    {/* <Section>
                        <Features>
                            <Section style={{ alignItems: 'baseline' }}>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Mobile App (Android / iOS)</div>
                                </div>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Free SSL Certificate</div>
                                </div>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Dashboard Analytics</div>
                                </div>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>SEO Ready</div>
                                </div>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Lexi AI</div>
                                </div>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Online Store</div>
                                </div>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Custom Domains</div>
                                </div>
                                <div className="item">
                                    <img src={BlackCircleWithTickSvg} alt="check list" />
                                    <div>Online Payment</div>
                                </div>
                            </Section>
                        </Features>
                    </Section>
                    <Divider />
                    <Section style={{ flexDirection: 'row', marginBottom: 20 }}>
                        <Fee>Unlimited Access. Regular Updates. No Hidden Fees.<More href='https://www.shopletzy.com/pricing/' target='_blank'>See more feature list</More></Fee>
                    </Section> */}
                </Section>}
                {plans && plans.length > 0 && <Section style={{ gap: 20 }}>
                    {plans.map((plan: any) => {
                        return <Plan key={plan.id} onClick={() => { setSelectedPlan(plan) }} style={{
                            cursor:
                                selectedPlan?.id === plan.id ? 'default' : 'pointer',
                            border: selectedPlan?.id === plan.id ? '1px solid #121212' : '1px solid #A0A7B142',
                            boxShadow: selectedPlan?.id === plan.id ? '0px 10px 10px -5px #0000000A' : '0px 20px 25px -5px #0000001A'
                        }}>
                            <Section style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Section style={{ flexDirection: 'row', }}>
                                    {selectedPlan?.id === plan.id ? <SelectPlanRadio>
                                        <div></div>
                                    </SelectPlanRadio> : <UnselectPlanRadio></UnselectPlanRadio>}
                                    <PName>{plan.name}</PName>
                                </Section>
                                <Section>
                                    <Section style={{ flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 6, alignItems: 'baseline' }}>
                                        <Price>₹ {plan.planFee / 100}</Price>
                                        <PriceMonth>/mo</PriceMonth>
                                    </Section>
                                </Section>
                            </Section>
                            <Transaction>
                                <div>{`+ ${plan.transactionFeePct}% transaction fee on every order `}</div>
                            </Transaction>
                        </Plan>
                    })}
                    <Section style={{ justifyContent: 'center', alignItems: 'center', margin: '18px 0px 28px 0px', width: '100%' }}>
                        <button onClick={onSelectedPlan} style={{ background: "#121212", color: '#fff', width: '100%' }} className="sp_btn getStared">
                            {setupStoreDetails && selectedPlan?.trialDays > 0 ? "Start 30 Days Free Trial" : "Proceed"}
                        </button>
                    </Section>
                </Section>}
            </Container>
        </div>
        <Footer />
    </div>
}

export default ChoosePlan;