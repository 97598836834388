import { Input } from "antd";
import { useEffect, useState } from "react";
import { SortableList } from "../sortableList";
import "../../../../assets/sass/dsk/popupConfirm.sass";
import DeleteSvg from "../../../../assets/images/settings/trash.svg";
import { getProductOptions } from "../../../../actions/productActions";
import { useDispatch } from "react-redux";
import { uuidv4 } from "../../../../helpers/util";

const CreateOptions = (props: any) => {
    const dispatch = useDispatch();
    const { data = null, onSubmit, onClose } = props;
    const [selectedOption, setSelectedOption] = useState(null);
    const [optionList, setOptionList] = useState([]);
    const [optionName, setOptionName] = useState("");
    const [selectedOptionName, setSelectedOptionName] = useState(null);
    const [optionValues, setOptionValues] = useState([]);
    const [debouncedValue, setDebouncedValue] = useState(optionName);
    const onDelete = (id) => {
        const index = optionValues.findIndex((i) => i.id === id);
        optionValues.splice(index, 1);
        setOptionValues([...optionValues]);
    }

    useEffect(() => {
        if (data) {
            setSelectedOption(data);
            if (data.name) {
                setOptionName(data.name);
            }
            if (data.values) {
                setOptionValues(data.values);
            }
        }
    }, [data]);

    useEffect(() => {
        if (optionValues && optionValues.length > 0 && optionValues[optionValues.length - 1].name !== "") {
            setOptionValues([...optionValues, { id: uuidv4(), name: "" }]);
        }
    }, [optionValues]);

    const getOptions = async () => {
        const resp = await getProductOptions(optionName)(dispatch);
        if (resp && resp.options && resp.options.length > 0) {
            setOptionList(resp.options);
        }
    }

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(optionName);
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [optionName]);

    useEffect(() => {
        if (selectedOptionName) {
            setOptionList([]);
            setOptionName(selectedOptionName);
        }
    }, [selectedOptionName]);

    useEffect(() => {
        if (debouncedValue && selectedOptionName === null) {
            getOptions();
        }
        // eslint-disable-next-line
    }, [debouncedValue, selectedOptionName]);

    return <div className="create-option-popup popup">
        <div className="container">
            <div className="box">
                <div className="title">Add Option</div>
                <div className="content">
                    <div className="create-option">
                        <div className="option-name">
                            <div className="label">Option Name</div>
                            <Input placeholder="Enter option name" value={optionName} onChange={(e) => {
                                setOptionName(e.target.value);
                                setSelectedOptionName(null);
                                if (optionName.length === 0) {
                                    setOptionValues([]);
                                    setOptionList([]);
                                } else if (optionName.length >= 2) {
                                    setOptionValues([{ id: uuidv4(), name: "" }]);
                                }
                            }} />
                            {optionName && optionName.length > 0 && optionList && optionList.length > 0 && <div className="option-list">
                                {optionList.map((option, index) => (
                                    <div className="option" key={`${option}_${index}`} onClick={() => {
                                        setSelectedOptionName(option.name);
                                        setOptionValues(option.values.map((value) => ({ id: uuidv4(), name: value })));
                                    }
                                    }>{option.name}</div>
                                ))}
                            </div>}
                        </div>
                        {optionName && optionName.length > 2 && <div className="option-values">
                            <div className="label" style={{ marginTop: 15, marginLeft: 30 }}>Option Values</div>
                            <SortableList
                                items={optionValues}
                                onChange={setOptionValues}
                                renderItem={(item: any) => (
                                    <SortableList.Item id={item.id}>
                                        <SortableList.DragHandle />
                                        <input type="text" value={item.name}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                if (optionValues[optionValues.length - 1].name !== "") {
                                                    setOptionValues([...optionValues, { id: optionValues.length + 1, name: "" }]);
                                                }
                                            }}
                                            onChange={(e) => {
                                                const index = optionValues.findIndex((i) => i.id === item.id);
                                                optionValues[index].name = e.target.value;
                                                setOptionValues([...optionValues]);
                                            }} />
                                        <img src={DeleteSvg} alt="delete" style={{ marginLeft: 16 }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); onDelete(item.id); }} />
                                    </SortableList.Item>
                                )}
                            />
                        </div>}
                    </div>
                </div>
                <div className="action">
                    <div className="sp_btn no" onClick={() => { onClose(); setOptionName(""); setOptionValues([]) }}>Cancel</div>
                    <div className="sp_btn yes" onClick={() => {
                        onSubmit({ name: optionName, values: optionValues, id: selectedOption?.id });
                    }}>Save</div>
                </div>
            </div>
        </div>
    </div>
}

export default CreateOptions;