import React, { useCallback, useEffect, useRef, useState } from "react";
import { HexAlphaColorPicker } from "react-colorful";
import useClickOutside from "./useClickOutside";
import PickerSvg from '../../../assets/images/color-picker-solid.svg';
import '../../../assets/sass/dsk/colorPalette.sass';
import { isDark } from '../../../helpers/util';
import { Input } from "antd";
import { useDebouncedAction } from "../../common/DebouncedAction";

interface IProps {
    defaultColor: string;
    label?: string;
    onColorChange: (string) => void;
    key?: string;
    colorPalette?: boolean
}

const ColorCodes = [
    "#FD7D7B",
    "#9CDEB4",
    "#80B5FD",
    "#6F79D7",
    "#21C082",
    "#1F4173",
    "#188FFC",
    "#02BA65",
];

const ColorPalette = (props: IProps) => {
    const [updateDefaultProps, setUpdateDefaultProps] = useState(false);
    const [color, setColor] = useDebouncedAction(null);
    const [colorPalette, setColorPalette] = useState(false);
    const [defaultColor, setDefaultColor] = useState(ColorCodes[0]);
    const [pickerColor, setPickerColor] = useState(null)
    const popoverColor = useRef();
    const [isOpenColor, setOpenColor] = useState(false);
    const closeColor = useCallback(() => setOpenColor(false), []);
    useClickOutside(popoverColor, closeColor);

    useEffect(() => {
        if (pickerColor) {
            setColor(pickerColor)
        }
        //eslint-disable-next-line
    }, [pickerColor])

    useEffect(() => {
        setUpdateDefaultProps(true);
        if (props.defaultColor) {
            setDefaultColor(props.defaultColor)
        }
        if (props.colorPalette) {
            setColorPalette(props.colorPalette);
        }
    }, [props.defaultColor, props.key, props.colorPalette]);

    // useEffect(() => {
    //     setColor(defaultColor)
    //     setPickerColor(defaultColor)
    //     //eslint-disable-next-line
    // }, [defaultColor])

    useEffect(() => {
        if (typeof props.onColorChange === "function" && !updateDefaultProps) {
            if (color != null && color !== props.defaultColor) {
                console.log({ color, props: props.defaultColor })
                props.onColorChange(color);
            }
        }
        setUpdateDefaultProps(false);
        //eslint-disable-next-line
    }, [color]);

    const onChangeColor = (cCode) => {
        setColor(`#${cCode}`);
    }

    useEffect(() => {
        if (!isOpenColor) {
            setPickerColor(color)
        }
        //eslint-disable-next-line
    }, [isOpenColor])


    return <div className="color_palettes" style={{ marginBottom: colorPalette ? 16 : 0 }}>
        {props.label && <div className="title">
            {props.label}
        </div>}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="palettes" style={{ marginBottom: colorPalette ? 20 : 0 }}>
                <div className="picker">
                    <div
                        style={{
                            cursor: 'pointer',
                            width: 32,
                            height: 32,
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: 30,
                            padding: 2,
                            border: `1px dotted #121212`
                        }}
                        onClick={() => setOpenColor(true)}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                width: 26,
                                height: 26,
                                borderRadius: 30,
                                background: 'transparent linear-gradient(180deg, #00DBDE 0%, #FC00FF 100%) 0% 0% no-repeat padding-box'
                            }}>
                            <img alt="Picker Icon" style={{ width: 16 }} src={PickerSvg}></img>
                        </div>
                    </div>
                    {isOpenColor && (
                        <div className="popover" ref={popoverColor}>
                            <section className="custom-layout example">
                                <HexAlphaColorPicker color={color || defaultColor} onChange={(val) => { setColor(null, () => setColor(val)) }} />
                            </section>
                        </div>
                    )}
                    <div className="custom-color">
                        <div style={{ marginRight: 4, marginLeft: 14, fontSize: 16, color: "#4E4E4E", fontWeight: 100 }}>#</div>
                        <Input key={color || defaultColor} maxLength={8}
                            onBlur={(e) => onChangeColor(e.target.value.toUpperCase())}
                            className="code"
                            style={{
                                fontWeight: 'bold',
                                textAlign: 'center',
                                color: isDark(color || defaultColor) ? "#FFF" : "#121212",
                                background: color || defaultColor, height: 32,
                                fontSize: 14, width: color?.length > 7 ? 98 : 84
                            }}
                            type="text" defaultValue={color?.toLocaleUpperCase().replace('#', '') || defaultColor?.toLocaleUpperCase().replace('#', '')} />
                    </div>
                </div>
            </div>
            {colorPalette && <div style={{ display: 'flex' }}>
                {ColorCodes.map((item, i) => {
                    return <div key={`pc_${i}`}
                        onClick={() => { setUpdateDefaultProps(false); setPickerColor(item); }}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: color === item ? '#FFF' : item,
                            border: `1px solid ${item}`,
                            borderRadius: 30,
                            width: 30,
                            height: 30,
                            padding: 4,
                            marginRight: 18,
                            boxShadow: '0px 3px 6px #00000029'
                        }}>
                        <div style={{
                            cursor: 'pointer',
                            width: 20,
                            height: 20,
                            backgroundColor: item,
                            borderRadius: 12
                        }}></div>
                    </div>
                })}
            </div>}
        </div>
    </div>
}

export default ColorPalette;