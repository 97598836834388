import React, { useEffect, useState } from 'react'
import { Row, Col, Input } from 'antd';
import { useDispatch } from 'react-redux';
import SearchIcn from "../../../../../assets/images/search.svg";
import DeleteSvg from "../../../../../assets/images/settings/trash.svg";
import CheckedCircleIcn from "../../../../../assets/images/checked-circle.svg";
import UncheckedCircleIcn from "../../../../../assets/images/ios-close-circle-outline.svg";
import { deletePageRedirect, getPagesRedirect, } from "../../../../../actions/blogActions";
import Loading from '../../../common/Loading';
import PopupConfirm from '../../../common/PopupConfirm';
import { useNav } from '../../../../../helpers/customHook';

const ListView = () => {
    const windowHeight = window.innerHeight;
    const navigateTo = useNav();
    const dispatch: any = useDispatch();
    const [search, setSearch] = useState("");
    const [pageRedirectList, setPageRedirectList] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pagesRedirect, setPagesRedirect] = useState(null);
    const onCreatePage = () => {
        navigateTo('create');
    }
    useEffect(() => {
        loadInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const loadInitialData = async () => {
        getPageRedirectList();
    }
    const getPageRedirectList = async () => {
        const resp = await getPagesRedirect()(dispatch);
        if (resp?.pageRedirects) {
            setPagesRedirect(resp.pageRedirects);
        } else {
            setPagesRedirect([]);
        }
    }
    useEffect(() => {
        if (search) {
            const filteredList = pagesRedirect?.filter((item) => {
                return item.destination.toLowerCase().indexOf(search.toLowerCase()) > -1 || item.source.toLowerCase().indexOf(search.toLowerCase()) > -1;
            });
            setPageRedirectList(filteredList);
        } else {
            setPageRedirectList(pagesRedirect);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    useEffect(() => {
        if (pagesRedirect) {
            setPageRedirectList(pagesRedirect);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagesRedirect]);
    const onDelete = async (item: any) => {
        setPopupConfirm({
            title: `Page Redirects`,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to {<span style={{ fontWeight: "bold" }}> delete </span>} this content.</span></div > },
            yes: {
                label: "Delete",
                callback: async () => {
                    setIsLoading(true);
                    const resp = await deletePageRedirect(item.id)(dispatch);
                    if (resp) {
                        getPageRedirectList();
                    }
                    setIsLoading(false);
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }
    return <Row className="product">
        {(pagesRedirect && pagesRedirect.length > 0) && <Col className="list" style={{ height: windowHeight - 76 }}>
            {pagesRedirect?.length > 0 && <div className="action-bar">
                <div className="title">
                    You can add or edit new page redirect
                </div>
                <div className="actions" style={{ flexGrow: 1, justifyContent: "flex-end" }}>
                    <Input style={{ width: "30%" }} className="search" value={search} placeholder="Search" onChange={(e) => setSearch(e.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                    <button className="sp_btn btn" style={{ width: "auto" }} onClick={onCreatePage} >+ Add New Page Redirect</button>
                </div>
            </div>}
            <div className="content">
                <Row className="header" style={{ marginBottom: 15, fontWeight: "bold" }}>
                    <Col span={10}>
                        Source
                    </Col>
                    <Col span={10}>
                        Destination
                    </Col>
                    <Col span={1}>
                        Permanent
                    </Col>
                    <Col style={{ textAlign: "center", paddingRight: 0 }} span={1}>
                        Regex
                    </Col>
                    <Col style={{ textAlign: "center", paddingRight: 0 }} span={2}>
                        Actions
                    </Col>
                </Row>
                <Row className="contains" style={{ height: windowHeight - 234 }}>
                    <Col span={24}>
                        {pageRedirectList && pageRedirectList.map((record, index) =>
                            <Row className="item" key={record.id}>
                                <Col span={10}>
                                    <a href={record.source} target='_blank' rel="noreferrer">{record.source}</a>
                                </Col>
                                <Col span={10}>
                                    <a href={record.destination} target='_blank' rel="noreferrer">{record.destination}</a>
                                </Col>
                                <Col span={1} style={{ display: 'flex', alignItems: 'center', justifyContent: "center", paddingRight: 0 }}>
                                    <img src={record.permanent ? CheckedCircleIcn : UncheckedCircleIcn} alt='Permanent' height={24} />
                                </Col>
                                <Col span={1} style={{ display: 'flex', alignItems: 'center', justifyContent: "center", paddingRight: 0 }}>
                                    <img src={record.useRegex ? CheckedCircleIcn : UncheckedCircleIcn} alt='Regex' height={24} />
                                </Col>
                                <Col span={2} >
                                    <div style={{ textAlign: 'center' }}><img alt='Delete Icon' onClick={(e) => { e.stopPropagation(); onDelete(record) }} style={{ cursor: 'pointer' }} src={DeleteSvg} /></div>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
        </Col>}
        {pagesRedirect?.length === 0 && <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: windowHeight - 200, flexDirection: 'column', width: '100%' }}>
            <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Page Redirects</div>
            <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first Page Redirects.</div>
            <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no Page Redirects yet...</div>
            <div style={{ display: 'flex' }}>
                <button onClick={onCreatePage} className="sp_btn" style={{ width: 136 }} >+ Add New Page</button>
            </div>
        </div>}
        {isLoading && <Loading background="#00000070"></Loading>}
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
    </Row >
}

export default ListView;