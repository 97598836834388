import React from 'react';
import { Outlet } from 'react-router-dom';
import '../../../assets/sass/dsk/btheme.sass';

const Themes = (props: any) => {
    return (
        <div className="btheme">
            <Outlet />
        </div>
    );
}

export default Themes;