export const defaultDskThemeProps = {
    lastUpdated: new Date().getTime(),
    general: {
        primaryColor: "#FF0A54",
        secondaryColor: "#077DFF",
        fontFamily: "AvenirMedium",
        button: {
            backgroundColor: "",
            color: "",
            fontSize: "",
        },
        brand: {
            label: "Shopletzy"
        },
    },
    header: {
        background: "#FFF",
        signinBtn: {
            label: "Signin",
            borderRadius: "23px"
        }
    },
    banner: [{
        type: "image",
        content: "https://images-eu.ssl-images-amazon.com/images/G/31/img21/Audio/Boat/Masaba/Boat_Gw_1500x600._CB658315717_.jpg",
    }, {
        type: "image",
        content: "https://images-eu.ssl-images-amazon.com/images/G/31/img21/ApparelGW/ATF/under599/1500X600-eng-fst._CB659534550_.jpg",
    }, {
        type: "text",
        content: "Premium Chicken-Strips (Boneless)"
    }],
    landing: {
        bgImage: "https://storage.googleapis.com/slz-dev-uploads-asia-southeast1/assets/bannerImg-min.png",
        backgroundColor: "#fff",
        content: {
            signinBtn: {
                label: "Signin",
                borderRadius: "23px"
            },
            mainTitle: {
                label: "Enjoy your favourite dish",
                color: "#232631"
            },
            subTitle: {
                color: "#686F89",
                label: "We Are Open For Take Aways",
                fontFamily: "AvenirMedium"
            },
            location: {
                errorMsg: "Choose your delivery location",
                placeholder: {
                    label: "Enter delivery location",
                    color: "#686F89"
                },
                icon: "https://storage.googleapis.com/slz-dev-uploads-asia-southeast1/assets/currentLoc_ico.svg",
                button: {
                    color: "#FFFFFF",
                    lable: "Explore Menu",
                    borderRadius: "4px"
                }
            },
        }
    },
    topPicks: {
        title: {
            label: "TOP PICKS",
            fontSize: "28px",
            fontWeight: "bold",
            color: "#232631",
        }
    },
    newItems: {
        title: {
            label: "What's new",
            fontSize: "28px",
            fontWeight: "bold",
            color: "#232631",
        }
    },
    popularItems: {
        background: "#F0F7FF",
        title: {
            label: "POPULAR FOOD",
            fontSize: "24px",
            fontWeight: "bold",
            color: "#686F89",
        },
        items: {
            fontSize: "16px",
            color: "#686F89"
        }
    },
    footer: {
        background: "#FFF",
        logo: {
            fontSize: "20px"
        },
        title: {
            color: "#686F89"
        },
        item: {
            color: "#686F89"
        },
        copyright: {
            label: "© 2021 Shopletzy All rights reserved",
            background: "#F0F7FF",
            color: "#686F89"
        }
    }

};

export const defaultMobThemeProps = {
    lastUpdated: new Date().getTime(),
    general: {
        primaryColor: "#FF0A54",
        secondaryColor: "#077DFF",
        fontFamily: "AvenirMedium",
        button: {
            backgroundColor: "",
            color: "",
            fontSize: "",
        },
        brand: {
            label: "Shopletzy"
        },
        closeIcon: "https://storage.googleapis.com/slz-dev-uploads-asia-southeast1/assets/bg_close_ico.svg"
    },
    header: {
        background: "#FFF",
        signinBtn: {
            label: "Signin",
            borderRadius: "23px"
        }
    },
    bannerMain: [{
        type: "image",
        content: "https://images-eu.ssl-images-amazon.com/images/G/31/img18/Wearables/GW/CC/wearables2_feb2020_CC_GW_1050x1050._SX1050_CB423371909_.jpg"
    }, {
        type: "image",
        content: "https://images-eu.ssl-images-amazon.com/images/G/31/img20/Events/Zeitgeist/Summerstore/summer_mobile_CC._SX1050_CB657597742_.jpg",
    }, {
        type: "image",
        content: "https://images-eu.ssl-images-amazon.com/images/G/31/in-certifiedrefurbished/gateway/Gateway_Unboxed_1209837_1050x1050._SX1050_CB424157453_.jpg",
    }, {
        type: "image",
        content: "https://images-eu.ssl-images-amazon.com/images/G/31/img21/OHL_HMT/1050x1050_CC-1._SX1050_CB661231483_.jpg"
    }],
    // bannerLanding: [{
    //     url: "https://image.shutterstock.com/image-photo/grilled-fish-on-oiled-paper-600w-1706008288.jpg",
    // }, {
    //     url: "https://images-eu.ssl-images-amazon.com/images/G/31/img21/Wireless/Xiaomi/Family/GW/March/D21563914_WLD_Mi_Family_BAU_DesktopHero_1500x600._CB658003410_.jpg",
    // }, {
    //     url: "https://images-eu.ssl-images-amazon.com/images/G/31/img19/AmazonPay/Rajeshwari/March-21/Network_Hero_banners/HeroPC_1500x600_5._CB657961025_.jpg"
    // }],
    bannerLanding: [{
        type: "image",
        content: "https://image.shutterstock.com/image-photo/grilled-fish-on-oiled-paper-600w-1706008288.jpg",
    }, {
        type: "video",
        content: "https://ak.picdn.net/shutterstock/videos/14755261/preview/stock-footage-chicken-with-vegetables.mp4",
    }, {
        type: "text",
        content: "Premium Chicken-Strips (Boneless)"
    }],
    topPicks: {
        title: {
            label: "TOP PICKS",
            fontSize: "28px",
            fontWeight: "bold",
            color: "#232631",
        }
    },
    newItems: {
        title: {
            label: "What's new",
            fontSize: "28px",
            fontWeight: "bold",
            color: "#232631",
        }
    },
    popularItems: {
        background: "#F0F7FF",
        title: {
            label: "POPULAR FOOD",
            fontSize: "24px",
            fontWeight: "bold",
            color: "#686F89",
        },
        items: {
            fontSize: "16px",
            color: "#686F89"
        }
    },
    landing: {
        bgImage: "https://storage.googleapis.com/slz-dev-uploads-asia-southeast1/assets/home-banner-mob.svg",
        backgroundColor: "#fff",
        content: {
            borderRadius: "10px",
            backgroundColor: "#FFFFFF",
            signinBtn: {
                label: "Login",
                borderRadius: "23px"
            },
            mainTitle: {
                label: "With Wide Collection Of Cuisines",
                color: "#232631"
            },
            subTitle: {
                color: "#686F89",
                label: "Ready to see top foods for orders",
                fontFamily: "AvenirMedium"
            },
            location: {
                title: "Search Location",
                errorMsg: "Choose your delivery location",
                placeholder: {
                    label: "Enter delivery location",
                    color: "#686F89"
                },
                icon: "https://storage.googleapis.com/slz-dev-uploads-asia-southeast1/assets/currentLoc_ico.svg",
                button: {
                    color: "#FFFFFF",
                    lable: "Setup Location",
                    borderRadius: "4px"
                }
            },
        }
    }
};