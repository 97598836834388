import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Drawer, Input, Row, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { isNotNullOrUndefinedString } from '../../../../../helpers/util';
import InputValidation from '../../../common/InputValidation';
// import Loading from '../../../common/Loading';

const accountDetails = {
    retypeAccountNo: null,
    accountHolderName: null,
    accountNo: null,
    ifscCode: null,
    branch: null,
    accountType: null,
    id: null
};

const Save = (props: any) => {
    const [error, setError] = useState(accountDetails);
    const [account, setAccount] = useState(props.account || accountDetails);
    const [isValidIfsc, setValidIfsc] = useState(false);
    const [isValidAccNo, setValidAccNo] = useState(false);
    const [isValidReAccNo, setValidReAccNo] = useState(false);
    const [isEdit, setEdit] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    useEffect(() => {
        setEdit(props.account.id ? false : true);
        //eslint-disable-next-line
    }, [props.account.id])
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    const onHandleSubmit = async () => {
        let errorInfo = Object.create(accountDetails);
        setError({ ...error, ...errorInfo });
        if (!isNotNullOrUndefinedString(account.accountHolderName)) {
            errorInfo.accountHolderName = "Enter the account holder name";
        } else {
            errorInfo.accountHolderName = null;
        }
        if (!isNotNullOrUndefinedString(account.accountNo)) {
            errorInfo.accountNo = "Enter the account no";
        } else if (isValidAccNo === true) {
            errorInfo.accountNo = "Please enter valid account number.";
        } else {
            errorInfo.accountNo = null;
        }
        if (!isNotNullOrUndefinedString(account.retypeAccountNo)) {
            errorInfo.retypeAccountNo = "Retype account no";
        } else if (isValidReAccNo === true) {
            errorInfo.accountNo = "Please enter valid account number.";
        } else {
            errorInfo.retypeAccountNo = null;
        }

        // if (isNotNullOrUndefinedString(account.accountNo) && isNotNullOrUndefinedString(account.retypeAccountNo)) {
        //     if (account.accountNo !== account.retypeAccountNo) {
        //         errorInfo.accountNo = "account no mismatch";
        //         errorInfo.retypeAccountNo = "account no mismatch";
        //     } else {
        //         errorInfo.accountNo = null;
        //         errorInfo.retypeAccountNo = null;
        //     }
        // }
        if (!isNotNullOrUndefinedString(account.ifscCode)) {
            errorInfo.ifscCode = "Enter the ifsc code";
        } else if (isValidIfsc === true) {
            errorInfo.ifscCode = "Enter the valid ifsc code";
        } else {
            errorInfo.ifscCode = null;
        }
        if (!isNotNullOrUndefinedString(account.branch)) {
            errorInfo.branch = "Enter the account branch";
        } else {
            errorInfo.branch = null;
        }
        if (!isNotNullOrUndefinedString(account.accountType)) {
            errorInfo.accountType = "Select the account type";
        } else {
            errorInfo.accountType = null;
        }
        setError({ ...error, ...errorInfo });
        if (!errorInfo.accountType && !errorInfo.branch && !errorInfo.accountNo && !errorInfo.ifscCode && !errorInfo.accountHolderName) {
            // let resp = null;
            let payload = {
                accountType: account.accountType,
                branch: account.branch,
                accountNo: account.accountNo,
                ifscCode: account.ifscCode,
                accountHolderName: account.accountHolderName
            }
            if (account.id == null) {
                await props.onSave(payload);
            }
        }
    }

    const onClose = () => {
        let errorInfo = Object.create(accountDetails);
        setError({ ...error, ...errorInfo });
        props.onClose();
    }

    const validateAcct = (name) => {
        let acctNo = account.accountNo

        var numbers = /^\d{9,18}$/;
        if (name === "account") {
            if (numbers.test(acctNo)) {
                setValidAccNo(false)
                return true;
            }
            else {
                setValidAccNo(true)
                return false;
            }
        } else if (name === "retypeAcc") {
            if (numbers.test(acctNo)) {
                setValidReAccNo(false)
                return true;
            }
            else {
                setValidReAccNo(true)
                return false;
            }

        }

    }

    const validateIfsc = () => {
        let ifscCode = account.ifscCode
        var numbers = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
        if (numbers.test(ifscCode)) {
            setValidIfsc(false)
            console.log('valid code');

            return true;
        }
        else {
            setValidIfsc(true)
            console.log('not valid code');
            return false;
        }

    }

    return (
        <Drawer
            width={470}
            closable={false}
            onClose={onClose}
            className="account-save"
            visible={props.isVisible}
            footer={
                <div className='footer'>
                    <div className="sp_btn" onClick={onClose} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                    {/* {!isEdit && <div className="sp_btn" onClick={() => { setEdit(true) }} style={{ width: 120, fontSize: 14 }}>Edit</div>} */}
                    {isEdit && <div className="sp_btn" onClick={onHandleSubmit} style={{ width: 155, fontSize: 14 }}>{account && account.id ? "Update" : "Add"}</div>}
                </div>
                // <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                //     {isEdit && <Row style={{ padding: "24px 52px" }}>
                //         <Col span={11} style={{ paddingRight: "15px" }}>
                //             <button onClick={props.onClose} className="sp_btn cancel">Cancel</button>
                //         </Col>
                //         <Col span={2}></Col>
                //         <Col span={11} style={{ paddingLeft: "15px" }}>
                //             {!isEdit && <button onClick={() => { setEdit(true) }} className="sp_btn">Edit</button>}
                //             {isEdit && <button onClick={onHandleSubmit} className="sp_btn">{account && account.id ? "Update" : "Add"}</button>}
                //         </Col>
                //     </Row>}
                // </div>
            }>
            {/* {isLoading && <Loading background="#00000070"></Loading>} */}
            <div className="header">
                <ArrowLeftOutlined className="arrow" onClick={onClose}></ArrowLeftOutlined>
                <div className="title">{!account.id ? 'ADD' : 'View'} Bank Details</div>
            </div>
            <Row className="content" >
                <Col span={24} className="details" style={{ height: dimensions.height - 148, overflow: 'auto' }}>
                    <div className="group">
                        <div className="label">Account Holder Name *</div>
                        <InputValidation type={error.accountHolderName ? "error" : null} message={error.accountHolderName}>
                            <Input disabled={!isEdit} type="text" name="accountHolderName" defaultValue={account.accountHolderName} placeholder="Enter the account holder name"
                                onChange={(e) => setAccount({ ...account, accountHolderName: e.target.value })}
                            />
                        </InputValidation>
                    </div>
                    <div className="group">
                        <div className="label">Account No *</div>
                        <InputValidation type={error.accountNo ? "error" : null} message={error.accountNo}>
                            <Input disabled={!isEdit} type="text" name="accountNo" defaultValue={account.accountNo} placeholder='eg: 27900000789789'
                                onChange={(e) => setAccount({ ...account, accountNo: e.target.value })}
                                onBlur={() => validateAcct("account")}
                            />
                        </InputValidation>
                    </div>
                    <div className="group">
                        <div className="label">Retype Account No *</div>
                        <InputValidation type={error.retypeAccountNo ? "error" : null} message={error.retypeAccountNo}>
                            <Input disabled={!isEdit} type="password" name="retypeAccountNo" defaultValue={account.retypeAccountNo} placeholder="Enter the retype account number"
                                onChange={(e) => setAccount({ ...account, retypeAccountNo: e.target.value })}
                                onBlur={() => validateAcct("retypeAcc")}
                            />
                        </InputValidation>
                    </div>
                    <div className="group">
                        <div className="label">IFSC Code *</div>
                        <InputValidation type={error.ifscCode ? "error" : null} message={error.ifscCode}>
                            <Input disabled={!isEdit} type="text" name="ifscCode" defaultValue={account.ifscCode} placeholder='eg: ICIC0006119'
                                onChange={(e) => setAccount({ ...account, ifscCode: e.target.value })}
                                onBlur={() => validateIfsc()}
                            />
                        </InputValidation>
                    </div>
                    <div className="group">
                        <div className="label">Branch *</div>
                        <InputValidation type={error.branch ? "error" : null} message={error.branch}>
                            <Input disabled={!isEdit} type="text" name="branch" defaultValue={account.branch} placeholder="Enter the branch name"
                                onChange={(e) => setAccount({ ...account, branch: e.target.value })}
                            />
                        </InputValidation>
                    </div>
                    <div className="group">
                        <div className="label">Account Type *</div>
                        <InputValidation type={error.accountType ? "error" : null} message={error.accountType}>
                            <Select disabled={!isEdit} defaultValue={account.accountType}
                                onChange={(val) => setAccount({ ...account, accountType: val })}>
                                <option value="current">Current</option>
                                <option value="savings">Savings</option>
                            </Select>
                        </InputValidation>
                    </div>
                </Col>
            </Row>
        </Drawer>
        // <Drawer
        //     width={"30%"}
        //     closable={false}
        //     onClose={props.onClose}
        //     className="account-save"
        //     visible={props.isVisible}
        //     footer={<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        //         {isEdit && <Row style={{ padding: "24px 52px" }}>
        //             <Col span={11} style={{ paddingRight: "15px" }}>
        //                 <button onClick={props.onClose} className="sp_btn cancel">Cancel</button>
        //             </Col>
        //             <Col span={2}></Col>
        //             <Col span={11} style={{ paddingLeft: "15px" }}>
        //                 {!isEdit && <button onClick={() => { setEdit(true) }} className="sp_btn edit">Edit</button>}
        //                 {isEdit && <button onClick={onHandleSubmit} className="sp_btn save">{account && account.id ? "Update" : "Add"}</button>}
        //             </Col>
        //         </Row>}
        //     </div>
        //     }>
        //     <div className="header">
        //         <ArrowLeftOutlined className="back" onClick={() => props.onClose()}></ArrowLeftOutlined>
        //         <div className="group" style={{ marginBottom: 0 }}>
        //             <div className="title">{!account.id ? 'ADD' : 'VIEW'} Bank Details</div>
        //         </div>
        //     </div>
        //     <Row style={{ height: dimensions.height - 120, overflowY: 'auto' }}>
        //         <Col span={24} className="content">
        //             <div className="group">
        //                 <div className="label">Account Holder Name *</div>
        //                 <InputValidation type={error.accountHolderName ? "error" : null} message={error.accountHolderName}>
        //                     <Input disabled={!isEdit} type="text" name="accountHolderName" defaultValue={account.accountHolderName} placeholder="Enter the account holder name"
        //                         onChange={(e) => setAccount({ ...account, accountHolderName: e.target.value })}
        //                     />
        //                 </InputValidation>
        //             </div>
        //             <div className="group">
        //                 <div className="label">Account No *</div>
        //                 <InputValidation type={error.accountNo ? "error" : null} message={error.accountNo}>
        //                     <Input disabled={!isEdit} type="text" name="accountNo" defaultValue={account.accountNo} placeholder="Enter the account number"
        //                         onChange={(e) => setAccount({ ...account, accountNo: e.target.value })}
        //                     />
        //                 </InputValidation>
        //             </div>
        //             <div className="group">
        //                 <div className="label">Retype Account No *</div>
        //                 <InputValidation type={error.retypeAccountNo ? "error" : null} message={error.retypeAccountNo}>
        //                     <Input disabled={!isEdit} type="password" name="retypeAccountNo" defaultValue={account.retypeAccountNo} placeholder="Enter the retype account number"
        //                         onChange={(e) => setAccount({ ...account, retypeAccountNo: e.target.value })}
        //                     />
        //                 </InputValidation>
        //             </div>
        //             <div className="group">
        //                 <div className="label">IFSC Code *</div>
        //                 <InputValidation type={error.ifscCode ? "error" : null} message={error.ifscCode}>
        //                     <Input disabled={!isEdit} type="text" name="ifscCode" defaultValue={account.ifscCode} placeholder="Enter the account holder name"
        //                         onChange={(e) => setAccount({ ...account, ifscCode: e.target.value })}
        //                     />
        //                 </InputValidation>
        //             </div>
        //             <div className="group">
        //                 <div className="label">Branch *</div>
        //                 <InputValidation type={error.branch ? "error" : null} message={error.branch}>
        //                     <Input disabled={!isEdit} type="text" name="branch" defaultValue={account.branch} placeholder="Enter the account holder name"
        //                         onChange={(e) => setAccount({ ...account, branch: e.target.value })}
        //                     />
        //                 </InputValidation>
        //             </div>
        //             <div className="group">
        //                 <div className="label">Account Type *</div>
        //                 <InputValidation type={error.accountType ? "error" : null} message={error.accountType}>
        //                     <Select disabled={!isEdit} defaultValue={account.accountType}
        //                         onChange={(val) => setAccount({ ...account, accountType: val })}>
        //                         <option value="current">Current</option>
        //                         <option value="saving">Saving</option>
        //                     </Select>
        //                 </InputValidation>
        //             </div>
        //         </Col>
        //     </Row>
        // </Drawer>
    );
}

export default connect(null, {})(Save);