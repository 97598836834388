import * as React from "react";

const OutletSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={17}
        viewBox="0 0 17 17"
        {...props}
    >
        <g
            id="vuesax_linear_courthouse"
            data-name="vuesax/linear/courthouse"
            transform="translate(-173.5 -189.5)"
        >
            <g id="courthouse" transform="translate(174 190)">
                <path
                    id="Vector"
                    d="M0,0H16"
                    transform="translate(0 16)"
                    fill="none"
                    stroke={props.stroke || "#fff"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M0,0A5.331,5.331,0,0,0,3.949,0V2.369A5.331,5.331,0,0,1,0,2.369Z"
                    transform="translate(8.034)"
                    fill="none"
                    stroke={props.stroke || "#fff"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M0,0V2.369"
                    transform="translate(8 2.405)"
                    fill="none"
                    stroke={props.stroke || "#fff"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-4"
                    data-name="Vector"
                    d="M10.433,0H2.408A2.111,2.111,0,0,0,0,2.369v8.687H12.841V2.369A2.111,2.111,0,0,0,10.433,0Z"
                    transform="translate(1.58 4.943)"
                    fill="none"
                    stroke={props.stroke || "#fff"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-5"
                    data-name="Vector"
                    d="M0,0H11.925"
                    transform="translate(2.038 8)"
                    fill="none"
                    stroke={props.stroke || "#fff"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-6"
                    data-name="Vector"
                    d="M0,0V7.9"
                    transform="translate(4.792 8.102)"
                    fill="none"
                    stroke={props.stroke || "#fff"}
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-7"
                    data-name="Vector"
                    d="M0,0V7.9"
                    transform="translate(7.992 8.102)"
                    fill="none"
                    stroke={props.stroke || "#fff"}
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-8"
                    data-name="Vector"
                    d="M0,0V7.9"
                    transform="translate(11.192 8.102)"
                    fill="none"
                    stroke={props.stroke || "#fff"}
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
            </g>
        </g>
    </svg>
);

export default OutletSVG;
