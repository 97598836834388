import React from "react";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";


const BulkUpload = (props: any) => {
    let {
        customTheme = null,
        isOpen,
        maxRecords = 2000,
        onClose,
        fields = null,
        onSubmit
    } = props;
    if (!customTheme) {
        customTheme = {
            colors: {
                textColor: "#2D3748",
                subtitleColor: "#718096",
                inactiveColor: "#A0AEC0",
                border: "#E2E8F0",
                background: "white",
                backgroundAlpha: "rgba(255,255,255,0)",
                secondaryBackground: "#121212",
                highlight: "#E2E8F0",
            },
            styles: {
                global: {
                    ".cui-steps__horizontal-step-container .cui-steps__step-icon-container[aria-current=step] span": {
                        color: "#121212"
                    },
                    ".cui-steps__horizontal-step[aria-disabled=false] div:nth-child(2) span": {
                        color: "#07c180"
                    },
                    ".cui-steps__horizontal-step[aria-disabled=true] div:nth-child(2) span": {
                        color: "#FFF"
                    },
                    ".cui-steps__horizontal-step-container [aria-current=step] span": {
                        color: "#FFF"
                    },
                    ".cui-steps__step-icon-container[data-highlighted]": {
                        background: "#07c180 !important",
                        borderColor: "#FFF !important"
                    },
                    ".chakra-modal__footer": {
                        background: "#FFF !important",
                    },
                    ".chakra-modal__footer button": {
                        color: "#FFF !important",
                        background: "#121212 !important"
                    },
                    ".chakra-modal__body [role=presentation] button:hover": {
                        background: "#121212c4 !important"
                    },
                    ".chakra-radio .chakra-radio__control[data-checked]": {
                        background: "#121212 !important",
                        borderColor: "#121212 !important"
                    },
                    ".chakra-modal__body [role=presentation]": {
                        backgroundImage: "repeating-linear-gradient(0deg, #121212, #121212 10px, #FFF 10px, #FFF 20px, #121212 20px),repeating-linear-gradient(90deg, #121212, #121212 10px, #FFF 10px, #FFF 20px, #121212 20px),repeating-linear-gradient(180deg, #121212, #121212 10px, #FFF 10px, #FFF 20px, #121212 20px),repeating-linear-gradient(270deg, #121212, #121212 10px, #FFF 10px, #FFF 20px, #121212 20px)"
                    },
                    ".chakra-heading .chakra-button": {
                        color: "#121212",
                        borderColor: "#121212"
                    },
                    ".chakra-portal .chakra-button[aria-label=Close modal]": {
                        "background": "#e90000",
                        "display": "flex",
                        "right": "30px",
                        "top": "34px",
                        "borderRadius": "50%",
                        "boxShadow": "0 0 50px #000",
                        "border": "2px solid #ffffff",
                    }
                }
            },
            components: {
                Modal: {
                    baseStyle: {
                        closeModalButton: {
                            "background": "#e90000",
                            "display": "flex",
                            "right": "30px",
                            "top": "34px",
                            "borderRadius": "50%",
                            "boxShadow": "0 0 50px #000",
                            "border": "2px solid #ffffff",
                        }
                    }
                },
                Button: {
                    baseStyle: {
                        borderColor: "#121212",
                    },
                    defaultProps: {
                        colorScheme: "#121212",
                    },
                },
                UploadStep: {
                    baseStyle: {
                        dropzoneButton: {
                            bg: "#121212",
                        },
                    },
                },
            },
        }
    }
    return <ReactSpreadsheetImport customTheme={customTheme} isOpen={isOpen} maxRecords={maxRecords} onClose={onClose} onSubmit={onSubmit} fields={fields} />;
};

export default BulkUpload;