import { Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import UploadSvg from '../../../../../../../assets/images/add-image.svg';
import config from "../../../../../../../apiConfig";
import ColorPalette from '../../../../../common/ColorPalette';
import CustomFileUploader from '../../../../../../common/CustomFileUploader';
import TextArea from 'antd/lib/input/TextArea';

const Banner = (props: any) => {
    const [images, setImages] = useState([]);
    const [banner, setBanner] = useState({
        background: "#000000",
        items: [],
        deleteImages: []
    })
    const [fileContent, setFileContent] = useState({});
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [deleteImages, setDeleteImages] = useState([]);
    const onFileRemove = (file) => {
        const deleteUrl = file.url.replace(config.baseImageUrl, "");
        setDeleteImages(preState => [...preState, deleteUrl]);
    }
    useEffect(() => {
        setBanner({ ...banner, deleteImages });
        //eslint-disable-next-line
    }, [deleteImages])
    const onSelectedFile = (uid) => {
        setTitle("");
        setDescription("");
        setSelectedFileId(uid);
        if (!fileContent[uid]) {
            const newObj = {};
            newObj[uid] = {
                title: "",
                desc: ""
            };
            setFileContent({
                ...fileContent,
                ...newObj
            })
        } else {
            setTitle(fileContent[uid].title);
            setDescription(fileContent[uid].desc);
        }
    }
    useEffect(() => {
        if (selectedFileId && fileContent[selectedFileId]) {
            const updateObj = {
                title: title,
                desc: description
            }
            setFileContent({ ...fileContent, [selectedFileId]: updateObj });
        }
        //eslint-disable-next-line
    }, [title, description]);
    useEffect(() => {
        onChangeFile({ fileList: banner.items });
        //eslint-disable-next-line
    }, [fileContent]);
    useEffect(() => {
        const { banner: dBanner } = props;
        if (dBanner) {
            if (dBanner.background) {
                setBanner({ ...banner, background: dBanner.background });
            }
            if (dBanner.items && dBanner.items.length > 0) {
                let updateImages = [];
                let newObj = {};
                for (let i in dBanner.items) {
                    updateImages.push({
                        uid: i,
                        title: dBanner.items[i].title,
                        description: dBanner.items[i].description,
                        name: dBanner.items[i].title,
                        type: dBanner.items[i].type,
                        status: 'done',
                        url: config.baseImageUrl + dBanner.items[i].url
                    });
                    newObj[i] = {
                        title: dBanner.items[i].title,
                        desc: dBanner.items[i].description
                    };
                }
                setFileContent({
                    ...fileContent,
                    ...newObj
                });
                setImages(updateImages);
            }
        }
        //eslint-disable-next-line
    }, [props.banner]);
    const onChangeFile = (data) => {
        let items = [];
        if (data.fileList && data.fileList.length > 0) {
            for (let i in data.fileList) {
                let title = "";
                let desc = "";
                if (fileContent[data.fileList[i].uid]) {
                    title = fileContent[data.fileList[i].uid].title;
                    desc = fileContent[data.fileList[i].uid].desc;
                }
                const item = {
                    description: desc,
                    name: data.fileList[i].name,
                    status: data.fileList[i].status === "done" ? data.fileList[i].status : "new",
                    title: title,
                    uid: data.fileList[i].uid,
                    url: data.fileList[i].url,
                    fileExt: data.fileList[i].fileExt,
                    type: data.fileList[i].type,
                    fileObj: data.fileList[i].fileObj || data.fileList[i]
                }
                items.push(item);
            }
        }
        banner.items = items;
        setBanner({ ...banner });
        if (data.selectedFileId) {
            onSelectedFile(data.selectedFileId)
        }
    }
    useEffect(() => {
        props.setBanner(banner);
        //eslint-disable-next-line
    }, [banner]);
    return (
        <div className="banner" style={{ marginBottom: 30 }}>
            <div className="header">Banner Details</div>
            <div className="section">
                {/* <div className="subtitle" style={{ justifyContent: 'flex-end' }}>
                    Set your 4 banners images here SVG, PNG or JPG  (max. 500 x 500)
                </div> */}
                <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 30 }}>
                    <div style={{ color: '#121212', fontSize: 15, marginBottom: 4 }}>Upload banner images with content</div>
                    <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', marginTop: 12 }}>
                        <CustomFileUploader onRemoveHandle={onFileRemove} onChange={onChangeFile} multiple={true} defaultFiles={images} uploadIcon={UploadSvg} uploadIconStyle={{ height: 40, objectFit: 'cover' }}></CustomFileUploader>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 22 }}>
                        <div style={{ flexGrow: 1, fontSize: 13, color: '#808080', width: '40%' }}>
                            Add banner title and short description to display
                        </div>
                        <div style={{ flexGrow: 1, width: '60%' }}>
                            <div style={{ width: '70%', marginBottom: 17 }}>
                                <div style={{
                                    fontSize: 15,
                                    color: '#808080'
                                }}>Title</div>
                                <Input style={{ width: 400 }} disabled={!selectedFileId} value={title} placeholder={"Enter Title"} onChange={(e) => setTitle(e.target.value)}></Input>

                            </div>
                            <div style={{ width: '70%' }}>
                                <div style={{
                                    fontSize: 15,
                                    color: '#808080'
                                }}>Description</div>
                                <TextArea disabled={!selectedFileId} value={description} onChange={(e) => setDescription(e.target.value)} style={{ width: 400 }} autoSize={{ minRows: 4, maxRows: 6 }} showCount maxLength={300} placeholder="Enter short description"></TextArea>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 22 }}>
                    <div>
                        <div className="title">Background Color</div>
                        <div style={{ flexGrow: 1 }} className="subtitle" >Set your section background color</div>
                    </div>
                    <div style={{ width: '60%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <ColorPalette defaultColor={banner.background} onColorChange={(val) => { setBanner({ ...banner, background: val }) }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(null, {})(Banner);