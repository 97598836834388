import React from 'react';
import { Outlet } from 'react-router-dom';

const Promotions = (props: any) => {
    // const tabMenu = useSelector((state: any) => state.ui.selectedTabMenu);
    // useEffect(() => {
    //     props.selectedMainMenu('promotions');
    //     props.selectedTabMenu('promotions');
    // }, []);
    return (
        <div className="menu">
            <Outlet></Outlet>
        </div>
    );
}

export default Promotions;