import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import "../../../../../assets/sass/dsk/settings.sass";
import { useNav } from "../../../../../helpers/customHook";

const Menus = [{
    key: 'codeinjection',
    label: 'Code Injection'
}, {
    key: 'customdomain',
    label: 'Custom Domain'
}];
const Web = (props: any) => {
    const location = useLocation();
    const navigateTo = useNav();
    const [selectedMenu, setSelectedMenu] = useState(Menus[0].key);
    useEffect(() => {
        const pathNames = location.pathname.split("/");
        const menuMatched: any = Menus.filter((m) => m.key === pathNames[pathNames.length - 1]);
        if (menuMatched.length > 0) {
            setSelectedMenu(menuMatched[0].key)
        } else {
            setSelectedMenu(Menus[0].key);
            navigateTo(Menus[0].key)
        }
        //eslint-disable-next-line
    }, [])
    return <div className="settings">
        <div className="menus">
            {Menus.map((m) => {
                return <Link to={`${m.key}`} onClick={() => setSelectedMenu(m.key)} className={selectedMenu === m.key ? "item selected" : 'item'} key={m.key} >{m.label}</Link>
            })}
        </div>
        <div className="content" style={{ height: window.innerHeight - 60 }}>
            <Outlet />
        </div>
    </div>
};

export default Web;