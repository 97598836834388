import React, { useEffect, useState } from "react";
import type from "../../../types/actionTypes";
import styled from "styled-components";
import { createSubscription, onlinePaymentFailure, onlinePaymentSuccess } from "../../../actions/setupStoreActions";
import { useDispatch, useSelector } from "react-redux";
// import ArrowLeftIcn from "../../../assets/images/arrow-left.svg";
import LockIcn from "../../../assets/images/lock.svg";
import PopupConfirm from "./PopupConfirm";
import { useNav } from "../../../helpers/customHook";
import { Switch, Select } from "antd";
import Loading from "./Loading";
import dayjs from "dayjs";
import InputValidation from "./InputValidation";
import { getStoreDetails } from "../../../actions/brandAction";
import Header from "../setupstore/Header";
import StateCodes from "../../../helpers/StateCode";
import Footer from "../setupstore/Footer";

const { Option } = Select;
const Container = styled.div`
    color: #121212;
    width: 90%;
    max-width: 357px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
`;
const Section = styled.div`
    display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .ant-switch-handle {
        width: 16px;
        height: 16px;
    }
    input {
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        border-bottom: 1px solid #A7A7A7 !important;
        border-radius: 0px;
        padding: 0px !important;
    }
    .ant-select-selection-search-input {
        border-width: 0px !important;
        border-radius: 0px !important;
    }
`;

const PayNow = styled.div`
    width: 100%;
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    background: #1A1A1A;
    box-shadow: 0px 8px 14px #BAC3D03C;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PlanDetail = styled.div`
   background: #FFFFFF;
    padding: 12px;
    box-shadow: 0px 8px 14px #BAC3D03C;
    border: 1px solid #BAC3D0;
    border-radius: 12px;
    margin-top: 20px;
    width: 100%;
    .gst {
        font-size: 13px;
        color: #5E6774;
    }     
    .total {
        font-size:  15px;
        font-weight: bold;
        color: #000000;
    }            
`;


const CheckoutPlan = () => {
    const billInfo = {
        "isRegistered": true,
        "businessName": "",
        "addressLine": "",
        "city": "",
        "state": "TN",
        "zipcode": "",
        "country": "IN",
        "taxInfo": {
            "gstin": ""
        },
        "tz": dayjs.tz.guess()
    };
    let errorInfo = {
        addressLine: {
            message: "",
            type: "",
        },
        city: {
            message: "",
            type: "",
        },
        state: {
            message: "",
            type: "",
        },
        zipcode: {
            message: "",
            type: "",
        },
        businessName: {
            message: "",
            type: ""
        },
        country: {
            message: "",
            type: ""
        }
    };
    const [error, setError] = useState(errorInfo);
    const [billInformation, setBillInformation] = useState(billInfo);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const { selectedStore, setupStoreDetails } = useSelector((state: any) => state.ui);
    const WindowObj: any = window;
    const navigateTo = useNav();
    const [isLoading, setIsLoading] = useState(false);
    const [subscription, setSubscription]: any = useState(null);
    const { plan } = setupStoreDetails || {};
    const dispatch: any = useDispatch();
    const onCreateStore = async () => {
        let isError = false;
        if (!billInformation.addressLine) {
            errorInfo.addressLine.message = "Address line is required";
            errorInfo.addressLine.type = "error";
            isError = true;
        } else {
            errorInfo.addressLine.message = "";
            errorInfo.addressLine.type = "";
        }
        if (!billInformation.city) {
            errorInfo.city.message = "City is required";
            errorInfo.city.type = "error";
            isError = true;
        } else {
            errorInfo.city.message = "";
            errorInfo.city.type = "";
        }
        if (!billInformation.state) {
            errorInfo.state.message = "State is required";
            errorInfo.state.type = "error";
            isError = true;
        } else {
            errorInfo.state.message = "";
            errorInfo.state.type = "";
        }
        if (!billInformation.zipcode) {
            errorInfo.zipcode.message = "Zipcode is required";
            errorInfo.zipcode.type = "error";
            isError = true;
        } else {
            errorInfo.zipcode.message = "";
            errorInfo.zipcode.type = "";
        }
        if (billInformation.isRegistered && !billInformation.businessName) {
            errorInfo.businessName.message = "Business name is required";
            errorInfo.businessName.type = "error";
            isError = true;
        } else {
            errorInfo.businessName.message = "";
            errorInfo.businessName.type = "";
        }
        if (!isError) {
            setIsLoading(true);
            if (plan?.id) {
                const { storeName, brandName, industryType } = setupStoreDetails;
                let payload = {
                    planId: plan.id,
                    trial: plan.name.toLowerCase() === 'growth' ? true : false,
                    signupRequest: {
                        storeName,
                        brandName,
                        industryType,
                        ...billInformation
                    }
                }
                if (!billInformation.isRegistered) {
                    delete payload.signupRequest.businessName;
                }
                if (payload.signupRequest.taxInfo.gstin === "") {
                    delete payload.signupRequest.taxInfo;
                }
                const resp = await dispatch(createSubscription(payload));
                if (resp?.hostedPage) {
                    setSubscription(resp);
                }
            }
            setIsLoading(false);
        }
        setError({ ...errorInfo });
    }
    useEffect(() => {
        if (subscription?.hostedPage) {
            payNow(subscription.hostedPage)
        }
        //eslint-disable-next-line
    }, [subscription]);
    const payNow = (hostedPage) => {
        var cbInstance = WindowObj.Chargebee.init({
            site: WindowObj.chargebee_site_name
        })
        cbInstance.openCheckout({
            hostedPage: function () {
                return new Promise((resolve, reject) => resolve(hostedPage));
            },
            success: (hostedPageId) => {
                onPaymentSuccess(hostedPageId)
            },
            failure: (hostedPageId) => {
                onPaymentFailure(hostedPageId)
            },
        });
    }

    const onPaymentSuccess = async (hostedPageId) => {
        const data = {
            "paymentGw": "chargebee",
            "cbResponse": {
                "hostedPageId": hostedPageId,
                "subscriptionId": subscription.paymentSubscriptionId
            }
        };
        const apiResp: any = await dispatch(onlinePaymentSuccess(data));
        if (apiResp?.subscriptionStatus) {
            let popupConfirmDetail = {
                title: "Store Created",
                renderContent: () => {
                    return <div style={{ color: "#000", lineHeight: 2 }} >Your store <span style={{ fontWeight: "bold" }}>
                        {setupStoreDetails.storeName || selectedStore.name}
                    </span> has been created Successfully</div>
                },
                okay: {
                    label: "Okay",
                    callback: async () => {
                        let store = setupStoreDetails;
                        if (selectedStore && (selectedStore?.subscriptionStatus === "cancelled" || selectedStore?.subscriptionStatus === "suspended")) {
                            store = selectedStore;
                        }
                        if (apiResp.sid && (apiResp.subscriptionStatus === "active" || apiResp.subscriptionStatus === "trial")) {
                            const storeDetails: any = await getStoreDetails(apiResp.sid)(dispatch);
                            console.log({ storeDetails })
                            dispatch({
                                type: type.SELECTED_STORE,
                                data: storeDetails
                            });
                        }
                        dispatch({
                            type: type.SETUP_STORE_DETAILS,
                            data: null
                        });
                        setPopupConfirm(null)
                        if (store.storeName || store.name) {
                            // navigateTo(`/${store.storeName || store.name}/orders/current`);
                            navigateTo(`/redirectToDsk`);
                        }
                    }
                }
            }
            setPopupConfirm(popupConfirmDetail)
        }
    }
    const onPaymentFailure = async (hostedPageId) => {
        const data = {
            "paymentGw": "chargebee",
            "cbResponse": {
                "hostedPageId": hostedPageId,
                "subscriptionId": subscription.paymentSubscriptionId
            }
        };
        const apiResp: any = await dispatch(onlinePaymentFailure(data));
        console.log({ apiResp })
    }
    const generateWebSiteUrl = (name, isUrl) => {
        if (isUrl) {
            return window.location.origin.replace('shopletzy', 'myshopletzy').replace('app', name) + '/landing';
        }
        return window.location.origin.replace('shopletzy', 'myshopletzy').replace('app', name);
    }
    return <div className="setup-store-mob" style={{ padding: "20px 0px 0px 0px" }}>
        <Header enableLogout={true} />
        <div className="content" style={{ flexGrow: 1 }}>
            <Container>
                <Section style={{ flexDirection: 'column' }}>
                    <Section style={{ fontWeight: 'bold', fontSize: 24, color: '#1A1A1A', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 26 }}>
                        <div>Subscribe Now</div>
                    </Section>
                    <Section>
                        {!selectedStore && <Section style={{
                            flexDirection: 'column',
                            background: '#FFFFFF',
                            boxShadow: '0px 20px 25px -5px #0000001A',
                            border: '1px solid #A0A7B1DB',
                            borderRadius: 12,
                            padding: '14px'
                        }}>
                            <Section style={{ flexDirection: 'column', width: '100%', borderBottom: '1px solid #A0A7B1' }}>
                                <Section style={{
                                    fontSize: 14,
                                    color: '#697B95',
                                    marginBottom: 15,
                                    fontWeight: 500,
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start'
                                }}>
                                    Store Name
                                </Section>
                                <Section style={{
                                    fontSize: 14,
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'start',
                                    marginBottom: 10,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start'
                                }}>
                                    <div style={{ color: '#121212' }}>
                                        {setupStoreDetails.storeName || selectedStore.name}
                                    </div>
                                    <div>
                                        <a style={{ cursor: 'pointer' }} rel="noreferrer" target={"_blank"} href={`${generateWebSiteUrl(setupStoreDetails.storeName || selectedStore.name, true)}?previewMode=true`}>
                                            {generateWebSiteUrl(setupStoreDetails.storeName || selectedStore.name, false)}
                                        </a>
                                    </div>
                                </Section>
                            </Section>
                            <Section style={{
                                flexDirection: 'column',
                                width: '100%',
                                marginTop: 14,
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start'
                            }}>
                                <div style={{
                                    fontSize: 14,
                                    color: '#697B95',
                                    paddingBottom: 12,
                                    fontWeight: 500,
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start'
                                }}>
                                    Billing Address
                                </div>
                                <div style={{ flexDirection: "row", justifyContent: 'space-between', display: 'flex', width: '100%', marginBottom: 14 }}>
                                    <div className="label">Are you a company?</div>
                                    <Switch className="toggle-opt" style={{ width: 40, height: 20, borderRadius: 50 }}
                                        checked={billInformation?.isRegistered}
                                        onClick={(val) => {
                                            setBillInformation({ ...billInformation, 'isRegistered': val })
                                        }}
                                    />
                                </div>
                                {billInformation.isRegistered && <div className="group" style={{ marginBottom: 18, width: '100%' }}>
                                    <InputValidation type={error.businessName.type} message={error.businessName.message}>
                                        <input style={{ border: '1px solid #FF0000 !important', width: '100%' }} type="text" placeholder="Enter Company Name" onChange={(e) => { setBillInformation({ ...billInformation, 'businessName': e.target.value }) }} />
                                    </InputValidation>
                                </div>}
                                <Section style={{
                                    flexDirection: 'column',
                                    width: '100%',
                                    fontSize: 14,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}>
                                    <Section style={{ color: '#121212', width: '100%', marginBottom: 18 }}>
                                        <InputValidation type={error.addressLine.type} message={error.addressLine.message} style={{ width: '100%' }}>
                                            <input style={{ border: '1px solid #FF0000 !important', width: '100%', marginBottom: 0 }} type="text" placeholder="Enter Address line*" onChange={(e) => { setBillInformation({ ...billInformation, 'addressLine': e.target.value }) }} />
                                        </InputValidation>
                                    </Section>
                                    <Section style={{ color: '#121212', width: '100%', marginBottom: 18, flexDirection: 'row' }}>
                                        <div style={{ width: '50%', marginRight: 12 }}>
                                            <InputValidation type={error.city.type} message={error.city.message}>
                                                <input style={{ border: '1px solid #FF0000 !important', width: '100%', marginBottom: 0 }} type="text" placeholder="City*" onChange={(e) => { setBillInformation({ ...billInformation, 'city': e.target.value }) }} />
                                            </InputValidation>
                                        </div>
                                        <div style={{ width: '50%', marginLeft: 12, }}>
                                            <InputValidation type={error.state.type} message={error.state.message}>
                                                <Select
                                                    style={{
                                                        width: '100%',
                                                        borderWidth: 0,
                                                        borderRadius: 0,
                                                        borderBottom: '1px solid #A7A7A7',
                                                        height: 44
                                                    }}
                                                    showSearch
                                                    defaultValue={'TN'}
                                                    filterOption={(inputValue, option) =>
                                                        option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                                                    }
                                                    onChange={(value) => setBillInformation({ ...billInformation, 'state': value })}  >
                                                    {StateCodes && StateCodes.map((b) => {
                                                        return <Option key={b.stateCode} value={b.stateCode} style={{ textTransform: 'capitalize' }}>{b.state}</Option>
                                                    })}
                                                </Select>
                                            </InputValidation>
                                        </div>
                                    </Section>
                                    <Section style={{ width: '100%', flexDirection: 'row' }}>
                                        <div style={{ width: '50%', marginRight: 12 }}>
                                            <InputValidation type={error.zipcode.type} message={error.zipcode.message}>
                                                <input style={{ border: '1px solid #FF0000 !important', width: '100%', marginBottom: 0 }} type="text" placeholder="Zipcode*" onChange={(e) => { setBillInformation({ ...billInformation, 'zipcode': e.target.value }) }} />
                                            </InputValidation>
                                        </div>
                                        <div style={{ width: '50%', marginLeft: 12 }}>
                                            <InputValidation type={error.country.type} message={error.country.message}>
                                                <input style={{ border: '1px solid #FF0000 !important', width: '100%', marginBottom: 0 }} disabled type="text" placeholder="Country" value={"India"} />
                                            </InputValidation>
                                        </div>
                                    </Section>
                                    <Section style={{ color: '#121212', width: '100%' }}>
                                    </Section>
                                </Section>
                            </Section>
                            <Section style={{
                                flexDirection: 'column', width: '100%', marginTop: 30, alignItems: 'flex-start',
                                justifyContent: 'flex-start'
                            }}>
                                <Section style={{
                                    fontSize: 14,
                                    color: '#5E6774',
                                    fontWeight: 500,
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start'
                                }}>
                                    GST Number
                                </Section>
                                <input style={{
                                    width: 'inherit',
                                }} type="text" placeholder="Enter GST Number" />
                            </Section>
                        </Section>}
                        <Section style={{ flexDirection: 'column', marginTop: 20 }}>
                            <Section style={{
                                width: '100%',
                                height: 60,
                                boxShadow: '0px 8px 14px #80B5FD1A',
                                border: '1px solid #34E5C1',
                                borderRadius: 12,
                                padding: '0px 14px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                background: '#FFFFFF',
                                alignItems: 'center',
                                flexDirection: 'row'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        width: 26,
                                        height: 26,
                                        background: '#FFFFFF',
                                        border: '2px solid #34E5C1',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginRight: 14
                                    }}>
                                        <div style={{
                                            width: 14,
                                            height: 14,
                                            background: '#34E5C1',
                                            borderRadius: 50
                                        }} />
                                    </div>
                                    <div style={{
                                        fontWeight: 600,
                                        fontSize: 20,
                                        color: '#1A1A1A',
                                        textTransform: 'capitalize'
                                    }}>
                                        {plan?.name}
                                    </div>
                                </div>
                                <div style={{
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#171923',
                                    display: 'flex',
                                    alignItems: 'baseline'
                                }}>
                                    ₹{plan?.planFee / 100}
                                    <div style={{ fontSize: 14, marginLeft: 4 }}>/mo</div>
                                </div>
                            </Section>
                            <PlanDetail>
                                <Section style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                }}>
                                    <div className="gst">Tax</div><div className="gst">₹{plan?.tax / 100}</div>
                                </Section>
                                <Section style={{
                                    flexDirection: 'row', marginTop: 16, alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}>
                                    <div className="total">Total Amount</div>
                                    <div className="total">₹{plan?.total / 100}</div>
                                </Section>
                            </PlanDetail>
                            <Section style={{ marginTop: 30 }}>
                                <PayNow onClick={onCreateStore}>
                                    {plan?.trialDays > 0 ? "Start 30 Days Free Trial" : "Proceed"}
                                </PayNow>
                            </Section>
                            <Section style={{
                                marginTop: 12,
                                fontSize: 10,
                                color: '#5E6774',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                width: '100%',
                                margin: '12px 0px 20px 0px'
                            }}>
                                <img alt="arrow" style={{ width: 10, height: 13, marginRight: 4 }} src={LockIcn} />
                                <div>Payments are secure and encrypted</div>
                            </Section>
                        </Section>
                    </Section>
                </Section>
                {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
                {isLoading && <Loading background="#00000070"></Loading>}
            </Container>
        </div>
        <Footer></Footer>
    </div>
}

export default CheckoutPlan;