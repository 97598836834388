import * as React from "react";
import { connect } from "react-redux";
import { Button } from 'antd';
import { withTranslation } from 'react-i18next';
import "../../../assets/sass/dsk/popupConfirm.sass";

const COLORCODE = {
    error: {
        box: {
            backgroundColor: "#FFF",
            color: "#000",
        },
        title: {
            color: "#FF0000"
        },
        buttonNo: {
            backgroundColor: "#595B69",
            color: "#fff"
        },
        buttonYes: {
            backgroundColor: "#FFF",
            color: "#000"
        },
        buttonOkay: {
            backgroundColor: "#000",
            color: "#FFF"
        }
    },
    info: {
        box: {
            backgroundColor: "#FFF",
            color: "#000",
        },
        title: {
            color: "#FFF"
        },
        buttonNo: {
            backgroundColor: "#FFF",
            color: "#000"
        },
        buttonYes: {
            backgroundColor: "#000",
            color: "#FFF"
        },
        buttonOkay: {
            backgroundColor: "#000",
            color: "#FFF"
        }
    }
}
class PopupConfirm extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { config } = this.props;
        const theme = config && (config.type ? COLORCODE[config.type] : COLORCODE["info"]);
        // const { t } = this.props;
        return (config && <div className={`popup ${config.rootClassName}`}>
            <div className="container">
                <div className="box" style={{ ...theme.box }}>
                    {config.title && <div className="title">{typeof config.title == "string" ? config.title : config.title()}</div>}
                    {config.content && <div className="content">{config.content}</div>}
                    {config.renderContent && typeof config.renderContent == "function" && <div className="content">{config.renderContent()}</div>}
                    <div className="action">
                        {config.no && typeof config.no.callback == "function" && <Button className="sp_btn no" style={{ ...theme.buttonNo, ...config.no.style }} onClick={config.no.callback}>{config.no.label}</Button>}
                        {config.okay && typeof config.okay.callback == "function" && <Button className="sp_btn okay" style={{ ...theme.buttonOkay, ...config.okay.style }} onClick={config.okay.callback}>{config.okay.label}</Button>}
                        {config.yes && typeof config.yes.callback == "function" && <Button className="sp_btn yes" style={{ ...theme.buttonYes, ...config.yes.style }} onClick={config.yes.callback}>{config.yes.label}</Button>}
                    </div>
                </div>
            </div>
        </div>);
    }
}
const mapStateToProps = (state: any) => {
    return {};
};
export default connect(mapStateToProps, {})(withTranslation()(PopupConfirm));
