import { useEffect, useState } from "react";
import SearchIcn from "../../../assets/images/search.svg"
import OptionsEmptySvg from "../../../assets/images/variant-options-empty.svg";
import StockEmptySvg from "../../../assets/images/variant-stock-empty.svg";
import DeleteSvg from "../../../assets/images/settings/trash.svg";
import VariantNameSelectionSvg from "../../../assets/images/variant-name-selection.svg";
import UploadIcon from "../../../assets/images/gallery-add.svg";
import { Input, Select } from "antd";
import PopupConfirm from "../common/PopupConfirm";
import CreateOptions from "./createOptions";
import { SortableList } from "./sortableList";
import { MediaAssets } from "../common/mediaAssets";
import { areObjectsDifferent, toLowerCaseKeysAndValues, uuidv4 } from "../../../helpers/util";
import InputValidation from "../common/InputValidation";
import { onUploadMedia } from '../../../actions/commonAction';
import { useDispatch } from "react-redux";

const { Option } = Select;
const Variants = (props: any) => {
    const errorInfo = {
        price: {
            message: "",
            type: ""
        },
        salePrice: {
            message: "",
            type: ""
        }
    };
    const dispatch = useDispatch();
    const { isEdit, selectedProduct } = props;
    const [error, setError] = useState(errorInfo);
    const [variantSearch, setVariantSearch] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [media, setMedia] = useState([]);
    // const [options, setOptions] = useState([]);
    const [addOption, setAddOption] = useState(false);
    const [selectedGroupBy, setSelectedGroupBy] = useState(null);
    const [editVariant, setEditVariant] = useState(null);
    // const [variantLabelSelection, setVariantLabelSelection] = useState(null);
    const [selectedVariantGroup, setSelectedVariantGroup] = useState(null);
    const [variantCombinations, setVariantCombinations]: any = useState(null);
    const [isEnableDelete, setEnableDelete] = useState(false);
    const [prePopulatedVariant, setPrePopulatedVariant] = useState(null);
    // const [variantOptions, setVariantOptions] = useState([]);
    // const [addVariants, setAddVariants] = useState([]);
    const [sku, setSku] = useState("");
    // const [hsnCode, setHSNCode] = useState("");
    const [updatePrice, setUpdatePrice] = useState({
        price: 0,
        salePrice: 0
    });
    const onDelete = (deleteItem: any) => {
        setPopupConfirm({
            title: `Delete Option`,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to {<span style={{ fontWeight: "bold" }}> delete </span>} the <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>{deleteItem.name}</span> option.</span></div > },
            yes: {
                label: "Delete",
                callback: async () => {
                    const copyAddVariants = JSON.parse(JSON.stringify(props.variants));
                    // for (let dItem of deleteItem.values) {
                    const key = deleteItem.name.toLocaleLowerCase();
                    // const value = dItem.name.toLocaleLowerCase();
                    for (let variant of copyAddVariants) {
                        const vOpt = toLowerCaseKeysAndValues(variant.options);
                        delete vOpt[key]
                        variant.options = vOpt;
                    }
                    // }
                    const uniqueVariantOptions = copyAddVariants.filter((option, index, self) =>
                        index === self.findIndex((o) => JSON.stringify(o.options) === JSON.stringify(option.options))
                    );
                    props.setVariants(uniqueVariantOptions);
                    const index = props.options.findIndex((i) => i.id === deleteItem.id);
                    props.options.splice(index, 1);
                    props.setOptions([...props.options]);
                    setSelectedVariantGroup(null);
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "Cancel",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }
    const updateNewOptions = (variants, newVariantOption) => {
        let updatedVariants = [];
        //variant based updated if variant option created by user
        for (let variant of variants) {
            if (variant.options) {
                const vOpt = toLowerCaseKeysAndValues(variant.options);
                // console.log("newVariantOption: ", newVariantOption.name)
                if (!vOpt[newVariantOption.name.toLocaleLowerCase()]) {
                    for (let value of newVariantOption.values) {
                        let newVariant = JSON.parse(JSON.stringify(variant));
                        newVariant.options[newVariantOption.name] = value.name;
                        updatedVariants.push(newVariant);
                    }
                }
            }
        }
        props.setVariants(updatedVariants);
    }
    const onSaveOption = (data) => {
        const formattedData = {
            id: data.id || uuidv4(),
            name: data.name,
            values: data.values.filter((value) => value.name.trim() !== "")
        }
        // console.log(":::::: onSaveOption ", data)
        if (editVariant) {
            onUpdateVariantOptions(data);
            setEditVariant(null);
        } else {
            if (props.variants) {
                updateNewOptions(props.variants, formattedData);
            }
        }
        if (props.options === null || props.options.length === 0) {
            props.setOptions([formattedData]);
        } else {
            props.setOptions(() => {
                const index = props.options.findIndex((i) => i.id === data.id);
                if (index !== -1) {
                    props.options[index] = formattedData;
                } else {
                    props.options.push(formattedData);
                }
                return [...props.options];
            });
        }
        setAddOption(false);
    }

    const onUpdateVariantOptions = (updateOptions) => {
        const copyAddVariants = JSON.parse(JSON.stringify(props.variants));
        for (let variant of copyAddVariants) {
            if (variant.options) {
                const vOpt = variant.options;
                // const vOpt = toLowerCaseKeysAndValues(variant.options);
                if (vOpt[updateOptions.name.toLowerCase()]) {
                    for (let vOption of updateOptions.values) {
                        if (vOption.original && vOption.original === vOpt[updateOptions.name]) {
                            vOpt[updateOptions.name] = vOption.name;
                        }
                    }
                }
                variant.options = vOpt;
            }
        }
        setSelectedVariantGroup(null);
        props.setVariants(copyAddVariants);
    }

    // useEffect(() => {
    //     if (addOption) {
    //         setEditVariant(null);
    //     }
    // }, [addOption]);

    useEffect(() => {
        if (props.options) {
            props.setOptions(props.options);
        }
        if (props.variants) {
            props.setVariants(props.variants);
        }
        // eslint-disable-next-line
    }, [props?.options && props?.variants]);

    useEffect(() => {
        if (selectedGroupBy && props?.options) {
            //&& option.values.filter(value => variantSearch.length === 0 || value.name.indexOf(variantSearch) > -1).length > 0
            const orderByOption = props.options.filter(option => option.id === selectedGroupBy);
            const otherOptions = props.options.filter(option => option.id !== selectedGroupBy);
            setVariantCombinations({
                orderByOption,
                otherOptions: function getCombinations(data) {
                    return data.reduce((acc, category) => {
                        const newAcc = [];
                        acc.forEach(combo => {
                            category.values.forEach(value => {
                                newAcc.push({
                                    ...combo,
                                    [category.name]: value.name
                                });
                            });
                        });
                        return newAcc;
                    }, [{}]);
                }(otherOptions)
            });
        }
        // if (props?.options && props?.options.length > 0) {
        //     props.setVariants(onGenerateVariantOptions());
        // }
        // eslint-disable-next-line
    }, [selectedGroupBy, props.options]);

    useEffect(() => {
        if (props.options && props.options.length > 0) {
            setSelectedGroupBy(props.options[0].id);
        } else if (props.options === null && props.variants === null) {
            setAddOption(false);
            setEditVariant(null);
            setSelectedGroupBy(null);
            setSelectedVariantGroup(null);
            setVariantCombinations(null);
            setEnableDelete(false);
            setUpdatePrice({
                price: 0,
                salePrice: 0
            });
            // setHSNCode("");
            setSku("");
            setMedia([]);
            setVariantSearch(null);
            setPopupConfirm(null);
            setError(errorInfo);
            setPrePopulatedVariant(null);
        }
        // console.log({ "Options": props.options, "Variants": props.variants });
        //eslint-disable-next-line
    }, [props.options, props.variants]);

    // const onGenerateVariantOptions = () => {

    //     const optionNames = props.options.map(option => option.name.toLowerCase());
    //     const optionValues = props.options.map(option => option.values.map(value => value.name));

    //     // Generate the Cartesian product of option values
    //     const cartesianProduct = optionValues.reduce((acc, curr) =>
    //         acc.flatMap(a => curr.map(c => [...a, c])), [[]]
    //     );

    //     // Create variants from Cartesian product
    //     return cartesianProduct.map(values => {
    //         const variant = { price: 0, salePrice: 0, sku: "", media: [], options: {} };
    //         values.forEach((value, index) => {
    //             variant.options[optionNames[index]] = value;
    //         });
    //         return variant;
    //     });

    //     // console.log("Generate Variant Options", JSON.stringify(props.options));
    //     // console.log("Generate Variant Options", JSON.stringify(props.variants));
    // }

    const onSearchVariantOptions = (orderByName, optionNames) => {
        let isShow = ((!variantSearch || variantSearch.trim().length === 0) || (variantSearch.trim() && variantSearch.trim().length > 0 && orderByName.toLowerCase().indexOf(variantSearch.toLowerCase()) > -1));
        for (let option of optionNames) {
            for (let key in option) {
                if (option[key]?.toLowerCase().indexOf(variantSearch?.toLowerCase()) > -1) {
                    isShow = true;
                    break;
                }
            }
        }
        return isShow;
    }

    const generatePermutation = (data, orderBy) => {
        return <>
            {data.map((item, index) => {
                let options = {};
                options[orderBy.optionName] = orderBy.name;
                let title = `${orderBy.name}`;
                for (let key in item) {
                    title += ` / ${item[key]}`;
                    options[key] = item[key];
                }
                const isShow = ((!variantSearch || variantSearch.trim().length === 0) || title.toLowerCase().indexOf(variantSearch.toLowerCase()) > -1);
                // const isDeletedItem = props.variants && props.variants.filter((variant) => {
                //     if (variant?.isDeleted === true) {
                //         if (!areObjectsDifferent(variant.options, options)) {
                //             return true;
                //         }
                //     }
                // });
                const isDeletedItem = props.variants?.filter(
                    (variant) => variant?.isDeleted === true && !areObjectsDifferent(variant.options, options)
                );

                return isShow && <div
                    key={`${index}_value`}
                    className={`permutation-combination-items ${title === selectedVariantGroup?.title ? 'selected-permutation-combination-item' : ''} ${isDeletedItem?.length > 0 ? 'delete-variant' : ''} `}
                    onClick={() => {
                        setSelectedVariantGroup({
                            item,
                            orderBy,
                            title
                        })
                    }}>
                    {Object.keys(item).map((key) => {
                        return item[key] && <div key={`${key}_value`} className="permutation-combination-item">
                            {item[key]}
                        </div>
                    })}
                </div>
            })}
        </>
    }

    const onSelectedGroupVariant = (val) => {
        setSelectedVariantGroup({
            item: val,
            orderBy: null,
            title: val.name
        })
    }

    const generateVariantCombinations = () => {
        let groupVariantSelection: any = null;
        if (selectedVariantGroup && !selectedVariantGroup.orderBy && selectedVariantGroup.item && selectedVariantGroup.item.name) {
            groupVariantSelection = selectedVariantGroup.item.name;
        }
        if (variantCombinations && variantCombinations.orderByOption && variantCombinations.orderByOption.length > 0) {
            return variantCombinations.orderByOption[0]?.values.map((val, index) => {
                const isStopRenderPermutation = variantCombinations.otherOptions.length === 1 && Object.keys(variantCombinations.otherOptions[0]).length === 0;
                const isShow = onSearchVariantOptions(val.name, variantCombinations.otherOptions);
                return isShow && <div key={`${val.id}_variant_combination`} className="group-by-name">
                    <div>
                        <div className="name" style={{ color: val.name === groupVariantSelection ? '#188FFC' : '#121212' }}>
                            {val.name}
                            <img src={VariantNameSelectionSvg} alt="Variant Name Selection" onClick={() => onSelectedGroupVariant(val)} />
                        </div>
                        {!isStopRenderPermutation && <div className="permutation-combination">
                            {generatePermutation(variantCombinations.otherOptions, { ...val, optionName: variantCombinations.orderByOption[0].name })}
                        </div>}
                    </div>
                    {/* index !== variantCombinations.orderByOption[0].values.length - 1 &&  */}
                    {<div className="divider" style={{ background: val.name === groupVariantSelection ? '#188FFC' : '#D9D9D9', height: val.name === groupVariantSelection ? 2 : 1 }}></div>}
                </div>
            })
        }
    }

    const loadImages = () => {
        let imageList = [];
        for (let url of media) {
            imageList.push({
                url,
                id: uuidv4()
            })
        }
        return imageList;
    }

    const onUploadFiles = (fileList) => {
        const uploadPromises = fileList.map(async (item) => {
            const formData = new FormData();
            formData.append('files', item.originFileObj);
            formData.append('forUse', 'mediaFiles');
            const resp = await onUploadMedia(formData)(dispatch);
            return resp;
        });
        Promise.all(uploadPromises)
            .then((resp: any) => {
                let urls = [];
                if (resp && resp.length > 0) {
                    urls = resp.map((r) => {
                        return r.files && r.files.length > 0 && r.files[0]
                    });
                    setMedia([...media, ...urls]);
                }
            })
            .catch(error => {
                // Handle errors if any
                console.error("Error uploading files:", error);
                return { error: true }
            });
    }

    useEffect(() => {
        if (editVariant) {
            setAddOption(true);
        }
        // eslint-disable-next-line
    }, [editVariant]);

    useEffect(() => {
        if (variantCombinations && variantCombinations.orderByOption && variantCombinations.orderByOption.length > 0 && selectedVariantGroup) {
            const copySelectedOption = JSON.parse(JSON.stringify(variantCombinations.orderByOption[0]));
            const copyOtherOptions = JSON.parse(JSON.stringify(variantCombinations.otherOptions));
            const copySelectedVariantGroup = JSON.parse(JSON.stringify(selectedVariantGroup));
            let allOptionSelected: any = null;
            if (copySelectedVariantGroup && copySelectedVariantGroup.orderBy) {
                allOptionSelected = copySelectedVariantGroup.item;
                allOptionSelected[copySelectedOption.name] = copySelectedVariantGroup.orderBy.name;
            } else {
                allOptionSelected = copyOtherOptions.map((item) => {
                    return { [copySelectedOption.name]: copySelectedVariantGroup.item.name, ...item }
                });
            }
            if (allOptionSelected && allOptionSelected.length > 0) {
                setPrePopulatedVariant(allOptionSelected[0]);

            } else {
                setPrePopulatedVariant(allOptionSelected);
            }
        }
        // eslint-disable-next-line
    }, [selectedVariantGroup]);


    useEffect(() => {
        if (prePopulatedVariant) {
            onSelectProductData(prePopulatedVariant);
        }
        // eslint-disable-next-line
    }, [prePopulatedVariant]);

    const onSelectProductData = (newOptions) => {
        let existVariant = null;
        if (props.variants) {
            for (let variant of props.variants) {
                //Selected only the first option. If we have multiple options, we need to compare all options
                // console.log({ "Variant": variant.options, "New Options": newOptions });
                if (!areObjectsDifferent(variant.options, newOptions)) {
                    existVariant = variant;
                    break;
                }
            }
        }
        setEnableDelete(false);
        setUpdatePrice({
            price: 0,
            salePrice: 0
        });
        // setHSNCode("");
        setSku("");
        setMedia([]);
        if (existVariant) {
            setUpdatePrice({
                price: existVariant?.price,
                salePrice: existVariant?.salePrice,
            });
            // setHSNCode(existVariant?.hsnCode);
            setSku(existVariant?.sku);
            if (existVariant.media && existVariant.media.length > 0) {
                setMedia([...existVariant.media]);
            } else {
                setMedia([]);
            }
            if (!existVariant.isDeleted || existVariant?.isDeleted === false) {
                setEnableDelete(true);
            }
        } else if (selectedProduct) {
            let priceDetails = {
                price: 0,
                salePrice: 0
            };
            if (selectedProduct.price) {
                priceDetails.price = selectedProduct.price;
            }
            if (selectedProduct.salePrice) {
                priceDetails.salePrice = selectedProduct.salePrice;
            }
            setUpdatePrice(priceDetails);
            if (selectedProduct.images) {
                setMedia(selectedProduct.images);
            }
        }
    };

    useEffect(() => {
        if (selectedVariantGroup && !prePopulatedVariant && variantCombinations) {
            const copyAddVariants = JSON.parse(JSON.stringify(props.variants));
            const copySelectedOption = JSON.parse(JSON.stringify(variantCombinations.orderByOption[0]));
            const copyOtherOptions = JSON.parse(JSON.stringify(variantCombinations.otherOptions));
            const copySelectedVariantGroup = JSON.parse(JSON.stringify(selectedVariantGroup));
            if (updatePrice.price && updatePrice.salePrice && !copySelectedVariantGroup.orderBy) {
                let opts = {
                    [copySelectedOption.name]: copySelectedVariantGroup.item.name
                };
                if (props.variants === null || props.variants.length === 0) {
                    for (let item of copyOtherOptions) {
                        props.setVariants([{
                            price: updatePrice.price,
                            salePrice: updatePrice.salePrice,
                            // hsnCode: hsnCode,
                            sku: sku,
                            media: media,
                            options: { ...item, ...opts }
                        }]);
                    }
                } else {
                    const allOptionSelected = copyOtherOptions.map((item) => {
                        return { [copySelectedOption.name]: copySelectedVariantGroup.item.name, ...item }
                    });
                    let updateAddVariants = [];
                    let isUpdated = false;
                    for (let item of allOptionSelected) {
                        for (let addVar of copyAddVariants) {
                            if (!areObjectsDifferent(addVar.options, item)) {
                                isUpdated = true;
                                addVar.price = updatePrice.price;
                                addVar.salePrice = updatePrice.salePrice;
                                addVar.media = media;
                                // addVar.hsnCode = hsnCode;
                                addVar.sku = sku;
                                addVar.options = item;
                            }
                        }
                        if (!isUpdated) {
                            updateAddVariants.push({
                                price: updatePrice.price,
                                salePrice: updatePrice.salePrice,
                                // hsnCode: hsnCode,
                                sku: sku,
                                media: media,
                                options: item
                            });
                        }
                    }
                    props.setVariants(copyAddVariants.concat(updateAddVariants));
                }
            } else if (updatePrice.price && updatePrice.salePrice) {
                let opts = copySelectedVariantGroup.item;
                if (copySelectedVariantGroup.orderBy) {
                    opts[copySelectedVariantGroup.orderBy.optionName] = copySelectedVariantGroup.orderBy.name;
                }
                if (props?.variants?.length === 0) {
                    props.setVariants([{
                        price: updatePrice.price,
                        salePrice: updatePrice.salePrice,
                        // hsnCode: hsnCode,
                        sku: sku,
                        media: media,
                        options: opts
                    }]);
                } else if (copyAddVariants) {
                    let updated = false;
                    const newVariant = copyAddVariants.map((item) => {
                        if (!areObjectsDifferent(item.options, opts)) {
                            updated = true;
                            delete item.isDeleted;
                            return {
                                ...item,
                                price: updatePrice.price,
                                salePrice: updatePrice.salePrice,
                                media: media,
                                // hsnCode: hsnCode,
                                sku: sku,
                            };
                        }
                        return item;
                    });
                    if (!updated) {
                        props.setVariants([
                            ...props.variants,
                            {
                                price: updatePrice.price,
                                salePrice: updatePrice.salePrice,
                                // hsnCode: hsnCode,
                                sku: sku,
                                media: media,
                                options: opts
                            },
                        ]);
                    } else {
                        props.setVariants(newVariant);
                    }
                }
            }
        } else {
            setPrePopulatedVariant(null);
        }
        // eslint-disable-next-line
    }, [media, updatePrice, sku]);

    const onDeleteVariant = () => {
        const copySelectedOption = JSON.parse(JSON.stringify(variantCombinations.orderByOption[0]));
        const copyOtherOptions = JSON.parse(JSON.stringify(variantCombinations.otherOptions));
        const copySelectedVariantGroup = JSON.parse(JSON.stringify(selectedVariantGroup));
        const copyAddVariants = JSON.parse(JSON.stringify(props.variants));
        if (updatePrice.price && updatePrice.salePrice && !copySelectedVariantGroup.orderBy) {
            const opts = copyOtherOptions.map((item) => {
                return { [copySelectedOption.name]: copySelectedVariantGroup.item.name, ...item }
            });
            for (let opt of opts) {
                for (let addVar of copyAddVariants) {
                    if (!areObjectsDifferent(addVar.options, opt)) {
                        let index = copyAddVariants.indexOf(addVar);
                        copyAddVariants.splice(index, 1);
                        // copyAddVariants[index].isDeleted = true;
                    }
                }
            }
            props.setVariants(copyAddVariants);
        } else if (updatePrice.price && updatePrice.salePrice) {
            let opts = copySelectedVariantGroup.item;
            if (copySelectedVariantGroup.orderBy) {
                opts[copySelectedVariantGroup.orderBy.optionName] = copySelectedVariantGroup.orderBy.name;
            }
            const newVariant = props.variants.filter((item) => {
                if (!areObjectsDifferent(item.options, opts)) {
                    item.isDeleted = true;
                    item.price = 0;
                    item.salePrice = 0;
                }
                return item;
            });
            props.setVariants(newVariant);
        }
        setEnableDelete(false);
        setUpdatePrice({
            price: 0,
            salePrice: 0
        });
        // setHSNCode("");
        setSku("");
        setMedia([]);
    }

    const onEditVariant = (item) => {
        for (let i of item.values) {
            i.original = i.name;
        }
        setEditVariant(item);
    }

    return <div className="product-variants">
        <div className="options">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: props.options && props.options.length > 0 ? 15 : 0 }}>
                <div className="title">Options</div>
                {isEdit && <div className="add-option" onClick={() => { setEditVariant(null); setAddOption(true); }}>+ Add Option</div>}
            </div>
            {props.options && <div className="items">
                <SortableList
                    items={props.options}
                    onChange={props.setOptions}
                    renderItem={(item: any) => (
                        <SortableList.Item id={item.id}>
                            <div className="option" >
                                {isEdit && <SortableList.DragHandle />}
                                <div className="data" onClick={() => isEdit && onEditVariant({ ...item })}>
                                    <div className="name">{item.name}</div>
                                    <div className="values">{
                                        item.values && item.values.map((vItem) => {
                                            return <div key={vItem.id} className="value">{vItem.name}</div>
                                        })
                                    }</div>
                                </div>
                                {isEdit && <img src={DeleteSvg} data-no-dnd="true" alt="delete" style={{ marginLeft: 16 }} onClick={(e) => onDelete(item)} />}
                            </div>
                        </SortableList.Item>
                    )}
                />
            </div>}
        </div>
        <div className="content">
            <div className="variants-combination">
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                    <div className="title">Variants</div>
                    {props.options && props.options.length > 0 && <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                        <div className="group-by">Group by</div>
                        <Select className="group-by-select" disabled={!isEdit} value={selectedGroupBy} onChange={(value) => { setSelectedGroupBy(value); setSelectedVariantGroup(null); }}>
                            {props.options && props.options.map((item) => {
                                return <Option key={item.id} value={item.id}>{item.name}</Option>
                            })}
                        </Select>
                    </div>}
                </div>
                <Input className="filter" name="productSearch" onChange={(event) => setVariantSearch(event.target.value)} placeholder="Search variants" suffix={<img alt='Search Icon' src={SearchIcn} />} />
                {variantCombinations && <div className="group-by-values">{generateVariantCombinations()}</div>}
            </div>
            <div className="selected-variant">
                <div className="title">{selectedVariantGroup?.title}</div>
                <div className="media">
                    {selectedVariantGroup && <>
                        <div className="title" style={{ marginBottom: 15 }}>Media</div>
                    </>}
                    {selectedVariantGroup === null && <div className="empty">
                        <img src={OptionsEmptySvg} alt="Options Empty" />
                        <div className="title" style={{ margin: "25px 0px 8px 0px" }}>Options</div>
                        <div className="desc">The options and their values are displayed here.</div>
                    </div>}
                    {selectedVariantGroup && <MediaAssets isEdit={isEdit} multiple={true}
                        key="product-media"
                        defaultValue={loadImages()}
                        onSort={(items) => {
                            let urls = items.map((item) => {
                                return item.url
                            });
                            setMedia([...urls]);
                        }}
                        uploadIcon={UploadIcon}
                        onSubmit={async ({ fileList, selectedPreIcon }) => {
                            let urls = [];
                            if (selectedPreIcon && selectedPreIcon.length > 0) {
                                urls = selectedPreIcon.map((item) => {
                                    return item.url
                                });
                            }
                            if (fileList && fileList.length > 0) {
                                onUploadFiles(fileList)
                            } else {
                                setMedia([...media, ...urls]);
                            }
                        }}
                        onRemove={(item) => {
                            let newImages = media.filter((img) => img !== item.url);
                            setMedia(newImages);
                        }} />}
                </div>
                <div className="pricing">
                    {selectedVariantGroup === null ? <div className="empty">
                        <img src={StockEmptySvg} alt="Stock Empty" />
                        <div className="title" style={{ margin: "25px 0px 8px 0px" }}>Price, Selling Price, and Stock </div>
                        <div className="">Pricing and selling price, inventory of the product are displayed here</div>
                    </div> : <>
                        <div className="title">Price</div>
                        <div style={{ display: 'flex', gap: 10 }}>
                            <div className="group">
                                <div className="label">Price *</div>
                                <InputValidation type={error.price.type} message={error.price.message}>
                                    <Input type="number" disabled={!isEdit} name="price" min="1" value={updatePrice.price} placeholder="₹0"
                                        onChange={(e) => setUpdatePrice({ ...updatePrice, [e.target.name]: parseFloat(e.target.value) })}
                                    />
                                </InputValidation>
                            </div>
                            <div className="group">
                                <div className="label">Sale Price *</div>
                                <InputValidation type={error.price.type} message={error.price.message}>
                                    <Input type="number" disabled={!isEdit} name="salePrice" min="1" value={updatePrice.salePrice} placeholder="₹0"
                                        onChange={(e) => setUpdatePrice({ ...updatePrice, [e.target.name]: parseFloat(e.target.value) })}
                                    />
                                </InputValidation>
                            </div>
                        </div>
                    </>}
                </div>
                {selectedVariantGroup !== null && <div className="inventory">
                    <div className="title">Inventory</div>
                    {/* <div className="group" style={{ width: 365 }}>
                        <div>
                            <div className="label" >HSN Code</div>
                        </div>
                        <div>
                            <Input type='number' disabled={!isEdit} className="value" value={hsnCode} onChange={(e: any) => {
                                setHSNCode(e.target.value);
                            }} />
                        </div>
                    </div> */}
                    <div className="group" style={{ width: 365 }}>
                        <div>
                            <div className="label" >Sku</div>
                        </div>
                        <div>
                            <Input className="value" disabled={!isEdit} value={sku} onChange={(e) =>
                                setSku(e.target.value.replace(/ /g, "_").toLocaleUpperCase())
                            } />
                        </div>
                    </div>
                </div>}
                {isEdit && isEnableDelete && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div style={{
                        borderRadius: 4,
                        background: '#C70039',
                        color: '#fff',
                        padding: '8px 18px',
                        fontSize: 13,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 600,
                        cursor: 'pointer',
                    }} onClick={onDeleteVariant}>Delete</div>
                </div>}
            </div>
        </div>
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        {addOption && <CreateOptions data={JSON.parse(JSON.stringify(editVariant))} onSubmit={onSaveOption} onClose={() => {
            setAddOption(false);
            // setEditVariant(null);
        }} />}
    </div>
}

export default Variants;