import * as React from "react";

function Sales(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="49" viewBox="0 0 53 49">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_17264" data-name="Rectangle 17264" width="30.45" height="30.45" fill="#fff" />
                </clipPath>
            </defs>
            <g id="sales" transform="translate(-0.613 -0.7)">
                <g id="Group_10725" data-name="Group 10725" transform="translate(0.613 0.701)">
                    <rect id="Rectangle_17263" data-name="Rectangle 17263" width="53" height="49" rx="5" transform="translate(0 0)" fill="rgba(250,88,46,0.15)" />
                    <g id="money-send" transform="translate(12.039 12.04)">
                        <g id="Group" transform="translate(10.714 8.45)">
                            <path id="Vector" d="M0,5.955A1.937,1.937,0,0,0,1.891,7.936H4.019A1.686,1.686,0,0,0,5.66,6.2,1.5,1.5,0,0,0,4.54,4.562L1.132,3.374A1.487,1.487,0,0,1,.011,1.732,1.693,1.693,0,0,1,1.653,0H3.781A1.937,1.937,0,0,1,5.672,1.981" transform="translate(0 1.121)" fill="none" stroke="#fa582e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                            <path id="Vector-2" data-name="Vector" d="M0,0V10.189" transform="translate(2.83)" fill="none" stroke="#fa582e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                        </g>
                        <path id="Vector-3" data-name="Vector" d="M22.561,11.28A11.28,11.28,0,1,1,11.28,0" transform="translate(2.264 2.264)" fill="none" stroke="#fa582e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                        <path id="Vector-4" data-name="Vector" d="M4.528,4.528V0H0" transform="translate(20.296 2.264)" fill="none" stroke="#fa582e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                        <path id="Vector-5" data-name="Vector" d="M0,5.66,5.66,0" transform="translate(19.164 2.264)" fill="none" stroke="#fa582e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                        <path id="Vector-6" data-name="Vector" d="M0,0H27.089V27.089H0Z" fill="none" opacity="0" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Sales
