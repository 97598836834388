import React, { useEffect, useState } from "react";
import LeftNav from "../common/LeftNav";
import Header from "../common/Header";
import { connect, useSelector } from "react-redux";
import { Row, Col } from "antd";
import { handleLeftDrawerVisibility } from "../../../actions/commonAction";
import { getOutlets } from '../../../actions/outletAction';
import { Outlet, useLocation } from "react-router-dom";
import StoreSuspended from "../setupstore/StoreSuspended";
import Overlay from "../billing/Overlay";

const Main = (props: any) => {
    const location = useLocation();
    const [, store, currentMainMenu, currentSubMenu, ...currentSubCategories] = location.pathname.split("/");
    const AllowStoreSuspended = ['billing']
    // const collapsed = true;
    const innerHeight = window.innerHeight;
    const { selectedStore } = useSelector((state: any) => state.ui);
    const { selectedStoreDetails } = useSelector((state: any) => state.common);

    const [shopServiceStop, setShopServiceStop] = useState(false);
    // const { info } = useSelector((state: any) => state.auth);
    // React.useEffect(() => {
    //     setCollapsed(leftMenuToggle);
    // }, [leftMenuToggle]);

    // useEffect(() => {
    //     setOutlets(outletList)
    // }, [outletList]);

    useEffect(() => {
        if (selectedStore && (selectedStore.subscriptionStatus === "cancelled" || selectedStore.subscriptionStatus === "suspended")) {
            setShopServiceStop(true);
        } else {
            setShopServiceStop(false);
        }
        // if (selectedStore && selectedStore.id) {
        // props.getOutlets();
        // }
        //eslint-disable-next-line
    }, [selectedStore])
    return (
        <div style={{ height: "100vh", display: 'flex', }}>
            {selectedStoreDetails?.paymentDue && <Overlay {...props}></Overlay>}
            <div style={{ width: 70 }}><LeftNav {...{ store, currentMainMenu, currentSubMenu }} /></div>
            <div style={{ width: 'calc(100% - 70px)' }}>
                <Row
                    style={{
                        height: "72px",
                        borderBottom: "1px solid #d7d9e5",
                        margin: "0px",
                        boxShadow: "0px 3px 6px #00000014"
                    }}
                >
                    {/* outlets && outlets.length > 0 &&  */}
                    <Col span={24}>{<Header {...{ ...props, ...{ store, currentMainMenu, currentSubMenu, currentSubCategories, edgeMenu: currentSubCategories[currentSubCategories.length - 1] } }}></Header>}</Col>
                </Row>
                <Row
                    style={{
                        // background: "#FAFAFA 0% 0% no-repeat padding-box",
                        overflow: "hidden",
                        height: innerHeight - 72,
                        margin: "0px",
                        position: "relative",
                    }}
                >
                    <Col span={24}>
                        <Outlet context={[store, currentMainMenu, currentSubMenu]} />
                    </Col>
                    {AllowStoreSuspended.indexOf(currentMainMenu) === -1 && shopServiceStop && <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backdropFilter: 'blur(4px)',
                    }}>
                        <StoreSuspended />
                    </div>}
                </Row>
            </div>
        </div>
    );
}
// }

export default connect(
    null,
    {
        handleLeftDrawerVisibility,
        getOutlets
    }
)(Main);
