import { Input, Switch, Popover } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { getStoreDetails, onUpdateSiteSettings } from "../../../../../actions/brandAction";
import "../../../../../assets/sass/dsk/settings.sass";
import { ThemeContext } from "../../theme";
import { loadSiteSettings, onCreatePayload } from "../../themeService";
import MarkdownViewer from "../../../common/MarkdownViewer";

const Checkout = (props: any) => {
    const dispatch = useDispatch();
    const context = useContext(ThemeContext);
    const [data, setdata] = useState(null);
    const [minOrderValue, setMinOrderValue] = useState(null);
    const [paymentModes, setPaymentModes] = useState([]);
    const [actionEnable, setActionEnable] = useState([]);
    const [pricingInclusiveOfTax, setPricingInclusiveOfTax] = useState(true);
    const [autoConfirmOrders, setAutoConfirmOrders] = useState({
        codOrders: false,
        onlineOrders: false
    });
    const [deliveryDisabled, setDeliveryDisabled] = useState(false);
    const [storePickupDisabled, setStorePickupDisabled] = useState(false);
    useEffect(() => {
        getData();
        //eslint-disable-next-line
    }, []);
    const getData = async () => {
        const resp: any = await getStoreDetails()(dispatch);
        const { data, paymentModes: paymentModesResp }: any = loadSiteSettings(resp, "paymentModes", context);
        const { minOrderValue: minOrderValueResp }: any = loadSiteSettings(resp, "minOrderValue", context);
        const { autoConfirmOrders: autoConfirmOrdersResp }: any = loadSiteSettings(resp, "autoConfirmOrders", context);
        const { pricingInclusiveOfTax: pricingInclusiveOfTaxResp }: any = loadSiteSettings(resp, "pricingInclusiveOfTax", context);
        const { deliveryDisabled: deliveryDisabledResp }: any = loadSiteSettings(resp, "deliveryDisabled", context);
        const { storePickupDisabled: storePickupDisabledResp }: any = loadSiteSettings(resp, "storePickupDisabled", context);
        setdata(data);
        if (minOrderValueResp) {
            setMinOrderValue(minOrderValueResp);
        }
        if (paymentModesResp) {
            setPaymentModes(paymentModesResp);
        }
        if (autoConfirmOrdersResp) {
            setAutoConfirmOrders(autoConfirmOrdersResp);
        }
        setPricingInclusiveOfTax(pricingInclusiveOfTaxResp)
        setDeliveryDisabled(deliveryDisabledResp)
        setStorePickupDisabled(storePickupDisabledResp)
    }

    const onPaymentMode = (payMode) => {
        const index = paymentModes.indexOf(payMode);
        if (index > -1) {
            setPaymentModes((preState) => preState.filter((_, i) => i !== index))
        } else {
            setPaymentModes(prevState => [...prevState, payMode])
        }
    }

    // const onAutoConfirmOrders = (payMode, val) => {
    //     setAutoConfirmOrders({ ...autoConfirmOrders, [payMode]: val });
    // }

    const onSave = async () => {
        if (actionEnable.length > 0) {
            let siteSettings = {
                theme: {
                    web: data.theme.web,
                    mobile: data.theme.mobile
                },
                codeInjection: data.codeInjection,
                aboutUs: data.aboutUs,
                social: data.social,
                paymentModes: paymentModes || [],
                // pricingInclusiveOfTax: pricingInclusiveOfTax,
                // autoConfirmOrders: {
                //     codOrders: autoConfirmOrders.codOrders,
                //     onlineOrders: autoConfirmOrders.onlineOrders
                // },
                deliveryDisabled,
                storePickupDisabled
            }
            if (minOrderValue) {
                siteSettings['minOrderValue'] = parseFloat(minOrderValue);
            }
            const payload = onCreatePayload(siteSettings, "all")
            const themeUpdateResp: any = await onUpdateSiteSettings(payload)(dispatch);
            if (themeUpdateResp) {
                setActionEnable([])
                getData();
            }
        }
    }

    useEffect(() => {
        setActionEnable(prevState => [...prevState, 'pricingInclusiveOfTax'])
    }, [pricingInclusiveOfTax])

    useEffect(() => {
        if (data) {
            const { paymentModes: paymentModesResp } = data;
            var difference = paymentModes.filter(x => !paymentModesResp.includes(x));
            if (difference.length > 0 || paymentModes.length !== paymentModesResp.length) {
                const index = actionEnable.indexOf('paymentModes');
                if (index === -1) {
                    setActionEnable(prevState => [...prevState, 'paymentModes'])
                }
            } else {
                const index = actionEnable.indexOf('paymentModes');
                setActionEnable((preState) => preState.filter((_, i) => i !== index))
            }
        }
        //eslint-disable-next-line
    }, [paymentModes]);

    useEffect(() => {
        if (data) {
            const { autoConfirmOrders: autoConfirmOrdersResp } = data;
            if (autoConfirmOrdersResp.codOrders !== autoConfirmOrders.codOrders || autoConfirmOrdersResp.onlineOrders !== autoConfirmOrders.onlineOrders) {
                const index = actionEnable.indexOf('autoConfirmOrders');
                if (index === -1) {
                    setActionEnable(prevState => [...prevState, 'autoConfirmOrders'])
                }
            } else {
                const index = actionEnable.indexOf('autoConfirmOrders');
                setActionEnable((preState) => preState.filter((_, i) => i !== index))
            }
        }
        //eslint-disable-next-line
    }, [autoConfirmOrders]);

    useEffect(() => {
        if (data && minOrderValue) {
            const { minOrderValue: minOrderValueResp } = data;
            var difference = minOrderValue !== minOrderValueResp;
            if (difference && minOrderValue.length !== 0) {
                const index = actionEnable.indexOf('minOrderValue');
                if (index === -1) {
                    setActionEnable(prevState => [...prevState, 'minOrderValue'])
                }
            } else {
                const index = actionEnable.indexOf('minOrderValue');
                setActionEnable((preState) => preState.filter((_, i) => i !== index))
            }
        }
        //eslint-disable-next-line
    }, [minOrderValue])

    return <div className="settings">
        <div className="content" style={{ flexDirection: 'row' }}>
            <div className="container" style={{ flexGrow: 1, margin: '0px 24px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {actionEnable.length > 0 && <button className="sp_btn" style={{ width: 'auto' }} onClick={onSave}>Save</button>}
                </div>
                <div style={{ height: window.innerHeight - 80 }}>
                    {/* <div className="division">
                        <div className="header">Auto Confirmation</div>
                        <div className="section">
                            <div className="title">
                                Auto Confirm Orders
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ flexGrow: 1 }} className="subtitle">Turning on this will automatically accept orders from customers</div>
                            </div>
                            <div style={{ marginTop: 37 }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: 26
                                }}>
                                    <div className="toggle_title" style={{ width: '36vw' }}>
                                        <label>Cash on Delivery Orders</label>
                                    </div>
                                    <Switch className="toggle-opt" checked={autoConfirmOrders.codOrders} onChange={(val) => onAutoConfirmOrders('codOrders', val)}></Switch>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div className="toggle_title" style={{ width: '36vw' }}>
                                        <label>Online Payment Orders</label>
                                    </div>
                                    <Switch className="toggle-opt" checked={autoConfirmOrders.onlineOrders} onChange={(val) => onAutoConfirmOrders('onlineOrders', val)}></Switch>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="division" style={{ display: "none" }}>
                        <div className="header">Tax</div>
                        <div className="section">
                            <div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div className="title" style={{ width: '36vw', display: 'flex' }}>
                                        <label>Inclusive of Tax</label>
                                        <Popover content={
                                            <div style={{ width: 200 }}>
                                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                                            </div>
                                        } >
                                            <div style={{
                                                marginLeft: 7,
                                                width: 20,
                                                borderRadius: 30,
                                                height: 20,
                                                color: '#A0A7B1',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid #A0A7B1',
                                                fontSize: 14,
                                                paddingTop: 2,
                                                fontWeight: 800
                                            }}>i</div>
                                        </Popover>
                                    </div>
                                    <Switch className="toggle-opt" checked={pricingInclusiveOfTax} onChange={(val) => setPricingInclusiveOfTax(val)}></Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="division">
                        <div className="header">Payment Modes</div>
                        <div className="section">
                            {/* <div className="title" >
                            Payment Modes
                        </div> */}
                            <div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div className="title" style={{ width: '36vw' }}>
                                        <label>Cash on Delivery</label>
                                    </div>
                                    <Switch className="toggle-opt" checked={paymentModes.indexOf('pod') > -1} onChange={(val) => onPaymentMode('pod')}></Switch>
                                </div>
                                <div style={{ marginTop: 2 }} className="key">Allows your customer to pay at door step</div>
                            </div>
                            <div style={{ marginTop: 26 }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div className="title" style={{ width: '36vw' }}>
                                        <label>Online Payments</label>
                                    </div>
                                    <Switch className="toggle-opt" checked={paymentModes.indexOf('online') > -1} onChange={(val) => onPaymentMode('online')}></Switch>
                                </div>
                                <div style={{ marginTop: 2 }} className="key">Allows your customer to pay online via credit cards / debit cards, UPI</div>
                            </div>
                        </div>
                    </div>
                    <div className="division">
                        <div className="header">Order Value</div>
                        <div className="section">
                            <div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{ width: '36vw' }}>
                                        <div className="title">Minimum Order Value</div>
                                        <div style={{ marginTop: 2 }} className="key">Say about your minimum order value</div>
                                    </div>
                                    <Input
                                        style={{ width: 210 }}
                                        prefix="₹"
                                        value={minOrderValue}
                                        name="minOrderValue"
                                        placeholder="Enter the min order value"
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                                setMinOrderValue(value);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="division">
                        <div className="header">Order Delivery / Store Pickup</div>
                        <div className="section">
                            <div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div className="title" style={{ width: '36vw' }}>
                                        <label>Delivery Disabled</label>
                                    </div>
                                    <Switch className="toggle-opt" checked={deliveryDisabled} onChange={(val) => setDeliveryDisabled(val)}></Switch>
                                </div>
                                <div style={{ marginTop: 2 }} className="key">Order delivery at customer's place</div>
                            </div>
                            <div style={{ marginTop: 26 }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div className="title" style={{ width: '36vw' }}>
                                        <label>Store Pickup Disabled</label>
                                    </div>
                                    <Switch className="toggle-opt" checked={storePickupDisabled} onChange={(val) => setStorePickupDisabled(val)}></Switch>
                                </div>
                                <div style={{ marginTop: 2 }} className="key">Order pickup by customer from store</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    height: window.innerHeight - 72,
                    overflowY: 'auto',
                    width: '26%',
                    background: '#FFFFFF',
                    boxShadow: '0px 3px 16px #DBE2EA6C',
                    flexShrink: 0
                }}>
                <MarkdownViewer fileName={'ConfiguringPaymentModesOrderValueDeliveryOptions'} />
            </div>
        </div>
    </div>
};

export default Checkout;