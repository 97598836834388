import { Input } from "antd";
import React, { useState } from "react";

const GoogleAds = () => {
    const [googleAdValue, setGoogleAdValue] = useState(null);
    // const [actionEnable, setActionEnable] = useState([]);
    // const onSave = async () => {

    // }
    return <div className="settings">
        <div className="content" style={{ flexDirection: 'row' }}>
            <div className="container" style={{ flexGrow: 1, margin: '0px 24px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* {actionEnable.length > 0 && <button className="sp_btn" style={{ width: 'auto' }} onClick={onSave}>Save</button>} */}
                </div>
                <div style={{ height: window.innerHeight - 80 }}>
                    <div className="division">
                        <div className="header">GoogleAd</div>
                        <div className="section">
                            <div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{ width: '36vw' }}>
                                        <div className="title">GoogleAd</div>
                                        <div style={{ marginTop: 2 }} className="key">Lorem ipsum dolor sit amet, consetetur sadipscing elitr</div>
                                    </div>
                                    <Input
                                        style={{ width: 210 }}
                                        // prefix={<img src={WhatsappSvg} />}
                                        value={googleAdValue}
                                        name="GoogleAd"
                                        // placeholder="Enter the min order value"
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                                setGoogleAdValue(value);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="container"
                style={{
                    padding: '12px 18px',
                    height: '100%',
                    width: '26%',
                    background: '#FFFFFF',
                    boxShadow: '0px 3px 16px #DBE2EA6C',
                    flexShrink: 0
                }}>
                <div className="header" style={{ marginBottom: 12 }}>Google Ad</div>
                <div className="title" style={{ marginBottom: 12 }}>Lorem ipsum dolor sit amet</div>
                <div className="value" style={{ marginBottom: 12, textAlign: 'justify' }}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    <br />
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                </div>
            </div>
        </div>
    </div>
}

export default GoogleAds;