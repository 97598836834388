const StateCodes = [
    { "state": "Andaman and Nicobar Islands", "code": "35", "stateCode": "AN" },
    { "state": "Andhra Pradesh", "code": "28", "stateCode": "AP" },
    { "state": "Andhra Pradesh (New)", "code": "37", "stateCode": "AD" },
    { "state": "Arunachal Pradesh", "code": "12", "stateCode": "AR" },
    { "state": "Assam", "code": "18", "stateCode": "AS" },
    { "state": "Bihar", "code": "10", "stateCode": "BH" },
    { "state": "Chandigarh", "code": "04", "stateCode": "CH" },
    { "state": "Chattisgarh", "code": "22", "stateCode": "CT" },
    { "state": "Dadra and Nagar Haveli", "code": "26", "stateCode": "DN" },
    { "state": "Daman and Diu", "code": "25", "stateCode": "DD" },
    { "state": "Delhi", "code": "07", "stateCode": "DL" },
    { "state": "Goa", "code": "30", "stateCode": "GA" },
    { "state": "Gujarat", "code": "24", "stateCode": "GJ" },
    { "state": "Haryana", "code": "06", "stateCode": "HR" },
    { "state": "Himachal Pradesh", "code": "02", "stateCode": "HP" },
    { "state": "Jammu and Kashmir", "code": "01", "stateCode": "JK" },
    { "state": "Jharkhand", "code": "20", "stateCode": "JH" },
    { "state": "Karnataka", "code": "29", "stateCode": "KA" },
    { "state": "Kerala", "code": "32", "stateCode": "KL" },
    { "state": "Lakshadweep Islands", "code": "31", "stateCode": "LD" },
    { "state": "Madhya Pradesh", "code": "23", "stateCode": "MP" },
    { "state": "Maharashtra", "code": "27", "stateCode": "MH" },
    { "state": "Manipur", "code": "14", "stateCode": "MN" },
    { "state": "Meghalaya", "code": "17", "stateCode": "ME" },
    { "state": "Mizoram", "code": "15", "stateCode": "MI" },
    { "state": "Nagaland", "code": "13", "stateCode": "NL" },
    { "state": "Odisha", "code": "21", "stateCode": "OR" },
    { "state": "Pondicherry", "code": "34", "stateCode": "PY" },
    { "state": "Punjab", "code": "03", "stateCode": "PB" },
    { "state": "Rajasthan", "code": "08", "stateCode": "RJ" },
    { "state": "Sikkim", "code": "11", "stateCode": "SK" },
    { "state": "Tamil Nadu", "code": "33", "stateCode": "TN" },
    { "state": "Telangana", "code": "36", "stateCode": "TS" },
    { "state": "Tripura", "code": "16", "stateCode": "TR" },
    { "state": "Uttar Pradesh", "code": "09", "stateCode": "UP" },
    { "state": "Uttarakhand", "code": "05", "stateCode": "UT" },
    { "state": "West Bengal", "code": "19", "stateCode": "WB" }
];

export default StateCodes;