import React, { useEffect, useRef, useState } from "react";
import Details from "../order/Details";
import { onUpdateOrderStatus } from "../../../actions/uiAction";
import { getCustomerOrders } from "../../../actions/customerActions";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANTS } from "../../../constants";
import EmptyBoxJson from '../../../assets/lotties/empty-box.json';
import LottiePlayer from 'lottie-web';
import dayjs from "dayjs";
import { Input } from "antd";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
// import Loading from "../common/Loading";
import { clearCustomerOrders } from "../../../actions/commonAction";

const Orders = (props: any) => {
    const notAvailableRef = useRef(null);
    const { id } = useParams();
    const [hasMore, setHasMore] = useState(true);
    // const [cusId, setCusId] = useState(null);
    const WindowHeight = window.innerHeight - 76;
    const { customerOrders } = useSelector((state: any) => state.customer);
    const { orderStatusUpdated } = useSelector((state: any) => state.ui);
    const [orderId, setOrderId] = useState(null);
    const [searchOrderId, setSearchOrderId] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    // const [customer, setCustomer] = useState(null);
    const [orders, setOrders] = useState(null);
    const [filterOrders, setFilterOrders] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setFilterOrders(customerOrders);
        setOrders(customerOrders);
        if (customerOrders.length > 0) {
            setOrderId(orderId || customerOrders[0].id)
        }
        //eslint-disable-next-line
    }, [customerOrders])

    useEffect(() => {
        onUpdateOrderStatus(false)(dispatch);
        return () => {
            dispatch(clearCustomerOrders());
            setOrderId(null);
            setSearchOrderId(null);
            setCustomerId(null);
            setOrders(null);
            setFilterOrders([]);
        };
        //eslint-disable-next-line
    }, [])

    const loadCustomerOrders = async (customerId, isFreshList = false) => {
        const resp = await getCustomerOrders(customerId, isFreshList)(dispatch);
        if (resp?.orders.length === 0) {
            setHasMore(false)
        }
    }
    useEffect(() => {
        if (orderStatusUpdated) {
            loadCustomerOrders(customerId);
            onUpdateOrderStatus(false)(dispatch);
        }
        //eslint-disable-next-line
    }, [orderStatusUpdated]);
    useEffect(() => {
        onFilterUpdate();
        //eslint-disable-next-line
    }, [orders]);
    useEffect(() => {
        onFilterUpdate();
        //eslint-disable-next-line
    }, [searchOrderId]);
    const onFilterUpdate = () => {
        let filterResults = [];
        // let isOrderId = false;
        if (searchOrderId) {
            for (let o of orders) {
                if (o.orderNo.toLowerCase().indexOf(searchOrderId.toLowerCase()) > -1) {
                    filterResults.push(o);
                }
            }
            filterResults.sort(function (a: any, b: any) {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            });
            setFilterOrders(filterResults);
        } else {
            setFilterOrders(orders || []);
        }
    }
    useEffect(() => {
        let instance = null;
        if (customerOrders && customerOrders.length === 0) {
            instance = LottiePlayer.loadAnimation({
                container: notAvailableRef.current,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: EmptyBoxJson
            });
        }
        // Return clean up function here
        return () => instance?.destroy();
    }, [customerOrders])
    useEffect(() => {
        setCustomerId(id)
        loadCustomerOrders(id, true);
        // getCustomerSearch({ cusId: id })(dispatch);
        //eslint-disable-next-line
    }, [id]);

    const fetchNext = () => {
        if (hasMore) {
            loadCustomerOrders(customerId)
        }
    }

    // useEffect(() => {
    //     if (customerId && customers.length > 0) {
    //         for (let c in customers) {
    //             if (customers[c].id === customerId) {
    //                 setCustomer(customers[c])
    //             }
    //         }
    //     }
    // }, [customerId, customers])
    return <div className="customer-orders" style={{ height: WindowHeight }}>
        {(orders && orders.length === 0) && <div style={{
            display: "flex",
            justifyContent: 'center',
            height: '80vh',
            alignItems: 'center',
            width: '100%'
        }}>
            <div style={{ height: '100%', display: 'flex', flexGrow: 1, justifyContent: "center", flexDirection: 'column', alignItems: "center" }} ref={notAvailableRef} >
            </div>
        </div>}
        {orders && orders.length > 0 && <div style={{
            display: "flex",
            justifyContent: 'space-between',
            background: '#FFFFFF',
            borderRadius: 7,
            flexDirection: 'column'
        }}>
            <div style={{
                display: "flex",
                justifyContent: 'flex-start',
                padding: 16,
                flexDirection: 'column'
            }}>
                <div
                    style={{
                        marginBottom: 8
                    }}>
                    <div style={{
                        fontWeight: 600,
                        color: '#188FFC'
                    }}>Cutomer Details</div>
                </div>
                <div>
                    <Input type="text" placeholder={"Search order id"} onChange={(e) => setSearchOrderId(e.target.value)}></Input>
                </div>
            </div>
            <div style={{
                overflowY: 'auto',
                display: "flex",
                width: 330,
                flexDirection: 'column',
                height: WindowHeight - 100
            }} id="scrollableDiv">
                <InfiniteScroll
                    dataLength={filterOrders.length}
                    hasMore={hasMore}
                    next={fetchNext}
                    loader={<div style={{ display: "flex", alignItems: "center", height: 40, marginLeft: 18, width: 'inherit' }} >
                        Loading...
                    </div>}
                    endMessage={<div style={{ display: "flex", alignItems: "center", justifyContent: 'center', height: 'auto' }} >Nothing more</div>}
                    scrollableTarget="scrollableDiv">
                    {filterOrders.map((o, i) => {
                        return <div key={o.id + "_" + i} className="card"
                            onClick={() => { setOrderId(o.id) }}
                            style={{
                                boxShadow: `0px 3px 6px ${o.id === orderId ? '#0051FF1A' : '#B5BFCC4A'}`,
                                border: `1px solid ${o.id === orderId ? '#0051FF' : '#B5BFCC'}`
                            }}>
                            <div style={{
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <div style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <div className="label">Order No: </div>
                                    <div className="value">{o.orderNo}</div>
                                </div>
                                <div>
                                    <div className="value"
                                        title={CONSTANTS.OrderStatus.labels[o.status] || o.status}
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            textAlign: 'center',
                                            width: 100,
                                            background: CONSTANTS.OrderStatus.colors[o.status] + 22,
                                            padding: "0px 8px",
                                            borderRadius: 4,
                                            color: CONSTANTS.OrderStatus.colors[o.status]
                                        }}>{CONSTANTS.OrderStatus.labels[o.status] || o.status}</div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginTop: 14,
                                marginBottom: 2
                            }}>
                                <div style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <div className="label">Paid: </div>
                                    <div className="value">{o?.billing?.toPay?.toLocaleString('en-IN')}</div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <div className="label">No of Items: </div>
                                    <div className="value">{o.items?.length}</div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <div style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <div className="label">Payment Mode: </div>
                                    <div className="value" style={{ textTransform: 'uppercase' }}>{o.payment && o.payment.mode}</div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <div className="label">
                                        {dayjs(o.createdAt).format('MMM')} {dayjs(o.createdAt).format('DD')},{dayjs(o.createdAt).format('YYYY')}, {dayjs(o.createdAt).format('hh:mm A')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </InfiniteScroll>
            </div>
        </div>}
        {orderId && <div style={{ flexGrow: 1 }}>
            {orderId && <Details orderId={orderId} disableBack={true}></Details>}
        </div>}
    </div>
}

export default Orders;