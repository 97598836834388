import type from "../types/actionTypes";

const initialState = {
    customers: [],
    totalCount: 0,
    customerOrders: [],
    customerLastPageItemTime: null,
    orderLastPageItemTime: null
};

export default function customerReducers(state = initialState, action) {
    switch (action.type) {
        case type.GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                ...{
                    totalCount: action.data.totalCount,
                    customerLastPageItemTime: action.data.customers && action.data.customers.length > 0 ? action.data.customers[action.data.customers.length - 1].createdAt : state.customerLastPageItemTime,
                    customers: action.data.isFreshList ? action.data.customers : [...state.customers, ...action.data.customers]
                }
            }
        case type.GET_CUSTOMER_ORDERS_SUCCESS:
            return {
                ...state,
                ...{
                    orderLastPageItemTime: action.data.customerOrders && action.data.customerOrders.length > 0 ? action.data.customerOrders[action.data.customerOrders.length - 1].createdAt : state.orderLastPageItemTime,
                    customerOrders: action.data.isFreshList ? action.data.customerOrders : [...state.customerOrders, ...action.data.customerOrders]
                }
            }
        case type.CLEAR_CUSTOMER_ORDERS:
            return {
                ...state,
                ...{
                    orderLastPageItemTime: null,
                    customerOrders: []
                }
            }
        case type.SEARCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: action.data.customers
            }
        case type.GET_CUSTOMERS_ERROR:
            return {
                ...state,
                message: action.error
            }
        // case type.GET_ORDERS_BY_CUSTOMER_SUCCESS:
        //     return {
        //         ...state,
        //         orders: action.data.orders
        //     }
        // case type.GET_ORDERS_BY_CUSTOMER_ERROR:
        //     return {
        //         ...state,
        //         message: action.error
        //     }

        // case type.GET_ORDER_DETAILS_SUCCESS:
        //     return {
        //         ...state,
        //         orderDetails: action.data
        //     }
        // case type.GET_ORDER_DETAILS_ERROR:
        //     return {
        //         ...state,
        //         message: action.error
        //     }
        default:
            return state;
    }
}