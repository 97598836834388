import { Input } from 'antd';
import React, { useState, useEffect } from 'react';
// import { connect } from "react-redux";
import PasteSvg from "../../../../../../assets/images/bx-scan.svg";
import { useDispatch } from "react-redux";
import { getStoreDetails, onUpdateSiteSettings } from "../../../../../../actions/brandAction";
import Loading from '../../../../common/Loading';

const SocialLinks = (props: any) => {
    const dispatch = useDispatch();
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [twitter, setTwitter] = useState("");
    const [youtube, setYoutube] = useState("");
    const [pinterest, setPinterest] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getSocialLinks();
        //eslint-disable-next-line
    }, [])

    const getSocialLinks = async () => {
        setIsLoading(true);
        const resp: any = await getStoreDetails()(dispatch);
        setIsLoading(false);
        const { settings } = resp;
        if (settings) {
            const { socialLinks } = settings;
            if (socialLinks) {
                if (socialLinks.facebook) {
                    setFacebook(socialLinks.facebook);
                }
                if (socialLinks.instagram) {
                    setInstagram(socialLinks.instagram);
                }
                if (socialLinks.twitter) {
                    setTwitter(socialLinks.twitter);
                }
                if (socialLinks.youtube) {
                    setYoutube(socialLinks.youtube);
                }
                if (socialLinks.pinterest) {
                    setPinterest(socialLinks.pinterest);
                }
            }
        }
    }

    const onPaste = async (elementName) => {
        const link = await navigator.clipboard.readText();
        if (elementName === "facebook") {
            setFacebook(link);
        } else if (elementName === "instagram") {
            setInstagram(link);
        } else if (elementName === "twitter") {
            setTwitter(link);
        } else if (elementName === "youtube") {
            setYoutube(link);
        } else if (elementName === "pinterest") {
            setPinterest(link);
        }
    }

    const onSave = async () => {
        const payload = {
            socialLinks: {
                facebook,
                twitter,
                instagram,
                pinterest,
                youtube
            }
        }
        if (payload) {
            setIsLoading(true);
            await onUpdateSiteSettings(payload)(dispatch);
            setIsLoading(false);
        }
    }

    return (<div>
        {isLoading && <Loading background="#00000070"></Loading>}
        <div className="container">
            <div className="header">
                Set your store social links
            </div>
            <div className='key'>Configure the store site status & settings.</div>
            <div className="section">
                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 14 }}>
                    <button style={{ width: 160, fontSize: 14 }} onClick={onSave} className="sp_btn" >Save Changes</button>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 26
                }}>
                    <div className="title" style={{ width: 150 }}>
                        <label>Facebook</label>
                    </div>
                    <Input defaultValue={facebook} value={facebook} onChange={(e) => setFacebook(e.target.value)} className="input" placeholder="Enter or paste Facebook url here" suffix={<img alt='Paste' src={PasteSvg} onClick={() => onPaste('facebook')} />} style={{ width: '60%', marginRight: 20 }} />
                    {/* <Switch></Switch> */}

                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 26
                }}>
                    <div className="title" style={{ width: 150 }}>
                        <label>Instagram</label>
                    </div>
                    <Input defaultValue={instagram} value={instagram} onChange={(e) => setInstagram(e.target.value)} className="input"
                        placeholder="Enter or paste Instagram url here" suffix={<img alt='Paste' src={PasteSvg} onClick={() => onPaste('instagram')}
                        />}
                        style={{ width: '60%', marginRight: 20 }} />
                    {/* <Switch></Switch> */}

                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 26
                }}>
                    <div className="title" style={{ width: 150 }}>
                        <label>Twitter</label>
                    </div>
                    <Input defaultValue={twitter} value={twitter} onChange={(e) => setTwitter(e.target.value)} className="input" placeholder="Enter or paste Twitter url here" suffix={<img alt='Paste' src={PasteSvg} onClick={() => onPaste('twitter')} />} style={{ width: '60%', marginRight: 20 }} />
                    {/* <Switch></Switch> */}
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 26
                }}>
                    <div className="title" style={{ width: 150 }}>
                        <label>Youtube</label>
                    </div>
                    <Input defaultValue={youtube} value={youtube} onChange={(e) => setYoutube(e.target.value)} className="input"
                        placeholder="Enter or paste Youtube url here" suffix={<img alt='Paste' src={PasteSvg} onClick={() => onPaste('youtube')}
                        />}
                        style={{ width: '60%', marginRight: 20 }} />
                    {/* <Switch></Switch> */}

                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 26
                }}>
                    <div className="title" style={{ width: 150 }}>
                        <label>Pinterest</label>
                    </div>
                    <Input defaultValue={pinterest} value={pinterest} onChange={(e) => setPinterest(e.target.value)} className="input"
                        placeholder="Enter or paste Pinterest url here" suffix={<img alt='Paste' src={PasteSvg} onClick={() => onPaste('pinterest')}
                        />}
                        style={{ width: '60%', marginRight: 20 }} />
                    {/* <Switch></Switch> */}

                </div>
            </div>
        </div>
    </div>
    );
}

export default SocialLinks;