import React, { useEffect, useState } from 'react';
// import { SearchOutlined, FlagFilled, EllipsisOutlined } from "@ant-design/icons";
// import { getL10N, loadOutletBasedProduct } from "../../../../../helpers/middleware";
import { Row, Col, Input } from 'antd';
import { getOutlets } from "../../../../../actions/outletAction";
import { clearSaveOutlet } from "../../../../../actions/commonAction";
// import View from './View';
// import Save from './Save';
// import Delete from './Delete';
import '../../../../../assets/sass/dsk/outlet.sass';
import SearchIcn from "../../../../../assets/images/search.svg"
// import viewIcn from '../../../../../assets/images/view.svg';
// import deleteIcn from '../../../../../assets/images/editv1_ico.svg';
// import editIcn from '../../../../../assets/images/edit.png';
import Add from './Add';
import SortUpSvg from "../../../../../assets/images/sort_up.svg";
import SortDownSvg from "../../../../../assets/images/sort_down.svg";
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNav } from '../../../../../helpers/customHook';
dayjs.extend(calendar);
// const menus = [{
//     key: "ViewProduct",
//     value: "View Product"
// }, {
//     key: "remove",
//     value: "Remove"
// }]

const ListedOutlets = (props: any) => {
    const navigateTo = useNav();
    // const [outlet, setOutlet] = useState(null);
    const [isSaveOutlet, setSaveOutlet] = useState(false);
    const [outlets, setOutlets] = useState([]);
    const [zipSearch, setZipSearch] = useState("");
    const [areaSearch, setAreaSearch] = useState("");
    const [mobileSearch, setMobileSearch] = useState("");
    const [outletSearch, setOutletSearch] = useState("");
    // const [merchantSearch, setMerchantSearch] = useState("");
    // const [action, setAction] = useState(null);
    const dispatch: any = useDispatch();
    const [sort, setSort] = useState("up");
    // const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    // const [selectedMenu, setSelectedMenu] = useState(null);
    const { selectedStore } = useSelector((state: any) => state.ui);
    const { outlets: outletList, createOutlet } = useSelector((state: any) => state.outlet);

    useEffect(() => {
        loadOutlets();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setOutlets(outletList)
    }, [outletList])

    useEffect(() => {
        if (createOutlet) {
            clearSaveOutlet();
        }
    }, [createOutlet])

    const loadOutlets = () => {
        if (selectedStore && selectedStore.id) {
            dispatch(getOutlets());
        }
    }

    useEffect(() => {
        onFilterOutlets();
        //eslint-disable-next-line
    }, [sort, outletSearch, areaSearch, zipSearch, mobileSearch])

    const onFilterOutlets = () => {
        // let outlets = this.props.outlet ? this.props.outlet.outlets ? this.props.outlet.outlets : [] : [];
        let filterOutlets = [];
        let isOutlet = false;
        let isMerchant = false;
        let isMobile = false;
        let isArea = false;
        let isZipcode = false;
        for (let o of outletList) {
            isOutlet = false;
            isMerchant = true;
            isMobile = false;
            isArea = false;
            isZipcode = false;
            if (outletSearch) {
                if (o.name.toLowerCase().indexOf(outletSearch.toLowerCase()) > -1) {
                    isOutlet = true
                }
            } else {
                isOutlet = true;
            }
            // if (merchantSearch) {
            //     if (o.mrId.toLowerCase().indexOf(merchantSearch.toLowerCase()) > -1) {
            //         isMerchant = true
            //     }
            // } else {
            //     isMerchant = true;
            // }
            if (areaSearch) {
                if (o.address.area.toLowerCase().indexOf(areaSearch.toLowerCase()) > -1) {
                    isArea = true
                }
            } else {
                isArea = true;
            }
            if (mobileSearch) {
                if (o.contactNumber.toString().indexOf(mobileSearch.toString()) > -1) {
                    isMobile = true
                }
            } else {
                isMobile = true;
            }
            if (zipSearch) {
                if (o.address.zipcode.toString().indexOf(zipSearch.toString()) > -1) {
                    isZipcode = true
                }
            } else {
                isZipcode = true;
            }
            // let { contactNumbers } = o;
            // if (contactNumbers && contactNumbers.length > 0 && mobileSearch) {
            //     let resp = contactNumbers.map((item) => {
            //         return item.type === "mobile" && item.phone.toString().indexOf(mobileSearch.toString()) > -1
            //     });
            //     isMobile = resp[0];
            // } else {
            //     isMobile = true;
            // }
            // console.log(isMobile[0])
            if (isOutlet && isMerchant && isArea && isZipcode && isMobile) {
                filterOutlets.push(o);
            }
        }

        filterOutlets.sort(function (a: any, b: any) {
            if (sort === "down") {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            } else {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            }
        });

        setOutlets(filterOutlets);
    }
    // renderMenu = (event, record) => {
    //     event.stopPropagation();
    //     const content = [];
    //     for (let i in menus) {
    //         content.push(
    //             <div key={menus[i].key} style={{ color: this.state.selectMenu == menus[i].key ? "#37a2fd" : "#000000", fontWeight: this.state.selectMenu == menus[i].key ? 800 : 100 }}>
    //                 <p onClick={() => this.onMenuChange(menus[i], record)}>{menus[i].value}</p>
    //             </div>
    //         );
    //     }
    //     return content;
    // }
    // const onMenuChange = (event, selectMenu, record) => {
    //     event.stopPropagation();
    //     event.preventDefault();
    //     // this.setState({ selectedMenu: null })
    //     // setSelectedMenu(null);
    //     console.log(selectMenu, record)
    //     // if (selectMenu.key == "ViewProduct") {
    //     //     this.props.selectedTabMenu("listed_outlet");
    //     //     this.props.history.push({ pathname: "/products", state: { selectedOutlet: record.id } });
    //     // }
    //     // if (selectMenu.key == "edit") {
    //     //     this.setState({
    //     //         outlet: record,
    //     //         isSaveOutlet: true,
    //     //     })
    //     // }
    // }
    // // render() {
    return (
        <Row className="outlet">
            {/* <Col span={24}>
                    <div className="head">Outlets</div>
                </Col> */}
            <Col className="list" style={{ height: window.innerHeight - 120 }}>
                <div className="action-bar">
                    <div className="title">
                        You've got {outlets && outlets.length} outlet listed
                    </div>
                    <div className="actions">
                        <button className="sp_btn btn" onClick={() => setSaveOutlet(true)} >+ Add Outlet</button>
                    </div>
                    {/* <button className="btn" onClick={() => this.setState({ isSaveOutlet: true })} >+ Add Outlet</button>
                        <button className="btn">Export</button> */}
                </div>
                <div className="content">
                    <Row className="header">
                        <Col span={8}>
                            Outlet Name
                        </Col>
                        {/* <Col span={5}>
                                Merchant ID
                            </Col> */}
                        <Col span={4}>
                            Mobile Number
                        </Col>
                        <Col span={5}>
                            Area
                        </Col>
                        <Col span={3}>
                            Zipcode
                        </Col>
                        <Col span={4}>
                            Created on
                        </Col>
                    </Row>
                    <Row className="filters">
                        <Col span={8}>
                            <Input className="filter" name="outletSearch" onChange={(event) => setOutletSearch(event.target.value)} placeholder="Outlet Name" suffix={<img alt='Search' src={SearchIcn} />} />
                        </Col>
                        {/* <Col span={5}>
                                <Input className="filter" placeholder="Merchant ID" name="merchantSearch" onChange={(event) => this.onChangeFilter(event)} suffix={<img alt='Search' src={SearchIcn} />} />
                            </Col> */}
                        <Col span={4}>
                            <Input className="filter" placeholder="Mobile Number" name="mobileSearch" onChange={(e) => setMobileSearch(e.target.value)} suffix={<img alt='Search' src={SearchIcn} />} />
                        </Col>
                        <Col span={5}>
                            <Input className="filter" placeholder="Area" name="areaSearch" onChange={(event) => setAreaSearch(event.target.value)} suffix={<img alt='Search' src={SearchIcn} />} />
                        </Col>
                        <Col span={3}>
                            <Input className="filter" placeholder="Zipcode" name="zipSearch" onChange={(event) => setZipSearch(event.target.value)} suffix={<img alt='Search' src={SearchIcn} />} />
                        </Col>
                        <Col span={4}>
                            <div style={{ display: 'flex', alignItems: 'center', height: "100%" }}>
                                <div style={{
                                    background: sort === "up" ? '#d6e6f2' : "#FFF",
                                    border: sort === "up" ? '1px solid #d6e6f2' : "1px solid #cacaca",
                                    marginRight: 20,
                                    cursor: 'pointer',
                                    borderRadius: 5,
                                    height: 28,
                                    width: 28,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }} onClick={() => { setSort("up") }}><img alt='Sort Up' src={SortUpSvg} width={20} /></div>
                                <div style={{
                                    background: sort === "down" ? '#d6e6f2' : "#FFF",
                                    border: sort === "down" ? '1px solid #d6e6f2' : "1px solid #cacaca",
                                    marginRight: 20,
                                    cursor: 'pointer',
                                    borderRadius: 5,
                                    height: 28,
                                    width: 28,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }} onClick={() => { setSort("down") }}><img alt='Sort Down' src={SortDownSvg} width={20} /></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="contains" style={{ height: window.innerHeight - 284 }}>
                        <Col span={24}>
                            {outlets && outlets.map((record, index) =>
                                <Row key={record.id} onClick={() => {
                                    // this.props.selectedTabMenu(null);
                                    navigateTo(`${record.id}/detail`);
                                }}
                                    className="item" style={{ border: outlets.length - 1 <= index ? "border: 1px solid #E7EEF7" : "1px solid #E7EEF7" }}>
                                    <Col className="record" span={8}>
                                        <div>
                                            <div style={{
                                                fontSize: 14,
                                                fontWeight: 900
                                            }}>{record.name}</div>
                                            <div style={{
                                                color: "#ADB7C3",
                                                fontSize: 12
                                            }}>{record.address.city}</div>
                                        </div>
                                    </Col>
                                    {/* <Col className="record" span={5}>
                                            <div>{record.mrId}</div>
                                        </Col> */}
                                    <Col className="record" span={4}>
                                        <div>{record.contactNumber}</div>
                                    </Col>
                                    <Col className="record" span={5}>
                                        <div>{record.address.area}</div>
                                    </Col>
                                    <Col className="record" span={3}>
                                        <div>{record.address.zipcode}</div>
                                    </Col>
                                    <Col span={4}>
                                        <div> {dayjs(record.createdAt).calendar(null, {
                                            sameDay: "[Today] h:mm A",
                                            nextDay: "[Tomorrow] h:mm A",
                                            nextWeek: "ddd h:mm A",
                                            lastDay: "[Yesterday] h:mm A",
                                            lastWeek: "[Last] dddd h:mm A",
                                            sameElse: "MMM D YYYY [at] h:mm A",
                                        })}</div>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
            </Col>
            <Add onClose={() => { setSaveOutlet(false); loadOutlets(); }} isVisible={isSaveOutlet} />
        </Row >
    )
}

export default ListedOutlets;
