import type from "../types/actionTypes";
const initialState = {
    industryTypes: [],
    otpTimestamp: null,
    isVerifiedOTP: null,
    stores: null,
    domainUnavailable: null,
    userType: null
};

export default function setupStoreReducers(state = initialState, action) {
    // console.log(action)
    switch (action.type) {
        case type.GET_INDUSTRY_TYPES_SUCCESS:
            return {
                ...state,
                industryTypes: action.data.options
            }
        case type.SEND_OTP_SUCCESS:
            return {
                ...state,
                otpTimestamp: action.data
            }
        case type.GET_DOMAIN_AVAILABLE_SUCCESS:
            return {
                ...state,
                domainAvailable: action.data
            }
        case type.GET_DOMAIN_AVAILABLE_ERROR:
            return {
                ...state,
                domainUnavailable: action.data
            }
        // case type.SEARCH_ADDRESS_SUCCESS:
        //     return {
        //         ...state,
        //         locationAutocomplete: action.data
        //     }
        // case type.GET_ADDRESS_SUCCESS:
        //     return {
        //         ...state,
        //         storeLocation: action.data
        //     }
        case type.VERIFY_OTP_SUCCESS:
            return {
                ...state,
                isVerifiedOTP: action.data
            }
        case type.USER_TYPE:
            return {
                ...state,
                userType: action.data.userTypeLogin
            }
        case type.STORES:
            return {
                ...state,
                stores: action.data
            }
        default:
            return state;
    }
}