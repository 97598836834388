import * as React from "react"

function OptionsSvg(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      stroke={props.stroke || "#FFF"}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="prefix__feather prefix__feather-menu"
    >
      <path fill="#fff" d="M3 12h18M3 6h18M3 18h13" />
    </svg>
  )
}

export default OptionsSvg