import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onUpdateSiteSettings } from "../../../../../../../actions/brandAction";
import { onCreatePayload } from "../../../../themeService";

const { TextArea } = Input;
const AboutUs = (props: any) => {
    const dispatch = useDispatch();
    const [data, setdata] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [aboutUs, setAboutUs] = useState(null);
    useEffect(() => {
        setdata(props.data);
    }, [props.data])
    useEffect(() => {
        setEdit(false);
        setAboutUs(props.aboutUs)
    }, [props.aboutUs])
    const onCancel = () => {
        setEdit(false);
        setAboutUs(data.aboutUs);
    }
    const onSave = async () => {
        const { theme } = data;
        const payload = onCreatePayload({
            theme: {
                web: theme.web,
                mobile: theme.mobile
            },
            codeInjection: data.codeInjection,
            aboutUs: aboutUs,
            social: data.social,
            pricingInclusiveOfTax: data.pricingInclusiveOfTax || false,
            paymentModes: data.paymentModes || [],
            autoConfirmOrders: data.autoConfirmOrders || {
                codOrders: false,
                onlineOrders: false
            }
        }, "all")
        const themeUpdateResp: any = await onUpdateSiteSettings(payload)(dispatch);
        if (themeUpdateResp && themeUpdateResp.isUpdated) {
            setEdit(false);
            props.getTheme();
        }
    }
    return <div className="division" style={{ display: "none" }}>
        <div className="header" style={{ marginTop: 40 }}>About Us</div>
        <div className="section" style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                {!isEdit && <button onClick={() => setEdit(true)} className="sp_btn save">Edit</button>}
                {isEdit && <button onClick={onCancel} className="sp_btn save">Cancel</button>}
                {isEdit && <button onClick={onSave} style={{ marginLeft: 10 }} className="sp_btn save">Save</button>}
            </div>
            <div style={{ display: 'flex', }}>
                <div style={{ width: '40%' }}>
                    <div style={{ flexGrow: 1, fontSize: 13 }} className="key" > Short description about your business</div>
                </div>
                <div style={{ alignItems: 'center', flexGrow: 1, display: 'flex', justifyContent: 'space-between', }}>
                    <TextArea disabled={!isEdit} style={{ background: "#FFF", width: 600 }} value={aboutUs} onChange={(e) => setAboutUs(e.target.value)} autoSize={{ minRows: 4, maxRows: 6 }} showCount maxLength={300} placeholder="Enter short description about your business"></TextArea>
                </div>
            </div>
        </div>
    </div>
}

export default AboutUs;