import type from "../types/actionTypes";

const initialState = {
    incompleteOrders: [],
    allOrders: [],
    paymentFailureOrder: []
};

export default function orderReducers(state = initialState, action) {
    switch (action.type) {
        case type.RESET_ORDERS:
            return {
                ...state,
                incompleteOrders: [],
                allOrders: [],
                paymentFailureOrder: []
            }
        case type.INCOMPLETE_ORDER_SUCCESS:
            return {
                ...state,
                incompleteOrders: [...state.incompleteOrders.filter(order => order.id !== action.data.order.id), action.data.order]
            }
        case type.GET_INCOMPLETE_ORDERS_SUCCESS:
            return {
                ...state,
                incompleteOrders: action.data.orders
            }
        case type.GET_ALL_ORDERS_SUCCESS:
            return {
                ...state,
                allOrders: action.data.orders
            }
        case type.GET_PAYMENT_FAILURE_ORDERS_SUCCESS:
            return {
                ...state,
                paymentFailureOrder: action.data.orders
            }
        case type.GET_ORDERS_ERROR:
            return {
                ...state,
                message: action.error
            }
        case type.CHANGE_MULTI_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                orderStatusMultiUpdated: action.data
            }
        case type.CHANGE_ORDER_STATUS_ERROR:
            return {
                ...state,
                message: action.error
            }
        default:
            return state;
    }
}