import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Drawer, Input, Row, Select, TimePicker } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import InputValidation from "../../../common/InputValidation";
import { Countries as CountryList } from '../../../../../countries';
import { isNotNullOrUndefinedString } from "../../../../../helpers/util";
import { createDeliverySlots, updateDeliverySlots } from "../../../../../actions/deliverySlotAction";
import { useDispatch } from "react-redux";
import Loading from "../../../common/Loading";

const { Option } = Select;
// const DeliveryLabels = {
//     cities: 'city',
//     states: 'state',
//     maxDistance: 'maxDistance',
//     countries: 'country'
// }
const Save = (props: any) => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const { selectedDeliverySlot, isVisible, onClose } = props;
    const [isEdit, setIsEdit] = useState(false);
    const [name, setName] = useState(null);
    const [slotType, setSlotType] = useState('fixed');
    const [orderStart, setOrderStart] = useState(null);
    const [orderEnd, setOrderEnd] = useState(null);
    const [startTime, setStartTime] = useState("09:30");
    const [endTime, setEndTime] = useState("21:30");
    const [isActive, setIsActive] = useState("true");
    const [deliveryType, setDeliveryType] = useState("maxDistance");
    const [deliveryBefore, setDeliveryBefore] = useState(null);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [maxDistance, setMaxDistance] = useState(1);
    const [deliveryBeforeType, setDeliveryBeforeType] = useState('minutes');
    const [orderStartType, setOrderStartType] = useState('minutes');
    const [orderEndType, setOrderEndType] = useState('minutes');
    const [postalCodes, setPostalCodes] = useState([]);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    const getErrorInfo = () => {
        return {
            name: {
                message: "",
                type: ""
            },
            deliveryArea: {
                message: "",
                type: ""
            },
            deliveryBefore: {
                message: "",
                type: ""
            },
            slotType: {
                message: "",
                type: ""
            },
            orderStart: {
                message: "",
                type: ""
            },
            orderEnd: {
                message: "",
                type: ""
            },
            endTime: {
                message: "",
                type: ""
            },
            startTime: {
                message: "",
                type: ""
            }
        }
    }
    const [error, setError] = useState(getErrorInfo());
    useEffect(() => {
        if (selectedDeliverySlot && selectedDeliverySlot.id) {
            setIsEdit(false);
            setName(selectedDeliverySlot.name);
            setSlotType(selectedDeliverySlot.slotType);
            if (selectedDeliverySlot.isActive) {
                setIsActive("true")
            } else {
                setIsActive("false")
            }
            if (selectedDeliverySlot.slotType === 'fixed') {
                setStartTime(selectedDeliverySlot.startTime);
                setEndTime(selectedDeliverySlot.endTime);
                const orderStartDetail = iso8601ToPlain(selectedDeliverySlot.orderStart);
                const orderEndDetail = iso8601ToPlain(selectedDeliverySlot.orderEnd);
                setOrderEndType(orderEndDetail.type);
                setOrderStartType(orderStartDetail.type);
                setOrderStart(orderStartDetail.value);
                setOrderEnd(orderEndDetail.value);
            } else {
                const deliveryBeforeDetail = iso8601ToPlain(selectedDeliverySlot.deliveryBefore);
                setDeliveryBeforeType(deliveryBeforeDetail.type);
                setDeliveryBefore(deliveryBeforeDetail.value);
            }
            if (selectedDeliverySlot.deliveryArea) {
                setDeliveryType(selectedDeliverySlot.deliveryArea.type);
                if (selectedDeliverySlot.deliveryArea.type === "maxDistance") {
                    setMaxDistance(selectedDeliverySlot.deliveryArea.maxDistance);
                }
                if (selectedDeliverySlot.deliveryArea.type === "country") {
                    setCountries(selectedDeliverySlot.deliveryArea.countries);
                }
                if (selectedDeliverySlot.deliveryArea.type === "state") {
                    setStates(selectedDeliverySlot.deliveryArea.states);
                }
                if (selectedDeliverySlot.deliveryArea.type === "city") {
                    setCities(selectedDeliverySlot.deliveryArea.cities);
                }
                if (selectedDeliverySlot.deliveryArea.type === "postalCode") {
                    setPostalCodes(selectedDeliverySlot.deliveryArea.postalCodes);
                }
            }
        } else {
            setIsEdit(true);
            reset();
        }
    }, [selectedDeliverySlot]);
    // const getOrderRange = (mins) => {
    //     let range = 0;
    //     let type = 'minutes'
    //     if (mins < 60) {
    //         range = mins
    //     } else {
    //         let hours = mins / 60;
    //         let days = hours / 24;
    //         if (days.toString().indexOf(".") === -1) {
    //             range = days;
    //             type = 'days';
    //         } else if (hours.toString().indexOf(".") === -1) {
    //             range = hours;
    //             type = 'hours';
    //         } else {
    //             range = mins;
    //         }
    //     }
    //     return { range, type };
    // }
    const reset = () => {
        setName(null);
        setSlotType('fixed');
        setOrderStart(null);
        setOrderEnd(null);
        setStartTime("09:30");
        setEndTime("21:30");
        setIsActive("true");
        setDeliveryType("maxDistance");
        setDeliveryBefore(null);
        setStates([]);
        setCities([]);
        setPostalCodes([]);
        setCountries([]);
        setMaxDistance(1);
        setDeliveryBeforeType('minutes');
        setOrderStartType('minutes');
        setOrderEndType('minutes');
    }
    const onSubmit = async () => {
        let errorInfo = getErrorInfo();
        setError(errorInfo);
        let isError = false;
        let payload: any = {
            deliveryArea: {
                type: null
            }
        };
        if (!name || !isNotNullOrUndefinedString(name)) {
            errorInfo.name.message = "Enter the slot name";
            errorInfo.name.type = "error";
            isError = true;
        } else {
            errorInfo.name.message = "";
            errorInfo.name.type = "";
        }
        if (!slotType || !isNotNullOrUndefinedString(slotType)) {
            errorInfo.slotType.message = "Choose the slot type";
            errorInfo.slotType.type = "error";
            isError = true;
        } else {
            errorInfo.slotType.message = "";
            errorInfo.slotType.type = "";
        }
        if (slotType === 'flexible') {
            payload['deliveryBefore'] = plainToISO8601(deliveryBeforeType, deliveryBefore)
            if (payload.deliveryBefore <= 0) {
                errorInfo.deliveryBefore.message = 'Enter the minutes / hours / days';
                errorInfo.deliveryBefore.type = 'error';
                isError = true;
            }
            delete payload['startTime'];
            delete payload['endTime'];
            delete payload['orderStart'];
            delete payload['orderEnd'];
        }
        if (slotType === 'fixed') {
            if (!startTime || !isNotNullOrUndefinedString(startTime)) {
                errorInfo.startTime.message = "Select the slot start time";
                errorInfo.startTime.type = "error";
                isError = true;
            } else {
                errorInfo.startTime.message = "";
                errorInfo.startTime.type = "";
            }
            if (!endTime || !isNotNullOrUndefinedString(endTime)) {
                errorInfo.endTime.message = "Select the slot end time";
                errorInfo.endTime.type = "error";
                isError = true;
            } else {
                errorInfo.endTime.message = "";
                errorInfo.endTime.type = "";
            }
            payload['startTime'] = dayjs(startTime, "HH:mm").format("HH:mm");
            payload['endTime'] = dayjs(endTime, "HH:mm").format("HH:mm");
            if (!orderEnd || orderEnd < 1) {
                errorInfo.orderEnd.message = "Select the order end time";
                errorInfo.orderEnd.type = "error";
                isError = true;
            } else {
                errorInfo.orderEnd.message = "";
                errorInfo.orderEnd.type = "";
                payload['orderEnd'] = plainToISO8601(orderEndType, orderEnd)
            }
            if (!orderStart || orderStart < 1) {
                errorInfo.orderStart.message = "Select the order start time";
                errorInfo.orderStart.type = "error";
                isError = true;
            } else {
                errorInfo.orderStart.message = "";
                errorInfo.orderStart.type = "";
                payload['orderStart'] = plainToISO8601(orderStartType, orderStart);
            }
        }
        payload['deliveryArea'] = {
            type: deliveryType
        }
        if (deliveryType === "states" && (!states || states.length === 0)) {
            errorInfo.deliveryArea.message = "Select the delivery state";
            errorInfo.deliveryArea.type = "error";
            isError = true;
        } else if (deliveryType === "cities" && (!cities || cities.length === 0)) {
            errorInfo.deliveryArea.message = "Select the delivery cities";
            errorInfo.deliveryArea.type = "error";
            isError = true;
        } else if (deliveryType === "countries" && (!countries || countries.length === 0)) {
            errorInfo.deliveryArea.message = "Select the delivery countries";
            errorInfo.deliveryArea.type = "error";
            isError = true;
        } else if (deliveryType === "maxDistance" && maxDistance < 1) {
            errorInfo.deliveryArea.message = "Enter the delivery max distance";
            errorInfo.deliveryArea.type = "error";
            isError = true;
        } else if (deliveryType === "postalCodes" && !postalCodes) {
            errorInfo.deliveryArea.message = "Enter the delivery postalCode";
            errorInfo.deliveryArea.type = "error";
            isError = true;
        } else {
            errorInfo.endTime.message = "";
            errorInfo.endTime.type = "";
        }
        setError(errorInfo);
        if (!isError) {
            setLoading(true);
            payload['isActive'] = isActive === "true" ? true : false;
            payload['name'] = name;
            payload['slotType'] = slotType;
            if (payload.deliveryArea.type === "maxDistance") {
                payload.deliveryArea['maxDistance'] = maxDistance;
            } else if (payload.deliveryArea.type === "state") {
                payload.deliveryArea['states'] = states;
            } else if (payload.deliveryArea.type === "city") {
                payload.deliveryArea['cities'] = cities;
            } else if (payload.deliveryArea.type === "country") {
                payload.deliveryArea['countries'] = countries;
            } else if (payload.deliveryArea.type === "postalCode") {
                payload.deliveryArea['postalCodes'] = postalCodes;
            }
            let resp = null;
            if (selectedDeliverySlot && selectedDeliverySlot.id) {
                resp = await updateDeliverySlots(selectedDeliverySlot.id, payload)(dispatch);
            } else {
                resp = await createDeliverySlots(payload)(dispatch);
            }
            setLoading(false);
            if (resp && (resp.isUpdated || resp.newDeliverySlotId)) {
                reset();
                onClose();
            }
        }
    }
    const plainToISO8601 = (type, value) => {
        let result;
        if (type === 'minutes') {
            result = `PT${value}M`;
        } else if (type === 'hours') {
            result = `PT${value}H`;
        } else if (type === 'days') {
            result = `P${value}D`;
        }
        return result;
    }
    const iso8601ToPlain = (value) => {
        let result = value;
        let type = typeof value === "string" ? value.indexOf('M') > -1 ? 'minutes' : value.indexOf('H') > -1 ? 'hours' : 'days' : null;
        if (type === 'minutes') {
            result = value.replace('PT', '').replace('M', '');
        } else if (type === 'hours') {
            result = value.replace('PT', '').replace('H', '');
        } else if (type === 'days') {
            result = value.replace('P', '').replace('D', '');
        } else {
            type = 'minutes';
        }
        return { value: result, type };
    }
    // const calculateToMinutes = (type, value) => {
    //     let minutes = 0;
    //     if (type === 'minutes') {
    //         minutes = value;
    //     } else if (type === 'hours') {
    //         minutes = value * 60;
    //     } else if (type === 'days') {
    //         minutes = value * 1440;
    //     }
    //     return minutes;
    // }
    return <Drawer
        width={470}
        closable={false}
        onClose={() => { setError(getErrorInfo()); onClose(); }}
        className="delivery-slot-view"
        visible={isVisible}
        footer={
            <div className="footer">
                <div className="sp_btn" onClick={() => { setIsEdit(false); setError(getErrorInfo()); onClose(); }} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                {!isEdit && <div className="sp_btn" onClick={() => { setIsEdit(true) }} style={{ width: 120, fontSize: 14 }}>Edit</div>}
                {isEdit && <div className="sp_btn" onClick={onSubmit} style={{ width: 164, fontSize: 14 }}>{(selectedDeliverySlot && selectedDeliverySlot.id) ? "Update Slot" : "Create Slot"}</div>}
            </div>
        }>
        <div className="header">
            <ArrowLeftOutlined className="arrow" onClick={() => { setError(getErrorInfo()); onClose(); }}></ArrowLeftOutlined>
            <div className="title">Delivery Slots</div>
        </div>
        <Row className="content" style={{ height: dimensions.height - 120, overflowY: 'auto' }} >
            <Col span={24}>
                <Row className="group">
                    <Col span={24}>
                        <div className="label" >Name *</div>
                    </Col>
                    <Col span={24}>
                        <InputValidation type={error.name.type} message={error.name.message}>
                            <Input className="value" onChange={(e) => setName(e.target.value)} disabled={!isEdit} value={name} />
                        </InputValidation>
                    </Col>
                </Row>
                <Row className="group">
                    <Col span={24}>
                        {selectedDeliverySlot && selectedDeliverySlot.id ?
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', fontSize: 16, height: 40, marginTop: 35, marginBottom: 10 }}>
                                <div style={{ fontWeight: "bold", color: slotType === "fixed" ? "#02BA65" : "#188FFC" }}>{slotType === "fixed" ? "Fixed Slots" : "Flexible Slots"}</div>
                                <div style={{ position: "absolute", width: '30%', height: 3, background: slotType === "fixed" ? "#02BA65" : "#188FFC", bottom: 0 }}></div>
                            </div> :
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', fontSize: 16, height: 40, marginTop: 35, marginBottom: 10 }}>
                                <div style={{ position: "absolute", width: '100%', height: 1, background: '#D8DEE6', bottom: 0 }}></div>
                                <div style={{ position: "absolute", width: '50%', height: 3, background: slotType === "fixed" ? "#02BA65" : "#188FFC", bottom: 0, right: slotType === "flexible" ? '0%' : '50%', transition: 'all 0.3s ease' }}></div>
                                <div onClick={() => { setSlotType("fixed") }} style={{ cursor: 'pointer', fontWeight: slotType === "fixed" ? "bold" : "normal", color: slotType === "fixed" ? "#02BA65" : "#4E4E4E", flexGrow: 1, marginBottom: 15, textAlign: 'center' }}>Fixed Slots</div>
                                <div onClick={() => { setSlotType("flexible") }} style={{ cursor: 'pointer', fontWeight: slotType === "flexible" ? "bold" : "normal", color: slotType === "flexible" ? "#188FFC" : "#4E4E4E", flexGrow: 1, marginBottom: 15, textAlign: 'center' }}>Flexible Slots</div>
                            </div>
                        }
                    </Col>
                </Row>
                {slotType === "flexible" &&
                    <Row className="group">
                        <Col span={11}>
                            <div className="label" >Delivery Before *</div>
                            <InputValidation type={error.deliveryBefore.type} message={error.deliveryBefore.message}>
                                {/* <TimePicker className="time-picker" style={{ paddingLeft: 10 }} defaultValue={dayjs(deliveryBefore, "h:mm A")} format="h:mm A" onChange={(time, timeString) => setDeliveryBefore(timeString)} placeholder={"09:00 AM"} /> */}
                                <div className="minutes-picker">
                                    <Select disabled={!isEdit} defaultValue={deliveryBeforeType} onChange={(val) => { setDeliveryBeforeType(val) }} style={{ width: 250, color: '#4E4E4E' }}>
                                        <Option value="minutes">In Minutes</Option>
                                        <Option value="hours">In Hours</Option>
                                        <Option value="days">In Days</Option>
                                    </Select>
                                    <Input disabled={!isEdit} className="value" type="number" onChange={(e) => { setDeliveryBefore(parseInt(e.target.value)) }} value={deliveryBefore} />
                                </div>
                            </InputValidation>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}></Col>
                    </Row>}
                {slotType === "fixed" && <>
                    <Row className="group">
                        <Col span={11}>
                            <div className="label" >Start Time *</div>
                            <InputValidation type={error.startTime.type} message={error.startTime.message}>
                                <TimePicker disabled={!isEdit} className="time-picker" style={{ paddingLeft: 10 }} value={dayjs(startTime, "HH:mm")} format="hh:mm A" onChange={(time, timeString) => setStartTime(dayjs(timeString, "hh:mm A").format("HH:mm"))} placeholder={"09:00 AM"} />
                            </InputValidation>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <div className="label" >End Time *</div>
                            <InputValidation type={error.endTime.type} message={error.endTime.message}>
                                <TimePicker disabled={!isEdit} className="time-picker" style={{ paddingLeft: 10 }} value={dayjs(endTime, "HH:mm")} format="hh:mm A" onChange={(time, timeString) => setEndTime(dayjs(timeString, "hh:mm A").format("HH:mm"))} placeholder={"06:00 PM"} />
                            </InputValidation>
                        </Col>
                    </Row>
                    <Row className="group">
                        <Col span={11}>
                            <div className="label" >Order Start *</div>
                            <InputValidation type={error.orderStart.type} message={error.orderStart.message}>
                                <div className="minutes-picker">
                                    <Select value={orderStartType} disabled={!isEdit} onChange={(val) => { setOrderStartType(val) }} style={{ width: 250, color: '#4E4E4E' }}>
                                        <Option value="minutes">In Minutes</Option>
                                        <Option value="hours">In Hours</Option>
                                        <Option value="days">In Days</Option>
                                    </Select>
                                    <Input className="value" type="number" disabled={!isEdit} onChange={(e) => { setOrderStart(parseInt(e.target.value)) }} value={orderStart} />
                                </div>
                            </InputValidation>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <div className="label" >Order End *</div>
                            <InputValidation type={error.orderEnd.type} message={error.orderEnd.message}>
                                <div className="minutes-picker">
                                    <Select value={orderEndType} disabled={!isEdit} onChange={(val) => { setOrderEndType(val) }} style={{ width: 250, color: '#4E4E4E' }}>
                                        <Option value="minutes">In Minutes</Option>
                                        <Option value="hours">In Hours</Option>
                                        <Option value="days">In Days</Option>
                                    </Select>
                                    <Input className="value" type="number" disabled={!isEdit} onChange={(e) => { setOrderEnd(parseInt(e.target.value)) }} value={orderEnd} />
                                </div>
                            </InputValidation>
                        </Col>
                        {(orderStart && orderEnd) && <Col span={24}>
                            <div style={{ fontSize: 10, color: "#4E4E4E", display: 'flex', flexWrap: 'wrap' }}>  Ordering starts <span style={{ color: '#188FFC', fontWeight: 'bold', margin: "0px 6px" }}>{orderStart} {orderStartType}</span> before the slot start time and ends <span style={{ color: '#188FFC', fontWeight: 'bold', margin: "0px 6px" }}> {orderEnd} {orderEndType} </span> before the start time</div>
                        </Col>}
                    </Row>
                </>}
                <Row className="group">
                    <Col span={24}>
                        <div className="label">Delivery Range Type</div>
                        <Select defaultValue={deliveryType} disabled={!isEdit} value={deliveryType} onChange={(val) => { setError({ ...error, "deliveryArea": { message: '', type: '' } }); setDeliveryType(val) }}>
                            <Option value={"maxDistance"}>Max distance from outlet</Option>
                            <Option value={"state"}>States</Option>
                            <Option value={"city"}>Cities</Option>
                            <Option value={"country"}>Country</Option>
                            <Option value={"postalCode"}>Pincodes</Option>
                        </Select>
                    </Col>
                </Row>
                {deliveryType === "maxDistance" && <Row className="group">
                    <Col span={24}>
                        <div className="group">
                            <div className="label">Max Distance (km)</div>
                            <InputValidation type={error.deliveryArea.type} message={error.deliveryArea.message}>
                                <Input disabled={!isEdit} type="number" min="1" name="maxDistance" value={maxDistance} placeholder="Max Distance"
                                    onChange={(e) => setMaxDistance(parseInt(e.target.value))}
                                />
                            </InputValidation>
                        </div>
                    </Col>
                </Row>}
                {deliveryType === "postalCode" && <Row className="group">
                    <Col span={24}>
                        <div className="group">
                            <div className="label">Pincodes</div>
                            <Select mode="tags" disabled={!isEdit} value={postalCodes} placeholder="Pincode"
                                onChange={(value: any) => {
                                    let validatePincodes = [];
                                    const reg = /^-?\d*(\.\d*)?$/;
                                    for (let i of value) {
                                        if ((i.length === 6 && !isNaN(i) && reg.test(i)) || i === '' || i === '-') {
                                            validatePincodes.push(i)
                                        }
                                    }
                                    setPostalCodes(validatePincodes)
                                }}
                            />
                        </div>
                    </Col>
                </Row>}
                {deliveryType === "state" && <Row className="group">
                    <Col span={24}>
                        <div className="group">
                            <div className="label">States</div>
                            <InputValidation type={error.deliveryArea.type} message={error.deliveryArea.message}>
                                <Select disabled={!isEdit} mode='multiple' maxTagCount='responsive' defaultValue={states} onChange={(val) => setStates(val)}>
                                    {CountryList && CountryList.IND.map((item) => { return item.type.toLocaleLowerCase() === "state" && <Option key={item.name} value={item.name}>{item.name}</Option> })}
                                </Select>
                            </InputValidation>
                        </div>
                    </Col>
                </Row>}
                {deliveryType === "city" && <Row className="group">
                    <Col span={24}> <div className="group">
                        <div className="label">Cities</div>
                        <InputValidation type={error.deliveryArea.type} message={error.deliveryArea.message}>
                            <Select disabled={!isEdit} mode='multiple' maxTagCount='responsive' defaultValue={cities} onChange={(val) => setCities(val)}>
                                {CountryList && CountryList.IND.map((item) => {
                                    return item.districts.map((citi) => {
                                        return <Option key={citi.name} value={citi.name}>{citi.name}</Option>
                                    })
                                })}
                            </Select>
                        </InputValidation>
                    </div>
                    </Col>
                </Row>}
                {deliveryType === "country" && <Row className="group">
                    <Col span={24}> <div className="group">
                        <div className="label">Countries</div>
                        <InputValidation type={error.deliveryArea.type} message={error.deliveryArea.message}>
                            <Select disabled={!isEdit} mode='multiple' maxTagCount='responsive' defaultValue={countries} onChange={(val) => setCountries(val)}>
                                <Option value={'India'}>{'India'}</Option>
                            </Select>
                        </InputValidation>
                    </div> </Col>
                </Row>}
                <Row className="group">
                    <Col span={24}>
                        <div className="label" >Make Slot Status</div>
                    </Col>
                    <Col span={24}>
                        <Select className="value" disabled={!isEdit} value={isActive} onChange={(value) => {
                            setIsActive(value)
                        }}>
                            <Option value="true">Active</Option>
                            <Option value="false">Inactive</Option>
                        </Select>
                    </Col>
                </Row>
            </Col>
        </Row>
        {isLoading && <Loading background="#00000070"></Loading>}
    </Drawer >
}

export default Save;

