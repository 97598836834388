import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedMainMenu as onSelectedMainMenu, selectedTabMenu as onSelectedTabMenu } from "../../../actions/uiAction";
import { getOrdersPerDay, getOrderStats, getTopProducts, getAcceptedOrders, getTopOutlets, getRepeatCustomers } from "../../../actions/homeAction";
import dayjs from "dayjs";
import "../../../assets/sass/dsk/dashboard.sass";
import SalesSvg from "../../../assets/svgComponents/SalesSvg";
import CompleteOrderSvg from "../../../assets/svgComponents/CompleteOrderSvg";
import AwaitSvg from "../../../assets/svgComponents/AwaitSvg";
import OrderAcceptSvg from "../../../assets/svgComponents/OrderAcceptSvg";
import { SyncOutlined } from '@ant-design/icons';
import PieVz from "./PieVz";
import BarVz from "./BarVz";
import OrderDeclinedSvg from "../../../assets/svgComponents/OrderDeclinedSvg";

const range = [{
    key: "7days",
    value: "Last 7 days"
}, {
    key: "14days",
    value: "Last 14 days"
}, {
    key: "28days",
    value: "Last 28 days"
}]
// , {
//     key: "month",
//     value: "This month"
// }, {
//     key: "previousMonth",
//     value: "Previous Month"
// }
const apiStatus = ['INITIATED', 'STARTED', 'COMPLETED'];
const Dashboard = (props: any) => {
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    const [selectedRange, setSelectedRange]: any = useState(range[0]);
    const [selectedDate, setSelectedDate]: any = useState(null);
    const [ordersCompletes, setOrdersCompletes] = useState(null);
    const [pending, setPending] = useState(null);
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [totalSales, setTotalSales] = useState(null);
    const [topProducts, setTopProducts] = useState(null);
    const [topOutletsSales, setTopOutletsSales] = useState(null);
    const [repeatCustomers, setRepeatCustomers] = useState(null);
    const [ordersAccepted, setOrdersAccepted] = useState(null);
    const [ordersAcceptedPer, setOrdersAcceptedPer] = useState(0);
    const [ordersDeclined, setOrdersDeclined] = useState(null);
    const [ordersPerDayData, setOrdersPerDayData] = useState(null);
    const [orderStatsApi, setOrderStatsApi] = useState(null);
    const [ordersPerDayApi, setOrdersPerDayApi] = useState(null);
    const [acceptedOrdersApi, setAcceptedOrdersApi] = useState(null);
    const [topOutletsApi, setTopOutletsApi] = useState(null);
    const [repeatCustomersApi, setRepeatCustomersApi] = useState(null);
    const [topProductsApi, setTopProductsApi] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(onSelectedMainMenu('home'))
        dispatch(onSelectedTabMenu(null))
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedStore) {
            resetState();
        }
    }, [selectedStore]);

    const resetState = () => {
        setOrdersCompletes(null);
        setPending(null);
        setTotalSales(null);
        setTopProducts(null);
        setTopOutletsSales(null);
        setRepeatCustomers(null);
        setOrdersAccepted(null);
        setOrdersAcceptedPer(0);
        setOrdersDeclined(null);
        setOrdersPerDayData(null);
        setOrderStatsApi(apiStatus[0]);
        setOrdersPerDayApi(apiStatus[0]);
        setAcceptedOrdersApi(apiStatus[0]);
        setTopOutletsApi(apiStatus[0]);
        setRepeatCustomersApi(apiStatus[0]);
        setTopProductsApi(apiStatus[0]);
    }

    useEffect(() => {
        if (selectedDate) {
            resetState();
        }
    }, [selectedDate]);

    useEffect(() => {
        if (selectedRange) {
            getDateRange();
        }
        //eslint-disable-next-line
    }, [selectedRange]);

    const getDateRange = () => {
        if (selectedRange.key.indexOf('days') > -1) {
            const days = selectedRange.key.split('days')[0];
            if (days) {
                setSelectedDate([
                    dayjs().subtract(days, 'd')
                        .set('hour', 0)
                        .set('minute', 0)
                        .set('second', 0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    dayjs().set('hour', 23)
                        .set('minute', 59)
                        .set('second', 59).format('YYYY-MM-DDTHH:mm:ss.SSS')
                ])
            }
        } else if (selectedRange.key === 'month') {
            setSelectedDate([
                dayjs().startOf('month')
                    .set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                dayjs().endOf('month')
                    .set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59).format('YYYY-MM-DDTHH:mm:ss.SSS')
            ])
        } else if (selectedRange.key === 'previousMonth') {
            setSelectedDate([
                dayjs().subtract(1, 'months').endOf('month')
                    .set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                dayjs().startOf('month')
                    .set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59).format('YYYY-MM-DDTHH:mm:ss.SSS')
            ])
        }
    }

    const loadOrderStatus = async () => {
        if (apiStatus && orderStatsApi === apiStatus[0]) {
            setOrderStatsApi(apiStatus[1]);
            const resp = await getOrderStats(selectedDate[0], selectedDate[1])(dispatch);
            setOrderStatsApi(apiStatus[2]);
            if (resp) {
                const { orderStats } = resp;
                setOrdersCompletes(orderStats.completed ? orderStats.completed : 0)
                setTotalSales(orderStats.sales)
                setPending(orderStats.pending ? orderStats.pending : 0)
            }
        }
    }

    const loadTopOutlets = async () => {
        if (topOutletsApi === apiStatus[0]) {
            setTopOutletsApi(apiStatus[1]);
            const resp = await getTopOutlets(selectedDate[0], selectedDate[1])(dispatch);
            setTopOutletsApi(apiStatus[2]);
            if (resp && resp.topOutlets.length > 0) {
                setTopOutletsSales(resp.topOutlets)
            } else {
                setTopOutletsSales(null);
            }
        }
    }


    const loadOrdersPerDay = async () => {
        if (apiStatus && ordersPerDayApi === apiStatus[0]) {
            setOrdersPerDayApi(apiStatus[1]);
            const resp = await getOrdersPerDay(selectedDate[0], selectedDate[1])(dispatch);
            setOrdersPerDayApi(apiStatus[2]);
            let isValue = false;
            if (resp && resp.ordersPerDay && resp.ordersPerDay.length > 0) {
                for (let o of resp.ordersPerDay) {
                    if (o.count > 0) {
                        isValue = true;
                        break;
                    }
                }
            }
            if (isValue) {
                setOrdersPerDayData(resp)
            }
        }
    }

    const loadAcceptedOrders = async () => {
        if (acceptedOrdersApi === apiStatus[0]) {
            setAcceptedOrdersApi(apiStatus[1]);
            const resp = await getAcceptedOrders(selectedDate[0], selectedDate[1])(dispatch);
            setAcceptedOrdersApi(apiStatus[2]);
            if (resp) {
                const { accepted,
                    declined } = resp;
                setOrdersAccepted(accepted);
                setOrdersDeclined(declined);
            }
        }
    }

    const loadRepeatCustomers = async () => {
        let resp = null;
        if (repeatCustomersApi === apiStatus[0]) {
            setRepeatCustomersApi(apiStatus[1]);
            resp = await getRepeatCustomers(selectedDate[0], selectedDate[1])(dispatch);
            setRepeatCustomersApi(apiStatus[2]);
            if (resp && (resp.repeat > 0 || resp.new > 0)) {
                setRepeatCustomers([{ id: 'NEW', label: 'NEW', value: resp.new, color: '#FD7D7B' }, { id: 'OLD', label: 'OLD', value: resp.repeat, color: '#1F4173' }]);
            } else {
                setRepeatCustomers(null);
            }
        }
    }

    const loadTopProducts = async () => {
        if (topProductsApi === apiStatus[0]) {
            setTopProductsApi(apiStatus[1]);
            const resp = await getTopProducts(selectedDate[0], selectedDate[1])(dispatch);
            setTopProductsApi(apiStatus[2]);
            if (resp && resp.topProducts.length > 0) {
                setTopProducts(resp.topProducts)
            } else {
                setTopProducts(null)
            }
        }

    }

    useEffect(() => {
        if (selectedDate) {
            if (orderStatsApi === 'INITIATED') {
                loadOrderStatus();
            }

            if (ordersPerDayApi === 'INITIATED') {
                loadOrdersPerDay();
            }

            if (acceptedOrdersApi === 'INITIATED') {
                loadAcceptedOrders();
            }

            if (topOutletsApi === 'INITIATED') {
                loadTopOutlets();
            }

            if (repeatCustomersApi === 'INITIATED') {
                loadRepeatCustomers();
            }

            if (topProductsApi === 'INITIATED') {
                loadTopProducts();
            }
        }
        //eslint-disable-next-line
    }, [orderStatsApi, ordersPerDayApi, acceptedOrdersApi, topOutletsApi, repeatCustomersApi, topProductsApi]);

    useEffect(() => {
        if (ordersDeclined !== null && ordersAccepted !== null && ordersDeclined !== null) {
            if (ordersAccepted + ordersDeclined > 0) {
                setOrdersAcceptedPer((ordersAccepted / (ordersAccepted + ordersDeclined)) * 100)
            }
        }
        //eslint-disable-next-line
    }, [ordersDeclined, ordersAccepted, ordersDeclined])

    return <div className="dashboard" style={{ height: dimensions.height - 80 }}>
        <div className="header">
            <div className="tabs">
                {range.map((item) => {
                    return <p key={item.key} style={{
                        cursor: (selectedRange && item.key === selectedRange.key) ? 'default' : 'pointer'
                    }} className={(selectedRange && item.key === selectedRange.key) ? "selected" : ""} onClick={() => setSelectedRange(item)}>{item.value}</p>
                })}
                <SyncOutlined onClick={resetState} style={{ marginRight: 18, cursor: acceptedOrdersApi === 'COMPLETED' ? 'pointer' : 'default' }} />
            </div>
        </div>
        <div className="content" style={{ height: dimensions.height - 170 }}>
            {/* style={{ gap: '10px' }} */}
            <div className="split-col" >
                {/* style={{ gap: '10px' }} */}
                <div className="split-col" >
                    <div className="card" style={{ height: 170 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SalesSvg></SalesSvg>
                            {/* <SyncOutlined spin={orderStatsApi !== 'COMPLETED'} onClick={() => {
                                setOrderStatsApi(apiStatus[0]);
                            }} style={{ marginTop: -34, marginRight: -8, cursor: orderStatsApi === 'COMPLETED' ? 'pointer' : 'default' }} /> */}
                        </div>
                        <div className="value" style={{ marginTop: 19 }}>₹{totalSales && totalSales?.toLocaleString('en-IN')}</div>
                        <div className="label">OverAll Sales</div>
                    </div>
                    <div className="card" style={{ height: 170 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <CompleteOrderSvg></CompleteOrderSvg>
                            {/* <SyncOutlined spin={orderStatsApi !== 'COMPLETED'} onClick={() => {
                                setOrderStatsApi(apiStatus[0]);
                            }} style={{ marginTop: -34, marginRight: -8, cursor: orderStatsApi === 'COMPLETED' ? 'pointer' : 'default' }} /> */}
                        </div>
                        <div className="value" style={{ marginTop: 19 }}>{ordersCompletes}</div>
                        <div className="label">Complete Orders</div>
                    </div>
                </div>
                <div className="card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="label" style={{ fontSize: 18, color: '#1F4173', fontWeight: 'bold', marginBottom: 16 }}>Top Selling Products</div>
                        {/* <SyncOutlined spin={topProductsApi !== 'COMPLETED'} onClick={() => {
                            setTopProductsApi(apiStatus[0]);
                        }} style={{ marginTop: -48, marginRight: -4, cursor: topProductsApi === 'COMPLETED' ? 'pointer' : 'default' }} /> */}
                    </div>

                    {topProducts ? <><div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        background: '#e6e6e675',
                        padding: '0px 10px',
                        height: 40,
                        alignItems: 'center',
                        borderRadius: 4,
                        marginBottom: 10
                    }}>
                        <div className="label" style={{ fontWeight: 'bold' }}>Products</div>
                        <div className="label" style={{ fontWeight: 'bold' }}>Orders</div>
                    </div>
                        <div style={{
                            height: '70%',
                            overflowY: 'auto'
                        }}>
                            {topProducts.map((item) => {
                                return <div key={item.title} style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '0px 10px',
                                    height: 40
                                }}>
                                    <div className="value row-ellipsis" style={{ fontSize: 14, color: '#121212' }} title={item.title}>{item.title}</div>
                                    <div className="value" style={{ fontSize: 14, color: '#121212' }}>{item.count}</div>
                                </div>
                            })}
                        </div></> : <div style={{
                            display: 'flex',
                            height: '60%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>No data found</div>}
                </div>
            </div>
            {/* gap: '10px', */}
            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridTemplateRows: '1fr 3.2fr',
            }}>
                {/* gap: '10px' */}
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: '20px'
                }}>
                    <div className="card" style={{ height: 170, width: '100%', }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <AwaitSvg></AwaitSvg>
                            {/* <SyncOutlined spin={orderStatsApi !== 'COMPLETED'} onClick={() => {
                                setOrderStatsApi(apiStatus[0]);
                            }} style={{ marginTop: -34, marginRight: -8, cursor: orderStatsApi === 'COMPLETED' ? 'pointer' : 'default' }} /> */}
                        </div>
                        <div className="value" style={{ marginTop: 19 }}>{pending}</div>
                        <div className="label">Pending Orders</div>
                    </div>
                    <div className="card" style={{ height: 170, width: '100%', }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <OrderAcceptSvg></OrderAcceptSvg>
                            {/* <SyncOutlined spin={acceptedOrdersApi !== 'COMPLETED'} onClick={() => {
                                setAcceptedOrdersApi(apiStatus[0]);
                            }} style={{ marginTop: -34, marginRight: -8, cursor: acceptedOrdersApi === 'COMPLETED' ? 'pointer' : 'default' }} /> */}
                        </div>
                        <div className="value" style={{ marginTop: 19 }}>{ordersAcceptedPer.toFixed(0)}%</div>
                        <div className="label">Order Acceptance</div>
                    </div>
                    <div className="card" style={{ height: 170 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <OrderDeclinedSvg></OrderDeclinedSvg>
                            {/* <SyncOutlined spin={acceptedOrdersApi !== 'COMPLETED'} onClick={() => {
                                setAcceptedOrdersApi(apiStatus[0]);
                            }} style={{ marginTop: -34, marginRight: -8, cursor: acceptedOrdersApi === 'COMPLETED' ? 'pointer' : 'default' }} /> */}
                        </div>
                        <div className="value" style={{ marginTop: 19 }}>{ordersDeclined}%</div>
                        <div className="label">Order Declined</div>
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <div className="card" style={{ width: '98%' }}>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="label" style={{ fontSize: 18, color: '#1F4173', fontWeight: 'bold', marginBottom: 16 }}>Cancellations Per Day</div>
                            <SyncOutlined spin={ordersPerDayApi !== 'COMPLETED'} onClick={() => {
                                setOrdersPerDayApi(apiStatus[0]);
                            }} style={{ marginTop: -48, marginRight: -4, cursor: ordersPerDayApi === 'COMPLETED' ? 'pointer' : 'default' }} />
                        </div>
                        <div style={{
                            height: '85%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <BarVz data={ordersPerDayData && ordersPerDayData.cancellationsPerDay} xKey={"date"} yKey={"count"}></BarVz>
                        </div> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="label" style={{ fontSize: 18, color: '#1F4173', fontWeight: 'bold', marginBottom: 16 }}>Orders Per Day</div>
                            {/* <SyncOutlined spin={ordersPerDayApi !== 'COMPLETED'} onClick={() => {
                                setOrdersPerDayApi(apiStatus[0]);
                            }} style={{ marginTop: -48, marginRight: -4, cursor: ordersPerDayApi === 'COMPLETED' ? 'pointer' : 'default' }} /> */}
                        </div>
                        <div style={{
                            height: '85%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <BarVz axisBottom={{
                                tickSize: 4,
                                tickPadding: 5,
                                tickRotation: 45,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32
                            }} data={ordersPerDayData && ordersPerDayData.ordersPerDay} xKey={"date"} yKey={"count"}></BarVz>
                        </div>
                    </div>
                </div>
            </div>
            {/* style={{ gap: '10px' }} */}
            <div className="split-col" >
                <div className="card" style={{ height: '94%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="label" style={{ fontSize: 18, color: '#1F4173', fontWeight: 'bold', marginBottom: 16 }}>New vs Returning Customers</div>
                        {/* <SyncOutlined spin={repeatCustomersApi !== 'COMPLETED'} onClick={() => {
                            setRepeatCustomersApi(apiStatus[0]);
                        }} style={{ marginTop: -48, marginRight: -4, cursor: repeatCustomersApi === 'COMPLETED' ? 'pointer' : 'default' }} /> */}
                    </div>
                    <div style={{
                        height: 200,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <PieVz data={repeatCustomers} ></PieVz>
                    </div>
                </div>
                <div className="card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="label" style={{ fontSize: 18, color: '#1F4173', fontWeight: 'bold', marginBottom: 16 }}>Top Performing Outlets</div>
                        {/* <SyncOutlined spin={topOutletsApi !== 'COMPLETED'} onClick={() => {
                            setTopOutletsApi(apiStatus[0]);
                        }} style={{ marginTop: -48, marginRight: -4, cursor: topOutletsApi === 'COMPLETED' ? 'pointer' : 'default' }} /> */}
                    </div>
                    {topOutletsSales ? <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            background: '#e6e6e675',
                            padding: '0px 10px',
                            height: 40,
                            borderRadius: 4,
                            marginBottom: 10,
                            alignItems: 'center'
                        }}>
                            <div className="label" style={{ fontWeight: 'bold' }}>Outlet</div>
                            <div className="label" style={{ fontWeight: 'bold' }}>Sales</div>
                        </div>
                        <div style={{
                            height: 150,
                            overflowY: 'auto'
                        }}>
                            {topOutletsSales.map((item) => {
                                return <div key={item.name} style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '0px 10px',
                                    height: 40
                                }}>
                                    <div className="value row-ellipsis" title={item.name} style={{ fontSize: 14, color: '#121212' }}>{item.name}</div>
                                    <div className="value" style={{ fontSize: 14, color: '#121212' }}>{item.sales}</div>
                                </div>
                            })}
                        </div></> : <div style={{
                            display: 'flex',
                            height: '60%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>No data found</div>}
                </div>
            </div>
        </div>
    </div>
}

export default Dashboard;