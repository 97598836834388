import React, { useEffect, useState } from "react";
import { getStoreDetails, onUpdateSiteSettings } from "../../../../../../actions/brandAction";
import { useDispatch } from "react-redux";
import InfoSvg from "../../../../../../assets/images/settings/info-blue.svg";
import ClearSvg from "../../../../../../assets/images/settings/clear.svg";

const Google = () => {
    const dispatch = useDispatch();
    const [integrations, setIntegrations] = useState(null);
    const [googleAnalytics, setGoogleAnalytics] = useState([]);
    useEffect(() => {
        loadSettings();
        //eslint-disable-next-line    
    }, []);
    const loadSettings = async () => {
        const resp = await getStoreDetails()(dispatch);
        if (resp?.settings?.integrations) {
            setIntegrations(resp.settings.integrations)
            if (resp?.settings?.integrations?.googleAnalytics) {
                setGoogleAnalytics(resp?.settings?.integrations?.googleAnalytics.split(","));
            }
        }
    }
    const onRemoveKey = (key: string) => {
        const filtered = googleAnalytics.filter((ga) => ga !== key);
        setGoogleAnalytics(filtered);
    }
    const onSave = async () => {
        const payload = { integrations: { ...integrations, googleAnalytics: googleAnalytics.join(",") } };
        await onUpdateSiteSettings(payload)(dispatch);
    }
    return <div className="settings">
        <div className="content" style={{ flexDirection: 'row' }}>
            <div className="container" style={{ flexGrow: 1, margin: '0px 24px' }}>
                <div style={{ height: window.innerHeight - 80 }}>
                    <div className="division">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                            <div className="header">Google Analytics</div>
                            <button className="sp_btn" style={{ width: 'auto' }} onClick={onSave}>Save</button>
                        </div>
                        <div className="section">
                            <div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{ width: '50%' }}>
                                        <div className="title">
                                            Google Analytics Tag ID
                                            <img src={InfoSvg} onClick={() => window.open('https://support.google.com/analytics/answer/12326985?hl=en', '_blank')} alt="info" style={{ marginLeft: 8, cursor: "pointer" }} />
                                        </div>
                                        <div style={{ marginTop: 15 }} className="key">Google Analytics helps you see how users, visitors, and customers interact with your site</div>
                                    </div>
                                    <div style={{
                                        borderRadius: 4,
                                        border: "1px solid #D9D9D9",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "0px 8px 0px 8px",
                                        width: "50%"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            padding: "6px 0px 3px 0px"
                                        }}>{googleAnalytics.map((ga, index) => {
                                            return <><div key={`${ga}_${index}`} style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "7px 9px",
                                                borderRadius: 6,
                                                background: "#E2E8F0",
                                                color: "#5A6068",
                                                fontWeight: 400,
                                                fontSize: 14,
                                                marginRight: 6
                                            }}>
                                                <div style={{ textWrap: "nowrap" }}>{ga}</div>
                                                <img src={ClearSvg} alt="clear" onClick={() => {
                                                    onRemoveKey(ga);
                                                }} style={{ marginLeft: 8, cursor: "pointer" }} />
                                            </div>
                                                {
                                                    googleAnalytics.length - 1 === index && <input
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "7px 9px",
                                                            fontWeight: 400,
                                                            fontSize: 14,
                                                            marginRight: 6,
                                                            width: "auto"
                                                        }}
                                                        // prefix={<img src={WhatsappSvg} />}
                                                        name="GoogleAd"
                                                        placeholder="Enter the your key"
                                                        onBlur={(e) => {
                                                            const { value }: any = e.target;
                                                            if (value?.length > 0) {
                                                                setGoogleAnalytics([...googleAnalytics, value.trim()]);
                                                            }
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                const { value }: any = e.target;
                                                                if (value?.length > 0) {
                                                                    setGoogleAnalytics([...googleAnalytics, value.trim()]);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                }
                                            </>
                                        })}
                                            {
                                                googleAnalytics.length === 0 && <input
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "7px 9px",
                                                        fontWeight: 400,
                                                        fontSize: 14,
                                                        marginRight: 6,
                                                        width: "auto"
                                                    }}
                                                    // prefix={<img src={WhatsappSvg} />}
                                                    name="GoogleAd"
                                                    placeholder="Enter the your key"
                                                    onBlur={(e) => {
                                                        const { value }: any = e.target;
                                                        if (value?.length > 0) {
                                                            setGoogleAnalytics([...googleAnalytics, value.trim()]);
                                                        }
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            const { value }: any = e.target;
                                                            if (value?.length > 0) {
                                                                setGoogleAnalytics([...googleAnalytics, value.trim()]);
                                                            }
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div
                className="container"
                style={{
                    padding: '12px 18px',
                    height: '100%',
                    width: '26%',
                    background: '#FFFFFF',
                    boxShadow: '0px 3px 16px #DBE2EA6C',
                    flexShrink: 0
                }}>
                <div className="header" style={{ marginBottom: 12 }}>Google Analytics</div>
                <div className="title" style={{ marginBottom: 12 }}>Lorem ipsum dolor sit amet</div>
                <div className="value" style={{ marginBottom: 12, textAlign: 'justify' }}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    <br />
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                </div>
            </div> */}
        </div>
    </div>
}

export default Google;