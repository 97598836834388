import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import Item from "./Item"

const SortableItem = (props: any) => {
    const { item, createDom, isFinalItem } = props;
    const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id })

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    }

    return (
        <Item
            item={item}
            createDom={createDom}
            isFinalItem={isFinalItem}
            ref={setNodeRef}
            style={style}
            isOpacityEnabled={isDragging}
            {...props}
            {...attributes}
            {...listeners}
        />
    )
}

export default SortableItem
