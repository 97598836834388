import React, { memo } from "react";
import AppDsk from "./App.dsk";
import AppMob from "./App.mob";
import { BrowserRouter as Router } from "react-router-dom";
import { useDeviceDetect } from './helpers/util';

const DeviceWrap = (props: any) => {
    const device = useDeviceDetect();
    return <Router basename={process.env.PUBLIC_URL}> {device.isDesktop() ? <AppDsk /> : <AppMob />}</Router>
}

export default memo(DeviceWrap);