import { useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

export const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }
    return [htmlElRef, setFocus]
}

export const useNav = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const appData = params.get("appData");
    let staticParam = '';
    if (appData) {
        staticParam = staticParam + "?appData=" + appData;
    }
    const navigate = useNavigate();
    const navigateTo = (dynamicParam, state = {}) => {
        if (typeof dynamicParam === 'number') {
            navigate(dynamicParam);
        } else {
            navigate(`${dynamicParam}${staticParam}`, state);
        }
    };
    return navigateTo;
};