import AccountSvg from "../../../assets/images/settings/account.svg";
import IntegrationsSvg from "../../../assets/images/settings/integrations.svg";
// import AppSvg from "../../../assets/images/settings/app_generation.svg";
// import BankSvg from "../../../assets/images/settings/bank.svg";
import BankDetailSvg from "../../../assets/images/settings/bank_detail.svg";
// import BillSvg from "../../../assets/images/settings/bill.svg";
import BotSvg from "../../../assets/images/settings/bot.svg";
// import BrandSvg from "../../../assets/images/settings/brand.svg";
import BusinessHoursSvg from "../../../assets/images/settings/business_hours.svg";
import HolidaysSvg from "../../../assets/images/settings/holidays.svg";
import ChannelSvg from "../../../assets/images/settings/channel.svg";
// import ChartSvg from "../../../assets/images/settings/chart.svg";
import CheckoutSvg from "../../../assets/images/settings/checkout.svg";
// import CodeSvg from "../../../assets/images/settings/code.svg";
import DeliveryChargesSvg from "../../../assets/images/settings/delivery_charges.svg";
import DeliverySlotsSvg from "../../../assets/images/settings/delivery_slots.svg";
// import EmailSvg from "../../../assets/images/settings/email.svg";
import FacebookSvg from "../../../assets/images/settings/facebook.svg";
// import FacebookPixelSvg from "../../../assets/images/settings/facebook_pixel.svg";
import FaqSvg from "../../../assets/images/settings/faq.svg";
// import FeaturesSvg from "../../../assets/images/settings/features.svg";
import GoogleAdsSvg from "../../../assets/images/settings/google_ads.svg";
import InstagramSvg from "../../../assets/images/settings/instagram.svg";
import LegalSvg from "../../../assets/images/settings/legal.svg";
// import LiveChatSvg from "../../../assets/images/settings/live_chat.svg";
import MarketingSvg from "../../../assets/images/settings/marketing.svg";
// import MixpanelSvg from "../../../assets/images/settings/mixpanel.svg";
import MobileSvg from "../../../assets/images/settings/mobile.svg";
import NotificationSvg from "../../../assets/images/settings/notification.svg";
// import PhoneSvg from "../../../assets/images/settings/phone.svg";
// import SiteConfigurationSvg from "../../../assets/images/settings/site_configuration.svg";
// import SocialLoginSvg from "../../../assets/images/settings/social_login.svg";
import StoreSvg from "../../../assets/images/settings/store.svg";
import SupportSvg from "../../../assets/images/settings/support.svg";
// import TaxSvg from "../../../assets/images/settings/tax.svg";
import WebSvg from "../../../assets/images/settings/web.svg";
import WhatsappSvg from "../../../assets/images/settings/whatsapp.svg";
// import YoutubeSvg from "../../../assets/images/settings/youtube.svg";
import BlogSvg from "../../../assets/images/settings/blog.svg";
import PageRedirectSvg from "../../../assets/images/settings/page-redirect.svg";
import MediaSvg from "../../../assets/images/settings/media.svg";
import GeneralSvg from "../../../assets/images/settings/general.svg";
import PagesSvg from "../../../assets/images/settings/pages.svg";
import OutletSvg from "../../../assets/images/settings/outlet.svg";
import AnalyticsSvg from "../../../assets/images/settings/analytics.svg";

export const Categories = [{
    icon: StoreSvg,
    key: "store",
    label: "Store",
    desc: "Customise the branding and page setup"
}, {
    icon: ChannelSvg,
    key: "channels",
    label: "Channels",
    desc: "Customise your shop across multiple channels"
}, {
    icon: SupportSvg,
    key: "support",
    label: "Support",
    desc: "Manage your customer support platforms"
}, {
    icon: MarketingSvg,
    key: "marketing",
    label: "Marketing",
    desc: "Advertise your shop"
}, {
    icon: IntegrationsSvg,
    key: "integrations",
    label: "integrations",
    desc: "Customise the branding and page setup"
}, {
    icon: AccountSvg,
    key: "account",
    label: "Account",
    desc: "Setup your bank payout account"
}];

export const Menus = {
    store: [{
        icon: GeneralSvg,
        key: "general",
        label: "General",
        desc: "Manage your site status and brand configuration",
        tags: ["about", "site status", "social links", "brand", "page"]
    }, {
        icon: CheckoutSvg,
        key: "checkout",
        label: "Checkout",
        desc: "Manage order values, delivery mode and payment modes",
        tags: ["auto confirmation", "payment modes", "order value"]
    }, {
        icon: DeliveryChargesSvg,
        key: "additionalcharges",
        label: "Additional Charges",
        desc: " Setup and manage packaging, delivery or any additional charges for orders" // 
    }, {
        icon: OutletSvg,
        key: "outlet",
        label: "Outlets",
        desc: "Setup and manage shop branches or outlets for customers to place orders"
    }, {
        icon: LegalSvg,
        key: "user",
        label: "Users",
        desc: "Add or manage access of your staff"
    }, {
        icon: DeliverySlotsSvg,
        key: "deliveryslots",
        label: "Delivery Slots",
        desc: "Setup and manage delivery timings or slots for orders"
    }, {
        icon: BusinessHoursSvg,
        key: "businesshours",
        label: "Business Hours",
        desc: "Set your store working hours",
    }, {
        icon: HolidaysSvg,
        key: "holidays",
        label: "Holidays",
        desc: "Manage your store's holiday dates",
        // }, {
        //     icon: MixpanelSvg,
        //     key: "themeconfiguration",
        //     label: "Theme Configuration",
        //     desc: "Setup your shop site theming",
        //     tags: ["theme"]
        // }, {
        //     icon: LegalSvg,
        //     key: "theme",
        //     label: "Brand Theme",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
        // }, {
        //     icon: SocialLoginSvg,
        //     key: "sociallogin",
        //     label: "Social Login",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
    }, {
        icon: BlogSvg,
        key: "blogs/post",
        label: "Blogs",
        root: true,
        tag: ["blog", "post"],
        desc: "Create your own blogs for the shop site",
    }, {
        icon: PagesSvg,
        key: "pages",
        label: "Pages",
        root: true,
        tag: ["page"],
        desc: "Create custom pages for your shop site",
    }, {
        icon: PageRedirectSvg,
        key: "pageredirects",
        label: "Page Redirects",
        tag: ["Page Redirects", "redirects"],
        desc: "Redirect your old webpage links to shopletzy's domain",
    }, {
        icon: MediaSvg,
        key: "media",
        label: "Media",
        root: true,
        tag: ["media", "upload files"],
        desc: "Upload media files for your shop site",
    }, {
        icon: BotSvg,
        root: true,
        key: "bot",
        label: "Bot Configuration",
        desc: "Setup your shop bot",
        tags: ["bot"]
    }],
    channels: [{
        icon: WebSvg,
        key: "web",
        label: "Web",
        desc: "Setup custom domains and web channel branding",
        tags: ["branding", "code injection", "custom domain"]
    }, {
        icon: MobileSvg,
        key: "mobile",
        label: "Mobile",
        desc: "Lorem ipsum dolor sit amet, sed consetetur",
        tags: ["Android", "iOS", "App"]
    }, {
        icon: WhatsappSvg,
        key: "whatsapp",
        label: "Whatsapp",
        desc: "Configure your whatsapp number for customer support"
    },
    {
        icon: InstagramSvg,
        key: "instagram",
        label: "Instagram",
        desc: "Configure your instagram account for customer support"
    },
    {
        icon: FacebookSvg,
        key: "facebook",
        label: "Facebook",
        desc: "Configure your facebook account for customer support"
    }
        //   {
        //     icon: PhoneSvg,
        //     key: "phone",
        //     label: "Phone",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
        // }, {
        //     icon: FacebookSvg,
        //     key: "facebook",
        //     label: "Facebook",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
        // }, {
        //     icon: EmailSvg,
        //     key: "email",
        //     label: "Email",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
    ],
    support: [{
        icon: FaqSvg,
        key: "faq",
        label: "FAQ",
        desc: "Setup and manage FAQs of customers"
        // }, {
        //     icon: LiveChatSvg,
        //     key: "livechat",
        //     label: "Live Chat",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
        // }, {
        //     icon: EmailSvg,
        //     key: "email",
        //     label: "Email",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
        // }, {
        //     icon: PhoneSvg,
        //     key: "phone",
        //     label: "Phone",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
    }],
    marketing: [{
        root: true,
        icon: NotificationSvg,
        key: "notifications",
        label: "Notifications",
        desc: "Lorem ipsum dolor sit amet, sed consetetur"
    }, {
        icon: GoogleAdsSvg,
        key: "googleads",
        label: "Google Ads",
        desc: "Coming Soon.."
        // }, {
        //     icon: YoutubeSvg,
        //     key: "youtubeads",
        //     label: "YouTube Ads",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
    }],
    integrations: [{
        icon: AnalyticsSvg,
        key: "analytics",
        label: "Analytics",
        desc: "Lorem ipsum dolor sit amet, sed consetetur",
        tags: ["google analytics", "facebook pixel", "mixpanel", "microsoft clarity", "hotjar"]
        // }, {
        //     icon: FacebookPixelSvg,
        //     key: "facebookpixel",
        //     label: "Facebook Pixel",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
        // }, {
        //     icon: MixpanelSvg,
        //     key: "mixpanel",
        //     label: "Mixpanel",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
    }],
    account: [{
        icon: BankDetailSvg,
        key: "bankdetails",
        label: "Bank Details",
        desc: "Configure your bank payout account for settlements"
        // }, {
        //     icon: BillSvg,
        //     key: "plansbillings",
        //     label: "Plans Billings",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
        // }, {
        //     icon: BillSvg,
        //     key: "plandetail",
        //     label: "Plan Detail",
        //     hide: true,
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
        // }, {
        //     icon: TaxSvg,
        //     key: "tax",
        //     label: "Tax",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
        // }, {
        //     icon: TaxSvg,
        //     key: "security",
        //     label: "Security",
        //     desc: "Lorem ipsum dolor sit amet, sed consetetur"
    }]
}

export const SettingsConstants = {
    "logo": "",
    "favicon": "",
    "socialLinks": {
        "facebook": "",
        "twitter": "",
        "instagram": "",
        "youtube": "",
        "pinterest": ""
    },
    "entryPage": "",
    "integrations": {
        "googleAnalytics": ""
    },
    "codeInjection": {
        "header": "",
        "footer": ""
    },
    "cancellation": {
        "allowed": false,
        "timeLimit": null,
        "fixedFee": null,
        "percentageFee": null,
        "minFee": null
    },
    "minOrderValue": null,
    "paymentModes": {
        "items": "online"
    },
    "holidays": "",
    "storePickupDisabled": false,
    "deliveryDisabled": false
}

export const SettingsMenuLabels = {
    "appconfiguration": "App Configuration",
}