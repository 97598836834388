import React, { useEffect } from 'react';
import { useSelector, connect } from "react-redux";
import WebView from "./WebView";
// import _ from "lodash";
import MobileView from "./MobileView";
import { defaultDskThemeProps, defaultMobThemeProps } from "./theme";
import "../../../../assets/sass/dsk/theme.sass";

export const ThemeContext = React.createContext({
    dskThemeProps: defaultDskThemeProps, setDskThemeProps: (arg: any) => { },
    mobThemeProps: defaultMobThemeProps, setMobThemeProps: (arg: any) => { }
});

const Theme = (props: any) => {
    const tabMenu = useSelector((state: any) => state.ui.selectedTabMenu);
    const [dskThemeProps, setDskThemeProps] = React.useState(defaultDskThemeProps);
    const [mobThemeProps, setMobThemeProps] = React.useState(defaultMobThemeProps);
    const iFrameMob: any = document.getElementById("slz_shop_mob_site");
    const iFrameWeb: any = document.getElementById("slz_shop_web_site");
    // const selectedSettingsMenu = useSelector((state: any) => state.ui.selectedSettingsMenu);
    // useEffect(() => {
    //     if (!selectedSettingsMenu) {
    //         console.log("selectedSettingsMenu ", selectedSettingsMenu)
    //         props.history.push("/settings");
    //     }
    // }, [selectedSettingsMenu])
    useEffect(() => {
        if (iFrameWeb && iFrameWeb.contentWindow && tabMenu === "web_view") {
            iFrameWeb.contentWindow.postMessage({
                theme: dskThemeProps,
                event: "theme_dsk_update",
            },
                "*"
            );
        }
    }, [dskThemeProps, iFrameWeb, tabMenu]);

    useEffect(() => {
        if (iFrameMob && iFrameMob.contentWindow && tabMenu === "mobile_view") {
            iFrameMob.contentWindow.postMessage({
                theme: mobThemeProps,
                event: "theme_mob_update",
            },
                "*"
            );
        }
    }, [mobThemeProps, iFrameMob, tabMenu]);
    // const apiDskTheme = {
    //     general: {
    //         fontFamily: "AvenirMedium"
    //     }
    // };
    // const apiMobTheme = {
    //     general: {
    //         fontFamily: "AvenirMedium"
    //     }
    // };
    // React.useEffect(() => {
    //     setDskThemeProps(_.merge({}, dskThemeProps, apiDskTheme));
    //     setMobThemeProps(_.merge({}, mobThemeProps, apiMobTheme));
    // }, []);
    return (
        <div className="theme">
            <ThemeContext.Provider
                value={{
                    mobThemeProps,
                    setMobThemeProps,
                    dskThemeProps,
                    setDskThemeProps,
                }}
            >
                {tabMenu === "web_view" && <WebView key="WebView" dskThemeProps={dskThemeProps} setDskThemeProps={setDskThemeProps} {...props}></WebView>}
                {tabMenu === "mobile_view" && <MobileView key="MobileView" mobThemeProps={mobThemeProps} setMobThemeProps={setMobThemeProps} {...props}></MobileView>}
            </ThemeContext.Provider>
        </div>
    );
}

export default connect(null, {})(Theme);