import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from '../store';
import { ErrorCodes } from "../errorCodes";
import axiosWrapper from "./axiosWrapper";

export const getProducts = (ouId = null) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.productsUrl.replace('{sId}', sId)}`;
            // url = ouId ? `${url}&ouId=${ouId}` : url;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data.products : [];
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message && error.response.status) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Products Failed",
                        message: ErrorCodes[error.response.status],
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const productsExport = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        responseType: "blob"
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.productsExportUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message && error.response.status) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Export Products Failed",
                        message: ErrorCodes[error.response.status],
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const getProductById = (pId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.saveProductUrl.replace('{productId}', pId).replace('{sId}', sId)}`;
            // url = ouId ? `${url}&ouId=${ouId}` : url;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message && error.response.status) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Product By Id Failed",
                        message: ErrorCodes[error.response.status],
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const getListedProductVariants = (ouId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getListedProductsUrl.replace('{sId}', sId).replace('{ouId}', ouId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data.variants : [];
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message && error.response.status) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Listed Products Failed",
                        message: ErrorCodes[error.response.status],
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const getProductByIdOuId = (productId, ouId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.productByIdOutletUrl.replace('{sId}', sId).replace('{productId}', productId).replace('{ouId}', ouId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message && error.response.status) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Product And Outlet Failed",
                        message: ErrorCodes[error.response.status],
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export function saveProductVariants(productId, ouId, payload) {
    const options = {
        method: 'PUT',
        data: payload,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    let url = `${config.baseUrl}${config.productByIdOutletUrl.replace('{sId}', sId).replace('{productId}', productId).replace('{ouId}', ouId)}`;
    return async (dispatch) => {
        try {
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Product Variants Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Authentication', error);
            }
            return null;
        }
    }
}

export const updateInventory = (ouId, payload) => {
    const options = {
        method: 'PUT',
        data: payload,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    let url = `${config.baseUrl}${config.updateInventoryUrl.replace('{sId}', sId).replace('{ouId}', ouId)}`;
    return async (dispatch) => {
        try {
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Product Inventory Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Authentication', error);
            }
            return null;
        }
    }
}

export const getProductOptions = (query) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getProductOptionsUrl.replace('{sId}', sId)}?q=${query}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message && error.response.status) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Product Options Failed",
                        message: ErrorCodes[error.response.status],
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export function createProduct(payload) {
    const options = {
        method: 'POST',
        data: payload,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    let url = `${config.baseUrl}${config.productsUrl.replace('{sId}', sId)}`;
    return async (dispatch) => {
        try {
            const resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.SAVE_PRODUCTS_SUCCESS,
                data: resp.data
            });
            return resp.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Create Product Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Authentication', error);
            }
            return null;
        }
    };
}

export function removeProductFromOutlet(pId, ouId) {
    const options = {
        method: 'PUT',
        data: { ouId },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    let url = `${config.baseUrl}${config.removeProductFromOutletUrl.replace('{productId}', pId).replace('{sId}', sId)}`;

    return async (dispatch) => {
        try {
            const resp = await axiosWrapper(url, options, { dispatch });
            return resp.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Remove Product From Outlet Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Authentication', error);
            }
            return null;
        }
    };
}

export function updateProduct(pId, payload) {
    const options = {
        method: 'PUT',
        data: payload,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    let url = `${config.baseUrl}${config.saveProductUrl.replace('{productId}', pId).replace('{sId}', sId)}`;
    return async (dispatch) => {
        try {
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.SAVE_PRODUCTS_SUCCESS,
                data: { ...resp.data, ...{ productId: pId } }
            });
            return resp.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Products Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Authentication', error);
            }
            return null;
        }
    }
}

export function bulkImport(payload) {
    const options = {
        method: 'POST',
        data: payload,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    let url = `${config.baseUrl}${config.bulkImportUrl.replace('{sId}', sId)}`;
    return async (dispatch) => {
        try {
            let respDeleteDetails = await axiosWrapper(url, options, { dispatch });
            return respDeleteDetails;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Bulk Import Products Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
            return { error: true };
        }
    };
}

export function uploadFile(pId, bodyFormData) {
    const options = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`,
            "Content-Type": "multipart/form-data"
        },
        data: bodyFormData
    };
    const sId = store.getState().ui?.selectedStore?.id;
    let url = `${config.baseUrl}${config.uploadProductImageUrl.replace('{productId}', pId).replace('{sId}', sId)}`;
    return async (dispatch) => {
        try {
            let respUploadDetails = await axiosWrapper(url, options, { dispatch });
            return respUploadDetails;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Product Upload Files Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export function deleteFile(pId, payload) {
    const options = {
        method: 'PUT',
        data: payload,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    let url = `${config.baseUrl}${config.deleteProductImageUrl.replace('{productId}', pId).replace('{sId}', sId)}`;
    return async (dispatch) => {
        try {
            let respDeleteDetails = await axiosWrapper(url, options, { dispatch });
            return respDeleteDetails;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Delete Product Files Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}