import { store } from "../store";

const getMobileDetect = (userAgent) => {
    let forceDeviceType = null;
    if (store && store.getState().common.forceDeviceType) {
        forceDeviceType = store.getState().common.forceDeviceType;
    }
    const isAndroid = () => Boolean(userAgent.match(/Android/i))
    const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
    const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
    const isWindows = () => Boolean(userAgent.match(/IEMobile/i))
    const isSSR = () => Boolean(userAgent.match(/SSR/i))
    const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows() || forceDeviceType === "mobile")
    const isDesktop = () => Boolean(!isMobile() && !isSSR())
    return {
        isMobile,
        isDesktop,
        isAndroid,
        isIos,
        isSSR,
    }
}
export const useDeviceDetect = () => {
    const { userAgent } = window.navigator;
    return getMobileDetect(userAgent)
}

export const isMail = (email) => {
    return email.toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isMobile = (data) => {
    const regEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    return data.length === 10 && regEx.test(data);
}

export const isPassword = (data) => {
    if (
        data === undefined ||
        data === null ||
        data.trim() === "" ||
        data === "undefined" ||
        data === "null" ||
        (data && data.length < 8)
    ) {
        return false;
    }
    return true;
};

export const isBoolean = (data) => {
    if (
        data === undefined ||
        data === null ||
        data === "undefined" ||
        data === "null"
    ) {
        return false;
    }
    return true;
};

export const isNotNullOrUndefinedString = (data) => {
    if (
        data === undefined ||
        data === null ||
        data.trim() === "" ||
        data === "undefined" ||
        data === "null"
    ) {
        return false;
    }
    return true;
};

export const parseParams = (querystring) => {
    // parse query string
    const params: any = new URLSearchParams(querystring);
    const obj = {};
    // iterate over all keys
    for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
            obj[key] = params.getAll(key);
        } else {
            obj[key] = params.get(key);
        }
    }
    return obj;
};

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

export const generateUniqueId = (prefix) => {
    const rand = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };
    return `${prefix}_${rand()}${rand()}-${rand()}-${rand()}`;
};

export const b64DecodeUnicode = (str) => {
    var decodeStr = null;
    try {
        decodeStr = decodeURIComponent(window.atob(str));
    } catch (e) {
        console.error('Error b64 Decode catch')
        decodeStr = window.atob(str)
    } finally {
        return decodeStr;
    }
}

export const humanFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }

    const units = si
        ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
};

export const b64Encode = (str) => {
    var b64 = null;
    try {
        b64 = window.btoa(encodeURIComponent(str));
    } catch (e) {
        console.error('Error b64 Encode catch')
        b64 = window.btoa(str)
    } finally {
        return b64;
    }
}

export const isDark = (color) => {
    const c = color.substring(1); // strip #
    const rgb = parseInt(c, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue
    const luma = 0.2126 + r + 0.7152 + g + 0.0722 * b; // per ITU-R BT.709
    return luma < 128;
};

export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const stringToHslColor = (str, s, l) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

export const deepClone = (obj) => {
    console.log(typeof obj === "object")
    return typeof obj === "object" ? JSON.parse(JSON.stringify(obj)) : obj;
}

export const uuidv4 = () => {
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : ((r && 0x3) || 0x8)).toString(16);
    });
}

export const encodeQueryData = (data) => {
    const params = [];
    if (typeof data === "object" && !(data instanceof Object && data instanceof Array)) {
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                params.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
            }
        }
    } else if (typeof Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
            for (let key in data[i]) {
                if (data[i].hasOwnProperty(key)) {
                    params.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[i][key]));
                }
            }
        }
    }
    const queryString = params.join('&');
    return `?${queryString}`;
}

export const getPermission = (roles) => {
    let permissions: any = [];
    if (roles) {
        for (let r of roles) {
            let arr = [...permissions, ...r.permissions];
            permissions = [...new Set(arr)]
        }
    }
    return permissions;
}

export const stopMediaPlayback = () => {
    // Stop video playback
    const videos = document.querySelectorAll('video');
    videos.forEach(video => {
        if (!video.paused) {
            video.pause();
        }
    });

    // Stop audio playback
    const audios = document.querySelectorAll('audio');
    audios.forEach(audio => {
        if (!audio.paused) {
            audio.pause();
        }
    });
};

export const toLowerCaseKeysAndValues = (obj: Record<string, any>): Record<string, any> => {
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
            key.toLowerCase(),
            typeof value === 'string' ? value.toLowerCase() : value
        ])
    );
}

export const areObjectsDifferent = (obj1, obj2) => {
    if (obj1 && obj2) {
        obj1 = toLowerCaseKeysAndValues(obj1);
        obj2 = toLowerCaseKeysAndValues(obj2);
        let keys1 = Object.keys(obj1);
        let keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) return true;
        for (let key of keys1) {
            if (obj1[key] !== obj2[key]) {
                return true; // Different key-value pair found
            }
        }
        return false; // No differences found
    } else {
        return false;
    }
}