import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { getOutlets } from '../../../actions/outletAction';

const Order = (props: any) => {
    const dispatch = useDispatch();
    useEffect(() => {
        loadOutlets();
        //eslint-disable-next-line
    }, []);
    const loadOutlets = async () => {
        await getOutlets()(dispatch);
    }
    return (
        <div className="menu">
            {<Outlet />}
        </div>
    );
}

export default Order;