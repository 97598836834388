import { Input, Upload } from 'antd';
import React, { useState, useEffect } from 'react';
import { getBase64 } from '../../../../../../../helpers/util';
import UploadSvg from '../../../../../../../assets/images/upload.svg';
import ColorPalette from '../../../../../common/ColorPalette';
import apiConfig from '../../../../../../../apiConfig';
import { CloseCircleFilled } from '@ant-design/icons';
import { useOutletContext } from 'react-router-dom';

const Landing = (props: any) => {
    const [onSave, isSave, webTheme]: any = useOutletContext();
    const [url, setUrl] = useState(null);
    const [mainTitle, setMainTitle] = useState({
        label: '',
        color: ''
    });
    const [subTitle, setSubTitle] = useState({
        label: '',
        color: ''
    });
    const [backgroundColor, setBackgroundColor] = useState(null);
    const [removeBGIcon, setRemoveBGIcon] = useState(false);
    const [deleteUrl, setDeleteUrl] = useState(null);
    const [file, setFile] = useState(null);
    const [selectedLayout, setSelectedLayout] = useState(null);
    const [location, setLocation] = useState(null);

    useEffect(() => {
        if (webTheme) {
            const { landingPage } = webTheme;
            if (landingPage) {
                const { content: dContent, backgroundColor: DBackgroundColor, url: dUrl, layout } = landingPage
                if (dContent) {
                    const { mainTitle: dMainTitle, subTitle: dSubTitle, location: dLocation } = dContent;
                    setMainTitle(dMainTitle);
                    setSubTitle(dSubTitle);
                    setUrl(dUrl);
                    setSelectedLayout(layout);
                    setBackgroundColor(DBackgroundColor);
                    setLocation(dLocation);
                }

            }
        }
    }, [webTheme])

    const onPreviewImg = async (f) => {
        const prevImg = await getBase64(f.originFileObj);
        f.preview = prevImg;
        setDeleteUrl(url);
        setUrl(prevImg);
        setFile(f);
    }

    const handleChange = ({ fileList }) => {
        if (fileList && fileList.length > 1) {
            fileList.shift();
        }
        if (fileList && fileList.length > 0) {
            onPreviewImg(fileList[0]);
        }
    };

    useEffect(() => {
        if (isSave) {
            let payload = {
                layout: selectedLayout,
                url,
                backgroundColor,
                content: {
                    mainTitle,
                    subTitle
                },
                location
            };
            if (deleteUrl) {
                payload['deleteUrl'] = deleteUrl;
            }
            if (file) {
                payload['file'] = file;
            }
            onSave("Landing", payload);
        }
        //eslint-disable-next-line
    }, [isSave])

    return <div className="content" style={{ flexDirection: 'row' }}>
        <div className="container" style={{ flexGrow: 1, padding: 0 }}>
            <div className='home-page'>
                <div className="section" style={{ display: 'flex', width: '100%' }}>
                    <div className="desc" style={{ width: '40%' }}>
                        <div className="title">Page Background Picture</div>
                        <div style={{ flexGrow: 1 }} className="subtitle" > Set your landing background picture here SVG, PNG or JPG</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <Upload
                                customRequest={() => { return false; }}
                                multiple={false}
                                showUploadList={false}
                                onChange={handleChange}
                            >
                                <div className="upload_icon">
                                    <img alt='Upload Icon' src={UploadSvg} />
                                    <div>Choose or drag & drop</div>
                                </div>
                            </Upload>
                        </div>
                        <div
                            onMouseEnter={() => {
                                setRemoveBGIcon(true);
                            }}
                            onMouseLeave={() => {
                                setRemoveBGIcon(false);
                            }}
                            style={{
                                position: 'relative',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                marginLeft: 20
                            }}>
                            {url && <img alt='BG Icon' style={{ height: 136, objectFit: 'contain' }} src={url.indexOf('data:image') > -1 ? url : `${apiConfig.baseImageUrl}${url}`} />}
                            {url && <div onClick={() => {
                                setDeleteUrl(url);
                                setUrl(null);
                            }} style={{ borderRadius: 50, boxShadow: '0px 0px 10px #0000003D', position: 'absolute', background: "#FFF", cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CloseCircleFilled style={{ transition: 'all', fontSize: removeBGIcon ? 30 : 0, color: "#FF0000" }} />
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="section" style={{ display: 'flex', width: '100%' }}>
                    <div className="desc" style={{ width: '40%' }}>
                        <div className="title">Background Color</div>
                        <div style={{ flexGrow: 1 }} className="subtitle" > Set your background colour</div>
                    </div>
                    <div style={{
                        flexDirection: 'column',
                    }}>
                        <ColorPalette defaultColor={backgroundColor} onColorChange={(color) => {
                            setBackgroundColor(color)
                        }}></ColorPalette>
                    </div>
                </div>
                <div className="section" style={{ display: 'flex', width: '100%' }}>
                    <div className="desc" style={{ width: '40%' }}>
                        <div className="title">Title</div>
                        <div style={{ flexGrow: 1 }} className="subtitle" >Set your landing screen title here</div>
                    </div>
                    <div style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <div style={{ flexGrow: 1, marginBottom: 20 }}>
                            <Input style={{ width: 400 }} value={mainTitle.label} defaultValue={mainTitle.label} onChange={(e) => setMainTitle({ ...mainTitle, label: e.target.value })} className="input" placeholder="Enter title here" />
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <ColorPalette label={"Set title color"} defaultColor={mainTitle.color} onColorChange={(color) => {
                                setMainTitle({ ...mainTitle, color: color })
                            }}></ColorPalette>
                        </div>
                    </div>
                </div>
                <div className="section" style={{ display: 'flex', width: '100%' }}>
                    <div className="desc" style={{ width: '40%' }}>
                        <div className="title">Sub Title</div>
                        <div style={{ flexGrow: 1 }} className="subtitle" >Set your landing screen subtitle here</div>
                    </div>
                    <div style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <div style={{ flexGrow: 1, marginBottom: 20 }}>
                            <Input style={{ width: 400 }} value={subTitle.label} defaultValue={subTitle.label} onChange={(e) => setSubTitle({ ...subTitle, label: e.target.value })} className="input" placeholder="Enter subtitle here" />
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <ColorPalette label={"Set sub title color"} defaultColor={subTitle.color} onColorChange={(color) => {
                                setSubTitle({ ...subTitle, color: color })
                            }}></ColorPalette>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Landing;