import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Drawer, Row, Switch, Select } from "antd";
import React, { useEffect, useState } from "react";
import InputValidation from "../common/InputValidation";
import { getBillAccount, updateBillingAccount } from "../../../actions/billingAction";
import { useDispatch } from "react-redux";
import Loading from "../common/Loading";
import StateCodes from "../../../helpers/StateCode";

const { Option } = Select;
const BillingInformation = () => {
    const info = {
        "fullname": "",
        "isRegistered": false,
        "businessName": "",
        "email": "",
        "phone": "",
        "addressLine": "",
        "city": "",
        "state": "",
        "zipcode": "",
        "country": "IN",
        "taxInfo": {
            "gstin": ""
        }
    }
    let errorInfo = {
        fullname: {
            message: "",
            type: "",
        },
        email: {
            message: "",
            type: "",
        },
        addressLine: {
            message: "",
            type: "",
        },
        city: {
            message: "",
            type: "",
        },
        state: {
            message: "",
            type: "",
        },
        phone: {
            message: "",
            type: "",
        },
        zipcode: {
            message: "",
            type: "",
        },
        businessName: {
            message: "",
            type: ""
        }
    };
    const dispatch = useDispatch();
    const [error, setError] = useState(errorInfo);
    const WindowHeight = window.innerHeight;
    const [isExistingBilling, setIsExistingBilling] = useState(false);
    const [billInformation, setBillInformation] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [billInformationEdit, setBillInformationEdit] = useState(info);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        getBillingInformation();
        //eslint-disable-next-line
    }, []);
    const getBillingInformation = async () => {
        setIsLoading(true);
        const resp = await getBillAccount()(dispatch);
        if (resp) {
            setIsExistingBilling(true);
            setBillInformation(resp);
        } else {
            setBillInformation(info);
        }
        setIsLoading(false);
    }
    const onClose = () => {
        setIsVisible(false);
    }
    const onHandleSubmit = async () => {
        // setIsVisible(false);        
        let isError = false;
        if (!billInformationEdit.fullname) {
            errorInfo.fullname.message = "Full name is required";
            errorInfo.fullname.type = "error";
            isError = true;
        }
        if (!billInformationEdit.email) {
            errorInfo.email.message = "Email is required";
            errorInfo.email.type = "error";
            isError = true;
        }
        if (!billInformationEdit.addressLine) {
            errorInfo.addressLine.message = "Address line is required";
            errorInfo.addressLine.type = "error";
            isError = true;
        }
        if (!billInformationEdit.city) {
            errorInfo.city.message = "City is required";
            errorInfo.city.type = "error";
            isError = true;
        }
        if (!billInformationEdit.state) {
            errorInfo.state.message = "State is required";
            errorInfo.state.type = "error";
            isError = true;
        }

        if (!billInformationEdit.phone) {
            errorInfo.phone.message = "Phone is required";
            errorInfo.phone.type = "error";
            isError = true;
        }
        if (!billInformationEdit.zipcode) {
            errorInfo.zipcode.message = "Zipcode is required";
            errorInfo.zipcode.type = "error";
            isError = true;
        }
        if (billInformationEdit.isRegistered && !billInformationEdit.businessName) {
            errorInfo.businessName.message = "Business name is required";
            errorInfo.businessName.type = "error";
            isError = true;
        }
        if (!isError) {
            const isStatus = await updateBillingAccount(billInformationEdit)(dispatch);
            if (isStatus) {
                setBillInformationEdit(info);
                setIsVisible(false);
                getBillingInformation();
            }
        } else {
            setError({ ...errorInfo });
        }

    }
    return <div className="billing-information">
        {isLoading && <Loading background="#00000070"></Loading>}
        {isExistingBilling && <div className="wrapper">
            <div className="row" style={{ justifyContent: 'space-between' }}>
                <div className="title">Billing Information</div>
                {billInformation && <div className="edit" onClick={() => { setBillInformationEdit(billInformation); setIsVisible(true); }}>Edit</div>}
            </div>
            {billInformation && <div className="details">
                <div className="row">
                    <div className="label">
                        Full Name
                    </div>
                    <div className="value">
                        {billInformation.fullname}
                    </div>
                </div>
                <div className="row" style={{ marginTop: 20 }}>
                    <div className="label">
                        Email Address
                    </div>
                    <div className="value">
                        {billInformation.email}
                    </div>
                </div>
                <div className="row" style={{ marginTop: 20 }}>
                    <div className="label">
                        Billing Address
                    </div>
                    <div className="value">
                        {billInformation.addressLine}
                    </div>
                </div>
                <div className="row" style={{ marginTop: 20 }}>
                    <div className="label">
                        City
                    </div>
                    <div className="value">
                        {billInformation.city}
                    </div>
                </div>
                <div className="row" style={{ marginTop: 20 }}>
                    <div className="label">
                        State
                    </div>
                    <div className="value">
                        {StateCodes.filter((b) => b.stateCode === billInformation?.state)[0]?.state}
                    </div>
                </div>
                <div className="row" style={{ marginTop: 20 }}>
                    <div className="label">
                        Zipcode
                    </div>
                    <div className="value">
                        {billInformation.zipcode}
                    </div>
                </div>
                <div className="row" style={{ marginTop: 20 }}>
                    <div className="label">
                        Country
                    </div>
                    <div className="value">
                        {billInformation.country}
                    </div>
                </div>
                {billInformation.isRegistered && <div className="row" style={{ marginTop: 20 }}>
                    <div className="label">
                        Company Name
                    </div>
                    <div className="value">
                        {billInformation.businessName}
                    </div>
                </div>}
                <div className="row" style={{ marginTop: 20 }}>
                    <div className="label">
                        GSTIN
                    </div>
                    <div className="value">
                        {billInformation?.taxInfo?.gstin}
                    </div>
                </div>
            </div>}
        </div>}
        {billInformation !== null && !isExistingBilling && <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: WindowHeight - 200, flexDirection: 'column' }}>
            <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Billing Address</div>
            <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to update your Billing Address.</div>
            <div style={{ display: 'flex' }}>
                <div className="sp_btn" onClick={() => { setBillInformation(info); setIsVisible(true); }}>Add Billing Address</div>
            </div>
        </div>}
        <Drawer
            closable={false}
            onClose={onClose}
            width={898}
            className="billing-information-edit"
            open={isVisible}
        >
            <div className="header">
                <div style={{ display: 'flex' }}>
                    <ArrowLeftOutlined className="arrow" onClick={onClose}></ArrowLeftOutlined>
                    <div className="title">Edit Billing Address</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="sp_btn" onClick={onClose} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                    <div className="sp_btn" onClick={onHandleSubmit} style={{ width: 120, fontSize: 14 }}>Submit</div>
                </div>
            </div>
            <Row>
                <Col span={24} className="content" style={{ height: WindowHeight - 80 + "px", overflow: 'auto' }}>
                    <Row>
                        <Col span={11}>
                            <div className="group">
                                <div className="label">Full Name *</div>
                                <InputValidation type={error.fullname.type} message={error.fullname.message}>
                                    <input type="text" value={billInformationEdit?.fullname} onChange={(e) => { setBillInformationEdit({ ...billInformationEdit, 'fullname': e.target.value }) }} />
                                </InputValidation>
                            </div>
                            <div className="group">
                                <div className="label">Email *</div>
                                <InputValidation type={error.email.type} message={error.email.message}>
                                    <input type="text" value={billInformationEdit?.email} onChange={(e) => { setBillInformationEdit({ ...billInformationEdit, 'email': e.target.value }) }} />
                                </InputValidation>
                            </div>
                            <div className="group">
                                <div className="label">Contact Number *</div>
                                <InputValidation type={error.phone.type} message={error.phone.message}>
                                    <input type="text" value={billInformationEdit?.phone} onChange={(e) => { setBillInformationEdit({ ...billInformationEdit, 'phone': e.target.value }) }} />
                                </InputValidation>
                            </div>
                            <div className="group" style={{ flexDirection: "row", justifyContent: 'space-between', marginTop: 34, marginBottom: 24 }}>
                                <div className="label">Are you a company?</div>
                                <Switch className="toggle-opt company"
                                    checked={billInformationEdit?.isRegistered}
                                    onClick={(val) => {
                                        setBillInformationEdit({ ...billInformationEdit, 'isRegistered': val })
                                    }}
                                />
                            </div>
                            {billInformationEdit.isRegistered && <div className="group">
                                <div className="label">Company Name</div>
                                <InputValidation type={error.businessName.type} message={error.businessName.message}>
                                    <input value={billInformationEdit.businessName} type="text" onChange={(e) => { setBillInformationEdit({ ...billInformationEdit, 'businessName': e.target.value }) }} />
                                </InputValidation>
                            </div>}
                            <div className="group">
                                <div className="label">GST</div>
                                <input value={billInformationEdit?.taxInfo?.gstin} type="text" onChange={(e) => {
                                    setBillInformationEdit({
                                        ...billInformationEdit, taxInfo: {
                                            ...billInformationEdit.taxInfo,
                                            gstin: e.target.value
                                        }
                                    })
                                }} />
                            </div>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <div className="group">
                                <div className="label">Address Line *</div>
                                <InputValidation type={error.addressLine.type} message={error.addressLine.message}>
                                    <input type="text" value={billInformationEdit.addressLine} onChange={(e) => { setBillInformationEdit({ ...billInformationEdit, 'addressLine': e.target.value }) }} />
                                </InputValidation>
                            </div>
                            <div className="group" style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                <div className="group" style={{ marginRight: 6 }}>
                                    <div className="label">City *</div>
                                    <InputValidation type={error.city.type} message={error.city.message}>
                                        <input type="text" value={billInformationEdit.city} onChange={(e) => { setBillInformationEdit({ ...billInformationEdit, 'city': e.target.value }) }} />
                                    </InputValidation>
                                </div>
                                <div className="group" style={{ marginLeft: 6 }}>
                                    <div className="label">State *</div>
                                    <InputValidation type={error.state.type} message={error.state.message}>
                                        <Select
                                            style={{
                                                width: 198,
                                                marginTop: 7,
                                                border: '1px solid #D9D9D9'
                                            }}
                                            showSearch
                                            defaultValue={'TN'}
                                            filterOption={(inputValue, option) =>
                                                option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                                            }
                                            onChange={(value) => {
                                                setBillInformationEdit({ ...billInformationEdit, 'state': value })
                                            }}  >
                                            {StateCodes && StateCodes.map((b) => {
                                                return <Option key={b.stateCode} value={b.stateCode} style={{ textTransform: 'capitalize' }}>{b.state}</Option>
                                            })}
                                        </Select>
                                        {/* <input type="text" value={billInformationEdit.state} onChange={(e) => { setBillInformationEdit({ ...billInformationEdit, 'state': e.target.value }) }} /> */}
                                    </InputValidation>
                                </div>
                            </div>
                            <div className="group" style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                <div className="group" style={{ marginLeft: 6 }}>
                                    <div className="label">Zipcode *</div>
                                    <InputValidation type={error.zipcode.type} message={error.zipcode.message}>
                                        <input type="text" value={billInformationEdit.zipcode} onChange={(e) => { setBillInformationEdit({ ...billInformationEdit, 'zipcode': e.target.value }) }} />
                                    </InputValidation>
                                </div>
                                <div className="group" style={{ marginLeft: 6 }}>
                                    <div className="label">Country *</div>
                                    <input type="text" disabled value={billInformationEdit.country} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Drawer>
    </div>
}

export default BillingInformation;