import Header from "../setupstore/Header";
import RedirectApp from "../../../assets/images/redirect-app.svg";
import Footer from "../setupstore/Footer";

const RedirectToDsk = () => {
    return <div className="setup-store-mob" style={{ padding: "20px 0px 0px 0px" }}>
        <Header enableLogout={true} redirectPath={'/selectStore'} />
        <div style={{
            flexGrow: 1, maxWidth: 375, width: '100%', padding: '0px 10px', display: 'flex',
            alignItems: 'center', justifyContent: 'center', flexDirection: 'column'
        }}>
            <img alt="Redirect App" src={RedirectApp} />
            <div style={{
                marginTop: 30,
                fontSize: 20,
                fontWeight: 500,
                textAlign: 'center'
            }}>For the best experience, please access our platform on a desktop, as mobile support is not yet available.</div>
        </div>
        <Footer />
    </div>
}

export default RedirectToDsk;