import { CSSProperties, forwardRef, HTMLAttributes } from "react";

type Props = {
    item: any;
    isOpacityEnabled?: boolean;
    isDragging?: boolean;
    createConditionTemplate: (item: any, isFinalItem: boolean, isDragging: boolean) => any;
    isFinalItem: boolean;
} & HTMLAttributes<HTMLDivElement>;

const ConditionItem = forwardRef<HTMLDivElement, Props>(
    ({
        item, isOpacityEnabled,
        isDragging, style,
        isFinalItem,
        createConditionTemplate, ...props }, ref) => {
        const styles: CSSProperties = {
            opacity: isOpacityEnabled ? "0.4" : "1",
            cursor: isDragging ? "grabbing" : "grab",
            lineHeight: "0.5",
            transform: isDragging ? "scale(1.05)" : "scale(1)",
            ...style,
        };
        return (
            <div ref={ref} style={styles} {...props} >
                {createConditionTemplate(item, isFinalItem, isDragging)}
            </div>
        );
    }
);

export default ConditionItem;
