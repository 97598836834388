import * as React from "react"

function OrderDeclinedSvg(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="49" viewBox="0 0 53 49">
            <g id="orderAccept" transform="translate(-0.963 -0.701)">
                <g id="Group_10727" data-name="Group 10727" transform="translate(0.963 0.701)">
                    <rect id="Rectangle_17261" data-name="Rectangle 17261" width="53" height="49" rx="5" fill="rgba(252,50,24,0.15)" />
                    <g id="vuesax_linear_bag-tick" data-name="vuesax/linear/bag-tick" transform="translate(11.388 10.685)">
                        <g id="bag-tick">
                            <path id="Vector" d="M1.737,3.474,5.5,0" transform="translate(10.643 17.487)" fill="none" stroke="#fc3218" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                            <path id="Vector-2" data-name="Vector" d="M5.5,3.475,1.737,0" transform="translate(10.643 17.488)" fill="none" stroke="#fc3218" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                            <path id="Vector-3" data-name="Vector" d="M4.192,0,0,4.2" transform="translate(6.267 2.316)" fill="none" stroke="#fc3218" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                            <path id="Vector-4" data-name="Vector" d="M0,0,4.192,4.2" transform="translate(18.341 2.316)" fill="none" stroke="#fc3218" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                            <path id="Vector-5" data-name="Vector" d="M0,2.316C0,.174,1.2,0,2.683,0h18.8c1.486,0,2.683.174,2.683,2.316,0,2.49-1.2,2.316-2.683,2.316H2.683C1.2,4.632,0,4.806,0,2.316Z" transform="translate(2.316 7.069)" fill="none" stroke="#fc3218" stroke-width="1.25" />
                            <path id="Vector-6" data-name="Vector" d="M0,0,1.716,10.006c.39,2.247,1.327,3.891,4.808,3.891h7.34c3.786,0,4.346-1.575,4.784-3.752L20.693,0" transform="translate(4.053 12.587)" fill="none" stroke="#fc3218" stroke-linecap="round" stroke-width="1.25" />
                            <path id="Vector-7" data-name="Vector" d="M0,0H28.8V28.8H0Z" fill="none" opacity="0" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default OrderDeclinedSvg
