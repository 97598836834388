import * as React from "react";

const Loading = (props: any) => {
    return (
        <div className="loading" style={{ background: props.background || "#000", width: props.width || "100%" }}>
            <div className="animation">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>)
}
export default Loading;
