import React, { useEffect, useState } from 'react';
import Header from '../setupstore/Header';
import Footer from '../setupstore/Footer';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../../../assets/sass/dsk/signin.sass';
import { getStores } from "../../../actions/setupStoreActions";
import { selectedStore } from "../../../actions/uiAction";
import Loading from '../common/Loading';
import { authOTPVerify, otpLogin } from '../../../actions/authActions';
import { useNav } from '../../../helpers/customHook';
let otpTimeCounter = null;

const VerifyOTP = (props: any) => {
    const dispatch: any = useDispatch();
    const params = useParams();
    const navigateTo = useNav();
    const SubscriptionAllowed = ["active", "trial"];
    const WindowHeight: any = window.innerHeight;
    const [contactId, setContactId] = useState("");
    const [otp, setOTP] = useState("");
    const [otpTimestamp, setOTPTimestamp] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [otpRunningTimer, setOTPRunningTimer] = useState(null);
    const [error, setError] = useState(null);
    const { otpToken } = useSelector((state: any) => state.auth);

    useEffect(() => {
        if (params.contactId) {
            setContactId(params.contactId);
            setOTPTimestamp(new Date().getTime());
            otpTimer();
        }
        //eslint-disable-next-line
    }, [params.mobileNo]);

    useEffect(() => {
        if (otpToken?.expiresIn)
            setOTPTimestamp(otpToken.expiresIn * 1000);
        //eslint-disable-next-line
    }, [otpToken])

    useEffect(() => {
        otpTimer();
        //eslint-disable-next-line
    }, [otpTimestamp]);

    const otpTimer = () => {
        // Set the date we're counting down to
        let countDownDate = otpTimestamp + (300 * 1000);
        if (otpTimeCounter) {
            clearInterval(otpTimeCounter);
        }
        // Update the count down every 1 second
        otpTimeCounter = setInterval(function () {
            // Get today's date and time
            let now = new Date().getTime();
            // Find the distance between now and the count down date
            let distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            // Output the result in an element with id="demo"
            setOTPRunningTimer(minutes + ":" + (seconds.toString().length === 1 ? "0" + seconds.toString() : seconds));
            if (distance < 0) {
                clearInterval(otpTimeCounter);
                setOTPRunningTimer(null);
            }
            //eslint-disable-next-line
        }.bind(this), 1000);
    }

    const onVerifyOTP = async () => {
        if (otp.length === 6 && contactId && otpToken?.token) {
            const resp = await authOTPVerify({
                "authProvider": "shopletzy",
                "otp": otp,
                "token": otpToken.token
            })(dispatch);
            if (resp.error) {
                setError(resp.error);
            } else if (resp?.token) {
                if (resp && (resp.isMobileVerified || resp.mobileNo)) {
                    const stores = await dispatch(getStores());
                    if (stores && stores.length > 0) {
                        if (stores.length > 1) {
                            navigateTo("/selectstore")
                        } else {
                            await dispatch(selectedStore(stores[0]));
                            if (SubscriptionAllowed.includes(stores[0]?.subscriptionStatus)) {
                                navigateTo(`/${stores[0].name}/orders`)
                            } else {
                                navigateTo(`/${stores[0].name}/cancelled`)
                            }
                        }
                    } else {
                        navigateTo("/setupstore/usertype")
                    }

                } else if (resp && (!resp.mobileNo || resp.isMobileVerified === false)) {
                    navigateTo("/setupstore/sendOTP");
                }
            }
        }
    }

    const resendOTP = async (e) => {
        e.preventDefault();
        setOTP("");
        if (otpRunningTimer === null && contactId) {
            setLoading(true);
            const resp = await otpLogin(contactId)(dispatch);
            if (resp.status === "unregistered") {
                setError("Email id does not exists.")
            }
            setLoading(false);
        }
    }
    return <div className="email" style={{ height: WindowHeight }}>
        <Header disableLogin={true} />
        <div className="content">
            <div className="signin">
                <div className='header'>
                    <div className="title">
                        OTP Verification
                    </div>
                </div>
                <div className="title">
                    <div style={{ flex: "none", marginRight: 6 }}>Enter the </div> <div style={{ flex: "none", marginRight: 6 }} className='title-plus'>OTP sent to </div> <div style={{ flex: "none" }}>{contactId}</div>
                </div>
                <input className="email" type="text" placeholder="Enter verification code" value={otp} onChange={(e) => setOTP(e.target.value)} />
                {error && <div className='error'>{error}</div>}
                <div className="otp-action" style={{ justifyContent: "flex-end", marginTop: 16 }}>
                    {otpRunningTimer !== null && <div className='timer'>
                        {otpRunningTimer}
                    </div>}
                    <div onClick={otpRunningTimer === null && resendOTP} className={`${otpRunningTimer !== null ? "resend-disable" : "resend-enable"}`} style={{ marginLeft: 10 }}>
                        Resend
                    </div>
                </div>
                <div className="send-otp" onClick={onVerifyOTP}>Verify OTP</div>
            </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Footer />
        </div>
        {isLoading && <Loading background="#00000070"></Loading>}
    </div>
}
export default VerifyOTP;