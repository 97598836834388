import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from '../store';
import qs from 'qs';
import dayjs from "dayjs";
import axiosWrapper from "./axiosWrapper";

export const getOrdersPerDay = (startDate, endDate) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.orderPerDayUrl.replace('{sId}', sId)}/ordersPerDay?${qs.stringify({
                'startDate': startDate,
                'endDate': endDate,
                'tz': dayjs.tz.guess()
            })}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Order Per Day Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error("Get Order Per Day Failed", error)
            }
        }
    };
}

export const getOrderStats = (startDate, endDate) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.orderPerDayUrl.replace('{sId}', sId)}/orderStats?${qs.stringify({
                'startDate': startDate,
                'endDate': endDate,
                'tz': dayjs.tz.guess()
            })}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Order Stats Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error("Get Order Stats Failed", error)
            }
        }
    };

}

export const getAcceptedOrders = (startDate, endDate) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.orderPerDayUrl.replace('{sId}', sId)}/acceptedOrders?${qs.stringify({
                'startDate': startDate,
                'endDate': endDate,
                'tz': dayjs.tz.guess()
            })}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Accepted Orders Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error("Get Accepted Orders Failed", error)
            }
        }
    };

}

export const getTopProducts = (startDate, endDate) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.orderPerDayUrl.replace('{sId}', sId)}/topProducts?${qs.stringify({
                'startDate': startDate,
                'endDate': endDate,
                'tz': dayjs.tz.guess()
            })}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Top Products Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error("Get Top Products Failed", error)
            }
        }
    };

}

export const getTopOutlets = (startDate, endDate) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.orderPerDayUrl.replace('{sId}', sId)}/topOutlets?${qs.stringify({
                'startDate': startDate,
                'endDate': endDate,
                'tz': dayjs.tz.guess()
            })}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Top Outlets Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error("Get Top Outlets Failed", error)
            }
        }
    };

}

export const getRepeatCustomers = (startDate, endDate) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.orderPerDayUrl.replace('{sId}', sId)}/repeatCustomers?${qs.stringify({
                'startDate': startDate,
                'endDate': endDate,
                'tz': dayjs.tz.guess()
            })}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Repeat Customers Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error("Get Repeat Customers Failed", error)
            }
        }
    };

}