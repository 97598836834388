import React, { useEffect, useState } from "react";
import { getStoreDetails, onUpdateSiteSettings } from "../../../../../../actions/brandAction";
import { useDispatch } from "react-redux";
import InfoSvg from "../../../../../../assets/images/settings/info-blue.svg";

const HotjarCmp = () => {
    const dispatch = useDispatch();
    const [integrations, setIntegrations]: any = useState(null);
    const [hotjar, setHotjar] = useState("");
    useEffect(() => {
        loadSettings();
        //eslint-disable-next-line    
    }, []);
    const loadSettings = async () => {
        const resp = await getStoreDetails()(dispatch);
        if (resp?.settings?.integrations) {
            setIntegrations(resp.settings.integrations)
            if (resp?.settings?.integrations?.hotjar) {
                setHotjar(resp?.settings?.integrations?.hotjar);
            }
        }
    }
    const onSave = async () => {
        const payload = { integrations: { ...integrations, hotjar } };
        await onUpdateSiteSettings(payload)(dispatch);
    }
    return <div className="settings">
        <div className="content" style={{ flexDirection: 'row' }}>
            <div className="container" style={{ flexGrow: 1, margin: '0px 24px' }}>
                <div style={{ height: window.innerHeight - 80 }}>
                    <div className="division">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                            <div className="header">Hotjar</div>
                            <button className="sp_btn" style={{ width: 'auto' }} onClick={onSave}>Save</button>
                        </div>
                        <div className="section">
                            <div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{ width: '50%' }}>
                                        <div className="title">
                                            Hotjar Tracking Code
                                            <img src={InfoSvg} onClick={() => window.open('https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-Your-Hotjar-Tracking-Code', '_blank')} alt="info" style={{ marginLeft: 8, cursor: "pointer" }} />
                                        </div>
                                        <div style={{ marginTop: 15 }} className="key">Hotjar tracks how visitors use and interact with your website or product.</div>
                                    </div>
                                    <div style={{
                                        borderRadius: 4,
                                        border: "1px solid #D9D9D9",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "0px 8px 0px 8px",
                                        width: "50%"
                                    }}>
                                        <input
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "7px 9px",
                                                fontWeight: 400,
                                                fontSize: 14,
                                                marginRight: 6,
                                                width: "100%"
                                            }}
                                            // prefix={<img src={WhatsappSvg} />}
                                            name="Hotjar"
                                            value={hotjar}
                                            placeholder="Enter the your key"
                                            onChange={(e) => {
                                                const { value }: any = e.target;
                                                setHotjar(value.trim());
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default HotjarCmp;