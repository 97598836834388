import * as React from "react";

const CMobileSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={13}
        height={17}
        viewBox="0 0 13 17"
        {...props}
    >
        <g
            id="vuesax_linear_mobile"
            data-name="vuesax/linear/mobile"
            transform="translate(-111.5 -381.5)"
        >
            <g id="mobile" transform="translate(112 382)">
                <path
                    id="Vector"
                    d="M12,4v8c0,3.2-.75,4-3.75,4H3.75C.75,16,0,15.2,0,12V4C0,.8.75,0,3.75,0h4.5C11.25,0,12,.8,12,4Z"
                    fill="none"
                    stroke={props.stroke || "#3d3d46"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M3.2,0H0"
                    transform="translate(4.399 2.801)"
                    fill="none"
                    stroke={props.stroke || "#3d3d46"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M2.481,1.241A1.241,1.241,0,1,1,1.241,0,1.241,1.241,0,0,1,2.481,1.241Z"
                    transform="translate(4.759 11.198)"
                    fill="none"
                    stroke={props.stroke || "#3d3d46"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
            </g>
        </g>
    </svg>
);

export default CMobileSVG;
