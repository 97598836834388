import * as React from "react"

function OrderAcceptSvg(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="49" viewBox="0 0 53 49">
            <g id="orderAccept" transform="translate(-0.963 -0.7)">
                <g id="Group_10727" data-name="Group 10727" transform="translate(0.963 0.7)">
                    <rect id="Rectangle_17261" data-name="Rectangle 17261" width="53" height="49" rx="5" fill="rgba(120,40,220,0.15)" />
                    <g id="vuesax_linear_bag-tick" data-name="vuesax/linear/bag-tick" transform="translate(12.961 12.697)">
                        <g id="bag-tick">
                            <path id="Vector" d="M0,1.508,1.508,3.015,4.775,0" transform="translate(10.106 15.177)" fill="none" stroke="#7828dc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                            <path id="Vector-2" data-name="Vector" d="M3.639,0,0,3.649" transform="translate(5.439 2.01)" fill="none" stroke="#7828dc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                            <path id="Vector-3" data-name="Vector" d="M0,0,3.639,3.649" transform="translate(15.919 2.01)" fill="none" stroke="#7828dc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" />
                            <path id="Vector-4" data-name="Vector" d="M0,2.01C0,.151,1.038,0,2.328,0H18.648c1.29,0,2.328.151,2.328,2.01,0,2.161-1.038,2.01-2.328,2.01H2.328C1.038,4.021,0,4.171,0,2.01Z" transform="translate(2.01 6.135)" fill="none" stroke="#7828dc" stroke-width="1.25" />
                            <path id="Vector-5" data-name="Vector" d="M0,0,1.49,8.685c.338,1.95,1.152,3.377,4.173,3.377h6.371c3.286,0,3.772-1.367,4.152-3.257L17.96,0" transform="translate(3.518 10.924)" fill="none" stroke="#7828dc" stroke-linecap="round" stroke-width="1.25" />
                            <path id="Vector-6" data-name="Vector" d="M0,0H25V25H0Z" fill="none" opacity="0" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default OrderAcceptSvg
