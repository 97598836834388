import React from 'react';
import { useParams } from 'react-router-dom';
import Details from "./Details";
import { useNavigate } from 'react-router-dom';

const View = (props: any) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const gotoOrders = () => {
        navigate(-1)
    }
    return (
        <Details orderId={id} disableBack={false} goback={gotoOrders}></Details>
    )
}

export default View;