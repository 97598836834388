import React from "react";
import "../../../assets/sass/dsk/billing.sass";
import { useNav } from "../../../helpers/customHook";

const Overlay = (props: any) => {
    const navigateTo = useNav();
    return <div className="overlay">
        <div className="line"></div>
        <div className="note">
            <div className="due">
                Your Payments are in Due.
            </div>
            <div className="pay" onClick={() => navigateTo(`billing/plans`)}>
                Pay Now
            </div>
        </div>
    </div>;
}

export default Overlay;