import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { getStoreDetails, onUpdateSiteSettings } from "../../../../../../actions/brandAction";
import { onDeleteMedia, onUploadMedia } from "../../../../../../actions/commonAction";
import apiConfig from "../../../../../../apiConfig";
import "../../../../../../assets/sass/dsk/settings.sass";
import Loading from "../../../../common/Loading";
import { loadSiteSettings, onCreatePayload } from "../../../themeService";
import TabMenu from "../../../TabMenu";
import { ThemeContext } from "../../../theme";

const Menus = [{
    key: 'general',
    value: 'General'
}, {
    key: 'home',
    value: 'Home'
}, {
    key: 'landing',
    value: 'Landing'
}]
const Branding = (props: any) => {
    const dispatch = useDispatch();
    const context = useContext(ThemeContext);
    const [data, setdata] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [mobTheme, setMobTheme] = useState(null);
    const [id, setId] = useState("elementary");
    const [aboutUs, setAboutUs] = useState(null);
    const [isSave, setSave] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(null);
    // const [pathMenu, setPathMenu] = useState(null);
    const onSelectedTabMenu = (item) => {
        if (item && item.key !== selectedMenu) {
            setSelectedMenu(item.key)
        }
    }
    useEffect(() => {
        getMobTheme();
        //eslint-disable-next-line
    }, [selectedMenu])

    const getMobTheme = async () => {
        const resp: any = await getStoreDetails()(dispatch);
        const { data, mobile } = loadSiteSettings(resp, "mobile", context);
        const { aboutUs: defaultAboutUs } = loadSiteSettings(resp, "aboutUs", context);
        if (data?.theme?.id) {
            setId(data.theme.id)
        }
        setdata(data);
        setAboutUs(defaultAboutUs);
        setMobTheme(mobile);
    }
    const onSave = async (tabName, updateTheme) => {
        const { theme } = data;
        if (tabName === "General") {
            const { mobThemeUpdate, deleteUrl, file } = updateTheme;
            const {
                deliveryAreaHint,
                fontFamily,
                name,
                primaryColor,
                secondaryColor,
                url,
                whatsAppNo,
                button,
                themeId
            } = mobThemeUpdate
            theme.mobile.brand = {
                deliveryAreaHint,
                fontFamily,
                name,
                primaryColor,
                secondaryColor,
                url,
                whatsAppNo,
                button
            }
            if (deleteUrl) {
                await onDeleteMedia({ filenames: [deleteUrl] })(dispatch);
            }
            if (file) {
                let bodyFormData = new FormData();
                bodyFormData.append('files', file.originFileObj);
                bodyFormData.append('forUse', 'brandLogo');
                const uploadResp: any = await onUploadMedia(bodyFormData)(dispatch);
                if (uploadResp && uploadResp.isUploaded) {
                    theme.mobile.brand.url = uploadResp.files[0];
                } else {
                    console.error("failed");
                }
            }
            theme.mobile.brand.url = theme.mobile.brand.url.replace(apiConfig.baseImageUrl, "");
            const payload = onCreatePayload({
                theme: {
                    web: theme.web,
                    mobile: theme.mobile,
                    id: themeId
                },
                codeInjection: data.codeInjection,
                aboutUs: data.aboutUs,
                social: data.social,
                paymentModes: data.paymentModes || [],
                pricingInclusiveOfTax: data.pricingInclusiveOfTax || false,
                autoConfirmOrders: data.autoConfirmOrders || {
                    codOrders: false,
                    onlineOrders: false
                }
            }, "all")
            const themeUpdateResp: any = await onUpdateSiteSettings(payload)(dispatch);
            if (themeUpdateResp && themeUpdateResp.isUpdated) {
                getMobTheme();
            }
        } else if (tabName === "Home") {
            let { banner, layoutPostion, title } = updateTheme;
            if (banner.deleteImages && banner.deleteImages.length > 0) {
                const deleteResp: any = await onDeleteMedia({ filenames: banner.deleteImages })(dispatch);
                if (deleteResp && deleteResp.isDeleted) {
                    delete banner.deleteImages;
                }
            }
            if (banner.items && banner.items.length > 0) {
                const imageProcessResp = await fileProcess(banner);
                banner = imageProcessResp.banner;
            }
            theme.mobile.homePage = {
                sections: {
                    title,
                    layoutPostion
                },
                banner
            }
            const payload = onCreatePayload({
                theme: {
                    mobile: theme.mobile,
                    web: theme.web,
                    id: theme.id
                },
                codeInjection: data.codeInjection,
                aboutUs: aboutUs,
                social: data.social,
                pricingInclusiveOfTax: data.pricingInclusiveOfTax || false,
                paymentModes: data.paymentModes || [],
                autoConfirmOrders: data.autoConfirmOrders || {
                    codOrders: false,
                    onlineOrders: false
                }
            }, "all")
            const themeUpdateResp: any = await onUpdateSiteSettings(payload)(dispatch);
            if (themeUpdateResp && themeUpdateResp.isUpdated) {
                getMobTheme();
            }
        } else if (tabName === "Landing") {
            let { banner, deleteUrl } = updateTheme;
            if (deleteUrl && deleteUrl.length > 0) {
                await onDeleteMedia({ filenames: deleteUrl })(dispatch);
                delete updateTheme.deleteUrl;
            }
            if (banner.items && banner.items.length > 0) {
                const imageProcessResp = await fileProcess(banner);
                banner = imageProcessResp.banner;
            }
            theme.mobile.landingPage = updateTheme
            const payload = onCreatePayload({
                theme: {
                    web: theme.web,
                    mobile: theme.mobile,
                    id: theme.id
                },
                codeInjection: data.codeInjection,
                aboutUs: aboutUs,
                social: data.social,
                paymentModes: data.paymentModes || [],
                pricingInclusiveOfTax: data.pricingInclusiveOfTax || false,
                autoConfirmOrders: data.autoConfirmOrders || {
                    codOrders: false,
                    onlineOrders: false
                }
            }, "all")
            const themeUpdateResp: any = await onUpdateSiteSettings(payload)(dispatch);
            if (themeUpdateResp && themeUpdateResp.isUpdated) {
                getMobTheme();
            }
        }
        setSave(false);
    }
    const fileProcess = async (banner) => {
        let uploadStatus = null;
        for (let i in banner.items) {
            if (banner.items[i].status === "new") {
                let bodyFormData = new FormData();
                bodyFormData.append('files', banner.items[i].fileObj);
                bodyFormData.append('forUse', 'banner');
                const uploadResp: any = await onUploadMedia(bodyFormData)(dispatch);
                if (uploadResp.isUploaded && uploadStatus !== false) {
                    uploadStatus = true;
                    banner.items[i].url = uploadResp.files[0];
                    banner.items[i].status = "done";
                } else {
                    uploadStatus = false;
                    banner.items[i].status = "failed";
                }
            } else {
                banner.items[i].url = banner.items[i].url.replace(apiConfig.baseImageUrl, "");
                delete banner.items[i].fileExt;
                // delete banner.items[i].name;
                delete banner.items[i].status;
                delete banner.items[i].uid;
                delete banner.items[i].fileObj;
                delete banner.deleteImages;
            }
            if (uploadStatus) {
                banner.items[i].url = banner.items[i].url.replace(apiConfig.baseImageUrl, "");
                delete banner.deleteImages;
                delete banner.items[i].fileExt;
                // delete banner.items[i].name;
                delete banner.items[i].status;
                delete banner.items[i].uid;
                delete banner.items[i].fileObj;
            }
        }
        return { banner, uploadStatus }
    }
    useEffect(() => {
        setLoading(isSave)
    }, [isSave]);
    // useEffect(() => {
    //     const pathNames = location.pathname.split("/");
    //     const menuMatched = Menus.filter((m) => m.key === pathNames[pathNames.length - 1]);
    //     let url = null
    //     if (menuMatched.length > 0) {
    //         setPathMenu(menuMatched[0])
    //         url = `${location.pathname}/${menuMatched[0].key}`;
    //     } else {
    //         setPathMenu(Menus[0]);
    //         url = `${location.pathname}/${Menus[0].key}`;
    //     }
    //     if (url) {
    //         navigateTo(url)
    //     }
    // }, [])
    return <div className="settings" style={{ flexDirection: 'column' }}>
        <div style={{
            display: 'flex', justifyContent: 'space-around',
            background: '#FFFFFF',
            borderRadius: 4,
            height: 60,
            alignItems: 'center',
            width: 'auto',
            margin: 15,
            padding: '0px 20px'
        }}>
            <TabMenu onSelectedTabMenu={onSelectedTabMenu} menus={Menus}></TabMenu>
            <button onClick={() => setSave(true)} className="sp_btn save">Save</button>
        </div>
        <div style={{ height: window.innerHeight - 180, overflowY: 'auto', padding: 15 }}>
            <Outlet context={[onSave, isSave, mobTheme, aboutUs, id]} />
        </div>
        {isLoading && <Loading background="#00000070"></Loading>}
    </div>
}

export default Branding;