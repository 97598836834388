import React from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import AppDownIcn from "../../../../../../../assets/images/settings/app_down.svg";
import EmptyIcon from "../../../../../../../assets/images/settings/app_generation.svg";
import apiConfig from "../../../../../../../apiConfig";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import { useOutletContext } from "react-router-dom";
dayjs.extend(relativeTime);

const BuildNow = styled.div`
    height: 44px;
    background: #121212;
    border-radius: 4px;
    color: #FFF;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
    cursor: pointer;
`;
const Empty = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
        width: 104px;
        height: 129px;
        margin-bottom: 45px;
    }
    .title {
        font-size: 18px;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 13px;
    }
    .desc {
        font-size: 14px;
        letter-spacing: 0px;
        color: #848484;
        margin-bottom: 17px;
    }
`;
const Build = (props: any) => {
    const {
        onBuildConfirm: onNewBuild,
        buildsApps,
        appConfig
    }: any = useOutletContext();
    const getStatusColorCode = (state) => {
        if (state === "error") return "#FF0000";
        if (state === "pending") return "#ff8800";
        if (state === "processing") return "#188FFC";
        return "#121212";
    }
    const runningTimer = (createdtime: any, completedAt: any) => {
        const duration = Math.abs((new Date(completedAt).getTime() - new Date(createdtime).getTime()));
        const hours = Math.round(duration / (1000 * 60 * 60) % 24);
        let mins: any = Math.round(duration / (1000 * 60) % 60);
        let secs: any = Math.round(duration / (1000) % 60);
        let displayTime: string = "";
        if (hours > 1) {
            displayTime = displayTime + hours + ":";
        }
        if (mins < 2) {
            mins = mins + " min, ";
        } else {
            mins = mins + " mins, ";
        }
        if (secs < 2) {
            secs = secs + " sec";
        } else {
            secs = secs + " secs";
        }
        return displayTime + mins + secs;
    }
    return <Row className="outlet" style={{ width: '100%' }}>
        <Col className="list" style={{ boxShadow: "unset" }} span={24}>
            {buildsApps && buildsApps.length > 0 ? <div className="content" style={{ borderRadius: 4 }} >
                <Row className="header" style={{
                    color: '#5A5A5A',
                    background: '#F2F2F2',
                    fontWeight: 500,
                    fontSize: 14,
                    marginRight: 8,
                    borderRadius: 6,
                    marginLeft: 8,
                    marginTop: 8,
                    height: 53, display: 'flex', alignItems: 'center'
                }}>
                    <Col span={5} style={{ paddingLeft: 15 }}>
                        App Name
                    </Col>
                    <Col span={2}>
                        App version
                    </Col>
                    <Col span={6}>
                        Release Notes
                    </Col>
                    <Col span={4}>
                        CreatedAt
                    </Col>
                    <Col span={5}>
                        Status
                    </Col>
                    <Col span={2}>
                        Duration
                    </Col>
                </Row>
                <Row className="contains" style={{ margin: 0, height: window.innerHeight - 220 }}>
                    <Col span={24}>
                        {buildsApps && buildsApps.map((record, index) => {
                            return <Row key={record.id} style={{ padding: '0px 22px', height: 46, display: 'flex', alignItems: 'center' }}>
                                <Col className="record" span={5} style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <div>
                                        <img alt="AppIcon"
                                            style={{ width: 25, marginRight: 15, marginLeft: '-8px' }}
                                            src={
                                                (appConfig.appIcon && appConfig.appIcon.indexOf('https://') > -1)
                                                    ? appConfig.appIcon : `${apiConfig.baseImageUrl}${appConfig.appIcon}`
                                            }
                                        />
                                    </div> */}
                                    <div>
                                        <div>{appConfig.appName}</div>
                                        {/* <div style={{ color: '#848484', fontSize: 12 }}>{record.bundleId}</div> */}
                                    </div>
                                </Col>
                                <Col className="record" span={2}>
                                    -
                                </Col>
                                <Col className="record" span={6}>
                                    -
                                </Col>
                                <Col className="record" span={4}>
                                    <div>{dayjs(record.createdAt).fromNow()}</div>
                                </Col>
                                <Col className="record" span={5}>
                                    {record.status !== "success" && <div style={{ color: getStatusColorCode(record.status), textTransform: 'uppercase' }}>{record.status}</div>}
                                    {record.status === "success" && <div style={{ display: 'flex', alignItems: 'center', width: 130, justifyContent: 'space-between' }}>
                                        {record.downloadUrls && record.downloadUrls.apk &&
                                            <a rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center' }} href={`${apiConfig.baseImageUrl}${record.downloadUrls.apk}`} target="_blank">
                                                <img alt="App Down Icon" src={AppDownIcn} title="Download APK" style={{ marginRight: 6 }} /> APK
                                            </a>
                                        }
                                        {record.downloadUrls && record.downloadUrls.aab &&
                                            <a rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', marginLeft: 6 }} href={`${apiConfig.baseImageUrl}${record.downloadUrls.aab}`} target="_blank">
                                                <img alt="App Down Icon" src={AppDownIcn} title="Download AAB" style={{ marginRight: 6 }} /> AAB
                                            </a>
                                        }
                                    </div>}
                                </Col>
                                <Col className="record" span={2}>
                                    <div>
                                        {(record.status === "success" || record.status === "error") && runningTimer(record.createdAt, record.completedAt)}
                                    </div>
                                </Col>
                            </Row>
                        })}
                    </Col>
                </Row>
            </div> : <Empty style={{ height: window.innerHeight - 260 }}>
                <img alt="Empty" src={EmptyIcon} />
                <div className="title">Build & Release</div>
                <div className="desc">
                    Begin by building a new version and download APK / AAB bundles ready for Google Play Store publication
                </div>
                <BuildNow onClick={onNewBuild}>Let's build</BuildNow>
            </Empty>}
        </Col>
    </Row>
}

export default Build;