export const defaultWebThemeProps = {
    "lastUpdated": new Date().getTime(),
    "brand": {
        "name": "",
        "whatsAppNo": "",
        "deliveryAreaHint": "",
        "url": "",
        "primaryColor": "#FF0A54",
        "secondaryColor": "#077DFF",
        "fontFamily": "AvenirNextMedium",
        "button": {
            "backgroundColor": "#2196f3",
            "color": "#FFFFFF",
            "borderRadius": 4
        }
    },
    "header": {
        "background": "#FFF",
        "signinBtn": {
            "label": "Signin",
            "borderRadius": 23,
        },
    },
    "landingPage": {
        "layout": "left",
        "url": "",
        "backgroundColor": "#000000",
        "content": {
            "mainTitle": {
                "label": "",
                "color": "#232631"
            },
            "subTitle": {
                "color": "#686F89",
                "label": ""
            },
            "location": {
                "errorMsg": "Choose your delivery location",
                "placeholder": {
                    "label": "Enter delivery location",
                    "color": "#686F89"
                },
                "iconUrl": "https://storage.googleapis.com/slz-dev-uploads-asia-southeast1/assets/currentLoc_ico.svg",
                "button": {
                    "color": "#FFFFFF",
                    "lable": "Explore Menu",
                    "borderRadius": "4px"
                }
            }
        }
    },
    "homePage": {
        "sections": {
            "title": {
                "fontSize": 24,
                "fontWeight": "bold",
                "color": "#686F89"
            },
            "layoutPostion": ["top_picks", "best_sellers", "whats_new"]
        },
        "banner": {
            "background": "#000000",
            "items": []
        }
    },
    "product": {
        "cardLayout": "classic"
    },
    "footer": {
        "background": "#FFF",
        "label": {
            "color": "#686F89",
        },
        "value": {
            "color": "#686F89",
        },
        "copyright": {
            "label": "© 2021 Shopletzy All rights reserved",
            "background": "#F0F7FF",
            "color": "#686F89"
        }
    }
};

export const defaultMobThemeProps = {
    "lastUpdated": new Date().getTime(),
    "brand": {
        "name": "",
        "url": "",
        "deliveryAreaHint": "",
        "whatsAppNo": "",
        "primaryColor": "#FF0A54",
        "secondaryColor": "#077DFF",
        "fontFamily": "AvenirNextMedium",
        "button": {
            "backgroundColor": "#FF0A54",
            "color": "#FFF",
            "borderRadius": 4
        }
    },
    "landingPage": {
        "backgroundColor": "#FFFFFF",
        "banner": {
            "background": "#000000",
            "items": []
        },
        "content": {
            "mainTitle": {
                "label": "Enjoy your favourite dish",
                "color": "#232631"
            },
            "subTitle": {
                "color": "#686F89",
                "label": "We Are Open For Take Aways"
            },
            "location": {
                "button": {
                    "color": "#FFFFFF",
                    "lable": "Explore Menu",
                    "borderRadius": "4px"
                }
            }
        }
    },
    "homePage": {
        "sections": {
            "title": {
                "fontSize": 24,
                "fontWeight": "bold",
                "color": "#686F89"
            },
            "layoutPostion": ["top_picks", "best_sellers", "whats_new"]
        },
        "banner": {
            "background": "#000000",
            "items": []
        }
    },
    "product": {
        "cardLayout": "classic"
    },
    "footer": {
        "background": "#FFFFFF",
        "copyright": {
            "label": "© 2021 Shopletzy All rights reserved",
            "background": "#F0F7FF",
            "color": "#686F89"
        }
    }
};