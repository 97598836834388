import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import '../../../assets/sass/dsk/category.sass';
import config from "../../../apiConfig";
import { getCategories, getMediaCategories, addCategories, updateCategories, deleteImage, uploadImage } from '../../../actions/categoryActions';
import { EditOutlined, ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { Row, Col, Select, Button, Input, Drawer, Modal, Tabs } from 'antd';
import InputValidation from '../common/InputValidation';
import { getBase64, isNotNullOrUndefinedString } from '../../../helpers/util';
import TextArea from 'antd/lib/input/TextArea';
import plusIcon from "../../../assets/images/plus.svg";
import SearchIcn from "../../../assets/images/search.svg"
import UploadIcn from "../../../assets/images/category_upload.svg"
import Dragger from 'antd/lib/upload/Dragger';
import Loading from '../common/Loading';

const { Option } = Select;
const { TabPane } = Tabs;

const Save = (props: any) => {
    const WindowHeight = window.innerHeight;
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteImage, setIsDeleteImage] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [imgSearch, setImgSearch] = useState("");
    const [preIcons, setPreIcons] = useState([]);
    const [selectedPreIcon, setSelectedPreIcon] = useState(null);
    const [visibleUploadFile, setVisibleUploadFile] = useState(false);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({
        id: null,
        mrId: "",
        parent: "",
        category: "",
        description: "",
        title: ""
    });
    const [error, setError] = useState({
        parent: {
            message: "",
            type: ""
        },
        title: {
            message: "",
            type: ""
        },
        category: {
            message: "",
            type: ""
        },
    });
    const [tempImage, setTempImage] = useState(null);
    // const [previewTitle, setPreviewTitle] = useState(null);

    useEffect(() => {
        if (category && category.id) {
            setIsEdit(false);
        } else {
            setIsEdit(true);
        }
        //eslint-disable-next-line
    }, [category && category.id]);

    useEffect(() => {
        if (props.categoryDetails) {
            setCategory(props.categoryDetails);
            if (props.categoryDetails.image) {
                setFileList([{
                    uid: '1',
                    name: 'category',
                    status: 'done',
                    url: config.baseImageUrl + props.categoryDetails.image
                }]);
                setPreviewImage(config.baseImageUrl + props.categoryDetails.image);
            } else {
                setFileList([]);
            }

        } else {
            setCategory({
                id: null,
                mrId: "",
                parent: "",
                category: "",
                description: "",
                title: ""
            });
            setFileList([]);
        }
    }, [props.categoryDetails]);


    // useEffect(() => {
    //     props.getCategories();
    // }, []);

    const renderImagePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        // setPreviewVisible(true);
        // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const onHandleFileChange = (info) => {
        setSelectedPreIcon(null);
        info.file.status = "isNew";
        setTempImage([info.file]);
        renderImagePreview(info.file);
    }

    // const [uploadProps, setUploadProps] = useState({
    //     accept: 'image/jpeg,image/png',
    //     name: 'file',
    //     multiple: false,
    //     action: '',
    //     onChange: onHandleFileChange
    // });

    const uploadProps = {
        accept: "image/jpeg,image/png,image/webp",
        name: 'file',
        multiple: false,
        action: '',
        onChange: onHandleFileChange
    };

    const showModal = async () => {
        if (isEdit) {
            const resp = await props.getMediaCategories();
            if (resp && resp.media) {
                setPreIcons(resp.media);
            }
            setVisibleUploadFile(true);
        }
    };

    const handleOk = e => {
        setVisibleUploadFile(false);
        setFileList(tempImage);
        setPreviewImage(null)
        if (selectedPreIcon) {
            setFileList([{
                url: config.baseImageUrl + selectedPreIcon
            }]);
        }
    };

    const handleCancel = e => {
        setPreviewImage(null);
        setTempImage(null);
        setVisibleUploadFile(false);
    };

    const onClose = () => {
        props.getCategories();
        setPreviewImage(null);
        setIsDeleteImage(false);
        setSelectedPreIcon(null);
        setError({
            parent: {
                message: "",
                type: ""
            },
            title: {
                message: "",
                type: ""
            },
            category: {
                message: "",
                type: ""
            },
        });
        setCategory({
            id: null,
            mrId: "",
            parent: "",
            description: "",
            category: "",
            title: ""
        });
        props.onClose({
            id: null,
            mrId: "",
            parent: "",
            description: "",
            category: "",
            title: ""
        });
    }
    // const gotoCategories = () => {
    //     const { history } = props;
    //     history.push({ pathname: "/categories" });
    // }

    const onHandleSubmit = async () => {
        setIsLoading(true);
        let errorInfo = {
            title: {
                message: "",
                type: ""
            },
            category: {
                message: "",
                type: ""
            },
            parent: {
                message: "",
                type: ""
            }
        }, isError = false;
        // this.setState({ error });
        setError(errorInfo);
        if (!isNotNullOrUndefinedString(category.title)) {
            errorInfo.title.type = "error";
            errorInfo.title.message = "Fill the category title";
            isError = true;
        }
        if (!isNotNullOrUndefinedString(category.category)) {
            errorInfo.category.message = "Fill the category name";
            errorInfo.category.type = "error";
            isError = true;
        }

        if (!isNotNullOrUndefinedString(category.parent)) {
            errorInfo.parent.message = "Select the parent category";
            errorInfo.parent.type = "error";
            isError = true;
        }
        if (!isError) {
            let resp = null;
            let payload = {};
            if (category.id === null) {
                payload = {
                    title: category.title,
                    parent: category.parent,
                    category: `/${category.category}`,
                    description: category.description
                }
                if (selectedPreIcon) {
                    payload['image'] = selectedPreIcon;
                }
                resp = await props.addCategories(payload);

            } else if (category.id) {
                payload = {
                    title: category.title,
                    category: category.category,
                    description: category.description
                }
                if (selectedPreIcon) {
                    payload['image'] = selectedPreIcon;
                }
                resp = await props.updateCategories(payload, category.id);
            }
            if (resp.error) {
                setIsLoading(false);
            } else if (!selectedPreIcon) {
                uploadImage(category.id || resp.categoryId);
            } else {
                setIsLoading(false);
                onClose();
            }
        } else {
            setIsLoading(false);
            setError(errorInfo);
        }
    }

    // componentDidUpdate() {
    //     const { categoryDetails } = this.props;
    //     const { category } = this.state;
    //     if (categoryDetails && categoryDetails.id && categoryDetails.id != category.id) {
    //         this.setState({
    //             isEdit: categoryDetails.id ? false : true,
    //             category: categoryDetails
    //         });
    //     }
    // }
    const { categories: categoryList } = useSelector((state: any) => state.category);
    useEffect(() => {
        if (categoryList) {
            setCategories(categoryList)
        }
    }, [categoryList])

    //     static getDerivedStateFromProps(nextProps, prevState) {
    //     let updateProps: any = {};
    //     if (nextProps.category.categories) {
    //         updateProps.categories = nextProps.category.categories;
    //     }
    //     return updateProps;
    // }

    const removeImage = async () => {
        if (fileList && fileList.length > 0) {
            setFileList([]);
            setIsDeleteImage(true);
        }
    }

    // const uploadButton = () => {
    //     return <div onClick={showModal} className="image-upload">
    //         <img src={plusIcon} />
    //         <div style={{ marginTop: 8 }}>ADD </div>
    //     </div>
    // };

    // const selectPreImage = (item) => {
    //     console.log(item)
    // }

    const uploadImage = async (cId) => {
        let fileUploaded = null;
        let fileDelete = null;
        let resp = null;
        if (fileList && fileList.length > 0 && fileList[0].status === "isNew") {
            resp = await props.uploadImage(cId, fileList[0].originFileObj);
            if (resp.data && resp.data.isUploaded) {
                fileUploaded = true;
            } else {
                fileUploaded = false;
            }
        } else {
            fileUploaded = true
        }
        if (isDeleteImage) {
            fileDelete = await props.deleteImage(category.id);
        } else {
            fileDelete = true;
        }
        setIsLoading(false);
        if (fileDelete && fileUploaded) {
            onClose();
        }
    }

    // useEffect(() => {
    //     console.log(fileList);
    // }, [fileList])

    return (
        <Drawer
            closable={false}
            onClose={onClose}
            width={470}
            className="category-save"
            visible={props.isVisible}
            footer={<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className="sp_btn" onClick={onClose} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                {!isEdit && <div className="sp_btn" onClick={() => { setIsEdit(true) }} style={{ width: 120, fontSize: 14 }}>Edit</div>}
                {isEdit && <div className="sp_btn" onClick={onHandleSubmit} style={{ width: 120, fontSize: 14 }}>{category && category.id ? "Update" : "Add"}</div>}

            </div>}
        >
            <div className="header">
                <ArrowLeftOutlined className="arrow" onClick={onClose}></ArrowLeftOutlined>
                <div className="title">Category</div>
            </div>
            <Row>
                <Col span={24} className="content" style={{ height: WindowHeight - 80 + "px", overflow: 'auto' }}>
                    {/* <div className="group title">
                        <ArrowLeftOutlined className="back" onClick={onClose} style={{
                            position: "absolute",
                            cursor: "pointer"
                        }} ></ArrowLeftOutlined>
                        Category
                    </div> */}
                    <div className="group">
                        <div className="label">Title *</div>
                        <InputValidation type={error.title.type} message={error.title.message}>
                            <Input disabled={!isEdit} type="text" name="title" value={category.title} defaultValue={category.title} placeholder="Title"
                                onChange={(e) => setCategory({ ...category, ...{ title: e.target.value, category: e.target.value.replace(/\s+/g, '').replace(/[^a-zA-Z 0-9]+/g, '-') } })}
                            />
                        </InputValidation>
                    </div>
                    {/* <div className="group">
                        <div className="label">Category *</div>
                        <InputValidation type={error.category.type} message={error.category.message}>
                            <Input disabled={!isEdit || category.id ? true : false} type="text" name="category" value={category.category} defaultValue={category.category} placeholder="Category"
                                onChange={(e) => setCategory({ ...category, ...{ "category": e.target.value } })}
                            />
                        </InputValidation>
                    </div> */}
                    <div className="group">
                        <div className="label">Parent Category *</div>
                        <InputValidation type={error.parent.type} message={error.parent.message}>
                            <Select style={{ height: 40 }}
                                disabled={!isEdit || category.id ? true : false}
                                defaultValue={category.parent}
                                value={category.parent}
                                onChange={(value) => setCategory({ ...category, ...{ "parent": value } })}
                                showSearch={true}
                                optionFilterProp="children"
                            // filterOption={(input, option) =>
                            //     option.value
                            // }
                            // filterSort={(optionA, optionB) =>
                            //    return optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                            // }
                            >
                                <Option key={"/"} value={"/"}>Main</Option>
                                {
                                    categories && categories.map((item, i) => {
                                        return <Option key={item.category + "_" + i} value={item.category}>{item.title}</Option>
                                    })
                                }
                            </Select>
                        </InputValidation>
                    </div>
                    <div className="group">
                        <div className="label">Description</div>
                        <TextArea disabled={!isEdit} rows={4} name="title" value={category.description} defaultValue={category.description} placeholder="Description"
                            onChange={(e) => setCategory({ ...category, ...{ "description": e.target.value } })}
                        />
                    </div>
                    {isEdit && fileList && fileList.length === 0 && <div className="group">
                        <div className="label">Set Icon</div>
                        <div>
                            <div onClick={showModal} className="image-upload">
                                <img alt='Plus Icon' src={plusIcon} />
                            </div>
                        </div>
                    </div>}
                    {fileList && fileList.length > 0 && <div className="group">
                        <div className="label">Category Icon</div>
                        <div className="category-icon" >
                            {isEdit && <div className="action">
                                <DeleteOutlined onClick={removeImage} style={{ cursor: "pointer" }} />
                                <EditOutlined onClick={showModal} style={{ cursor: "pointer" }} />
                            </div>}
                            <img alt='Preview' onClick={showModal} className="icon" src={fileList[0].url || fileList[0].preview} />
                        </div>
                    </div>}
                </Col>
            </Row>
            {/* <Row>
                    <Col span={24}>
                        <div className="footer">
                            <button onClick={this.onClose} className="cancel">Cancel</button>
                            {!isEdit && <button onClick={() => { this.setState({ isEdit: true }) }} className="cancel">Edit</button>}
                            {isEdit && <button onClick={this.onHandleSubmit} className="update">{category && category.id ? "Update" : "Add"}</button>}
                        </div>
                    </Col>
                </Row> */}


            <Modal
                width={(window.innerWidth / 2) - 200}
                className="image-upload"
                title="Set Icons"
                visible={visibleUploadFile}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" className="sp_btn cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="sp_btn upload" onClick={handleOk}>
                        Upload
                    </Button>
                ]}
            >
                <Tabs>
                    <TabPane className="pre-icons" tab="Pre-Icons" key="1">
                        <Input className="search" type="text" suffix={<img alt='Search Icon' src={SearchIcn} />} onChange={(e) => setImgSearch(e.target.value)} />
                        <div className="pics" style={{ height: window.innerHeight / 2 }}>
                            {preIcons.map((item) => {
                                return ((item.tags?.find((el => el.indexOf(imgSearch.toLowerCase()) > -1)) || imgSearch.length === 0) &&
                                    <div key={item.id} style={{ borderColor: (selectedPreIcon && selectedPreIcon === item.objectUrl) ? 'red' : '#00000000' }}> <img alt='Pre Icon' onClick={() => setSelectedPreIcon(item.objectUrl)} src={config.baseImageUrl + item.objectUrl} /></div>
                                )
                            })}
                        </div>
                    </TabPane>
                    <TabPane tab="Upload Image" key="2" className="from-local">
                        <Dragger customRequest={() => { return false; }} defaultFileList={fileList} {...uploadProps} >
                            <p className="ant-upload-drag-icon">
                                {!previewImage && <img alt='Upload Icon' src={UploadIcn} />}
                                {previewImage && <img alt='Preview' className="preview-image" src={previewImage} />}
                            </p>
                            <p className="ant-upload-text">Drag a icon here</p>
                            <p className="ant-upload-text">---- or ----</p>
                            <p className="ant-upload-hint">
                                <span className="select-icn">Select Icon</span>
                            </p>
                        </Dragger>
                    </TabPane>
                </Tabs>
            </Modal>
            {isLoading && <Loading background="#00000070"></Loading>}
        </Drawer>
    )
}



function mapStateToProps(state: any) {
    // console.log("Product View mapStateToProps: ", state);
    return {
        common: state.common,
        ui: state.ui,
        category: state.category
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getCategories,
        addCategories,
        updateCategories,
        deleteImage,
        uploadImage,
        getMediaCategories
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Save);