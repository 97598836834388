import React, { useEffect, useState } from "react";
import CalenderSvg from "../../../assets/images/calender.svg";
import NoAppSvg from "../../../assets/images/app.svg";
import CloseSvg from "../../../assets/images/close_black.svg";
import DoodleSvg from "../../../assets/images/doodle.svg";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptions, makePayment } from "../../../actions/billingAction";
import { onCancelSubscription, onReactivateSubscription, onlinePaymentFailure, onlinePaymentSuccess } from "../../../actions/setupStoreActions";
import { selectedStore as onSelectedStore } from "../../../actions/uiAction";
import { useNav } from "../../../helpers/customHook";
import Loading from "../common/Loading";
import ChoosePlan from "./ChoosePlan";
import styled from "styled-components";
import { getStoreDetails } from "../../../actions/brandAction";
import PopupConfirm from "../common/PopupConfirm";

const ShowPlan = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000070;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    .container {
        position: relative;
        width: 60%;
        min-height: 60vh;
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px #00000070;
        display: flex;
        justify-content: center;
        align-items: center;
        .doodle {
            position: absolute;
            height: 100%;
            width: 100%;
        }
        .on-close {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 20px;
            cursor: pointer;
        }
    }
`;

const SubscriptionStatus = {
    "trial": "Trial",
    "active": "Active",
    "cancelled": "Cancelled",
    "pendingCancellation": "Pending Cancellation",
    "suspended": "Suspended",
    "ended": "Ended"
}

const Plans = () => {
    const WindowObj: any = window;
    const dispatch = useDispatch();
    const navigateTo = useNav();
    const [isTrailPeriod, setIsTrailPeriod] = useState(false);
    const [isShowPlan, setShowPlan] = useState(false);
    const [cancelSubscription, setCancelSubscription] = useState(false);
    const [acceptSubscription, setAcceptSubscription] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const { selectedStore } = useSelector((state: any) => state.ui);
    useEffect(() => {
        loadSubscription();
        //eslint-disable-next-line
    }, []);

    const loadSubscription = async () => {
        setShowPlan(false);
        setLoading(true);
        const resp = await getSubscriptions()(dispatch);
        if (resp?.storeSubscription?.status) {
            if (selectedStore?.subscriptionStatus) {
                await dispatch(onSelectedStore({ ...selectedStore, 'subscriptionStatus': resp.storeSubscription.status }));
            }
            if (resp.storeSubscription.status === "trial") {
                setIsTrailPeriod(true);
            } else {
                setIsTrailPeriod(false);
            }
            setSubscription(resp);
        }
        setLoading(false);
    }

    const onCancelSub = async () => {
        setLoading(true);
        if (subscription && subscription?.storeSubscription?.id) {
            const resp = await onCancelSubscription(subscription?.storeSubscription?.id)(dispatch);
            if (resp?.subscriptionStatus === "cancelled" || resp?.subscriptionStatus === "pendingCancellation") {
                loadSubscription();
                setCancelSubscription(false);
            }
        }
        setLoading(false);
    }

    const onMakePayment = async () => {
        const resp = await makePayment()(dispatch);
        if (resp?.hostedPage) {
            payNow(resp.hostedPage);
        }
    }

    const payNow = (hostedPage) => {
        var cbInstance = WindowObj.Chargebee.init({
            site: WindowObj.chargebee_site_name
        })
        cbInstance.openCheckout({
            hostedPage: function () {
                return new Promise((resolve, reject) => resolve(hostedPage));
            },
            success: (hostedPageId) => {
                onPaymentSuccess(hostedPageId)
            },
            failure: (hostedPageId) => {
                onPaymentFailure(hostedPageId)
            },
        });
    }

    const reactivatingSubscription = () => {
        let popupConfirmDetail = {
            title: "Reactivate Your Shopletzy Subscription",
            renderContent: () => {
                return <div style={{ color: "#000", lineHeight: 2 }} >Are you sure you want to reactivate your Shopletzy subscription? This will give you immediate access to all features, helping you manage and grow your store. Any previous data will be restored. Confirm to proceed with reactivation.</div>
            },
            yes: {
                label: "Proceed",
                callback: async () => {
                    setLoading(true);
                    if (subscription && subscription?.storeSubscription?.id) {
                        const resp = await onReactivateSubscription(subscription.storeSubscription.id)(dispatch);
                        if (resp?.subscriptionStatus === "active") {
                            loadSubscription();
                        }
                    }
                    setLoading(false);
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "Cancel",
                callback: () => { setPopupConfirm(null); }
            }
        }
        setPopupConfirm(popupConfirmDetail);
    }

    const onPaymentSuccess = async (hostedPageId) => {
        const data = {
            "paymentGw": "chargebee",
            "cbResponse": {
                "hostedPageId": hostedPageId,
                "subscriptionId": subscription.storeSubscription?.id
            }
        };
        const apiResp: any = await onlinePaymentSuccess(data)(dispatch);
        if (apiResp?.subscriptionStatus) {
            loadSubscription();
            await getStoreDetails()(dispatch);
        }
    }

    const onPaymentFailure = async (hostedPageId) => {
        const data = {
            "paymentGw": "chargebee",
            "cbResponse": {
                "hostedPageId": hostedPageId,
                "subscriptionId": subscription.paymentSubscriptionId
            }
        };
        const apiResp: any = await onlinePaymentFailure(data)(dispatch);
        console.log({ apiResp })
    }

    return <div className="plans">
        {isLoading && <Loading background="#00000070"></Loading>}
        {subscription !== null && !isTrailPeriod && <div className="wrapper">
            <div className="billings">
                <div className="title">
                    Billing & Plans
                </div>
                {subscription?.storeSubscription?.status !== "cancelled" && subscription?.paymentDue && subscription?.paymentDue.amount > 0 &&
                    <div className="details" style={{ marginTop: 20 }}>
                        <div className="row">
                            <div className="amount">{subscription?.paymentDue?.currency === 'inr' ? '₹' : ''}{subscription?.paymentDue?.amount}</div>
                            <div className="status">
                                <div className="dot"></div>
                                Action Due
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: 16, justifyContent: 'space-between' }}>
                            <div className="row">
                                <div className="date">Billed for {subscription?.paymentDue?.billingStartDate} - {subscription?.paymentDue?.billingEndDate}</div>
                                <div className="divider"></div>
                                <div className="due">Due in {subscription?.paymentDue?.dueDate}</div>
                            </div>
                            <div className="row">
                                <div className="view-bill" onClick={() => navigateTo(`/${selectedStore.name}/billing/invoices`)}>View Bill</div>
                                <div className="pay-now" onClick={onMakePayment}>Pay Now</div>
                            </div>
                        </div>
                    </div>}
            </div>
            <div className="manage-subscriptions">
                <div className="title">Manage Subscriptions</div>
                <div className="details">
                    <div className="row">
                        <div className="row" style={{ alignItems: "baseline" }}>
                            <div className="amount">₹{subscription?.storeSubscription?.planFee / 100} / </div>
                            <div className="gst">month + 18% GST</div>
                        </div>
                        <div className={`${subscription?.storeSubscription?.status}`}>
                            {SubscriptionStatus[subscription?.storeSubscription?.status] || subscription?.storeSubscription?.status}
                        </div>
                    </div>
                    {(subscription?.storeSubscription?.status === "cancelled" || subscription?.storeSubscription?.status === "pendingCancellation") && <div className="row" style={{
                        justifyContent: "end"
                    }}>
                        {/* <div className="cancel-subscription" onClick={() => setShowPlan(true)}>
                            Activate Subscription
                        </div> */}
                        <div className="cancel-subscription" onClick={reactivatingSubscription}>
                            Reactivate Subscription
                        </div>
                    </div>}
                    {subscription?.storeSubscription?.status !== "cancelled" && subscription?.storeSubscription?.status !== "pendingCancellation" && <div className="row" style={{ justifyContent: "space-between", marginTop: 19 }}>
                        <div className="billed">Billed on {subscription?.storeSubscription?.billingCycleAnchor} of every month</div>
                        <div className="cancel-subscription" onClick={() => setCancelSubscription(true)}>Cancel Subscription</div>
                    </div>}
                </div>
            </div>
            <div className="app-subscriptions">
                <div className="title">App Subscriptions</div>
                <div className="details">
                    {/* <>
                        <div className="row" style={{ justifyContent: "space-between" }}>
                            <div className="app-name">
                                Product image editor
                            </div>
                            <div className="row">
                                <div className="next-bill">Next Billing Date:</div>
                                <div className="bill-date">January 8, 2024</div>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: "space-between", marginTop: 19 }}>
                            <div className="price">
                                ₹699
                            </div>
                            <div className="cancel-subscription">Cancel Subscription</div>
                        </div>
                    </> */}
                    <>
                        <div className="no-app">
                            <img src={NoAppSvg} alt="App" />
                            <div className="desc">You currently do not have any app subscriptions.</div>
                        </div>
                    </>
                </div>
            </div>
        </div>}
        {subscription !== null && isTrailPeriod && <div className="trail-period">
            <img src={CalenderSvg} alt="Calender" />
            <div className="title">You are in a 30-day trial period</div>
            <div className="desc">We provide a 30-day trial period, allowing you to assess our service at your convenience. Following the trial period, charges will apply.</div>
            <div className="cancel-subscription" onClick={() => setCancelSubscription(true)}>Cancel Subscription</div>
        </div>}
        {isShowPlan && <ShowPlan>
            <div className="container">
                <img alt="doodle" className="doodle" src={DoodleSvg} />
                <img alt="close" className="on-close" src={CloseSvg} onClick={() => setShowPlan(false)} />
                <ChoosePlan successCallback={loadSubscription} />
            </div>
        </ShowPlan>
        }
        {cancelSubscription && <div className="cancel-subscription-popup">
            <div className="container">
                <div className="header">Warning</div>
                <div className="contain">
                    <div className="title">Are you sure you want to cancel your subscription? </div>
                    <div className="row">
                        <div className="accept" onClick={() => setAcceptSubscription(!acceptSubscription)}>
                            {acceptSubscription && <div className="checked"></div>}
                        </div>
                        <div className="desc">
                            By checking this box, you acknowledge that you will lose access to all Shopletzy features and data at the conclusion of your current billing cycle.
                            {/* I accept the
                            <p className="terms">
                                Shopletzy Terms of Use.
                            </p>
                            Your account will remain accessible for 30 days after cancellation. */}
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="stay" onClick={() => setCancelSubscription(false)}>Stay Subscribed</div>
                    <div className="cancel" onClick={() => acceptSubscription && onCancelSub()} style={{
                        cursor: acceptSubscription ? 'pointer' : 'default',
                        color: acceptSubscription ? "#fff" : "#726f6f",
                        border: acceptSubscription ? '1px solid #C70039' : '1px solid #D9D9D9',
                        background: acceptSubscription ? '#C70039' : '#FFF'
                    }}>Cancel Subscrption</div>
                </div>
            </div>
        </div>}
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
    </div>
}

export default Plans;