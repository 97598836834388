import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import Banner from './Banner';
import Layout from './Layout';
import Section from './Section';

const Home = (props: any) => {
    const [onSave, isSave, webTheme]: any = useOutletContext();
    const [title, setTitle] = useState(null);
    // const [sections, setSections] = useState(null);
    const [layoutPostion, setLayoutPostion] = useState(null);
    const [banner, setBanner] = useState(null);

    const [defaultTitle, setDefaultTitle] = useState(null);
    const [defaultLayoutPostion, setDefaultLayoutPostion] = useState(null);
    const [defaultBanner, setDefaultBanner] = useState(null);

    useEffect(() => {
        if (webTheme && webTheme.homePage) {
            const { sections: dSections, banner: dBanner } = webTheme.homePage;
            const { title: dTitle, layoutPostion: dLayoutPostion } = dSections;
            // setSections(dSections);
            setDefaultTitle(dTitle);
            setDefaultLayoutPostion(dLayoutPostion);
            setDefaultBanner(dBanner);
        }
    }, [webTheme]);
    useEffect(() => {
        if (isSave) {
            let payload = {
                title,
                layoutPostion,
                banner
            };
            onSave("Home", payload);
        }
        //eslint-disable-next-line
    }, [isSave]);
    return <div>
        <div className="content" style={{ flexDirection: 'row' }}>
            <div className="container" style={{ flexGrow: 1, padding: 0 }}>
                <div className="home-page">
                    <Section isSave={isSave} title={defaultTitle} setTitle={setTitle} ></Section>
                    <Layout isSave={isSave} layoutPostion={defaultLayoutPostion} setLayoutPostion={setLayoutPostion} ></Layout>
                    <Banner isSave={isSave} banner={defaultBanner} setBanner={setBanner} ></Banner>
                </div>
            </div>
        </div>
    </div>
}

export default Home;