import type from "../types/actionTypes";
import config from "../apiConfig";
import axiosWrapper from "./axiosWrapper";

export const oauthLogin = (oauthDetail) => {
    const options = {
        method: 'POST',
        data: { authProvider: oauthDetail.authProvider, token: oauthDetail.tokenId }
    };
    return async (dispatch) => {
        try {
            dispatch({
                type: type.OAUTH_LOGIN,
                data: oauthDetail
            });
            let url = `${config.baseUrl}${config.authUrl}`
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipAuth: true, skipSubscriptionStatus: true } });
            dispatch({
                type: type.AUTH_SUCCESS,
                data: resp.data
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                if (error.response.data.code === "SC10001") {
                    return { status: "unregistered" };
                } else {
                    dispatch({
                        type: type.ERROR,
                        data: {
                            title: "Oauth Failed",
                            message: error.response.data.message,
                            code: error.response.data.code
                        }
                    });
                }
            } else {
                console.error('error', 'OAUTH', error);
            }
        }
    }
};

export const signup = (oauthDetail) => {
    const options = {
        method: 'POST',
        data: { authProvider: oauthDetail.authProvider, token: oauthDetail.tokenId }
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.authUrl}?signup=true`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipAuth: true, skipSubscriptionStatus: true } });
            dispatch({
                type: type.AUTH_SUCCESS,
                data: resp.data
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                if (error.response.data.code === "SC10001") {
                    return { status: "unregistered" };
                } else {
                    dispatch({
                        type: type.ERROR,
                        data: {
                            title: "Signup Failed",
                            message: error.response.data.message,
                            code: error.response.data.code
                        }
                    });
                }
            } else {
                console.error('error', 'Signup', error);
            }
        }
    };
}

export const otpLogin = (email) => {
    const options = {
        method: 'POST',
        data: { email }
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.otpLoginUrl}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipAuth: true, skipSubscriptionStatus: true } });
            dispatch({
                type: type.OTP_TOKEN_SUCCESS,
                data: { ...resp.data, "timestamp": new Date().getTime() }
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                if (error.response.data.code === "SC10001") {
                    return { status: "unregistered" };
                } else if (error.response.data.code === "SC50000") {
                    return { status: "support" };
                    // return { status: "Some unexpected error occurred, please contact support" };
                } else {
                    dispatch({
                        type: type.ERROR,
                        data: {
                            title: "OTP Login Failed",
                            message: error.response.data.message,
                            code: error.response.data.code
                        }
                    });
                }
            } else {
                console.error('error', 'OTP Login', error);
            }
        }
    };
}

export const authOTPVerify = (data) => {
    const options = {
        method: 'POST',
        data
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.authOTPUrl}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipAuth: true, skipSubscriptionStatus: true } });
            dispatch({
                type: type.AUTH_SUCCESS,
                data: resp.data
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                if (error.response.data.code === "SC10001") {
                    return { status: "unregistered" };
                } else {
                    return { error: error.response.data.message }
                }
            } else {
                console.error('error', 'OTP Login', error);
                return { error }
            }
        }
    };
}

export const emailSignup = (data) => {
    const options = {
        method: 'POST',
        data
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.emailSignupUrl}`;
            let resp = await axiosWrapper(url, options, { dispatch, skipOption: { skipAuth: true, skipSubscriptionStatus: true } });
            dispatch({
                type: type.OTP_TOKEN_SUCCESS,
                data: { ...resp.data, "timestamp": new Date().getTime() }
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                if (error.response.data.code === "SC10001") {
                    return { status: "unregistered" };
                } else {
                    return { error: error.response.data.message }
                }
            } else {
                console.error('error', 'Email Signup', error);
                return { error }
            }
        }
    };
}