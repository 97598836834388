import { Select } from 'antd';
import React, { useState, useEffect } from 'react';
import ColorPalette from '../../../../../common/ColorPalette';

const { Option } = Select;
const FontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900, "bold", "bolder", "normal", "lighter"];
const Section = (props: any) => {
    const { title } = props;
    const [fontWeight, setFontWeight] = useState("normal");
    const [fontSize, setFontSize] = useState(20);
    const [color, setColor] = useState("#000000");
    useEffect(() => {
        if (title) {
            const { fontWeight: dFontWeight, fontSize: dFontSize, color: dColor } = title;
            if (title) {
                if (dFontWeight) {
                    setFontWeight(dFontWeight);
                }
                if (dFontSize) {
                    setFontSize(dFontSize);
                }
                if (dColor) {
                    setColor(dColor);
                }
            }
        }
        //eslint-disable-next-line
    }, [title]);
    useEffect(() => {
        props.setTitle(preState => ({ ...preState, ...{ color: color, fontSize: fontSize, fontWeight: fontWeight } }));
        //eslint-disable-next-line
    }, [fontSize, fontWeight, color])
    return (
        <div style={{ marginBottom: 30 }}>
            <div className="header">Section Title</div>
            <div className="section" style={{ flexGrow: 1 }}>
                <div style={{ display: 'flex', marginBottom: 20, width: '100%', justifyContent: 'space-between', }}>
                    <div>
                        <div className="title">Font Size</div>
                        <div style={{ flexGrow: 1 }} className="key" >Set your section font size</div>
                    </div>
                    <div className="val" style={{ width: '60%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <Select style={{ width: 200 }} value={fontSize} onChange={(val) => { setFontSize(val) }}>
                                {[...Array(21)].map((opt, i) => {
                                    return <Option key={i + 10} value={i + 10}>{i + 10}px</Option>
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', marginBottom: 20, width: '100%', justifyContent: 'space-between', }}>
                    <div>
                        <div className="title">Font Weight</div>
                        <div style={{ flexGrow: 1 }} className="key" >Set your section font weight</div>
                    </div>
                    <div className="val" style={{ width: '60%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <Select style={{ width: 200 }} value={fontWeight} onChange={(val) => { setFontWeight(val) }}>
                                {FontWeights.map((opt) => {
                                    return <Option key={opt} value={opt}>{opt}</Option>
                                })}
                            </Select>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', marginBottom: 20, width: '100%', justifyContent: 'space-between', }}>
                    <div>
                        <div className="title">Font Color</div>
                        <div style={{ flexGrow: 1 }} className="key" >Set your section font color</div>
                    </div>
                    <div className="val" style={{ width: '60%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <ColorPalette defaultColor={color} onColorChange={(val) => setColor(val)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section;