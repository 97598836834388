import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from '../store';
import axiosWrapper from "./axiosWrapper";

export const getAllThemes = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }

    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getAllThemesUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Themes Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };

};

export const getInstalledThemes = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }

    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.getInstalledThemesUrl.replace('{sId}', sId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Themes Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const getThemeConfig = (themeId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }

    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.themesConfigUrl.replace('{sId}', sId).replace('{themeId}', themeId)}`
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Theme Config Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };

};

export const onUpdateThemeConfig = (themeId, data) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.themesConfigUrl.replace('{sId}', sId).replace('{themeId}', themeId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Theme Config Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const onUpdateThemePreset = (data, themeId) => {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.themeApplyPresetUrl.replace('{sId}', sId).replace('{themeId}', themeId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Theme Preset Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const onInstallTheme = (themeId) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        data: {
            storeId: store.getState().ui?.selectedStore?.id
        }
    };
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.installThemeUrl.replace('{themeId}', themeId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Install Theme Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const onActiveTheme = (themeId) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.activeThemeUrl.replace('{sId}', sId).replace('{themeId}', themeId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Active Theme Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}
