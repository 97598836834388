import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import FacebookWhiteSvg from "../../../../../assets/images/settings/facebook-white.svg";
import FacebookSvg from "../../../../../assets/images/settings/facebook.svg";
import FacebookBotSvg from "../../../../../assets/images/facebook-bot.svg";
import { updateChannelSettings, getChannelSettings } from "../../../../../actions/commonAction";
import { useDispatch } from "react-redux";
import apisUrl from "../../../../../apiConfig";
import PopupConfirm from "../../../common/PopupConfirm";
import Loading from "../../../common/Loading";
import MarkdownViewer from "../../../common/MarkdownViewer";

const Facebook = () => {
    const dispatch = useDispatch();
    const WindowObj: any = window;
    const [enabled, setEnabled] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [pageName, setPageName] = useState(null);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        const data = await getChannelSettings('facebook')(dispatch);
        if (data) {
            setEnabled(data.enabled);
            setPageId(data.pageId || null);
            setPageName(data.pageName || null);
        } else {
            setEnabled(null);
            setPageId(null);
            setPageName(null);
        }
    }

    const fbAsyncInit = () => {
        WindowObj.FB.init({
            appId: '1009393129632820',
            cookie: true,
            xfbml: true,
            version: "v20.0",
        });
    };

    useEffect(() => {
        fbAsyncInit();
        fetchData();
        //eslint-disable-next-line
    }, []);

    const onDisconnect = async () => {
        const title = `Disconnect Facebook Page`;
        setPopupConfirm({
            title,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Are you sure you want to disconnect<span style={{ fontWeight: "bold", margin: "0px 6px" }}>{pageName}</span>from Shopletzy?</span></div> },
            yes: {
                label: "Disconnect",
                callback: async () => {
                    setIsLoading(true);
                    await updateChannelSettings('facebook', { authCode: '', enabled: false })(dispatch);
                    fetchData();
                    setIsLoading(false);
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }

    const onSave = async (authCode) => {
        if (enabled && authCode) {
            await updateChannelSettings('facebook', { authCode, enabled })(dispatch);
            fetchData();
        }
    }

    const login = () => {
        WindowObj.FB.login(function (response) {
            console.log(response)
            if (response.authResponse) {
                if (response.authResponse.code) {
                    onSave(response.authResponse.code);
                }
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: apisUrl.whatsappConfigId,
            response_type: 'code',
            override_default_response_type: true
        });
    }

    return <div className="settings">
        <div className="content" style={{ flexDirection: 'row' }}>
            <div className="container" style={{ flexGrow: 1, margin: '0px 24px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                </div>
                <div style={{ height: WindowObj.innerHeight - 80 }}>
                    <div className="division">
                        <div className="header" style={{ display: 'flex', justifyContent: 'space-between', height: 40 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt="Whatsapp" src={FacebookSvg} style={{ marginRight: 10 }} />Facebook
                            </div>
                        </div>
                        <div className="section" style={{ display: 'flex' }}>
                            <div style={{ width: '36vw' }}>
                                <div className="title">Connect Facebook Messenger</div>
                            </div>
                            <div style={{
                                width: '100%'
                            }}>
                                <Switch
                                    className="toggle-opt"
                                    disabled={pageId !== null ? true : false}
                                    onClick={(val) => {
                                        setEnabled(val)
                                    }}
                                    checked={enabled}
                                />
                            </div>
                        </div>
                        {enabled && <div className="section">
                            <div>
                                <div style={{
                                    display: 'flex'
                                }}>
                                    <div style={{ width: '36vw' }}>
                                        <div className="title" style={{ marginBottom: 10 }}>Facebook Messenger</div>
                                        <div style={{ color: "#697b95", width: '72%' }} >To automate replies, allow us to manage
                                            message for your page.</div>
                                        <div style={{ marginTop: 28, color: '#404040', display: 'flex', gap: 10, alignItems: 'center' }}>
                                            {!pageId && <button onClick={login} style={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                padding: "10px 20px",
                                                gap: 10,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                color: '#fff',
                                                border: 'none',
                                                borderRadius: 5,
                                                background: 'linear-gradient(90deg, #188FFC -10.51%, #276FFF 229.67%)'
                                            }}>
                                                <img src={FacebookWhiteSvg} alt="Facebook" />
                                                Sign in with Facebook
                                            </button>}
                                            {pageName}
                                            {pageId && <button onClick={onDisconnect} style={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                                padding: "10px 20px",
                                                gap: 10,
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                justifyContent: 'center',
                                                display: 'flex',
                                                color: '#c70039',
                                                border: 'none',
                                                borderRadius: 5,
                                                background: '#fff'
                                            }}>
                                                Disconnect
                                            </button>}
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }} >
                                        <div>
                                            <img src={FacebookBotSvg} alt="Facebook Bot" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div >
            </div >
            <div
                style={{
                    height: WindowObj.innerHeight - 72,
                    overflowY: 'auto',
                    width: '26%',
                    background: '#FFFFFF',
                    boxShadow: '0px 3px 16px #DBE2EA6C',
                    flexShrink: 0
                }}>
                <MarkdownViewer fileName={'SettingUpFacebookMessengerChatbot'} />
            </div>
        </div>
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        {isLoading && <Loading background="#00000070"></Loading>}
    </div >
}

export default Facebook;