import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import insert from 'markdown-it-ins';
import { useEffect, useState } from 'react';
import axios from 'axios';


const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    highlight(str, lang) { },
}).use(insert);

const MarkdownViewer = ({ fileName }) => {
    const [markdown, setMarkdown] = useState('');
    const fetchMarkdown = async () => {
        const response = await axios.get(`${window.origin}/help-docs/${fileName}.md`);
        if (response?.data) {
            setMarkdown(response.data);
        }
    };
    useEffect(() => {
        if (fileName) {
            fetchMarkdown();
        }
        // eslint-disable-next-line
    }, [fileName]);
    return <MdEditor
        className='markdown-viewer'
        readOnly={true}
        config={{
            view: {
                menu: false,
                md: false,
                html: true,
                fullScreen: false,
                hideMenu: false,
            }
        }}
        value={markdown}
        renderHTML={(text) => mdParser.render(text)}
    />
}

export default MarkdownViewer;