import React, { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Row, Col, Select, Input } from 'antd';
import { getUsers, getRoles } from "../../../../../actions/userActions";
import '../../../../../assets/sass/dsk/employee.sass';
import SearchIcn from "../../../../../assets/images/search.svg"
import Save from './Save';
import { isDark, stringToHslColor } from '../../../../../helpers/util';

const Option = Select;
const RoleColorCode = {
    "admin": "#7828DC",
    "sales": "#1877F2",
    "manager": "blue",
    "delivery": "#DA9A3A",
    "inventory": "gray"
};
const ListedUser = (props: any) => {
    const windowHeight = window.innerHeight;
    // const { stores } = useSelector((state: any) => state.setupStore);
    const { selectedStore } = useSelector((state: any) => state.ui);
    const { outlets } = useSelector((state: any) => state.outlet);
    const [user, setUser] = useState(null);
    const [isSaveUser, setIsSaveUser] = useState(false);
    const [filterUsers, setFilterUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [rolesSearch, setRolesSearch]: any = useState("");
    // const [roleList, setRoleList]: any = useState([]);
    const [userTypeSearch, setUserTypeSearch]: any = useState("all");
    const [emailSearch, setEmailSearch] = useState("");
    const [mobileSearch, setMobileSearch] = useState("");
    const popupRoleDetailRef = useRef();
    const [userSearch, setUserSearch] = useState("");
    const [popupRoleDetail, setPopupRoleDetail] = useState(null);

    useEffect(() => {
        loadData();
        //eslint-disable-next-line
    }, [selectedStore]);

    const loadData = async () => {
        const respRoles = await props.getRoles();
        setRoles(respRoles);
        const respUsers = await props.getUsers();
        setUsers(respUsers);
    }

    useEffect(() => {
        onFilterUser();
        //eslint-disable-next-line
    }, [users])

    useEffect(() => {
        onFilterUser();
        //eslint-disable-next-line
    }, [emailSearch, userTypeSearch, userSearch, mobileSearch, rolesSearch])

    const onFilterUser = () => {
        let filterResults = [];
        let isRoles = false;
        let isUserType = false;
        let isMobile = false;
        let isEmail = false;
        let isUser = false;
        for (let user of users) {
            isRoles = false;
            isMobile = false;
            isEmail = false;
            isUser = false;
            isUserType = false;
            let { mobileNo, fullName, email, stores } = user;
            if (userSearch) {
                if (fullName && fullName.toLowerCase().indexOf(userSearch.toLowerCase()) > -1) {
                    isUser = true
                }
            } else {
                isUser = true;
            }
            if (emailSearch) {
                if (email && email.toLowerCase().indexOf(emailSearch.toLowerCase()) > -1) {
                    isEmail = true
                }
            } else {
                isEmail = true;
            }
            if (userTypeSearch && userTypeSearch !== 'all') {
                if (stores.length > 0 && stores[0].userType.toLowerCase().indexOf(userTypeSearch.toLowerCase()) > -1) {
                    isUserType = true
                }
            } else {
                isUserType = true;
            }
            if (rolesSearch) {
                let sRoles = [];
                for (let s of stores) {
                    if (s.sid === selectedStore.id) {
                        sRoles = s.roles;
                    }
                }
                if (rolesSearch && sRoles.find((el => el.role.indexOf(rolesSearch.toLowerCase()) > -1))) {
                    isRoles = true
                }
            } else {
                isRoles = true;
            }
            if (mobileSearch) {
                if (mobileNo && mobileNo.toString().indexOf(mobileSearch.toString()) > -1) {
                    isMobile = true;
                }
            } else if (mobileSearch.length === 0) {
                isMobile = true;
            }
            if (isUser && isUserType && isEmail && isMobile && isRoles) {
                filterResults.push(user);
            }
        }

        setFilterUsers(filterResults);
    }

    let rolesUnique = [];
    const displayRoles = (uId, roles, isCompleted) => {
        let roleObj = {};
        let roleDom = [];
        for (let r in roles) {
            if (!roleObj[roles[r].role]) {
                rolesUnique.push(roles[r].role)
                roleObj[roles[r].role] = {
                    types: [],
                    items: []
                }
            }
            if (roleObj[roles[r].role].types.indexOf(roles[r].scope) === -1) {
                roleObj[roles[r].role].types.push(roles[r].scope)
            }
            roleObj[roles[r].role].items.push({
                scope: roles[r].scope,
                outlets: roles[r].outlets
            });
        }
        for (let item in roleObj) {
            roleDom.push(
                <div style={{ cursor: 'pointer', marginRight: 9, color: RoleColorCode[item.toLowerCase()] }} key={`${uId}_${item}`} onClick={(e) => { e.stopPropagation(); showRoleDetail(e, uId, item) }}>
                    <div style={{ cursor: 'pointer', fontWeight: popupRoleDetail && popupRoleDetail.uId === uId && popupRoleDetail.rName === item.toLowerCase() ? 'bold' : 'normal', textTransform: "capitalize" }}>{item}</div>
                    <div
                        key={`${item}`}
                        ref={popupRoleDetailRef}
                        style={{
                            fontSize: 12,
                            display: popupRoleDetail && popupRoleDetail.uId === uId && popupRoleDetail.rName === item.toLowerCase() ? 'block' : 'none',
                            position: 'absolute',
                            width: 304,
                            background: '#FFFFFF',
                            boxShadow: '0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4)',
                            borderRadius: 12,
                            zIndex: 9999,
                            padding: "16px 12px"
                        }}>
                        {roleObj[item].items.map((s, i) => {
                            return <div key={`role_detail_${i}`} style={{ marginBottom: s.scope === "store" && roleObj[item].types.length > 1 ? 10 : 0 }}>
                                {s.scope === "store" && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 'bold', color: "#121212" }}>Store Wide Access</div>
                                    <div style={{ textTransform: "capitalize" }}>{item}</div>
                                </div>}
                                {s.outlets && s.outlets.length > 0 && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: 'bold', color: "#121212", marginBottom: 15 }}>Outlets</div>
                                    {<div style={{ textTransform: "capitalize" }}>{item}</div>}
                                </div>
                                }
                                {
                                    s.outlets && s.outlets.map((item) => {
                                        return <div key={item}>{outlets.map((outlet, index) => {
                                            return outlet.id ? <div style={{ textTransform: "capitalize", marginBottom: outlets.length - 1 === index ? 0 : 10 }} key={outlet.id}>{outlet.name}</div> : null
                                        })}</div>
                                    })
                                }
                            </div>
                        })}
                    </div>
                </div>
            )
        }
        // console.log(isCompleted)
        // if (isCompleted) {
        //     debugger
        //     setTableRenderCompleted(true)
        // }
        return roleDom
    }

    const showRoleDetail = (event, uId, rName) => {
        setPopupRoleDetail({
            uId,
            rName,
        });
    }

    // const getStoreName = (sId) => {
    //     let storeName = null;
    //     if (stores) {
    //         stores.filter((s) => {
    //             if (s.id === sId) {
    //                 storeName = s.name;
    //             }
    //         })
    //     }
    //     return storeName;
    // }

    return (
        <Row className="employee" onClick={() => setPopupRoleDetail(null)}>
            <Col className="list" style={{ height: windowHeight - 120 }}>
                <div className="action-bar">
                    <div className="title">
                        You've {users && users.length} User listed
                    </div>
                    <div className="actions">
                        <button className="sp_btn btn" onClick={() => setIsSaveUser(true)} >+ Add User</button>
                    </div>
                </div>
                <div className="content">
                    <Row className="header">
                        <Col span={4}>
                            Name
                        </Col>
                        <Col span={4}>
                            Email
                        </Col>
                        <Col span={3}>
                            Mobie Number
                        </Col>
                        <Col span={10}>
                            Roles
                        </Col>
                        <Col span={3}>
                            User Type
                        </Col>
                    </Row>
                    <Row className="filters">
                        <Col span={4}>
                            <Input className="filter" name="userSearch" onChange={(event) => setUserSearch(event.target.value)} placeholder="User Name" suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={4}>
                            <Input className="filter" placeholder="Email" name="emailSearch" onChange={(event) => setEmailSearch(event.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={3}>
                            <Input className="filter" placeholder="Mobile Number" name="mobileSearch" onChange={(e) => setMobileSearch(e.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={10}>
                            <Select className="filter" placeholder="Role" onChange={(val) => setRolesSearch(val)}  >
                                {roles.map((item) => {
                                    return <Option key={item} value={item}>{item}</Option>
                                })}
                            </Select>
                        </Col>
                        <Col span={3}>
                            <Select className="filter" defaultValue={userTypeSearch} placeholder="User Type" onChange={(val) => setUserTypeSearch(val)}  >
                                <Option value="all">All</Option>
                                <Option value="owner">Owner</Option>
                                <Option value="staff">Staff</Option>
                            </Select>

                        </Col>
                    </Row>
                    <Row className="contains" style={{ height: windowHeight - 240 }}>
                        <Col span={24}>
                            {/* {MemoizedTable} */}
                            {/* {generateRows()} */}
                            {filterUsers && filterUsers.map((record, index) => {
                                const initialLetters = record.fullName.split(" ").length > 1 ? record.fullName.split(" ")[0].charAt(0) + record.fullName.split(" ")[1].charAt(0) : record.fullName.split(" ")[0].charAt(0);
                                return <Row key={record.id} onClick={(e) => { e.stopPropagation(); setUser(record); setIsSaveUser(true); }} className="item" style={{ border: users.length - 1 <= index ? "border: 1px solid #E7EEF7" : "1px solid #E7EEF7" }}>
                                    <Col span={4}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: 30, height: 30,
                                                borderRadius: 30,
                                                textTransform: 'uppercase',
                                                background: stringToHslColor(record.fullName, 60, 40),
                                                fontSize: 12,
                                                marginRight: 10,
                                                color: isDark(stringToHslColor(record.fullName, 60, 40)) ? "#FFF" : "#121212"
                                            }}>{initialLetters}</div>
                                            <div className='row-ellipsis'>{record.fullName}</div>
                                        </div>
                                    </Col>
                                    <Col span={4}>
                                        <div className='row-ellipsis' style={{ paddingTop: 2 }}>{record.email}</div>
                                    </Col>
                                    <Col span={3}>
                                        <div className='row-ellipsis' style={{ paddingTop: 2 }}>{record.mobileNo}</div>
                                    </Col>
                                    <Col span={10}>
                                        <div className='row-ellipsis' style={{ paddingTop: 2, display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                            {record.roles && displayRoles(record.id, record.roles, filterUsers.length - 1 === index)}
                                        </div>
                                    </Col>
                                    <Col span={3}>
                                        <div
                                            className='row-ellipsis'
                                            style={{
                                                textTransform: 'capitalize',
                                                background: record.userType === 'owner' ? '#07C180' : '#0076FF',
                                                color: '#FFF',
                                                borderRadius: 4,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: 10,
                                                height: 30,
                                                paddingTop: 2,
                                                width: 100,
                                            }} key={record.userType}>{record.userType}</div>
                                    </Col>
                                </Row>
                            })}
                        </Col>
                    </Row>
                </div>
            </Col>
            <Save onClose={() => { setIsSaveUser(false); setUser(null); loadData(); }} user={user} roles={roles} isVisible={isSaveUser} />
        </Row >
    )
    // }
}



function mapStateToProps(state: any) {
    // console.log("User mapStateToProps: ", state);
    return {
        auth: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getUsers,
        getRoles
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListedUser);
