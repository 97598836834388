import * as React from "react"

function HomeSvg(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={21.504}
      height={21.501}
      fill={props.fill}
      stroke={props.stroke}
      viewBox="0 0 21.504 21.501"
    >
      <g data-name="Group 41">
        <path
          data-name="Path 22"
          d="M1.392 20.749a1.084 1.084 0 01-.506-.149.461.461 0 01-.13-.375v-10.1L10.405.858a.62.62 0 01.3-.106.594.594 0 01.3.106l9.548 9.267.2 10.1a.606.606 0 01-.13.41.715.715 0 01-.44.114h-6.725a.917.917 0 01-.492-.126.505.505 0 01-.119-.4V14.46s-.122-1.472-2.141-1.455-2.289 1.455-2.289 1.455v5.769a.6.6 0 01-.168.379.9.9 0 01-.45.145z"
          stroke={props.stroke || "#fff"}
          strokeWidth={1.5}
        />
      </g>
    </svg>
  )
}

export default HomeSvg