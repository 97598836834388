import * as React from "react";

const UnCheckSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        viewBox="0 0 15 15"
        {...props}
    >
        <g
            id="Group_12163"
            data-name="Group 12163"
            transform="translate(-1023 -189)"
        >
            <circle
                id="Ellipse_1229"
                data-name="Ellipse 1229"
                cx={7.5}
                cy={7.5}
                r={7.5}
                transform="translate(1023 189)"
                fill="#b5bfcc"
            />
            <path
                id="Path_24231"
                data-name="Path 24231"
                d="M17.273,5.726a.37.37,0,0,1,0,.5l-4.611,4.93a.314.314,0,0,1-.466,0L10.22,9.042a.37.37,0,0,1,0-.5.315.315,0,0,1,.466,0l1.743,1.864,4.378-4.682a.314.314,0,0,1,.466,0Z"
                transform="translate(1016.754 188.061)"
                fill="#fff"
                stroke="#fff"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </g>
    </svg>
);

export default UnCheckSVG;
