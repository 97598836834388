import React, { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import { Upload, Slider, Input, InputNumber, Select } from 'antd';
import UploadSvg from '../../../../../../../assets/images/upload.svg';
import { defaultWebThemeProps } from '../../../../../common/theme';
import ColorPalette from '../../../../../common/ColorPalette';
import apiConfig from '../../../../../../../apiConfig';
import { getBase64 } from '../../../../../../../helpers/util';
import WhatsAppIcon from '../../../../../../../assets/images/whatsApp.png';

const { Option } = Select;
const General = (props: any) => {
    const [onSave, isSave, webTheme, aboutUs, id]: any = useOutletContext();
    const [brandName, setBrandName] = useState(null);
    const [whatsAppNo, setWhatsAppNo] = useState(null);
    const [themeId, setThemeId] = useState("elementary");
    const [deliveryAreaHint, setDeliveryAreaHint] = useState(null);
    const [url, setUrl] = useState(defaultWebThemeProps.brand.url);
    const [deleteUrl, setDeleteUrl] = useState(null);
    const [file, setFile] = useState(null);
    const [aboutUsDetail, setAboutUsDetails] = useState(null);
    const [fontFamily, setFontFamily] = useState(null);
    const [primaryColor, setPrimaryColor] = useState(null);
    const [secondaryColor, setSecondaryColor] = useState(null);
    // const [buttonBGColor, setButtonBGColor] = useState(null);
    const [buttonFGColor, setButtonFGColor] = useState(null);
    const [buttonBorderRadius, setButtonBorderRadius] = useState(null);
    useEffect(() => {
        if (webTheme && webTheme.brand) {
            const {
                deliveryAreaHint: defaultDeliveryAreaHint,
                fontFamily: defaultFontFamily,
                name: defaultName,
                primaryColor: defaultPrimaryColor,
                secondaryColor: defaultSecondaryColor,
                url: defaultUrl,
                whatsAppNo: defaultWhatsAppNo,
                button
            } = webTheme.brand;
            const {
                color,
                borderRadius
            } = button;
            setButtonFGColor(color);
            setButtonBorderRadius(borderRadius);
            // setButtonBGColor(defaultPrimaryColor);
            setFontFamily(defaultFontFamily);
            setPrimaryColor(defaultPrimaryColor);
            setSecondaryColor(defaultSecondaryColor);
            setBrandName(defaultName);
            setWhatsAppNo(defaultWhatsAppNo);
            setDeliveryAreaHint(defaultDeliveryAreaHint);
            setUrl(`${apiConfig.baseImageUrl}${defaultUrl}`);
        }
    }, [webTheme]);
    useEffect(() => {
        setThemeId(id);
    }, [id]);
    useEffect(() => {
        if (aboutUs) {
            setAboutUsDetails(aboutUs);
        }
    }, [aboutUs]);
    const onPreviewImg = async (f) => {
        const prevImg = await getBase64(f.originFileObj);
        f.preview = prevImg;
        setFile(f);
    }

    const handleChange = ({ fileList }) => {
        if (fileList && fileList.length > 1) {
            fileList.shift();
        }
        if (fileList && fileList.length > 0) {
            onPreviewImg(fileList[0]);
        }
    };
    useEffect(() => {
        if (file) {
            setDeleteUrl(url);
            setUrl(file.preview);
        }
        //eslint-disable-next-line
    }, [file && file.preview]);
    useEffect(() => {
        if (isSave) {
            const button = {
                color: buttonFGColor,
                borderRadius: buttonBorderRadius
            };
            let payload = {
                webThemeUpdate: {
                    deliveryAreaHint,
                    fontFamily,
                    name: brandName,
                    primaryColor,
                    secondaryColor,
                    url,
                    whatsAppNo,
                    button,
                    themeId
                },
                file,
                aboutUs: aboutUsDetail,
                deleteUrl
            };
            onSave("General", payload);
        }
        //eslint-disable-next-line
    }, [isSave])
    return <div>
        <div className="content" style={{ flexDirection: 'row' }}>
            <div className="container" style={{ flexGrow: 1, padding: 0 }}>
                <div className="division">
                    <div className="section" style={{ display: 'flex' }}>
                        <div style={{ width: '40%' }}>
                            <div className="title" style={{ marginBottom: 10 }}> Brand Name</div>
                            <div style={{ flexGrow: 1, fontSize: 13 }} className="key" >Set your brand name here</div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            position: 'relative'
                        }}>
                            <div style={{ flexGrow: 1 }}>
                                <Input style={{ width: 400 }} value={brandName} defaultValue={brandName} onChange={(e) => setBrandName(e.target.value)} className="input" placeholder="Enter brand name" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="division" style={{ display: 'none' }}>
                    <div className="section" style={{ display: 'flex' }}>
                        <div style={{ width: '40%' }}>
                            <div className="title" style={{ marginBottom: 10 }}> Theme ID</div>
                            <div style={{ flexGrow: 1, fontSize: 13 }} className="key" >Set your brand theme here</div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            position: 'relative'
                        }}>
                            <div style={{ flexGrow: 1 }}>
                                <Input style={{ width: 400 }} value={themeId} defaultValue={themeId} onChange={(e) => setThemeId(e.target.value)} className="input" placeholder="Enter brand theme id" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="division">
                    <div className="section" style={{ display: 'flex' }}>
                        <div style={{ width: '40%' }}>
                            <div className="title" style={{ marginBottom: 10 }}> Brand Logo</div>
                            <div style={{ flexGrow: 1, fontSize: 13 }} className="key" > Set your brand logo here SVG, PNG or JPG  (max. 500 x 500)</div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            position: 'relative'
                        }}>
                            <Upload
                                customRequest={() => { return false; }}
                                multiple={false}
                                showUploadList={false}
                                onChange={handleChange}
                            >
                                <div className="upload_icon">
                                    <img alt='Upload Icon' src={UploadSvg} />
                                    <div>Choose or drag & drop</div>
                                </div>
                            </Upload>
                            <div style={{
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                                {url && <img alt='Icon' style={{ height: 110, objectFit: 'contain', marginLeft: 30 }} src={url} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="division">
                    <div className="section" style={{ display: 'flex' }}>
                        <div style={{ width: '40%' }}>
                            <div className="title" style={{ marginBottom: 10 }}>WhatsApp Number</div>
                            <div style={{ flexGrow: 1, fontSize: 13 }} className="key" >Set your whatsapp number here</div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            position: 'relative'
                        }}>
                            <div style={{ flexGrow: 1 }}>
                                <Input prefix={<img alt='WhatsApp Icon' src={WhatsAppIcon} style={{ width: 30 }} />} style={{ width: 400 }} value={whatsAppNo} type={"number"} defaultValue={whatsAppNo} onChange={(e) => setWhatsAppNo(e.target.value)} className="input" placeholder="Enter whatsApp number" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="division" style={{ display: "none" }}>
                    <div className="section" style={{ display: 'flex' }}>
                        <div style={{ width: '40%' }}>
                            <div className="title">Font Family</div>
                            <div style={{ flexGrow: 1, fontSize: 13 }} className="key" > Setup brand font style</div>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <Select style={{ width: 400 }} value={fontFamily} defaultValue={fontFamily} placeholder={"Select your font"} onChange={(value) => setFontFamily(value)}  >
                                <Option value={"AvenirNextMedium"}>AvenirNextMedium</Option>
                                {/* <Option value={"ProximaNova"}>ProximaNova</Option> */}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="division" style={{ display: "none" }}>
                    <div className="section" style={{ display: 'flex' }}>
                        <div style={{ width: '40%' }}>
                            <div className="title">Brand Color</div>
                            <div style={{ flexGrow: 1, fontSize: 13 }} className="key" > Set your brand colours</div>
                        </div>
                        <div style={{
                            flexDirection: 'column'
                        }}>
                            <div>
                                <ColorPalette label={"Primary Color"} defaultColor={primaryColor} onColorChange={(color) => {
                                    setPrimaryColor(color)
                                }}></ColorPalette>
                            </div>
                            <div style={{ marginTop: 40 }}>
                                <ColorPalette label={"Secondary Color"} defaultColor={secondaryColor} onColorChange={(color) => {
                                    setSecondaryColor(color)
                                }}></ColorPalette>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="division" style={{ display: "none" }}>
                    <div className="section" style={{ display: 'flex' }}>
                        <div style={{ width: '40%' }}>
                            <div className="title">Button Style</div>
                            <div style={{ flexGrow: 1, fontSize: 13 }} className="key"> Set your button colour & radius</div>
                        </div>
                        <div style={{ flexDirection: 'column' }}>
                            <div className="title" style={{ display: 'flex', flexDirection: 'column', marginTop: 20, marginBottom: 10 }}>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10, alignItems: 'center', height: 60 }}>
                                    <div style={{
                                        fontSize: 14,
                                        height: 40,
                                        width: '70%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: buttonBorderRadius,
                                        cursor: 'pointer',
                                        color: buttonFGColor,
                                        backgroundColor: primaryColor
                                    }}> Check Out</div>
                                </div>
                                <ColorPalette label={"Text Color"} defaultColor={buttonFGColor} onColorChange={(color) => {
                                    setButtonFGColor(color)
                                }}></ColorPalette>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                    <div className="title">Radius</div>
                                    <div style={{ flexGrow: 1, display: 'flex', alignItems: 'flex-end' }}>
                                        <Slider
                                            style={{ flexGrow: 1, marginRight: 10, marginLeft: 0 }}
                                            min={1}
                                            max={20}
                                            onChange={(val) => setButtonBorderRadius(val)}
                                            value={typeof buttonBorderRadius === 'number' ? buttonBorderRadius : 0}
                                        />
                                        <InputNumber
                                            min={1}
                                            max={20}
                                            className="input"
                                            style={{ marginLeft: 16, height: 40 }}
                                            value={buttonBorderRadius}
                                            onChange={(val) => setButtonBorderRadius(val)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default General;