import { ArrowLeftOutlined } from "@ant-design/icons";
import { Checkbox, Drawer, Input, Radio, Select, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import "../../../../../assets/sass/dsk/branding.sass";
import CloseSvg from "../../../../../assets/images/close.svg";
import { generateUniqueId } from "../../../../../helpers/util";
import { onUpdateBusinessHours } from "../../../../../actions/brandAction";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch } from "react-redux";
import Loading from "../../../common/Loading";

dayjs.extend(utc);
const { Option } = Select;
const { TextArea } = Input;
const Days = {
    "monday": "mon",
    "tuesday": "tue",
    "wednesday": "wed",
    "thursday": "thu",
    "friday": "fri",
    "saturday": "sat",
    "sunday": "sun",
};
const Timing = {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: []
}
const DayTiming = {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false
}
const TimeValidation = /^([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AaPp][Mm])$/g;
const Save = (props: any) => {
    const [businessHours, setBusinessHours] = useState(null);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hourType, setHourType] = useState(null);
    const [closedMessage, setClosedMessage] = useState(null);
    const [timingType, setTimingType] = useState(null);
    const [selectedDayTab, setSelectedDayTab] = useState(null);
    const [customTimingHours, setCustomTimingHours] = useState(Timing);
    const [customTiming24Hours, setCustomTiming24Hours] = useState(DayTiming);
    const [sameTimeValidate, setSameTimeValidate] = useState({
        open: true,
        close: true
    });
    const [sameTimeOpen, setSameTimeOpen] = useState("09:30");
    const [sameTimeClose, setSameTimeClose] = useState("18:30");

    useEffect(() => {
        if (sameTimeOpen && TimeValidation.test(sameTimeOpen)) {
            setSameTimeValidate({ ...sameTimeValidate, open: true });
        } else if (sameTimeOpen) {
            setSameTimeValidate({ ...sameTimeValidate, open: false });
        }
        if (sameTimeClose && TimeValidation.test(sameTimeClose)) {
            setSameTimeValidate({ ...sameTimeValidate, close: true });
        } else if (sameTimeClose) {
            setSameTimeValidate({ ...sameTimeValidate, close: false });
        }
        //eslint-disable-next-line
    }, [sameTimeOpen, sameTimeClose]);
    const addCustomHours = () => {
        let timing = customTimingHours[selectedDayTab];
        timing.push({ open: "09:30", close: "18:30", id: generateUniqueId('timing') })
        setCustomTimingHours({ ...customTimingHours, [selectedDayTab]: timing });
    }

    // useEffect(() => {
    //     console.log(customTimingHours)
    // }, [customTimingHours])
    // useEffect(() => {
    //     console.log(customTiming24Hours)
    // }, [customTiming24Hours])

    const onRemoveTiming = (id) => {
        let timing = customTimingHours[selectedDayTab];
        timing = timing.filter((t) => t.id !== id)
        setCustomTimingHours({ ...customTimingHours, [selectedDayTab]: timing });
    }
    const onUpdateTiming = (id, type, val) => {
        let timing = customTimingHours[selectedDayTab];
        for (let t of timing) {
            if (t.id === id) {
                t[type] = val;
            }
        }
        setCustomTimingHours({ ...customTimingHours, [selectedDayTab]: timing });
    }
    // useEffect(() => {
    //     console.log(customTimingHours)
    // }, [customTimingHours])
    // useEffect(() => {
    //     console.log(customTiming24Hours)
    // }, [customTiming24Hours])

    useEffect(() => {
        let timing24Hours = {
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
            sun: false
        }
        let timingHours = {
            mon: [],
            tue: [],
            wed: [],
            thu: [],
            fri: [],
            sat: [],
            sun: []
        }
        if (businessHours) {
            let hType = "24 Hours";
            let tType = "Same Timing"
            if (businessHours.closed) {
                hType = "Closed";
                tType = "All days"
            } else if (businessHours.days) {
                let allDaysTiming = [];
                for (let d in businessHours.days) {
                    if (businessHours.days[d].open24hours) {
                        tType = "Custom Timing";
                        hType = "Custom Hours";
                        timing24Hours[d] = true
                        timingHours[d] = [{ status: "24 Hours" }];
                        // setCustomTiming24Hours({ ...customTiming24Hours, [d]: true })
                    } else {
                        hType = "Custom Hours"
                        if (businessHours.days[d].hours) {
                            let isSameTiming = { open: null, close: null };
                            let hTime = [];
                            for (let h in businessHours.days[d].hours) {
                                if (isSameTiming.open === null) {
                                    isSameTiming.open = businessHours.days[d].hours[h].openTime
                                }
                                if (isSameTiming.close === null) {
                                    isSameTiming.close = businessHours.days[d].hours[h].closeTime
                                }
                                if (allDaysTiming.indexOf(`${businessHours.days[d].hours[h].openTime}-${businessHours.days[d].hours[h].closeTime}`) === -1) {
                                    allDaysTiming.push(`${businessHours.days[d].hours[h].openTime}-${businessHours.days[d].hours[h].closeTime}`)
                                }
                                if (isSameTiming.open !== businessHours.days[d].hours[h].openTime || isSameTiming.close !== businessHours.days[d].hours[h].closeTime) {
                                    tType = "Custom Timing"
                                }
                                hTime.push({
                                    id: generateUniqueId('timing'),
                                    open: businessHours.days[d].hours[h].openTime.slice(0, 2) + ":" + businessHours.days[d].hours[h].openTime.slice(2, 4),
                                    close: businessHours.days[d].hours[h].closeTime.slice(0, 2) + ":" + businessHours.days[d].hours[h].closeTime.slice(2, 4),
                                })
                            }
                            timingHours[d] = hTime;
                            // setCustomTimingHours({ ...customTimingHours, [d]: hTime });
                            if (tType === "Same Timing") {
                                setSameTimeOpen(isSameTiming.open.slice(0, 2) + ":" + isSameTiming.open.slice(2, 4));
                                setSameTimeClose(isSameTiming.close.slice(0, 2) + ":" + isSameTiming.close.slice(2, 4));
                            }
                        } else if (businessHours.days[d].closed) {
                            tType = "Custom Timing";
                            timingHours[d] = [{ status: "Closed" }];
                        }
                    }
                }
                // console.log(".........................", hType, tType)
                if (allDaysTiming.length > 1) {
                    tType = "Custom Timing"
                }
                if (hType === "24 Hours") {
                    // console.log(timing24Hours)
                    // console.log(customTiming24Hours)
                    setCustomTiming24Hours({ ...customTiming24Hours, ...timing24Hours })
                } else if (hType === "Custom Hours") {
                    // console.log(timingHours)
                    // console.log(customTimingHours)
                    setCustomTimingHours({ ...customTimingHours, ...timingHours });
                    setCustomTiming24Hours({ ...customTiming24Hours, ...timing24Hours })
                }
            }
            setHourType(hType)
            setTimingType(tType);
            setClosedMessage(businessHours.closedMessage)
        }
        //eslint-disable-next-line
    }, [businessHours])

    // useEffect(() => {
    //     if (businessHours) {
    //         let hType = "24 Hours";
    //         let tType = "Same Timing"
    //         if (businessHours.closedMessage) {
    //             setClosedMessage(businessHours.closedMessage)
    //         }
    //         if (businessHours.closed) {
    //             hType = "closed";
    //         } else if (businessHours.days) {
    //             for (let d in businessHours.days) {
    //                 if (businessHours.days[d].open24hours) {
    //                     setCustomTiming24Hours({ ...customTiming24Hours, [d]: true })
    //                 } else {
    //                     hType = "Custom Hours"
    //                     if (businessHours.days[d].hours) {
    //                         let isSameTiming = { open: null, close: null };
    //                         let hTime = [];
    //                         for (let h in businessHours.days[d].hours) {
    //                             if (isSameTiming.open === null) {
    //                                 isSameTiming.open = businessHours.days[d].hours[h].openTime
    //                             }
    //                             if (isSameTiming.close === null) {
    //                                 isSameTiming.close = businessHours.days[d].hours[h].closeTime
    //                             }
    //                             if (isSameTiming.open !== businessHours.days[d].hours[h].openTime || isSameTiming.close !== businessHours.days[d].hours[h].closeTime) {
    //                                 tType = "Custom Timing"
    //                             }
    //                             hTime.push({
    //                                 open: businessHours.days[d].hours[h].openTime.slice(0, 2) + ":" + businessHours.days[d].hours[h].openTime.slice(2, 4),
    //                                 close: businessHours.days[d].hours[h].closeTime.slice(0, 2) + ":" + businessHours.days[d].hours[h].closeTime.slice(2, 4),
    //                             })
    //                         }
    //                         setCustomTimingHours({ ...customTimingHours, [d]: hTime });
    //                         setSelectedDayTab("mon");
    //                         if (tType === "Same Timing") {
    //                             setSameTimeOpen(isSameTiming.open.slice(0, 2) + ":" + isSameTiming.open.slice(2, 4));
    //                             setSameTimeClose(isSameTiming.close.slice(0, 2) + ":" + isSameTiming.close.slice(2, 4));
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //         setHourType(hType)
    //         setTimingType(tType);
    //     }
    // }, [businessHours])

    useEffect(() => {
        if (props.businessHours) {
            setBusinessHours(props.businessHours)
        }
    }, [props.businessHours])

    const onSubmit = async () => {
        let payload = {
            closed: false,
            days: {
                sun: {},
                mon: {},
                tue: {},
                wed: {},
                thu: {},
                fri: {},
                sat: {}
            }
        };
        if (hourType === "Custom Hours") {
            if (timingType === "Custom Timing") {
                for (let d in Days) {
                    const day = Days[d];
                    if (customTiming24Hours[day]) {
                        payload.days[day] = {
                            "closed": false,
                            "open24hours": true,
                        }
                    } else if (customTimingHours[day].length > 0) {
                        let hours = []
                        for (let h of customTimingHours[day]) {
                            if (h.open && h.close) {
                                hours.push({
                                    openTime: dayjs(h.open, "HH:mm").format("HH:mm").replace(":", ""),
                                    closeTime: dayjs(h.close, "HH:mm").format("HH:mm").replace(":", "")
                                })
                            } else {
                                payload.days[day] = {
                                    "closed": true
                                }
                            }
                        }
                        if (hours.length > 0) {
                            payload.days[day] = {
                                closed: false,
                                open24hours: false,
                                hours
                            }
                        }
                    } else {
                        payload.days[day] = {
                            "closed": true
                        }
                    }
                }
            } else if (timingType === "Same Timing") {
                const sameHours = {
                    closed: false,
                    open24hours: false,
                    hours: [{
                        openTime: dayjs(sameTimeOpen, "HH:mm").format("HH:mm").replace(":", ""),
                        closeTime: dayjs(sameTimeClose, "HH:mm").format("HH:mm").replace(":", ""),
                    }]
                }
                payload = {
                    "closed": false,
                    "days": {
                        "sun": sameHours,
                        "mon": sameHours,
                        "tue": sameHours,
                        "wed": sameHours,
                        "thu": sameHours,
                        "fri": sameHours,
                        "sat": sameHours
                    }
                };
            } else {
                setError("Selected business timing");
            }
        } else if (hourType === "24 Hours") {
            const _24Hours = {
                open24hours: true
            }
            payload = {
                "closed": false,
                "days": {
                    "sun": _24Hours,
                    "mon": _24Hours,
                    "tue": _24Hours,
                    "wed": _24Hours,
                    "thu": _24Hours,
                    "fri": _24Hours,
                    "sat": _24Hours
                }
            };
        } else if (hourType === "Closed") {
            payload.closed = true;
            payload.days.sun = { closed: true }
            payload.days.mon = { closed: true }
            payload.days.tue = { closed: true }
            payload.days.wed = { closed: true }
            payload.days.thu = { closed: true }
            payload.days.fri = { closed: true }
            payload.days.sat = { closed: true }
        } else {
            setError("Selected business hours");
        }
        if (closedMessage && closedMessage.length === 0 ? null : closedMessage) {
            payload['closedMessage'] = closedMessage;
        }
        if (!error) {
            setLoading(true);
            const resp = await onUpdateBusinessHours(payload)(dispatch);
            setLoading(false);
            if (resp && resp.isUpdated) {
                onClose();
            }
        }
    }

    // useEffect(() => {
    //     setCustomTiming24Hours(DayTiming);
    //     setCustomTiming24Hours(DayTiming);
    // }, [timingType, hourType])

    // useEffect(() => {
    //     console.log(timingType)
    // }, [timingType])

    // useEffect(() => {
    //     console.log(hourType)
    // }, [hourType])

    const onClose = () => {
        setError(null);
        setHourType(null);
        setClosedMessage(null);
        setTimingType(null);
        setSelectedDayTab(null);
        setCustomTimingHours(Timing);
        setCustomTiming24Hours(DayTiming);
        props.onClose();
    }

    // useEffect(() => {
    //     console.log('..................selectedDayTab  ', selectedDayTab)
    //     console.log('--------------------------customTiming24Hours ', customTiming24Hours[selectedDayTab])
    //     console.log(',,,,,,,,,,,,,,,,,,,,,,,,,,customTimingHours ', customTimingHours[selectedDayTab])
    //     console.log(selectedDayTab && customTimingHours[selectedDayTab].filter((th) => th.open).length === 0)
    // }, [selectedDayTab])

    return <Drawer
        width={470}
        maskClosable={false}
        closable={false}
        onClose={onClose}
        className="business_hours_view_edit"
        visible={props.isVisible}
        footer={<div className="footer">
            <div className="sp_btn" onClick={onClose} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
            <div className="sp_btn" onClick={onSubmit} style={{ width: 120, fontSize: 14 }}>Save</div>
        </div>}>
        <div className="header">
            <ArrowLeftOutlined className="arrow" onClick={() => onClose()}></ArrowLeftOutlined>
            <div className="title">Edit Business Hours</div>
        </div>
        <div className="content">
            <div className="group">
                <div className="label">Business Hours</div>
                <Select value={hourType} onChange={(val) => setHourType(val)} placeholder={"Set your business hours"}>
                    <Option key="24 Hours" value="24 Hours">24 Hours X 7 days</Option>
                    <Option key="Custom Hours" value="Custom Hours">Custom Hours</Option>
                    <Option key="Closed" value="Closed">Closed</Option>
                </Select>
            </div>
            {hourType === "Custom Hours" && <div className="group">
                <div className="label">Business Timing</div>
                <Radio.Group style={{ display: 'flex', flexDirection: 'column' }} onChange={(event) => setTimingType(event.target.value)} value={timingType}>
                    <div style={{ display: 'flex', height: 30, marginRight: 16, marginBottom: 28, alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Radio style={{ fontSize: 16, fontWeight: timingType === "Same Timing" ? "bold" : "normal" }} value="Same Timing">Same hours every day</Radio>
                        <TimePicker disabled={timingType !== "Same Timing"} style={{ width: 70, borderBottom: '2px solid #188FFC' }} defaultValue={dayjs(sameTimeOpen, "HH:mm")} format="hh:mm A" onChange={(time, timeString) => setSameTimeOpen(dayjs(timeString, "hh:mm A").format("HH:mm"))} placeholder={"Open"} />
                        {/* <Input style={{ fontSize: 16, width: 95, color: '#188FFC', border: 'none', borderRadius: 0, borderBottom: `2px solid ${sameTimeValidate.open ? '#188FFC' : '#FF0000'}` }} onChange={(e) => setSameTimeOpen(e.target.value)} placeholder={"08:45 AM"} type="text"></Input> */}
                        <div style={{ color: "#000", width: 60, textAlign: 'center', fontSize: 16, border: 'none', background: '#FFF' }} >to</div>
                        <TimePicker disabled={timingType !== "Same Timing"} style={{ width: 70, borderBottom: '2px solid #188FFC' }} defaultValue={dayjs(sameTimeClose, "HH:mm")} format="hh:mm A" onChange={(time, timeString) => setSameTimeClose(dayjs(timeString, "hh:mm A").format("HH:mm"))} placeholder={"Close"} />
                        {/* <Input style={{ fontSize: 16, width: 95, color: '#188FFC', border: 'none', borderRadius: 0, borderBottom: `2px solid ${sameTimeValidate.close ? '#188FFC' : '#FF0000'}` }} placeholder={"06:30 PM"} type="text"></Input> */}
                    </div>
                    <div style={{ display: 'flex', height: 30 }}>
                        <Radio style={{ fontSize: 16, fontWeight: timingType === "Custom Timing" ? "bold" : "normal" }} value="Custom Timing">Different hours every day</Radio>
                    </div>
                </Radio.Group>
            </div>}
            {hourType === "Custom Hours" && timingType === "Custom Timing" && <div className="group">
                <div className="label">Business Days</div>
                <div className="days_tab">
                    {Object.keys(Days).map((day) => {
                        // console.log(day)
                        // console.log(customTimingHours && customTimingHours[Days[day]] && customTimingHours[Days[day]].filter((th) => th.open).length > 0, " ::::customTimingHours")
                        // console.log(customTiming24Hours[Days[day]], " :::customTiming24Hours[Days[day]]")
                        // console.log(customTimingHours[Days[day]].filter((th) => th.open))
                        return <div key={day} className={selectedDayTab === Days[day] ? "day selected" : "day"} onClick={() => setSelectedDayTab(Days[day])} >
                            <div>{Days[day]}</div>
                            <div className={(selectedDayTab !== Days[day] && customTimingHours && customTimingHours[Days[day]] && customTimingHours[Days[day]].filter((th) => th.open).length > 0) || customTiming24Hours[Days[day]] ? "filled" : ""}></div>
                        </div>
                    })}
                </div>
                {/* {customTimingHours[selectedDayTab] && */}
                {selectedDayTab && <div className="custom_timing_hours">
                    {!customTiming24Hours[selectedDayTab] && customTimingHours[selectedDayTab] && customTimingHours[selectedDayTab].map((t, i) => {
                        return t.open && t.close && t.id && <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }} key={`timing_${t.id}`}>
                            <TimePicker style={{ width: 86 }} defaultValue={dayjs(t.open, "HH:mm")} format="hh:mm A" onChange={(time, timeString) => onUpdateTiming(t.id, 'open', dayjs(timeString, "hh:mm A").format("HH:mm"))} placeholder={"Open"} />
                            {/* <Input style={{ fontSize: 16, width: 95, color: '#188FFC', border: 'none', borderRadius: 0, borderBottom: `2px solid ${sameTimeValidate.open ? '#188FFC' : '#FF0000'}` }} onChange={(e) => onUpdateTiming(t.id, 'open', e.target.value)} placeholder={"08:45 AM"} type="text"></Input> */}
                            <div style={{ color: "#000", width: 60, fontSize: 16, border: 'none', background: '#FFF', textAlign: 'center' }} >to</div>
                            <TimePicker style={{ width: 86 }} defaultValue={dayjs(t.close, "HH:mm")} format="hh:mm A" onChange={(time, timeString) => onUpdateTiming(t.id, 'close', dayjs(timeString, "hh:mm A").format("HH:mm"))} placeholder={"Close"} />
                            {/* <Input style={{ fontSize: 16, width: 95, color: '#188FFC', border: 'none', borderRadius: 0, borderBottom: `2px solid ${sameTimeValidate.close ? '#188FFC' : '#FF0000'}` }} placeholder={"06:30 PM"} onChange={(e) => onUpdateTiming(t.id, 'close', e.target.value)} type="text"></Input> */}
                            <img alt="Close Icon" src={CloseSvg} style={{ marginLeft: 10 }} onClick={() => { onRemoveTiming(t.id) }} />
                        </div>
                    })}
                    {!customTiming24Hours[selectedDayTab] && <div className="sp_btn" style={{ marginTop: 28, width: 140, fontSize: 16 }} onClick={addCustomHours}>Add Hours</div>}
                    <Checkbox key={selectedDayTab} checked={(selectedDayTab && customTiming24Hours[selectedDayTab]) || false} onChange={(e) => setCustomTiming24Hours({ ...customTiming24Hours, [selectedDayTab]: e.target.checked })} style={{ marginTop: 24 }}> Open 24 Hours</Checkbox>
                </div>}
                {/* } */}
            </div>}
            {(hourType === "Closed" || (selectedDayTab && (!customTiming24Hours[selectedDayTab] && customTimingHours && customTimingHours[selectedDayTab].filter((th) => th.open).length === 0))) && <div className="group">
                <div className="label">Closed Message</div>
                <TextArea value={closedMessage} onChange={(e) => setClosedMessage(e.target.value)} autoSize={{ minRows: 4, maxRows: 6 }} showCount maxLength={300} placeholder="Enter message"></TextArea>
            </div>}
        </div>
        {isLoading && <Loading background="#00000070"></Loading>}
    </Drawer>

}

export default Save;