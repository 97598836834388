import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import '../../../../../assets/sass/dsk/outlet.sass';
import { AimOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { searchAddress, getAddress } from "../../../../../actions/setupStoreActions";
import { createOutlet, updateOutlet } from "../../../../../actions/outletAction";
import { Row, Col, Select, Input, Drawer } from 'antd';
// import MapWidget from '../../../common/MapWidget';
import InputValidation from '../../../common/InputValidation';
import { isNotNullOrUndefinedString } from '../../../../../helpers/util';
import Loading from '../../../common/Loading';
import { Countries } from '../../../../../countries';

const { Option } = Select;
const windowHeight = window.innerHeight;
class Save extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            isEdit: true,
            name: "",
            suggestions: [],
            outlet: null,
            sessionToken: null,
            addressSearch: "",
            address: {
                name: "",
                addressLine1: "",
                addressLine2: "",
                formattedAddress: "",
                placeId: "",
                zipcode: "",
                area: "",
                city: "",
                state: "",
                country: "",
                plusCode: ""
            },
            contactNumber: null,
            deliveryType: "maxDistance",
            cities: [],
            states: [],
            postalCodes: [],
            countries: [],
            maxDistance: 1,
            error: {
                name: {
                    message: "",
                    type: ""
                },
                address1: {
                    message: "",
                    type: ""
                },
                address2: {
                    message: "",
                    type: ""
                },
                area: {
                    message: "",
                    type: ""
                },
                city: {
                    message: "",
                    type: ""
                },
                contactNumber: {
                    message: "",
                    type: ""
                },
                state: {
                    message: "",
                    type: ""
                },
                zipcode: {
                    message: "",
                    type: ""
                },
                country: {
                    message: "",
                    type: ""
                },
                range: {
                    message: "",
                    type: ""
                },
            },
        };
    }

    // componentWillMount() {
    //     this.init();
    // }

    // init = () => {
    //     let { outlet } = this.props;
    //     console.log("outlet", outlet);
    //     let addressLine1 = (outlet && outlet.address && outlet.address.addressLine1) || "",
    //         addressLine2 = (outlet && outlet.address && outlet.address.addressLine2) || "",
    //         area = (outlet && outlet.address && outlet.address.area) || "",
    //         city = (outlet && outlet.address && outlet.address.city) || "",
    //         state = (outlet && outlet.address && outlet.address.state) || "",
    //         zipcode = (outlet && outlet.address && outlet.address.zipcode) || "",
    //         country = (outlet && outlet.address && outlet.address.country) || "",
    //         plusCode = (outlet && outlet.placeDetails && outlet.placeDetails.plusCode) || "";
    //     let geoCoords = outlet && outlet.placeDetails && outlet.placeDetails.geoCoords ? outlet.placeDetails.geoCoords : {
    //         lat: 12.94356,
    //         lng: 80.2386649
    //     };
    //     let formattedAddress = (outlet && outlet.placeDetails && outlet.placeDetails.formattedAddress) || "",
    //         name = (outlet && outlet.placeDetails && outlet.placeDetails.name) || "",
    //         placeId = (outlet && outlet.placeDetails && outlet.placeDetails.placeId) || "";
    //     let address = {
    //         formattedAddress,
    //         name,
    //         placeId,
    //         addressLine1,
    //         addressLine2,
    //         area,
    //         city,
    //         state,
    //         zipcode,
    //         country,
    //         plusCode,
    //         geoCoords,
    //     };
    //     this.setState({
    //         addressSearch: "",
    //         isEdit: outlet && outlet.id ? false : true,
    //         outlet,
    //         name: (outlet && outlet.name) || "",
    //         address,
    //         contactNumber: null
    //     });
    //     if (outlet && outlet.deliveryArea) {
    //         if (outlet.deliveryArea.maxDistance) {
    //             this.setState({
    //                 deliveryType: "maxDistance",
    //                 maxDistance: outlet.deliveryArea.maxDistance
    //             })
    //         } else if (outlet.deliveryArea.countries) {
    //             this.setState({
    //                 deliveryType: "country",
    //                 countries: outlet.deliveryArea.countries
    //             })
    //         } else if (outlet.deliveryArea.cities) {
    //             this.setState({
    //                 deliveryType: "city",
    //                 cities: outlet.deliveryArea.cities
    //             })
    //         } else if (outlet.deliveryArea.states) {
    //             this.setState({
    //                 deliveryType: "state",
    //                 states: outlet.deliveryArea.states
    //             })
    //         } else if (outlet.deliveryArea.postalCodes) {
    //             this.setState({
    //                 deliveryType: "postalCode",
    //                 postalCodes: outlet.deliveryArea.postalCodes
    //             })
    //         }
    //     } else {
    //         this.setState({
    //             deliveryType: "maxDistance",
    //             maxDistance: 1
    //         })
    //     }
    // }

    // componentDidUpdate() {
    //     if (this.props.outlet !== this.state.outlet) {
    //         this.init();
    //     }
    // }

    onHandleCancel = () => {
        this.setState({
            error: {
                name: {
                    message: "",
                    type: ""
                },
                address1: {
                    message: "",
                    type: ""
                },
                address2: {
                    message: "",
                    type: ""
                },
                area: {
                    message: "",
                    type: ""
                },
                city: {
                    message: "",
                    type: ""
                },
                contactNumber: {
                    message: "",
                    type: ""
                },
                state: {
                    message: "",
                    type: ""
                },
                zipcode: {
                    message: "",
                    type: ""
                },
                country: {
                    message: "",
                    type: ""
                },
                range: {
                    message: "",
                    type: ""
                },
            }
        })
        this.props.onClose();
    }
    gotoOutlets = () => {
        const { history } = this.props;
        history.push({ pathname: "/outlets" });
    }
    updatePhoneNumber = (value) => {
        this.setState({
            contactNumber: value
        });
    }
    searchAddress = async (value) => {
        if (value && value.length > 2) {
            let resp = await this.props.searchAddress(value);
            if (resp && resp.suggestions) {
                this.setState({
                    suggestions: resp.suggestions,
                    sessionToken: resp.sessionToken
                })
            }
        }
    }
    getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition);
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }
    showPosition = (position) => {
        if (position && position.coords) {
            let { address } = this.state;
            if (position.coords.latitude) {
                address.geoCoords.lat = position.coords.latitude;
            }
            if (position.coords.longitude) {
                address.geoCoords.lng = position.coords.longitude;
            }
            this.setState({
                address
            })
        }
        console.log("Latitude: " + position.coords.latitude + "<br>Longitude: " + position.coords.longitude);
    }
    updateAddress = (e) => {
        if (e.target.name) {
            this.setState({
                address: { ...this.state.address, [e.target.name]: e.target.value }
            });
        }
    }

    onHandleSubmit = async () => {
        this.setState({
            isLoading: true
        });
        let error = {
            contactNumber: {
                message: "",
                type: ""
            },
            name: {
                message: "",
                type: ""
            },
            address1: {
                message: "",
                type: ""
            },
            address2: {
                message: "",
                type: ""
            },
            area: {
                message: "",
                type: ""
            },
            city: {
                message: "",
                type: ""
            },
            state: {
                message: "",
                type: ""
            },
            zipcode: {
                message: "",
                type: ""
            },
            country: {
                message: "",
                type: ""
            },
            range: {
                message: "",
                type: ""
            },
        },
            isError = false,
            resp = null;
        this.setState({ error });
        if (!isNotNullOrUndefinedString(this.state.name)) {
            error.name.type = "error";
            error.name.message = "Fill the outlet name";
            isError = true;

        } else if (this.state.name === "Duplicate check") {
            error.name.type = "error";
            error.name.message = "Outlet name already exists";
            isError = true;
        }
        if (!isNotNullOrUndefinedString(this.state.contactNumber) || !/^\+?[0-9-\s]{8,14}\d$/.test(this.state.contactNumber)) {
            error.contactNumber.message = "Enter a valid mobile number";
            error.contactNumber.type = "error";
            isError = true;
        }
        if (!isNotNullOrUndefinedString(this.state.address.area)) {
            error.area.message = "Fill the outlet area";
            error.area.type = "error";
            isError = true;
        }
        if (!isNotNullOrUndefinedString(this.state.address.addressLine1)) {
            error.address1.type = "error";
            error.address1.message = "Fill the outlet address line1";
            isError = true;
        }
        if (!isNotNullOrUndefinedString(this.state.address.state)) {
            error.state.type = "error";
            error.state.message = "Fill the outlet state";
            isError = true;
        }
        let zcde = this.state.address.zipcode
        if (!isNotNullOrUndefinedString(zcde) || !/^\+?[0-9-\s]{4,14}\d$/.test(zcde)) {
            error.zipcode.type = "error";
            error.zipcode.message = "Enter a valid zipcode";
            isError = true;
        }
        if (!isNotNullOrUndefinedString(this.state.address.city)) {
            error.city.type = "error";
            error.city.message = "Fill the outlet city";
            isError = true;
        }
        if (this.state.deliveryType === "maxDistance" && this.state.maxDistance < 1) {
            error.range.type = "error";
            error.range.message = "Max distance greater then zero";
            isError = true;
        } else if (this.state.deliveryType === "state" && this.state.states.length === 0) {
            error.range.type = "error";
            error.range.message = "choose a minimum of one state";
            isError = true;
        } else if (this.state.deliveryType === "city" && this.state.cities.length === 0) {
            error.range.type = "error";
            error.range.message = "choose a minimum of one city";
            isError = true;
        } else if (this.state.deliveryType === "country" && this.state.countries.length === 0) {
            error.range.type = "error";
            error.range.message = "choose a minimum of one country";
            isError = true;
        } else if (this.state.deliveryType === "postalCode" && this.state.postalCodes.length === 0) {
            error.range.type = "error";
            error.range.message = "choose a minimum of one postal code";
            isError = true;
        }
        if (!isError) {
            let outletPayload = {
                name: this.state.name,
                contactNumber: this.state.contactNumber,
                address: {
                    addressLine1: this.state.address.addressLine1,
                    addressLine2: this.state.address.addressLine2,
                    area: this.state.address.area,
                    city: this.state.address.city,
                    state: this.state.address.state,
                    zipcode: this.state.address.zipcode,
                    country: "IN"
                },
                placeDetails: {
                    placeId: this.state.address.placeId,
                    name: this.state.address.name,
                    plusCode: this.state.address.plusCode,
                    formattedAddress: this.state.address.formattedAddress,
                    geometry: {
                        type: "Point",
                        coordinates: [this.state.address.geoCoords.lng, this.state.address.geoCoords.lat]
                    }
                },
                deliveryArea: {
                    type: this.state.deliveryType
                }
            }
            if (outletPayload.deliveryArea.type === "maxDistance") {
                outletPayload.deliveryArea['maxDistance'] = parseFloat(this.state.maxDistance);
            } else if (outletPayload.deliveryArea.type === "state") {
                outletPayload.deliveryArea['states'] = this.state.states;
            } else if (outletPayload.deliveryArea.type === "city") {
                outletPayload.deliveryArea['cities'] = this.state.cities;
            } else if (outletPayload.deliveryArea.type === "country") {
                outletPayload.deliveryArea['countries'] = this.state.countries;
            } else if (outletPayload.deliveryArea.type === "postalCode") {
                outletPayload.deliveryArea['postalCodes'] = this.state.postalCodes;
            }
            // if (!this.state.outlet || !this.state.outlet.id) {
            //     resp = await this.props.createOutlet(outletPayload);
            // } else if (this.state.outlet && this.state.outlet.id) {
            //     resp = await this.props.updateOutlet(this.state.outlet.id, outletPayload)
            // }
            resp = await this.props.createOutlet(outletPayload);
            this.setState({
                isLoading: false
            });
            if (resp && (resp.isUpdated || resp.ouId)) {
                this.onHandleCancel();
            }
        } else {
            this.setState({
                isLoading: false,
                error
            });
        }
    }

    selectedAddress = async (item) => {
        this.setState({
            suggestions: []
        });
        this.setState({ address: { ...this.state.address, "placeId": item.placeId }, suggestions: [] });
        let resp = await this.props.getAddress(item.placeId, this.state.sessionToken);
        if (resp && resp.addressComponents) {
            let { addressLine, area, city, country, state, zipcode } = resp.addressComponents;
            let { geometry, formattedAddress, name, plusCode } = resp;
            let { address, addressSearch } = this.state;
            addressSearch = name || "";
            address.name = name || "";
            address.addressLine1 = addressLine || "";
            address.addressLine2 = "";
            address.formattedAddress = formattedAddress || "";
            address.placeId = item.placeId;
            address.zipcode = zipcode || "";
            address.area = area || "";
            address.city = city || "";
            address.state = state || "";
            address.country = country || "";
            address.plusCode = plusCode;
            if (!plusCode) {
                alert("PlusCode is missing");
            }
            if (geometry && geometry.coordinates && geometry.coordinates.length === 2) {
                address.geoCoords = {
                    lat: geometry.coordinates[1],
                    lng: geometry.coordinates[0]
                };
            }
            this.setState({
                address,
                addressSearch
            });
        }
    }

    render() {
        return (
            <Drawer
                width={470}
                closable={false}
                onClose={this.onHandleCancel}
                className="outlet-update"
                visible={this.props.isVisible}
                footer={
                    <div className="footer">
                        <div className="sp_btn" onClick={this.onHandleCancel} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                        {/* {this.props.outlet && !this.state.isEdit && <div className="sp_btn" onClick={() => { this.setState({ isEdit: true }) }} style={{ width: 120, fontSize: 14 }}>Edit</div>} */}
                        <div className="sp_btn" onClick={this.onHandleSubmit} style={{ width: 155, fontSize: 14 }}>Create Outlet</div>
                    </div>
                }
            >
                <Row>
                    {/* <Col span={12}>
                        <MapWidget lat={this.state.address.geoCoords.lat} lng={this.state.address.geoCoords.lng}></MapWidget>
                    </Col> */}
                    <Col span={24} className="content" style={{ marginTop: 0 }}>
                        <div className="header">
                            <ArrowLeftOutlined className="arrow" onClick={this.onHandleCancel}></ArrowLeftOutlined>
                            <div className="title">Outlet Details</div>
                        </div>
                        {/* <div className="group title">
                            <ArrowLeftOutlined className="back" onClick={this.onHandleCancel} style={{
                                position: "absolute",
                                cursor: "pointer"
                            }} ></ArrowLeftOutlined>
                            Outlet Details
                        </div> */}
                        <div style={{ height: windowHeight - 166, overflowY: "auto", marginTop: 30 }}>
                            <div className="group">
                                <div className="label">Outlet Name *</div>
                                <InputValidation type={this.state.error.name.type} message={this.state.error.name.message}>
                                    <Input disabled={!this.state.isEdit} type="text" name="name" value={this.state.name} defaultValue={this.state.name} placeholder="Outlet name"
                                        onChange={(e) => this.setState({ name: e.target.value })}
                                    //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                    />
                                </InputValidation>
                            </div>
                            <div className="group">
                                <div className="label">Search Address *</div>
                                <Input disabled={!this.state.isEdit} type="text" name="search" placeholder="Search here"
                                    value={this.state.addressSearch}
                                    onChange={(e) => { this.searchAddress(e.target.value); this.setState({ addressSearch: e.target.value }) }}
                                    suffix={<AimOutlined onClick={this.getLocation} />}
                                //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                />
                                {this.state.suggestions.length > 0 && <div className="suggestions">
                                    {this.state.suggestions.map((item, index) => {
                                        return (
                                            <div key={item.placeId} style={{ borderBottomWidth: this.state.suggestions.length - 1 > index ? "1px" : "0px" }} className="item"
                                                onClick={() => this.selectedAddress(item)}>
                                                <div className="main">{item.mainText}</div>
                                                <div className="secondary">{item.secondaryText}</div>
                                            </div>
                                        )
                                    })}
                                </div>}
                            </div>
                            <div className="group">
                                <div className="label">Address Line1 *</div>
                                <InputValidation type={this.state.error.address1.type} message={this.state.error.address1.message}>
                                    <Input disabled={!this.state.isEdit} type="text" name="addressLine1" value={this.state.address.addressLine1} defaultValue={this.state.address.addressLine1} placeholder="Address Line 1"
                                        onChange={this.updateAddress}
                                    //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                    />
                                </InputValidation>
                            </div>
                            <div className="group">
                                <div className="label">Address Line2</div>
                                <InputValidation type={this.state.error.address2.type} message={this.state.error.address2.message}>
                                    <Input disabled={!this.state.isEdit} type="text" name="addressLine2" defaultValue={this.state.address.addressLine2} placeholder="Address Line 2"
                                        onChange={this.updateAddress}
                                    //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                    />
                                </InputValidation>
                            </div>
                            {/* <div className="group">
                                <div className="label">Landmark</div>
                                <InputValidation type={this.state.error.landmark.type} message={this.state.error.landmark.message}>
                                <Input type="text" name="landmark" defaultValue={this.state.address.landmark} placeholder="Landmark"
                                    onChange={this.updateAddress}
                                />
                                </InputValidation>
                            </div> */}
                            <div className="group">
                                <Row>
                                    <Col span={12} style={{ paddingRight: "15px" }}>
                                        <div className="label">Area *</div>
                                        <InputValidation type={this.state.error.area.type} message={this.state.error.area.message}>
                                            <Input disabled={!this.state.isEdit} type="text" name="area" value={this.state.address.area} defaultValue={this.state.address.area} placeholder="Area"
                                                onChange={this.updateAddress}
                                            //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                            />
                                        </InputValidation>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: "15px" }}>
                                        <div className="label">Zipcode *</div>
                                        <InputValidation type={this.state.error.zipcode.type} message={this.state.error.zipcode.message}>
                                            <Input disabled={!this.state.isEdit} type="text" name="zipcode" value={this.state.address.zipcode} defaultValue={this.state.address.zipcode} placeholder="Zipcode"
                                                onChange={this.updateAddress}
                                            //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                            />
                                        </InputValidation>
                                    </Col>
                                </Row>
                            </div>
                            <div className="group">
                                <Row>
                                    <Col span={12} style={{ paddingRight: "15px" }}>
                                        <div className="label">City *</div>
                                        <InputValidation type={this.state.error.city.type} message={this.state.error.city.message}>
                                            <Input disabled={!this.state.isEdit} type="text" name="city" value={this.state.address.city} defaultValue={this.state.address.city} placeholder="City"
                                                onChange={this.updateAddress}
                                            //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                            />
                                        </InputValidation>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: "15px" }}>
                                        <div className="label">State *</div>
                                        <InputValidation type={this.state.error.state.type} message={this.state.error.state.message}>
                                            <Input disabled={!this.state.isEdit} type="text" name="state" value={this.state.address.state} defaultValue={this.state.address.state} placeholder="State"
                                                onChange={this.updateAddress}
                                            //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                            />
                                        </InputValidation>
                                    </Col>
                                </Row>
                            </div>
                            <div className="group">
                                <div className="label">Telephone *</div>
                                <InputValidation type={this.state.error.contactNumber.type} message={this.state.error.contactNumber.message}>
                                    <Input disabled={!this.state.isEdit} min="1" type="tel" pattern="[]" name="mobile" value={this.state.contactNumber} placeholder="10-digit mobile number or Landline"
                                        onChange={(e) => this.updatePhoneNumber(e.target.value)}
                                    //onBlur={event => event.target.value && this.getDomainAvailable(event.target.value)}
                                    />
                                </InputValidation>
                            </div>

                            <div className="group">
                                <div className="label">Delivery Range Type</div>
                                <Select disabled={!this.state.isEdit} value={this.state.deliveryType} onChange={(val) => this.setState({ deliveryType: val })}>
                                    <Option value={"maxDistance"}>Max distance from outlet</Option>
                                    <Option value={"state"}>States</Option>
                                    <Option value={"city"}>Cities</Option>
                                    <Option value={"country"}>Country</Option>
                                    <Option value={"postalCode"}>Pincodes</Option>
                                </Select>
                            </div>

                            {this.state.deliveryType === "maxDistance" && <div className="group">
                                <div className="label">Max Distance (km)</div>
                                <InputValidation type={this.state.error.range.type} message={this.state.error.range.message}>
                                    <Input disabled={!this.state.isEdit} type="number" min="1" name="maxDistance" value={this.state.maxDistance} placeholder="Max Distance"
                                        onChange={(e) => this.setState({ "maxDistance": e.target.value })}
                                    />
                                </InputValidation>
                            </div>}
                            {this.state.deliveryType === "state" && <div className="group">
                                <div className="label">States</div>
                                <InputValidation type={this.state.error.range.type} message={this.state.error.range.message}>
                                    <Select disabled={!this.state.isEdit} mode='multiple' maxTagCount='responsive' defaultValue={this.state.states} onChange={(val) => this.setState({ states: val })}>
                                        {Countries && Countries.IND.map((item) => { return item.type.toLocaleLowerCase() === "state" && <Option key={item.name} value={item.name}>{item.name}</Option> })}
                                    </Select>
                                </InputValidation>
                            </div>}
                            {this.state.deliveryType === "city" && <div className="group">
                                <div className="label">Cities</div>
                                <InputValidation type={this.state.error.range.type} message={this.state.error.range.message}>
                                    <Select disabled={!this.state.isEdit} mode='multiple' maxTagCount='responsive' defaultValue={this.state.cities} onChange={(val) => this.setState({ cities: val })}>
                                        {Countries && Countries.IND.map((item) => {
                                            return item.districts.map((citi) => {
                                                return <Option key={citi.name} value={citi.name}>{citi.name}</Option>
                                            })
                                        })}
                                    </Select>
                                </InputValidation>
                            </div>}
                            {this.state.deliveryType === "country" && <div className="group">
                                <div className="label">Countries</div>
                                <InputValidation type={this.state.error.range.type} message={this.state.error.range.message}>
                                    <Select disabled={!this.state.isEdit} mode='multiple' maxTagCount='responsive' defaultValue={this.state.countries} onChange={(val) => this.setState({ countries: val })}>
                                        <Option value={'India'}>{'India'}</Option>
                                    </Select>
                                </InputValidation>
                            </div>}
                            {this.state.deliveryType === "postalCode" && <div className="group">
                                <div className="label">Pincodes</div>
                                <InputValidation type={this.state.error.range.type} message={this.state.error.range.message}>
                                    <Select maxTagCount={'responsive' as const} mode="tags" disabled={!this.state.isEdit} value={this.state.postalCodes} placeholder="Pincode"
                                        onChange={(value: any) => {
                                            let validatePincodes = [];
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            for (let i of value) {
                                                if ((i.length === 6 && !isNaN(i) && reg.test(i)) || i === '' || i === '-') {
                                                    validatePincodes.push(i)
                                                }
                                            }
                                            this.setState({
                                                postalCodes: validatePincodes
                                            });
                                        }}
                                    />
                                </InputValidation>
                            </div>}
                        </div>
                        {/* <div className="footer">
                            <div className="sp_btn" onClick={this.onHandleCancel} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                            {this.props.outlet && !this.state.isEdit && <div className="sp_btn" onClick={() => { this.setState({ isEdit: true }) }} style={{ width: 120, fontSize: 14 }}>Edit</div>}
                            {this.state.isEdit && <div className="sp_btn" onClick={this.onHandleSubmit} style={{ width: 155, fontSize: 14 }}>{this.props.outlet && this.props.outlet.id ? "Update Outlet" : "Create Outlet"}</div>} */}
                        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div className="sp_btn" onClick={this.onHandleCancel} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                                {this.props.outlet && !this.state.isEdit && <div className="sp_btn" onClick={() => { this.setState({ isEdit: true }) }} style={{ width: 120, fontSize: 14 }}>Edit</div>}
                                {this.state.isEdit && <div className="sp_btn" onClick={this.onHandleSubmit} style={{ width: 155, fontSize: 14 }}>{this.props.outlet && this.props.outlet.id ? "Update Outlet" : "Create Outlet"}</div>}
                            </div> */}
                        {/* <Row>
                                <Col span={11} style={{ paddingRight: "15px" }}>
                                    <button onClick={() => this.onHandleCancel()} className="sp_btn cancel">Cancel</button>
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11} style={{ paddingLeft: "15px" }}>
                                    {this.state.isEdit && <button onClick={this.onHandleSubmit} className="sp_btn save">{this.props.outlet && this.props.outlet.id ? "Update Outlet" : "Create Outlet"}</button>}
                                    {this.props.outlet && !this.state.isEdit && <button onClick={() => this.setState({ isEdit: true })} className="sp_btn edit">Edit</button>}
                                </Col>
                            </Row> */}
                        {/* </div> */}
                    </Col>
                    {this.state.isLoading && <Loading background="#00000070"></Loading>}
                </Row>
            </Drawer>
        )
    }
}



function mapStateToProps(state: any) {
    // console.log("Product View mapStateToProps: ", state);
    return {
        ui: state.ui,
        setupStore: state.setupStore
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchAddress,
        getAddress,
        // clearSearchAddress,
        // clearGetAddressSuccess,
        createOutlet,
        updateOutlet
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Save);