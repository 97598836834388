import React from 'react';
import '../../../assets/sass/dsk/menu.sass';
import ArrowSvg from "../../../assets/images/arrow_ico.svg";
import { useNav } from '../../../helpers/customHook';

const DetailMenu = (props: any) => {
    const navigateTo = useNav();
    const gotoCustomers = () => {
        navigateTo("customers");
    }
    return (
        <div className="header-menu-bar" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%' }}>
            <div className="title">
                <div style={{ cursor: 'pointer' }} onClick={gotoCustomers}>
                    Customers
                </div>
                <img alt='Arrow Icon' src={ArrowSvg} style={{ paddingRight: 5, paddingLeft: 5 }} />
                <div style={{
                    color: '#474747',
                    fontWeight: 100,
                }}> Order Details </div>
            </div>
        </div>
    );
}
export default DetailMenu;
