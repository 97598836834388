import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import ConditionItem from "./ConditionItem"

const SortableItem = (props: any) => {
    const { item, createConditionTemplate, isFinalItem } = props;
    const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id })

    const styles = {
        transform: CSS.Translate.toString(transform),
        transition: transition || undefined,
    }

    return (
        <ConditionItem
            item={item}
            createConditionTemplate={createConditionTemplate}
            isFinalItem={isFinalItem}
            ref={setNodeRef}
            style={styles}
            isOpacityEnabled={isDragging}
            {...props}
            {...attributes}
            {...listeners}
        />
    )
}

export default SortableItem
