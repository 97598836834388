import React from 'react';
const Card = (props: any) => {
    const { account, primaryPayoutAccountId, onSetPrimaryAC } = props;
    return (
        // onClick={props.onSelect}
        account && <div key={account.id} className="card" >
            <div className="row divider" style={{ justifyContent: "space-between" }}>
                <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>
                    <div className="bank">{account.ifscCode.substring(0, 4)}</div>
                    {primaryPayoutAccountId === account.id && <div className="primary">Primary</div>}
                </div>
                {primaryPayoutAccountId !== account.id && <div style={{ display: "flex" }}>
                    <div className="set-primary" onClick={(e) => { e.stopPropagation(); onSetPrimaryAC(); }}>Set Primary</div>
                </div>}
            </div>
            <div className="row" style={{ justifyContent: "space-between" }}>
                <div className="col">
                    <div className="label">Account holder name</div>
                    <div className="value">{account.accountHolderName}</div>
                </div>
                <div className="col">
                    <div className="label">Account Number</div>
                    <div className="value">{account.accountNo}</div>
                </div>
            </div>
            <div className="row">
                <div className="label" style={{ marginRight: 10 }}>IFSC Code</div>
                <div className="value" >{account.ifscCode}</div>
            </div>
        </div>
    );
}

export default Card;