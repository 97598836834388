import { b64DecodeUnicode, b64Encode } from "../../../helpers/util";

export const loadSiteSettings = (resp: any, contentSpecific: string = "all", context) => {
    if (resp) {
        if (resp.settings) {
            let mobile = context.mobThemeProps;
            let web = context.dskThemeProps;
            let themeId = "";
            try {
                if (resp.settings.theme) {
                    if (resp.settings.theme.web) {
                        web = typeof resp.settings.theme.web === "string" ? JSON.parse(b64DecodeUnicode(resp.settings.theme.web)) : resp.settings.theme.web;
                    }
                    if (resp.settings.theme.mobile) {
                        mobile = typeof resp.settings.theme.mobile === "string" ? JSON.parse(b64DecodeUnicode(resp.settings.theme.mobile)) : resp.settings.theme.mobile;
                    }
                    if (resp.settings.theme.id) {
                        themeId = resp.settings.theme.id;
                    }
                }
                let parseThemeProperties: any = {
                    theme: {
                        web,
                        mobile,
                        id: themeId
                    },
                    codeInjection: resp.settings && resp.settings.codeInjection ? decodeCodeInjection(resp.settings.codeInjection) : { header: null, footer: null },
                    aboutUs: (resp.settings && resp.settings.aboutUs) || "",
                    social: (resp.settings && resp.settings.social) || {
                        facebook: "",
                        instagram: "",
                        twitter: ""
                    },
                    paymentModes: resp.settings.paymentModes || [],
                    autoConfirmOrders: resp.settings.autoConfirmOrders || {
                        codOrders: false,
                        onlineOrders: false
                    },
                    pricingInclusiveOfTax: resp.settings.pricingInclusiveOfTax || false,
                    deliveryDisabled: resp.settings.deliveryDisabled || false,
                    storePickupDisabled: resp.settings.storePickupDisabled || false
                };
                if (resp.settings.minOrderValue) {
                    parseThemeProperties.minOrderValue = resp.settings.minOrderValue;
                }
                if (contentSpecific === "all") {
                    return { data: parseThemeProperties };
                } else if (contentSpecific === "web") {
                    return { data: parseThemeProperties, [contentSpecific]: parseThemeProperties.theme.web };
                } else if (contentSpecific === "mobile") {
                    return { data: parseThemeProperties, [contentSpecific]: parseThemeProperties.theme.mobile };
                } else if (contentSpecific === "aboutUs") {
                    return { data: parseThemeProperties, [contentSpecific]: parseThemeProperties.aboutUs };
                } else {
                    // if (contentSpecific === "pricingInclusiveOfTax") {
                    //     return { data: parseThemeProperties, ['pricingInclusiveOfTax']: parseThemeProperties?.pricingInclusiveOfTax || false };
                    // } else if (contentSpecific === "social") {
                    //     return { data: parseThemeProperties, [contentSpecific]: parseThemeProperties.social };
                    // } else if (contentSpecific === "codeInjection") {
                    //     return { data: parseThemeProperties, [contentSpecific]: parseThemeProperties.codeInjection };
                    // } else if (contentSpecific === "paymentModes") {
                    //     return { data: parseThemeProperties, [contentSpecific]: parseThemeProperties.paymentModes };
                    // } else if (contentSpecific === "minOrderValue") {
                    //     return { data: parseThemeProperties, [contentSpecific]: parseThemeProperties.minOrderValue || 0 };
                    // } else if (contentSpecific === "autoConfirmOrders") {
                    return { data: parseThemeProperties, [contentSpecific]: parseThemeProperties[contentSpecific] };
                }
            } catch (e) {
                console.error(e);
                return null;
            }
        }
    }
    return null
}
const decodeCodeInjection = (code) => {
    return {
        header: typeof code.header === "string" ? b64DecodeUnicode(code.header) : code.header,
        footer: typeof code.footer === "string" ? b64DecodeUnicode(code.footer) : code.footer
    }
}
const encodeCodeInjection = (code) => {
    return {
        header: b64Encode(code.header),
        footer: b64Encode(code.footer)
    }
}
export const onCreatePayload = (data, contentSpecific: string = "all", payload: any = null) => {
    if (data && data.theme) {
        try {
            // let updatePayload: any = {
            //     theme: {
            //         web: null,
            //         mobile: null,
            //     },
            //     codeInjection: null,
            //     aboutUs: null,
            //     social: null,
            //     paymentModes: null,
            //     pricingInclusiveOfTax: false,
            //     autoConfirmOrders: {
            //         codOrders: false,
            //         onlineOrders: false
            //     }
            // }
            let updatePayload: any = {
                // theme: {
                //     id: data?.theme?.id,
                //     web: typeof data.theme.web === "string" ? data.theme.web : b64Encode(JSON.stringify(data.theme.web)),
                //     mobile: typeof data.theme.mobile === "string" ? data.theme.mobile : b64Encode(JSON.stringify(data.theme.mobile)),
                // },
                codeInjection: data.codeInjection ? encodeCodeInjection(data.codeInjection) : null,
                // aboutUs: data?.aboutUs || "",
                socialLinks: data?.social || "",
                paymentModes: data?.paymentModes,
                deliveryDisabled: data?.deliveryDisabled,
                storePickupDisabled: data?.storePickupDisabled
                // pricingInclusiveOfTax: data?.pricingInclusiveOfTax || false,
                // autoConfirmOrders: data?.autoConfirmOrders || {
                //     codOrders: false,
                //     onlineOrders: false
                // }
            }
            if (data.minOrderValue) {
                updatePayload.minOrderValue = data.minOrderValue;
            }

            // if (contentSpecific === "all") {
            //     updatePayload = {
            //         theme: {
            //             web: typeof data.theme.web === "string" ? data.theme.web : b64Encode(JSON.stringify(data.theme.web)),
            //             mobile: typeof data.theme.mobile === "string" ? data.theme.mobile : b64Encode(JSON.stringify(data.theme.mobile)),
            //         },
            //         codeInjection: encodeCodeInjection(data.codeInjection),
            //         aboutUs: data.aboutUs,
            //         social: data.social,
            //         paymentModes: data.paymentModes,
            //         pricingInclusiveOfTax: data.pricingInclusiveOfTax || false,
            //         autoConfirmOrders: data.autoConfirmOrders
            //     }
            //     if (data.minOrderValue) {
            //         updatePayload.minOrderValue = data.minOrderValue;
            //     }
            // } else 
            if (contentSpecific === "web" && payload) {
                updatePayload.theme.web = payload ? b64Encode(payload) : "";
            } else if (contentSpecific === "mobile" && payload) {
                updatePayload.theme.mobile = payload ? b64Encode(payload) : "";
            } else if (contentSpecific === "aboutUs" && payload) {
                updatePayload.aboutUs = payload;
            } else if (contentSpecific === "tax" && payload) {
                updatePayload.pricingInclusiveOfTax = payload;
            } else if (contentSpecific === "social" && payload) {
                updatePayload.social = payload;
            } else if (contentSpecific === "codeInjection" && payload) {
                updatePayload.codeInjection = encodeCodeInjection(payload);
            } else if (contentSpecific === "paymentModes" && payload) {
                updatePayload.paymentModes = payload;
            } else if (contentSpecific === "autoConfirmOrders" && payload) {
                updatePayload.autoConfirmOrders = payload;
            } else if (contentSpecific === "holidays" && payload) {
                updatePayload.holidays = payload
            }
            return updatePayload;
        } catch (e) {
            console.error(e);
            return null;
        }
    }
    return null;
}