import { Input, Switch } from "antd";
import React, { useEffect, useState } from "react";
import WhatsappSvg from "../../../../../assets/images/settings/whatsapp.svg";
import CopySvg from "../../../../../assets/images/copy.svg";
import { updateChannelSettings, getChannelSettings } from "../../../../../actions/commonAction";
import { useDispatch, useSelector } from "react-redux";
import apisUrl from "../../../../../apiConfig";

const Whatsapp = () => {
    const dispatch = useDispatch();
    const [accessToken, setAccessToken] = useState(null);
    const [webhookToken, setWebhookToken] = useState(null);
    const [enabled, setEnabled] = useState(null);
    const [webhookUrl, setWebhookUrl] = useState(null);
    const [phoneNumberId, setPhoneNumberId] = useState(null);
    const [channelSettings, setChannelSettings] = useState(null);
    const [isChanged, setChanged] = useState(null);
    const { selectedStore } = useSelector((state: any) => state.ui);
    const getWebhookUrl = () => {
        const environment = window.location.hostname.indexOf('app.shopletzy.com') > -1 ? '' : 'testing';
        return `${apisUrl.shopWebhookUrl.replace('{env}', environment)}/${selectedStore.name}/wa/webhook`;
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await getChannelSettings('whatsapp')(dispatch);
            if (data) {
                setChannelSettings(data);
                setAccessToken(data.accessToken);
                setWebhookToken(data.webhookToken);
                setEnabled(data.enabled);
                setPhoneNumberId(data.phoneNumberId);
            }
        }
        setChanged(false);
        fetchData();
        setWebhookUrl(getWebhookUrl());
        //eslint-disable-next-line
    }, []);

    const onSave = async () => {
        let data = {};
        if (enabled) {
            // if (!accessToken || !webhookToken || !phoneNumberId) return;
            data = {
                accessToken,
                webhookToken,
                enabled,
                phoneNumberId
            }
        } else {
            data = {
                enabled
            }
        }
        if (enabled !== null && (channelSettings?.enabled !== enabled || channelSettings?.accessToken !== accessToken || channelSettings?.webhookToken !== webhookToken || channelSettings?.phoneNumberId !== phoneNumberId)) {
            await updateChannelSettings('whatsapp', data)(dispatch);
            setChannelSettings(data);
            setChanged(false);
        }
    }

    useEffect(() => {
        if (enabled !== null && (channelSettings?.enabled !== enabled || channelSettings?.accessToken !== accessToken || channelSettings?.webhookToken !== webhookToken || channelSettings?.phoneNumberId !== phoneNumberId)) {
            setChanged(true);
        } else {
            setChanged(false);
        }
        //eslint-disable-next-line
    }, [accessToken, webhookToken, enabled, phoneNumberId]);

    const onCopyToken = (e: any, token) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(token);
    }

    const onCopyWebhook = (e: any, webhook) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(webhook);
    }

    return <div className="settings">
        <div className="content" style={{ flexDirection: 'row' }}>
            <div className="container" style={{ flexGrow: 1, margin: '0px 24px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* {actionEnable.length > 0 && <button className="sp_btn" style={{ width: 'auto' }} onClick={onSave}>Save</button>} */}
                </div>
                <div style={{ height: window.innerHeight - 80 }}>
                    <div className="division">
                        <div className="header" style={{ display: 'flex', justifyContent: 'space-between', height: 40 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt="Whatsapp" src={WhatsappSvg} style={{ marginRight: 10 }} />Whatsapp
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {isChanged && <button className="sp_btn" style={{ width: 'auto' }} onClick={onSave}>Save</button>}
                            </div>
                        </div>
                        <div className="section" style={{ display: 'flex' }}>
                            <div style={{ width: '36vw' }}>
                                <div className="title">Enable WhatsApp</div>
                                {/* <div style={{ flexGrow: 1, fontSize: 13 }} className="key" >Lorem ipsum dolor sit amet, consectetur</div> */}
                            </div>
                            <div style={{
                                width: '100%'
                            }}>
                                <Switch
                                    className="toggle-opt"
                                    onClick={(val) => {
                                        setEnabled(val)
                                    }}
                                    checked={enabled}
                                />
                            </div>
                        </div>
                        {enabled && <div className="section">
                            <div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{ width: '36vw' }}>
                                        <div className="title">Webhook URL</div>
                                        {/* <div style={{ marginTop: 2 }} className="key"></div> */}
                                    </div>
                                    <div style={{ width: '100%', display: 'flex' }} >
                                        <div style={{ marginRight: 10 }}>{webhookUrl}</div>
                                        <img src={CopySvg} style={{ cursor: 'pointer', marginLeft: 15 }} alt="copy" onClick={(e) => onCopyWebhook(e, webhookUrl)} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{ width: '36vw' }}>
                                        <div className="title">Phone number</div>
                                        <div style={{ marginTop: 2 }} className="key">Provide the legal business phone number.</div>
                                    </div>
                                    <Input
                                        style={{ width: '100%' }}
                                        value={phoneNumberId}
                                        name="whatsApp"
                                        placeholder="Enter phone number"
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            setPhoneNumberId(value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{ width: '36vw' }}>
                                        <div className="title">Webhook Token</div>
                                        <div style={{ marginTop: 2 }} className="key">WhatsApp Webhook Token for API integration and messaging automation</div>
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                        <Input
                                            style={{ width: '100%', flexGrow: 1 }}
                                            value={webhookToken}
                                            name="whatsApp"
                                            readOnly
                                            placeholder="Enter webhook token"
                                            onChange={(e) => {
                                                const { value }: any = e.target;
                                                setWebhookToken(value);
                                            }}
                                            suffix={<img src={CopySvg} alt="copy" onClick={(e) => onCopyToken(e, webhookToken)} />}
                                        />
                                        <button style={{ width: 180, marginLeft: 20 }} className="sp_btn" onClick={() => {
                                            setWebhookToken(crypto.randomUUID().replaceAll('-', ''));
                                        }}>Generate</button>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{ width: '36vw' }}>
                                        <div className="title">WhatsApp Access Token</div>
                                        <div style={{ marginTop: 2 }} className="key">WhatsApp Access Token for API integration and messaging automation</div>
                                    </div>
                                    <Input
                                        style={{ width: '100%' }}
                                        value={accessToken}
                                        name="whatsApp"
                                        placeholder="Enter whatsapp access token"
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            setAccessToken(value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div
                className="container"
                style={{
                    padding: '12px 18px',
                    height: '100%',
                    width: '26%',
                    background: '#FFFFFF',
                    boxShadow: '0px 3px 16px #DBE2EA6C',
                    flexShrink: 0
                }}>
                <div className="header" style={{ marginBottom: 12 }}>Whatsapp</div>
                <div className="title" style={{ marginBottom: 12 }}>Lorem ipsum dolor sit amet</div>
                <div className="value" style={{ marginBottom: 12, textAlign: 'justify' }}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    <br />
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                </div>
            </div>
        </div>
    </div>
}

export default Whatsapp;