import React from 'react';
import { Outlet } from 'react-router-dom';
import "../../../assets/sass/dsk/billing.sass"

const Billing = (props: any) => {
    return (
        <div className="billing">
            <Outlet />
        </div>
    );
}

export default Billing;