import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import "../../../../../assets/sass/dsk/settings.sass";
import { useNav } from "../../../../../helpers/customHook";
// import AmplitudeSvg from "../../../../../assets/images/settings/amplitude.svg";
import MixpanelPng from "../../../../../assets/images/settings/mixpanel.png";
import HotjarSvg from "../../../../../assets/images/settings/hotjar.svg";
import MicrosoftClarityPng from "../../../../../assets/images/settings/microsoftClarity.png";
import GoogleAnalyticsPng from "../../../../../assets/images/settings/gAnalytics.png";
import FacebookPixelSvg from "../../../../../assets/images/settings/facebook_pixel.svg";

const Menus = [{
    key: 'google',
    label: 'Google Analytics',
    icon: GoogleAnalyticsPng
}, {
    key: 'microsoftClarity',
    label: 'Microsoft Clarity',
    icon: MicrosoftClarityPng
}, {
    key: 'hotjar',
    label: 'Hotjar',
    icon: HotjarSvg
}, {
    key: 'mixpanel',
    label: 'Mixpanel',
    icon: MixpanelPng
    // }, {
    //     key: 'amplitude',
    //     label: 'Amplitude',
    //     icon: AmplitudeSvg
}, {
    key: 'facebookpixel',
    label: 'Facebook Pixel',
    icon: FacebookPixelSvg
}
];

const Analytics = (props: any) => {
    const location = useLocation();
    const navigateTo = useNav();
    const [selectedMenu, setSelectedMenu] = useState(Menus[0].key);
    useEffect(() => {
        const pathNames = location.pathname.split("/");
        const menuMatched: any = Menus.filter((m) => m.key === pathNames[pathNames.length - 1]);
        if (menuMatched.length > 0) {
            setSelectedMenu(menuMatched[0].key)
        } else {
            setSelectedMenu(Menus[0].key);
            navigateTo(Menus[0].key)
        }
        //eslint-disable-next-line
    }, [])
    return <div className="settings">
        <div className="menus">
            {Menus.map((m) => {
                return <Link to={`${m.key}`} style={{ height: 48 }} onClick={() => setSelectedMenu(m.key)} className={selectedMenu === m.key ? "item selected" : 'item'} key={m.key} >
                    <img src={m.icon} alt={m.key} style={{ marginRight: 10 }} />
                    {m.label}
                </Link>
            })}
        </div>
        <div className="content" style={{ height: window.innerHeight - 60 }}>
            <Outlet />
        </div>
    </div>
};

export default Analytics;