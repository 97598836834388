import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCollections, deleteCollection } from '../../../actions/collectionActions';
import { Row, Col, Input, Select } from 'antd';
import '../../../assets/sass/dsk/collection.sass';
import SearchIcn from "../../../assets/images/search.svg"
import NoImageAvailableIcn from "../../../assets/images/No-image-available.png"
import deleteIcn from '../../../assets/images/delete.svg';
// import MoveTopIcn from '../../../assets/images/sort_up.svg';
import editIcn from '../../../assets/images/settings/edit.svg';
// import Save from './Save';
import config from '../../../apiConfig';
import PopupConfirm from '../common/PopupConfirm';
import Loading from '../common/Loading';
import Save from './Save';

const { Option } = Select;

const ListedCollection = (props: any) => {
    const WindowHeight = window.innerHeight;
    const dispatch = useDispatch();
    const [filterCollections, setFilterCollections] = useState([]);
    const [collections, setCollections] = useState([]);
    const [titleSearch, setTitleSearch] = useState("");
    const [typeSearch, setTypeSearch] = useState("all");
    const [isLoading, setIsLoading] = useState(false);
    // const [categorySearch, setCollectionSearch] = useState("");
    const [popupConfirm, setPopupConfirm] = useState(null);
    // const [action, setAction] = useState(null);
    const [isSaveCollection, setIsSaveCollection] = useState(null);
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [collection, setCollection] = useState(null);

    useEffect(() => {
        loadCollections();
        //eslint-disable-next-line
    }, [selectedStore]);

    useEffect(() => {
        loadCollections();
        //eslint-disable-next-line
    }, []);

    const onAction = (data, action) => {
        if (action === "new") {
            setCollection({
                id: null,
                category: "",
                description: "",
                title: ""
            })
        }
        if (action === "edit") {
            setCollection(data)
        }
        setIsSaveCollection(true);
    }

    const loadCollections = async () => {
        let resp: any = await getCollections()(dispatch);
        if (resp && resp.collections) {
            setCollections(resp.collections);
        }
    }

    useEffect(() => {
        onFilterCollections();
        //eslint-disable-next-line
    }, [titleSearch, typeSearch, collections])

    const onFilterCollections = () => {
        let filterList = [];
        let isTitle = false;
        let isType = false;
        for (let c of collections) {
            isTitle = false;
            isType = false;
            if (titleSearch) {
                if (c.title && c.title.toLowerCase().indexOf(titleSearch.toLowerCase()) > -1) {
                    isTitle = true
                }
                if (c.description && c.description.toLowerCase().indexOf(titleSearch.toLowerCase()) > -1) {
                    isTitle = true
                }
            } else {
                isTitle = true;
            }
            if (typeSearch && typeSearch !== "all") {
                if (c.type && c.type.toLowerCase().indexOf(typeSearch.toLowerCase()) > -1) {
                    isType = true
                }
            } else {
                isType = true
            }
            if (isTitle && isType) {
                filterList.push(c);
            }
        }
        setFilterCollections(filterList)
    }

    const onDeleteCollection = (c) => {
        const title = `Collection remove from outlet`;
        setPopupConfirm({
            title,
            renderContent: () => { return <div><span style={{ color: "#000" }}>Do you like to remove <span style={{ fontWeight: "bold" }}>{c.title}</span> collection from <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>{selectedStore.name}</span> store.</span></div > },
            yes: {
                label: "Remove Product",
                callback: async () => {
                    setIsLoading(true);
                    const resp = await deleteCollection(c.id)(dispatch);
                    if (resp && resp.isDeleted) {
                        loadCollections();
                    }
                    setIsLoading(false);
                    setPopupConfirm(null);
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }

    return (<>
        {collections && collections.length ? <Row className="collection">
            <Col className="list" style={{ height: WindowHeight - 120 }}>
                <div className="action-bar">
                    <div className="title">
                        You've {collections && collections.length} collection listed
                    </div>
                    <div className="actions">
                        <button className="sp_btn" onClick={() => onAction(null, "new")} >+ Add Collection</button>
                        {/* <button className="sp_btn">Export</button> */}
                    </div>
                </div>
                <div className="content">
                    <Row className="header">
                        <Col span={16}>
                            Collection Name
                        </Col>
                        <Col span={4}>
                            Type
                        </Col>
                        <Col style={{ textAlign: "center" }} span={4}>
                            Actions
                        </Col>
                    </Row>
                    <Row className="filters">
                        <Col span={16}>
                            <Input className="filter" name="titleSearch" onChange={(e) => setTitleSearch(e.target.value)} placeholder="Title" suffix={<img alt='Search Icon' src={SearchIcn} />} />
                        </Col>
                        <Col span={4}>
                            <Select className="filter" value={typeSearch} onChange={(val) => setTypeSearch(val)} placeholder="Type" style={{ width: "100%" }}>
                                <Option value="all">All</Option>
                                <Option value="automatic">Automatic</Option>
                                <Option value="manual">Manual</Option>
                            </Select>
                        </Col>
                        <Col span={4}>
                        </Col>
                    </Row>
                    <Row className="contains" style={{ height: WindowHeight - 284 }}>
                        <Col span={24}>
                            {filterCollections && filterCollections.map((record, index) =>
                                <Row className="item" onClick={() => onAction(record, "edit")} key={record.id} style={{ border: collections.length - 1 <= index ? "borderTop: 1px solid #f1f1f1" : "" }}>
                                    <Col span={16}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {(!record.image) && <img alt='Not Available' src={NoImageAvailableIcn} style={{
                                                marginRight: "10px",
                                                width: "50px",
                                                borderRadius: "3px",
                                                objectFit: 'contain'
                                            }} />}
                                            {record.image &&
                                                <img alt='Category Icon' style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    borderRadius: "4px",
                                                    objectFit: 'fill',
                                                    marginRight: "10px"
                                                }}
                                                    src={`${config.baseImageUrl}${record.image}`} />
                                            }
                                            <div>
                                                <div>{record.title}</div>
                                                <div style={{ color: "#707070" }}>{record.description}</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={4}>
                                        <div style={{ textTransform: 'capitalize' }}>{record.type}</div>
                                    </Col>
                                    <Col span={4}>
                                        <div style={{ fontSize: "14px", display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ width: 100, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                <img alt='Delete Icon' src={deleteIcn} style={{ cursor: "pointer", height: 20 }} onClick={(e) => { e.stopPropagation(); onDeleteCollection(record) }} />
                                                <img alt='Edit Icon' src={editIcn} style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); onAction(record, "edit") }} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row > : <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: WindowHeight - 200, flexDirection: 'column' }}>
            <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Collection</div>
            <div style={{ fontSize: 16, color: '#C1C1C1', marginBottom: 47 }}>This wizard will walk you through the steps to create your first Collection.</div>
            <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no Collections yet...</div>
            <div style={{ display: 'flex' }}>
                <button className="sp_btn" onClick={() => onAction(null, "new")} >+ Add Collection</button>
            </div>
        </div>}
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        {isLoading && <Loading background="#00000070"></Loading>}
        <Save onClose={(data) => { setIsSaveCollection(false); setCollection(data); loadCollections(); }} collection={collection} isVisible={isSaveCollection} />
    </>)
}

export default ListedCollection;
