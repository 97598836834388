import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getIndustryTypes, sendOTP, verifyOTP, getDomainAvailable, createNewStore, getStoreAvailable } from "../../../actions/setupStoreActions";
import { setSetupStoreDetails } from "../../../actions/uiAction";
import { clearDomainAvailable, clearDomainUnavailable, signout } from "../../../actions/commonAction";
// import Locale from "../common/Locale";
import '../../../assets/sass/dsk/setupstore.sass';
// import { Select } from 'antd';
// import { LikeFilled, DislikeFilled } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
// import InputValidation from '../common/InputValidation';
// import { isNotNullOrUndefinedString, isBoolean } from '../../../helpers/util';
import Loading from '../common/Loading';
// import PopupConfirm from '../common/PopupConfirm';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

// const { Option } = Select;
const SetUpStore = (props: any) => {
    // const navigateTo = useNav();
    // const { industryTypes: industryTypeList } = useSelector((state: any) => state.setupStore);
    // const [industryTypes, setIndustryTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [popupConfirm, setPopupConfirm] = useState(null);
    // const [tc, setTC] = useState(false);
    // const [storeName, setStoreName] = useState(null);
    // const [industryType, setIndustryType] = useState(null);
    // const [brandName, setBrandName] = useState(null);
    // const [isStoreAvailable, setIsStoreAvailable] = useState(null);
    // const [error, setError] = useState({
    //     industryType: {
    //         message: "",
    //         type: ""
    //     },
    //     brandName: {
    //         message: "",
    //         type: ""
    //     },
    //     storeName: {
    //         message: "",
    //         type: ""
    //     },
    //     tc: {
    //         message: "",
    //         type: ""
    //     }
    // });
    const windowHeight = window.innerHeight;

    useEffect(() => {
        props.getIndustryTypes();
        //eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     if (industryTypeList) {
    //         setIndustryTypes(industryTypeList);
    //     }
    // }, [industryTypeList]);

    // const onSubmitHandler = async () => {
    //     setIsLoading(true);
    //     let errorInfo = {
    //         industryType: {
    //             message: "",
    //             type: ""
    //         },
    //         brandName: {
    //             message: "",
    //             type: ""
    //         },
    //         storeName: {
    //             message: "",
    //             type: ""
    //         },
    //         tc: {
    //             message: "",
    //             type: ""
    //         }
    //     },
    //         isError = false;
    //     setIsStoreAvailable(null);
    //     setError(errorInfo);
    //     if (!isNotNullOrUndefinedString(storeName)) {
    //         errorInfo.storeName.type = "error";
    //         errorInfo.storeName.message = "Fill the domain name";
    //         isError = true;
    //     }
    //     if (!isNotNullOrUndefinedString(brandName)) {
    //         errorInfo.brandName.message = "Fill the brand name";
    //         errorInfo.brandName.type = "error";
    //         isError = true;
    //     }

    //     if (!isNotNullOrUndefinedString(industryType)) {
    //         errorInfo.industryType.message = "Select the industry type";
    //         errorInfo.industryType.type = "error";
    //         isError = true;
    //     }
    //     if (!isError) {
    //         const payload = {
    //             industryType,
    //             storeName,
    //             brandName
    //         };
    //         const resp = await props.createNewStore(payload);
    //         if (resp) {
    //             setIsLoading(false);
    //             let popupConfirmDetail = {
    //                 title: "Store Created",
    //                 renderContent: () => { return <div style={{ color: "#000", lineHeight: 2 }} >Your store <span style={{ fontWeight: "bold" }}>{storeName}</span> has been created Successfully</div> },
    //                 okay: {
    //                     label: "Okay",
    //                     callback: () => {
    //                         setPopupConfirm(null)
    //                         navigateTo("orders");
    //                     }
    //                 }
    //             }
    //             setPopupConfirm(popupConfirmDetail)
    //         }
    //     } else {
    //         setIsLoading(false);
    //         setError(errorInfo);
    //     }
    // }

    // const getStoreAvailable = async (sName) => {
    //     const resp = await props.getStoreAvailable(sName);
    //     let errorInfo = {
    //         storeName: {
    //             message: "",
    //             type: ""
    //         }
    //     };
    //     setError({ ...error, ...errorInfo });
    //     if (resp.error) {
    //         setIsStoreAvailable(false);
    //     } else if (resp) {
    //         setIsStoreAvailable(resp.isAvailable);
    //     }
    // }

    // const displayDomainName = () => {
    //     const hostUrl = window.location.hostname.indexOf('localhost') > -1 ? 'app.testing.shopletzy.com' : window.location.hostname;
    //     const domainName = hostUrl.replace('app', storeName || '').replace('shopletzy', 'myshopletzy');
    //     return domainName;
    // }

    // const { t } = props
    //    
    return (
        <div className="setup-store" style={{ height: (windowHeight) + "px" }}>
            <Header></Header>
            <div className="content" style={{ marginTop: 100 }}>
                <Outlet context={[setIsLoading]}></Outlet>
            </div>
            <Footer></Footer>
            {isLoading && <Loading background="#00000070"></Loading>}
            {/* {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>} */}
        </div>
    );
}

function mapStateToProps(state: any) {
    // console.log("SignIn mapStateToProps: ", state);
    return {
        setupStore: state.setupStore
    }
}

export default connect(mapStateToProps, {
    signout,
    getIndustryTypes,
    createNewStore,
    sendOTP,
    verifyOTP,
    setSetupStoreDetails,
    getStoreAvailable,
    getDomainAvailable,
    clearDomainAvailable,
    clearDomainUnavailable
})(withTranslation()(SetUpStore));
