import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import types from "../../../types/actionTypes";
import PopupConfirm from "./PopupConfirm";
import { signout } from "../../../actions/commonAction";
import { useNav } from "../../../helpers/customHook";

const Error = ({ errorInfo }) => {
    const dispatch: any = useDispatch();
    const [popupConfirm, setPopupConfirm] = useState(null);
    const navigateTo = useNav();

    useEffect(() => {
        if (errorInfo) {
            if (errorInfo.code === "SC10020") {
                clearError();
            } else {
                setPopupConfirm({
                    title: () => { return errorInfo && errorInfo.title },
                    type: "error",
                    renderContent: () => { return errorInfo && errorInfo.message },
                    okay: {
                        label: "Okay",
                        callback: clearError
                    }
                })
            }
        }
        // eslint-disable-next-line
    }, [errorInfo]);

    const clearError = () => {
        setPopupConfirm(null);
        dispatch({
            type: types.ERROR,
            data: null
        })
        if (errorInfo && errorInfo.code === "SC10020") {
            navigateTo("/")
            dispatch(signout());
        }
    }

    return (
        <div>
            {errorInfo &&
                <PopupConfirm config={popupConfirm}></PopupConfirm>
            }
        </div>
    );
}

export default Error;
