import * as React from "react";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Categories, Menus } from "./Constants";
import { selectedSettingsMenu, selectedMainMenu as onSelectedMainMenu, selectedTabMenu as onSelectedTabMenu } from "../../../actions/uiAction";
import { useEffect, useState } from "react";
import { Input } from "antd";
import SearchSvg from "../../../assets/images/settings/search.svg";
import { useNav } from "../../../helpers/customHook";
import { useLocation } from "react-router-dom";

const Settings = (props: any) => {
    const location = useLocation();
    const [, store] = location.pathname.split("/");
    const { selectedSettingsMenu } = useSelector((state: any) => state.ui);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [searchMenu, setSearchMenu] = useState(null);
    const navigateTo = useNav();

    const onSelectedMenu = (selectedCategory, selectMenu) => {
        // setSelectedMenu(selectMenu);
        props.selectedSettingsMenu({ selectedCategory, selectedMenu: selectMenu });
    }
    useEffect(() => {
        props.onSelectedMainMenu('settings');
        props.onSelectedTabMenu(null);
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (selectedSettingsMenu) {
            if (selectedSettingsMenu.selectedMenu) {
                setSelectedMenu(selectedSettingsMenu.selectedMenu)
            }
        }
    }, [selectedSettingsMenu]);
    return (<div style={{
        background: "#FAFAFA",
        margin: 0,
        padding: 0,
        height: window.innerHeight - 74,
        display: "flex"
    }}>
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: 257,
            flexShrink: 0,
            height: window.innerHeight - 74,
            overflowY: 'auto'
        }}>
            {Categories.map((c) => {
                return <a href={`#${c.key}`} key={c.key + '_c'}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            cursor: "pointer",
                            margin: '25px 25px 0px 25px'
                        }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            cursor: "pointer"
                        }}>
                            <img alt="Icon" style={{ marginRight: 5, marginTop: 4 }} height={11} width={13} src={c.icon} />
                            <div>
                                <div style={{ fontWeight: 'bold', color: "#1C3A66", fontSize: 14 }}>{c.label}</div>
                                <div style={{ color: '#758295', fontSize: 12, marginTop: 7, width: 192, whiteSpace: 'break-spaces' }}>{c.desc}</div>
                            </div>
                        </div>
                    </div>
                </a>
            })}
        </div>
        <div style={{
            display: "flex",
            flexDirection: "column",
            boxShadow: '0px 1px 26px #DBE2EABA',
            flexGrow: 1
        }}>
            <div style={{
                margin: '25px 25px 33px 25px'
            }}>
                <Input type="text"
                    onChange={(e) => { setSearchMenu(e.target.value) }}
                    prefix={<img alt="Search Icon" src={SearchSvg} />}
                    placeholder="Search"
                    style={{
                        height: 44,
                        border: '1px solid #CBCBCB',
                        borderRadius: 4
                    }} />
            </div>
            <div style={{ overflowY: 'auto', height: window.innerHeight - 140, scrollBehavior: 'smooth', }}>
                {Object.keys(Menus).map((key) => {
                    const Items = searchMenu ? Menus[key].filter((item) => {
                        return (item.label.toLowerCase().includes(searchMenu.toLowerCase()) === true) ||
                            (item.tags && item.tags.filter((tf) => tf.toLowerCase().includes(searchMenu.toLowerCase()) === true).length > 0)
                    }) : Menus[key];
                    return Items.length > 0 && <div id={key} key={key} >
                        <div style={{ color: '#121212', fontSize: 20, fontWeight: 'bold', marginBottom: 18, textTransform: 'capitalize', margin: '0px 25px 25px' }}>{key}</div>
                        <div style={{
                            margin: '0px 12px 25px',
                            display: "flex",
                            flexWrap: "wrap"
                        }}>
                            {Items.map((m) => {
                                return !m.hide && <div
                                    key={key + "_" + m.key}
                                    onClick={() => { onSelectedMenu(key, m); navigateTo(m.root ? `/${store}/${m.key}` : `${key}/${m.key}`) }}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        padding: '12px 12px 6px',
                                        marginRight: 24,
                                        marginBottom: 24,
                                        borderRadius: selectedMenu && selectedMenu.key === m.key ? 4 : 0,
                                        background: selectedMenu && selectedMenu.key === m.key ? '#CBCBCB33' : '#fafafa'
                                    }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        cursor: "pointer"
                                    }}>
                                        <img alt="Icon" style={{ marginRight: 9 }} height={18} width={18} src={m.icon} />
                                        <div>
                                            <div style={{ fontWeight: 'bold', color: "#1C3A66", fontSize: 14 }}>{m.label}</div>
                                            <div style={{ color: '#758295', fontSize: 12, marginTop: 7, height: 40, width: 208 }}>{m.desc}</div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                })}
            </div>
        </div>
    </div >)
}
const mapStateToProps = (state: any) => {
    return {
        common: state.common
    };
};
export default connect(mapStateToProps, { selectedSettingsMenu, onSelectedMainMenu, onSelectedTabMenu })(withTranslation()(Settings));
