import React from 'react'
import { Outlet } from 'react-router-dom';
import "../../../../../assets/sass/dsk/blog.sass";

const PageRedirect = () => {
    return <div>
        {<Outlet />}
    </div>
}

export default PageRedirect;