import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { oauthLogin, signup } from "../../../actions/authActions";
import { selectedStore } from "../../../actions/uiAction";
import { getStores } from "../../../actions/setupStoreActions";
// import Locale from "../common/Locale";
import '../../../assets/sass/dsk/signin.sass';
import GooglePng from "../../../assets/images/google_icon.png";
import LetzyIcn from "../../../assets/images/ShopLetzy_logo_bl_s1.svg";
import { withTranslation } from 'react-i18next';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import PopupConfirm from '../common/PopupConfirm';
import { CONSTANTS } from '../../../constants';
import Header from '../setupstore/Header';
import Footer from '../setupstore/Footer';
import { useDispatch } from 'react-redux';
import { useNav } from '../../../helpers/customHook';

const isShowFaceLogin = false;
const SignIn = (props: any) => {
    const dispatch: any = useDispatch();
    const SubscriptionAllowed = ["active", "trial"];
    const navigateTo = useNav();
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    const [popupConfirm, setPopupConfirm] = useState(null);

    const signin = async (response) => {
        const resp = await oauthLogin(response)(dispatch);
        if (resp && resp.isMobileVerified && resp.mobileNo) {
            const stores = await getStores()(dispatch);
            if (stores && stores.length > 0) {
                if (stores.length > 1) {
                    navigateTo("/selectstore")
                } else {
                    await dispatch(selectedStore(stores[0]));
                    if (SubscriptionAllowed.includes(stores[0]?.subscriptionStatus)) {
                        navigateTo(`/${stores[0].name}/orders`)
                    } else {
                        navigateTo(`/${stores[0].name}/cancelled`)
                    }
                }
            } else {
                navigateTo("/setupstore/usertype")
            }

        } else if (resp && (resp.status === "unregistered" || resp.isMobileVerified === false || !resp.mobileNo)) {
            let popupConfirmDetail = {
                title: "Hey there!",
                renderContent: () => {
                    return <div>
                        <div>You do not have any account created with us yet. Do you want to create new account?</div>
                        <div style={{ marginTop: 14 }}>By creating an account with Shopletzy, you are agreeing to our <div onClick={() => window.open(CONSTANTS.Urls.tc)} style={{ color: '#0076FF', cursor: 'pointer' }}>terms and conditions</div></div>
                    </div>
                },
                yes: {
                    label: "Yes",
                    callback: async () => {
                        const resp: any = await dispatch(signup(response));
                        if (resp && resp.token) {
                            navigateTo("/setupstore/sendOTP");
                        }
                    }
                },
                no: {
                    label: "No",
                    callback: () => { setPopupConfirm(null) }
                }
            }
            setPopupConfirm(popupConfirmDetail);
        } else if (resp && (resp.status === "support")) {
            let popupConfirmDetail = {
                title: "Hey there!",
                renderContent: () => {
                    return <div>
                        <div>Unexpected error occurred. For assistance, please get in touch with support.</div>
                    </div>
                },
                okay: {
                    label: "Okay",
                    callback: () => { setPopupConfirm(null) }
                }
            }
            setPopupConfirm(popupConfirmDetail);
        } else {
            let popupConfirmDetail = {
                title: "Hey there!",
                renderContent: () => {
                    return <div>
                        <div>Something went worng</div>
                        <div style={{ marginTop: 14 }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor<div onClick={() => window.open(CONSTANTS.Urls.tc)} style={{ color: '#0076FF', cursor: 'pointer' }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</div></div>
                    </div>
                },
                okay: {
                    label: "Okay",
                    callback: () => { setPopupConfirm(null) }
                }
            }
            setPopupConfirm(popupConfirmDetail);
        }
    }

    const responseGoogle = async (response) => {
        if (response.tokenId) {
            response.authProvider = "google";
            await signin(response);
        }
    }
    const responseFacebook = async (response) => {
        if (response.accessToken) {
            response.tokenId = response.accessToken;
            response.authProvider = "facebook";
            await signin(response);
        }
    }
    // const { t } = props;
    return (
        <div className="signin" style={{ height: dimensions.height }}>
            <Header disableLogin={true} />
            <div className="content">
                <div className="motto">
                    Democratizing eCommerce
                </div>
                <div className="desc">
                    Get your own branded online shopping website It costs you nothing to get started
                </div>
                <div className="oauth">
                    <GoogleLogin
                        clientId="80195849052-g354619gb9nithq0nqhaapl94g51u9hv.apps.googleusercontent.com"
                        render={renderProps => (
                            <button onClick={renderProps.onClick} className="google" disabled={renderProps.disabled}>
                                <img alt='Google Login' src={GooglePng}></img>
                                <label>Login with Google</label>
                            </button>
                        )}
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    />
                    {isShowFaceLogin && <FacebookLogin
                        appId="2105590542907033"
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={responseFacebook}
                        cssClass="fb"
                        icon="fa-facebook"
                        render={renderProps => (
                            <button onClick={renderProps.onClick}>
                                <span> Login with Facebook</span>
                            </button>
                        )}
                    />}
                    <button onClick={() => navigateTo("/loginotp")} >
                        <img src={LetzyIcn} alt={"Shopletzy"} style={{ width: 40, marginRight: 2 }} />
                        <div>Login with Email</div>
                    </button>
                </div>
            </div>
            <Footer />
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        </div >
    );
}

// function mapStateToProps(state: any) {
//     // console.log("SignIn mapStateToProps: ", state);
//     return {
//         auth: state.auth
//     }
// }

export default connect(null, { oauthLogin, signup, getStores, selectedStore })(withTranslation()(SignIn));
