import type from "../types/actionTypes"
const initialState = {
    otpToken: null,
    isLoggedIn: false,
    isLoading: false,
    info: null,
    error: null,
    temporaryPath: "",
    forgetPasswordDetails: {},
    resetPasswordtoken: "",
    oauth: null
};

export default function authReducers(state = initialState, action) {
    // console.log(action)
    switch (action.type) {
        case type.GET_INDUSTRY_TYPES_SUCCESS:
            return {
                ...state,
                industryTypes: action.data.options
            }
        case type.OAUTH_LOGIN:
            return {
                ...state,
                oauth: action.data
            }
        case type.AUTH_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                isLoading: false,
                info: action.data
            }
        case type.OTP_TOKEN_SUCCESS:
            return {
                ...state,
                otpToken: action.data
            }
        case type.VERIFY_OTP_SUCCESS:
            return {
                ...state,
                info: { ...state.info, ...{ isMobileVerified: action.data } }
            }
        case type.AUTH_ERROR:
            return {
                ...state,
                isLoggedIn: false,
                isLoading: false,
                info: null,
                error: action.data
            }
        case type.GET_MERCHANT_AVAILABLE_ERROR:
            return {
                ...state,
                merchantUnavailable: action.data
            }
        case type.SIGNOUT:
            return {
                ...initialState
            }
        default:
            return state;
    }
}