import React, { useEffect, useState } from "react";
import "../../../../../assets/sass/dsk/deliverySlots.sass";
import { getDeliverySlots, removeDeliverySlots } from "../../../../../actions/deliverySlotAction";
import DeliverySlotSvg from "../../../../../assets/images/time_slots.svg";
import ArrowExchangeSvg from "../../../../../assets/images/arrows-exchange.svg";
import CricleCloseSvg from "../../../../../assets/images/ios-close-circle-outline.svg";
import CheckedCircleSvg from "../.././../../../assets/images/checked-circle.svg";
import DeleteSvg from "../.././../../../assets/images/red-delete.svg";
import TimeSvg from "../../../../../assets/images/time.svg";
// import EditSvg from "../../../../../assets/images/edit.svg";
import { useDispatch } from "react-redux";
import Save from "./save";
import dayjs from "dayjs";
import PopupConfirm from "../../../common/PopupConfirm";
import Loading from "../../../common/Loading";

const DeliveryLabels = {
    city: 'Cities',
    state: 'States',
    maxDistance: 'Max Distance',
    postalCode: 'PostalCodes',
    country: 'Countries'
}
const DeliverySlots = (props: any) => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [deliverySlots, setDeliverySlots] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(false);
    // const [deliveryBeforeType, setDeliveryBeforeType] = useState(null);
    useEffect(() => {
        if (!isVisible) {
            loadDeliverySlots();
        }
        //eslint-disable-next-line
    }, [isVisible]);
    const loadDeliverySlots = async () => {
        const resp: any = await getDeliverySlots()(dispatch);
        if (resp && resp.deliverySlots) {
            setDeliverySlots(resp.deliverySlots)
        }
    }
    // const getOrderRange = (mins) => {
    //     let range = 0;
    //     let type = 'min'
    //     if (mins < 60) {
    //         range = mins
    //     } else {
    //         let hours = mins / 60;
    //         let days = hours / 24;
    //         if (days.toString().indexOf(".") === -1) {
    //             range = days;
    //             type = 'days';
    //         } else if (hours.toString().indexOf(".") === -1) {
    //             range = hours;
    //             type = 'hours';
    //         } else {
    //             range = mins;
    //         }
    //     }
    //     return { range, type };
    // }
    const renderFixedSlot = (ds) => {
        let startDetail = iso8601ToPlain(ds.orderStart);
        let endDetail = iso8601ToPlain(ds.orderEnd);
        return <div>Ordering starts <span className="time-content">{startDetail.value} {startDetail.type} </span>before the slot start time and ends  <span className="time-content">{endDetail.value} {endDetail.type} </span>before the start time.</div>
    }
    const iso8601ToPlain = (value) => {
        let result = value;
        let type = typeof value === "string" ? value.indexOf('M') > -1 ? 'minutes' : value.indexOf('H') > -1 ? 'hours' : 'days' : null;
        if (type === 'minutes') {
            result = value.replace('PT', '').replace('M', '');
        } else if (type === 'hours') {
            result = value.replace('PT', '').replace('H', '');
        } else if (type === 'days') {
            result = value.replace('P', '').replace('D', '');
        } else {
            type = 'minutes';
        }
        return { value: result, type };
    }
    const removeSlot = (ds) => {
        const popupConfirmDetail = {
            title: "Slot Remove",
            renderContent: () => { return <div>Do you want delete <span style={{ fontWeight: 900, font: "14px AvenirMedium", letterSpacing: "0px", color: "#34E5C1" }}>{ds.name}</span></div> },
            yes: {
                label: "Yes",
                callback: async () => {
                    setLoading(true);
                    const resp = await removeDeliverySlots(ds.id)(dispatch);
                    setLoading(false);
                    setPopupConfirm(null);
                    if (resp && resp.isDeleted) {
                        loadDeliverySlots();
                    }
                }
            },
            no: {
                label: "May be next time",
                callback: () => { setPopupConfirm(null) }
            }
        }
        setPopupConfirm(popupConfirmDetail)
    }
    return <div className="delivery-slots">
        {deliverySlots.length > 0 && <div className="action-bar">
            <div className="title">
                View & configure the delivery slots
            </div>
            <div className="actions">
                <button className="sp_btn btn" onClick={() => { setSelectedDeliverySlot(null); setIsVisible(true); }} >+ Add Slot</button>
            </div>
        </div>}
        {deliverySlots.length > 0 && <div className="contents" style={{ height: window.innerHeight - 180, overflowY: "auto" }}>
            {deliverySlots.map((ds) => {
                return <div className="card" key={ds.id} onClick={() => { setSelectedDeliverySlot(ds); setIsVisible(true); }}>
                    <div style={{ height: 290 }}>
                        <div className="title">
                            <div className="name">
                                {ds.name}
                            </div>
                            <div className={ds.slotType === 'fixed' ? "fixed-slot" : "flexible-slot"}>
                                {ds.slotType} Slot
                            </div>
                        </div>
                        <div className="time">
                            {ds.slotType === 'fixed' && <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <div>Order Start</div> <div style={{ marginLeft: 5, marginRight: 5 }}> & </div> <div>End Time</div>
                            </div>}
                            {ds.slotType === 'flexible' && <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <div>Delivery Before:</div> <div style={{ marginLeft: 5, marginRight: 5, fontSize: 14, fontWeight: 'bold', color: '#188FFC', textTransform: 'capitalize' }}>
                                    {iso8601ToPlain(ds.deliveryBefore).type}
                                </div>
                            </div>}
                        </div>
                        <div className="time-value">
                            {ds.slotType === 'fixed' && <div style={{ padding: '0px 10px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <img alt="Time Icon" src={TimeSvg} style={{ marginRight: 10 }} />
                                    <div style={{ marginTop: 1 }}>{dayjs(ds.startTime, "HH:mm").format("hh:mm A")}</div>
                                </div>
                                <div> <img alt="Arrow Exchange Icon" src={ArrowExchangeSvg} /> </div> <div style={{ marginTop: 1 }}>{dayjs(ds.endTime, "HH:mm").format("hh:mm A")}</div>
                            </div>}
                            {ds.slotType === 'flexible' && <div style={{ padding: '0px 10px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <img alt="Time Icon" src={TimeSvg} style={{ marginRight: 10 }} />
                                    <div style={{ marginTop: 1 }}>{iso8601ToPlain(ds.deliveryBefore).value} {iso8601ToPlain(ds.deliveryBefore).type}</div>
                                </div>
                            </div>}
                        </div>
                        {ds.slotType === 'fixed' && <div className="time-desc">
                            {renderFixedSlot(ds)}
                        </div>}
                        {ds.deliveryArea && <><div style={{ display: 'flex', alignItems: "center", justifyContent: 'flex-start', marginTop: 18, marginBottom: 9 }}>
                            <div className="time" style={{ width: 'auto', marginTop: 0, marginRight: 6 }} >Location:  </div>
                            <div className="time-content" >{DeliveryLabels[ds.deliveryArea.type]} </div>
                        </div>
                            <div className="time-value">
                                <div style={{ padding: '0px 10px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {ds.deliveryArea.type === 'maxDistance' && <div>{ds.deliveryArea.maxDistance}</div>}
                                    {ds.deliveryArea.type === 'city' && <div style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>{ds.deliveryArea.cities.join(', ')}</div>}
                                    {ds.deliveryArea.type === 'state' && <div style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>{ds.deliveryArea.states.join(', ')}</div>}
                                    {ds.deliveryArea.type === 'country' && <div style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>{ds.deliveryArea.countries.join(', ')}</div>}
                                    {ds.deliveryArea.type === 'postalCode' && <div style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>{ds.deliveryArea.postalCodes.join(', ')}</div>}
                                </div>
                            </div>
                        </>}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                            <div className="slot-state">Slot State:</div>
                            <div className={ds.isActive ? 'active' : 'not-active'}>
                                {ds.isActive ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}><img alt="Active" style={{ marginRight: 8 }} src={CheckedCircleSvg} /><div>Active</div></div> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}><img alt="Not Active" style={{ marginRight: 8 }} src={CricleCloseSvg} /><div>Not Active</div></div>}
                            </div>
                        </div>
                        <div>
                            <img alt="Delete Icon" style={{ cursor: "pointer" }} src={DeleteSvg} onClick={(e) => { e.stopPropagation(); removeSlot(ds) }} />
                        </div>
                    </div>
                </div>
            })}
        </div>}
        {deliverySlots.length === 0 && <div style={{
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%'
        }}>
            <div style={{
                width: 111,
                borderRadius: 50,
                height: 111,
                marginBottom: 18,
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
                background: 'transparent linear-gradient(180deg, #F1F6F9 0%, #FFFFFF 100%)'
            }}>
                <img alt="Delivery Icon" src={DeliverySlotSvg} />
            </div>
            <div>You Have not Configured Delivery slot</div>
            <div
                onClick={() => { setSelectedDeliverySlot(null); setIsVisible(true) }}
                style={{
                    cursor: 'pointer',
                    width: 127,
                    height: 40,
                    background: '#000000',
                    borderRadius: 4,
                    marginTop: 32,
                    color: '#FFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>Add Slot</div>
        </div>}
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        {isLoading && <Loading background="#00000070"></Loading>}
        <Save selectedDeliverySlot={selectedDeliverySlot} onClose={() => { setIsVisible(false); setSelectedDeliverySlot(null); getDeliverySlots(); }} isVisible={isVisible}></Save>
    </div>
}

export default DeliverySlots;