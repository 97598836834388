import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from '../store';
import { ErrorCodes } from "../errorCodes";
import axiosWrapper from "./axiosWrapper";

export const getOutletSlot = (ouId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.outletSlotUrl.replace('{ouId}', ouId).replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp.data && resp.data.deliverySlots ? resp.data.deliverySlots : [];
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message && error.response.status) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Outlet Slot Failed",
                        message: ErrorCodes[error.response.status],
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getOutletById = (ouId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.updateOutletUrl.replace('{ouId}', ouId).replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message && error.response.status) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Outlet Id Failed",
                        message: ErrorCodes[error.response.status],
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export const getOutlets = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.outletsUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.GET_OUTLETS_SUCCESS,
                data: resp.data
            });
            return resp && resp.data ? resp.data.outlets : [];
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message && error.response.status) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Outlets Failed",
                        message: ErrorCodes[error.response.status],
                        code: error.response.data.code
                    }
                });
            }
        }
    };
};

export function createOutlet(outletPayload) {
    const options = {
        method: 'POST',
        data: outletPayload,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            // let deliveryAreaUrl = null;
            const outletUrl = `${config.baseUrl}${config.outletsUrl.replace('{sId}', sId)}`;
            const respOutlet = await axiosWrapper(outletUrl, options, { dispatch });
            // if (respOutlet && respOutlet.data && respOutlet.data.ouId) {
            // deliveryAreaUrl = `${config.baseUrl}${config.deliveryAreaUrl.replace('{sId}', sId).replace('{ouId}', respOutlet.data.ouId)}`;
            // const deliveryAreaOptions = {
            //     method: 'PUT',
            //     data: deliveryPayload,
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': `Bearer ${store.getState().auth?.info?.token}`
            //     }
            // };
            // await axiosWrapper(deliveryAreaUrl, deliveryAreaOptions);
            // }
            return respOutlet && respOutlet.data ? respOutlet.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Create Outlet Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Create Outlet', error);
            }
        }
    };
    // return async (dispatch) => {
    //     axios(url, options)
    //         .then(function (resp) {
    //             dispatch({
    //                 type: type.SAVE_OUTLET_SUCCESS,
    //                 data: true
    //             });
    //         })
    //         .catch(function (error) {
    //             if (error.response && error.response.data && error.response.data.message) {
    //                 dispatch({
    //                     type: type.ERROR,
    //                     data: {
    //                         title: "Create Outlet Failed",
    //                         message: error.response.data.message
    //                     }
    //                 });
    //             } else {
    //                 console.error('error', 'Authentication', error);
    //             }
    //         });
    // }
}

export function updateOutlet(ouId, outletPayload) {
    const options = {
        method: 'PUT',
        data: outletPayload,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            const outletUrl = `${config.baseUrl}${config.updateOutletUrl.replace('{sId}', sId).replace('{ouId}', ouId)}`;
            let respOutlet = await axiosWrapper(outletUrl, options, { dispatch });
            return respOutlet && respOutlet.data ? respOutlet.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Outlet Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Update Outlet', error);
            }
        }
    };
}

export function updateOutletSlot(ouId, data) {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            const deliveryAreaUrl = `${config.baseUrl}${config.updateOutletSlotUrl.replace('{sId}', sId).replace('{ouId}', ouId)}`;
            let resp = await axiosWrapper(deliveryAreaUrl, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Outlet Delivery Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Update Outlet Delivery', error);
            }
        }
    };
}

export function updateOutletDelivery(ouId, deliveryPayload) {
    const options = {
        method: 'PUT',
        data: deliveryPayload,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            const deliveryAreaUrl = `${config.baseUrl}${config.deliveryAreaUrl.replace('{sId}', sId).replace('{ouId}', ouId)}`;
            let respDeliveryArea = await axiosWrapper(deliveryAreaUrl, options, { dispatch });
            return respDeliveryArea && respDeliveryArea.data ? respDeliveryArea.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Outlet Delivery Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Update Outlet Delivery', error);
            }
        }
    };
}

export function addProductOutlets(pId, productPriceDetails) {
    const options = {
        method: 'PUT',
        data: productPriceDetails,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.addProductToOutletUrl.replace('{sId}', sId).replace('{productId}', pId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Add Product To Outlet Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Add Product To Outlet', error);
            }
        }
    };
}

export function updateProductOutlets(pId, data) {
    const options = {
        method: 'PUT',
        data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.updateProductToOutletUrl.replace('{sId}', sId).replace('{productId}', pId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Products to Outlet Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            } else {
                console.error('error', 'Update Products to Outlet', error);
            }
        }
    };
    // return async (dispatch) => {
    //     axios(url, options)
    //         .then(function (resp) {
    //             dispatch({
    //                 type: type.SAVE_PRODUCTS_SUCCESS,
    //                 data: resp.data
    //             });
    //         })
    //         .catch(function (error) {
    //             if (error.response && error.response.data && error.response.data.message) {
    //                 dispatch({
    //                     type: type.ERROR,
    //                     data: {
    //                         title: "Update Products to Outlet Failed",
    //                         message: error.response.data.message
    //                     }
    //                 });
    //             } else {
    //                 console.error('error', 'Update Product To Outlet', error);
    //             }
    //         });
    // }
}
