import * as React from "react";

function ManagementSvg(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width={22.074}
            height={22.074}
            viewBox="0 0 22.074 22.074"
        >
            <g fill={props.fill} stroke={props.stroke || "#fff"} strokeWidth={2} data-name="Layer 2">
                <path
                    data-name="Path 5914"
                    d="M21.074 6.576v-2.23A3.346 3.346 0 0017.729 1H4.346A3.346 3.346 0 001 4.346v2.23z"
                />
                <path
                    data-name="Path 5915"
                    d="M1 8.807v8.922a3.346 3.346 0 003.346 3.346h5.576V8.807z"
                />
                <path
                    data-name="Path 5916"
                    d="M12.152 8.807v12.268h5.576a3.346 3.346 0 003.346-3.346V8.807z"
                />
            </g>
        </svg>
    )
}

export default ManagementSvg
