import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Divider, Drawer, Input, Radio, Row, Select } from 'antd';
import Loading from '../common/Loading';
// import PopupConfirm from '../common/PopupConfirm';
import '../../../assets/sass/dsk/order.sass';
import FastDeliverySvg from '../../../assets/images/Fast-Delivery.svg';
import { onUpdateOrderStatus } from '../../../actions/uiAction';
import { shipOrder as onShipOrder, getDeliveryExecs } from '../../../actions/orderActions';
import InputValidation from '../common/InputValidation';
const { Option } = Select;

const DeliveryService = (props: any) => {

    const windowHeight = window.innerHeight;
    const [courierList, setCourierList] = useState(['Blue Dart', 'Professional Courier', 'ST Courier']);
    const [newCourierName, setNewCourierName] = useState(null);
    const [courierName, setCourierName] = useState(null);
    const [trackingNo, setTrackingNo] = useState(null);
    const [trackingWebsite, setTrackingWebsite] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [deliveryExecs, setDeliveryExecs] = useState([]);
    const [deliveryType, setDeliveryType] = useState(null);
    const [deliveryExec, setDeliveryExec] = useState(null);
    const [error, setError] = useState({
        deliveryExec: {
            message: "",
            type: ""
        },
        deliveryServiceName: {
            message: "",
            type: ""
        },
        trackingNo: {
            message: "",
            type: ""
        }
    })

    useEffect(() => {
        setSelectedOrder(props.selectedOrder);
    }, [props.selectedOrder]);

    useEffect(() => {
        if (props.isVisible) {
            getDeliveryExecs();
        }
        //eslint-disable-next-line
    }, [props.isVisible]);

    // useEffect(() => {
    //     setDeliveryExecs(props.deliveryExecs);
    // }, [props.deliveryExecs]);

    const getDeliveryExecs = async () => {
        if (props.selectedOrder && props.selectedOrder.ouId) {
            const resp = await props.getDeliveryExecs(props.selectedOrder.ouId);
            if (resp) {
                setDeliveryExecs(resp);
            }
        }
    }

    const onSubmit = async () => {
        let payload = {};
        if (deliveryType === "own_service") {
            if (deliveryExec) {
                setError(prevState => ({
                    ...prevState,
                    deliveryExec: {
                        message: "",
                        type: ""
                    }
                }));
                payload = {
                    "deliveryService": "inhouse",
                    "deliveryExec": deliveryExec
                }
            } else {
                setError(prevState => ({
                    ...prevState,
                    deliveryExec: {
                        message: "Assign the delivery executive",
                        type: "error"
                    }
                }));
            }
        } else if (deliveryType === "external_service") {
            payload = {
                "deliveryService": "external",
                "deliveryServiceName": courierName,
                "trackingNo": trackingNo
            }
            if (trackingWebsite && trackingWebsite.length > 0) {
                payload['trackingWebsite'] = trackingWebsite;
            }
        }
        setIsLoading(true);
        const resp = await props.onShipOrder(selectedOrder.id, payload);
        setIsLoading(false);
        if (resp && resp.order) {
            props.onUpdateOrderStatus(true);
            onClose(resp.order);
        }
    }

    const onClose = (status = null) => {
        setDeliveryType(null);
        setDeliveryExec(null);
        props.onClose(status)
    }

    return (<Drawer
        width={470}
        closable={false}
        onClose={onClose}
        footer={
            <Row>
                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: 38 }}>
                    <button onClick={onClose} className="sp_btn cancel">Cancel</button>
                    <button onClick={onSubmit} className="sp_btn save">Ship</button>
                </Col>
            </Row>
        }
        className="delivery-execs"
        visible={props.isVisible}>
        {isLoading && <Loading background="#00000070"></Loading>}
        <Row className="header">
            <Col span={24}>
                <div style={{ display: 'flex', alignItems: "center" }}><img alt='Fast Delivery' src={FastDeliverySvg} /> <div style={{ marginLeft: 10 }}>Ship Order # {selectedOrder && selectedOrder.orderNo}</div></div>
            </Col>
        </Row>
        <Row className="content" >
            <Col span={24} className="details" style={{ height: windowHeight - 150 }}>
                <Row>
                    <Col span={24} style={{ color: '#188FFC', fontSize: 16, fontWeight: 'bold' }}>
                        Shipping To
                    </Col>
                    <Col span={24} style={{ marginTop: 6 }}>
                        {selectedOrder && selectedOrder.shippingAddress && <div>{selectedOrder.shippingAddress.houseName}, {selectedOrder.shippingAddress.addressLine}, {selectedOrder.shippingAddress.area}</div>}
                        {selectedOrder && selectedOrder.shippingAddress && <div>{selectedOrder.shippingAddress.city} - {selectedOrder.shippingAddress.zipcode} </div>}
                        {selectedOrder && selectedOrder.shippingAddress && <div>{selectedOrder.shippingAddress.state} - {selectedOrder.shippingAddress.country} </div>}
                    </Col>
                </Row>
                <Row style={{ marginTop: 35 }}>
                    <Col span={24} style={{ fontSize: 16, marginBottom: 10, color: '#FC8118', fontWeight: 'bold' }}>
                        Delivery Type
                    </Col>
                    <Col span={24} style={{ marginTop: 6 }}>
                        <Radio.Group style={{ display: 'flex' }} onChange={(event) => setDeliveryType(event.target.value)} value={deliveryType}>
                            <div style={{ display: 'flex', height: 30, alignItems: "center", justifyContent: "center", marginRight: 16 }}>
                                <Radio value="own_service">My Delivery Team</Radio>
                            </div>
                            <div style={{ display: 'flex', height: 30, alignItems: "center", justifyContent: "center" }}>
                                <Radio value="external_service">Courier / External Delivery Service</Radio>
                            </div>
                        </Radio.Group>
                    </Col>
                </Row>
                {deliveryType === "own_service" && <Row style={{ marginTop: 35 }}>
                    <Col span={24} style={{ fontSize: 14 }}>
                        Assign Delivery Executive
                    </Col>
                    <Col span={24} style={{ marginTop: 6 }}>
                        <InputValidation type={error.deliveryExec.type} message={error.deliveryExec.message}>
                            <Select onChange={(value) => setDeliveryExec(value)}  >
                                {deliveryExecs && deliveryExecs.map((de) => {
                                    return <Option key={de.id} value={de.id} style={{ textTransform: 'capitalize' }}>{de.fullName}</Option>
                                })}
                            </Select>
                        </InputValidation>
                    </Col>
                </Row>}
                {deliveryType === "external_service" && <Row style={{ marginTop: 35 }}>
                    <Col span={24} style={{ fontSize: 14 }}>
                        Courier / Delivery Service
                    </Col>
                    <Col span={24} style={{ marginTop: 6 }}>
                        <InputValidation type={error.deliveryServiceName.type} message={error.deliveryServiceName.message}>
                            <Select dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', padding: '10px 16px' }}>
                                        <Input type="text" value={newCourierName} onChange={(e) => setNewCourierName(e.target.value)} />
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                width: 180,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                textTransform: 'uppercase',
                                                background: '#00BDFF',
                                                color: '#FFF',
                                                marginLeft: 10,
                                                marginRight: 10,
                                                borderRadius: 3,
                                                height: 36
                                            }}
                                            onMouseDown={e => e.preventDefault()}
                                            onClick={() => { newCourierName && newCourierName.length > 2 && setCourierList(oldArray => [...oldArray, newCourierName]); setNewCourierName(""); }}
                                        >
                                            Add Courier
                                        </div>
                                    </div>
                                </div>
                            )}
                                onChange={(val) => { setCourierName(val) }}
                            >
                                {courierList.map((c) => {
                                    return <Option key={c} value={c}>{c}</Option>
                                })}
                            </Select>
                        </InputValidation>
                    </Col>
                </Row>}
                {deliveryType === "external_service" && <Row style={{ marginTop: 35 }}>
                    <Col span={24} style={{ fontSize: 14 }}>
                        Tracking Website (optional)
                    </Col>
                    <Col span={24} style={{ marginTop: 6 }}>
                        <Input type="text" placeholder="Delivery Service Tracking Website" onChange={(e) => setTrackingWebsite(e.target.value)} />
                    </Col>
                </Row>}
                {deliveryType === "external_service" && <Row style={{ marginTop: 35 }}>
                    <Col span={24} style={{ fontSize: 14 }}>
                        Tracking No
                    </Col>
                    <Col span={24} style={{ marginTop: 6 }}>
                        <InputValidation type={error.trackingNo.type} message={error.trackingNo.message}>
                            <Input type="text" placeholder="Enter delivery tracking number" onChange={(e) => setTrackingNo(e.target.value)} />
                        </InputValidation>
                    </Col>
                </Row>}
            </Col>
        </Row>
    </Drawer>)
}

function mapStateToProps(state: any) {
    // console.log("Product View mapStateToProps: ", state);
    return {
        ui: state.ui,
        product: state.product
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onShipOrder,
        getDeliveryExecs,
        onUpdateOrderStatus
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryService);
