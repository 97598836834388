import React from "react";
import { useDispatch, connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import type from "../../../types/actionTypes";
import "../../../assets/sass/dsk/userType.sass";
import { useNav } from "../../../helpers/customHook";
// import Header from './Header';
// import Footer from './Footer';

const UserType = (props: any) => {
    const navigateTo = useNav();
    // const windowHeight = window.innerHeight;
    const dispatch = useDispatch();
    const onSubmit = (userTypeLogin) => {
        if (userTypeLogin) {
            dispatch({
                type: type.USER_TYPE,
                data: {
                    userTypeLogin
                }
            });
            if (userTypeLogin === "owner") {
                navigateTo("/setupstore/createstore");
            } else {
                navigateTo("/selectstore");
            }
        }
    }
    return <div className="uset_type">
        <div className="content">
            <div className="motto">
                Democratizing eCommerce
            </div>
            <div className="desc">
                Get your own branded online shopping website It costs you nothing to get started
            </div>
            <div className="oauth">
                <button onClick={() => onSubmit("owner")}>
                    I'm the store owner
                </button>
                <button onClick={() => onSubmit("staff")}>
                    I'm working for a store
                </button>
            </div>
        </div>
    </div>
}

export default connect(null, {
})(withTranslation()(UserType));