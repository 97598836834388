import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getStoreDetails } from "../../../../../../actions/brandAction";
// import Web from "../../../channels/web";
import { ThemeContext } from "../../../theme";
import { loadSiteSettings } from "../../../themeService";
import AboutUs from "./aboutus";
import DeliveryArea from "./delivery";
const Overview = (props: any) => {
    const context = useContext(ThemeContext);
    const dispatch = useDispatch();
    const [data, setdata] = useState(null);
    const [webTheme, setWebTheme] = useState(null);
    const [aboutUsDetail, setAboutUsDetail] = useState(null);
    const getTheme = async () => {
        const resp: any = await getStoreDetails()(dispatch);
        const { data, web } = loadSiteSettings(resp, "web", context);
        setWebTheme(web);
        const { aboutUs: defaultAboutUs } = loadSiteSettings(resp, "aboutUs", context);
        setAboutUsDetail(defaultAboutUs);
        setdata(data);
    }
    useEffect(() => {
        getTheme();
        //eslint-disable-next-line
    }, []);
    return <div className="container">
        <DeliveryArea data={data} webTheme={webTheme} getTheme={getTheme}></DeliveryArea>
        <AboutUs data={data} aboutUs={aboutUsDetail} getTheme={getTheme}></AboutUs>
    </div>
};

export default Overview;