import * as React from "react";

const StoreConfigSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17.01}
        height={17}
        viewBox="0 0 17.01 17"
        {...props}
    >
        <g
            id="vuesax_linear_global-edit"
            data-name="vuesax/linear/global-edit"
            transform="translate(-749.5 -189.5)"
        >
            <g id="global-edit" transform="translate(750 190)">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M16,8a8,8,0,1,0-8,8"
                        fill="none"
                        stroke={props.stroke || "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                    />
                    <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M.372,0h.805a22.574,22.574,0,0,0,0,14.39H.372"
                        transform="translate(4.428 0.805)"
                        fill="none"
                        stroke={props.stroke || "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                    />
                    <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,0A22.987,22.987,0,0,1,1.175,7.245"
                        transform="translate(10.395 0.805)"
                        fill="none"
                        stroke={props.stroke || "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                    />
                    <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M0,.805V0A22.987,22.987,0,0,0,7.245,1.175"
                        transform="translate(0.805 10.395)"
                        fill="none"
                        stroke={props.stroke || "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                    />
                    <path
                        id="Vector-5"
                        data-name="Vector"
                        d="M0,1.177a22.574,22.574,0,0,1,14.39,0"
                        transform="translate(0.805 4.428)"
                        fill="none"
                        stroke={props.stroke || "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                    />
                </g>
                <g id="Group-2" data-name="Group" transform="translate(10.503 10.499)">
                    <path
                        id="Vector-6"
                        data-name="Vector"
                        d="M3.252.461.4,3.311a.992.992,0,0,0-.242.475L.007,4.873a.511.511,0,0,0,.612.612l1.087-.153a.958.958,0,0,0,.475-.241l2.85-2.85a1.1,1.1,0,0,0,0-1.787A1.1,1.1,0,0,0,3.252.461Z"
                        transform="translate(0 0)"
                        fill="none"
                        stroke={props.stroke || "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                    />
                    <path
                        id="Vector-7"
                        data-name="Vector"
                        d="M0,0A2.564,2.564,0,0,0,1.787,1.787"
                        transform="translate(2.841 0.872)"
                        fill="none"
                        stroke={props.stroke || "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default StoreConfigSVG;
