import { ArrowLeftOutlined } from "@ant-design/icons";
import { Drawer, Input, Select, Switch, } from "antd";
import React, { useEffect, useState } from "react";
import "../../../../../assets/sass/dsk/branding.sass";
import closeBlackSvg from "../../../../../assets/images/close_black.svg";
import { isNotNullOrUndefinedString } from "../../../../../helpers/util";
import { useDispatch } from "react-redux";
// import Loading from "../../../common/Loading";
import InputValidation from "../../../common/InputValidation";
import { getDeliverySlots } from "../../../../../actions/deliverySlotAction";
import { getOutlets } from "../../../../../actions/outletAction";
import { onNewAdditionalCharge, onUpdateAdditionalCharge } from "../../../../../actions/commonAction";

const { Option } = Select;
const errorInfo = {
    chargeName: {
        message: "",
        type: ""
    },
    buckets: {
        message: "",
        type: ""
    },
    flatAmount: {
        message: "",
        type: ""
    },
    appliesTo: {
        message: "",
        type: ""
    },
    range: {
        message: "",
        type: ""
    }
}
const Save = (props: any) => {
    const dispatch = useDispatch();
    const [taxRate, setTaxRate] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [isActive, setActive] = useState(false);
    const [error, setError] = useState(errorInfo);
    const [deliverySlots, setDeliverySlots] = useState(null);
    const [chargeFeeType, setChargeFeeType] = useState("flat");
    const [flatAmount, setFlatAmount] = useState(0);
    const [outlets, setOutlets] = useState(null);
    const [appliesTo, setAppliesTo] = useState("deliverySlot");
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [buckets, setBuckets] = useState({
        keys: [],
        items: []
    });
    const [selectedOutlets, setSelectedOutlets] = useState([]);
    // const [isLoading, setLoading] = useState(false);
    const [chargeType, setChargeType] = useState("delivery");
    const [chargeName, setChargeName] = useState("");
    const [rangeFrom, setRangeFrom] = useState(null);
    const [rangeAmount, setRangeAmount] = useState(0);
    const [rangeTo, setRangeTo] = useState(null);

    const reset = () => {
        setIsEdit(true);
        setChargeName("");
        setChargeFeeType("flat");
        setFlatAmount(0);
        setTaxRate(0);
        setAppliesTo("deliverySlot");
        setSelectedSlots([]);
        setSelectedOutlets([]);
        setBuckets({
            keys: [],
            items: []
        });
        setChargeType("delivery");
        setRangeFrom(null);
        setRangeAmount(0);
        setRangeTo(null);
        setActive(false);
        setError(errorInfo);
    }

    useEffect(() => {
        if (props.selectedCharge) {
            setIsEdit(false);
            if (props.selectedCharge?.label) {
                setChargeName(props.selectedCharge?.label);
            }
            if (props.selectedCharge?.outletIds) {
                setSelectedOutlets(props.selectedCharge?.outletIds);
            }
            if (props.selectedCharge?.deliverySlotIds) {
                setSelectedSlots(props.selectedCharge?.deliverySlotIds);
            }
            if (props.selectedCharge?.fee.type) {
                setChargeFeeType(props.selectedCharge?.fee.type);
            }
            if (props.selectedCharge?.type) {
                setChargeType(props.selectedCharge?.type);
            }
            if (props.selectedCharge?.isActive) {
                setActive(props.selectedCharge?.isActive);
            }
            if (props.selectedCharge?.fee.flatFee) {
                setFlatAmount(props.selectedCharge?.fee.flatFee)
            }
            if (props.selectedCharge?.fee.buckets) {
                const keys = props.selectedCharge?.fee.buckets.map((b) => {
                    return `${b.from}_${b.to}`
                })
                setBuckets({ ...buckets, ...{ items: props.selectedCharge?.fee.buckets, keys } });
            }
            if (props.selectedCharge?.appliesTo) {
                setAppliesTo(props.selectedCharge?.appliesTo)
            }
            if (props.selectedCharge?.taxRate) {
                setTaxRate(props.selectedCharge?.taxRate)
            } else {
                setTaxRate(0)
            }
        } else {
            reset();
        }
        //eslint-disable-next-line
    }, [props.selectedCharge])

    const onClose = () => {
        reset();
        setIsEdit(false);
        props.onClose();
    }

    const onSubmit = async () => {
        let isError = false;
        errorInfo.flatAmount.type = "";
        errorInfo.flatAmount.message = "";
        errorInfo.buckets.type = "";
        errorInfo.buckets.message = "";
        errorInfo.appliesTo.type = "";
        errorInfo.appliesTo.message = "";
        errorInfo.flatAmount.type = "";
        errorInfo.flatAmount.message = "";
        if (!isNotNullOrUndefinedString(chargeName)) {
            errorInfo.chargeName.type = "error";
            errorInfo.chargeName.message = "Fill the charge name";
            isError = true;
        } else {
            errorInfo.chargeName.type = "";
            errorInfo.chargeName.message = "";
        }
        let payload = {
            type: chargeType,
            label: chargeName,
            appliesTo: appliesTo,
            taxRate: taxRate,
            fee: {
                type: "flat",
                flatFee: flatAmount,
                buckets: buckets.items
            }
        }
        if (appliesTo === "deliverySlot") {
            if (!selectedSlots || selectedSlots?.length === 0) {
                isError = true;
                errorInfo.appliesTo.type = "error";
                errorInfo.appliesTo.message = "Choose at least one delivery slot";
            }
            payload["deliverySlotIds"] = selectedSlots;
        }
        if (appliesTo === "outlet") {
            if (!selectedOutlets || selectedOutlets?.length === 0) {
                isError = true;
                errorInfo.appliesTo.type = "error";
                errorInfo.appliesTo.message = "Choose at least one outlet";
            }
            payload["outletIds"] = selectedOutlets;
        }
        if (chargeFeeType === "flat") {
            payload.fee.type = "flat";
            if (!flatAmount || flatAmount < 1) {
                errorInfo.flatAmount.type = "error";
                errorInfo.flatAmount.message = "Fill the flat amount greater than one";
                isError = true;
            }
            delete payload.fee.buckets;
        }
        if (chargeFeeType === "bucket") {
            payload.fee.type = "bucket";
            if (buckets.items?.length === 0) {
                errorInfo.buckets.type = "error";
                errorInfo.buckets.message = "Fill the distance range";
                isError = true;
            } else {
                errorInfo.buckets.type = "";
                errorInfo.buckets.message = "";
            }
            delete payload.fee.flatFee;
        }
        console.log({ payload })
        setError({ ...error, ...errorInfo })
        let resp = null;
        if (!isError) {
            if (props.selectedCharge?.id) {
                payload['isActive'] = isActive;
                resp = await onUpdateAdditionalCharge(props.selectedCharge?.id, payload)(dispatch)
            } else {
                resp = await onNewAdditionalCharge(payload)(dispatch)
            }
            if (resp?.additionalChargeId || resp?.isUpdated) {
                onClose();
            }
        }
    }

    useEffect(() => {
        getSlots();
        setIsEdit(!props.selectedCharge ? true : false);
        //eslint-disable-next-line
    }, [])

    const getSlots = async () => {
        const respDel = await getDeliverySlots()(dispatch);
        if (respDel?.deliverySlots) {
            setDeliverySlots(respDel.deliverySlots)
        }
        const respOut = await getOutlets()(dispatch);
        if (respOut) {
            setOutlets(respOut)
        }
    }

    const onAddBucket = () => {
        errorInfo.range.message = "";
        errorInfo.range.type = ""
        if (rangeAmount > 0 && rangeTo > rangeFrom) {
            const { keys, items } = buckets;
            if (!keys.includes(`${rangeFrom}_${rangeTo}`)) {
                const withinRange = keys.filter((item) => {
                    const r = item.split("_");
                    // as <= at && bs >= bt
                    return r[0] <= rangeFrom && r[1] >= rangeTo
                });
                if (withinRange.length === 0) {
                    keys.push(`${rangeFrom}_${rangeTo}`);
                    items.push({
                        from: rangeFrom,
                        to: rangeTo,
                        bucketFee: rangeAmount
                    });
                    setBuckets({ ...buckets, ...{ items, keys } });
                    setRangeAmount(0);
                    setRangeFrom(null);
                    setRangeTo(null);
                } else {
                    errorInfo.range.message = "range already exists";
                    errorInfo.range.type = "error"
                }
            }
        } else {
            if (rangeTo < rangeFrom) {
                errorInfo.range.message = "distance from km greater than to km";
                errorInfo.range.type = "error"
            } else if (rangeAmount <= 0) {
                errorInfo.range.message = "amount should be greater than 0";
                errorInfo.range.type = "error"
            } else if ((rangeTo === null && rangeFrom === null) || ((rangeTo === "" || rangeFrom === "")) || (rangeTo === 0 && rangeFrom === 0)) {
                errorInfo.range.message = "fill the km ranges";
                errorInfo.range.type = "error"
            }
        }
        setError({ ...error, ...errorInfo })
    }

    const onRemoveRange = (range) => {
        const { keys, items } = buckets;
        let updateItems = null;
        if (keys.includes(`${range.from}_${range.to}`)) {
            keys.splice(keys.indexOf(`${range.from}_${range.to}`), 1)
        }
        updateItems = items.filter((item) => !(item.from === range.from && item.to === range.to))
        setBuckets({ ...buckets, ...{ items: updateItems, keys } });
    }

    return <Drawer
        width={(appliesTo === "deliverySlot" || appliesTo === "outlet" || chargeFeeType === "bucket") ? '50%' : 470}
        maskClosable={false}
        closable={false}
        onClose={onClose}
        className="business_hours_view_edit"
        visible={props.isVisible}
        footer={<div className="footer">
            <div className="sp_btn" onClick={onClose} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
            {!isEdit && (
                <div
                    className="sp_btn"
                    onClick={() => {
                        setIsEdit(true);
                    }}
                    style={{ width: 120, fontSize: 14 }}
                >
                    Edit
                </div>
            )}
            {isEdit && (
                <div className="sp_btn" onClick={onSubmit} style={{ width: 155, fontSize: 14 }}>
                    {props.selectedCharge ? "Update" : "Add"}
                </div>
            )}
        </div>}>
        <div className="header">
            <ArrowLeftOutlined className="arrow" onClick={() => onClose()}></ArrowLeftOutlined>
            <div className="title">{!props.selectedCharge ? "Add New Charges" : "Update Charges"}</div>
        </div>
        <div className="content" style={{ display: 'flex' }}>
            {(appliesTo === "deliverySlot" || appliesTo === "outlet" || chargeFeeType === "bucket") && <div style={{ width: '100%', marginRight: 15 }}>
                {appliesTo === "deliverySlot" && <div className="group">
                    <div className="label">Delivery slot</div>
                    <InputValidation type={error.appliesTo.type} message={error.appliesTo.message}>
                        <Select disabled={!isEdit} style={{ height: '150px !important' }} mode="multiple" value={selectedSlots} onChange={(val) => setSelectedSlots(val)}>
                            {deliverySlots && deliverySlots.map((item) => {
                                return <Option key={item.id} value={item.id}>{item.name}</Option>
                            })}
                        </Select>
                    </InputValidation>
                </div>}
                {appliesTo === "outlet" && <div className="group">
                    <div className="label">Outlets</div>
                    <InputValidation type={error.appliesTo.type} message={error.appliesTo.message}>
                        <Select disabled={!isEdit} style={{ height: '150px !important' }} mode="multiple" value={selectedOutlets} onChange={(val) => setSelectedOutlets(val)}>
                            {outlets && outlets.map((item) => {
                                return <Option key={item.id} value={item.id}>{item.name}</Option>
                            })}
                        </Select>
                    </InputValidation>
                </div>}
                {chargeFeeType === "bucket" && <div className="group">
                    <div className="label">Distance Range</div>
                    {isEdit && <div style={{ display: 'flex', marginBottom: 24 }}>
                        <InputValidation type={error.range.type} message={error.range.message}>
                            <div style={{ display: 'flex' }}>
                                <Input style={{ marginRight: 7 }} type="number" disabled={!isEdit} placeholder="From distance" className="value" value={rangeFrom} onChange={(e) =>
                                    setRangeFrom(parseInt(e.target.value))
                                } />
                                <Input style={{ marginRight: 7 }} type="number" disabled={!isEdit} placeholder="To distance" className="value" value={rangeTo} onChange={(e) =>
                                    setRangeTo(parseInt(e.target.value))
                                } />
                                <Input prefix={"₹"} className="value" type="number" onChange={(e) => setRangeAmount(parseInt(e.target.value))} disabled={!isEdit} value={rangeAmount} />
                            </div>
                        </InputValidation>
                        <div className="sp_btn" onClick={onAddBucket} style={{ marginLeft: 7, width: 120, fontSize: 14 }}>Add</div>
                    </div>}
                    {buckets && buckets.items.map((item) => {
                        return <div style={{
                            height: 39,
                            background: '#D8DEE65C',
                            borderRadius: 4,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '0px 7px',
                            marginBottom: 10
                        }}>
                            <div style={{
                                fontSize: 13,
                                color: "#808080",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginRight: 18,
                                flexGrow: 1
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    Distance <div style={{ color: "#121212", margin: '0px 6px', fontWeight: 700 }}>{item.from} - {item.to} km</div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    Amount - <div style={{ color: "#121212", marginLeft: 6, fontWeight: 700 }}>₹{item.bucketFee}</div>
                                </div>
                            </div>
                            {isEdit && <img alt="Close" style={{ cursor: 'pointer' }} onClick={() => onRemoveRange(item)} src={closeBlackSvg} />}
                        </div>
                    })}
                </div>}
            </div>}
            <div style={{ width: '100%', marginLeft: 15 }}>
                <div className="group">
                    <div className="label">Charge Name</div>
                    <InputValidation type={error.chargeName.type} message={error.chargeName.message}>
                        <Input disabled={!isEdit} className="value" value={chargeName} onChange={(e) =>
                            setChargeName(e.target.value)
                        } />
                    </InputValidation>
                </div>
                <div className="group">
                    <div className="label">Charge Type</div>
                    <Select value={chargeType} disabled={!isEdit || props.selectedCharge?.id} onChange={(val) => setChargeType(val)}>
                        <Option key="delivery" value="delivery">Delivery</Option>
                        <Option key="convenience" value="convenience">Convenience</Option>
                        <Option key="installation" value="installation">Installation</Option>
                        <Option key="packaging" value="packaging">Packaging</Option>
                        <Option key="custom" value="custom">Custom</Option>
                    </Select>
                </div>
                <div className="group">
                    <div className="label">Applies To</div>
                    <Select value={appliesTo} disabled={!isEdit} onChange={(val) => setAppliesTo(val)}>
                        <Option key="deliverySlot" value="deliverySlot">Delivery Slot</Option>
                        <Option key="outlet" value="outlet">Outlet</Option>
                        <Option key="store" value="store">Store</Option>
                    </Select>
                </div>
                <div className="group">
                    <div className="label">Charge Fee</div>
                    <Select disabled={!isEdit} value={chargeFeeType} onChange={(val) => setChargeFeeType(val)}>
                        <Option key="flat" value="flat">Flat Fee</Option>
                        <Option key="bucket" value="bucket">Distance</Option>
                    </Select>
                </div>
                <div className="group">
                    <div className="label" >
                        Tax
                    </div>
                    <div>
                        <Input disabled={!isEdit} type="number" className="value rm-border" value={taxRate} onChange={(e) => {
                            const { value }: any = e.target;
                            if (value >= 0 && value <= 28) {
                                setTaxRate(parseInt(value))
                            }
                        }} />
                    </div>
                </div>
                {chargeFeeType === "flat" && <div className="group">
                    <div className="label">Flat Amount</div>
                    <InputValidation type={error.flatAmount.type} message={error.flatAmount.message}>
                        <Input disabled={!isEdit} prefix={"₹"} className="value" type="number" onChange={(e) => setFlatAmount(parseInt(e.target.value))} value={flatAmount} />
                    </InputValidation>
                </div>}
                {props.selectedCharge && <div className="group">
                    <div className="label">Active</div>
                    <Switch
                        className="toggle-opt"
                        style={{ width: 50 }}
                        disabled={!isEdit}
                        checked={isActive}
                        onChange={(value) => setActive(value)}
                    />
                </div>}
            </div>
        </div>
        {/* {isLoading && <Loading background="#00000070"></Loading>} */}
    </Drawer>

}

export default Save;