import * as React from "react"

function OrdersSvg(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={20}
      viewBox="0 0 17 20"
    >
      <g data-name="Group 37" stroke={props.stroke || "#fff"} strokeWidth={1.5}>
        <g data-name="Rectangle 23">
          <rect width={17} height={20} rx={4} stroke="none" />
          <rect
            x={0.75}
            y={0.75}
            width={15.5}
            height={18.5}
            rx={3.25}
            fill="none"
          />
        </g>
        <g data-name="Group 2382" fill="none" strokeLinecap="round">
          <path data-name="Line 5" d="M3.594 4.724h9.214" />
          <path data-name="Line 6" d="M3.594 8.01h9.214" />
          <path data-name="Line 7" d="M3.594 11.296h9.214" />
          <path data-name="Line 8" d="M3.594 14.582h9.214" />
        </g>
      </g>
    </svg>
  )
}

export default OrdersSvg
