import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStoreDetails, onUpdateSiteSettings } from "../../../../../../actions/brandAction";
import Loading from "../../../../common/Loading";
// import { loadSiteSettings } from "../../../../themeService";

const Pages = (props) => {
    const dispatch = useDispatch();
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [isEnable, setEnable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const getStoreDetail = async () => {
        const resp = await getStoreDetails()(dispatch);
        if (resp) {
            if (resp.settings && resp.settings.entryPage === "landing") {
                setEnable(true);
            }
        }
    }
    useEffect(() => {
        if (selectedStore.id) {
            getStoreDetail();
        }
        //eslint-disable-next-line
    }, [selectedStore]);

    const onPageSettings = async (val) => {
        const payload = {
            entryPage: val ? "landing" : ""
        };
        setIsLoading(true);
        const resp = await onUpdateSiteSettings(payload)(dispatch);
        if (resp) {
            setEnable(val);
        }
        setIsLoading(false);
    }
    return <>
        {isLoading && <Loading background="#00000070"></Loading>}
        <div className="container">
            <div className="header" >Pages</div>
            <div className="division">
                <div className="section" style={{ display: 'flex' }}>
                    <div style={{ width: '40%' }}>
                        <div className="title" style={{ marginBottom: 10 }}>Show landing page on start up</div>
                        <div style={{ flexGrow: 1, fontSize: 13 }} className="key" >Lorem ipsum dolor sit amet, consectetur</div>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        position: 'relative'
                    }}>
                        <div style={{ flexGrow: 1 }}>
                            <Switch
                                className="toggle-opt"
                                onClick={(val) =>
                                    onPageSettings(val)
                                }
                                checked={isEnable}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Pages;