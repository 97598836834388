import type from "../types/actionTypes";
import config from "../apiConfig";
import { store } from '../store';
import axiosWrapper from "./axiosWrapper";

export const getPosts = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.postsOrPagesUrl.replace('{sId}', sId).replace('{type}', "post")}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Posts Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getPagesRedirect = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.pagesRedirectUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Page Redirects Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const createPageRedirect = (data) => {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        data
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.pagesRedirectUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Create Page Redirects Status Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    }
}

export const deletePageRedirect = (prId) => {
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.pageRedirectByIdUrl.replace('{sId}', sId).replace('{pageRedirectId}', prId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Delete Page Redirects  Status Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    }
}

export const getPages = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.postsOrPagesUrl.replace('{sId}', sId).replace('{type}', "page")}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Pages Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getPostById = (pId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.postByIdUrl.replace('{sId}', sId).replace('{postId}', pId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Post By Id Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const createPost = (data) => {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        data
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.postsUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Create Post Status Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    }
}

export const updatePost = (pId, data) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        data
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.postByIdUrl.replace('{sId}', sId).replace('{postId}', pId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Post Status Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    }
}

export const deletePost = (pId) => {
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.postByIdUrl.replace('{sId}', sId).replace('{postId}', pId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Delete Post Status Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    }
}

export const deletePage = (pId) => {
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.pageByIdUrl.replace('{sId}', sId).replace('{pageId}', pId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Delete Page Status Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    }
}

export const getBlogs = () => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.blogsUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Blogs Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const getBlogById = (pId) => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        }
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.blogByIdUrl.replace('{sId}', sId).replace('{blogId}', pId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            dispatch({
                type: type.get,
                data: resp.data
            });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Get Blog By Id Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    };
}

export const createBlog = (data) => {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        data
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.blogsUrl.replace('{sId}', sId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Create Blog Status Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    }
}

export const updateBlog = (bId, data) => {
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getState().auth?.info?.token}`
        },
        data
    };
    const sId = store.getState().ui?.selectedStore?.id;
    return async (dispatch) => {
        try {
            let url = `${config.baseUrl}${config.blogByIdUrl.replace('{sId}', sId).replace('{blogId}', bId)}`;
            let resp = await axiosWrapper(url, options, { dispatch });
            return resp && resp.data ? resp.data : null;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                dispatch({
                    type: type.ERROR,
                    data: {
                        title: "Update Blog Status Failed",
                        message: error.response.data.message,
                        code: error.response.data.code
                    }
                });
            }
        }
    }
}