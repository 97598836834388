import type from "../types/actionTypes"

const initialState = {
    searchFilter: null,
    multiOrderStatusDetails: null,
    selectedOutlets: null,
    error: null,
    selectedStoreDetails: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case type.SEARCH_FILTER:
            return {
                ...state,
                searchFilter: action.data
            }
        case type.SELECTED_STORE_DETAILS:
            return {
                ...state,
                selectedStoreDetails: action.data
            }
        case type.MULTI_ORDER_STATUS_DETAILS:
            return {
                ...state,
                multiOrderStatusDetails: action.data
            }

        case type.SELECTED_OUTLETS:
            return {
                ...state,
                selectedOutlets: action.data
            }
        case type.ERROR:
            return {
                ...state,
                error: action.data
            }
        default:
            return state;
    }
};

export default reducer;