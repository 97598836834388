import { Input, Switch } from "antd";
import React, { useEffect, useState } from "react";
import "../../../assets/sass/dsk/settings.sass";
import TextArea from "antd/es/input/TextArea";
import { useDispatch } from "react-redux";
import { updateBotSettings, getBotSettings } from "../../../actions/commonAction";
import MarkdownViewer from "../common/MarkdownViewer";

const BotConfig = () => {
    const WindowObj: any = window;
    let keyStrokesTimer: any = null;
    const dispatch = useDispatch();
    const [botName, setBotName] = useState(null);
    const [businessDescription, setBusinessDescription] = useState(null);
    const [enabled, setEnabled] = useState<boolean>(false);
    const [botSettings, setBotSettings] = useState(null);
    const [selectedMsgPriceType, setSelectedMsgPriceType] = useState(null);
    const [msgPrice, setMsgPrice] = useState(null);
    const MsgPriceSelection = [{
        label: '₹ 100',
        value: 100
    }, {
        label: '₹ 500',
        value: 500
    }, {
        label: '₹ 1000',
        value: 1000
    }, {
        label: 'Other Amount',
        value: 'otherAmount'
    }];
    useEffect(() => {
        const fetchData = async () => {
            const data = await getBotSettings()(dispatch);
            if (data) {
                setBotSettings(data);
                setBotName(data.botName);
                setBusinessDescription(data.businessDescription);
                setEnabled(data.enabled);
                setMsgPrice(data.monthlyBudget);
                const msgPriceType = MsgPriceSelection.filter((mp) => mp.value === data.monthlyBudget);
                setSelectedMsgPriceType(msgPriceType.length > 0 ? msgPriceType[0] : MsgPriceSelection[MsgPriceSelection.length - 1])
            }
        }
        fetchData();
        //eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (selectedMsgPriceType && selectedMsgPriceType?.value !== 'otherAmount') {
            setMsgPrice(selectedMsgPriceType.value)
        } else {
            setMsgPrice(null);
        }
    }, [selectedMsgPriceType])

    const onSave = async () => {
        let data = {};
        if (enabled) {
            if (!botName || !businessDescription || !msgPrice) return;
            data = {
                botName,
                businessDescription,
                enabled,
                monthlyBudget: msgPrice
            }
        } else {
            data = {
                enabled
            }
        }
        if (enabled !== null && (botSettings?.enabled !== enabled || botSettings?.botName !== botName || botSettings?.businessDescription !== businessDescription)) {
            keyStrokesTimer = setTimeout(async () => {
                await updateBotSettings(data)(dispatch);
            }, 500);
        }
    }

    useEffect(() => {
        if (keyStrokesTimer) {
            clearTimeout(keyStrokesTimer);
        }
        onSave();
        return () => {
            if (keyStrokesTimer) {
                clearTimeout(keyStrokesTimer);
            }
        };
        //eslint-disable-next-line
    }, [enabled, botName, businessDescription, msgPrice]);
    return <div className="settings">
        <div className="content" style={{ flexDirection: 'row' }}>
            <div className="container" style={{ flexGrow: 1, margin: '0px 24px' }}>
                <div style={{ height: window.innerHeight - 80 }}>
                    <div className="division">
                        <div className="header">Bot Configuration</div>
                        <div className="section" style={{ display: 'flex' }}>
                            <div style={{ width: '40%' }}>
                                <div className="title" style={{ marginBottom: 10 }}>Enable the Bot settings</div>
                                <div style={{ flexGrow: 1, fontSize: 13 }} className="key" >Lorem ipsum dolor sit amet, consectetur</div>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                position: 'relative'
                            }}>
                                <div style={{ flexGrow: 1 }}>
                                    <Switch
                                        className="toggle-opt"
                                        onClick={(val) => {
                                            setEnabled(val)
                                        }}
                                        checked={enabled}
                                    />
                                </div>
                            </div>
                        </div>
                        {enabled && <div className="section">
                            <div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{ width: '40vw', marginRight: 52 }}>
                                        <div className="title">Bot Name</div>
                                        <div style={{ marginTop: 2 }} className="key">Provide the legal name of your business for whatsapp.</div>
                                    </div>
                                    <Input
                                        style={{ width: '100%' }}
                                        value={botName}
                                        placeholder="Enter business name"
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            setBotName(value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{ width: '40vw', marginRight: 52 }}>
                                        <div className="title">Business Description</div>
                                        <div style={{ marginTop: 2 }} className="key">Provide a short business description for WhatsApp.</div>
                                    </div>
                                    <TextArea
                                        style={{ width: '100%', resize: 'none' }}
                                        value={businessDescription}
                                        placeholder="Enter business description"
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            setBusinessDescription(value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div style={{ width: '40vw', marginRight: 46 }}>
                                        <div className="title">Monthly Budget</div>
                                        <div style={{ marginTop: 2 }} className="key">Specify the amount you'd like to add to your bot channel.</div>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 10, }}>
                                            {MsgPriceSelection.map((mp) => {
                                                return <div key={mp.value} style={{
                                                    cursor: mp.value === selectedMsgPriceType?.value ? "default" : "pointer",
                                                    background: mp.value === selectedMsgPriceType?.value ? '#121212' : '#fff',
                                                    color: mp.value === selectedMsgPriceType?.value ? '#fff' : '#121212',
                                                    border: mp.value === selectedMsgPriceType?.value ? '1px solid #121212' : '1px solid #D9D9D9',
                                                    borderRadius: 5,
                                                    padding: '10px'
                                                }} onClick={() => { setSelectedMsgPriceType(mp) }}>
                                                    {mp.label}
                                                </div>
                                            })}
                                        </div>
                                        {selectedMsgPriceType?.value === 'otherAmount' && <div style={{
                                            display: 'flex',
                                            alignContent: 'center',
                                            marginTop: 8,
                                        }}>
                                            <Input
                                                style={{ width: 294 }}
                                                type="number"
                                                placeholder="Enter your specify amount"
                                                onChange={(e) => {
                                                    setMsgPrice(e.target.value);
                                                }}
                                            />
                                        </div>}
                                        {msgPrice && <div style={{
                                            fontSize: 13,
                                            fontWeight: 500,
                                            display: 'flex',
                                            color: '#121212',
                                            marginTop: 8,
                                            alignItems: 'center',
                                        }}>
                                            <div>Estimated number of queries handled</div>
                                            <div style={{
                                                color: '#188FFC',
                                                fontSize: 13,
                                                fontWeight: 500,
                                                marginLeft: 4
                                            }}>{` ${Math.round(msgPrice / 0.24)}`}</div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div
                style={{
                    height: WindowObj.innerHeight - 72,
                    overflowY: 'auto',
                    width: '26%',
                    background: '#FFFFFF',
                    boxShadow: '0px 3px 16px #DBE2EA6C',
                    flexShrink: 0
                }}>
                <MarkdownViewer fileName={'BotConfig'} />
            </div>
        </div>
    </div>
}

export default BotConfig;