import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import VisibilityOnSvg from "../../../assets/images/visibility-on.svg";
import VisibilityOffSvg from "../../../assets/images/visibility-off.svg";
import { ArrowLeftOutlined, CaretDownOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { getProducts, getProductByIdOuId, getProductById } from "../../../actions/productActions";
import { updateProductOutlets, addProductOutlets } from "../../../actions/outletAction";
import { getL10N } from "../../../helpers/middleware";
import { Row, Col, Drawer, Select } from "antd";
import Loading from "../common/Loading";
import "../../../assets/sass/dsk/outlet.sass";
import InputValidation from "../common/InputValidation";
import { motion } from "framer-motion";

const { Option } = Select;

const ProductToOutlet = (props: any) => {
    const windowHeight = window.innerHeight;
    let { selectedOutletId, isVisible, defaultValue } = props;
    const [isEdit, setIsEdit] = useState(defaultValue ? false : true);
    const priceRef = useRef(null);
    const salePriceRef = useRef(null);
    const availableStockRef = useRef(null);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [unassignedProducts, setUnassignedProducts] = useState([]);
    const [unassignedProductList, setUnassignedProductList] = useState([]);
    const [searchProduct, setSearchProduct] = useState(null);
    const [selectedProductDetail, setSelectedProductDetail] = useState(null);
    const [selectedProductDetailRaw, setSelectedProductDetailRaw] = useState(null);
    const [salePriceId, setSalePriceId] = useState(null);
    const [salePrice, setSalePrice] = useState(null);
    const [priceId, setPriceId] = useState(null);
    const [price, setPrice] = useState(null);
    const [availableStockId, setAvailableStockId] = useState(null);
    const [availableStock, setAvailableStock] = useState(null);
    const [variantVisibleId, setVariantVisibleId] = useState(null);
    // const [debouncedPriceValue, setDebouncedPriceValue] = useState(price);
    // const [debounceSalePriceValue, setDebouncedSalePriceValue] = useState(price);
    // const [debounceAvailableStockValue, setDebouncedAvailableStockValue] = useState(availableStock);
    // const [debounceVariantVisibleValue, setDebounceVariantVisibleValue] = useState(variantVisibleId);

    let priceDetails = {
        mrp: null,
        price: 0,
        isAvailable: null,
        salePrice: 0,
        stock: 0,
        outOfStock: null,
        salePricePercentage: 0
    };

    const [updatePrice, setUpdatePrice] = useState(priceDetails);
    let errorInfo = {
        selectedProduct: {
            message: "",
            type: "",
        },
        stock: {
            message: "",
            type: "",
        },
        isAvailable: {
            message: "",
            type: "",
        },
        outOfStock: {
            message: "",
            type: "",
        },
        price: {
            message: "",
            type: "",
        },
        salePrice: {
            message: "",
            type: "",
        },
    };

    const [error, setError] = useState(errorInfo);

    // useEffect(() => {
    //     const handler = setTimeout(() => {
    //         setDebouncedPriceValue(price);
    //     }, 500);
    //     return () => {
    //         clearTimeout(handler);
    //     };
    // }, [price]);

    // useEffect(() => {
    //     const handler = setTimeout(() => {
    //         setDebouncedSalePriceValue(salePrice);
    //     }, 500);
    //     return () => {
    //         clearTimeout(handler);
    //     };
    // }, [salePrice]);

    // useEffect(() => {
    //     const handler = setTimeout(() => {
    //         setDebouncedAvailableStockValue(availableStock);
    //     }, 500);
    //     return () => {
    //         clearTimeout(handler);
    //     };
    // }, [availableStock]);

    // useEffect(() => {
    //     const handler = setTimeout(() => {
    //         setDebounceVariantVisibleValue(variantVisible);
    //     }, 500);
    //     return () => {
    //         clearTimeout(handler);
    //     };
    // }, [variantVisibleId]);

    useEffect(() => {
        if (isVisible === false) {
            let priceDetails = {
                mrp: null,
                salePrice: 0,
                price: 0,
                isAvailable: null,
                stock: 0,
                outOfStock: null,
                salePricePercentage: 0
            };
            setSelectedProductId(null);
            // setSelectedProductCatalog(null)
            setUnassignedProducts([]);
            setUnassignedProductList([]);
            // setSelectedProductTax(0);
            setUpdatePrice({ ...updatePrice, ...priceDetails });
            // setUpdatePrice((preState) => ({ ...preState, ...priceDetails }));
        } else {
            if (defaultValue?.productId) {
                setSelectedProductId(defaultValue.productId);
            }
            setIsEdit(defaultValue ? false : true);
            loadProducts();
            setUpdatePrice({ ...updatePrice, ...priceDetails });
        }
        //eslint-disable-next-line
    }, [defaultValue, isVisible]);

    const loadProducts = async () => {
        let resp = await props.getProducts();
        // setProducts(resp);
        let unProductList = [];
        let isOutlet = false;
        if (resp) {
            for (let p of resp) {
                isOutlet = false;
                if (p.outlets && p.outlets.length > 0 && selectedOutletId) {
                    for (let i in p.outlets) {
                        if (selectedOutletId === p.outlets[i].ouId) {
                            isOutlet = true;
                        }
                    }
                }
                if (!isOutlet) {
                    unProductList.push(p);
                }
            }
        }
        setUnassignedProducts(unProductList);
        setUnassignedProductList(unProductList);
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        // let { error, updatePrice, selectedProduct } = this.state;
        let isError = false;
        if (!selectedProductId) {
            error.selectedProduct.message = "Selected the product";
            error.selectedProduct.type = "error";
            isError = true;
        } else {
            error.selectedProduct.message = "";
            error.selectedProduct.type = "";
        }
        // if (isNaN(updatePrice.price) || updatePrice.price <= 0) {
        //     error.price.message = "Fill the product price";
        //     error.price.type = "error";
        //     isError = true;
        // } else {
        //     error.price.message = "";
        //     error.price.type = "";
        // }

        // if (parseInt(updatePrice.price.toString()) > 0 && parseInt(updatePrice.salePrice.toString()) > parseInt(updatePrice.price.toString())) {
        //     isError = true;
        //     error.salePrice.message = "Sale price must be lesser than product price";
        //     error.salePrice.type = "warning";
        // } else {
        //     error.salePrice.message = "";
        //     error.salePrice.type = "";
        // }

        // if (isNaN(updatePrice.stock)) {
        //     error.stock.message = "Fill the product stock";
        //     error.stock.type = "error";
        //     isError = true;
        // } else {
        //     error.stock.message = "";
        //     error.stock.type = "";
        // }
        // if (!isBoolean(updatePrice.isAvailable)) {
        //     error.isAvailable.message = "Fill the product Available";
        //     error.isAvailable.type = "error";
        //     isError = true;
        // } else {
        //     error.isAvailable.message = "";
        //     error.isAvailable.type = "";
        // }
        // if (!isBoolean(updatePrice.outOfStock)) {
        //     error.outOfStock.message = "Fill the out of stock";
        //     error.outOfStock.type = "error";
        //     isError = true;
        // } else {
        //     error.outOfStock.message = "";
        //     error.outOfStock.type = "";
        // }
        if (isError !== true) {
            setIsLoading(true);
            let variants = [];
            for (let vList of selectedProductDetailRaw.variants) {
                const filteredVariant = selectedProductDetail.variants.filter((v) => v.id === vList.id);
                if (filteredVariant && filteredVariant.length > 0) {
                    if (filteredVariant[0].outlet && (filteredVariant[0].outlet.price !== vList.price || filteredVariant[0].outlet.salePrice !== vList.salePrice ||
                        filteredVariant[0].outlet.availableStock !== vList.availableStock || filteredVariant[0].outlet.isVisible !== vList.isVisible)) {
                        variants.push({
                            "id": filteredVariant[0].id,
                            "availableStock": filteredVariant[0].outlet.availableStock || 0,
                            "isVisible": filteredVariant[0].outlet.isVisible || false,
                            "price": filteredVariant[0].outlet.price || 0,
                            "salePrice": filteredVariant[0].outlet.salePrice || 0
                        });
                    } else if (filteredVariant[0].price !== vList.price || filteredVariant[0].salePrice !== vList.salePrice ||
                        filteredVariant[0].availableStock !== vList.availableStock || filteredVariant[0].isVisible !== vList.isVisible) {
                        variants.push({
                            "id": filteredVariant[0].id,
                            "availableStock": filteredVariant[0].availableStock || 0,
                            "isVisible": filteredVariant[0].isVisible || false,
                            "price": filteredVariant[0].price || 0,
                            "salePrice": filteredVariant[0].salePrice || 0
                        });
                    } else if (!defaultValue) {
                        variants.push({
                            "id": filteredVariant[0].id,
                            "availableStock": filteredVariant[0].availableStock || 0,
                            "isVisible": filteredVariant[0].isVisible || false,
                            "price": filteredVariant[0].price || 0,
                            "salePrice": filteredVariant[0].salePrice || 0
                        });
                    }
                }
            }
            const payloadOutlet = {
                ouId: selectedOutletId,
                variants
            };
            // console.log({ payloadOutlet })
            const resp = await props.updateProductOutlets(selectedProductId, payloadOutlet);
            // console.log({ resp })
            setIsLoading(false);
            if (resp && resp.isUpdated) {
                onClose();
            }
        } else {
            setError((preState) => ({ ...preState, ...error }));
        }
    };

    // const onUpdateStock = (e) => {
    //     if (/^[0-9\b]+$/.test(e.target.value)) {
    //         setUpdatePrice({ ...updatePrice, [e.target.name]: parseInt(e.target.value) })
    //     }
    //      ?  : setUpdatePrice({ ...updatePrice, [e.target.name]: updatePrice }) }
    // }

    const onSearchProduct = () => {
        let newList = [];
        for (let unProduct of unassignedProducts) {
            const title = getL10N(unProduct, "title", null).i10n;
            if (
                searchProduct === null ||
                searchProduct.length === 0 ||
                title.toLowerCase().indexOf(searchProduct.toLowerCase()) > -1
            ) {
                newList.push(unProduct);
            }
        }
        setUnassignedProductList(newList);
    };

    useEffect(() => {
        onSearchProduct();
        //eslint-disable-next-line
    }, [searchProduct]);

    const onClose = () => {
        setError(errorInfo);
        setPrice(null);
        setSelectedProductDetail(null);
        setSelectedProductDetailRaw(null);
        setSalePrice(null);
        setSelectedVariants([]);
        setSearchProduct(null);
        setSelectedProductId(null);
        props.onClose();
    };
    const loadProductVariants = async () => {
        let resp = null;
        if (defaultValue) {
            resp = await props.getProductByIdOuId(selectedProductId, selectedOutletId);
        } else {
            resp = await props.getProductById(selectedProductId);
        }
        if (resp) {
            setSelectedProductDetail(JSON.parse(JSON.stringify(resp)));
            setSelectedProductDetailRaw(resp);
        }
    }
    useEffect(() => {
        if (selectedProductId) {
            loadProductVariants();
        }
        //eslint-disable-next-line
    }, [selectedProductId]);
    const onGenerateVariant = (options) => {
        let title = "";
        for (let op of Object.keys(options)) {
            title += ` ${title.length > 0 ? " / " : ""} ${options[op]}`;
        }
        return title;
    }
    const onSelectVariant = (id) => {
        if (id === "all" && selectedVariants.indexOf("all") > -1) {
            setSelectedVariants([]);
        } else if (id === "all") {
            setSelectedVariants(["all"]);
        } else {
            setSelectedVariants((prevVariants) => {
                const isSelected = prevVariants.find((vId) => vId === id);
                return isSelected
                    ? prevVariants.filter((vId) => vId !== id)
                    : [...prevVariants.filter((vId) => vId !== 'all'), id];
            });
        }
    }
    // useEffect(() => {
    //     if (priceId) {
    //         onUpdateVariantDetails('price', parseFloat(price), null);
    //     }
    //     //eslint-disable-next-line
    // }, [debouncedPriceValue]);
    // useEffect(() => {
    //     if (salePriceId) {
    //         onUpdateVariantDetails('salePrice', parseFloat(salePrice), null);
    //     }
    //     //eslint-disable-next-line
    // }, [debounceSalePriceValue]);
    // useEffect(() => {
    //     if (availableStockId) {
    //         onUpdateVariantDetails('availableStock', parseInt(availableStock), null);
    //     }
    //     //eslint-disable-next-line
    // }, [debounceAvailableStockValue]);
    // useEffect(() => {
    //     console.log({ debounceVariantVisibleValue })
    //     if (variantVisible !== null) {
    //         onUpdateVariantDetails('isVisible', variantVisible);
    //     }
    //     //eslint-disable-next-line
    // }, [debounceVariantVisibleValue]);
    const onUpdateVariantDetails = (key: string, value: any, id: string) => {
        let isUpdate = false;
        const { variants } = selectedProductDetail;
        if (id && (selectedVariants && (selectedVariants.length === 0 || (selectedVariants.indexOf(id) === -1 && selectedVariants.indexOf('all') === -1)))) {
            // if (id && (selectedVariants && (selectedVariants.length === 0 || (selectedVariants.indexOf(id) === -1) && selectedVariants.indexOf('all') === -1))) {
            for (let vf of variants) {
                if (vf.id === id) {
                    isUpdate = true;
                    if (vf.outlet) {
                        vf.outlet[key] = value;
                    } else {
                        vf[key] = value;
                    }
                }
            }
        } else if (selectedVariants && selectedVariants.length > 0) {
            if (selectedVariants.indexOf("all") > -1) {
                isUpdate = true;
                for (let vf of variants) {
                    if (vf.outlet) {
                        vf.outlet[key] = value;
                    } else {
                        vf[key] = value;
                    }
                }
            } else {
                for (let sv of selectedVariants) {
                    for (let vf of variants) {
                        if (vf.id === sv) {
                            isUpdate = true;
                            if (vf.outlet) {
                                vf.outlet[key] = value;
                            } else {
                                vf[key] = value;
                            }
                        }
                    }
                }
            }
        }
        if (isUpdate) {
            setSelectedProductDetail(selectedProductDetail);
        }
    }
    useEffect(() => {
        if (priceId && priceRef && priceRef.current) {
            priceRef.current.focus();
        }
        //eslint-disable-next-line
    }, [priceRef && priceId]);
    useEffect(() => {
        if (salePriceId && salePriceRef && salePriceRef.current) {
            salePriceRef.current.focus();
        }
        //eslint-disable-next-line
    }, [salePriceRef && salePriceId]);
    useEffect(() => {
        if (availableStockId && availableStockRef && availableStockRef.current) {
            availableStockRef.current.focus();
        }
        //eslint-disable-next-line
    }, [availableStockRef && availableStockId]);

    useEffect(() => {
        if (selectedProductDetail && variantVisibleId) {
            const { variants } = selectedProductDetail;
            for (let v of variants) {
                if (v.id === variantVisibleId) {
                    if (v.outlet) {
                        v.outlet.isVisible = !v.outlet.isVisible;
                    } else {
                        v.isVisible = !v.isVisible;
                    }
                }
            }
            setSelectedProductDetail(selectedProductDetail);
            setVariantVisibleId(null);
        }
        //eslint-disable-next-line        
    }, [variantVisibleId])
    return (
        <Drawer
            width={'70%'}
            closable={false}
            onClose={onClose}
            className="outlet-update"
            visible={isVisible}
            footer={
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div
                        className="sp_btn"
                        onClick={() => {
                            onClose();
                        }}
                        style={{
                            boxShadow: "none",
                            width: 120,
                            fontSize: 14,
                            marginRight: 10,
                            border: "1px solid #121212",
                            color: "#000",
                            background: "#fff",
                        }}
                    >
                        Cancel
                    </div>
                    {!isEdit && (
                        <div
                            className="sp_btn"
                            onClick={() => {
                                setIsEdit(true);
                            }}
                            style={{ width: 120, fontSize: 14 }}
                        >
                            Edit
                        </div>
                    )}
                    {isEdit && (
                        <div className="sp_btn" onClick={onSubmitHandler} style={{ width: 155, fontSize: 14 }}>
                            {defaultValue ? "Update " : "Add "}Price
                        </div>
                    )}
                </div>
            }
        >
            {isLoading && <Loading background="#00000070"></Loading>}
            <div className="header">
                <ArrowLeftOutlined
                    className="arrow"
                    onClick={() => {
                        onClose();
                    }}
                ></ArrowLeftOutlined>
                <div className="title">Product Price Details</div>
            </div>
            <Row>
                <Col span={24} className="content" style={{ marginTop: 15 }}>
                    <div style={{ overflow: "hidden", height: windowHeight - 140 + "px", width: "100%" }}>
                        <div className="group" style={{ padding: "0px 15px" }}>
                            <div className="label">{defaultValue ? "Selected product" : "Select to add product *"}</div>
                            {!defaultValue && (
                                <InputValidation
                                    type={error.selectedProduct.type}
                                    message={error.selectedProduct.message}
                                >
                                    <Select
                                        showSearch={selectedProductId ? false : true}
                                        suffixIcon={
                                            selectedProductId ? (
                                                <CloseCircleOutlined onClick={() => { setSelectedProductId(null); }} />
                                            ) : (
                                                <CaretDownOutlined />
                                            )
                                        }
                                        disabled={!isEdit}
                                        className="add-product"
                                        placeholder="Select to add product"
                                        filterOption={false}
                                        // mode="tags" maxTagCount={1}
                                        value={selectedProductId}
                                        onSearch={(value: string) => {
                                            setSearchProduct(value);
                                        }}
                                        onChange={(id: string) => {
                                            setSelectedProductId(id);
                                            setSelectedVariants([]);
                                            setPriceId(null);
                                            setPrice(null);
                                            setSalePriceId(null);
                                            setSalePrice(null);
                                            // const selectedItem = unassignedProductList.filter((item: any) => item.id === id);
                                            // if (selectedItem?.length > 0) {
                                            //     setSelectedProductCatalog(selectedItem[0]);
                                            // }
                                        }}
                                    >
                                        {unassignedProductList.map((unProduct) => {
                                            return (
                                                <Option key={unProduct.id} value={unProduct.id}>
                                                    <div
                                                        style={{
                                                            height: 40,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "space-around",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: 14,
                                                                color: "#121212",
                                                                fontWeight: 900,
                                                                textTransform: "uppercase",
                                                            }}
                                                        >
                                                            {getL10N(unProduct, "title", null).i10n}
                                                        </div>
                                                        <div
                                                            style={{
                                                                color: "#ADB7C3",
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            {getL10N(unProduct, "subtitle", null).i10n}
                                                        </div>
                                                    </div>
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </InputValidation>
                            )}
                            {defaultValue && (
                                <div
                                    style={{
                                        color: "#fff",
                                        border: "1px solid #ccc",
                                        padding: "8px 9px",
                                        borderRadius: 5,
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: 14,
                                            color: "#121212",
                                            fontWeight: 900,
                                            marginBottom: 4,
                                        }}
                                    >
                                        {getL10N(defaultValue, "title", null).i10n}
                                    </div>
                                    <div
                                        style={{
                                            color: "#ADB7C3",
                                            fontSize: 12,
                                        }}
                                    >
                                        {getL10N(defaultValue, "subtitle", null).i10n}
                                    </div>
                                </div>
                            )}
                        </div>
                        {selectedProductId && <div className="variants" style={{ padding: "0px 15px" }}>
                            <Row className="row-header" style={{
                                height: 46,
                                alignItems: "center",
                                marginBottom: 0
                            }}>
                                <Col span={12}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {isEdit && <div className="checkbox" onClick={() => onSelectVariant('all')} >
                                            <div className={`${selectedVariants.indexOf('all') > -1 ? 'selected' : ''}`}></div>
                                        </div>}
                                        <div>Variants</div>
                                    </div>
                                </Col>
                                <Col span={3}>
                                    <div style={{ textAlign: 'center' }}>Price</div>
                                </Col>
                                <Col span={3}>
                                    <div style={{ textAlign: 'center' }}>Sale Price</div>
                                </Col>
                                <Col span={3}><div style={{ textAlign: 'center' }}>Available Stock</div></Col>
                                <Col span={3}><div style={{ textAlign: 'center' }}>Visibility</div></Col>
                            </Row>
                            {selectedProductDetail && selectedProductDetail && selectedProductDetail.variants.map((v) => {
                                let vPrice = v.price ?? 0;
                                let vAvailableStock = v.availableStock ?? 0;
                                let vIsVisible = v.isVisible ?? false;
                                let vSalePrice = v.salePrice ?? 0;
                                if (v?.outlet) {
                                    if (v.outlet.price) {
                                        vPrice = v.outlet.price;
                                    }
                                    if (v.outlet.salePrice) {
                                        vSalePrice = v?.outlet?.salePrice
                                    }
                                    if (v.outlet.availableStock) {
                                        vAvailableStock = v?.outlet?.availableStock;
                                    }
                                    vIsVisible = v.outlet.isVisible;
                                }
                                const title = v.options ? onGenerateVariant(v.options) : selectedProductDetail.title
                                return <Row className="row-item" key={v.id}>
                                    <Col span={12}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {isEdit && <div className="checkbox" onClick={() => onSelectVariant(v.id)}>
                                                <div className={`${(selectedVariants.indexOf('all') > -1 || selectedVariants.indexOf(v.id) > -1) ? 'selected' : ''}`}></div>
                                            </div>}
                                            <div title={title} className="row-ellipsis">{title}</div>
                                        </div>
                                    </Col>
                                    <Col span={3}>
                                        <motion.div
                                            key={v.id}
                                            layout
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                        >
                                            {priceId !== v.id ? <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => {
                                                if (isEdit) {
                                                    setSalePriceId(null);
                                                    setSalePrice(null);
                                                    setAvailableStock(null);
                                                    setAvailableStockId(null);
                                                    setPriceId(v.id);
                                                    setPrice(vPrice);
                                                }
                                            }}>{vPrice}</div> : <div style={{ textAlign: 'center' }}>
                                                <input type="number" ref={priceRef} defaultValue={price}
                                                    onBlur={() => {
                                                        setPriceId(null);
                                                        setPrice(null);
                                                    }}
                                                    onKeyDown={(e: any) => {
                                                        if (e.key === "Enter") {
                                                            onUpdateVariantDetails('price', parseFloat(e.target.value), v.id);
                                                            e.target.blur();
                                                        } else if (e.key === "Escape") {
                                                            e.target.blur();
                                                        }
                                                    }}
                                                    onFocus={(e) => {
                                                        e.target.select();
                                                    }}
                                                // onChange={(e) => {
                                                //     if (selectedVariants.indexOf(v.id) > -1) {
                                                //         setSelectedVariants((preState) => [...preState, v.id])
                                                //         const inputValue = e.target.value;
                                                //         if (inputValue === "" || /^\d*\.?\d*$/.test(inputValue)) {
                                                //             setPrice(parseFloat(inputValue));
                                                //         }
                                                //     }
                                                // }}
                                                />
                                            </div>}
                                        </motion.div>
                                    </Col>
                                    <Col span={3}>
                                        <motion.div
                                            key={v.id}
                                            layout
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                        >
                                            {salePriceId !== v.id ? <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => {
                                                if (isEdit) {
                                                    setAvailableStock(null);
                                                    setAvailableStockId(null);
                                                    setPriceId(null);
                                                    setPrice(null);
                                                    setSalePrice(vSalePrice);
                                                    setSalePriceId(v.id);
                                                }
                                            }}>{vSalePrice}</div> :
                                                <input type="number" ref={salePriceRef} defaultValue={salePrice}
                                                    onFocus={(e) => {
                                                        e.target.select();
                                                    }}
                                                    onBlur={() => {
                                                        setSalePriceId(null);
                                                        setSalePrice(null);
                                                    }}
                                                    onKeyDown={(e: any) => {
                                                        if (e.key === "Enter") {
                                                            onUpdateVariantDetails('salePrice', parseFloat(e.target.value), v.id);
                                                            e.target.blur();
                                                        } else if (e.key === "Escape") {
                                                            e.target.blur();
                                                        }
                                                    }}
                                                // onChange={(e) => {
                                                //     if (selectedVariants.indexOf(v.id) > -1) {
                                                //         setSelectedVariants((preState) => [...preState, v.id])
                                                //     }
                                                //     const inputValue = e.target.value;
                                                //     if (inputValue === "" || /^\d*\.?\d*$/.test(inputValue)) {
                                                //         setSalePrice(parseFloat(inputValue))
                                                //     }
                                                // }}
                                                />
                                            }
                                        </motion.div>
                                    </Col>
                                    <Col span={3}>
                                        <motion.div
                                            key={v.id}
                                            layout
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                        >
                                            {availableStockId !== v.id ? <div
                                                style={{
                                                    textAlign: 'center',
                                                    cursor: 'pointer',
                                                    color: vAvailableStock === 0 ? '#C70039' : '#121212',
                                                    fontWeight: vAvailableStock === 0 ? 500 : 'normal'
                                                }}
                                                onClick={() => {
                                                    if (isEdit) {
                                                        setSalePrice(null);
                                                        setSalePriceId(null);
                                                        setPriceId(null);
                                                        setPrice(null);
                                                        setAvailableStock(vAvailableStock);
                                                        setAvailableStockId(v.id);
                                                    }
                                                }}>
                                                {vAvailableStock === 0 ? 'Out of Stock' : vAvailableStock}
                                            </div> :
                                                <input type="number" ref={availableStockRef} defaultValue={availableStock}
                                                    onFocus={(e) => {
                                                        e.target.select();
                                                    }}
                                                    onBlur={() => {
                                                        setAvailableStockId(null);
                                                        setAvailableStock(null);
                                                    }}
                                                    onKeyDown={(e: any) => {
                                                        if (e.key === "Enter") {
                                                            onUpdateVariantDetails('availableStock', parseInt(e.target.value), v.id);
                                                            e.target.blur();
                                                        } else if (e.key === "Escape") {
                                                            e.target.blur();
                                                        }
                                                    }}
                                                // onChange={(e) => {
                                                //     if (selectedVariants.indexOf(v.id) > -1) {
                                                //         setSelectedVariants((preState) => [...preState, v.id])
                                                //     }
                                                //     const inputValue = e.target.value;
                                                //     if (inputValue === "" || /^\d*\.?\d*$/.test(inputValue)) {
                                                //         setAvailableStock(parseInt(inputValue))
                                                //     }
                                                // }}
                                                />
                                            }
                                        </motion.div>
                                    </Col>
                                    <Col span={3}>
                                        <div style={{ textAlign: 'center' }} onClick={() => {
                                            if (isEdit) {
                                                // if (selectedVariants.indexOf(v.id) === -1) {
                                                //     setSelectedVariants((preState) => [...preState, v.id])
                                                // }
                                                setVariantVisibleId(v.id);
                                            }
                                        }}>
                                            <img src={vIsVisible === true ? VisibilityOnSvg : VisibilityOffSvg} alt="visibility" />
                                        </div>
                                    </Col>
                                </Row>
                            })}
                        </div>}
                        {/* <div className="group">
                                <div className="label">MRP</div>
                                <Input type="text" name="mrp" value={updatePrice.mrp} defaultValue={this.state.updatePrice.mrp} placeholder="₹0"
                                    onChange={this.onUpdatePrice}
                                />
                                <InputValidation type={this.state.error.mrp.type} message={this.state.error.address1.message}>
                                </InputValidation>
                            </div> */}
                        {/* <div className="group">
                            <div className="label">Price *</div>
                            <InputValidation type={error.price.type} message={error.price.message}>
                                <Tax>
                                    <Input
                                        style={{ borderLeftWidth: 1, borderRightWidth: 1, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
                                        disabled={!isEdit}
                                        name="price"
                                        pattern="[0-9]*"
                                        value={updatePrice.price}
                                        placeholder="₹0"
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                                setUpdatePrice({ ...updatePrice, [e.target.name]: value });
                                            }
                                        }}
                                    />
                                    <Input key={selectedProduct?.id || selectedProduct} readOnly className="alignRight" value={(updatePrice.price || 0) * (selectedProductTax ? selectedProductTax : 0) / 100} prefix={<div style={{ marginTop: 4 }}>
                                        <div style={{ color: "#A7A7A7", fontSize: 10 }}>Tax</div>
                                        <div style={{ color: "#777777", fontSize: 13 }}>{selectedProductTax || 0}%</div>
                                    </div>} placeholder="0" style={{ borderLeftWidth: 0, borderRightWidth: 1, borderBottomRightRadius: 5, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 5 }}>
                                    </Input>
                                </Tax>
                            </InputValidation>
                        </div>
                        <div className="group">
                            <div className="label">Sale Price</div>
                            <InputValidation type={error.salePrice.type} message={error.salePrice.message}>
                                <div style={{ display: 'flex' }}>
                                    <Input
                                        style={{ borderRightWidth: 1, borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
                                        prefix="₹"
                                        disabled={!isEdit}
                                        name="salePrice"
                                        value={updatePrice.salePrice}
                                        placeholder="₹0"
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                                const calPer: any = ((updatePrice.price - value) * 100) / updatePrice.price;
                                                setUpdatePrice({ ...updatePrice, ...{ [e.target.name]: value, salePricePercentage: calPer.toFixed(2) } });
                                            }
                                        }}
                                    />
                                    <Input
                                        style={{ borderLeftWidth: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                        prefix="%"
                                        disabled={!isEdit}
                                        name="salePricePercentage"
                                        value={updatePrice.salePricePercentage}
                                        placeholder="0%"
                                        // maxLength={2}
                                        onChange={(e) => {
                                            const { value }: any = e.target;
                                            const reg = /^-?\d*(\.\d*)?$/;
                                            const calPer: any = updatePrice.price - ((updatePrice.price * value) / 100);
                                            if ((value !== 0 && value <= 100 && updatePrice.price && !isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                                setUpdatePrice({ ...updatePrice, ...{ [e.target.name]: value, salePrice: calPer.toFixed(2) } });
                                            }
                                        }}
                                    />
                                </div>
                            </InputValidation>
                        </div>
                        <div className="group">
                            <div className="label">Stock *</div>
                            <InputValidation type={error.stock.type} message={error.stock.message}>
                                <Input
                                    disabled={!isEdit}
                                    name="stock"
                                    value={updatePrice.stock}
                                    placeholder="₹0"
                                    onChange={(e) => {
                                        const { value }: any = e.target;
                                        const reg = /^-?\d*(\.\d*)?$/;
                                        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                                            setUpdatePrice({ ...updatePrice, [e.target.name]: value });
                                        }
                                    }}
                                />
                            </InputValidation>
                        </div>
                        <div className="group">
                            <div className="label">Visible *</div>
                            <InputValidation type={error.isAvailable.type} message={error.isAvailable.message}>
                                <Switch
                                    className="toggle-opt"
                                    disabled={!isEdit}
                                    checked={updatePrice.isAvailable}
                                    onChange={(value) => setUpdatePrice({ ...updatePrice, isAvailable: value })}
                                />
                            </InputValidation>
                        </div>
                        <div className="group">
                            <div className="label">Out Of Stock *</div>
                            <InputValidation type={error.outOfStock.type} message={error.outOfStock.message}>
                                <Switch
                                    className="toggle-opt"
                                    disabled={!isEdit}
                                    checked={updatePrice.outOfStock}
                                    onChange={(value) => setUpdatePrice({ ...updatePrice, outOfStock: value })}
                                />
                            </InputValidation>
                        </div> */}
                    </div>
                    {/* <div className="footer">
                        <Row>
                            <Col span={11} style={{ paddingRight: "15px" }}>
                                <button onClick={() => props.onClose()} className="sp_btn cancel">Cancel</button>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11} style={{ paddingLeft: "15px" }}>
                                {isEdit && <button onClick={onSubmitHandler} className="sp_btn save">{defaultValue ? "Update " : "Add "}Price</button>}
                                {!isEdit && <button onClick={() => setIsEdit(true)} className="sp_btn edit">Edit</button>}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} >
                                {isEdit && <button onClick={onSubmitHandler} className="add-price">{defaultValue ? "Update " : "Add "}Price</button>}
                                {!isEdit && <button onClick={() => setIsEdit(true)} className="add-price">Edit</button>}
                            </Col>
                        </Row>
                    </div> */}
                </Col>
            </Row>
        </Drawer>
    );
};

function mapStateToProps(state: any) {
    // console.log("Product mapStateToProps: ", state);
    return {
        product: state.product,
        auth: state.auth,
        common: state.common,
        ui: state.ui,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getProducts,
            getProductByIdOuId,
            getProductById,
            updateProductOutlets,
            addProductOutlets
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductToOutlet);
