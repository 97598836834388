import React from 'react';
import { connect } from "react-redux";
import Accounts from "./Accounts";
import "../../../../../assets/sass/dsk/account.sass";
const Account = (props: any) => {
    return (
        <div className="account">
            <Accounts {...props}></Accounts>
        </div>
    );
}

export default connect(null, {})(Account);