import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStoreDetails } from "../../../../../../actions/brandAction";
// import { loadSiteSettings } from "../../../../themeService";

const Store = (props) => {
    const dispatch = useDispatch();
    const [storeInfo, setStoreInfo] = useState(null);
    const { selectedStore } = useSelector((state: any) => state.ui);
    const getStoreDetail = async () => {
        const resp = await getStoreDetails()(dispatch);
        setStoreInfo(resp);
    }
    useEffect(() => {
        if (selectedStore.id) {
            getStoreDetail();
        }
        //eslint-disable-next-line
    }, [selectedStore]);

    const generateWebSiteUrl = (name) => {
        return window.location.origin.replace('shopletzy', 'myshopletzy').replace('app', name) + '/landing';
    }
    return <div className="container">
        <div className="header" >Store Details</div>
        <div className="section">
            {storeInfo &&
                <Row style={{ width: '100%' }}>
                    <Col span={24} style={{
                        paddingRight: 10
                    }}>
                        {/* <div className="title">Strore Details</div> */}
                        <div className="division">
                            <Row style={{ marginTop: 16 }}>
                                <Col span={10} className="label">
                                    Brand Name:
                                </Col>
                                <Col span={14} className="value">
                                    {storeInfo.brandName}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 16 }}>
                                <Col span={10} className="label">
                                    Website:
                                </Col>
                                <Col span={14} className="value">
                                    <a rel="noopener noreferrer" target={"_blank"} href={`${generateWebSiteUrl(selectedStore.name)}?previewMode=true`}>{storeInfo.name}</a>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 16 }}>
                                <Col span={10} className="label">
                                    Industry Type:
                                </Col>
                                <Col span={14} className="value">
                                    {storeInfo.industryType}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 16 }}>
                                <Col span={10} className="label">
                                    Contact Number:
                                </Col>
                                <Col span={14} className="value">
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 16 }}>
                                <Col span={10} className="label">
                                    Registered Business:
                                </Col>
                                <Col span={14} className="value">
                                </Col>
                            </Row>
                        </div>
                        <div className="division">
                            <div className="title">Address</div>
                        </div>
                    </Col>
                </Row>
            }
        </div>
    </div>
}

export default Store;