import React from 'react';
import { connect } from "react-redux";
import ListedUser from "./ListedUser";
import { selectedMainMenu, selectedTabMenu } from "../../../../../actions/uiAction";

const Users = (props: any) => {
    // const [selectedTabMenu, setSelectedTabMenu] = useState("listed_product");
    // const tabMenu = useSelector((state: any) => state.ui.selectedTabMenu);
    // console.log(tabMenu)
    // useEffect(() => {
    //     props.selectedMainMenu('users')
    //     props.selectedTabMenu('listed_user')
    // }, []);
    return (
        <div className="menu">
            <ListedUser {...props}></ListedUser>
        </div>
    );
}

export default connect(null, { selectedMainMenu, selectedTabMenu })(Users);