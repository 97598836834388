import React from 'react';
import "../../../assets/sass/dsk/inputvalidation.sass";

const InputValidation = (props: any) => {
    const { style = {} } = props;
    return (
        <div style={{ ...style }} className="input-validation">
            <div className="block">
                <div className={props.type}>
                    {props.children}
                    <div className="message">{props.message}</div>
                </div>
            </div>
        </div>
    );
}

export default InputValidation;