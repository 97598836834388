import * as React from "react";

const CWebSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={17}
        viewBox="0 0 17 17"
        {...props}
    >
        <g
            id="vuesax_linear_global"
            data-name="vuesax/linear/global"
            transform="translate(-173.5 -253.5)"
        >
            <g id="global" transform="translate(174 254)">
                <path
                    id="Vector"
                    d="M16,8A8,8,0,1,1,8,0,8,8,0,0,1,16,8Z"
                    fill="none"
                    stroke={props.stroke || "#3d3d46"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M.379,0H1.2a22.133,22.133,0,0,0,0,14.363H.379"
                    transform="translate(4.422 0.819)"
                    fill="none"
                    stroke={props.stroke || "#3d3d46"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M0,0A22.133,22.133,0,0,1,0,14.363"
                    transform="translate(10.381 0.819)"
                    fill="none"
                    stroke={props.stroke || "#3d3d46"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-4"
                    data-name="Vector"
                    d="M0,.819V0A22.133,22.133,0,0,0,14.363,0V.819"
                    transform="translate(0.819 10.381)"
                    fill="none"
                    stroke={props.stroke || "#3d3d46"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
                <path
                    id="Vector-5"
                    data-name="Vector"
                    d="M0,1.2a22.133,22.133,0,0,1,14.363,0"
                    transform="translate(0.819 4.422)"
                    fill="none"
                    stroke={props.stroke || "#3d3d46"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                />
            </g>
        </g>
    </svg>
);

export default CWebSVG;
