import React from 'react';

import { Action, ActionProps } from '../Action';

export function Add(props: ActionProps) {
  return (
    <Action
      {...props}
      active={{
        fill: 'rgba(255, 70, 70, 0.95)',
        background: 'rgba(255, 70, 70, 0.1)',
        marginRight: '12px',
      }}
    >
      <div style={{
        color: '#fff',
        fontSize: 16,
        background: '#121212',
        borderRadius: 30,
        width: 18,
        height: 18,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>+</div>
      {/* <img alt="add" src={AddSvg} style={{ height: 20 }} /> */}
    </Action>
  );
}
