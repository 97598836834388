import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import InputValidation from "../common/InputValidation";
import { useDispatch } from "react-redux";
import { onCreateMenu, onUpdateMenu } from "../../../actions/navigationAction";

const SaveMenu = (props: any) => {
    const dispatch = useDispatch();
    const { isVisible, onClose, menu } = props;
    const [title, setTitle] = useState(null);
    const [error, setError] = useState({
        title: {
            type: null,
            message: null
        }
    });
    useEffect(() => {
        if (menu?.title) {
            setTitle(menu.title);
        }
    }, [menu]);
    const onSubmit = async () => {
        if (!title) {
            setError({
                title: {
                    type: 'error',
                    message: 'Title is required'
                }
            });
        } else {
            setError({
                title: {
                    type: 'success',
                    message: null
                }
            });
            if (menu?.id) {
                const resp: any = await onUpdateMenu({ title }, menu.id)(dispatch);
                if (resp?.isUpdated) {
                    onClose({ id: menu.id, title });
                }
            } else {
                const resp: any = await onCreateMenu({ title })(dispatch);
                if (resp?.menuId) {
                    onClose({ id: resp?.menuId, title });
                }
            }
        }
    }
    useEffect(() => {
        if (!isVisible) {
            setTitle('');
            setError({
                title: {
                    type: null,
                    message: null
                }
            });
        }
    }, [isVisible]);
    return (
        <Modal
            title={`${title ? "Update " : "Create "} Menu`}
            onCancel={() => onClose(null)}
            closable={false}
            width={"30%"}
            className="add-menu"
            open={isVisible}
            footer={<div className='footer'>
                <div key="back" className="sp_btn cancel" onClick={() => onClose(null)}>
                    Cancel
                </div>
                <div key="submit" className="sp_btn create" onClick={onSubmit}>
                    {menu?.id ? "Update" : "Create"}
                </div>
            </div>}
        >
            <div className="content">
                <div>Title *</div>
                <InputValidation type={error.title.type}
                    message={error.title.message}>
                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                </InputValidation>
            </div>
        </Modal>
    );
}

export default SaveMenu;