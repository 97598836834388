import type from "../types/actionTypes";
import { store } from '../store';

export const selectedMainMenu = data => {
    return {
        type: type.SELECTED_MAIN_MENU,
        data
    };
};

export const onUpdateOrderStatus = (status) => {
    return async (dispatch) => {
        dispatch({
            type: type.ORDER_STATUS_UPDATED,
            data: status
        });
    };
};

export const getSelectedMainMenu = data => {
    return {
        data: store.getState().ui?.selectedMainMenu
    }
}

export const selectedStore = data => {
    return {
        type: type.SELECTED_STORE,
        data
    }
}

export const resetOrders = data => {
    return {
        type: type.RESET_ORDERS,
        data
    }
}

export const selectedTabMenu = data => {
    return {
        type: type.SELECTED_TAB_MENU,
        data
    };
};

export const onNavBarHidden = data => {
    return {
        type: type.HIDDEN_NAV_BAR_BOTTOM,
        data
    };
};

export const selectedSettingsMenu = data => {
    return {
        type: type.SELECTED_SETTINGS_MENU,
        data
    };
};

export const showMultiUpdate = data => {
    return {
        type: type.SHOW_MULTI_UPDATE,
        data
    };
}

export const selectedOrderDates = data => {
    return {
        type: type.SELECTED_ORDER_DATES,
        data
    };
};

export const orderFilterToggle = data => {
    return {
        type: type.ORDER_FILTER_TOGGLE,
        data
    };
};

export const currentOrderFilterOptions = data => {
    return {
        type: type.CURRENT_ORDER_FILTER_OPTIONS,
        data
    };
};

export const onListedProductFilterOptions = data => {
    return {
        type: type.LISTED_PRODUCT_FILTER_OPTIONS,
        data
    };
}

export const onProductCatalogFilterOptions = data => {
    return {
        type: type.PRODUCT_CATALOG_FILTER_OPTIONS,
        data
    };
}

export const onInventoryFilterOptions = data => {
    return {
        type: type.INVENTORY_FILTER_OPTIONS,
        data
    };
}

export const paymentFailureOrderFilterOptions = data => {
    return {
        type: type.PAYMENT_FAILURE_ORDER_FILTER_OPTIONS,
        data
    };
};

export const pendingOrderFilterOptions = data => {
    return {
        type: type.PENDING_ORDER_FILTER_OPTIONS,
        data
    };
};

export const allOrderFilterOptions = data => {
    return {
        type: type.ALL_ORDER_FILTER_OPTIONS,
        data
    };
};

export const setLocale = data => {
    return {
        type: type.LOCALE,
        data: data
    };
};

export const getLocale = () => {
    return store.getState().ui?.locale;
};

export const toggleLeftMenu = data => {
    return {
        type: type.LEFT_MENU_TOGGLE,
        data: !store.getState().ui?.leftMenuToggle
    };
};

export const getSetupStoreDetails = data => {
    return {
        data: store.getState().ui?.setupStoreDetails
    }
}

export const setSetupStoreDetails = data => {
    return {
        type: type.SETUP_STORE_DETAILS,
        data
    }
}

// export const clearSearchAddress = data => {
//     return {
//         type: type.SEARCH_ADDRESS_SUCCESS,
//         data: { suggestions: [], sessionToken: null }
//     }
// }

// export const clearGetAddressSuccess = data => {
//     return {
//         type: type.GET_ADDRESS_SUCCESS,
//         data
//     }
// }

export const setSaveCredentials = data => {
    return {
        type: type.SET_CREDENTIALS_SUCCESS,
        data
    }
}

export const getSaveCredentials = () => {
    return {
        type: type.GET_CREDENTIALS_SUCCESS,
        data: store.getState().ui?.saveCredentials
    }
}