import type from "../types/actionTypes"

export const initialState = {
    /**
     * This prop response for add active
     * class to root nav current selection
     */
    selectedMainMenu: "orders",
    showUpdateSetting: false,
    setupStoreDetails: null,
    selectedSettingsMenu: null,
    selectedTabMenu: null,
    selectedOuId: null,
    listedProductFilterOptions: {
        query: null,
    },
    productCatalogFilterOptions: {
        query: null,
    },
    inventoryFilterOptions: {
        query: null,
    },
    currentOrderFilterOptions: {
        query: null,
        status: "all",
        slotDate: "all",
        slotId: "all",
    },
    allOrderFilterOptions: {
        query: null,
        status: "all",
        date: null
    },
    paymentFailureOrderFilterOptions: {
        query: null,
        status: "all",
        date: null
    },
    pendingOrderFilterOptions: {
        query: null,
        status: "all",
        date: null
    },
    selectedOrderDates: null,
    orderStatusUpdated: false,
    selectedStore: null,
    navBarHidden: false,
    locale: {
        key: "en",
        value: "English"
    },
    testing: "hello"
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // case type.SELECTED_MAIN_MENU:
        //     return {
        //         ...state,
        //         selectedMainMenu: action.data,
        //         selectedSettingsMenu: null,
        //         selectedTabMenu: null
        //     };
        // case type.SELECTED_MENU:
        //     return {
        //         ...state,
        //         activeTabMenu: action.data
        //     };
        case type.ORDER_STATUS_UPDATED:
            return {
                ...state,
                orderStatusUpdated: action.data
            }
        case type.SELECTED_STORE:
            return {
                ...state,
                selectedStore: action.data,
                selectedOuId: null,
                listedProductFilterOptions: {
                    query: null,
                },
                productCatalogFilterOptions: {
                    query: null,
                },
                inventoryFilterOptions: {
                    query: null,
                },
                currentOrderFilterOptions: {
                    query: null,
                    status: "all",
                    slotDate: "all",
                    slotId: "all",
                },
                allOrderFilterOptions: {
                    query: null,
                    status: "all",
                    date: null
                },
                paymentFailureOrderFilterOptions: {
                    query: null,
                    status: "all",
                    date: null
                },
                pendingOrderFilterOptions: {
                    query: null,
                    status: "all",
                    date: null
                },
            }
        // case type.SELECTED_TAB_MENU:
        //     return {
        //         ...state,
        //         selectedTabMenu: action.data
        //     };
        case type.SELECTED_SETTINGS_MENU:
            return {
                ...state,
                selectedSettingsMenu: action.data
            };
        case type.SHOW_MULTI_UPDATE:
            return {
                ...state,
                showMultiUpdate: action.data
            }
        case type.SELECTED_ORDER_DATES:
            return {
                ...state,
                selectedOrderDates: action.data
            }
        case type.ORDER_FILTER_TOGGLE:
            return {
                ...state,
                orderFilterToggle: action.data
            }
        case type.CURRENT_ORDER_FILTER_OPTIONS:
            debugger
            return {
                ...state,
                currentOrderFilterOptions: {
                    status: action.data.status,
                    slotDate: action.data.slotDate,
                    slotId: action.data.slotId,
                    query: action.data.query
                },
                selectedOuId: action.data.ouId
            }
        case type.LISTED_PRODUCT_FILTER_OPTIONS:
            return {
                ...state,
                listedProductFilterOptions: {
                    query: action.data.query
                },
                selectedOuId: action.data.ouId
            }
        case type.PRODUCT_CATALOG_FILTER_OPTIONS:
            return {
                ...state,
                productCatalogFilterOptions: {
                    query: action.data.query
                },
                selectedOuId: action.data.ouId
            }
        case type.INVENTORY_FILTER_OPTIONS:
            return {
                ...state,
                inventoryFilterOptions: {
                    query: action.data.query
                },
                selectedOuId: action.data.ouId
            }
        case type.ALL_ORDER_FILTER_OPTIONS:
            return {
                ...state,
                allOrderFilterOptions: {
                    status: action.data.status,
                    date: action.data.date,
                    query: action.data.query
                },
                selectedOuId: action.data.ouId
            }
        case type.PAYMENT_FAILURE_ORDER_FILTER_OPTIONS:
            return {
                ...state,
                paymentFailureOrderFilterOptions: {
                    status: action.data.status,
                    date: action.data.date,
                    query: action.data.query
                },
                selectedOuId: action.data.ouId
            }
        case type.PENDING_ORDER_FILTER_OPTIONS:
            return {
                ...state,
                pendingOrderFilterOptions: {
                    status: action.data.status,
                    date: action.data.date,
                    query: action.data.query
                },
                selectedOuId: action.data.ouId
            }
        case type.LOCALE:
            return {
                ...state,
                locale: action.data
            }
        case type.LEFT_MENU_TOGGLE:
            return {
                ...state,
                leftMenuToggle: action.data
            }
        case type.SETUP_STORE_DETAILS:
            return {
                ...state,
                setupStoreDetails: action.data
            }
        case type.SIGNOUT:
            return {
                ...initialState
            }
        case type.SET_CREDENTIALS_SUCCESS:
            return {
                ...state,
                saveCredentials: action.data,
            };
        case type.HIDDEN_NAV_BAR_BOTTOM:
            return {
                ...state,
                navBarHidden: action.data,
            };
        default:
            return state;
    }
};
export default reducer;
