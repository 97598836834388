import { Col, Input, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import GlobalSvg from "../../../../../../assets/images/settings/global.svg";
import DeleteSvg from "../../../../../../assets/images/settings/delete.svg";
import ConnectedSvg from "../../../../../../assets/images/settings/connected.svg";
import { onGetDomains, onRemoveDomain, onSetupDomain } from '../../../../../../actions/commonAction';
import PopupConfirm from '../../../../common/PopupConfirm';
import dayjs from 'dayjs';
import Loading from '../../../../common/Loading';

const CustomDomain = (props: any) => {
    const dispatch = useDispatch();
    const { selectedStoreDetails } = useSelector((state: any) => state.common);
    const [domains, setDomains] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [newDomainUrl, setNewDomainUrl] = useState(null);
    const [domainRemoveConfirm, setDomainRemoveConfirm] = useState(null);
    useEffect(() => {
        loadData();
        //eslint-disable-next-line
    }, []);

    const loadData = async () => {
        const resp: any = await onGetDomains()(dispatch);
        if (resp && resp.domains) {
            setDomains(resp.domains);
        }
    }
    const onSetupUrl = async () => {
        if (newDomainUrl && newDomainUrl.length > 0) {
            setLoading(true);
            const resp = await onSetupDomain({ domain: newDomainUrl })(dispatch)
            if (resp) {
                loadData();
            }
            setLoading(false);
        }
    }
    const onRemoveConfirm = (domainUrl) => {
        let popupConfirm = null;
        popupConfirm = {
            title: () => { return <div style={{ color: "#B20600" }}>Remove Domain</div> },
            renderContent: () => {
                return <div>
                    <div>Are you sure you want to remove this domain?</div>
                    <div style={{ marginTop: 14 }}><a rel="noopener noreferrer" href={domainUrl} target="_blank" style={{ color: '#0076FF', cursor: 'pointer' }}>{domainUrl}</a></div>
                </div>
            },
            yes: {
                label: "Yes",
                callback: async () => {
                    setDomainRemoveConfirm(null);
                    const resp: any = await onRemoveDomain({ domain: domainUrl })(dispatch)
                    if (resp) {
                        loadData();
                    }
                }
            },
            no: {
                label: "No",
                callback: () => { setDomainRemoveConfirm(null) }
            }
        }
        setDomainRemoveConfirm(popupConfirm);
    }
    return (
        <div className="custom-domain" >
            {isLoading && <Loading background="#00000070"></Loading>}
            <div
                className="container" style={{ flexGrow: 1, padding: 0, marginRight: 15 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', margin: "14px 4px" }}>
                    <div>
                        <div style={{ fontSize: 18, fontWeight: 'bold' }}>Custom Domain</div>
                        <div style={{ fontSize: 14, color: '#4E4E4E' }}>Set your custom domain for your store</div>
                    </div>
                </div>
                <div className='division'>
                    <div className='section'>
                        <div style={{ display: 'flex' }}>
                            <Input type="text" style={{ marginRight: 15 }} value={newDomainUrl} onChange={(e) => setNewDomainUrl(e.target.value.toLocaleLowerCase())} />
                            <button style={{ width: 160, fontSize: 14 }} className="sp_btn" onClick={onSetupUrl}>Setup URL</button>
                        </div>
                        <div style={{ background: '#f5f7f9', padding: "4px 25px", margin: "20px 0px 0px 0px" }}>
                            <div style={{ margin: "10px 0px", fontWeight: 'bold', color: '#121212', fontSize: 24 }}>Next Step, verify your domain</div>
                            <div>Before you add your domain here, make sure that you have added the following A record in your DNS zone editor settings.
                                After adding the A record, please verify it here
                                <a rel="noopener noreferrer" style={{ marginLeft: 4 }} href='https://www.nslookup.io' target={"_blank"}>nslookup</a>
                            </div>
                            <div style={{ margin: "15px 0px" }}>
                                <Row style={{ marginBottom: 10 }}>
                                    <Col span={3} style={{ fontWeight: 'bold' }}>
                                        Domain
                                    </Col>
                                    <Col span={21}>
                                        {selectedStoreDetails && selectedStoreDetails.name}.com
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={3} style={{ fontWeight: 'bold' }}>
                                        IP Address
                                    </Col>
                                    <Col span={21}>
                                        20.198.109.60
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                {domains && domains.length > 0 && <div className='division'>
                    <div style={{ color: "#121212", fontWeight: "bold", fontSize: 15, marginLeft: 14 }}>Domains Linked</div>
                    <div className="section" style={{ padding: '0px', overflow: 'hidden', borderColor: "#D9D9D9", boxShadow: "0px 4px 16px 0px rgba(198, 198, 198, 0.12)" }}>
                        <Row style={{ color: '#7A7F85', background: "#F5F7F9", fontWeight: 500, fontSize: 15, borderBottom: '1px solid #CBCBCB', height: 36, display: 'flex', alignItems: 'center', padding: '0px 21px' }}>
                            <Col span={12}>
                                <div style={{ marginLeft: 34 }}>Domain</div>
                            </Col>
                            <Col span={5}>
                                Created At
                            </Col>
                            <Col span={5}>
                                Status
                            </Col>
                            <Col span={2}>
                                Action
                            </Col>
                        </Row>
                        <div style={{ overflowY: 'auto', maxHeight: window.innerHeight - 600 }}>
                            {domains.map((d, i) => <Row key={d.id} style={{ borderBottom: `${domains.length - 1 === i ? 0 : 1}px solid #DFE0E1`, height: 43, display: 'flex', alignItems: 'center', margin: '0px 21px' }}>
                                <Col span={12}>
                                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: "#21C082" }}>
                                        <img src={GlobalSvg} alt='Global' style={{ marginRight: 10 }} />
                                        <a rel="noopener noreferrer" style={{ marginLeft: 4 }} href={d.domain} target={"_blank"}>{d.domain}</a>
                                    </div>
                                </Col>
                                <Col span={5}>
                                    {dayjs(d.createdAt).calendar()}
                                </Col>
                                <Col span={5}>
                                    <div style={{ display: 'flex', alignItems: 'center', color: "#21C082" }}>
                                        <img src={ConnectedSvg} alt='Connected' /> <div style={{ marginLeft: 6 }}>Connected</div>
                                    </div>
                                </Col>
                                <Col span={2}>
                                    <div onClick={() => onRemoveConfirm(d.domain)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: "#3C3C3C" }}>
                                        <img src={DeleteSvg} alt='Delete' /> <div style={{ marginLeft: 6 }}>Remove</div>
                                    </div>
                                </Col>
                            </Row>)}
                        </div>
                    </div>
                </div>}
            </div>
            <div
                className="container"
                style={{
                    padding: '12px 18px',
                    height: '100%',
                    width: '26%',
                    background: '#FFFFFF',
                    boxShadow: '0px 3px 16px #DBE2EA6C',
                    flexShrink: 0
                }}>
                <div className="header" style={{ marginBottom: 12 }}>Custom Domain</div>
                <div className="title" style={{ marginBottom: 12 }}>Lorem ipsum dolor</div>
                <div className="value" style={{ marginBottom: 12 }}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    <br />
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                </div>
                <div className="title" style={{ marginBottom: 12 }}>Lorem ipsum dolor</div>
                <div className="value" style={{ marginBottom: 12 }}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                </div>
                <div className="title" style={{ marginBottom: 12 }}>Lorem ipsum dolor</div>
                <div className="value" style={{ marginBottom: 12 }}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                    et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                </div>
            </div>
            {domainRemoveConfirm && <PopupConfirm config={domainRemoveConfirm}></PopupConfirm>}
        </div>
    );
}

export default CustomDomain;